// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pslbilling-table_parentRow__xqvZc {
    background-color: #ffffff !important;
}

.pslbilling-table_removeBorder__xvqeX {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.pslbilling-table_expandBtn__r5jHS svg * {
    fill: #d40511;
}

.pslbilling-table_internalRowHeader__\\+grPr {
    padding-left: 27px !important;
}

.pslbilling-table_addBorderBottom__tfuIl {
    border-bottom: 1px solid #d1d1d1 !important;
}

.pslbilling-table_addBorderRight__793Ch {
    border-right: 1px solid #d1d1d1 !important;
}

.pslbilling-table_parentRow__xqvZc td:first-child {
    vertical-align: middle;
}

.pslbilling-table_rightAlign__r1xTc {
    text-align: right !important;
}

.pslbilling-table_centerAlign__\\+k-VN{
    text-align: center !important;
}

.pslbilling-table_billingTableP__Ap-4g {
    margin-top: 15px;
    margin-bottom: 15px !important;
}

.pslbilling-table_removeWhiteSpace__DWxmE{
    white-space: pre-wrap !important;
}

.pslbilling-table_summaryRowHeight__Lr-Tj{
    height:30px !important;
}

.pslbilling-table_tableMargin__RkkrX{
    margin:20px 0;
}

.pslbilling-table_correctionTable__2Rc5c{
    margin:20px auto;
    width:75%;

}
.pslbilling-table_lastNoPadding__k9RyN td:last-child {
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/PSLBillingTableUpdated/pslbilling-table.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;IAChC,iCAAiC;IACjC,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,SAAS;;AAEb;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".parentRow {\n    background-color: #ffffff !important;\n}\n\n.removeBorder {\n    border-top-width: 0 !important;\n    border-right-width: 0 !important;\n    border-bottom-width: 0 !important;\n    border-left-width: 0 !important;\n}\n\n.expandBtn svg * {\n    fill: #d40511;\n}\n\n.internalRowHeader {\n    padding-left: 27px !important;\n}\n\n.addBorderBottom {\n    border-bottom: 1px solid #d1d1d1 !important;\n}\n\n.addBorderRight {\n    border-right: 1px solid #d1d1d1 !important;\n}\n\n.parentRow td:first-child {\n    vertical-align: middle;\n}\n\n.rightAlign {\n    text-align: right !important;\n}\n\n.centerAlign{\n    text-align: center !important;\n}\n\n.billingTableP {\n    margin-top: 15px;\n    margin-bottom: 15px !important;\n}\n\n.removeWhiteSpace{\n    white-space: pre-wrap !important;\n}\n\n.summaryRowHeight{\n    height:30px !important;\n}\n\n.tableMargin{\n    margin:20px 0;\n}\n\n.correctionTable{\n    margin:20px auto;\n    width:75%;\n\n}\n.lastNoPadding td:last-child {\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentRow": `pslbilling-table_parentRow__xqvZc`,
	"removeBorder": `pslbilling-table_removeBorder__xvqeX`,
	"expandBtn": `pslbilling-table_expandBtn__r5jHS`,
	"internalRowHeader": `pslbilling-table_internalRowHeader__+grPr`,
	"addBorderBottom": `pslbilling-table_addBorderBottom__tfuIl`,
	"addBorderRight": `pslbilling-table_addBorderRight__793Ch`,
	"rightAlign": `pslbilling-table_rightAlign__r1xTc`,
	"centerAlign": `pslbilling-table_centerAlign__+k-VN`,
	"billingTableP": `pslbilling-table_billingTableP__Ap-4g`,
	"removeWhiteSpace": `pslbilling-table_removeWhiteSpace__DWxmE`,
	"summaryRowHeight": `pslbilling-table_summaryRowHeight__Lr-Tj`,
	"tableMargin": `pslbilling-table_tableMargin__RkkrX`,
	"correctionTable": `pslbilling-table_correctionTable__2Rc5c`,
	"lastNoPadding": `pslbilling-table_lastNoPadding__k9RyN`
};
export default ___CSS_LOADER_EXPORT___;
