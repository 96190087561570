import { OrderResponse } from '../dtos/GetOrder';

export function determinePackagingQuantity(order: OrderResponse): number {
  let quantity;

  switch (order.orderCategoryProductKey) {
    default:
      quantity = order.packaging?.quantity ?? 0;
      break;
  }

  return quantity;
}
