import React, { FC, ReactElement, useCallback, useState } from 'react';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { useTranslation } from 'react-i18next';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { FormRow } from 'common/components/FormRow';
import { useFormContext } from 'react-hook-form';

export interface AdditionallyPaidItemsProps {
  name?: string;
  defaultValue?: number;
  disabled?: boolean;
}
export const AdditionallyPaidItems = ({ ...props }: AdditionallyPaidItemsProps): ReactElement => {
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { setValue } = useFormContext();
  const [errorMessageKey, setErrorMessageKey] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '0') {
      setError(true);
      setErrorMessageKey('zero');
    } else if (Number(e.target.value) < 0) {
      setError(true);
      setErrorMessageKey('negative');
    } else if (value.length > 9) {
      setError(true);
      setErrorMessageKey('tooBig');
    } else {
      setError(false);
    }
    setValue(props.name ? `${props.name}` : 'additionallyPaidItems', value);
  };

  return (
    <>
      <OrderStepSection headline={oc('additionallyPaidItems')}></OrderStepSection>
      <FormRow mode="two-col">
        <CustomTextInput
          // required={props.required}
          type={'number'}
          name={props.name}
          label={oc('quantity')}
          testid={'additionallyPaidItems'}
          onChange={onChangeHandler}
          error={error ? t(`error.additionallyPaidItems.${errorMessageKey}`) : undefined}
          defaultValue={props?.defaultValue ?? ''}
          disabled={props?.disabled}
        />
      </FormRow>
    </>
  );
};
