import i18n from 'i18n';

const deTranslation = {
  error: {
    length: 'Bitte prüfen Sie Ihre Eingabe. Der eingegebene Wert darf maximal neun Ziffern enthalten.'
  }
};
const enTranslation = {
  error: {
    length: 'Please check your input. The entered value can contain a maximum of nine digits.'
  }
};

i18n.addResourceBundle('de', 'shipmentInputs', deTranslation);
i18n.addResourceBundle('en', 'shipmentInputs', enTranslation);
