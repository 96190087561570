import classNames from 'classnames';
import { useAuthContext } from 'common/context/auth/AuthContext';
import React, { VFC, InputHTMLAttributes, useState, useEffect } from 'react';
import styles from './CustomTextInput.module.css';

export interface customInputRules {
  regex?: RegExp;
}
interface CustomTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string | number;
  testid?: string;
  label?: string;
  error?: string;
  rules?: customInputRules;
  disabled?: boolean;
  formatNumbers?: boolean;
  meta?: { language?: string };
}

export const regexValidator = (value: string, regex: RegExp) => {
  return regex ? value.match(regex) : false;
};

export const CustomTextInput: VFC<CustomTextInputProps> = (props) => {
  const defaultValue = props.defaultValue ? props.defaultValue : '';
  const [value, setValue] = useState<string | readonly string[] | number>(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  // if controlled input
  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
    else setValue(defaultValue);
  }, [props.value, defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const invalid = props.rules?.regex ? !regexValidator(value, props.rules.regex) : false;

    if (!invalid) {
      setValue(value);
      props.onChange?.(e);
    } else {
      e.preventDefault();
      if (value.length === 0) {
        setValue('');
        props.onChange?.(e);
      }
    }
  };

  const handleOnFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    props.onFocus?.(e as any);
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    props.onBlur?.(e as any);
  };

  return (
    <>
      <div className={classNames(styles.customTextInputContainer, `${props.className}Container`)}>
        <input
          {...props}
          className={classNames(
            styles.customTextInput,
            !props.label ? styles.customTextInputWithoutLabel : '',
            props.error ? styles.errorInInput : '',
            props.className ? props.className : ''
          )}
          // defaultValue={value ? value : ''}
          data-testid={props.testid}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={value ? (!isFocused && props.formatNumbers && props.meta?.language ? value.toLocaleString(props.meta?.language) : value) : ''}
          disabled={props.disabled}
        />
        {props.label && (
          <label
            htmlFor={props.name}
            data-testid={`${props.testid}-label`}
            className={classNames(
              styles.customTextInputLabel,
              {
                [styles.errorInInput]: props.error,
                [styles.labelActive]: (value && value.toString().length > 0) || isFocused
              },
              props.className
            )}
          >
            {props.label}
          </label>
        )}
        {props.error && (
          <div className={classNames(styles.error, `${props.className}Error`)} data-testid={`${props.testid}-error`}>
            {props.error}
          </div>
        )}
      </div>
    </>
  );
};
