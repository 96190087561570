import bwipjs from 'bwip-js';

export const getPaletteIdBarcodeImageUrl = (barcodeValue: string) => {
  const canvas = document.createElement('canvas');

  bwipjs.toCanvas(canvas, {
    bcid: 'code128', // Barcode type
    text: barcodeValue, // Text to encode
    scale: 3, // scaling factor, increases quality of bitmap
    height: 33, // Bar height, in millimeters
    width: 150, // bar width, in millimeters
    includetext: false, // Show human-readable text
    textfont: 'delivery',
    textsize: 10,
    textyalign: 'below',
    textxalign: 'center',
    textyoffset: 5
  });

  return canvas.toDataURL('image/png');
};
