import { DHLContextmenuGroup, DHLIcon, DHLLoading } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { AMDHLDropdownButton } from 'common/components/AMDHLDropdownButton';
import selectClasses from 'common/components/AMDHLSelectSingle/AMDHLSelectSingle.module.css';
import { HighlightedText } from 'common/components/HighlightedText';
import IconSearchButton from 'common/components/IconSearchButton';
import { Input } from 'common/components/Input';
import { InputGroup } from 'common/components/InputGroup';
import { FetchMethod, useFetcher, UseFetcherReturn } from 'common/components/InputSelectSearchable/useFetcher';
import { Item } from 'common/components/MultiList';
import multiListClasses from 'common/components/MultiList/MultiList.module.css';
import { useSelect, UseSelectParams } from 'common/hooks/useSelect';
import React, { FormEventHandler, KeyboardEvent, MouseEvent, ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import classes from './input-select-searchable.module.css';
import { JournalRep } from 'generated';

export type DeleteMethod<T> = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, item: T, index: number) => void;

export type InputSelectSearchableProps<T> = Omit<UseSelectParams<T>, 'items'> & {
  fetcher: FetchMethod<T[]>;
  initialQuery?: string;
  onQueryChange?: FormEventHandler<HTMLInputElement>;
  highlight?: string;
  onDelete?: DeleteMethod<T>;
  headline?: string;
  searchPlaceholder?: string;
  wrapperClassName?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  cached?: T[];
  children?: (result: UseFetcherReturn<T[]>, query: string) => ReactNode;
  onSearched?: (result: UseFetcherReturn<T[]>, query: string) => void;
  journalInfo?: JournalRep;
};

export const InputSelectSearchable = <T,>({
  valueToString = String,
  onDelete,
  headline,
  cached,
  initialQuery,
  fetcher,
  ...props
}: InputSelectSearchableProps<T>): ReactElement => {
  const [query, setQuery] = useState<string | undefined>(initialQuery);
  const [inputValue, setInputValue] = useState<string | undefined>(initialQuery);
  const result = useFetcher<T[]>({
    fetcher,
    query
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    query && props.onSearched?.(result, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const { items, getListProps, getInputProps, getOptionProps, getListContainerProps, isOpen, activeIndex, getContextInputProps } = useSelect<T>({
    ...props,
    items: (result.state === 'finished' && result.data) || (result.state === 'idle' && cached) || [],
    valueToString,
    mode: 'select'
  });

  const onSearch = (e: SyntheticEvent) => {
    e.preventDefault();
    setQuery(inputValue);
  };
  const journalInfoToString =
    props.journalInfo?.registrationNumber && props.journalInfo?.title && `${props.journalInfo.registrationNumber} - ${props.journalInfo.title}`;

  return (
    <div className={props.wrapperClassName}>
      <AMDHLDropdownButton
        {...getInputProps()}
        className={classNames({ placeholder: props.placeholder }, props.className)}
        wrapperClassName="flex-grow-1"
        placeholder={props.placeholder}
        error={!!props.error}
        value={(props.value && valueToString?.(props.value)) || journalInfoToString}
      />
      {!!props.error && (
        <div data-testid={props.name + '-error'} className="fieldNote error">
          {props.error}
        </div>
      )}
      <div className={selectClasses.anchor}>
        <div
          {...getListContainerProps()}
          className={classNames('dhlContextmenu', { 'dhlContextmenu-visible': isOpen }, multiListClasses.multiList, classes.contextMenu)}
        >
          <form onSubmit={onSearch} autoComplete="off">
            <InputGroup className={classes.searchRow}>
              <Input
                {...getContextInputProps()}
                wrapperProps={{
                  className: classes.searchInput
                }}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.currentTarget.value);
                  return props.onQueryChange?.(e);
                }}
                name={`${props.name}-search-input`}
                data-testid={`${props.name}-search-input`}
                placeholder={props.searchPlaceholder}
                label={props.searchPlaceholder}
                disabled={result.state === 'loading'}
              />
              <IconSearchButton
                icon="search"
                name={`${props.name}-search-button`}
                disabled={result.state === 'loading'}
                onClick={onSearch}
                data-testid={`${props.name}-search-button`}
              />
            </InputGroup>
          </form>
          {result.state === 'error' && (
            <div className={classNames(multiListClasses.item, multiListClasses.disabled)}>{props.children?.(result, query || '')}</div>
          )}
          {result.state === 'loading' && (
            <div className={classes.loadingWrapper}>
              <DHLLoading name={props.name + '-loading'} loading />
            </div>
          )}
          {result.state !== 'loading' && (
            <div {...getListProps()} className={classes.listBox}>
              <DHLContextmenuGroup headline={result.state === 'idle' ? headline : undefined}>
                {items.map((item, index) => (
                  <Item {...getOptionProps(item)} type="button" key={index} className={classes.selectItem} active={activeIndex === index}>
                    <HighlightedText highlighted={result.state === 'finished' ? query : undefined}>{valueToString?.(item)}</HighlightedText>
                    {result.state === 'idle' && (
                      <div
                        aria-label="Delete option"
                        role="button"
                        tabIndex={0}
                        className={classes.deleteButton}
                        data-testid={`${props.name}-context-menu-item-${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete?.(e, item, index);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Space') {
                            e.stopPropagation();
                            onDelete?.(e, item, index);
                          }
                        }}
                      >
                        <DHLIcon icon="close-thin" name={`delete-option-${index}`} data-testid={`${props.name}-context-menu-item-delete-${index}`} />
                      </div>
                    )}
                  </Item>
                ))}
              </DHLContextmenuGroup>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
