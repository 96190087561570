import React, { useLayoutEffect, useState, VFC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomTextInput } from '../CustomTextInput';
import { determineLetterTypeByFormat } from './helper/determineLetterTypeByFormat';
import { Button } from 'common/components/Button/Button';
import { QtyAllocation } from '../../context/order/dtos/Order';
import { OrderReference } from '../../context/order/dtos/OrderReference';
import { OrderMode } from '../../dtos/OrderMode';
import i18n from 'i18n';

import PlusIcon from 'assets/icons/plus.svg';

import styles from './qty-allocation.module.css';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';
import { LetterTypes } from './dtos/LetterTypes';
import { maxiBriefDiscountAvailable } from 'order/productGroups/additionalOrders/utils/maxiBriefDeliveryDiscountDiscontinuedDate';
import { useOrderContext } from 'order/common/context/order/OrderContext';

interface Props {
  data: QtyAllocation;
  orderReference: OrderReference | null;
  language: string;
  id: string;
  onChange: (productNumber?: string, qty?: number) => void;
  onError: (format: string, error: boolean) => void;
  onIdAssignmentClick: (productNumber: string, format: string) => void;
  orderMode?: OrderMode;
  disabled?: boolean;
  readOnly?: boolean;
  selectedDate?: Date;
}

export const QtyAllocationRow: VFC<Props> = (props) => {
  const { meta, order, orderMode } = useOrderContext();
  const { t } = useTranslation('shipmentAssignment');
  // const [disabled, setDisabled] = useState(!props.data.reducible || (props.data.initialQty || 0) === props.data.quantity);
  const [disabled, setDisabled] = useState(!props.data.reducible);
  const [error, setError] = useState(false);
  // const [errorTooManyIds, setErrorTooManyIds] = useState(false);
  const [value, setValue] = useState(props.data.initialQty ? props.data.quantity : 0);
  i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
  i18n.addResourceBundle('en', 'shipmentAssignment', transEN);
  // const letterType = determineLetterTypeByProductNumber(props.data.productNumber);
  const letterType = determineLetterTypeByFormat(props.data.format);
  /*
  const containsIds =
    props.orderReference?.includesDestinations ||
    props.orderReference?.containsIds === Occurrence.ALL ||
    props.orderReference?.containsIds === Occurrence.SOME;
   */
  // const enabledByDefault = useRef(props.orderReference?.new && !containsIds);

  useLayoutEffect(() => {
    /*
    if (!errorTooManyIds && ((props.data.idsQty && value < props.data.idsQty) || (props.data.initialIdsQty && value < props.data.initialIdsQty))) {
      setErrorTooManyIds(true);
      props.onError?.(props.data.format, true);
    } else if (errorTooManyIds) {
      setErrorTooManyIds(false);
      props.onError?.(props.data.format, false);
    }
     */
  }, [props.data.idsQty, value]);

  const handleOnClick = () => {
    props.onIdAssignmentClick(props.data.productNumber || '', props.data.format);
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = e.target.value;
    const qty = props.data.initialQty;
    if (isNaN(parseInt(enteredValue)) || enteredValue === '') {
      setValue(0);
      setError(false);
      setDisabled(true);
      props.onError?.(props.data.format, false);
      // } else if (parseInt(enteredValue) === 0 || parseInt(enteredValue) < 0) {
    } else if (parseInt(enteredValue) < 0) {
      setValue(0);
      setError(false);
      setDisabled(true);
      props.onError?.(props.data.format, false);
      props.onChange(props.data.productNumber, 0);
    } else if (qty && parseInt(enteredValue) > qty) {
      setValue(qty);
      setError(true);
      setDisabled(true);
      props.onError?.(props.data.format, true);
    } else {
      setValue(parseInt(enteredValue));
      setError(false);
      setDisabled(false);
      props.onError?.(props.data.format, false);
      props.onChange(props.data.productNumber, parseInt(enteredValue));
    }
  };

  const orderDateForOrderview = order?.orderDetail?.date ? new Date(order?.orderDetail?.date) : undefined;

  return (
    <>
      <tr id={props.id} data-testid={`TableRow-${props.id}`} key={props.id}>
        <td className={classNames(styles.letterTypes)}>
          {orderMode === OrderMode.VIEW ? (
            <p>
              {letterType === LetterTypes.Maxi && !maxiBriefDiscountAvailable(orderDateForOrderview) ? 'Maxibrief (Laufzeit E+1)' : t(letterType)}
            </p>
          ) : (
            <p>{letterType === LetterTypes.Maxi && !maxiBriefDiscountAvailable(meta?.pickUpDate) ? 'Maxibrief (Laufzeit E+1)' : t(letterType)}</p>
          )}
        </td>
        <td className={classNames(styles.qtyTD)}>{props.data.initialQty ? props.data.initialQty.toLocaleString(props.language) : '0'}</td>
        {props?.orderMode === OrderMode.CHANGE ? (
          <td className={classNames(styles.idsTD)}>{props.data.initialIdsQty ? props.data.initialIdsQty.toLocaleString(props.language) : '0'}</td>
        ) : null}
        {props.readOnly || props.disabled ? (
          <td className={classNames(styles.qtyTD)}>{props.data.quantity ? props.data.quantity.toLocaleString(props.language) : '0'}</td>
        ) : (
          <>
            <td>
              <CustomTextInput
                key={props.id}
                value={props.data.quantity ? props.data.quantity : value ? value : '0'}
                onBlur={handleOnBlur}
                type={'text'}
                id={`input-${props.id}-${letterType}`}
                min={'0'}
                testid={`input-${props.id}`}
                // disabled={props.data.initialQty === 0 || !props.data.initialQty || !props.data.reducible}
                disabled={!props.data.initialQty}
                rules={{ regex: /^[0-9]+$/ }}
                className={styles.qtyAllocationInput}
              />
            </td>
            <td className="pr-0">
              <Button
                label={t('addId')}
                key={props.id}
                sizing="xs"
                onClick={handleOnClick}
                className={classNames(styles.assignmentButton)}
                disabled={
                  props.data.multipleContainsIds || disabled /*
                  !enabledByDefault.current &&
                  (!props.data.reducible || !props.data.initialQty || (props.data.initialQty === value && !props.data.initialIdsQty) || disabled)
                */
                }
                dataTestId={`btn-${props.id}`}
              />
            </td>
          </>
        )}
      </tr>
      {error && (
        <tr className={styles.errorRow} data-testid={'qar-exceeded-qty-error'}>
          <td colSpan={4} className={styles.errorMessage}>
            {t('error.exceededQtyError')}
          </td>
        </tr>
      )}
      {/*
      errorTooManyIds && (
        <tr className={styles.errorRow} data-testid={'qar-id-assignment-exceeds-allocated-qty'}>
          <td colSpan={4} className={styles.errorMessage}>
            {t('error.idAssignmentExceedsAllocatedQty')}
          </td>
        </tr>
      )*/}
    </>
  );
};
