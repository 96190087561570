// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baseProductsSection_subHeading__Qlozy{
  font-weight: bold;
}

.baseProductsSection_wrapper__ZOb4y {
  position: relative;
  margin-top: 24px;
}

.baseProductsSection_disabledOverlay__HN\\+7i {
  position: absolute;
  width: 100%;
  height: calc(100% - 120px);
  opacity: .4;
  background: #f5f5f5;
  z-index: 2;
  top: 120px;
  border-radius: 6px;
}

.baseProductsSection_delete__r6IzE {
  position: absolute;
  right: 6px;
  top: -8px;
}`, "",{"version":3,"sources":["webpack://./src/order/productGroups/letter/components/BaseProductsSection/baseProductsSection.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,WAAW;EACX,mBAAmB;EACnB,UAAU;EACV,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX","sourcesContent":[".subHeading{\n  font-weight: bold;\n}\n\n.wrapper {\n  position: relative;\n  margin-top: 24px;\n}\n\n.disabledOverlay {\n  position: absolute;\n  width: 100%;\n  height: calc(100% - 120px);\n  opacity: .4;\n  background: #f5f5f5;\n  z-index: 2;\n  top: 120px;\n  border-radius: 6px;\n}\n\n.delete {\n  position: absolute;\n  right: 6px;\n  top: -8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeading": `baseProductsSection_subHeading__Qlozy`,
	"wrapper": `baseProductsSection_wrapper__ZOb4y`,
	"disabledOverlay": `baseProductsSection_disabledOverlay__HN+7i`,
	"delete": `baseProductsSection_delete__r6IzE`
};
export default ___CSS_LOADER_EXPORT___;
