import { useAlerts } from 'common/AlertContext';
import { DefaultDayRangePreset } from 'common/components/AMDHLDateRangeInput/DayRangePreset';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { deepClone } from 'common/utils/deepClone';
import { useSWRCache } from 'order/common/hooks/useSWRCache';
import { OrderSearchResultRepTDO } from 'order/common/services/OrderService';
import { validateOrderId } from 'order/orderSearch/components/filter/validate';
import { attributesEqual, DefaultAttribute, OrderSearchAttribute, sortAttributes } from 'order/orderSearch/services/orderSearchAttribute';
import {
  checkHistoryItemForFavourite,
  getSearchName,
  deduplicateColumnHistory,
  deduplicateSearchHistory,
  persistedColumnFn,
  validateHistoryItem
} from 'order/orderSearch/services/searchHistoryService';
import validateSearchAttributes from 'order/orderSearch/services/validateSearchAttributes';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertTypes } from '../../components/Alerts/dtos/AlertTypes';
import { HistoryItem } from './dtos/HistoryItem';
import { OrderSearchKey } from './dtos/OrderSearchKey';
import { OrderSearchType } from './dtos/OrderSearchType';
import { OrderTableConfiguration } from './dtos/OrderTableConfiguration';
import { SearchState } from './dtos/SearchState';
import { requestOrderSearchData } from './fetcher/requestOrderSearch';
import { PersistColumn, useSearchPersistence } from './hooks/useSearchPersistence';
import { useSearchTabs } from './hooks/useSearchTabs';
import { defaultSearch, defaultSearchState, SearchActions, searchReducer, SearchReducerState } from './reducers/search';
import { resolveMetaInfo } from './refineOrderSearchResult';
import { SearchContext } from './SearchContext';
import { addAttributes } from './utils/addAttributes';
import { processSearchResponse } from './utils/processSearchResponse';
import { addRecommendationsToExisting } from './utils/recommendations';
import { useOrderContext } from '../order/OrderContext';
import { DateRange } from './dtos/OrderSearchAttribute';
import { Filter } from 'common/components/Table/dtos/Filter';
import { Sort } from 'common/components/Table/dtos/Sort';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';
import { AxiosResponse } from 'axios';
import { OrderResultRep } from '../../../../generated';

export const SearchProvider: React.FC = (props) => {
  const { tab, tabIndex, onTabChange, getTab, initSearchTabs, searchTabs } = useSearchTabs();
  const { deletePartialKey } = useSWRCache();
  const [searchData, dispatchSearchData] = useReducer(searchReducer, defaultSearchState);
  const [persistenceLoaded, setPersistenceLoaded] = useState(false);
  const [persistenceColumnLoaded, setPersistenceColumnLoaded] = useState(false);
  const [myOrdersLoaded, setMyOrdersLoaded] = useState<boolean>(false);
  const [_, setUpdated] = useState<number>(new Date().getTime());
  const {
    getPersistedSearchFromServer,
    getPersistedCurrentSearches,
    persistCurrentSearches,
    persistSearchHistory,
    getPersistedColumnConfigFromServer,
    persistColumnConfig,
    getPersistedMyOrdersFromServer,
    persistMyOrders
  } = useSearchPersistence();
  const { user } = useAuthContext();
  const reducerState = useRef<SearchReducerState>(defaultSearchState);
  const newRecommendations = useRef<DefaultAttribute[]>([]);
  const runningSearches = useRef<string[]>([]);
  const editedAttribute = useRef<DefaultAttribute | undefined>(undefined);
  const { t } = useTranslation('orderSearch');
  const { addAlert, clear: clearAlerts } = useAlerts();
  const controller = useRef<AbortController | undefined>(undefined);
  const { order } = useOrderContext();
  const loadDataFromServer = async () => {
    // only in normal order search view, not in other searches (e.g. in order editing)
    switch (reducerState?.current?.currentType) {
      case OrderSearchType.SIMPLE:
      case OrderSearchType.PRESS:
      case OrderSearchType.ADVANCED:
        const history = await getPersistedSearchFromServer();
        if (history) {
          // reducerState.current.currentType = history.currentType;
          reducerState.current.persistedSearches = history.searchHistory;
          dispatchSearchData({ type: SearchActions.SET_CURRENT_TYPE, data: history.currentType });
          dispatchSearchData({ type: SearchActions.SET_PERSISTED_SEARCHES, data: history.searchHistory });
          setPersistenceLoaded(true);
        }
        break;

      default:
        break;
    }
  };
  const loadDataFromServerForColumns = async () => {
    const history = await getPersistedColumnConfigFromServer();
    setPersistenceColumnLoaded(true);
    if (history) {
      reducerState.current.persistedColumns = history;
      dispatchSearchData({ type: SearchActions.SET_PERSISTED_COLUMNS, data: history });
      const simple = persistedColumnFn(OrderSearchType.SIMPLE, reducerState.current.persistedColumns);
      simple && setTableConfiguration(simple);
    }
  };

  useEffect(() => {
    if (user.userName && user._accessToken) {
      loadDataFromServer();
      loadDataFromServerForColumns();
    }
    reinitSearch();
  }, [user.userName, user.extUserId]);

  const loadDataFromServerForMyOrders = async () => {
    if (!myOrdersLoaded) {
      const myOrders = await getPersistedMyOrdersFromServer();
      if (myOrders) {
        reducerState.current.myOrders = myOrders;
        dispatchSearchData({ type: SearchActions.SET_MYORDERS, data: myOrders });
      }
      setMyOrdersLoaded(true);
    }
  };

  const performUpdate = () => {
    dispatchSearchData({ type: SearchActions.SET_STATE, data: reducerState.current });
    setUpdated(new Date().getTime());
  };

  useEffect(() => {
    if (user.userName && user._accessToken) {
      loadDataFromServerForMyOrders();
    }
  }, [user.userName, user.extUserId]);

  const changeTab = (tab: OrderSearchType | number) => {
    const currentSearches = [...reducerState.current.currentSearches];
    const tabName = getTab(tab);
    let newCurrentSearch = currentSearches.find((s) => s.type === tabName);

    if (!newCurrentSearch) {
      newCurrentSearch = {
        ...defaultSearch,
        type: tabName as OrderSearchType
      };
    }
    onTabChange(tab);

    setCurrentSearches(newCurrentSearch);
    reducerState.current.currentType = tabName as OrderSearchType;
    reducerState.current.currentSearch = newCurrentSearch;
    if (
      reducerState.current.currentType !== OrderSearchType.MERGE &&
      reducerState.current.currentType !== OrderSearchType.PARTIAL &&
      reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
    ) {
      reducerState.current.previousSearch = newCurrentSearch;
    }
    if (newCurrentSearch.type) {
      reducerState.current.lastSearch = [newCurrentSearch.type, newCurrentSearch.attributes, newCurrentSearch.filter, newCurrentSearch.sort];
    }
    persistCurrentSearches(user, { currentSearches, currentType: tabName as OrderSearchType });
    const tableConfig = reducerState.current.currentSearch.tableConfig;
    switch (reducerState?.current?.currentType) {
      case OrderSearchType.SIMPLE:
        const simple = persistedColumnFn(reducerState?.current?.currentType, reducerState.current.persistedColumns);
        simple && tableConfig && (tableConfig.hidden = simple);
        break;
      case OrderSearchType.PRESS:
        const press = persistedColumnFn(reducerState?.current?.currentType, reducerState.current.persistedColumns);
        press && tableConfig && (tableConfig.hidden = press);
        break;
      case OrderSearchType.ADVANCED:
        const advanced = persistedColumnFn(reducerState?.current?.currentType, reducerState.current.persistedColumns);
        advanced && tableConfig && (tableConfig.hidden = advanced);
        break;
      default:
        break;
    }

    performUpdate();
  };

  const goToHistoryItem = (item: HistoryItem) => {
    const type = item.orderSearchState.type || OrderSearchType.SIMPLE;
    const newCurrentSearch: SearchState = {
      ...item.orderSearchState,
      attributes: item.orderSearchState.attributes || [],
      tableConfig: item.orderSearchState.tableConfig || {},
      type,
      orders: undefined,
      rawOrders: undefined,
      dirty: false,
      updated: true,
      active: true,
      loading: true
    };
    reducerState.current.currentType = type;
    reducerState.current.previousSearch = newCurrentSearch;
    reducerState.current.currentSearch = newCurrentSearch;
    setCurrentSearches(newCurrentSearch);
    onTabChange(type);
    resetResponseWithLoader();
    search(type, item.orderSearchState.attributes || []);
  };

  const addAttribute = (attribute: OrderSearchAttribute): OrderSearchAttribute[] | null => {
    const newAttributes = addAttributes(attribute, reducerState.current.currentSearch.attributes);

    if (newAttributes) {
      reducerState.current.currentSearch.attributes = newAttributes;
      reducerState.current.currentSearch.dirty = true;
      if (
        reducerState.current.currentType !== OrderSearchType.MERGE &&
        reducerState.current.currentType !== OrderSearchType.PARTIAL &&
        reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
      ) {
        reducerState.current.previousSearch = {
          ...reducerState.current.previousSearch,
          attributes: newAttributes
        };
      }
    }

    performUpdate();
    return newAttributes;
  };

  const removeAttribute = (attribute: OrderSearchAttribute): void => {
    if (attribute.key === OrderSearchKey.Date && reducerState.current.currentType !== OrderSearchType.SIMPLE) {
      const dateAttribute = reducerState.current.currentSearch.attributes.find(({ key }) => key === OrderSearchKey.Date);
      if (dateAttribute) {
        dateAttribute.value = DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS;
      }
      return;
    }

    if (!attribute.value) {
      return;
    }

    const currentSearch = { ...reducerState.current.currentSearch };
    const newAttributes = reducerState.current.currentSearch.attributes.filter((other) => !attributesEqual(other, attribute));

    reducerState.current.currentSearch = {
      ...currentSearch,
      dirty: true,
      updated: true,
      attributes: newAttributes
    };
    if (
      reducerState.current.currentType !== OrderSearchType.MERGE &&
      reducerState.current.currentType !== OrderSearchType.PARTIAL &&
      reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
    ) {
      reducerState.current.previousSearch = {
        ...reducerState.current.previousSearch,
        attributes: newAttributes
      };
    }
    performUpdate();
  };

  const reinitSearch = () => {
    // clear alerts
    clearAlerts();

    // init search data
    reducerState.current.currentType = OrderSearchType.SIMPLE;
    reducerState.current.currentSearch = { ...defaultSearch };
    reducerState.current.currentSearches = [];

    // reset search
    reset();
  };

  const reset = () => {
    if (controller.current) {
      controller.current?.abort();
    }
    const newCurrentSearch: SearchState = {
      ...defaultSearch,
      orders: undefined,
      rawOrders: undefined,
      attributes: [],
      dirty: false,
      updated: false,
      type: reducerState?.current?.currentType
    };
    const submitter = order?.parties?.find((p) => p.role === OrderSearchKey.Submitter)?.customerId;
    const originator = order?.parties?.find((p) => p.role === OrderSearchKey.Originator)?.customerId;
    const orderDate = order?.orderDetail?.date
      ? ({ start: new Date(order.orderDetail.date), end: new Date(order.orderDetail.date) } as DateRange)
      : ({ start: new Date(), end: new Date() } as DateRange);
    switch (reducerState?.current?.currentType) {
      case OrderSearchType.PRESS:
      case OrderSearchType.ADVANCED:
        newCurrentSearch.attributes.push({
          key: OrderSearchKey.Date,
          value: DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS
        });
        break;

      case OrderSearchType.PARTIAL:
        newCurrentSearch.attributes.push({
          key: OrderSearchKey.Date,
          value: DefaultDayRangePreset.TODAY
        });
        break;

      case OrderSearchType.MERGE:
        newCurrentSearch.attributes.push({
          key: OrderSearchKey.Date,
          value: orderDate
        });
        newCurrentSearch.attributes.push({
          key: OrderSearchKey.ProductionState,
          value: 'PLA'
        });
        submitter &&
          newCurrentSearch.attributes.push({
            key: OrderSearchKey.Submitter,
            value: submitter
          });
        originator &&
          newCurrentSearch.attributes.push({
            key: OrderSearchKey.Originator,
            value: originator
          });
        break;

      case OrderSearchType.COLLECTION_LETTER:
        newCurrentSearch.attributes.push({
          key: OrderSearchKey.Date,
          value: DefaultDayRangePreset.USER_DEFINED
        });
        break;

      default:
        break;
    }
    reducerState.current.currentSearch = newCurrentSearch;
    setCurrentSearches(newCurrentSearch);
    performUpdate();
  };

  const resetResponseWithLoader = () => {
    if (controller.current) {
      controller.current?.abort();
    }
    reducerState.current.currentSearch.orders = undefined;
    reducerState.current.loading = true;
    dispatchSearchData({ type: SearchActions.SET_LOADING, data: true });
    dispatchSearchData({ type: SearchActions.SET_CURRENT_SEARCH_ORDERS, data: undefined });
    performUpdate();
  };

  const search = async (type: OrderSearchType, attr: OrderSearchAttribute[], options?: { meta?: Record<string, any> }) => {
    const attributes = deepClone(attr || []);
    const searchAttributes = deepClone(attributes || []);
    // set special search attrs
    switch (type) {
      case OrderSearchType.PARTIAL:
        searchAttributes.push({
          key: OrderSearchKey.OrderCategoryGroup,
          value: 'BRIEF'
        });
        searchAttributes.push({
          key: OrderSearchKey.OrderCategories,
          value: 'AFM_BRIEF'
        });
        searchAttributes.push({
          key: OrderSearchKey.OrderCategories,
          value: 'DV_BRIEF'
        });
        break;

      case OrderSearchType.COLLECTION_LETTER:
        searchAttributes.push({
          key: OrderSearchKey.V_SEARCH_MODE,
          value: 'ABHOLAUFTRAG'
        });
        break;

      case OrderSearchType.MERGE:
        searchAttributes.push({
          key: OrderSearchKey.V_SEARCH_MODE,
          value: OrderSearchType.MERGE
        });
        break;

      default:
        break;
    }
    if (attributes && searchAttributes && attributes.length && searchAttributes.length) {
      dispatchSearchData({ type: SearchActions.SET_LOADING, data: true });
      reducerState.current.loading = true;
      reducerState.current.currentSearch.loading = true;
      runningSearches.current.push(type);
      performUpdate();
      // adjust last search only in normal order search view, not in other searches (e.g. in order editing)
      switch (type) {
        case OrderSearchType.SIMPLE:
        case OrderSearchType.PRESS:
        case OrderSearchType.ADVANCED:
          reducerState.current.lastSearch = [type, searchAttributes, undefined, undefined];
          break;

        default:
          break;
      }

      const data = await sendRequest(type, searchAttributes, options?.meta);
      processResponse(type, attributes, data);
    }
  };

  /**
   * Send search request
   */
  const sendRequest = async (
    searchType: OrderSearchType,
    attributes: OrderSearchAttribute[],
    meta: Record<string, any> = {}
  ): Promise<OrderSearchResultRepTDO | undefined> => {
    deletePartialKey('getOrder');
    controller.current = new AbortController();

    return await requestOrderSearchData(searchType, attributes, meta, controller.current);
    // reducerState.current.currentSearch.rawOrders = data;
    // reducerState.current.currentSearch.orders = data;
    // return data;
  };

  /**
   * Process search response
   *
   * @param type OrderSearchType
   * @param attributes OrderSearchAttribute[]
   * @param rawOrders OrderSearchResultRepTDO
   */
  const processResponse = (type: OrderSearchType, attributes: OrderSearchAttribute[], rawOrders: OrderSearchResultRepTDO | undefined) => {
    if (rawOrders?.resultStatus === '500') {
      reducerState.current.loading = false;
      reducerState.current.currentSearch.loading = false;
      reducerState.current.currentSearches.forEach((c) => (c.loading = false));
      runningSearches.current = [];
      dispatchSearchData({ type: SearchActions.SET_LOADING, data: false });
      performUpdate();
      return;
    }
    let searchResponse = deepClone(rawOrders || {});
    const modifiedData = processSearchResponse({
      attributes,
      response: searchResponse,
      searchType: type
    });
    let newAttributes = attributes;

    if (searchResponse?.orders?.length) {
      const [attributesWithMeta, ordersWithMeta] = resolveMetaInfo(attributes, searchResponse?.orders);
      searchResponse = {
        ...searchResponse,
        orders: ordersWithMeta
      };
      newAttributes = attributesWithMeta;

      // add search to history only in normal order search view, not in other searches (e.g. in order editing)
      switch (type) {
        case OrderSearchType.SIMPLE:
        case OrderSearchType.PRESS:
        case OrderSearchType.ADVANCED:
          adjustSearchHistory(type, rawOrders, newAttributes);
          break;

        default:
          break;
      }
    }

    const requestedSearch =
      reducerState.current.currentSearch.type === type
        ? reducerState.current.currentSearch
        : reducerState.current.currentSearches.find((c) => c.type === type);
    const newRequestedSearch: any = {
      ...(requestedSearch || {}),
      ...searchResponse,
      updated: true,
      dirty: reducerState.current.currentSearch.orders?.resultStatus === '504',
      orders: modifiedData,
      rawOrders,
      attributes: newAttributes,
      loading: false
    };
    addRecommendations(newRequestedSearch);

    if (reducerState.current.currentSearch.type === type) {
      reducerState.current.currentSearch = newRequestedSearch;
      if (
        reducerState.current.currentType !== OrderSearchType.MERGE &&
        reducerState.current.currentType !== OrderSearchType.PARTIAL &&
        reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
      ) {
        reducerState.current.previousSearch = newRequestedSearch;
        reducerState.current.currentSearch.filter = undefined;
        reducerState.current.currentSearch.sort = undefined;
      }
    } else if (reducerState.current.currentSearches.find((c) => c.type === type)) {
      reducerState.current.currentSearches.map((c) => {
        if (c.type === type) {
          return newRequestedSearch;
        }

        return c;
      });
    }
    setCurrentSearches(newRequestedSearch);
    runningSearches.current = runningSearches.current.filter((s) => s !== type);
    reducerState.current.loading = false;
    dispatchSearchData({ type: SearchActions.SET_LOADING, data: false });
    performUpdate();

    // TODO find better solution after search rework
    if (reducerState.current.currentSearch.orders?.resultStatus === '504') {
      addAlert({
        type: AlertTypes.Warning,
        title: 'Fehler bei der Suchabfrage',
        description: 'Während der Suchabfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
      });
    }
  };

  /**
   * Set column filter
   *
   * @param filter
   */
  const setColumnFilter = (filter?: Filter) => {
    const currentSearches = [...reducerState.current.currentSearches];
    let newCurrentSearch = currentSearches.find((s) => s.type === filter?.type);
    reducerState.current.currentSearches.map((c) => {
      if (c.type === filter?.type) {
        c.filter = filter;
      }
    });
    if (!newCurrentSearch) {
      newCurrentSearch = {
        ...defaultSearch,
        filter: filter
      };
    }
    setCurrentSearches(newCurrentSearch);
    if (newCurrentSearch.type) {
      reducerState.current.lastSearch = [newCurrentSearch.type, newCurrentSearch.attributes, newCurrentSearch.filter, newCurrentSearch.sort];
    }
    reducerState.current.currentSearch = newCurrentSearch;
    if (
      reducerState.current.currentType !== OrderSearchType.MERGE &&
      reducerState.current.currentType !== OrderSearchType.PARTIAL &&
      reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
    ) {
      reducerState.current.previousSearch = newCurrentSearch;
    }
    performUpdate();
  };

  /**
   * Set column sorting
   *
   * @param sort
   */
  const setColumnSorting = (sort?: Sort) => {
    const currentSearches = [...reducerState.current.currentSearches];
    let newCurrentSearch = currentSearches.find((s) => s.type === sort?.searchType);
    reducerState.current.currentSearches.map((c) => {
      if (c.type === sort?.searchType) {
        c.sort = sort;
      }
    });
    if (!newCurrentSearch) {
      newCurrentSearch = {
        ...defaultSearch,
        sort: sort
      };
    }
    setCurrentSearches(newCurrentSearch);
    if (newCurrentSearch.type) {
      reducerState.current.lastSearch = [newCurrentSearch.type, newCurrentSearch.attributes, newCurrentSearch.filter, newCurrentSearch.sort];
    }
    reducerState.current.currentSearch = newCurrentSearch;
    if (
      reducerState.current.currentType !== OrderSearchType.MERGE &&
      reducerState.current.currentType !== OrderSearchType.PARTIAL &&
      reducerState.current.currentType !== OrderSearchType.COLLECTION_LETTER
    ) {
      reducerState.current.previousSearch = newCurrentSearch;
    }
    performUpdate();
  };

  /**
   * Set current values from history
   *
   * @param history
   */
  const setCurrentSearchValueFromHistory = (history?: HistoryItem): Promise<HistoryItem | undefined> => {
    return new Promise((resolve) => {
      if (history) {
        const type = history.orderSearchState.type || OrderSearchType.SIMPLE;
        const newCurrentSearch: SearchState = {
          attributes: history?.orderSearchState.attributes || [],
          id: history.id,
          tableConfig: history?.orderSearchState.tableConfig || {},
          type,
          dirty: false,
          active: true,
          updated: true,
          loading: true
        };
        reducerState.current.currentType = type;
        reducerState.current.currentSearch = newCurrentSearch;
        performUpdate();
        resolve(history);
      }
      performUpdate();
      resolve(undefined);
    });
  };

  /**
   * create history item
   * @param type OrderSearchType
   * @param attributes OrderSearchAttribute[]
   * @param tableConfig
   */
  const createHistoryItem = (type: OrderSearchType, attributes: OrderSearchAttribute[], tableConfig?: OrderTableConfiguration) => {
    const id = new Date().getTime().toString();
    const orderSearchState = {
      attributes,
      tableConfig: reducerState.current.currentSearch.tableConfig,
      type
    };
    const isFavourite = checkHistoryItemForFavourite(orderSearchState, reducerState.current.persistedSearches);
    const searchName = getSearchName(orderSearchState, reducerState.current.persistedSearches);
    return {
      id,
      orderSearchState,
      isFavourite: isFavourite,
      dateCreated: new Date(),
      table: tableConfig || reducerState.current.currentSearch.tableConfig,
      searchName: searchName
    };
  };

  const createHistory = (historyItem: HistoryItem) => {
    const MAX_HISTORY_ITEMS = 40;
    const MAX_FAVORITES = 20;
    // First add the new history item
    let updatedHistory = [...reducerState.current.persistedSearches, historyItem];

    // Then, deduplicate
    updatedHistory = deduplicateSearchHistory(historyItem, updatedHistory);

    // Check and remove extra favorites if there are more than MAX_FAVORITES
    let favorites = updatedHistory.filter((item) => item.isFavourite);
    if (favorites.length > MAX_FAVORITES) {
      // Sort favorites by date, oldest first
      favorites.sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());
      // Remove the oldest favorites
      favorites = favorites.slice(favorites.length - MAX_FAVORITES);
      // Merge the trimmed favorites with non-favorites
      updatedHistory = [...favorites, ...updatedHistory.filter((item) => !item.isFavourite)];
    }

    // If the history exceeds the max, remove the oldest entries
    if (updatedHistory.length > MAX_HISTORY_ITEMS) {
      // Sort the non-favorite items by date, oldest first
      const sortedNonFavorites = updatedHistory
        .filter((item) => !item.isFavourite)
        .sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());

      // Calculate the number of non-favorite items to remove
      const itemsToRemove = updatedHistory.length - MAX_HISTORY_ITEMS;

      // Get the oldest non-favorite items that will be removed
      const nonFavoritesToRemove = sortedNonFavorites.slice(0, itemsToRemove);

      // Remove the oldest non-favorite items from the history
      updatedHistory = updatedHistory.filter((item) => item.isFavourite || !nonFavoritesToRemove.includes(item));
    }

    reducerState.current.persistedSearches = updatedHistory;
  };

  /**
   * triggered when search is being updated
   */
  const adjustSearchHistory = async (
    type: OrderSearchType,
    rawOrders: OrderSearchResultRepTDO | undefined,
    attributes: OrderSearchAttribute[],
    tableConfig?: OrderTableConfiguration
  ) => {
    const historyItem: HistoryItem = createHistoryItem(type, attributes, tableConfig);
    const attributelength =
      OrderSearchType.SIMPLE === historyItem.orderSearchState.type || historyItem.orderSearchState.type === OrderSearchType.PRESS
        ? historyItem.orderSearchState.attributes.length
        : historyItem.orderSearchState.attributes.length > 1;
    if (validateHistoryItem(historyItem) && attributelength) {
      createHistory(historyItem);
      updateSearchHistory(type, rawOrders, reducerState.current.persistedSearches, historyItem.id);
    }
    performUpdate();
  };

  /**
   * Function updateSearchHistory triggered to update search history
   *
   * @param type OrderSearchType
   * @param rawOrders OrderSearchResultRepTDO | undefined
   * @param history HistoryItem[]
   * @param id string (optional)
   */
  const updateSearchHistory = (type: OrderSearchType, rawOrders: OrderSearchResultRepTDO | undefined, history: HistoryItem[], id?: string) => {
    if (rawOrders) {
      persistSearchHistory({
        currentSearchId: id,
        searchHistory: history,
        currentType: type,
        orders: rawOrders
      });
    }
    reducerState.current.persistedSearches = history;
    dispatchSearchData({ type: SearchActions.SET_PERSISTED_SEARCHES, data: history });
  };

  /**
   * Function updateColumnHistory triggered to update column history
   *
   * @param history Persited[]
   * @param id string (optional)
   */
  const updateColumnHistory = (historyItem: PersistColumn) => {
    if (historyItem) {
      // preventing duplicates for columns
      // const col: any[] = [];
      // const columnlist = historyItem.columnConfig.filter((a) => {
      //   const deduplicate = col.includes(a.property);
      //   if (!deduplicate) {
      //     col.push(a.property);
      //     return true;
      //   }
      //   return false;
      // });
      // const updatedcol = { currentType: historyItem.currentType, columnConfig: columnlist };
      let updatedHistory = [...reducerState.current.persistedColumns, historyItem];
      updatedHistory = deduplicateColumnHistory(historyItem, updatedHistory);
      persistColumnConfig(updatedHistory);
      reducerState.current.persistedColumns = updatedHistory;
      dispatchSearchData({ type: SearchActions.SET_PERSISTED_COLUMNS, data: updatedHistory });
    }
  };

  /**
   * Function triggerSearchHistoryUpdate triggered to update search history
   *
   * @param history HistoryItem[]
   * @param id string (optional)
   */
  const triggerSearchHistoryUpdate = (history: HistoryItem[], id?: string) => {
    persistSearchHistory({
      currentSearchId: id,
      searchHistory: history,
      currentType: reducerState.current.currentType,
      orders: reducerState.current.currentSearch.rawOrders
    });
    reducerState.current.persistedSearches = history;
    dispatchSearchData({ type: SearchActions.SET_PERSISTED_SEARCHES, data: history });
  };

  /**
   * Replaces attribute for current search
   *
   * @param attribute OrderSearchAttribute
   * @param addMissing bool
   */
  const replaceAttribute = (attribute: OrderSearchAttribute, addMissing?: boolean): OrderSearchAttribute[] | null => {
    let newAttributes = [...reducerState.current.currentSearch.attributes];
    if (addMissing && !newAttributes.find((a) => a.key === attribute.key)) {
      newAttributes = [...newAttributes, attribute];
    } else {
      newAttributes = newAttributes.map((a) => {
        if (a.key === attribute.key) {
          return attribute;
        }
        return a;
      });
    }
    if (newAttributes) {
      reducerState.current.currentSearch.attributes = newAttributes;
      reducerState.current.currentSearch.dirty = true;

      dispatchSearchData({ type: SearchActions.SET_CURRENT_SEARCH_ATTRIBUTES, data: newAttributes });
      setPreviousState();
    }

    performUpdate();
    return newAttributes;
  };

  /**
   * Add attributes for current search
   *
   * @param type
   * @param attribute OrderSearchAttribute
   */
  const triggerSearchForAttribute = (type: OrderSearchType, attribute: OrderSearchAttribute): void => {
    onTabChange(type);
    changeTab(type);
    reset();
    const newAttributes = addAttributes(attribute, []);
    reducerState.current.currentType = type;
    reducerState.current.currentSearch.attributes = newAttributes || [];
    setPreviousState();
    performUpdate();
    search(type, newAttributes || []);
  };

  const triggerSearchWithAttributes = (type: OrderSearchType, attributes: OrderSearchAttribute[]): void => {
    onTabChange(type);
    changeTab(type);
    reset();
    const newAttributes: OrderSearchAttribute[] = [];
    attributes?.forEach((attr) => {
      if (addAttributes(attr, [])) {
        newAttributes.push(attr);
      }
    });
    reducerState.current.currentType = type;
    reducerState.current.currentSearch.attributes = newAttributes || [];
    setPreviousState();
    performUpdate();
    search(type, newAttributes || []);
  };

  const triggerLastSearch = (): void => {
    clearAlerts();
    const type = reducerState.current.lastSearch[0];
    const attributes = reducerState.current.lastSearch[1];
    reducerState.current.currentType = reducerState.current.previousSearch.type || type;
    reducerState.current.currentSearch.attributes = reducerState.current.previousSearch.attributes || attributes || [];
    reducerState.current.currentSearch.rawOrders = reducerState.current.previousSearch.rawOrders;
    reducerState.current.currentSearch.orders = reducerState.current.previousSearch.orders;
    reducerState.current.currentSearch.filter = reducerState.current.previousSearch.filter;
    reducerState.current.currentSearch.sort = reducerState.current.previousSearch.sort;
    reducerState.current.currentSearch.type = reducerState.current.previousSearch.type;
    setPreviousState();
    performUpdate();
  };

  /**
   * Edit attribute for current search
   *
   * @param attribute
   */
  const editAttribute = (attribute: DefaultAttribute): void => {
    editedAttribute.current = attribute;
    removeAttribute(attribute);
    setPreviousState();
    performUpdate();
  };

  /**
   * Set previous state for look-up of changes
   */
  const setPreviousState = () => {
    reducerState.current.previousSearch = {
      type: reducerState.current.previousSearch.type,
      tableConfig: reducerState.current.previousSearch.tableConfig,
      attributes: reducerState.current.previousSearch.attributes,
      orders: reducerState.current.previousSearch.orders,
      rawOrders: reducerState.current.previousSearch.rawOrders,
      loading: false,
      active: reducerState.current.previousSearch.active,
      filter: reducerState.current.previousSearch.filter,
      sort: reducerState.current.previousSearch.sort
    };
  };

  const setCurrentSearches = (newSearch: SearchState) => {
    const searches = [...reducerState.current.currentSearches];
    const newSearches = searches.filter((s) => s.type !== newSearch.type);
    newSearches.push(newSearch);
    reducerState.current.currentSearches = newSearches;
    dispatchSearchData({ type: SearchActions.SET_CURRENT_SEARCHES, data: reducerState.current.currentSearches });
    persistCurrentSearches(user, {
      currentSearches: reducerState.current.currentSearches,
      currentType: reducerState.current.currentType
    });
  };

  /**
   * Add recommendations
   */
  const addRecommendations = (currentSearch: SearchState): void => {
    newRecommendations.current = addRecommendationsToExisting(currentSearch, currentSearch.orders);
  };

  /**
   * Check whether mandatory attributes for current search type are present
   */
  const mandatoryAttributesPresent = () => {
    return validateSearchAttributes(reducerState.current.currentType, reducerState.current.currentSearch.attributes);
  };

  /**
   * Sort attributes for current search
   */
  const sortedAttributes = (): OrderSearchAttribute[] => reducerState.current.currentSearch.attributes.slice().sort(sortAttributes);

  /**
   * Set search type for the provided value
   *
   * @param searchType OrderSearchType
   */
  const setSearchType = (searchType: OrderSearchType) => {
    reducerState.current.currentType = searchType;
    performUpdate();
  };

  /**
   * Set cancel state for order status to provided value
   *
   * @param status string | undefined
   */
  const setCancelOrderStatus = (status: string | undefined, orderNumber: string | undefined) => {
    reducerState.current.cancelOrderStatus = status;
    const arr = reducerState.current.currentSearch.orders?.orders ? reducerState.current.currentSearch.orders.orders : [];
    const rawArr = reducerState.current.currentSearch.rawOrders?.orders ? reducerState.current.currentSearch.rawOrders?.orders : [];
    if (arr && rawArr) {
      if (reducerState.current.currentSearch.orders) {
        if (status?.toString().toUpperCase() === 'OK' || status?.toString().toUpperCase() === 'WARNING') {
          arr.forEach((order: any) => {
            if (orderNumber === order.data.orderNumber) {
              order.data.state = 'CAN';
            }
          });
        }
      }

      if (reducerState.current.currentSearch.rawOrders) {
        if (status?.toString().toUpperCase() === 'OK' || status?.toString().toUpperCase() === 'WARNING') {
          rawArr.forEach((order: any) => {
            if (orderNumber === order.data.orderNumber) {
              order.data.state = 'CAN';
            }
          });
        }
      }
    }

    // Handling back to search -- cancel status in result table from order view
    const prevOrders = reducerState.current.previousSearch.orders?.orders ? reducerState.current.previousSearch.orders.orders : [];
    const prevRawOrders = reducerState.current.previousSearch.rawOrders?.orders ? reducerState.current.previousSearch.rawOrders?.orders : [];
    if (prevOrders && prevRawOrders) {
      if (reducerState.current.previousSearch.orders) {
        if (status?.toString().toUpperCase() === 'OK' || status?.toString().toUpperCase() === 'WARNING') {
          prevOrders.forEach((order: any) => {
            if (orderNumber === order.data.orderNumber) {
              order.data.state = 'CAN';
            }
          });
        }
      }

      if (reducerState.current.previousSearch.rawOrders) {
        if (status?.toString().toUpperCase() === 'OK' || status?.toString().toUpperCase() === 'WARNING') {
          prevRawOrders.forEach((order: any) => {
            if (orderNumber === order.data.orderNumber) {
              order.data.state = 'CAN';
            }
          });
        }
      }
    }
    performUpdate();
  };

  /**
   * Set table configuration for current search
   *
   * @param newConfig Record<string, boolean>
   */
  const setTableConfiguration = (newConfig: Record<string, boolean>): void => {
    const config = { ...reducerState.current };
    config.currentSearch = {
      ...config.currentSearch,
      tableConfig: {
        ...config.currentSearch.tableConfig,
        hidden: {
          ...(config.currentSearch.tableConfig?.hidden || {}),
          ...newConfig
        }
      }
    };
    reducerState.current.currentSearch = config.currentSearch;
    adjustSearchHistory(
      reducerState.current.currentSearch.type || OrderSearchType.SIMPLE,
      reducerState.current.currentSearch.rawOrders,
      reducerState.current.currentSearch.attributes,
      config.currentSearch.tableConfig
    );
  };

  const triggerAlert = (orderResponse: any) => {
    if (orderResponse?.resultStatus) {
      addAlert({
        type: AlertTypes.Success,
        title: t(`orderSearchSuccessTitle`),
        description: t(`orderSearchSuccessMessage`) + orderResponse?.orderId
      });
    }
    const parseResult = validateOrderId(orderResponse?.orderId);
    if (parseResult) {
      const orderAttr: DefaultAttribute = {
        key: OrderSearchKey.OrderId,
        value: orderResponse?.orderId
      };
      triggerSearchForAttribute(OrderSearchType.SIMPLE, orderAttr);
    }
    if (orderResponse?.resultStatus?.toString().toUpperCase() === AlertTypes.Warning.toUpperCase()) {
      const warningAlerts = orderResponse.amErrors?.map((error: { code: string; errorCategory: string; errorDescription: any }) => ({
        title: t('warning') + ' ' + error.code + '!',
        description: error.errorDescription
      }));
      warningAlerts.forEach((alert: { title: string | undefined; description: string | undefined }) => {
        addAlert({
          type: AlertTypes.Warning,
          title: alert?.title,
          description: alert?.description
        });
      });
    }
  };

  const triggerMultipleAlerts = (orderResponse: AxiosResponse<OrderResultRep>[]) => {
    const orderAttr: DefaultAttribute[] = [];
    orderResponse.forEach((response) => {
      if (response?.data.resultStatus?.toString().toUpperCase() !== AlertTypes.Error.toUpperCase()) {
        addAlert({
          type: AlertTypes.Success,
          title: t(`orderSearchSuccessTitle`),
          description: t(`orderSearchSuccessMessage`) + response?.data.orderId
        });
        if (response?.data.orderId && validateOrderId(response?.data.orderId)) {
          orderAttr.push({
            key: OrderSearchKey.OrderId,
            value: response?.data.orderId
          });
        }
      }
    });
    orderResponse.forEach((response) => {
      if (response?.data.resultStatus?.toString().toUpperCase() === AlertTypes.Error.toUpperCase()) {
        const errorAlerts = response?.data?.amErrors?.map((error) => ({
          title: `${error.errorCategory} ${error.code}!`,
          description: error?.errorDescription?.toString()
        }));
        errorAlerts?.forEach((alert: { title: string | undefined; description: string | undefined }) => {
          addAlert({
            type: AlertTypes.Error,
            title: alert?.title,
            description: alert?.description
          });
        });
      }
    });
    orderResponse.forEach((response) => {
      if (response?.data.resultStatus?.toString().toUpperCase() === AlertTypes.Warning.toUpperCase()) {
        const warningAlerts = response?.data?.amErrors?.map((error) => ({
          title: t('warning') + ' ' + error.code + '!',
          description: error.errorDescription
        }));
        warningAlerts?.forEach((alert: { title: string | undefined; description: string | undefined }) => {
          addAlert({
            type: AlertTypes.Warning,
            title: alert?.title,
            description: alert?.description
          });
        });
      }
    });
    if (orderAttr.length) {
      triggerSearchWithAttributes(OrderSearchType.PRESS, orderAttr);
    }
  };

  const upsertMetaData = useCallback((key: string, value: unknown) => {
    dispatchSearchData({ type: SearchActions.UPSERT_META_DATA, data: { key, value } });
  }, []);

  const updateMyOrders = (data: MyOrder, mode: 'ADD' | 'DELETE' | 'UPDATE') => {
    const myOrders = reducerState.current?.myOrders || [];
    switch (mode) {
      case 'ADD':
        const newList = [...myOrders, data]?.sort((a, b) => (a?.sortingDate && b?.sortingDate ? b.sortingDate - a.sortingDate : 0))?.slice(0, 40);
        reducerState.current.myOrders = newList;
        dispatchSearchData({ type: SearchActions.SET_MYORDERS, data: newList });
        persistMyOrders(newList);
        break;

      case 'DELETE':
        const newMyOrderList = myOrders.filter((item) => item.id !== data.id);
        reducerState.current.myOrders = newMyOrderList;
        dispatchSearchData({ type: SearchActions.SET_MYORDERS, data: newMyOrderList });
        persistMyOrders(newMyOrderList);
        break;

      case 'UPDATE':
        let itemToBeUpdatedIndex = -1;
        const myo = myOrders.find((item, index) => {
          if (item.id === data.id) {
            itemToBeUpdatedIndex = index;
            return item;
          }
        });

        myOrders[itemToBeUpdatedIndex] = { ...data, creationDate: myo?.creationDate };
        reducerState.current.myOrders = myOrders;
        dispatchSearchData({ type: SearchActions.SET_MYORDERS, data: myOrders });
        persistMyOrders(myOrders);
        break;

      default:
        break;
    }
  };

  return (
    <SearchContext.Provider
      value={{
        activeSearchUpdated: reducerState.current.currentSearch.updated || false,
        activeSearchDifferent: reducerState.current.currentSearch.dirty || false,
        attributes: reducerState.current.currentSearch.attributes,
        cancelOrderStatus: reducerState.current.cancelOrderStatus,
        myOrdersLoaded,
        changeTab,
        addAttribute,
        editAttribute,
        editedAttribute: editedAttribute.current,
        goToHistoryItem,
        initSearchTabs,
        loading: runningSearches.current.indexOf(reducerState.current.currentSearch.type || '') >= 0,
        mandatoryAttributesPresent,
        newRecommendations: newRecommendations.current,
        persistenceLoaded,
        persistenceColumnLoaded,
        rawResponse: reducerState.current.currentSearch.rawOrders,
        removeAttribute,
        replaceAttribute,
        response: reducerState.current.currentSearch.orders,
        reset,
        search,
        searchHistory: searchData.persistedSearches,
        columnHistory: searchData.persistedColumns,
        searchTabs,
        searchType: reducerState.current.currentType,
        setCancelOrderStatus,
        setSearchType,
        setCurrentSearchValueFromHistory,
        setTableConfiguration,
        sortedAttributes,
        tab,
        tabIndex,
        tableConfiguration: reducerState.current.currentSearch.tableConfig,
        triggerSearchForAttribute,
        triggerSearchWithAttributes,
        triggerLastSearch,
        triggerSearchHistoryUpdate,
        updateSearchHistory,
        updateColumnHistory,
        triggerAlert,
        triggerMultipleAlerts,
        metadata: searchData.metadata,
        upsertMetaData,
        columnFilter: reducerState.current.currentSearch.filter,
        setColumnFilter,
        columnSorting: reducerState.current.currentSearch.sort,
        setColumnSorting,
        updateMyOrders,
        myOrders: reducerState.current.myOrders || []
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
