import { inRange } from 'common/utils/helpers';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

export const useEventListener = <K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions,
  active: boolean = true
): void =>
  useEffect(() => {
    if (active) {
      document.addEventListener(type, listener, options);
      return () => {
        document.removeEventListener(type, listener);
      };
    } else {
      return () => {};
    }
  }, [active, listener, options, type]);

export const useRefEventListener = <K extends keyof DocumentEventMap>(
  type: K,
  refs: React.MutableRefObject<Element | null>[],
  listener: (ev: DocumentEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions,
  active: boolean = true
): void => useEventListener(type, (event) => refs.some((ref) => ref?.current?.contains(event.target as Node)) && listener(event), options, active);

/**
 * Creates a handler to catch mousedown events outside of the elements referred to by
 * their respective {@link React.Ref}s. The active flag adds a flexible activation mechanism of this hook.
 *
 * @param refs list of references of "inside" elements
 * @param handleOutsideClick mousedown handler
 * @param active flag to de-/activate hooks
 */
export const useClickOutside = (
  refs: React.MutableRefObject<Element | null>[],
  handleOutsideClick: (event: MouseEvent) => void,
  active: boolean = true
): void =>
  useEventListener(
    'mousedown',
    (event: MouseEvent) => {
      if (refs.every((ref) => !ref?.current?.contains(event.target as Node))) {
        handleOutsideClick(event);
      }
    },
    {},
    active
  );

export const useKeyPress = (
  refs: React.MutableRefObject<Element | null>[],
  keys: string[],
  handleKeyClick: (event: KeyboardEvent) => void,
  active: boolean = true
): void =>
  useRefEventListener(
    'keydown',
    refs,
    (event: KeyboardEvent) => (keys.includes(event.code) || keys.includes(event.key)) && handleKeyClick(event),
    {},
    active
  );

/**
 * Holds the previous state of a react state variable.
 *
 * This is done with the useEffect hooks. It runs __after__ every render or state change.
 * The state already changed before the render thus passing the updated state while the value in the created {@code useRef} persists until its
 * updated after the render.
 *
 * @template T
 * @param {T} value state variable to store
 * @return {T | undefined} the previous state
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export type UseCounterReturn = {
  counter: number;
  setCounter: Dispatch<SetStateAction<number>>;
  increase: (delta?: number) => void;
  decrease: (delta?: number) => void;
};
export const useCounter = (initialValue: number = 0, minValue?: number, maxValue?: number): UseCounterReturn => {
  const [value, setValue] = useState(initialValue);

  const setCounter = useCallback(
    (counter: number | ((previousCounter: number) => number)): void => {
      const newValue = typeof counter === 'function' ? counter(value) : counter;
      if (inRange(newValue, minValue, maxValue)) {
        setValue(counter);
      }
    },
    [maxValue, minValue, value]
  );

  const increase = useCallback(
    (delta: number = 1) => {
      setCounter(value + delta);
    },
    [setCounter, value]
  );

  const decrease = useCallback(
    (delta: number = 1) => {
      increase(-delta);
    },
    [increase]
  );

  return {
    counter: value,
    setCounter,
    increase,
    decrease
  };
};
