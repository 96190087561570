import { ProductionFacility } from 'order/productGroups/common/services/ProductionFacilitiesService';

export const sortProductionFacilities = (a: ProductionFacility, b: ProductionFacility): number => {
  if (a.catalogId?.startsWith('GrASt') && b.catalogId?.startsWith('GrASt')) {
    return (a.address?.postCode || '')?.localeCompare(b.address?.postCode || '');
  }
  if (a.catalogId?.startsWith('DEPOT') && b.catalogId?.startsWith('DEPOT')) {
    return (a.catalogId || '')?.localeCompare(b.catalogId || '');
  }
  if (a.catalogId?.startsWith('SLN') && b.catalogId?.startsWith('SLN')) {
    return (a.catalogId || '')?.localeCompare(b.catalogId || '');
  }
  if (a.catalogId?.startsWith('GrASt')) {
    return -1;
  }
  if (a.catalogId?.startsWith('DEPOT') && b.catalogId?.startsWith('SLN')) {
    return -1;
  }
  return 1;
};
