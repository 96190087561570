import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DHLCheckbox, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { Accordion } from 'common/components/Accordion';
import { Button } from 'common/components/Button';
import { FormRow } from 'common/components/FormRow';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { AddressSection } from 'order/productGroups/common/components/molecule/AddressSection';
import { ContactSection } from 'order/productGroups/common/components/molecule/ContactSection';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import { PackagingSection } from 'order/productGroups/common/components/molecule/PackagingSection';
import { TimeCitySection } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { JournalInput } from 'order/productGroups/press/components/JournalInput';
import {
  PressBaseProduct,
  PressBaseProductVariant,
  PressBasicProduct,
  PressOrderCreate,
  validatePressOrderCreate
} from 'order/productGroups/press/schema/pressSchema';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { usePressDistributionBaseProducts } from 'order/common/hooks/usePressDistributionBaseProducts';
import { BaseProductRegion } from 'order/common/dtos/BaseProductRegion';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { BackToSearchButton } from 'order/common/components/BackToSearch/BackToSearchButton';
import { usePressDistributionSupplementProducts } from 'order/common/hooks/usePressDistributionSupplementProducts';
import { OrderMode } from 'order/common/dtos/OrderMode';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { AccordionItemOtherPartners } from 'order/orderChange/common/components/AccordionItems/OtherPartners';
import iconAccountDelivery from 'assets/icon-account-delivery.svg';
import orderClasses from 'common/styles/order.module.css';
import { SupplementInput } from '../../components/SupplementInput/SupplementInput';
import { Packaging } from 'order/productGroups/common/dtos/Packaging';
import { PressBaseProducts } from '../../components/PressBaseProduct/PressBaseProducts';
import { InternationalDestinationSection } from '../../../international/components/InternationalDestinationSection/InternationalDestinationSection';
import classes from '../../components/PressVariants/PressVariants.module.css';
import { useBoolean } from 'common/hooks/useBoolean';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { parseISO, isValid, isBefore, isAfter, endOfDay } from 'date-fns';
import { getDateOnly } from 'common/utils/helpers';
import { usePressDistributionDependentProducts } from 'order/common/hooks/usePressDistributionDependentProducts';
import { PRESS_CATEGORIES, PressDistributionBaseProduct } from 'order/common/dtos/PressDistributionBaseProduct';
import { DestinationSchema } from '../../../common/components/atom/AMDHLDestinationInput';
import moment from 'moment';

export const PressStep2: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { isDirty, setDirty, upsertMetaData, orderMode, order, getJournalPaymentData, journalPaymentInfo: journalPayment, meta } = useOrderContext();
  const [resetFields, setResetFields] = useState<boolean>(false);
  const [errorMsg, setSetErrorMsg] = useState(false);
  const { trigger, getValues, setValue, setError, control, reset, resetField, clearErrors } = useFormContext<PressOrderCreate>();
  const [
    takeOriginatorAsSubmitter,
    originatorUcp,
    submitterUcp,
    pickupAddress,
    selectedPackagingType,
    matrix = [],
    journal,
    zkz,
    selectedDate,
    otherPartners,
    nationalProducts,
    supplements,
    internationalProducts
  ] = useWatch({
    control,
    name: [
      'takeOriginatorAsSubmitter',
      'originator',
      'submitter',
      'pickupAddress',
      'internationalPackaging.type',
      'destinations.matrix',
      'journal',
      'journal.zkz',
      'details.date',
      'otherPartners',
      'nationalProducts',
      'supplements',
      'internationalProducts'
    ]
  });
  const currentOriginator = useRef<string | undefined>(undefined);
  const inputDisabled = !zkz;

  useMemo(() => getJournalPaymentData(zkz), [zkz]);

  const selectDateObject = selectedDate ? selectedDate : new Date();
  const fetchedBaseProductsByZkz = usePressDistributionBaseProducts(zkz, getDateOnly(selectDateObject));
  const nationalProductsNumber = nationalProducts?.map((np) => np.productNumber || '') || [];
  const fetchSupplementProductsByZkz = usePressDistributionSupplementProducts(zkz, nationalProductsNumber, moment(selectedDate).format('yyyy-MM-DD'));
  const pressDistributionDependentProducts = usePressDistributionDependentProducts(zkz, selectDateObject);

  useEffect(() => {
    setDirty(true);
  }, []);

  useEffect(() => {
    if (resetFields) {
      reset(
        {
          journal: { ...journal, postingIdentifier: 'R', journalNumber: null as any },
          productGroup: ProductGroup.PRESS_DISTRIBUTION,
          supplements: [],
          nationalProducts: [
            {
              productNumber: null as any,
              packaging: { type: null as any, quantity: null as any } as any,
              variants: [{ weight: null, quantity: null, destinations: null } as any]
            }
          ] as PressBaseProduct[],
          internationalProducts: [
            { productNumber: null as any, variants: [{ weight: null, quantity: null, destinations: null } as any] }
          ] as PressBaseProduct[],
          contactForQuestions: null as any,
          internationalPackaging: null as any,
          subProducts: null as any
        },
        {
          keepDefaultValues: true
        }
      );
    }
  }, [resetFields, zkz]);

  useEffect(() => {
    if (!journalPayment) {
      return;
    }

    upsertMetaData('journalPaymentPayer', journalPayment.payer);

    if (journalPayment.originator?.customerId) {
      const customerId = journalPayment.originator.customerId;
      setValue('originator', customerId);
    }
  }, [journalPayment]);
  const pickup = !!pickupAddress;
  const matrixValue = matrix.reduce((acc, v) => acc + v.quantity, 0) || 0;

  const allBaseProductsNational = fetchedBaseProductsByZkz.filter(
    (p) => !!p.productNumber && !!p.descriptionShort && p.region === BaseProductRegion.NATIONAL
  );

  const baseProductsInternational = fetchedBaseProductsByZkz.filter(
    (p) => !!p.productNumber && !!p.descriptionShort && p.region === BaseProductRegion.INTERNATIONAL
  );

  const parseDate = (dateStr: string | undefined): Date | undefined => {
    return dateStr ? parseISO(dateStr) : undefined;
  };
  const selectedDateObj = selectedDate ? new Date(selectedDate) : new Date();
  const supplementProductsNational = useMemo(() => {
    if (!isValid(selectedDateObj)) {
      return [];
    }

    return fetchSupplementProductsByZkz
      .filter((p) => p.region === BaseProductRegion.NATIONAL && p.productNumber && p.descriptionShort)
      .filter((p) => {
        const validFrom = parseDate(p.validFrom);
        const validTo = parseDate(p.validTo);

        const isValidFrom = validFrom && isValid(validFrom) && !isBefore(selectedDateObj, validFrom);
        const isValidTo = validTo && isValid(validTo) && !isAfter(selectedDateObj, endOfDay(validTo));

        const isValidProduct = isValidFrom && (validTo ? isValidTo : true);

        return isValidProduct;
      });
  }, [fetchSupplementProductsByZkz, selectedDate]);

  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });

  useEffect(() => {
    if (currentOriginator.current !== originatorUcp) {
      currentOriginator.current = originatorUcp;

      if (takeOriginatorAsSubmitter) {
        setValue('submitter', originatorUcp);
      }
    }
  }, [originatorUcp, takeOriginatorAsSubmitter]);

  const checked = takeOriginatorAsSubmitter;
  const [einliefererUbernehmen, setEinliefererUbernehmen] = useBoolean(takeOriginatorAsSubmitter);

  const originatorCheckboxConfig = {
    controlName: 'takeOriginatorAsSubmitter',
    name: 'einliefererUbernehmen',
    label: `${oc('alsoAcceptAsConsignor')}`,
    value: einliefererUbernehmen || checked,
    onChange: () => {
      setEinliefererUbernehmen.toggle();
      setValue('submitter', einliefererUbernehmen ? '' : getValues('originator'), { shouldValidate: true });
      setValue('takeOriginatorAsSubmitter', !einliefererUbernehmen);
    }
  };
  const submitterCheckboxConfig = {
    name: 'pickupAddress.toggle',
    label: `${oc('pickupDesired')}`,
    value: pickup,
    onChange: () => {
      if (pickup) {
        setValue('pickupAddress', undefined, { shouldTouch: true });
      } else {
        setValue(
          'pickupAddress',
          {
            companyName: '',
            street: '',
            streetNumber: '',
            postCode: '',
            city: '',
            country: '',
            further: ''
          },
          { shouldTouch: true }
        );
        // setValue('details.productionPlantId', '');
      }
    }
  };

  const handleNationalBaseProductDelete = useCallback((index: number, baseProduct: any, isLast: boolean) => {
    if (isLast) {
      setValue('nationalProducts', [{ variants: [] }]);
      setValue('supplements', []);
    }
  }, []);

  const handleInternationalBaseProductDelete = useCallback((index: number, baseProduct: any, isLast: boolean) => {
    if (isLast) {
      setValue('internationalProducts', [{ variants: [] }]);
      setValue('internationalPackaging', { quantity: null as any, type: null as any });
      setValue('internationalDestinations', {
        totalWeight: 0,
        netWeight: 0
      });
    }
  }, []);

  const handleSupplementsDelete = useCallback((index: number, supplement: any, isLast: boolean) => {
    if (isLast) {
      setValue('supplements', []);
    }
  }, []);

  const isAllNationalProduct = nationalProducts?.map((a) => !a.variants?.[0]?.quantity && !a.variants?.[0]?.weight && !a?.productNumber)?.[0];
  const isAllInternationProduct = internationalProducts?.map((a) => !a.variants?.[0]?.quantity && !a.variants?.[0]?.weight && !a?.productNumber)?.[0];

  const showErrorForNationalAndInternational: boolean = isAllNationalProduct && isAllInternationProduct;

  const isNationalProduct = nationalProducts
    ?.map((value) => value)
    .some((a) => a.variants?.[0]?.quantity || a.variants?.[0]?.weight || a?.productNumber);

  const isInternationProduct = internationalProducts
    ?.map((value) => value)
    .some((a) => a.variants?.[0]?.quantity || a.variants?.[0]?.weight || a?.productNumber);

  useEffect(() => {
    if (!showErrorForNationalAndInternational) {
      if (isNationalProduct && isAllInternationProduct) {
        resetField('internationalProducts', { defaultValue: [{ productNumber: undefined, variants: [] }] });
      } else if (isInternationProduct && isAllNationalProduct) {
        resetField('nationalProducts', { defaultValue: [{ productNumber: undefined, variants: [] }] });
      }
    }
  }, [isAllNationalProduct, isAllInternationProduct, showErrorForNationalAndInternational]);

  const hasPreExsistingIP = internationalProducts?.some((p) => p?.preExisting === true);
  const disableIPPackaging = orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable && hasPreExsistingIP;

  // Checking if there is any smart national product
  const isSmart = nationalProducts?.some(
    (p) =>
      allBaseProductsNational?.find((ap) => ap?.productNumber === p?.productNumber)?.prdCategory?.toLocaleLowerCase() ===
      PRESS_CATEGORIES.SMART?.toLocaleLowerCase()
  );

  const coverPlusLabel = useMemo(() => {
    if (!isSmart && pressDistributionDependentProducts) {
      const validProduct = pressDistributionDependentProducts.pressDistributionDependentProducts.find((product) => {
        const validFrom = parseDate(product.validFrom);
        const validTo = parseDate(product.validTo);

        const isValidFrom = validFrom && isValid(validFrom) && !isBefore(selectedDateObj, validFrom);
        const isValidTo = validTo && isValid(validTo) && !isAfter(selectedDateObj, endOfDay(validTo));

        const isValidProduct = isValidFrom && (validTo ? isValidTo : true);
        return isValidProduct && isAvailableFromJan25(selectedDate) && product.hierarchyProductCharacteristic === 'CoverPlus';
      });

      if (validProduct) {
        return validProduct.descriptionShort;
      } else if (!validProduct && isAvailableFromJan25(selectedDate)) {
        return 'CoverPlus';
      }
    }
    if (!isAvailableFromJan25(selectedDate)) {
      return 'CoverPlus';
    }
  }, [isSmart, pressDistributionDependentProducts, selectedDate]);

  const baseProductsNational = useMemo(() => {
    return orderMode === OrderMode.CHANGE && isSmart
      ? ([
          allBaseProductsNational.find((sp) => sp?.productNumber === order?.nationalProducts?.[0]?.productNumber),
          ...allBaseProductsNational?.filter((p) => p?.prdCategory === PRESS_CATEGORIES?.CLASSIC)
        ] as PressDistributionBaseProduct[])
      : allBaseProductsNational?.filter((p) => p?.prdCategory === PRESS_CATEGORIES?.CLASSIC);
  }, [selectedDate, zkz, nationalProducts, isSmart]);

  useEffect(() => {
    if (!meta?.smartChanged) {
      if (!isAvailableFromJan25(selectedDate) && nationalProducts?.some((p) => p?.prdCategory === PRESS_CATEGORIES.SMART)) {
        resetField('nationalProducts', { defaultValue: [{ productNumber: undefined, variants: [] }] });
        upsertMetaData('smartChanged', true);
      }
    }
  }, [selectedDate]);

  const isPressReleaseSmart =
    orderMode === OrderMode.CHANGE && isSmart && nationalProducts?.some((p) => p?.prdBasicProduct === PressBasicProduct.PRESS_RELEASE);
  const isTTRDisable = selectedPackagingType === 'TRT';
  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconAccountDelivery} alt="Icon Auftragsinhalt">
              {oc('title')}
            </OrderStepHeadline>
            <OrderStepSection className={`${classes.borderLine} ${classes.borderLineSpaceEven} mt-0`}>
              <DHLHeadline type="h6" color={'gray'}>
                {oc('pressArticle')}
              </DHLHeadline>
              <ErrorBoundary context={`PressDistribution::${orderMode}::JournalInput`}>
                <JournalInput<PressOrderCreate> name="journal" disabled={inputDisabled} orderMode={orderMode} setResetFields={setResetFields} />
              </ErrorBoundary>
            </OrderStepSection>
            <OrderStepSection>
              <ErrorBoundary context={`PressDistribution::${orderMode}::BaseProductInput`}>
                <PressBaseProducts
                  name="nationalProducts"
                  title="Nationale Produkte"
                  items={baseProductsNational}
                  disabled={!journal?.zkz || !baseProductsNational?.length}
                  onDelete={handleNationalBaseProductDelete}
                  setSetErrorMsg={setSetErrorMsg}
                  showErrorForNationalAndInternational={errorMsg}
                  isSmart={isSmart}
                  isPressReleaseSmart={isPressReleaseSmart}
                />
              </ErrorBoundary>
              <ErrorBoundary context={`PressDistribution::${orderMode}::SupplementInput`}>
                <SupplementInput
                  name={'supplements'}
                  disabled={inputDisabled}
                  assignmentBaseProduct={nationalProducts}
                  supplements={supplements}
                  supplementProductsNational={supplementProductsNational}
                  onDelete={handleSupplementsDelete}
                />
              </ErrorBoundary>
            </OrderStepSection>
            <div className={`${classes.borderLine} pb-4`}>
              <OrderStepSection headline="Zusatzleistung">
                <FormRow mode="checkboxes">
                  {orderMode === OrderMode.CHANGE && (
                    <>
                      <Controller
                        name="subProducts.trackAndMatch"
                        render={({ field }) => (
                          <DHLCheckbox
                            name={field.name}
                            label={'Track & Match'}
                            value={field.value}
                            onChange={() => field.onChange(!field.value)}
                            disabled={true}
                          />
                        )}
                      />
                      <Controller
                        name="subProducts.premium"
                        render={({ field }) => (
                          <DHLCheckbox
                            name={field.name}
                            label="Premiumadress"
                            value={field.value}
                            onChange={() => field.onChange(!field.value)}
                            disabled={true}
                          />
                        )}
                      />
                    </>
                  )}
                  {(!isSmart || !isAvailableFromJan25(selectedDate)) &&
                    !(zkz == undefined || baseProductsNational[0]?.productNumber == undefined) && (
                      <Controller
                        name="subProducts.coverplus"
                        render={({ field }) => (
                          <DHLCheckbox
                            name={field.name}
                            label={coverPlusLabel}
                            value={field.value}
                            onChange={() => field.onChange(!field.value)}
                            disabled={inputDisabled}
                          />
                        )}
                      />
                    )}
                </FormRow>
              </OrderStepSection>

              <OrderStepSection headline={oc('furtherProductCharacteristics')}>
                <Controller
                  name="subProducts.gogreen"
                  render={({ field: { value, onChange, ref, ...field } }) => {
                    const variant = 'GOGREEN';
                    return (
                      <DHLCheckbox
                        {...field}
                        key={variant}
                        label={tCatalogValues(`lineItemVariant.display.${variant}`)}
                        value={value}
                        onChange={() => onChange(!value)}
                        disabled={inputDisabled}
                      />
                    );
                  }}
                />
              </OrderStepSection>
            </div>
            <OrderStepSection>
              <ErrorBoundary context={`PressDistribution::${orderMode}::internationalProducts`}>
                <PressBaseProducts
                  name="internationalProducts"
                  title="Internationale Produkte"
                  items={baseProductsInternational}
                  disabled={!journal?.zkz}
                  onDelete={handleInternationalBaseProductDelete}
                  setSetErrorMsg={setSetErrorMsg}
                  showErrorForNationalAndInternational={errorMsg}
                  selectedDate={selectedDate}
                />
              </ErrorBoundary>
            </OrderStepSection>
            <OrderStepSection>
              <ErrorBoundary context={`PressDistribution::${orderMode}::packaging`}>
                <PackagingSection<PressOrderCreate>
                  name={'internationalPackaging'}
                  disabled={meta?.enablePackaging ? !meta?.enablePackaging : disableIPPackaging || inputDisabled}
                  internationalPackagingSum={meta?.internationalPackagingSum || 0}
                  isInternational={true}
                  isTTRDisable={isTTRDisable}
                />
              </ErrorBoundary>
              {selectedPackagingType === Packaging.PAL && (
                <ErrorBoundary context={`PressDistribution::${orderMode}::destinations`}>
                  <InternationalDestinationSection<PressOrderCreate>
                    name={'internationalDestinations'}
                    disabled={meta?.enablePackaging ? !meta?.enablePackaging : disableIPPackaging || inputDisabled}
                    showEcomony={true}
                    showMixed={true}
                    totalNetWeight={
                      getValues('internationalProducts')?.reduce((acc: number, bp: PressBaseProduct) => {
                        return (
                          acc +
                            bp.variants?.reduce((accumulator: number, variant: PressBaseProductVariant) => {
                              return accumulator + ((variant?.quantity || 0) * (variant?.weight || 0)) / 1000;
                            }, 0) || 0
                        );
                      }, 0) || 0
                    }
                  />
                </ErrorBoundary>
              )}
            </OrderStepSection>
            <OrderStepSection headline="Zusatzleistung" className={`${classes.borderLine} pb-3`}>
              <FormRow mode="checkboxes">
                <Controller
                  name="subProducts.additionalFeeSLN"
                  render={({ field }) => (
                    <DHLCheckbox
                      name={field.name}
                      label={'Zusatzentgelt SLN'}
                      value={field.value}
                      onChange={() => field.onChange(!field.value)}
                      disabled={inputDisabled}
                    />
                  )}
                />
              </FormRow>
            </OrderStepSection>
            <ErrorBoundary context={`PressDistribution::${orderMode}::sender`}>
              <CustomerSection<PressOrderCreate>
                headline={oc('sender')}
                inputName="originator"
                disabled
                customerRole={OrderSearchKey.Originator}
                errorMessage={t('error.customerIdUnknown')}
                checkboxConfig={originatorCheckboxConfig}
              />
            </ErrorBoundary>
            <ErrorBoundary context={`PressDistribution::${orderMode}::consignor`}>
              <CustomerSection<PressOrderCreate>
                headline={oc('consignor')}
                inputName="submitter"
                disabled={inputDisabled || takeOriginatorAsSubmitter}
                customerRole={OrderSearchKey.Submitter}
                errorMessage={t('error.customerIdUnknown')}
                checkboxConfig={submitterCheckboxConfig}
              />
            </ErrorBoundary>
            <ErrorBoundary context={`PressDistribution::${orderMode}::pickupAddress`}>
              {pickup && <AddressSection<PressOrderCreate> name="pickupAddress" fallbackCustomerId={submitterUcp} />}
            </ErrorBoundary>
            <ErrorBoundary context={`PressDistribution::${orderMode}::contactForQuestions`}>
              <ContactSection<PressOrderCreate> name="contactForQuestions" disabled={inputDisabled} />
            </ErrorBoundary>
            <ErrorBoundary context={`PressDistribution::${orderMode}::details`}>
              <TimeCitySection<PressOrderCreate>
                name="details"
                disabled={inputDisabled}
                pickup={pickup}
                selectedProductGroup={`${ProductGroup.PRESS_DISTRIBUTION}`}
              />
            </ErrorBoundary>
            {/* <ErrorBoundary context={`PressDistribution::${orderMode}::packaging`}>*/}
            {/*  <PackagingSection<PressOrderCreate>*/}
            {/*    name="packaging"*/}
            {/*    disabled={inputDisabled || (orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable)}*/}
            {/*    destinationAmount={matrixValue}*/}
            {/*    onTypeChange={handlePackagingTypeChange}*/}
            {/*    showSLN*/}
            {/*  />*/}
            {/* </ErrorBoundary>*/}
            {/* {selectedPackagingType === Packaging.PAL && (*/}
            {/*  <ErrorBoundary context={`PressDistribution::${orderMode}::destinations`}>*/}
            {/*    <DestinationSection<PressOrderCreate>*/}
            {/*      productGroup={ProductGroup.PRESS_DISTRIBUTION}*/}
            {/*      name="destinations"*/}
            {/*      showSLN*/}
            {/*      disabled={inputDisabled || (orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable)}*/}
            {/*    />*/}
            {/*  </ErrorBoundary>*/}
            {/* )}*/}
            <OrderStepSection>
              <Accordion>
                {(register) => <AccordionItemOtherPartners name={'otherPartners'} id={1} register={register} disabled={inputDisabled} />}
              </Accordion>
            </OrderStepSection>
          </main>
          <MarginalColumn />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            setSetErrorMsg(!!showErrorForNationalAndInternational);
            const fieldName: any = [
              'journal',
              // 'journal.journalNumber',
              // 'journal.postingIdentifier',
              // 'nationalProducts',
              'supplements',
              'details.date',
              'details.productionPlantId',
              'originator',
              'submitter',
              'pickupAddress'
            ];

            (showErrorForNationalAndInternational && fieldName.push('nationalProducts')) || (isNationalProduct && fieldName.push('nationalProducts'));

            (showErrorForNationalAndInternational && fieldName.push('internationalProducts')) ||
              (isInternationProduct && fieldName.push('internationalProducts'));

            pickup && fieldName.push('details.time');
            const isContentValid = await trigger(fieldName);
            const areDependentFieldsValid = validatePressOrderCreate(getValues(), setError, clearErrors, 2);
            if (isContentValid && areDependentFieldsValid) {
              nextStep();
            }
          }}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
