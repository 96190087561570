import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import i18n from 'i18n';
import { Alert } from 'order/common/components/Alerts/Alert';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { translationEnglish } from '../Translations/OrderSearch/englishTranslation';
import { translationGerman } from '../Translations/OrderSearch/germanTranslation';

import styles from './OrderSearchAlertContainer.module.css';

i18n.addResourceBundle('de', 'orderSearch', translationGerman);
i18n.addResourceBundle('en', 'orderSearch', translationEnglish);

export const OrderSearchAlertContainer: FC = () => {
  const { alerts, addAlert } = useAlerts();
  const { t } = useTranslation('orderSearch');
  const { response } = useSearchContext();

  useEffect(() => {
    if (response?.amErrors) {
      response.amErrors.forEach((error) => {
        const errorCategory = error.errorCategory?.toString().toUpperCase();
        if (errorCategory === AlertTypes.Error.toUpperCase()) {
          addAlert({
            type: AlertTypes.Error,
            title: t('error') + ' ' + error.code + '!',
            description: error.errorDescription
          });
        }
        if (errorCategory === AlertTypes.Warning.toUpperCase()) {
          addAlert({
            type: AlertTypes.Warning,
            title: t('warning') + ' ' + error.code + '!',
            description: error.errorDescription
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (alerts.length === 0) {
    return <div data-testid={'noAlertsContainer'}></div>;
  } else {
    return (
      <>
        {alerts.map((alertGroup) => {
          return (
            <div
              className={classNames(
                alertGroup.type.toString().toUpperCase() !== AlertTypes.Warning.toUpperCase()
                  ? styles.orderSearchWarningAlert
                  : styles.orderSearchAlert
              )}
              key={alertGroup.id}
              data-testid={'testing-alert'}
            >
              <Alert
                id={alertGroup.id}
                type={alertGroup.type}
                closeable={alertGroup.type.toString().toLowerCase() !== AlertTypes.Error.toString().toLowerCase()}
                title={alertGroup.title}
                description={alertGroup.description}
              />
            </div>
          );
        })}
      </>
    );
  }
};
