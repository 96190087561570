import styles from 'order/orderChange/common/components/BillingTable/billing-table.module.css';
import { BillingTableItemHeadRowProps } from 'order/orderChange/common/components/BillingTable/BillingTableItemHeadRow';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';

export type BillingTableHeadRowProps = Omit<BillingTableItemHeadRowProps, 'index'>;

export const BillingTableHeadRow: VFC<BillingTableHeadRowProps> = (props) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  return (
    <tr>
      <th colSpan={props.showDesignation ? 2 : 1}>{t('position')}</th>
      <th colSpan={props.showDiscount ? 2 : 1}>{t('product')}</th>
      <th colSpan={props.showTariffZone ? 2 : 1} className={styles.rightAlign}>
        {t('number')}
      </th>
      {props.showSingleWeight && props.showTotalWeight && <th />}
      <th colSpan={props.showZKZ ? 2 : 1} className={styles.rightAlign}>
        {t('feeWithoutVat')}
      </th>
      <th colSpan={props.showBasicCharge ? 2 : 1} />
      <th />
    </tr>
  );
};
