// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message-container_errorContainer__aC0VI {
  max-width: 1400px;
  width: calc(100% - 6rem);
  margin: 3rem auto;
  background-color: #f5f5f5;
  border-radius: 4px;
  min-height: 10vh;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/ErrorMessageContainer/error-message-container.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,wBAAwB;EACxB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB","sourcesContent":[".errorContainer {\n  max-width: 1400px;\n  width: calc(100% - 6rem);\n  margin: 3rem auto;\n  background-color: #f5f5f5;\n  border-radius: 4px;\n  min-height: 10vh;\n  position: relative;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": `error-message-container_errorContainer__aC0VI`
};
export default ___CSS_LOADER_EXPORT___;
