import classNames from 'classnames';
import { StatusProgressBar } from 'common/components/StatusProgressBar';
import { toFormattedDate } from 'common/formatting';
import { OrderCustomer, OrderCustomerRoleEnum } from 'generated';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { getJournals } from 'common/services/JournalService';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './HeaderSection.module.css';
import i18n, { useTranslation } from 'i18n';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { Alert } from 'order/common/components/Alerts/Alert';
import { Order } from 'order/common/context/order/dtos/Order';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

const Row: FC<{ label?: string; value?: string; showColon?: boolean; id?: string }> = ({ label, id, value, showColon = true }) => {
  return (
    <tr>
      <td className={classNames(styles.rowAlignTop)} data-testid={`row-label-${id}`}>
        <p>
          {label}
          {showColon ? ':' : ''}
        </p>
      </td>
      <td className={classNames(styles.rowAlignTop)} data-testid={`row-value-${id}`}>
        <p>{value}</p>
      </td>
    </tr>
  );
};

const HeaderColumn: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div className={classNames(styles.col_4)}>
      <table className={classNames(styles.table)}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export type HeaderSectionProps = {
  order?: Order;
  productionState?: ProductionState;
};

export const HeaderSection: FC<HeaderSectionProps> = ({ order, productionState }) => {
  const progressStep = useMemo(() => {
    switch (order?.productionState) {
      case 'GPL':
      case 'PLA':
        return 0;
      case 'IPR':
        return 1;
      case 'BIL':
        return 2;
      default:
        return -1;
    }
  }, [order?.productionState]);
  const getCustomer = (role: OrderCustomerRoleEnum): OrderCustomer | undefined => order?.parties?.find((x) => x.role === role);
  const hideProgressBar =
    progressStep === -1 || productionState === ProductionState.CANCELED || productionState === ProductionState.CANCELED_AND_MERGED;
  const originator = getCustomer(OrderCustomerRoleEnum.Originator);
  const Payer = getCustomer(OrderCustomerRoleEnum.Payer);
  const submitter = getCustomer(OrderCustomerRoleEnum.Submitter);
  const baseProducts = useBaseProducts(order?.productGroup, order?.orderDetail?.date);
  const baseProductName = baseProducts.find((x) => x.productNumber === order?.baseProduct?.productNumber)?.descriptionShort;
  const pressDistribution: boolean = order?.productGroup === ProductGroup.PRESS_DISTRIBUTION;
  const zkz = order?.journal?.zkz || '';
  const { orderMode, upsertMetaData } = useOrderContext();
  const [title, setTitle] = useState<string | undefined>('');

  useEffect(() => {
    if (orderMode === OrderMode.VIEW && pressDistribution) {
      journalTitleData(zkz);
    }
  }, []);

  const journalTitleData = async (zkz?: string) => {
    if (zkz) {
      const journals = await getJournals(zkz);
      setTitle(journals?.data[0]?.title);
      upsertMetaData('zkzTitle', journals?.data[0]?.title);
    }
  };

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });
  const { t } = useTranslation('translation', { keyPrefix: 'headerOrderView' });

  const renderHeader = () => {
    switch (order?.productGroup) {
      case ProductGroup.BRIEF:
        if (order?.orderCategory === OrderCategory.AFM_BRIEF) {
          return (
            <>
              <HeaderColumn>
                <Row
                  id="orderCategory"
                  label={t('orderCategory')}
                  value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
                />
                <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
                <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
                <Row id="machineCode" label={t('machineCode')} value={order?.postage?.machineCode} />
              </HeaderColumn>
              <HeaderColumn>
                <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
                <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
                <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
                <Row id="consignmentOn" label={t('ConsignmentOn')} value={toFormattedDate(order?.orderDetail?.date)} />
              </HeaderColumn>
            </>
          );
        }
        if (order.orderCategory === OrderCategory.DV_BRIEF) {
          return (
            <>
              <HeaderColumn>
                <Row
                  id="orderCategory"
                  label={t('orderCategory')}
                  value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
                />
                <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
                <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
              </HeaderColumn>
              <HeaderColumn>
                <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
                <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
                <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
                <Row id="consignmentOn" label={t('ConsignmentOn')} value={toFormattedDate(order?.orderDetail?.date)} />
                <Row id="billingNumber" label={t('billingNumber')} value={Payer?.customerId} />
                <Row id="paymentClearingNumber" label={t('paymentClearingNumber')} value={order.postage?.paymentClearingNumber} />
              </HeaderColumn>
            </>
          );
        }
        break;
      case ProductGroup.DIALOGPOST_INTERNATIONAL:
      case ProductGroup.BRIEF_INTERNATIONAL:
      case ProductGroup.PRESS_INTERNATIONAL:
      case ProductGroup.BRIEF_DMC_INTERNATIONAL:
        return (
          <>
            <HeaderColumn>
              <Row
                id="orderCategory"
                label={t('orderCategory')}
                value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
              />
              <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
              <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
              <Row id="billingNumber" label={t('billingNumber')} value={Payer?.customerId} />
              <Row id="paymentClearingNumber" label={t('paymentClearingNumber')} value={order.postage?.paymentClearingNumber} />
            </HeaderColumn>
            <HeaderColumn>
              <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
              <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
              <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
              <Row id="consignmentOn" label={t('ConsignmentOn')} value={toFormattedDate(order?.orderDetail?.date)} />
            </HeaderColumn>
          </>
        );
      case ProductGroup.TEILLEISTUNG:
      case ProductGroup.PARTIAL_SERVICE_LETTER:
      case ProductGroup.COLLECTION:
        if (order.orderCategory === OrderCategory.TL_BRIEF) {
          return (
            <>
              <HeaderColumn>
                <Row
                  id="orderCategory"
                  label={t('orderCategory')}
                  value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
                />
                <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
                <Row id="contractor" label={t('contractor')} value={`${Payer?.customerId} ${Payer?.name}`} />
                <Row id="partialServiceNumber" label={t('partialServiceNumber')} value={order?.submissionId} />
              </HeaderColumn>
              <HeaderColumn>
                <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
                <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
                <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
                <Row id="consignmentOn" label={t('ConsignmentOn')} value={toFormattedDate(order?.orderDetail?.date)} />
              </HeaderColumn>
            </>
          );
        }
        if (order.orderCategory === OrderCategory.ABHOLAUFTRAG) {
          return (
            <>
              <HeaderColumn>
                <Row
                  id="orderCategory"
                  label={t('orderCategory')}
                  value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
                />
                <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
                <Row id="customer" label={t('customer')} value={`${Payer?.customerId} ${Payer?.name}`} />
              </HeaderColumn>
              <HeaderColumn>
                <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
                <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
                <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
                <Row id="consignmentOn" label={t('collectionOn')} value={toFormattedDate(order?.orderDetail?.date)} />
              </HeaderColumn>
            </>
          );
        }
        break;
      case ProductGroup.DIALOGPOST:
      case ProductGroup.DIALOGPOSTWURFSPEZIAL:
      case ProductGroup.POSTAKTUELL:
        return (
          <>
            <HeaderColumn>
              <Row
                id="orderCategory"
                label={t('orderCategory')}
                value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
              />
              <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
              <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
              {order?.orderCategoryProductKey !== OrderCategoryProductKey.POSTAKTUELL && (
                <Row id="basicProduct" label={t('basicproduct')} value={baseProductName} />
              )}
            </HeaderColumn>
            <HeaderColumn>
              <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
              <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
              <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
              <Row
                id="consignmentOn"
                label={!!order?.pickupAddress === true ? t('collectionOn') : t('ConsignmentOn')}
                value={toFormattedDate(order?.orderDetail?.date)}
              />
            </HeaderColumn>
          </>
        );
      case ProductGroup.PRESS_DISTRIBUTION:
        return (
          <>
            <HeaderColumn>
              <Row
                id="orderCategory"
                label={t('orderCategory')}
                value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
              />
              <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
              <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
              <Row id="zkz" data-testid={'zkz'} label={t('zkz')} value={`${order?.journal?.zkz}`} />
              <Row id="newspaper-title" data-testid={'newspaper-title'} label={t('newspaperTitle')} value={`${title}`} />
            </HeaderColumn>
            <HeaderColumn>
              <Row showColon={false} label=" " value="" />
              <Row showColon={false} label=" " value="" />
              <Row showColon={false} label=" " value="" />
              <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
              <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
              <Row id="newspaper-number" data-testid={'newspaper-number'} label={t('newspapernumber')} value={`${order?.journal?.journalNumber}`} />
              <Row
                id="consignmentOn"
                label={!!order?.pickupAddress === true ? t('collectionOn') : t('ConsignmentOn')}
                value={toFormattedDate(order?.orderDetail?.date)}
              />
            </HeaderColumn>
          </>
        );
      case ProductGroup.INFOPOST_DHL:
        return (
          <>
            <HeaderColumn>
              <Row
                id="orderCategory"
                label={t('orderCategory')}
                value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order.orderCategory}
              />
              <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
              <Row id="sender" label={t('sender')} value={`${originator?.customerId} ${originator?.name}`} />
              <Row id="customerIdentifier" label={t('customerIdentifier')} value={order?.postage?.frankingIdPrefix} />
            </HeaderColumn>
            <HeaderColumn>
              <Row id="orderName" label={t('orderName')} value={order?.orderLabel} />
              <Row id="salesOrderNumber" label={t('salesorderno')} value={order?.customerOrderId} />
              <Row id="consignor" label={t('consignor')} value={`${submitter?.customerId} ${submitter?.name}`} />
              <Row
                id="consignmentOn"
                label={!!order?.pickupAddress === true ? t('collectionOn') : t('ConsignmentOn')}
                value={toFormattedDate(order?.orderDetail?.date)}
              />
            </HeaderColumn>
          </>
        );
      default:
        return (
          <>
            <HeaderColumn>
              <Row
                id="orderCategory"
                label={t('orderCategory')}
                value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : order?.orderCategory}
              />
              <Row id="orderNumber" label={t('Ordernumber')} value={order?.orderId} />
            </HeaderColumn>
            <HeaderColumn />
          </>
        );
    }
  };

  return (
    <>
      {productionState === ProductionState.CANCELED && (
        <div className={styles.spacingStorno}>
          <Alert type={AlertTypes.Error} title={'Auftrag storniert'} id={'Error'} closeable={false} />
        </div>
      )}
      {productionState === ProductionState.CANCELED_AND_MERGED && (
        <div className={styles.spacingStorno}>
          <Alert type={AlertTypes.Error} title={'Auftrag Storniert (zusammengeführt)'} id={'Error'} closeable={false} />
        </div>
      )}
      <div data-testid={'header-for-display-order'} className={classNames(styles.grid)}>
        {renderHeader()}
        <div className={classNames(styles.col_4, styles.nopadding)}>
          {!hideProgressBar && (
            <StatusProgressBar
              steps={[`${i18n.t('orderViewStatus.planning')}`, `${i18n.t('orderViewStatus.progress')}`, `${i18n.t('orderViewStatus.completed')}`]}
              active={progressStep}
              data-testid="status-progress-bar"
            />
          )}
        </div>
      </div>
    </>
  );
};
