import { AxiosInstance, AxiosPromise } from 'axios';

import { Configuration } from 'generated';
import { getAxiosClient } from './axiosClient';

export type Endpoint<T, R> = (input: T) => Promise<R | undefined>;

type ApiFactory<T> = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T;

export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/';

/**
 * Create a new instance of axios with our custom config.
 */
const client = getAxiosClient();

export const createApi = <T>(factory: ApiFactory<T>): T => factory(undefined, BASE_URL.replace(/\/+$/g, ''), client);

export const responseOrUndefined = async <Response>(response: AxiosPromise<Response>): Promise<Response | undefined> =>
  response.then((r) => r.data).catch(() => Promise.resolve(undefined));
