import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { getDependentProducts, Product } from 'order/productGroups/common/services/ProductService';
import { FertigungTypes } from 'order/productGroups/dialogMarketing/components/FertigungSection/FertigungSection';
import { useMemo } from 'react';
import useSWR from 'swr/immutable';
import { useClusterCombination } from './useClusterCombinations';
import { ProductGroup } from '../dtos/ProductGroup';

export type UseDependentProductsOptions = Partial<{
  suspense: boolean;
}>;

export type UseDependentProductsReturn = {
  all: Product[];
  filtered: Product[];
  easy?: Product;
  nichtAutomationsfaehig?: Product;
  trackAndMatch?: Product;
  premium: Product[];
  fertigung: Partial<Record<FertigungTypes, Product>>;
  isLoading: boolean;
  infrastructureDiscount?: Product;
  digitalCopy?: Product;
  additionalLetterServices?: Product[];
  startPointSelection: Product[];
  deliveryAreaSelection: Product[];
  seasonalSurcharge?: Product[];
};

const getCheckBoxValue = (filteredDependentProducts: Product[], value: string, basicFormat?: string): Product | undefined =>
  filteredDependentProducts.find(
    ({ hierarchyProductCharacteristic: hierarchy, isAdditionalCharge: charge, basicFormat: format }) =>
      hierarchy === value && !charge && (!basicFormat || basicFormat === format)
  );

const getCheckBoxValueList = (filteredDependentProducts: Product[], value: string): Product[] | undefined =>
  filteredDependentProducts.filter(({ hierarchyProductCharacteristic: hierarchy, isAdditionalCharge: charge }) => hierarchy === value && !charge);

const getFertigungProduct = (filteredDependentProducts: Product[], key: keyof Product, basicFormat?: string): Product | undefined =>
  filteredDependentProducts.find(({ [key]: flag, isAdditionalCharge, basicFormat: format }) => flag && !isAdditionalCharge && basicFormat === format);

export const useDependentProducts = (
  productGroup?: string,
  date = new Date(),
  baseProductNumber?: string,
  variant?: string,
  basicFormat?: string,
  options?: UseDependentProductsOptions
): UseDependentProductsReturn => {
  const isDIALOGPOSTWURFSPEZIAL = productGroup === ProductGroup.DIALOGPOSTWURFSPEZIAL;
  const isInternational =
    productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
    productGroup === ProductGroup.PRESS_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL;

  const userClusterReturnV = useClusterCombination(baseProductNumber, [], date);
  const { isProductAvailable: isProductAvailableCheck, available } = userClusterReturnV;

  const { data: dependentProducts = [], isValidating } = useSWR(
    productGroup?.length && !isInternational ? ['dependentProducts', productGroup] : null,
    async (_, productGroup) => getDependentProducts(productGroup).catch(() => []),
    { suspense: options?.suspense }
  );

  const baseProducts = useBaseProducts(productGroup, date);

  const baseProduct = baseProducts.find((x) => x.productNumber === baseProductNumber);

  const filteredProducts = useMemo(() => dependentProducts.filter((p) => isEntityWithDatesValid(p, date)), [dependentProducts, date]);

  const filtered = filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
  const startPointSelection = filtered.filter((a) => a.variant === 'START_POINT_SELECTION');
  const deliveryAreaSelection = filtered.filter((a) => a.variant === 'DELIVERY_AREA_SELECTION');

  const easyLists = getCheckBoxValueList(filtered, 'Dialogpost Easy');
  const nichtAutomationsfaehigLists = getCheckBoxValueList(filtered, 'Nicht Automationsfähig');
  const trackAndMatchLists = getCheckBoxValueList(filtered, 'Track und Match');

  const easyPWSP = easyLists?.find((pn) => isProductAvailableCheck(pn));
  const nichtAutomationsfaehigPWSP = nichtAutomationsfaehigLists?.find((pn) => isProductAvailableCheck(pn));
  const trackAndMatchPWSP = trackAndMatchLists?.find((pn) => isProductAvailableCheck(pn));
  const seasonalSurcharge = getCheckBoxValueList(filtered, 'Saisonzuschlag');

  return {
    all: dependentProducts,
    filtered,
    easy: isDIALOGPOSTWURFSPEZIAL ? easyPWSP : getCheckBoxValue(filtered, 'Dialogpost Easy', baseProduct?.basicFormat),
    nichtAutomationsfaehig: isDIALOGPOSTWURFSPEZIAL
      ? nichtAutomationsfaehigPWSP
      : getCheckBoxValue(filtered, 'Nicht Automationsfähig', baseProduct?.basicFormat),
    trackAndMatch: isDIALOGPOSTWURFSPEZIAL ? trackAndMatchPWSP : getCheckBoxValue(filtered, 'Track und Match'),
    premium: filtered.filter(({ premiumAddress }) => premiumAddress),
    fertigung: {
      sortedPalletZone: getFertigungProduct(filtered, 'sortedPalletZone', baseProduct?.basicFormat),
      sortedPalletRegion: getFertigungProduct(filtered, 'sortedPalletRegion', baseProduct?.basicFormat),
      sortedTrayRegion: getFertigungProduct(filtered, 'sortedTrayRegion', baseProduct?.basicFormat),
      sortedTrayPostcode: getFertigungProduct(filtered, 'sortedTrayPostcode', baseProduct?.basicFormat)
    },
    startPointSelection,
    deliveryAreaSelection,
    seasonalSurcharge,
    isLoading: isValidating
  };
};
