import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';

import { TableLoadingState } from '../Table/dtos/LoadingState';
import { Pages } from './Pages';

import styles from './pagination.module.css';
import { useSearchContext } from 'order/common/context/search/SearchContext';

interface Props {
  currentPage?: number;
  defaultPageSize?: number;
  defaultPageNumber?: number;
  totalItems: number;
  loadingState?: TableLoadingState;
  pageSizeOptions?: number[];
  onPageChange?: (number: number) => void;
  onSizeChange?: (size: number) => void;
  pageRange?: number;
  currentPageSize?: number;
}

const PaginatorWrapper = (props: Props): JSX.Element | null => {
  const pageSizeOptions = props.pageSizeOptions || [5, 10, 25, 0];
  const defaultPageSize = props.defaultPageSize || 10;
  const [pageSizeValue, setPageSizeValue] = useState(props?.currentPageSize === 0 ? 0 : props?.currentPageSize || props?.defaultPageSize || 10);
  const [availablePages, setAvailablePages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(props?.currentPage || 1);

  const calculatePages = (pageSize: number, totalItems: number) => {
    const pages = pageSize == 0 ? 1 : Math.ceil(totalItems / pageSize);
    setAvailablePages(pages);
  };
  useEffect(() => {
    if (props.loadingState === TableLoadingState.LOADED) setPageSizeValue(props?.defaultPageSize || 10);
  }, [props.loadingState]);

  useEffect(() => {
    setPageSizeValue(props?.currentPageSize === 0 ? 0 : props?.currentPageSize || props?.defaultPageSize || 10);
  }, [props.totalItems]);

  useLayoutEffect(() => {
    calculatePages(pageSizeValue, props.totalItems);
    setSelectedPage(props?.currentPage || 1);
  }, [pageSizeValue, props.totalItems]);

  const handlePageSizeChange = (e: any) => {
    setPageSizeValue(e.target.value);
    setSelectedPage(1);
    props.onSizeChange?.(parseInt(e.target.value));
  };
  const handlePagesChange = (page: number) => {
    setSelectedPage(page);
    props.onPageChange?.(page);
  };

  return props.loadingState === TableLoadingState.LOADED && defaultPageSize < props.totalItems ? (
    <div className={styles.pagination} data-testid="pagination">
      <div />
      <div>
        {
          <nav data-testid={'pagerName'}>
            <ul data-testid={'pagination-button-list'} className={'dhlPagination'}>
              {availablePages ? (
                <Pages pageCut={1} pages={availablePages} page={props.currentPage || selectedPage || 1} onSelect={handlePagesChange} />
              ) : null}
            </ul>
          </nav>
        }
      </div>
      <div>
        <div>
          <div className="dhlInputSelectContainer">
            <div className={styles.inputSelectContainerInner}>
              <select
                data-testid="pagerName-pagesize"
                className={styles.inputSelect}
                autoComplete=""
                value={pageSizeValue}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((o, i) => {
                  return (
                    <option value={o} key={`paginator-option-${o}-${i}`} data-testid={`pagerName-pagesize-option-${o}`}>
                      {o > 0 ? o : 'Alles anzeigen'}
                    </option>
                  );
                })}
              </select>
              <div className={classNames(styles.inputFunction, styles.notInteractive, 'arrow-down')}>
                <div data-testid="pagerName-pagesize-function" className="svgIcon svgIcon-arrow-down">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <polygon fill="#878787" fillRule="evenodd" points="12 13.655 21 8 21 10.345 12 16 3 10.345 3 8" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const Pagination = React.memo(PaginatorWrapper);
