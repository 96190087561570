import classNames from 'classnames';
import React, { FC } from 'react';
import classes from './AMDHLInputFilterContainer.module.css';

/**
 * Definiert die Property für AMDHLInputFilterContainer Komponente.
 *
 */
export interface AMDHLInputFilterContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export const AMDHLInputFilterContainer: FC<AMDHLInputFilterContainerProps> = ({ className, children }) => (
  <div role="presentation" data-testid="text-inputContainer" className={classNames('inputContainer', className)}>
    <div className={classes.amInputContainerInner}>{children}</div>
  </div>
);
