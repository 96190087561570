import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useAlerts } from 'common/AlertContext';
import { useCounter } from 'common/utils/hooks';
import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { Header } from 'order/common/components/Header';
import { PayerProvider } from 'order/common/context/PayerContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { OrderStepProvider } from 'order/common/services/OrderStepProvider';
import classes from 'order/orderChange/common/components/OrderChange/order-change.module.css';
import { OrderCreateSteps } from 'order/productGroups/common/components/molecule/OrderCreateSteps';
import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';

import orderClasses from 'common/styles/order.module.css';
import { SpaceErrorAlerts } from '../../../../../common/components/Alerts/SpaceErrorAlerts';

export enum CreateOrderStep {
  Step1,
  Step2,
  Step3,
  Step4,
  StepSaved
}

export const OrderCreatePage = observer((): JSX.Element => {
  const useCounterReturn = useCounter(CreateOrderStep.Step1, CreateOrderStep.Step1, CreateOrderStep.Step4);
  const { reset, setOrderMode, productGroup, orderCategory } = useOrderContext();
  const { counter: step } = useCounterReturn;

  useEffect(() => {
    reset();
    setOrderMode(OrderMode.CREATE);
  }, []);

  const methods = useForm<OrderCreate>({
    mode: 'onBlur'
  });

  // prime the swr caches
  useBaseProducts(productGroup);
  useDependentProducts(productGroup);
  const { clear, alerts } = useAlerts();

  useEffect(() => {
    window.scrollTo(0, 0);
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div className={classes.container}>
      <Header type={OrderMode.CREATE} activeStep={step} productGroup={productGroup} orderCategory={orderCategory} />
      <SpaceErrorAlerts alerts={alerts} />
      <div
        className={
          step === 2 && (productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || productGroup === ProductGroup.COLLECTION) ? '' : orderClasses.dialog
        }
      >
        <PayerProvider>
          <OrderStepProvider {...useCounterReturn}>
            <FormProvider {...methods}>
              <React.Suspense fallback={<ActivityIndicator />}>
                <OrderCreateSteps />
              </React.Suspense>
            </FormProvider>
          </OrderStepProvider>
        </PayerProvider>
      </div>
    </div>
  );
});
