import React, { useEffect, useState, VFC } from 'react';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { OrderSearch } from 'order/orderSearch/components/OrderSearch';
import { useSearchContext } from '../../../common/context/search/SearchContext';
import { TableProvider } from '../../../common/context/table/TableProvider';
import { Spinner } from 'order/common/components/Spinner';
import { Authorized } from '../../../../common/components/Authorized/Authorized';

export const OrderSearchPage: VFC = () => {
  const [loaded, setLoaded] = useState(false);
  const { permissions } = useAuthContext();
  const { initSearchTabs } = useSearchContext();

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      initSearchTabs(permissions);
    }
  }, [permissions]);

  return (
    <Authorized userRight={UserRights.OrderSearch}>
      {!loaded ? (
        <Spinner testid="spinnerTestId" />
      ) : (
        <>
          <TableProvider>
            <OrderSearch />
          </TableProvider>
        </>
      )}
    </Authorized>
  );
};
