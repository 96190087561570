import React from 'react';
import { useTranslation } from 'react-i18next';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';

import { useSearchContext } from '../../common/context/search/SearchContext';
import { HistoryItem } from '../../common/context/search/dtos/HistoryItem';
import HistorySearchItem from './HistorySearchItem';

import styles from './SearchHistory.module.css';
import { useAlerts } from 'common/AlertContext';

const SearchHistory = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'orderSearch.history' });
  const { searchHistory, triggerSearchHistoryUpdate, goToHistoryItem } = useSearchContext();
  const favs = searchHistory.filter((item) => item.isFavourite);
  const { clear } = useAlerts();
  const updateHistoryItems = (items: HistoryItem[], id?: string): void => {
    triggerSearchHistoryUpdate(items, id);
  };

  const toElement = (historyItem: HistoryItem): JSX.Element => {
    const index = searchHistory.indexOf(historyItem);
    return (
      <HistorySearchItem
        key={historyItem?.id}
        index={index}
        addItemToFavourites={() => {
          if (historyItem.isFavourite) {
            return;
          }
          const searchHistoryTemp = [...searchHistory];
          historyItem.isFavourite = true;
          searchHistoryTemp.splice(index, 1, historyItem);
          updateHistoryItems(searchHistoryTemp);
        }}
        goToOriginalSearchTab={async () => {
          if (historyItem.orderSearchState.attributes?.length === 1 && !historyItem.orderSearchState.attributes[0]?.value?.toString().length) {
            return;
          }
          clear();
          goToHistoryItem(historyItem);
        }}
        deleteItemFromHistory={() => {
          const searchHistoryTemp = [...searchHistory];
          searchHistoryTemp.splice(index, 1);
          updateHistoryItems(searchHistoryTemp);
        }}
        isFavourite={historyItem.isFavourite}
        orderSearchState={historyItem.orderSearchState}
        historyItem={historyItem}
      />
    );
  };

  const byDateDesc = (a: HistoryItem, b: HistoryItem): number => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB.getTime() - dateA.getTime();
  };

  return (
    <div className={styles.searchHistory}>
      <div className={styles.savedHeaderContainer}>
        <DHLHeadline type="h5">{t('saved')}</DHLHeadline>
        <span className={styles.subMessage}>
          ({favs.length} {t('result.of')} 20)
        </span>
      </div>
      {searchHistory
        ?.filter(({ isFavourite }: { isFavourite: boolean }) => isFavourite)
        .sort(byDateDesc)
        .map(toElement)}
      <DHLHeadline type="h5">{t('last')}</DHLHeadline>
      {searchHistory
        ?.filter(({ isFavourite }: { isFavourite: boolean }) => !isFavourite)
        .sort(byDateDesc)
        .map(toElement)}
    </div>
  );
};
export default SearchHistory;
