import React from 'react';
import { Accordion, AccordionItemOverview, AccordionButtonOverview } from 'common/components/Accordion';
import { t, use } from 'i18next';
import { OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { PalletsOverview } from '../PalletsOverview';
import { OrderDestination } from 'generated';
import classes from '../palletOverview.module.css';
import { useTranslation } from 'i18n';

export type PalletsOverviewAccordianProps = {
  destinations: OrderDestination[];
  id?: string;
};
export const PalletsOverviewAccordian = ({ destinations, id }: PalletsOverviewAccordianProps): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'destinationDetailSection' });

  return (
    <OrderOverviewWidget width={3}>
      <div className={classes.palletsOverviewAccod}>
        {destinations?.length > 0 && (
          <Accordion allowMultiple={true}>
            {(register) => (
              <>
                <AccordionItemOverview {...register}>
                  <AccordionButtonOverview>{t('palletsOverview')}</AccordionButtonOverview>
                  <PalletsOverview destinations={destinations} id={id || ''} />
                </AccordionItemOverview>
              </>
            )}
          </Accordion>
        )}
      </div>
    </OrderOverviewWidget>
  );
};
