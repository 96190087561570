// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderMergeModal_mergeOrderModal__\\+hqPu div:first-child {
  max-width: 1400px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/OrderMerge/components/OrderMergeModal/OrderMergeModal.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".mergeOrderModal div:first-child {\n  max-width: 1400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mergeOrderModal": `OrderMergeModal_mergeOrderModal__+hqPu`
};
export default ___CSS_LOADER_EXPORT___;
