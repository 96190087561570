import React, { ReactElement, useEffect, useRef, useState, VFC } from 'react';
import { uid } from 'react-uid';

import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { OrderOverviewRow, OrderOverviewWidget } from '../../../../common/components/molecule/OrderOverview';
import { useTranslation } from 'i18n';
import { LetterTypes } from 'order/common/components/QtyAllocation/dtos/LetterTypes';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { determineLetterTypeByFormat } from 'order/common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { maxiBriefDiscountAvailable } from '../../../utils/maxiBriefDeliveryDiscountDiscontinuedDate';

export type ShipmentQuantityFeeReductionProps = {
  qtyAlloc: QtyAllocation[];
  loaded?: boolean;
  pickupDate: Date;
};

export const ShipmentQuantityFeeReduction: VFC<ShipmentQuantityFeeReductionProps> = ({ qtyAlloc, loaded, pickupDate }) => {
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4ShipmentFeeReduction' });
  const [calculated, setCalculated] = useState(false);
  const [childRows, setChildRows] = useState<ReactElement[]>([]);
  const totalPartialServiceDiscount = useRef(0);
  const totalIdDiscount = useRef(0);
  const totalTermDiscount = useRef(0);
  const lang = useAuthContext().user.language;
  const { allocationsCount, allocationsTotal } = useOrderContext();

  useEffect(() => {
    if (loaded) {
      const calcQty = {
        [LetterTypes.Standard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0 },
        [LetterTypes.Compact]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0 },
        [LetterTypes.Large]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0 },
        [LetterTypes.Maxi]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0 },
        [LetterTypes.Postcard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0 }
      };

      for (const q of qtyAlloc) {
        if (determineLetterTypeByFormat(q.format)) {
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount =
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount + q.quantity;
          if (q.containsIds)
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount + q.quantity;
          if (q.flexOption)
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount + q.quantity;
        }
      }
      calcQty[LetterTypes.Maxi].termDiscount = maxiBriefDiscountAvailable(pickupDate) ? calcQty[LetterTypes.Maxi].termDiscount : 0;
      const rows: ReactElement[] = [];
      let internalTotalPartialServiceDiscount = 0;
      let internalTotalIdDiscount = 0;
      let internalTotalTermDiscount = 0;
      Object.entries(calcQty).forEach(([key, value], id) => {
        internalTotalPartialServiceDiscount = internalTotalPartialServiceDiscount + value.partialServiceDiscount;
        internalTotalIdDiscount = internalTotalIdDiscount + value.idDiscount;
        internalTotalTermDiscount = internalTotalTermDiscount + value.termDiscount;
        const generatedID = uid(`${id}-${'shipmentFeeReductionChildRows'}`);
        if (value.partialServiceDiscount || value.idDiscount || value.termDiscount)
          rows.push(
            <tr key={generatedID} style={{ lineHeight: '24px' }}>
              <td>{key === LetterTypes.Maxi && !maxiBriefDiscountAvailable(pickupDate) ? 'Maxibrief (Laufzeit E+1)' : oc(key)}</td>
              <td style={{ textAlign: 'right' }}>{value.partialServiceDiscount.toLocaleString(lang)}</td>
              <td style={{ textAlign: 'right' }}>{value.idDiscount.toLocaleString(lang)}</td>
              <td style={{ textAlign: 'right' }}>{value.termDiscount.toLocaleString(lang)}</td>
            </tr>
          );
      });
      totalPartialServiceDiscount.current = internalTotalPartialServiceDiscount;
      totalIdDiscount.current = internalTotalIdDiscount;
      totalTermDiscount.current = internalTotalTermDiscount;
      setChildRows(rows);
      setCalculated(true);
    }
  }, [loaded, qtyAlloc]);

  return (
    <OrderOverviewRow>
      <OrderOverviewWidget width={3} title={oc('SQFeeReduction')}>
        {loaded && calculated ? (
          <table>
            <thead>
              <tr style={{ lineHeight: '24px' }}>
                <th>{oc('product')}</th>
                <th style={{ textAlign: 'right' }}>{oc('SQPartialDiscount')}</th>
                <th style={{ textAlign: 'right' }}>{oc('SQIdDiscount')}</th>
                <th style={{ textAlign: 'right' }}>{oc('SQTermDiscount')}</th>
              </tr>
            </thead>
            <tbody>{childRows}</tbody>
            <tfoot>
              <tr style={{ lineHeight: '24px' }}>
                <th>{oc('total')}</th>
                <th style={{ textAlign: 'right' }}>{totalPartialServiceDiscount.current.toLocaleString(lang)}</th>
                <th style={{ textAlign: 'right' }}>{totalIdDiscount.current.toLocaleString(lang)}</th>
                <th style={{ textAlign: 'right' }}>{totalTermDiscount.current.toLocaleString(lang)}</th>
              </tr>
            </tfoot>
          </table>
        ) : (
          <div style={{ textAlign: 'center' }}>
            {oc('calculating')}
            <div>{`${Math.ceil((allocationsCount / allocationsTotal) * 100)}% ${oc('loaded')}`}</div>
          </div>
        )}
      </OrderOverviewWidget>
    </OrderOverviewRow>
  );
};
