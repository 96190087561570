// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderFilter_tabList__Z17kf {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.OrderFilter_tabContainer__cJBgO {
  margin-left: 1.6875em;
}

.OrderFilter_selectAttribute__AUHOI {
  font-weight: 500;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 220px;
}

.OrderFilter_selectAttribute__AUHOI:focus {
  z-index: 1;
  position: relative;
}
.OrderFilter_tabItem__dipU- {
  color: #323232;
}

.OrderFilter_fontSize12__3MbD6 {
  font-size: 12px !important;
}
.OrderFilter_additionalSpace__hpRiy {
  padding-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/filter/OrderFilter.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".tabList {\n  border-radius: 0 !important;\n  margin-bottom: 0 !important;\n}\n\n.tabContainer {\n  margin-left: 1.6875em;\n}\n\n.selectAttribute {\n  font-weight: 500;\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n  width: 220px;\n}\n\n.selectAttribute:focus {\n  z-index: 1;\n  position: relative;\n}\n.tabItem {\n  color: #323232;\n}\n\n.fontSize12 {\n  font-size: 12px !important;\n}\n.additionalSpace {\n  padding-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabList": `OrderFilter_tabList__Z17kf`,
	"tabContainer": `OrderFilter_tabContainer__cJBgO`,
	"selectAttribute": `OrderFilter_selectAttribute__AUHOI`,
	"tabItem": `OrderFilter_tabItem__dipU-`,
	"fontSize12": `OrderFilter_fontSize12__3MbD6`,
	"additionalSpace": `OrderFilter_additionalSpace__hpRiy`
};
export default ___CSS_LOADER_EXPORT___;
