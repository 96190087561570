import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment/moment';
import { useAuthContext } from 'common/context/auth/AuthContext';

import { styles } from './EinlieferungslisteStyles';
import {
  GetDialogPostOrderRep,
  GetDialogPostWurfSpecialOrderRep,
  GetPostaktuellOrderRep,
  OrderCustomerRoleEnum,
  OrderFeeRep,
  ProductionFacilityTO,
  ShipmentData
} from 'generated';
import { PostLogo } from '../pdf/PostLogo';
import { toFormattedDate, toFormattedInt } from 'common/formatting';
import { getOrderIdBarcodeImageUrl } from './EinlieferungslisteBarcodes';
import { getDeliveryPoint, getPickupDepot } from '../utils/productionFacilityUtils';
import { now } from 'common/utils/timeAndDate';
import { generateAndDownloadPdf } from '../utils/pdfUtils';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { Product } from 'order/productGroups/common/services/ProductService';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
import { PostageType } from 'order/common/dtos/PostageType';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadEinlieferungslistePdfDiP = async (
  printVariantenliste: boolean,
  order:
    | ({ productGroup: 'DIALOGPOST' } & GetDialogPostOrderRep)
    | ({ productGroup: 'DIALOGPOSTWURFSPEZIAL' } & GetDialogPostWurfSpecialOrderRep)
    | ({ productGroup: 'POSTAKTUELL' } & GetPostaktuellOrderRep),
  baseProducts: Product[],
  dependentProductDescs: string[],
  validProductionFacilities: ProductionFacilityTO[],
  version: string | undefined,
  finished: () => void
): Promise<void> => {
  try {
    const doc = (
      <EinlieferungslisteDiP
        printVariantenliste={printVariantenliste}
        order={order}
        baseProducts={baseProducts}
        dependentProductDescs={dependentProductDescs}
        validProductionFacilities={validProductionFacilities}
        version={version}
      />
    );
    await generateAndDownloadPdf(doc, order, 'Einlieferungsliste');
  } catch (e) {
    console.error(`${now()} - error printing Einlieferungsliste: ${e}`);
  } finally {
    finished();
  }
};

interface EinlieferungslisteDiPProps {
  printVariantenliste: boolean;
  order:
    | ({ productGroup: 'DIALOGPOST' } & GetDialogPostOrderRep)
    | ({ productGroup: 'DIALOGPOSTWURFSPEZIAL' } & GetDialogPostWurfSpecialOrderRep)
    | ({ productGroup: 'POSTAKTUELL' } & GetPostaktuellOrderRep);
  baseProducts: Product[];
  dependentProductDescs: string[];
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
}

export const EinlieferungslisteDiP = ({
  printVariantenliste,
  order,
  baseProducts,
  dependentProductDescs,
  validProductionFacilities,
  version
}: EinlieferungslisteDiPProps): JSX.Element => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const isPostaktuell = order.orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL;
  console.log(order, 999);
  const originator = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Originator);
  const submitter = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Submitter);
  const payer = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Payer);
  const baseProduct = baseProducts.find(
    (product) => product.productNumber === (order as GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep).baseProduct?.productNumber
  );
  const deliveryPoint = order?.orderDetail?.pickup ? undefined : getDeliveryPoint(order?.orderDetail?.productionPlantId, validProductionFacilities);
  const pickupDepot = order?.orderDetail?.pickup ? getPickupDepot(order, validProductionFacilities) : undefined;

  // // billing
  // const getFeeByType = (type: string): OrderFeeRep | undefined => order?.orderPrice?.orderFees?.find((sc) => sc.type === type);
  // const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;
  // const sumAuftragswert = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE') + getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  // const sumEntgeltermaessigung = getFeeValueByType('OM_PORTAL_REDUCTION');
  // const sumGesamtOhneUSt = sumAuftragswert + sumEntgeltermaessigung;
  // const sumGesamtMitUSt = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');
  // const sumUSt = sumGesamtMitUSt - sumGesamtOhneUSt;
  // const sumBereitsGezahlt = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  // const sumRestforderung = sumGesamtMitUSt - sumBereitsGezahlt;
  // const currency = order?.orderPrice?.orderFees?.[0]?.amount?.currency;

  // billing information calculations
  const getFeeByType = (type: string): OrderFeeRep | undefined => order?.orderPrice?.orderFees?.find((sc) => sc.type === type);
  const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;
  // Order value -- Auftragswert
  const sumAuftragswert = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE') + getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  // Fee reduction -- EntgeltermßUigung
  const sumEntgeltermaessigung = getFeeValueByType('OM_PORTAL_REDUCTION');
  // Already paid amount -- Bereits abgerechnet
  const sumBereitsGezahlt = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  // Total Sum or remaining amount -- Restforderung inkl. USt.
  const sumRestforderung = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');
  // Calculations are based on netting table -- VAT or Ust.
  const shippingChargeWithoutVAT = getFeeByType('OM_PORTAL_POSITION_RELATED_CHARGE')?.amount?.value || 0;
  const additionalChargeWithoutVAT = getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  const summeEntgelte = shippingChargeWithoutVAT + additionalChargeWithoutVAT + sumEntgeltermaessigung;
  const sumUSt = sumRestforderung - (summeEntgelte + sumBereitsGezahlt);
  // Currency
  const currency = order?.orderPrice?.orderFees?.[0]?.amount?.currency;
  const language = useAuthContext().user.language;

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '6mm' }, styles.lineContainerStreched]}>
            <Text style={styles.pageHeading}>
              {order.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
                ? 'Einlieferungsliste Postwurfspezial'
                : order.orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL
                ? 'Einlieferungsliste Postaktuell'
                : 'Einlieferungsliste Dialogpost national'}
            </Text>
            <Text>{`Auftragsnummer: ${order.orderId}`}</Text>
          </View>
          <View style={styles.lineContainerStreched}>
            <Text style={[{ minWidth: '60mm' }, styles.paddingTop5]}>Auftragsbezeichnung:</Text>
            <Text style={[{ width: '100%' }, styles.paddingTop5]}>{customWordBreakForPDF(order.orderLabel, 35) || '--'}</Text>
            {(() => {
              const barcodeUrl = getOrderIdBarcodeImageUrl(order.orderId || '');
              return barcodeUrl ? <Image style={{ minWidth: '40mm', height: '10mm' }} src={barcodeUrl} /> : <></>;
            })()}
          </View>
          {order?.postage?.paymentClearingNumber ? (
            <View style={[styles.lineContainerStreched, { paddingTop: '2mm' }]}>
              <Text style={[{ minWidth: '60mm' }]}>Einlieferungsbelegnummer:</Text>
              <Text style={[{ width: '100%' }]}>{order?.postage?.paymentClearingNumber}</Text>
            </View>
          ) : (
            <></>
          )}
          {/* Kundendaten */}
          <View style={[styles.groupContainer, { marginTop: '4mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '1.5mm' }]}>Kundendaten</Text>
            <View style={styles.lineContainer}>
              <View style={[{ width: '100%' }, styles.linesContainer]}>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Absender:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${originator?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{originator?.name}</Text>
                  </View>
                </View>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Einlieferer:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${submitter?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{submitter?.name}</Text>
                  </View>
                </View>
                <View style={[{ marginBottom: '1mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Zahlungspflichtiger:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${payer?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{payer?.name}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Einlieferungsinformationen */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>
              {order?.orderDetail?.pickup === true ? 'Abholinformationen' : 'Einlieferungsinformationen'}
            </Text>{' '}
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order?.orderDetail?.pickup === true ? 'Abholdatum:' : 'Einlieferungsdatum:'}</Text>
              <Text style={{ width: '100%' }}>{toFormattedDate(order.orderDetail?.date)}</Text>
            </View>
            {/* <View style={styles.lineContainer}>*/}
            {/*  <Text style={{ minWidth: '60mm' }}>Einlieferungsuhrzeit:</Text>*/}
            {/*  <Text style={{ width: '100%' }}>{toFormattedTime(order.orderDetail?.date)}</Text>*/}
            {/* </View>*/}
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order.orderDetail?.pickup ? 'Disponierendes Depot:' : 'Einlieferungsstelle:'}</Text>
              <Text style={{ width: '100%' }}>
                {order.orderDetail?.pickup
                  ? `${pickupDepot?.catalogId || ''} ${pickupDepot?.comment || ''}`
                  : `${deliveryPoint?.address?.postCode || ''} ${deliveryPoint?.comment || ''}`}
              </Text>
            </View>
            {(order?.orderDetail?.submissionType === 'T' || order?.orderDetail?.submissionType === 'S') && (
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Schlusskennzeichen {'\n'} der Teileinlieferung:</Text>
                <Text style={{ width: '100%', marginTop: '5mm' }}>
                  {order.orderDetail?.submissionType === 'S' ? 'ja' : order.orderDetail?.submissionType === 'T' ? 'nein' : ''}
                </Text>
              </View>
            )}
          </View>
          {/* Produktdaten */}
          <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '1mm' }]}>Produktdaten</Text>
          </View>
          {isPostaktuell ? (
            <View style={styles.gridContainer}>
              <View style={styles.gridRowContainer}>
                <Text style={[styles.gridRowItem, styles.subTextHeader]}>Basisprodukt</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight, styles.subTextHeader]}>Sendungsmenge</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight, styles.subTextHeader]}>Einzelgewicht in g</Text>
              </View>
              {(order as GetPostaktuellOrderRep).letterBaseProducts?.map((np, i) => {
                return (
                  <View style={styles.gridRowContainer} key={`productdata_${i}`}>
                    <Text style={styles.gridRowItem}>{np.baseProduct.description}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{np.baseProduct.quantity?.toLocaleString(language)}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{np.baseProduct.weight}</Text>
                  </View>
                );
              })}

              <View style={styles.gridContainer}>
                <View style={styles.gridRowContainer}>
                  <Text style={[styles.gridRowItem, styles.subTextHeader]}>Zusatzleistungen</Text>
                  <Text style={[styles.gridRowItem, styles.subTextHeader]}>Weitere Produktmerkmale</Text>
                </View>
                {(() => {
                  const dependentProductItems = dependentProductDescs.filter((desc) => desc?.length > 0);
                  const variantItems = order.variants?.map((variant) => tCatalogValues(`lineItemVariant.display.${variant}`) || '') || [];
                  return new Array(Math.max(dependentProductItems.length, variantItems.length)).fill(0).map((item, idx) => {
                    return (
                      <View style={styles.gridRowContainer} key={`productdata_${idx}`}>
                        <Text style={styles.gridRowItem}>{dependentProductItems.shift() || ''}</Text>
                        <Text style={styles.gridRowItem}>{variantItems.shift() || ''}</Text>
                      </View>
                    );
                  });
                })()}
              </View>
            </View>
          ) : (
            <View style={styles.gridContainer}>
              <View style={styles.gridHeaderContainer}>
                <Text style={styles.gridHeaderItem}>Basisprodukt</Text>
                <Text style={styles.gridHeaderItem}>Zusatzleistungen</Text>
                <Text style={styles.gridHeaderItem}>Weitere Produktmerkmale</Text>
              </View>
              {(() => {
                const baseProductItems = [baseProduct?.descriptionShort];
                const dependentProductItems = dependentProductDescs.filter((desc) => desc?.length > 0);
                const variantItems = order.variants?.map((variant) => tCatalogValues(`lineItemVariant.display.${variant}`) || '') || [];
                return new Array(Math.max(baseProductItems.length, dependentProductItems.length, variantItems.length)).fill(0).map((item, idx) => {
                  return (
                    <View style={styles.lineContainer} key={`productdata_${idx}`}>
                      <View style={styles.gridRowContainer}>
                        <Text style={[styles.gridRowItem, { width: '33.33%', flexWrap: 'wrap' }]}>{baseProductItems.shift() || ''}</Text>
                        <Text style={[styles.gridRowItem, { width: '33.33%', flexWrap: 'wrap' }]}>{dependentProductItems.shift() || ''}</Text>
                        <Text style={[styles.gridRowItem, { width: '33.33%', flexWrap: 'wrap', paddingRight: '25px' }]}>
                          {variantItems.shift() || ''}
                        </Text>
                      </View>
                    </View>
                  );
                });
              })()}
            </View>
          )}
          {/* Sendungsmenge und –gewicht */}
          {(() => {
            switch (order.orderCategory) {
              case OrderCategory.DIP_EINZEL:
                return (
                  <>
                    <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
                      <Text style={styles.textHeader}>Sendungsmenge und –gewicht</Text>
                    </View>
                    <View>
                      <View style={styles.gridContainer}>
                        <View style={styles.gridHeaderContainer}>
                          <Text style={styles.gridHeaderItem}>Sendungsmenge</Text>
                          <Text style={styles.gridHeaderItem}>Aufgezahlt</Text>
                          <Text style={styles.gridHeaderItem}>Einzelgewicht in g</Text>
                        </View>
                        <View style={styles.gridRowContainer}>
                          <Text style={styles.gridRowItem}>
                            {toFormattedInt((order as GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep).baseProduct?.quantity) || ''}
                          </Text>
                          <Text style={styles.gridRowItem}>{toFormattedInt(order.additionalQuantity) || ''}</Text>
                          <Text style={styles.gridRowItem}>
                            {toFormattedInt((order as GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep).baseProduct?.weight) || ''}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </>
                );

              case OrderCategory.DIP_VARIO: {
                const sumQuantity = order.shipmentData?.map((shipment) => shipment?.totalQuantity || 0).reduceRight((acc, value) => acc + value, 0);
                const sumAdditionalQuantity = order.shipmentData
                  ?.map((shipment) => shipment?.additionalQuantity || 0)
                  .reduceRight((acc, value) => acc + value, 0);
                return (
                  <>
                    <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
                      <View style={styles.lineContainer}>
                        <Text style={styles.textHeader}>{`Sendungsmenge aller Varianten${
                          printVariantenliste ? ' (Weitere Details siehe Variantenliste)' : ''
                        }`}</Text>
                      </View>
                    </View>
                    <View>
                      <View style={styles.gridContainer}>
                        <View style={styles.gridHeaderContainer}>
                          <Text style={styles.gridHeaderItem}>Sendungsmenge</Text>
                          <Text style={styles.gridHeaderItem}>Aufgezahlt</Text>
                        </View>
                        <View style={styles.gridRowContainer}>
                          <Text style={styles.gridRowItem}>{toFormattedInt(sumQuantity) || ''}</Text>
                          <Text style={styles.gridRowItem}>{toFormattedInt(sumAdditionalQuantity) || ''}</Text>
                        </View>
                      </View>
                    </View>
                  </>
                );
              }

              case OrderCategory.DIP_SAMMEL: {
                const sumQuantity = order.shipmentData?.map((shipment) => shipment?.totalQuantity || 0).reduceRight((acc, value) => acc + value, 0);
                const sumAdditionalQuantity = order.shipmentData
                  ?.map((shipment) => shipment?.additionalQuantity || 0)
                  .reduceRight((acc, value) => acc + value, 0);
                return (
                  <>
                    <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
                      <View style={styles.lineContainer}>
                        <Text style={styles.textHeader}>{`Sendungsmenge einer Sammeleinlieferung${
                          printVariantenliste ? ' (Weitere Details siehe Sammeleinlieferungsliste)' : ''
                        }`}</Text>
                      </View>
                    </View>
                    <View>
                      <View style={styles.gridContainer}>
                        <View style={styles.gridHeaderContainer}>
                          <Text style={styles.gridHeaderItem}>Sendungsmenge</Text>
                          <Text style={styles.gridHeaderItem}>Aufgezahlt</Text>
                        </View>
                        <View style={styles.gridRowContainer}>
                          <Text style={styles.gridRowItem}>{toFormattedInt(sumQuantity) || ''}</Text>
                          <Text style={styles.gridRowItem}>{toFormattedInt(sumAdditionalQuantity) || ''}</Text>
                        </View>
                      </View>
                    </View>
                  </>
                );
              }

              default:
                return <></>;
            }
          })()}
          {/* Frankierung und Abrechnungsdaten */}
          <View style={styles.lineContainerStreched}>
            <View style={{ width: '45%' }}>
              <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
                <View style={styles.lineContainer}>
                  <Text style={styles.textHeader}>Frankierung</Text>
                </View>
              </View>
              <View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Art der Frankierung:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {order.postage?.type ? tCatalogValues(`postageType.display.${order.postage.type}`) : '--'}
                  </Text>
                </View>
                {!isPostaktuell && order.orderCategoryProductKey !== OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL && (
                  <View style={styles.lineContainer}>
                    <Text style={{ minWidth: '40mm' }}>Einlieferungsbelegnr.:</Text>
                    <Text style={{ width: '100%', textAlign: 'right' }}>{order.postage?.paymentClearingNumber || '--'}</Text>
                  </View>
                )}
              </View>
            </View>
            <View style={{ width: '45%' }}>
              <View style={[styles.groupContainer, { marginTop: '5mm' }]}>
                <View style={styles.lineContainer}>
                  <Text style={styles.textHeader}>Abrechnungsdaten</Text>
                </View>
              </View>
              <View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Auftragswert:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumAuftragswert && currency
                      ? sumAuftragswert.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Entgeltermäßigung:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumEntgeltermaessigung && currency
                      ? sumEntgeltermaessigung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Bereits abgerechnet:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumBereitsGezahlt && currency
                      ? sumBereitsGezahlt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumUSt && currency
                      ? sumUSt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={[styles.lineContainer]}>
                  <Text style={{ minWidth: '40mm', fontWeight: 'bold' }}>Restforderung inkl. USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right', fontWeight: 'bold' }}>
                    {sumRestforderung && currency
                      ? sumRestforderung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
        {/* Varianten- or Sammeleinlieferungsliste on next page(s) */}
        {printVariantenliste && order.orderCategory === OrderCategory.DIP_VARIO && (
          <>
            <View style={[{ marginTop: '6mm' }, styles.lineContainer]} break>
              <Text style={styles.pageHeading}>Variantenliste</Text>
            </View>
            <View style={styles.lineContainer}>
              <Text style={styles.textHeader}>Sendungsmengen und -gewichte der Varianten</Text>
            </View>
            <View style={styles.groupContainer}>
              <View style={styles.gridContainer}>
                <View style={styles.gridHeaderContainer}>
                  <Text style={styles.gridHeaderItem}>Bezeichnung</Text>
                  <Text style={styles.gridHeaderItem}>Sendungsmenge</Text>
                  <Text style={styles.gridHeaderItem}>Aufgezahlt</Text>
                  <Text style={styles.gridHeaderItem}>Einzelgewicht in g</Text>
                </View>
                {order.shipmentData
                  ?.map((shipment) => shipment as ShipmentData)
                  ?.sort((a, b) => (a?.referenceShipmentId || 0) - (b?.referenceShipmentId || 0))
                  .map((shipment, idx) => {
                    return (
                      <View style={styles.gridRowContainer} key={`shipment_${idx}`}>
                        <Text style={styles.gridRowItem}>{shipment?.description || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.totalQuantity) || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.additionalQuantity) || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.individualWeight) || ''}</Text>
                      </View>
                    );
                  })}
              </View>
            </View>
          </>
        )}
        {printVariantenliste && order.orderCategory === OrderCategory.DIP_SAMMEL && (
          <>
            <View style={[{ marginTop: '6mm' }, styles.lineContainer]} break>
              <Text style={styles.pageHeading}>Sammeleinlieferungsliste</Text>
            </View>
            <View style={styles.lineContainer}>
              <Text style={styles.textHeader}>Sendungsmengen und -gewichte der Sammeleinlieferung</Text>
            </View>
            <View style={styles.groupContainer}>
              <View style={styles.gridContainer}>
                <View style={styles.gridHeaderContainer}>
                  <Text style={styles.gridHeaderItem}>Bezeichnung</Text>
                  <Text style={styles.gridHeaderItem}>Sendungsmenge</Text>
                  <Text style={styles.gridHeaderItem}>Aufgezahlt</Text>
                  <Text style={styles.gridHeaderItem}>Einzelgewicht in g</Text>
                </View>
                {order.shipmentData
                  ?.map((shipment) => shipment as ShipmentData)
                  ?.sort((a, b) => (a?.referenceShipmentId || 0) - (b?.referenceShipmentId || 0))
                  .map((shipment, idx) => {
                    return (
                      <View style={styles.gridRowContainer} key={`shipment_${idx}`}>
                        <Text style={styles.gridRowItem}>{shipment?.description || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.totalQuantity) || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.additionalQuantity) || ''}</Text>
                        <Text style={styles.gridRowItem}>{toFormattedInt(shipment?.individualWeight) || ''}</Text>
                      </View>
                    );
                  })}
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  ) : (
    <></>
  );
};
