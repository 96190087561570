export interface EnvironmentValues {
  whitelistingEnabled: boolean;
  searchByOrderTypeEnabled: boolean;
  environment: string;
  maintenanceEnabled: boolean;
}

export const getQueryParam = (paramName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
};

export const isFeatureEnabled = (queryParameter: string, envVariableValue?: string | boolean): boolean => {
  const queryParameterValue = getQueryParam(queryParameter);
  return queryParameterValue != null ? queryParameterValue === 'true' : envVariableValue === 'true' || envVariableValue === true;
};

export function envValues(): EnvironmentValues {
  return {
    whitelistingEnabled: isFeatureEnabled('whitelistingEnabled', process.env.REACT_APP_WHITELISTING_ENABLED),
    searchByOrderTypeEnabled: isFeatureEnabled('searchByOrderTypeEnabled', process.env.REACT_APP_SEARCH_BY_ORDER_TYPE_ENABLED),
    environment: process.env.REACT_APP_MY_ENV || '',
    maintenanceEnabled: false
  };
}
