import React, { createRef, FC, useState } from 'react';
import { reaction } from 'mobx';
import { DHLFilteredComboBox, FetchAdapter, FormField, ValidationRuleDataStore } from '@gkuis/gkp-base-widgets/dist/lib';
import { DHLFilteredComboBoxDataType } from '@gkuis/gkp-base-widgets/dist/lib/components/molecules/DHLFilteredComboBox/DHLFilteredComboBox';

import IconSearchButton from 'common/components/IconSearchButton';
import { DefaultNoOpResource } from 'common/services/DefaultNoOpResource';
import { ProcedureTDO } from 'common/services/ProcedureService';
import styles from './ProcedureComboBox.module.css';

type ProcedureComboBoxProps = {
  procedures: ProcedureTDO[];
  onSelect: (procedure: string) => void;
};

const ProcedureComboBox: FC<ProcedureComboBoxProps> = ({ procedures, onSelect }) => {
  const wrapper = createRef<HTMLDivElement>();
  const [data] = useState(
    procedures
      .map(({ id, name }) => ({ value: id, additionalInformation: name }))
      // currently do not support procedure "04 Dialogpost schwer" Schwerlast
      .filter((p) => p.value !== '04')
      .filter((p) => p.value) as DHLFilteredComboBoxDataType[]
  );
  const [formField] = useState(
    new FormField<string>('procedure', '', DefaultNoOpResource, new ValidationRuleDataStore(new FetchAdapter(undefined), '', ''))
  );

  reaction(
    () => formField.value,
    (input, prev) => {
      if (!input || prev.length) {
        return;
      }
      selectionHandler(input);
    }
  );

  const selectionHandler = (idInput: string): void => {
    const selectedValue = data.find(({ value }) => value === idInput);
    if (selectedValue?.value) {
      onSelect(selectedValue.value);
      formField.updateValue('');
    }
  };

  return (
    <>
      <div className={styles.comboBox} ref={wrapper}>
        <DHLFilteredComboBox
          name={'select'}
          data={[{ value: '', additionalInformation: '' }, ...data]}
          formField={formField}
          emptyListText={DefaultNoOpResource.getError('procedures.emptyList')}
          maxShownElements={20}
        />
      </div>
      <IconSearchButton name="orderSearchFilterAttrSelectableAdd" onClick={() => selectionHandler(formField.value)} icon={'plus'} />
    </>
  );
};

export default ProcedureComboBox;
