export enum OrderCustomerRole {
  Payer = 'PAYER',
  Submitter = 'SUBMITTER',
  Producer = 'PRODUCER',
  Originator = 'ORIGINATOR',
  Agency = 'AGENCY',
  Beneficiary = 'BENEFICIARY',
  Owner = 'OWNER',
  Consolidator = 'CONSOLIDATOR',
  DiscountRecipient = 'DISCOUNT_RECIPIENT',
  InvoiceRecipient = 'INVOICE_RECIPIENT',
  Contractor = 'CONTRACTOR',
  ContractorPremium = 'CONTRACTOR_PREMIUM',
  GoodsRecipient = 'GOODS_RECIPIENT',
  ShipmentRecipient = 'SHIPMENT_RECIPIENT',
  CreditRecipient = 'CREDIT_RECIPIENT',
  AvisRecipient = 'AVIS_RECIPIENT',
  DpOptimiser = 'DP_OPTIMISER',
  ReminderRecipient = 'REMINDER_RECIPIENT'
}
