import React, { FC } from 'react';
import { AlertTypes } from './dtos/AlertTypes';
import classNames from 'classnames';
import styles from './alerts.module.css';
import { Alert } from './Alert';

export type AlertsProps = {
  alerts: any[];
};
export const Alerts: FC<AlertsProps> = (props: AlertsProps) => {
  const { alerts } = props;
  return (
    <>
      {alerts?.length !== 0 &&
        alerts?.map((alert, key) => {
          return (
            <Alert
              key={`${key}-${alert.id}`}
              type={alert.type}
              id={alert.id}
              title={alert.title}
              closeable={alert.type.toString().toLowerCase() !== AlertTypes.Error.toString().toLowerCase()}
              description={alert.description}
              className={classNames(styles.alert)}
            />
          );
        })}
    </>
  );
};
