import { attributesEqual, OrderSearchAttribute } from '../../../../orderSearch/services/orderSearchAttribute';
import { OrderSearchKey } from '../dtos/OrderSearchKey';

export const addAttributes = (attribute: OrderSearchAttribute, attributes: OrderSearchAttribute[]): OrderSearchAttribute[] | null => {
  const newAttributes = [...attributes];
  const alreadyExisting = attributes.some((value) => attributesEqual(value, attribute));
  const keyIndex = attributes.findIndex(({ key }) => key === attribute.key);
  const dateAttribute = attribute.key === OrderSearchKey.Date;
  const emptyStringValue = typeof attribute.value === 'string' && !attribute.value;

  if (keyIndex >= 0 && dateAttribute && !alreadyExisting) {
    attributes.splice(keyIndex, 1);
  }

  if (alreadyExisting || emptyStringValue) {
    return null;
  }

  if (!dateAttribute) {
    attribute.value = attribute.value.toString();
  }

  newAttributes.push(attribute);

  return newAttributes;
};
