import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Spinner.module.css';

export enum SpinnerColors {
  Red = 'Red',
  White = 'white'
}

export interface SpinnerProps {
  testid?: string;
  spinnerColor?: SpinnerColors;
  sizing?: 'lg' | 'md' | 'sm' | 'xs';
  message?: string | JSX.Element;
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ sizing, testid, spinnerColor, message, className }) => {
  return (
    <div data-testid={`${testid}-wrapper`} className={classNames(styles.spinnerWrapper)}>
      <div>
        <div
          data-testid={`${testid}-spinner`}
          className={classNames(
            styles.spinner,
            styles['spinner_' + sizing],
            spinnerColor === SpinnerColors.White ? styles.spinnerWhite : styles.spinnerRed,
            className
          )}
        />
        {message ? <div className={styles.message}>{message}</div> : null}
      </div>
    </div>
  );
};
