import React, { VFC } from 'react';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import AMDHLRadioButtonCard from 'common/components/AMDHLRadioButtonCard';
import { useAuthContext } from 'common/context/auth/AuthContext';
import classes from './ProductGroupInput.module.css';
import { OrderCategory } from '../../../../../common/context/order/dtos/OrderCategory';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useProductGroupTiles } from './useProductGroupTiles';
import { envValues } from 'common/environment/env';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export type ProductGroupInputProps = {
  value?: string;
  onChange?: (group: ProductGroup, category: OrderCategory, key: OrderCategoryProductKey) => void;
  disabled?: boolean;
  isDvBriefOrder?: boolean;
  selectedKey?: OrderCategoryProductKey;
  workflowType: OrderMode.CREATE | OrderMode.COPY | OrderMode.CHANGE;
};

export const ProductGroupInput: VFC<ProductGroupInputProps> = ({ selectedKey, onChange, disabled, isDvBriefOrder, workflowType }) => {
  const { hasPermission } = useAuthContext();
  const { productGroupTiles } = useProductGroupTiles();

  const { checkWhitelistingKey } = useAuthContext();
  const checkWhitelist = {
    [OrderMode.CREATE]: (key: OrderCategoryProductKey) => checkWhitelistingKey(key, WhitelistMethod.CREATE),
    [OrderMode.COPY]: (key: OrderCategoryProductKey) => checkWhitelistingKey(key, WhitelistMethod.COPY),
    [OrderMode.CHANGE]: (key: OrderCategoryProductKey) => checkWhitelistingKey(key, WhitelistMethod.CHANGE)
  }[workflowType];

  return (
    <>
      {productGroupTiles.map(({ title, children, permission }, index) => {
        if (permission && !hasPermission(permission)) return <></>;
        const filteredOrderCategories = envValues().whitelistingEnabled
          ? children.filter(
              (child) =>
                child.keys.some((key) => checkWhitelist?.(key)) ||
                (workflowType !== OrderMode.CREATE && child.orderCategory === OrderCategory.DV_BRIEF) ||
                (workflowType !== OrderMode.CREATE && child.orderCategory === OrderCategory.POSTAKTUELL) ||
                (workflowType !== OrderMode.CREATE && child.orderCategory === OrderCategory.BRIEF_DMC_INTERNATIONAL)
            )
          : children;
        return (
          filteredOrderCategories.length > 0 && (
            <section key={title + index} className={classes.group} data-testid={`order-category-key-selector-${selectedKey}`}>
              <DHLHeadline key={'productHeading' + title} type="h6" color="gray">
                {title}
              </DHLHeadline>
              <div className={classes.products}>
                {filteredOrderCategories.map(({ title, link, text, productGroup, orderCategory, keys, tooltip }) => {
                  if (!keys.some((key) => checkWhitelist?.(key)) && orderCategory !== OrderCategory.DV_BRIEF) return null;
                  if (
                    (workflowType !== OrderMode.CHANGE && orderCategory === OrderCategory.DV_BRIEF) ||
                    (workflowType !== OrderMode.CHANGE && orderCategory === OrderCategory.POSTAKTUELL) ||
                    (workflowType !== OrderMode.CHANGE && orderCategory === OrderCategory.BRIEF_DMC_INTERNATIONAL)
                  )
                    return null;
                  const isSelected: boolean = selectedKey ? keys.includes(selectedKey) : false;
                  return (
                    <>
                      <AMDHLRadioButtonCard
                        key={'radioBtnComp' + productGroup + orderCategory}
                        title={title}
                        isSelected={isSelected}
                        moreLink={link}
                        onSelect={() => onChange?.(productGroup as ProductGroup, orderCategory as OrderCategory, keys[0])}
                        disabled={disabled && !isSelected}
                        tooltip={tooltip}
                      >
                        {text}
                      </AMDHLRadioButtonCard>
                    </>
                  );
                })}
              </div>
            </section>
          )
        );
      })}
    </>
  );
};
