import React, { FC, useState, useEffect } from 'react';
import i18n from 'i18n';
import { CustomTextInput, regexValidator } from 'order/common/components/CustomTextInput';
import { useTranslation } from 'react-i18next';
import { translationEnglish } from './Translations/englishTranslation';
import { translationGerman } from './Translations/germanTranslation';
import { useFormContext } from 'react-hook-form';
import { FormRow } from 'common/components/FormRow';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';

export interface MachineCodeInputProps {
  required: boolean;
  onChange?: (machineCode: string) => void;
  editing: boolean;
  name: string;
  postageMachineCode?: string;
  error?: string;
  controlledErrorState?: (errorState: boolean) => void;
  disabled?: boolean;
}

i18n.addResourceBundle('de', 'machineCodeInput', translationGerman);
i18n.addResourceBundle('en', 'machineCodeInput', translationEnglish);
const MACHINE_CODE_MAXLENGTH = 10;

export const MachineCodeInput: FC<MachineCodeInputProps> = (props) => {
  const [error, setError] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState<string | undefined>(undefined);
  const { setValue } = useFormContext();
  const { t } = useTranslation('machineCodeInput');

  useEffect(() => {
    props.controlledErrorState?.(error);
  }, [error]);

  const frankingIdRegex = /[1-6][D]([0-9A-Fa-f]{8})$/g;

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length === 0) {
      if (props.required) {
        setError(true);
        setErrorMessageKey('length');
      } else {
        setError(false);
        setValue('postageMachineCode', e.target.value);
      }
    } else if (value.length < MACHINE_CODE_MAXLENGTH) {
      setError(true);
      setErrorMessageKey('length');
    } else if (regexValidator(value, frankingIdRegex) === null) {
      setError(true);
      setErrorMessageKey('format');
    } else {
      setError(false);
      setValue('postageMachineCode', value);
    }
    props.onChange?.(value);
  };

  return (
    <>
      <OrderStepSection headline={t('title')}>
        <FormRow mode="two-col">
          <CustomTextInput
            maxLength={MACHINE_CODE_MAXLENGTH}
            required={props.required}
            type={'text'}
            name={props.name}
            label={t('label')}
            onChange={onChangeHandler}
            error={props.error ?? error ? t(`error.${errorMessageKey}`) : undefined}
            testid={'MachineCodeInput'}
            defaultValue={props.postageMachineCode ?? ''}
            disabled={props.disabled}
          />
        </FormRow>
      </OrderStepSection>
    </>
  );
};
