export enum StandardLetter {
  Standardbrief = '102100001',
  StandardbriefBZE = '1102122',
  StandardbriefBZA = '1102130'
}

export enum CompactLetter {
  Kompaktbrief = '102200001',
  KompaktbriefBZE = '1102222',
  KompaktbriefBZA = '1102230'
}

export enum CapitalLetter {
  Großbrief = '102300001',
  GroßbriefBZE = '1102322',
  GroßbriefBZA = '1102330'
}

export enum MaxLetter {
  Maxibrief = '102400001',
  MaxibriefBZE = '1102422',
  MaxibriefBZA = '1102430'
}

export enum PostCard {
  Postkarte = '102500001',
  PostkarteBZE = '1102522',
  PostkarteBZA = '1102530'
}

export enum LetterTypes {
  Standard = 'Standardbrief',
  Compact = 'Kompaktbrief',
  Large = 'Großbrief',
  Maxi = 'Maxibrief',
  Postcard = 'Postkarte'
}
