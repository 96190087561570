import { DefaultDayRangePreset, getDefaultDayRange } from 'common/components/AMDHLDateRangeInput/DayRangePreset';
import moment, { Moment } from 'moment';
import { OrderSearchTDO } from 'order/common/services/OrderService';
import { DateRange } from './orderSearchAttribute';
import { extractSettlementNumber } from './SettlementNumber';
import { OrderSearchState } from '../../common/context/search/dtos/OrderSearchState';
import { OrderSearchKey } from '../../common/context/search/dtos/OrderSearchKey';

export const convertToTDO = (input: OrderSearchState, meta: Record<string, any> = {}): OrderSearchTDO => {
  const orderSearchTransferObject: OrderSearchTDO = {
    searchCriteria: {},
    firstDate: undefined,
    lastDate: undefined
  };

  for (const searchAttr of input.attributes) {
    if (searchAttr.key === OrderSearchKey.SettlementNumber) {
      setSettlementNumber(orderSearchTransferObject, searchAttr);
    } else if (searchAttr.key === OrderSearchKey.Date) {
      setDates(orderSearchTransferObject, searchAttr.value, meta);
    } else {
      setOrderData(orderSearchTransferObject, searchAttr.key, searchAttr.value);
    }
  }

  if (!orderSearchTransferObject.searchCriteria[OrderSearchKey.ZKZ] && input.type === 'press') {
    orderSearchTransferObject.searchCriteria[OrderSearchKey.OrderCategoryGroup] = ['PRESSEDISTRIBUTION'];
  }

  return orderSearchTransferObject;
};

const setOrderData = (orderSearchTransferObject: OrderSearchTDO, key: OrderSearchKey, value: string) => {
  if (orderSearchTransferObject.searchCriteria[key]) {
    orderSearchTransferObject.searchCriteria[key].push(value.trim());
  } else {
    orderSearchTransferObject.searchCriteria[key] = [value.trim()];
  }
};

const formatDate = (date: Date | Moment | undefined): string => {
  const dateAsMoment = moment(date);
  return dateAsMoment.format('yyyy-MM-DD');
};

const setDates = (orderSearchTransferObject: OrderSearchTDO, value: DateRange | DefaultDayRangePreset, meta: Record<string, any> = {}) => {
  if (typeof value === 'number') {
    if (value === DefaultDayRangePreset.USER_DEFINED && meta.pickUpDate) {
      const formattedDate = formatDate(meta.pickUpDate);
      orderSearchTransferObject.firstDate = formattedDate;
      orderSearchTransferObject.lastDate = formattedDate;
    } else {
      orderSearchTransferObject.firstDate = formatDate(getDefaultDayRange(value)?.getStartDate());
      orderSearchTransferObject.lastDate = formatDate(getDefaultDayRange(value)?.getEndDate());
    }
  } else {
    orderSearchTransferObject.firstDate = formatDate(value.start);
    orderSearchTransferObject.lastDate = formatDate(value.end);
  }
};

const setSettlementNumber = (tdo: OrderSearchTDO, searchAttr: { key: Exclude<OrderSearchKey, OrderSearchKey.Date>; value: string }) => {
  const decomposedSettlementNumber = extractSettlementNumber(searchAttr.value.replace(/\s+/g, ''));

  tdo.searchCriteria[OrderSearchKey.Payer] = [decomposedSettlementNumber.payer.trim()];
  tdo.searchCriteria[OrderSearchKey.Procedure] = [decomposedSettlementNumber.process.trim()];
  tdo.searchCriteria[OrderSearchKey.Participation] = [decomposedSettlementNumber.participation.trim()];

  const paymentClearingNumber = decomposedSettlementNumber.paymentClearingNumber?.trim();
  if (paymentClearingNumber) {
    tdo.searchCriteria[OrderSearchKey.PaymentClearingNumber] = [paymentClearingNumber];
  }
};
