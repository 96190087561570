import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import SearchHistory from '../SearchHistory';
import { AMDHLOrderSearchFilterDatePicker } from './AMDHLOrderSearchFilterDatePicker';
import { AMDHLOrderSearchFilterOrderNumberInput } from './AMDHLOrderSearchFilterOrderNumberInput';
import { AMDHLOrderSearchFilterSelectableTextInput } from './AMDHLOrderSearchFilterSelectableTextInput';
import { OrderSearchFilterContainer } from './OrderSearchFilterContainer';
import { envValues } from 'common/environment/env';
import { useAlerts } from 'common/AlertContext';

import styles from './OrderFilter.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { MyOrders } from '../MyOrders/MyOrders';

const OrderFilter = (): JSX.Element => {
  const { tabIndex, changeTab, searchTabs } = useSearchContext();
  const { clear } = useAlerts();
  const { t } = useTranslation('', { keyPrefix: 'orderFilter.tab' });
  const [selectedAttrType, setSelectedAttrType] = useState<OrderSearchKey>();

  const tabClasses = (p: { selected: boolean }) => classNames('tab-item tab-item-primary', styles.tabItem, { 'tab-item-primary-active': p.selected });

  const handleOnChange = async (type: OrderSearchType | number) => {
    changeTab(type);
    clear();
  };

  const setSelectedAttr = (type: OrderSearchKey) => {
    setSelectedAttrType(type);
  };

  return (
    <Tab.Group onChange={handleOnChange} selectedIndex={tabIndex}>
      <Tab.List className={classNames('nav nav-tabs dhlTabNavigation primary', styles.tabList)} as="ul" id="orderFilterTabs">
        <div className={classNames('dhlTabNavigationItemContainer primary', styles.tabContainer)}>
          <Tab as="li" className={tabClasses} data-testid={'tab1'}>
            {t('tab1')}
          </Tab>
          {searchTabs.includes(OrderSearchType.PRESS) && (
            <Tab as="li" className={tabClasses} data-testid={'tab2'}>
              {t('tab2')}
            </Tab>
          )}
          <Tab as="li" className={tabClasses} data-testid={'tab3'}>
            {t('tab3')}
          </Tab>
          <Tab as="li" className={tabClasses} data-testid={'tab4'}>
            {t('tab4')}
          </Tab>
          <Tab as="li" className={tabClasses} data-testid={'tab5'}>
            {t('tab5')}
          </Tab>
        </div>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <OrderSearchFilterContainer additionalSpace={styles.additionalSpace}>
            <AMDHLOrderSearchFilterOrderNumberInput nonNumeric={true} />
          </OrderSearchFilterContainer>
        </Tab.Panel>
        {searchTabs.includes(OrderSearchType.PRESS) && (
          <Tab.Panel>
            <OrderSearchFilterContainer additionalSpace={styles.additionalSpace} key="PRESS">
              <AMDHLOrderSearchFilterSelectableTextInput
                searchKeys={[
                  OrderSearchKey.ZKZ,
                  OrderSearchKey.Submitter,
                  OrderSearchKey.Originator,
                  OrderSearchKey.Payer,
                  OrderSearchKey.Producer,
                  OrderSearchKey.OrderId
                ]}
                setSelectedAttr={setSelectedAttr}
              />
              <AMDHLOrderSearchFilterDatePicker />
            </OrderSearchFilterContainer>
          </Tab.Panel>
        )}
        <Tab.Panel>
          <OrderSearchFilterContainer additionalSpace={styles.additionalSpace} key="ADVANCED">
            <AMDHLOrderSearchFilterSelectableTextInput
              orderMode={OrderMode.SEARCH}
              searchKeys={[
                OrderSearchKey.Submitter,
                OrderSearchKey.Originator,
                OrderSearchKey.Payer,
                OrderSearchKey.Producer,
                {
                  key: OrderSearchKey.ActionDescription,
                  initiallyDeactivated: true
                },
                {
                  key: OrderSearchKey.SettlementNumber,
                  initiallyDeactivated: true
                },
                {
                  key: OrderSearchKey.Procedure,
                  initiallyDeactivated: true
                },
                {
                  key: OrderSearchKey.Participation,
                  initiallyDeactivated: true
                },
                {
                  key: OrderSearchKey.PaymentClearingNumber,
                  initiallyDeactivated: true
                },
                { key: OrderSearchKey.ProductionState, initiallyDeactivated: true },
                ...(envValues().searchByOrderTypeEnabled
                  ? [
                      {
                        key: OrderSearchKey.OrderType,
                        initiallyDeactivated: true
                      }
                    ]
                  : [])
              ]}
              nonNumeric={selectedAttrType === OrderSearchKey.ActionDescription || selectedAttrType === OrderSearchKey.Participation}
              setSelectedAttr={setSelectedAttr}
            />
            <AMDHLOrderSearchFilterDatePicker />
          </OrderSearchFilterContainer>
        </Tab.Panel>
        <Tab.Panel>
          <SearchHistory key="HISTORY" />
        </Tab.Panel>
        <Tab.Panel>
          <MyOrders key="MYORDERS" />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default OrderFilter;
