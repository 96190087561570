// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderSection_grid__quv4w {
  display: flex;
  flex-wrap: wrap;
}

.HeaderSection_col_4__cs61s {
  width: 33.33%;
  flex-grow: 1;
  padding-right: 10px;
}

.HeaderSection_spacingStorno__CDoOH {
  margin-bottom: 1.5rem;
}

.HeaderSection_nopadding__KI2-z {
  padding: 0;
}

.HeaderSection_table__uobPF p {
  margin: 0;
  padding-bottom: 5px;
  word-wrap: break-word;
  max-width: 200px;
}

.HeaderSection_table__uobPF td:last-child p {
  font-weight: 500;
}

.HeaderSection_table__uobPF {
  width: 100%;
}

.HeaderSection_table__uobPF tr td:first-child {
  width: 35%;
}

.HeaderSection_rowAlignTop__tg7s9 {
  vertical-align: top;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/HeaderSection/HeaderSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".grid {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.col_4 {\n  width: 33.33%;\n  flex-grow: 1;\n  padding-right: 10px;\n}\n\n.spacingStorno {\n  margin-bottom: 1.5rem;\n}\n\n.nopadding {\n  padding: 0;\n}\n\n.table p {\n  margin: 0;\n  padding-bottom: 5px;\n  word-wrap: break-word;\n  max-width: 200px;\n}\n\n.table td:last-child p {\n  font-weight: 500;\n}\n\n.table {\n  width: 100%;\n}\n\n.table tr td:first-child {\n  width: 35%;\n}\n\n.rowAlignTop {\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `HeaderSection_grid__quv4w`,
	"col_4": `HeaderSection_col_4__cs61s`,
	"spacingStorno": `HeaderSection_spacingStorno__CDoOH`,
	"nopadding": `HeaderSection_nopadding__KI2-z`,
	"table": `HeaderSection_table__uobPF`,
	"rowAlignTop": `HeaderSection_rowAlignTop__tg7s9`
};
export default ___CSS_LOADER_EXPORT___;
