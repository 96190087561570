// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar_indicatorContainer__3qYR6 {
  display: flex;
  align-items: center;
  padding: 0 45px;
}

.progress-bar_indicator__QBmQc {
  width: 41px;
  height: 41px;
  border: 3px solid #e8e8e8;
  border-radius: 100%;
  background: white;
  color: #666666;
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 2;
}

.progress-bar_indicator__QBmQc.progress-bar_active__xBP5V {
  color: white;
  border-color: #d40511;
  background: #d40511;
  /* outline: 8px solid white; */
}

.progress-bar_indicator__QBmQc.progress-bar_done__Ss9tw {
  color: #4caf50;
  border-color: #4caf50;
  background: white;
  outline: 0;
}

.progress-bar_line__58YdX {
  border: 0;
  width: 100%;
  border-top: 3px solid #e8e8e8;
  margin: 0;
  z-index: 1;
}

.progress-bar_line__58YdX.progress-bar_done__Ss9tw {
  border-color: #4caf50;
}

.progress-bar_labelContainer__BYL6w {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.progress-bar_label__Jl6kD {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  color: rgba(50, 50, 50, 0.6);
  width: 130px;
  flex-shrink: 0;
  text-transform: uppercase;
}

.progress-bar_label__Jl6kD.progress-bar_active__xBP5V {
  color: #d40511;
}

.progress-bar_label__Jl6kD.progress-bar_done__Ss9tw {
  color: #4caf50;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/ProgressBar/progress-bar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;EAC5B,YAAY;EACZ,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".indicatorContainer {\n  display: flex;\n  align-items: center;\n  padding: 0 45px;\n}\n\n.indicator {\n  width: 41px;\n  height: 41px;\n  border: 3px solid #e8e8e8;\n  border-radius: 100%;\n  background: white;\n  color: #666666;\n  font-size: 20px;\n  line-height: 36px;\n  font-weight: 500;\n  text-align: center;\n  box-sizing: border-box;\n  flex-shrink: 0;\n  z-index: 2;\n}\n\n.indicator.active {\n  color: white;\n  border-color: #d40511;\n  background: #d40511;\n  /* outline: 8px solid white; */\n}\n\n.indicator.done {\n  color: #4caf50;\n  border-color: #4caf50;\n  background: white;\n  outline: 0;\n}\n\n.line {\n  border: 0;\n  width: 100%;\n  border-top: 3px solid #e8e8e8;\n  margin: 0;\n  z-index: 1;\n}\n\n.line.done {\n  border-color: #4caf50;\n}\n\n.labelContainer {\n  margin-top: 8px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.label {\n  font-size: 12px;\n  line-height: 1;\n  font-weight: 500;\n  text-align: center;\n  color: rgba(50, 50, 50, 0.6);\n  width: 130px;\n  flex-shrink: 0;\n  text-transform: uppercase;\n}\n\n.label.active {\n  color: #d40511;\n}\n\n.label.done {\n  color: #4caf50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicatorContainer": `progress-bar_indicatorContainer__3qYR6`,
	"indicator": `progress-bar_indicator__QBmQc`,
	"active": `progress-bar_active__xBP5V`,
	"done": `progress-bar_done__Ss9tw`,
	"line": `progress-bar_line__58YdX`,
	"labelContainer": `progress-bar_labelContainer__BYL6w`,
	"label": `progress-bar_label__Jl6kD`
};
export default ___CSS_LOADER_EXPORT___;
