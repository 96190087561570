import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { DHLCheckbox, DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';

import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { GetOrderRep } from 'generated';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';

import translationDe from './printDialog.de.json';
import styles from './PrintDialog.module.css';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { AllocatedOrderData } from 'order/common/context/order/dtos/AllocatedOrder';

export interface PrintDialogOptions {
  postingList: boolean;
  variantList: boolean;
  palletLabel: boolean;
  sortingByVariants: boolean;
  transportNotification: boolean;
}

interface PrintDialogContentProps {
  show: boolean;
  order?: GetOrderRep;
  options: PrintDialogOptions;
  handleOptionChange: (newOptions: PrintDialogOptions) => void;
  isSuspended: boolean;
  allocatedOrders?: AllocatedOrderData[];
}

i18n.addResourceBundle('de', 'printDialog', translationDe);
i18n.addResourceBundle('en', 'printDialog', translationDe);

export const PrintDialogContent = ({
  show,
  order,
  options,
  handleOptionChange,
  isSuspended,
  allocatedOrders
}: PrintDialogContentProps): JSX.Element => {
  const { t } = useTranslation('printDialog');
  const { t: oc } = useTranslation('orderCreate');
  const { hasPermission } = useAuthContext();

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  const reset = () => {
    handleOptionChange({} as PrintDialogOptions);
  };

  // allow only required and implemented orders
  const isDiPOrderEinzel =
    [ProductGroup.DIALOGPOST.toString(), ProductGroup.DIALOGPOSTWURFSPEZIAL.toString()].includes(order?.productGroup || '') &&
    order?.orderType === OrderTypes.Einlieferungsauftrag &&
    OrderCategory.DIP_EINZEL.toString() === order?.orderCategory;
  const isDiPOrderVario =
    [ProductGroup.DIALOGPOST.toString(), ProductGroup.DIALOGPOSTWURFSPEZIAL.toString()].includes(order?.productGroup || '') &&
    order?.orderType === OrderTypes.Einlieferungsauftrag &&
    OrderCategory.DIP_VARIO.toString() === order?.orderCategory;
  const isDiPOrderSammel =
    [ProductGroup.DIALOGPOST.toString(), ProductGroup.DIALOGPOSTWURFSPEZIAL.toString()].includes(order?.productGroup || '') &&
    order?.orderType === OrderTypes.Einlieferungsauftrag &&
    OrderCategory.DIP_SAMMEL.toString() === order?.orderCategory;
  const isDiPOrder = isDiPOrderEinzel || isDiPOrderVario || isDiPOrderSammel;
  const isAfmBriefOrder =
    ProductGroup.BRIEF.toString() === order?.productGroup &&
    order?.orderType === OrderTypes.Einlieferungsauftrag &&
    OrderCategory.AFM_BRIEF.toString() === order?.orderCategory;
  const isPressOrder =
    ProductGroup.PRESS_DISTRIBUTION.toString() === order?.productGroup &&
    order?.orderType === OrderTypes.Einlieferungsauftrag &&
    OrderCategory.PRESS_DISTRIBUTION.toString() === order?.orderCategory;
  const isTLOrder = ProductGroup.PARTIAL_SERVICE_LETTER.toString() === order?.productGroup && order?.orderType === OrderTypes.Zusatzauftrag;
  const isAbholOrder = OrderCategory.ABHOLAUFTRAG === order?.orderCategory && order?.orderType === OrderTypes.Zusatzauftrag;
  const isPostaktuell = OrderCategoryProductKey.POSTAKTUELL === order?.orderCategoryProductKey;
  const isIntIP = OrderCategoryProductKey.DIALOGPOST_INTERNATIONAL === order?.orderCategoryProductKey;
  const isIntPUB = OrderCategoryProductKey.PRESS_INTERNATIONAL === order?.orderCategoryProductKey;
  const isIntBrief = OrderCategoryProductKey.BRIEF_INTERNATIONAL === order?.orderCategoryProductKey;
  const isIntDMC = OrderCategoryProductKey.BRIEF50_INTERNATIONAL === order?.orderCategoryProductKey;
  const isClusterInt = isIntIP || isIntPUB || isIntBrief || isIntDMC;

  const isOrderPrintable = isDiPOrder || isAfmBriefOrder || isPressOrder || isTLOrder || isAbholOrder || isPostaktuell || isClusterInt;
  const orderPrintableTooltip = !isOrderPrintable ? t('orderNotPrintable') : '';

  const isNotPAL =
    allocatedOrders && isAbholOrder
      ? allocatedOrders?.some((a) => a.packaging?.type !== 'PAL')
      : (order?.packaging?.type === 'PAL' && order?.packaging?.quantity === 0) || order?.packaging?.type !== 'PAL';

  const nationalDestinations = (order as any)?.destinations || [];
  const internationalDestinations = (order as any)?.internationalDestinations || [];
  const allDestinations = [...nationalDestinations, ...internationalDestinations];

  return isSuspended ? (
    <ActivityIndicator testId="print-dialogue" />
  ) : (
    <DHLFormGroup>
      {(isDiPOrder || isAfmBriefOrder || isTLOrder || isPressOrder || isPostaktuell || isClusterInt) && (
        <DHLFormGroup>
          {(order as any)?.pendingIds ? <div>{oc('notifications.pendingIdsForPrint')}</div> : null}
          <DHLCheckbox
            name={'postingList'}
            label={t('postingList')}
            value={options.postingList}
            size={'slim'}
            onChange={() => {
              const sel = { ...options };
              if (sel.postingList) {
                sel.variantList = false;
              }
              sel.postingList = !sel.postingList;
              handleOptionChange(sel);
            }}
            disabled={(order as any)?.pendingIds || !isOrderPrintable}
            tooltip={orderPrintableTooltip}
          />
          {(isDiPOrderVario || isDiPOrderSammel) && (
            <div className={styles.subSection}>
              <DHLCheckbox
                name={'variantList'}
                label={isDiPOrderVario ? t('varioList') : t('sammelList')}
                value={options.variantList}
                size={'slim'}
                onChange={() => {
                  handleOptionChange({ ...options, variantList: !options.variantList });
                }}
                disabled={(order as any)?.pendingIds || !options.postingList}
              />
            </div>
          )}
          {isTLOrder && (
            <div className={styles.subSection}>
              <DHLCheckbox
                name={'variantList'}
                label={t('teilauftragList')}
                value={options.variantList}
                size={'slim'}
                onChange={() => {
                  handleOptionChange({ ...options, variantList: !options.variantList });
                }}
                disabled={(order as any)?.pendingIds || !options.postingList}
              />
            </div>
          )}
        </DHLFormGroup>
      )}
      {(isDiPOrder || isPressOrder || isClusterInt) && (
        <DHLFormGroup>
          <DHLCheckbox
            name={'palletLabel'}
            label={t('palletLabel')}
            value={options.palletLabel}
            size={'slim'}
            onChange={() => {
              const sel = { ...options };
              if (sel.palletLabel) {
                sel.sortingByVariants = false;
              }
              sel.palletLabel = !sel.palletLabel;
              handleOptionChange(sel);
            }}
            disabled={(order as any)?.pendingIds || !allDestinations?.length || !hasPermission(UserRights.PalletLabelPrint) || !isOrderPrintable}
            tooltip={orderPrintableTooltip}
          />
          {false && (
            <div className={styles.subSection}>
              <DHLCheckbox
                name={'sortingByVariants'}
                label={t('sortingByVariants')}
                value={options.sortingByVariants}
                size={'slim'}
                onChange={() => {
                  handleOptionChange({ ...options, sortingByVariants: !options.sortingByVariants });
                }}
                disabled={(order as any)?.pendingIds || !options.palletLabel}
              />
            </div>
          )}
        </DHLFormGroup>
      )}
      {(isDiPOrder || isPressOrder || isAbholOrder || isPostaktuell || isClusterInt) && (
        <DHLCheckbox
          name={'transportNotification'}
          label={t('transportNotification')}
          value={options.transportNotification}
          size={'slim'}
          onChange={() => {
            handleOptionChange({ ...options, transportNotification: !options.transportNotification });
          }}
          disabled={(order as any)?.pendingIds || !isOrderPrintable || isNotPAL}
          tooltip={orderPrintableTooltip}
        />
      )}
    </DHLFormGroup>
  );
};
