import { DHLTextInput, DHLTextInputProps } from '@gkuis/gkp-base-widgets/dist/lib/components/molecules/DHLTextInput/DHLTextInput';
import { FunctionOrReturn } from 'common/utils/helpers';
import React, { MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type TextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  Omit<DHLTextInputProps, 'name' | 'error' | 'onFunctionClick'> & {
    errorPostfix?: string;
    error?: FunctionOrReturn<UseControllerReturn<TFieldValues, TName>, string | undefined>;
    onFunctionClick?: (e: ReactMouseEvent<unknown, MouseEvent>, c: UseControllerReturn<TFieldValues, TName>) => void;
  };

export const TextInput = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  error,
  errorPostfix,
  onFunctionClick,
  onBlur,
  onChange,
  ...props
}: TextInputProps<TFieldValues, TName>): ReactElement => {
  const useControllerReturn = useController({ name, rules, shouldUnregister, defaultValue, control });
  const {
    field: { ref, ...field }
  } = useControllerReturn;
  const { t } = useTranslation('orderCreate');
  const errorMessage = typeof error === 'function' ? error(useControllerReturn) : error;
  return (
    <DHLTextInput
      {...props}
      {...field}
      innerRef={ref}
      onBlur={(e: any) => (onBlur ? onBlur(e) : undefined)}
      onChange={(e) => {
        if (props.type === 'number' && e.target.value.length) {
          field.onChange(e.target.valueAsNumber);
          onChange?.(e);
        } else {
          field.onChange(e);
          onChange?.(e);
        }
      }}
      value={props.value ?? field.value}
      onFunctionClick={(e) => onFunctionClick?.(e, useControllerReturn)}
      error={errorMessage || (errorPostfix && t(`error.${name}.${errorPostfix}`))}
    />
  );
};
