import React from 'react';
import { uid } from 'react-uid';
import { DHLButton, DHLTableCol } from '@gkuis/gkp-base-widgets/dist/lib';

import { ColumnType } from '../dtos/ColumnType';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { extractObjectData } from '../helper/extractObjectData';
import { CellMetaData, ColumnConfig } from '../dtos/ColumnConfig';
import { Cell } from '../cell/Cell';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { WhitelistMethod } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export type Props<T> = {
  columnDefinitions: ColumnConfig[];
  hasChildren: boolean;
  expanded: boolean | undefined;
  childExpanded: boolean | undefined;
  setExpanded: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setChildExpanded: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  allAutoExpanded: boolean | undefined;
  data: T;
  selected: (event: React.MouseEvent, data: T) => void;
  formatter: Map<string, unknown>;
  idPropertiesName: string;
  language?: string;
};

export const TableRowElement = ({
  columnDefinitions,
  hasChildren,
  expanded,
  setExpanded,
  setChildExpanded,
  childExpanded,
  allAutoExpanded,
  data,
  selected,
  formatter,
  idPropertiesName,
  language
}: Props<OrderTreeItem<OrderReplyTDO>>): JSX.Element => {
  const { checkWhitelistingKey, hasPermission } = useAuthContext();
  const orderCategoryReadWhitelisted =
    data.data?.orderCategoryProductKey && checkWhitelistingKey(data.data.orderCategoryProductKey as OrderCategoryProductKey, WhitelistMethod.GET);
  const isOrderViewHyperlinkDisabled = !orderCategoryReadWhitelisted || !hasPermission(UserRights.OrderShow);

  return (
    <>
      {columnDefinitions.map((columnDefinition, col) => {
        const { property, type, customRenderer, name, contentAlignment, tdClassName } = columnDefinition;
        const generatedID = uid(`${property}-${col}`);
        const cellValue = extractObjectData(data, property);
        const formattedValue = property === 'data.idsQty' && typeof cellValue === 'number' ? cellValue.toLocaleString(language) : cellValue;

        const cellMeta: CellMetaData = { cellId: col, language, isOrderViewHyperlinkDisabled: isOrderViewHyperlinkDisabled };
        const disabledPropertiesName = ' ';
        if (!data.data) {
          return <td data-testid={'emptyCell'} key={`table-cell-${generatedID}-${col}`} />;
        } else {
          switch (type) {
            case ColumnType.CHECKBOX:
            case ColumnType.CUSTOM_ICON_BUTTON:
            case ColumnType.SELECT:
            case ColumnType.CUSTOM_CELL:
              return (
                <td
                  data-testid={`custom-cell-${property}-${col}`}
                  className={(tdClassName ? tdClassName : '') + (contentAlignment ? ` dhlTablecell align-${contentAlignment}` : ` dhlTablecell`)}
                  key={`${property}-${generatedID}`}
                >
                  {customRenderer?.(cellValue, columnDefinition, data, cellMeta)}
                </td>
              );
            case ColumnType.CHILD_EXPAND:
              return (
                <td key={`${property}-${generatedID}`} data-testid={`${property}-${data.data.orderNumber}`}>
                  <DHLButton
                    icon={childExpanded ? 'arrow-up' : 'arrow-down'}
                    onClick={() => setChildExpanded?.((oldChildExpanded) => !oldChildExpanded)}
                    iconPosition="icon"
                    type="primary"
                  />
                </td>
              );
            case ColumnType.TREE_EXPAND:
              return (
                <td key={`${property}-${generatedID}`}>
                  {hasChildren && (
                    <DHLButton
                      icon={expanded ? 'arrow-up' : 'arrow-down'}
                      disabled={allAutoExpanded}
                      onClick={() => setExpanded((oldExpanded) => !oldExpanded)}
                      iconPosition="icon"
                      type="primary"
                    />
                  )}
                </td>
              );
            default:
              const cellDef = <Cell {...columnDefinition} id={col} />;
              return (
                <ErrorBoundary useErrorUI={<td />} key={`error-boundary-${col}`}>
                  <DHLTableCol
                    key={`${generatedID}`}
                    value={formattedValue}
                    selected={(event: React.MouseEvent) => selected(event, data)}
                    formatter={formatter.get(property)}
                    cellDefinition={cellDef}
                    cellId={`${generatedID}`}
                    row={data.data}
                    idPropertiesName={idPropertiesName}
                    disabled={extractObjectData(data, disabledPropertiesName || ' ')}
                  />
                </ErrorBoundary>
              );
          }
        }
      })}
    </>
  );
};
