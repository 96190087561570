import React, { VFC } from 'react';
import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import styles from './qty-allocation.module.css';
import classNames from 'classnames';

interface Props {
  currentAllocation?: QtyAllocation[];
  language: string;
}

export const ExpandedAllocation: VFC<Props> = ({ currentAllocation, language }) => {
  const basis = currentAllocation?.find((q) => !q.containsIds && !q.flexOption && q.quantity);
  const id = currentAllocation?.find((q) => q.containsIds && q.flexOption && q.quantity);
  const schnell = currentAllocation?.find((q) => q.containsIds && !q.flexOption && q.quantity);

  return (
    <>
      <td colSpan={2} className={classNames(styles.borderRight)}>
        {basis?.quantity.toLocaleString(language) ?? ''}
      </td>
      <td>{id?.quantity.toLocaleString(language) ?? ''}</td>
      <td className={classNames(styles.borderRight)}>{schnell?.quantity.toLocaleString(language) ?? ''}</td>
    </>
  );
};
