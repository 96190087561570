import { Key, MouseEventHandler, useCallback, useEffect, useState } from 'react';

export type UseAccordionRegisterReturn<T> = {
  open: boolean;
  onClick: MouseEventHandler<T>;
  setOpen?: (open: boolean) => void;
};

export type UseAccordionReturn<T> = (key: Key) => UseAccordionRegisterReturn<T>;

export type UseAccordionProps<T> = {
  allowMultiple?: boolean;
} & Partial<UseAccordionRegisterReturn<T>>;

export const useAccordion = <T extends HTMLDetailsElement>(props?: UseAccordionProps<T>): UseAccordionReturn<T> => {
  const [active, setActive] = useState<Key[]>([]);

  useEffect(() => {
    if (!props?.allowMultiple) {
      setActive([]);
    }
  }, [props?.allowMultiple]);

  const handleSetOpen = useCallback(
    (key, openState) => {
      if (openState) {
        setActive((old) => [...old, key]);
      } else {
        setActive((old) => old.filter((k) => k !== key));
      }
    },
    [active]
  );

  return (key: Key) => ({
    open: typeof props?.open === 'undefined' ? active.includes(key) : props?.open,
    setOpen: (openState: boolean) => handleSetOpen(key, openState),
    onClick: (e) => {
      if ((e.target as HTMLElement).nodeName.toLowerCase() !== 'summary') {
        return;
      }
      if (e.currentTarget.nodeName) {
        e.preventDefault();
        if (!active.includes(key)) {
          if (props?.allowMultiple) {
            setActive((old) => [...old, key]);
          } else {
            setActive([key]);
          }
        } else {
          setActive((old) => old.filter((k) => k !== key));
        }
      }
      props?.onClick?.(e);
    }
  });
};
