/**
 * The {@link AMDHLDateRangeInput} is a wrapper for the gkp-based `DHLDateRangeInput`.
 * With {@link DefaultResourceDataStore} we provide a default `ResourceDataStore` and we use a wrapper for the `DateRangePickerDataStore`
 * in the form of the {@link AMDHLDateRangeInputModel}.
 *
 * @module
 */
import React from 'react';
import { DateRangePickerDataStore, DHLDateRangeInput } from '@gkuis/gkp-base-widgets/dist/lib';
import { AMDHLDateRangeInputModel, AMDHLPartialSearchDateRangeInputModel } from './DateRangeInputModel';
/**
 * Property interface for the {@link AMDHLDateRangeInput}.
 */
export interface GKPDatePickerRangeProps {
  /**
   * Name of the input field.
   */
  name: string;

  /**
   * HTML identifier for the input.
   */
  id?: string;

  /**
   * Flag whether to show the picker or not.
   */
  show?: boolean;

  /**
   * Handler for the input submit event.
   */
  onSubmit?: () => void;

  /**
   * Handler for the input cancel event.
   */
  onCancel?: () => void;

  /**
   * CSS classes for the input.
   */
  className?: string;

  /**
   * Flag to mark the input as required.
   */
  required?: boolean;

  /**
   * The data model containing the internal `DateRangePickerDataStore`.
   *
   * @see DateRangePickerDataStore
   */
  model: AMDHLDateRangeInputModel | AMDHLPartialSearchDateRangeInputModel;
}

/**
 * This component displays a date range picker.
 * We wrap the `DHLDateRangeInput` adding further features.
 *
 * @param {GKPDatePickerRangeProps} __namedParameters properties for the {@link AMDHLDateRangeInput}
 *
 * @return {Element} the gkp date range picker component
 */
export function AMDHLDateRangeInput({ name, className, show, model, onSubmit, onCancel, required }: GKPDatePickerRangeProps): JSX.Element {
  if (show === false) {
    return <></>;
  }

  return (
    <DHLDateRangeInput
      className={className}
      onCancel={onCancel}
      name={name}
      dataStore={model.getDataStore()}
      onSubmit={onSubmit}
      required={required}
    />
  );
}
