import { customerIdValidator, stripWhiteSpace } from 'order/common/services/validators';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';

interface ValidationResult {
  success: boolean;
  errorMessage?: string | null;
  original?: string | number;
  value?: string | number;
}

function contains(input: string, regex: RegExp) {
  return input.search(regex) >= 0;
}

/**
 * Fallback validation explicitly failing on pure numbers.
 */
const stringNotNumber = (input: string | number): boolean => {
  if (typeof input !== 'string') {
    return false;
  }

  return contains(input, /[^\d\s]/g);
};

/**
 * Validates the last two check digits of an {@link OrderSearchKey.OrderId}.
 * @param orderId order id to test
 */
const checkTestDigits = (orderId: number): boolean => {
  let checkDigits = 0;
  let orderNumberLong = Math.floor(orderId / 100);
  for (let i = 13; i >= 2; i--) {
    checkDigits += i * (orderNumberLong % 10);
    orderNumberLong = Math.floor(orderNumberLong / 10);
  }
  return orderId % 100 === checkDigits % 97;
};

export const validateOrderId = (input: string): ValidationResult => {
  const value = stripWhiteSpace(input);
  let success = false;
  let errorMessage = null;

  if ((value as string).length > 14) {
    errorMessage = 'Input too long';
  } else {
    const number = Number(value);

    if (isNaN(number)) {
      errorMessage = 'Input is no number';
    } else if (number < 100) {
      errorMessage = 'Order id too small';
    } else {
      success = checkTestDigits(number);
    }
  }

  return {
    success,
    errorMessage,
    value
  };
};

const validateEposLabel = (input: string): ValidationResult => {
  const regex = /^\d{21}(?:-V|\*)?$/;
  const value = stripWhiteSpace(input);
  let success = false;

  if (regex.test(value as string)) {
    success = true;
  }

  return {
    success,
    value: contains(input, /(-V|\*)/g) ? input : `${input}-V`
  };
};

const validateParticipation = (input: string): ValidationResult => {
  const value = stripWhiteSpace(input);

  return {
    success: (value as string).length === 2,
    value
  };
};

const validateRegex = (input: string, regex: RegExp): ValidationResult => {
  const value = stripWhiteSpace(input);

  return {
    success: regex.test(value as string),
    value
  };
};

const validateActionDescription = (input: string | number): ValidationResult => ({
  success: typeof input === 'string',
  value: ((input as string) || '').trim()
});

const validateZKZ = (input: string): ValidationResult => {
  const value = stripWhiteSpace(input);
  const number = Number(value);

  if (isNaN(number)) {
    return {
      success: value.length > 0,
      value,
      original: input
    };
  }
  if (value.length > 2 && value.length < 7) {
    return {
      success: true,
      value,
      original: input
    };
  }

  return {
    success: false,
    value,
    original: input
  };
};

const validatorsMap = (key: OrderSearchKey, value: string): ValidationResult => {
  let returnValue: ValidationResult = {
    success: false,
    errorMessage: null,
    original: value,
    value
  };

  switch (key) {
    case OrderSearchKey.ActionDescription:
      returnValue = {
        ...returnValue,
        ...validateActionDescription(value)
      };
      break;
    case OrderSearchKey.OrderId:
      returnValue = {
        ...returnValue,
        ...validateOrderId(value)
      };
      break;
    case OrderSearchKey.Beneficiary:
    case OrderSearchKey.Payer:
    case OrderSearchKey.Producer:
    case OrderSearchKey.Submitter:
      returnValue = {
        ...returnValue,
        ...customerIdValidator(value)
      };
      break;
    case OrderSearchKey.CustomerOrderId1:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^(\d{10}-\w{0,15})$/)
      };
      break;
    case OrderSearchKey.CustomerOrderId2:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^(\d{19}-\w{2}-\w{3})$/)
      };
      break;
    case OrderSearchKey.EposLabel:
      returnValue = {
        ...returnValue,
        ...validateEposLabel(value)
      };
      break;
    case OrderSearchKey.Originator:
      returnValue = {
        ...returnValue,
        success: customerIdValidator(value).success || stringNotNumber(value)
      };
      break;
    case OrderSearchKey.Participation:
      returnValue = {
        ...returnValue,
        ...validateParticipation(value)
      };
      break;
    case OrderSearchKey.PaymentClearingNumber:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^(\d{1,5})$/)
      };
      break;
    case OrderSearchKey.Procedure:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^\d{2}$/)
      };
      break;
    case OrderSearchKey.SettlementNumber:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^\d{12}[\dA-z]{2}(?:\d{3,5})?$/)
      };
      break;
    case OrderSearchKey.ZKZ:
      returnValue = {
        ...returnValue,
        ...validateZKZ(value)
      };
      break;
    case OrderSearchKey.OrderType:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^[A-Z0-9]{2,5}/)
      };
      break;
    case OrderSearchKey.ProductionState:
      returnValue = {
        ...returnValue,
        ...validateRegex(value, /^[A-Za-z]+$/)
      };
      break;
    default:
      break;
  }

  return returnValue;
};

export const getValidator = (key: OrderSearchKey, value: string): ValidationResult => validatorsMap(key, value);
