import React from 'react';
import classNames from 'classnames';
import styles from './OnlineHelp.module.css';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { OnlineHelpLinks } from '../../dtos/OnlineHelpLink';

interface Props {
  link: OnlineHelpLinks;
}

export const OnlineHelp = ({ link }: Props): JSX.Element => {
  return (
    <div className={classNames(styles.toolbarList)}>
      <div className={classNames(styles.toolbarLists)}>
        <DHLButton
          name={'question'}
          icon={'question'}
          iconPosition={'icon'}
          type={'default'}
          size="sm"
          onClick={() => {
            link && window.open(link, '_blank');
          }}
        />
      </div>
    </div>
  );
};
