import { ProductGroup } from 'order/common/dtos/ProductGroup';

export const Regions = {
  PressDeactivatedZones: ['00', '11', '43'],
  DeactivatedZones: ['00', '05', '11', '62', '43'],
  SLN_REGIONS: ['04', '10', '20', '30', '34', '44', '50', '60', '70', '76', '80', '90']
};

export const calculateRegions = (productGroup?: ProductGroup) => {
  if (productGroup === ProductGroup.PRESS_DISTRIBUTION) {
    return Regions.PressDeactivatedZones;
  } else {
    return Regions.DeactivatedZones;
  }
};
