export enum OrderCategory {
  DIP_VARIO = 'DiP_VARIO',
  DIP_SAMMEL = 'DiP_SAMMEL',
  DIP_EINZEL = 'DiP_EINZEL',
  DIP_VARIO_TL = 'DiP_VARIO_TL',
  AFM_BRIEF = 'AFM_BRIEF', // frankit
  DV_BRIEF = 'DV_BRIEF',
  PRESS_DISTRIBUTION = 'PRESSEDISTRIBUTION', // placeholder until we have real order categories
  TL_BRIEF = 'TL_BRIEF', // partial service letter
  PARTIAL = 'PARTIAL',
  ABHOLAUFTRAG = 'ABHOLAUFTRAG', // partial service collection order
  DHL_INFOPOST = 'DHL_INFOPOST',
  POSTAKTUELL = 'PA',
  BRIEF_INTERNATIONAL = 'BRIEF_INTERNATIONAL',
  IP_INTERNATIONAL = 'IP_INTERNATIONAL',
  BRIEF_DMC_INTERNATIONAL = 'BRIEF_DMC_INTERNATIONAL',
  PUB_INTERNATIONAL = 'PUB_INTERNATIONAL'
}
