import { deDateFormatter } from 'common/utils/helpers';
import { OrderReplyTDO } from 'order/common/services/OrderService';

export const deliveryDateEvaluation = (order?: OrderReplyTDO): string => {
  let result = '-';
  if (order && order.firstInductionDate) {
    const date = new Date(order.firstInductionDate);
    if (date.toString() !== 'Invalid Date') {
      result = deDateFormatter.format(date).toString();
    }
  }

  return result;
};
