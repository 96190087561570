import { useCallback, useEffect, useState } from 'react';

export type UseFetcherReturn<T> =
  | {
      data?: T;
      state: 'idle' | 'loading' | 'finished';
    }
  | { state: 'error'; error: Error };

export type FetcherState = UseFetcherReturn<unknown>['state'];

export type FetchMethod<Result, Query = string> = (searchText: Query) => Promise<Result>;

export type UserFetcherParams<Result, Query> = {
  fetcher: FetchMethod<Result, Query>;
  query?: Query;
};

export const useFetcher = <Result, Query = string>({ fetcher, query }: UserFetcherParams<Result, Query>): UseFetcherReturn<Result> => {
  const [result, setResult] = useState<UseFetcherReturn<Result>>({
    state: 'idle'
  });

  const setError = useCallback((error: Error) => setResult({ error, state: 'error' }), []);
  const setState = useCallback((state: Exclude<FetcherState, 'error'>, data: Result | undefined = undefined) => setResult({ state, data }), []);

  const onSearch = useCallback(
    async (query: Query) => {
      setState('loading');
      fetcher(query)
        .then((result) => setState('finished', result))
        .catch(setError);
    },
    [fetcher, setError, setState]
  );

  useEffect(() => {
    if (typeof query !== 'undefined') {
      onSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return result;
};
