import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { AvailableProductGroup } from 'order/productGroups/common/utils/orderCreateSchema';
import { Product } from 'order/common/context/order/dtos/Product';

export type BaseProductSectionProps<T extends FieldValues> = SFCProps<T, string> & {
  disabled?: boolean;
  name?: string;
  productGroup?: AvailableProductGroup;
  selectedDate?: Date;
};

export const BaseProductSection = <T extends FieldValues>({
  disabled,
  productGroup,
  selectedDate = new Date(),
  ...props
}: BaseProductSectionProps<T>): ReactElement => {
  const [loaded, setLoaded] = useState(false);
  const baseProducts = useBaseProducts(productGroup, selectedDate);
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { setValue } = useFormContext();
  const baseProduct = useWatch({
    control: props.control,
    name: props.name
  });

  useEffect(() => {
    if (!loaded && !baseProduct && baseProducts.length) {
      setValue<string>(props.name, baseProducts.find((p) => p.basicFormat === 'STANDARD')?.productNumber);
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseProducts, loaded]);

  return (
    <OrderStepSection headline={oc('selectProduct')}>
      <FormRow mode="two-col">
        <Controller<ObjectContaining<string>, string>
          {...(props as any)}
          render={({ field }) => (
            <AMDHLSelectSingle<Product>
              data-testid={'base-product'}
              name={field.name}
              placeholder={oc('basicProduct')}
              items={baseProducts}
              value={baseProducts.find(({ productNumber }) => productNumber === field.value)}
              onChange={(item) => {
                field.onChange(item?.productNumber);
                field.onBlur();
              }}
              valueToString={(item) => item.descriptionShort || ''}
              disabled={disabled}
            />
          )}
        />
      </FormRow>
    </OrderStepSection>
  );
};
