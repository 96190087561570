import { OrderSearchAttribute } from './orderSearchAttribute';
import { OrderSearchType } from '../../common/context/search/dtos/OrderSearchType';
import { OrderSearchKey } from '../../common/context/search/dtos/OrderSearchKey';

const validSimpleSearchKeys = [OrderSearchKey.OrderId, OrderSearchKey.CustomerOrderId1, OrderSearchKey.CustomerOrderId2, OrderSearchKey.EposLabel];

const validateSearchAttributes = (searchType: OrderSearchType, attributes: OrderSearchAttribute[]): boolean => {
  if (!attributes.length) {
    return false;
  }

  if (searchType === OrderSearchType.SIMPLE) {
    return attributesContainKeys(attributes, validSimpleSearchKeys);
  }

  if (!isDateConstraintMet(attributes)) {
    return false;
  }

  if (searchType === OrderSearchType.PRESS) {
    return validatePressSearch(attributes);
  }

  if (searchType === OrderSearchType.PARTIAL || searchType === OrderSearchType.COLLECTION_LETTER) {
    return validatePartialSearch(attributes);
  }

  return validateAdvancedSearch(attributes);
};

export default validateSearchAttributes;

function isDateConstraintMet(attributes: OrderSearchAttribute[]) {
  const dateInAttributes = attributes.filter(({ key }) => key === OrderSearchKey.Date).length === 1;
  const otherInAttributes = attributes.some(({ key }) => key !== OrderSearchKey.Date);
  return dateInAttributes && otherInAttributes;
}

const validatePressSearch = (attributes: OrderSearchAttribute[]): boolean => {
  const validKeys = [
    OrderSearchKey.ZKZ,
    OrderSearchKey.Date,
    OrderSearchKey.Originator,
    OrderSearchKey.Submitter,
    OrderSearchKey.Producer,
    OrderSearchKey.Payer,
    OrderSearchKey.Beneficiary
  ];
  return attributesContainKeys(attributes, validKeys);
};

const validateAdvancedSearch = (attributes: OrderSearchAttribute[]): boolean => {
  if (!attributesDontContainKeys(attributes, [OrderSearchKey.ZKZ, ...validSimpleSearchKeys])) {
    return false;
  }
  const uniqueAttributeKeys = [
    OrderSearchKey.ActionDescription,
    OrderSearchKey.SettlementNumber,
    OrderSearchKey.Procedure,
    OrderSearchKey.Participation,
    OrderSearchKey.PaymentClearingNumber
  ];
  return (
    attributes.some((attribute) => attribute.key !== OrderSearchKey.Date && !uniqueAttributeKeys.includes(attribute.key)) &&
    uniqueAttributeKeys.every((key) => attributes.filter((attr) => attr.key === key).length <= 1)
  );
};

const validatePartialSearch = (attributes: OrderSearchAttribute[]): boolean => {
  if (!attributesDontContainKeys(attributes, [OrderSearchKey.ZKZ, ...validSimpleSearchKeys.filter((attr) => attr !== OrderSearchKey.OrderId)])) {
    return false;
  }
  const uniqueAttributeKeys = [
    OrderSearchKey.ActionDescription,
    OrderSearchKey.SettlementNumber,
    OrderSearchKey.Procedure,
    OrderSearchKey.Participation,
    OrderSearchKey.PaymentClearingNumber
  ];
  return (
    attributes.some((attribute) => attribute.key !== OrderSearchKey.Date && !uniqueAttributeKeys.includes(attribute.key)) &&
    uniqueAttributeKeys.every((key) => attributes.filter((attr) => attr.key === key).length <= 1)
  );
};

const attributesContainKeys = (attributes: OrderSearchAttribute[], keys: OrderSearchKey[]): boolean => attributes.every((a) => keys.includes(a.key));

const attributesDontContainKeys = (attributes: OrderSearchAttribute[], keys: OrderSearchKey[]): boolean =>
  attributes.every((a) => !keys.includes(a.key));
