import classNames from 'classnames';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './billing-table.module.css';

export type BillingTableItemHeadRowProps = {
  index: number;
  showTotalWeight?: boolean;
  showBasicCharge?: boolean;
  showDiscount?: boolean;
  showTariffZone?: boolean;
  showZKZ?: boolean;
  showDesignation?: boolean;
  showSingleWeight?: boolean;
};

export const BillingTableItemHeadRow: VFC<BillingTableItemHeadRowProps> = (props) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  return (
    <tr key={'row-expanded-' + props.index}>
      <th className={classNames(styles.addBorderBottom, styles.internalRowHeader)}>{t('product')}</th>
      <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('number')}</th>
      <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('basicFeePerPiece')}</th>
      {props.showSingleWeight && <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('singleWeight')}</th>}
      {props.showTotalWeight && <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('totalWeight')}</th>}
      {props.showBasicCharge && <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('basicChargePerKg')}</th>}
      {props.showDiscount && <th className={styles.addBorderBottom}>{t('discount')}</th>}
      {props.showTariffZone && <th className={styles.addBorderBottom}>{t('tariffzone')}</th>}
      {props.showZKZ && <th className={styles.addBorderBottom}>{t('zkz')}</th>}
      {props.showDesignation && <th className={styles.addBorderBottom}>{t('designation')}</th>}
      <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('tax')}</th>
      <th className={classNames(styles.addBorderBottom, styles.rightAlign)}>{t('feeWithoutVat')}</th>
    </tr>
  );
};
