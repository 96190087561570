// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductInfoTable_table__CjD\\+F {
  color: #717171;
  width: 100%;
}

.ProductInfoTable_table__CjD\\+F td {
  padding-top: 10px;
  line-height: 1;
  vertical-align: top;
  max-width: 230px;
  word-wrap: break-word;
}
.ProductInfoTable_table__CjD\\+F tbody:not(:first-child) tr:first-child td {
  padding-top: 20px;
}

.ProductInfoTable_table__CjD\\+F td:first-child {
  padding-right: 1rem;
  width: 46%;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/ProductInfoTable/ProductInfoTable.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".table {\n  color: #717171;\n  width: 100%;\n}\n\n.table td {\n  padding-top: 10px;\n  line-height: 1;\n  vertical-align: top;\n  max-width: 230px;\n  word-wrap: break-word;\n}\n.table tbody:not(:first-child) tr:first-child td {\n  padding-top: 20px;\n}\n\n.table td:first-child {\n  padding-right: 1rem;\n  width: 46%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ProductInfoTable_table__CjD+F`
};
export default ___CSS_LOADER_EXPORT___;
