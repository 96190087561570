import React, { useEffect, useState } from 'react';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { uid } from 'react-uid';

import { ColumnConfig } from '../dtos/ColumnConfig';

declare type HeaderVisibleCheckBoxProps = ColumnConfig & {
  onCheck: (checked: boolean) => void;
  testId?: string;
};

const HeaderVisibleCheckBoxWrapper = (props: HeaderVisibleCheckBoxProps) => {
  const [checked, setChecked] = useState(!props.hidden);

  useEffect(() => {
    setChecked(!props.hidden);
  }, [props.hidden]);

  function onChange() {
    setChecked(!checked);
    props.onCheck(checked);
  }

  return (
    <DHLCheckbox
      name={uid(props)}
      label={props.title!}
      value={checked}
      onChange={onChange}
      disabled={!props.hideable}
      size="slim"
      data-testid={props.testId}
    />
  );
};

export const HeaderVisibleCheckBox = React.memo(HeaderVisibleCheckBoxWrapper);
