import { Authorized } from 'common/components/Authorized/Authorized';
import { UserRights } from 'common/dtos/userRights';
import { useOrderView } from 'order/orderView/components/OrderView/useOrderView';
import React, { FC } from 'react';

import { OrderViewContainer } from './OrderViewContainer';

export type OrderViewProps = {
  text?: string;
};

export type OrderViewRouteParams = {
  orderId: string;
  status: string;
};

export const OrderView: FC<OrderViewProps> = () => {
  const { status, orderId, orderType } = useOrderView();

  return (
    <Authorized userRight={UserRights.OrderShow}>
      <OrderViewContainer orderId={orderId} status={status} orderType={orderType} />
    </Authorized>
  );
};
