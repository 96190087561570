import { DHLIcon, DHLTooltip } from '@gkuis/gkp-base-widgets/dist/lib';
import { createApi } from 'common/utils/apiUtils';
import { OpsApiFactory, VersionTO } from 'generated';
import i18n from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import GitInfo from 'react-git-info/macro';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../context/auth/AuthContext';
import styles from './Footer.module.css';
import { useHistory } from 'react-router-dom';
import { AppPaths } from 'Main';

const versionApi = createApi(OpsApiFactory);

export interface VersionInfo {
  version?: string;
  hash?: string;
  id?: string;
  branch?: string;
  otcEnv?: string;
}

export const Footer = observer((): JSX.Element => {
  const { user } = useAuthContext();
  const { t } = useTranslation('', { keyPrefix: 'footer' });
  const history = useHistory();
  // eslint-disable-next-line new-cap
  const [gitInfo] = useState(GitInfo());
  const [backendInfo, setBackendInfo] = useState<VersionInfo | undefined>();
  const [frontendTooltipOpen, setFrontendTooltipOpen] = useState(false);
  const [backendTooltipOpen, setBackendTooltipOpen] = useState(false);
  const [lang, setLang] = useState<'de' | 'en'>((localStorage.getItem('lang') as 'de' | 'en') || 'de');

  useEffect(() => {
    versionApi
      .getVersion()
      .then(({ data }) => setBackendInfo(data))
      .catch(() => setBackendInfo(undefined));
  }, []);

  const onChangeLanguage = async (value: 'de' | 'en') => {
    setLang(value);
    await i18n.changeLanguage(value);
    localStorage.setItem('lang', value);
    moment.locale(value);
    location.reload();
  };

  function truncate(input: string, max: number, postfix?: string): string {
    if (!postfix) {
      postfix = '...';
    }

    if (input.length < max) {
      return input;
    }
    return input.substring(0, max) + postfix;
  }

  const [currentWorkflow, setCurrentWorkflow] = useState('');

  const orderPathHandler = (option: any) => {
    if (option?.target?.value === AppPaths.orderCreatePath) {
      setCurrentWorkflow(AppPaths.orderCreatePath);
      history.replace(AppPaths.orderCreatePath);
    } else {
      {
        setCurrentWorkflow(AppPaths.orderSearchPath);
        history.replace(AppPaths.orderSearchPath);
      }
    }
  };

  const version: string | undefined = gitInfo.tags[gitInfo.tags.length - 1] || process.env.REACT_APP_VERSION;
  return (
    <footer data-testid="commonFooter" className={styles.footer + ' d-flex justify-content-between bg-light p-2'}>
      {process.env.REACT_APP_MY_ENV === 'development' && (
        <div className={styles.workFlow}>
          <select onBlur={() => {}} onChange={(option) => orderPathHandler(option)} value={''}>
            <option disabled value={''}>
              Workflow
            </option>
            <option
              style={currentWorkflow === AppPaths.orderSearchPath ? { backgroundColor: '#d40511', color: '#fff' } : {}}
              value={AppPaths.orderSearchPath}
            >
              Order Search
            </option>
            <option
              style={currentWorkflow === AppPaths.orderCreatePath ? { backgroundColor: '#d40511', color: '#fff' } : {}}
              value={AppPaths.orderCreatePath}
            >
              Order Create
            </option>
          </select>
        </div>
      )}
      {!user.isAuthenticated ? (
        <div className={styles.links}>
          <select onBlur={() => {}} onChange={(option) => onChangeLanguage(option.target.value as 'de' | 'en')} value={lang}>
            <option value="de">Deutsch</option>
            <option value="en">English</option>
          </select>
        </div>
      ) : null}
      <div className={styles.version}>
        {user.isAuthenticated ? <span className={'mx-1'}>{`${t('authMgr.loggedIn')}: ${user.userName}`}</span> : <></>}
        <span className={'mx-1'} id="commit_info">
          {process.env.REACT_APP_MY_ENV === 'ite' || process.env.REACT_APP_MY_ENV === 'prod' ? (
            `FE: ${version}`
          ) : (
            <>
              {`FE (${process.env.NODE_ENV || '-'}): ${version}`}
              <span className="text-secondary ml-1">({gitInfo.commit.shortHash})</span>
              <DHLIcon icon="alert-info" name="commit_info" className="d-inline ml-1" />
              <DHLTooltip
                target="commit_info"
                placement="top"
                className="text-left"
                noMaxWidth={true}
                tooltipOpen={frontendTooltipOpen}
                toggle={() => setFrontendTooltipOpen((o) => !o)}
              >
                <code className="text-light">
                  Commit: {gitInfo.commit.shortHash}
                  <br />
                  Branch: {gitInfo.branch}
                  <br />
                  Message: {truncate(gitInfo.commit.message, 32)}
                  <br />
                  Hash: {gitInfo.commit.hash}
                  <br />
                  Date: {new Date(gitInfo.commit.date).toLocaleString()}
                </code>
              </DHLTooltip>
            </>
          )}
        </span>
        {backendInfo ? (
          <span className="mx-1" id="commit_info_be">
            {process.env.REACT_APP_MY_ENV === 'ite' || process.env.REACT_APP_MY_ENV === 'prod' ? (
              `BE: ${backendInfo.version?.replace(/-SNAPSHOT/i, '')}`
            ) : (
              <>
                {`BE (${process.env.REACT_APP_MY_ENV || '-'}): ${backendInfo.version?.replace(/-SNAPSHOT/i, '')}`}
                <span className="text-secondary ml-1">({backendInfo.hash})</span>
                <DHLIcon icon="alert-info" name="commit_info_be" className="d-inline ml-1" />
                <DHLTooltip
                  target="commit_info_be"
                  placement="top"
                  className="text-left"
                  noMaxWidth={true}
                  tooltipOpen={backendTooltipOpen}
                  toggle={() => setBackendTooltipOpen((o) => !o)}
                >
                  <code className="text-light">
                    Commit: {backendInfo.hash}
                    <br />
                    Branch: {backendInfo.branch}
                    <br />
                    Hash: {backendInfo.id}
                    <br />
                    otcEnv: {backendInfo.otcEnv}
                  </code>
                </DHLTooltip>
              </>
            )}
          </span>
        ) : (
          <span className="mx-1">{`BE (${process.env.REACT_APP_MY_ENV || '-'})`}</span>
        )}
      </div>
    </footer>
  );
});
