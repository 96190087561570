import React, { useEffect, useState, VFC } from 'react';

import CustomerAddress, { CustomerAddressProps } from 'order/productGroups/common/components/atom/CustomerAddress/CustomerAddress';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { CustomerInfoWithAddressTO } from 'generated';
import { useOrderContext } from 'order/common/context/order/OrderContext';

export type CustomerInfoProps = {
  customerId?: string;
} & CustomerAddressProps;

export const CustomerInfo: VFC<CustomerInfoProps> = ({ customerId, ...props }) => {
  const { order } = useOrderContext();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const filteredParty = order?.parties?.filter((p) => p.customerId === customerId);
  const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
    customerId: filteredParty?.[0]?.customerId,
    name: filteredParty?.[0]?.name,
    address: filteredParty?.[0]?.orderAddress
  };

  const fetchCustomerInfo = async (customerNumber?: string) => {
    if (customerNumber) {
      const { customerInfo: data } = await getCustomerInfo(customerNumber);
      if (data) {
        setCustomerInfo(data);
      } else if (transformedCustomerData) {
        setCustomerInfo(transformedCustomerData);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  if (!customerInfo?.customerId) {
    return <div />;
  }

  return (
    <CustomerAddress {...props}>
      <p data-testid={'customer-name'}>{customerInfo.name}</p>
      <p data-testid={'customer-address-info-street'}>
        {customerInfo.address?.street} {customerInfo.address?.streetNumber}
      </p>
      <p data-testid={'customer-address-info-postcode'}>
        {customerInfo.address?.postCode} {customerInfo.address?.city}
      </p>
    </CustomerAddress>
  );
};
