import React, { useEffect, useState, VFC } from 'react';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'i18n';
import { AccordionButton } from 'common/components/Accordion';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import styles from 'order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2.module.css';
import { AvailableProductGroup } from 'order/productGroups/common/utils/orderCreateSchema';
import iconBund from 'assets/icon-bund.svg';
import iconPallet from 'assets/icon-pallet.svg';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { isValidInteger } from '../../../../../common/utils/formHelpers';
import { useDialogPostClusterCombinations } from '../../hooks/useDialogPostClusterCombinations';
import { DialogMarketingOrderCreate } from '../../schema/dialogMarketingSchema';

export const fertigungTypes = ['sortedPalletZone', 'sortedPalletRegion', 'sortedTrayRegion', 'sortedTrayPostcode'] as const;
export type FertigungTypes = typeof fertigungTypes[number];

export type Fertigung = Partial<Record<FertigungTypes, number>>;

export type FertigungSectionProps = {
  disabled?: boolean;
  productGroup?: AvailableProductGroup;
  selectedDate?: Date;
  baseProduct?: string;
  onOpen?: () => void;
};

export const FertigungSection: VFC<FertigungSectionProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { control, trigger } = useFormContext<DialogMarketingOrderCreate>();
  const { fertigung } = useDialogPostClusterCombinations();
  const { sortedTrayPostcodeAvailable, sortedTrayRegionAvailable, sortedPalletRegionAvailable, sortedPalletZoneAvailable } = fertigung;
  const { t: errTranslation } = useTranslation('orderCreate');
  const { t } = useTranslation('shipmentInputs');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const formControlNames = [
    'fertigung.sortedTrayRegion',
    'fertigung.sortedTrayPostcode',
    'fertigung.sortedPalletZone',
    'fertigung.sortedPalletRegion'
  ];

  const [sortedTrayRegion, sortedTrayPostcode, sortedPalletZone, sortedPalletRegion] = useWatch({ control, name: formControlNames as any[] });

  useEffect(() => {
    if (!loaded && (sortedPalletRegion || sortedPalletZone || sortedTrayRegion || sortedTrayPostcode)) {
      setLoaded(true);
      props.onOpen && props.onOpen();
    }
  }, [sortedTrayRegion, sortedTrayPostcode, sortedPalletZone, sortedPalletRegion, loaded]);

  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? errTranslation(`error.${fieldState.error.message}`) : undefined;

  return (
    <>
      <AccordionButton>{oc('processingOptional')}</AccordionButton>
      <section>
        <p style={{ padding: '15px 0' }}>
          <img style={{ paddingRight: '10px' }} id="bundFertigungIcon" src={iconBund} alt={oc('processingOfBundlesOrContainers')} />
          {oc('processingOfBundlesOrContainers')}
        </p>
        {sortedTrayRegionAvailable && (
          <div className="row">
            <div className="col">
              <p className={classNames(styles.shippingP)}>{oc('1PerRoutingRegion')}</p>
            </div>
            <TextInput
              name={formControlNames[0]}
              label={oc('numberOfShipments')}
              type="number"
              rules={{
                min: { value: 0, message: 'negative' },
                validate: isValidInteger
              }}
              disabled={props.disabled}
              className={classNames('col')}
              error={getErrorMessage()}
              onChange={() => trigger(formControlNames[0] as any)}
            />
          </div>
        )}
        {sortedTrayPostcodeAvailable && (
          <div className={'row'}>
            <div className="col">
              <p className={classNames(styles.shippingP)}>{oc('2PerPostcode')}</p>
            </div>
            <TextInput
              name={formControlNames[1]}
              label={oc('numberOfShipments')}
              type="number"
              rules={{
                min: { value: 0, message: 'negative' },
                validate: isValidInteger
              }}
              disabled={props.disabled}
              className={classNames(styles.shippingInput + ' ' + 'col')}
              error={getErrorMessage()}
              onChange={() => trigger(formControlNames[1] as any)}
            />
          </div>
        )}
      </section>
      <hr />
      <DHLFormGroup>
        <p style={{ padding: '15px 0' }} className="col">
          <img style={{ paddingRight: '10px' }} id="palettenFertigungIcon" src={iconPallet} alt={oc('processingOfPalettes')} />
          {oc('processingOfPalettes')}
        </p>
        {sortedPalletZoneAvailable && (
          <div className="row">
            <div className="col">
              <p className={classNames(styles.shippingP)}>{oc('1PerRoutingZone')}</p>
            </div>
            <TextInput
              name={formControlNames[2]}
              label={oc('numberOfShipments')}
              type="number"
              rules={{
                min: { value: 0, message: 'negative' },
                validate: isValidInteger
              }}
              disabled={props.disabled}
              className={classNames('col')}
              error={getErrorMessage()}
              onChange={() => trigger(formControlNames[2] as any)}
            />
          </div>
        )}
        {sortedPalletRegionAvailable && (
          <div className="row">
            <div className="col">
              <p className={classNames(styles.shippingP)}>{oc('2PerRoutingRegion')}</p>
            </div>
            <TextInput
              name={formControlNames[3]}
              label={oc('numberOfShipments')}
              type="number"
              rules={{
                min: { value: 0, message: 'negative' },
                validate: isValidInteger
              }}
              disabled={props.disabled}
              className={classNames(styles.shippingInput + ' ' + 'col')}
              error={getErrorMessage()}
              onChange={() => trigger(formControlNames[3] as any)}
            />
          </div>
        )}
      </DHLFormGroup>
    </>
  );
};
