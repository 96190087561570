export interface StoragePayload<T> {
  timestampMs: number;
  expirationDurationMs?: number;
  data: T;
}

export function saveInSessionStorage<T>(key: string, data: T, expirationDurationMs?: number): void {
  sessionStorage.setItem(
    key,
    JSON.stringify({
      timestampMs: Date.now(),
      expirationDurationMs,
      data
    })
  );
}

function isStoragePayload<T>(storagePayload: StoragePayload<T> | undefined): storagePayload is StoragePayload<T> {
  return !!storagePayload && (!!+storagePayload.timestampMs || storagePayload.timestampMs === 0) && !!storagePayload.data;
}

export function loadFromSessionStorage<T>(key: string): T | undefined {
  const storedData = sessionStorage.getItem(key);

  try {
    const payload = JSON.parse(storedData as string);
    if (!isStoragePayload<T>(payload)) {
      removeFromSessionStorage(key);
      return undefined;
    }

    if (!!payload.expirationDurationMs && Date.now() > payload.timestampMs + payload.expirationDurationMs) {
      // data considered expired
      removeFromSessionStorage(key);
      return undefined;
    }

    return JSON.parse(payload.data as any);
  } catch (e) {
    console.warn('vkvlvk', e);
    return undefined;
  }
}

export function removeFromSessionStorage(key: string) {
  sessionStorage.removeItem(key);
}
