import React from 'react';

import { UserRights } from 'common/dtos/userRights';
import { useAuthContext } from 'common/context/auth/AuthContext';
import i18n from 'i18n';

import { ErrorMessageContainer } from '../../../order/productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';

interface Props {
  userRight: UserRights;
}

export const Authorized: React.FC<Props> = (props) => {
  const { hasPermission } = useAuthContext();
  const translate = (key: string): string => i18n.t(key);

  if (!hasPermission(props.userRight)) {
    return <ErrorMessageContainer message={`${translate('noPermission')}`} dataTestId={'no-permission'} />;
  }

  return <>{props.children}</>;
};
