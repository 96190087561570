import classNames from 'classnames';
import React, { AllHTMLAttributes, forwardRef, ReactNode } from 'react';
import classes from './input.module.css';

export type InputProps = AllHTMLAttributes<HTMLInputElement> & {
  wrapperProps?: AllHTMLAttributes<HTMLDivElement>;
  errorProps?: AllHTMLAttributes<HTMLParagraphElement>;
  labelProps?: AllHTMLAttributes<HTMLLabelElement>;
  error?: boolean;
  icon?: ReactNode;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(({ wrapperProps, errorProps, labelProps, error, icon, ...props }, ref) => (
  <div {...wrapperProps} className={classNames(classes.wrapper, wrapperProps?.className)}>
    <input
      {...props}
      ref={ref}
      className={classNames(
        classes.input,
        {
          [classes.inputError]: error,
          [classes.inputIcon]: icon,
          [classes.inputPlaceholder]: props.placeholder
        },
        props.className
      )}
    />
    {icon}
    {!!props.placeholder && (
      <label
        {...labelProps}
        htmlFor={props.id}
        className={classNames(
          classes.label,
          {
            [classes.labelError]: error,
            [classes.active]: props.value
          },
          errorProps?.className
        )}
      >
        {props.placeholder}
      </label>
    )}
  </div>
));

Input.displayName = 'Input';
