import i18n from 'i18n';

const translation = {
  heading: 'Auftragsbezeichnung',
  label: 'Auftragsbezeichnung',
  type: 'ZIELGRUPPE',
  errors: {
    orderLabel: 'Bitte prüfen Sie Ihre Eingabe. Der eingegebene Text ist zu lang.'
  }
};
i18n.addResourceBundle('de', 'JobTitleSection', translation);

i18n.addResourceBundle('en', 'JobTitleSection', translation);
