import classes from 'common/components/ContextMenu/context-menu.module.css';
import { reference } from 'common/utils/helpers';
import { HTMLProps } from 'react';

export type MenuProps = HTMLProps<HTMLDivElement> & {
  isOpen?: boolean;
};
export const Menu = reference<HTMLDivElement, MenuProps>(
  'Menu',
  'div',
  (props) => [classes.menu, { [classes.visible]: props.isOpen }],
  ({ isOpen, ...props }) => ({
    ...props,
    visible: isOpen ? 'true' : 'false'
  })
);
