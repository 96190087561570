import { procedureCache } from 'common/services/ProcedureService';
import { findFirstRecursively, mapRecursively } from 'common/utils/helpers';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { OrderSearchKey, orderSearchKeyReplyTDOMapping } from 'order/common/context/search/dtos/OrderSearchKey';
import { DefaultAttribute, OrderSearchAttribute } from 'order/orderSearch/services/orderSearchAttribute';

export const recursivelyFlattenOrderTree = (items: OrderTreeItem<OrderReplyTDO>[] = []): OrderTreeItem<OrderReplyTDO>[] =>
  items.reduce<OrderTreeItem<OrderReplyTDO>[]>((acc, { children, ...data }) => [...acc, data, ...recursivelyFlattenOrderTree(children)], []);

export const checkExpandedOrHighlighted = (
  attributes: OrderSearchAttribute[] | undefined,
  treeItem: OrderTreeItem<OrderReplyTDO>
): OrderTreeItem<OrderReplyTDO> => {
  const children = treeItem.children?.map((child) => checkExpandedOrHighlighted(attributes, child));

  const highlight =
    attributes?.some(({ key, value }) => {
      const valueKey = orderSearchKeyReplyTDOMapping.get(key);
      if (!valueKey || !treeItem.data?.[valueKey] || typeof value !== 'string') {
        return false;
      }
      return treeItem.data?.[valueKey]?.toString().includes(value);
    }) || false;

  const autoExpanded = highlight || children?.some((child) => child.autoExpanded);

  return {
    ...treeItem,
    highlight,
    autoExpanded,
    children
  };
};

export const resolveProcedureNamesRecursively = (treeItem: OrderTreeItem<OrderReplyTDO>): OrderTreeItem<OrderReplyTDO> => {
  const foundProcedure = treeItem.data?.procedure ? procedureCache.get(treeItem.data.procedure) : undefined;
  let newTreeItem = treeItem;
  if (foundProcedure?.name && treeItem.data) {
    newTreeItem = {
      ...treeItem,
      data: {
        ...treeItem.data,
        procedure: foundProcedure.name
      }
    };
    treeItem.data.procedure = foundProcedure.name;
  }

  return {
    ...newTreeItem,
    children: newTreeItem.children?.map(resolveProcedureNamesRecursively)
  };
};

const resolveCustomerInfo = (attribute: DefaultAttribute, items: OrderTreeItem<OrderReplyTDO>[] = []): DefaultAttribute => {
  const newVar = orderSearchKeyReplyTDOMapping.get(attribute.key);
  if (!newVar) {
    return attribute;
  }
  const reply = findFirstRecursively(items, 'children', (item) => {
    const rowData = item.data?.[newVar];
    return typeof rowData === 'object' && rowData?.customerId === attribute.value;
  });
  const rowValue = reply?.data?.[newVar];
  if (typeof rowValue === 'object') {
    return {
      ...attribute,
      meta: rowValue.name
    };
  }
  return attribute;
};

const resolveNewspaperTitle = (
  attribute: DefaultAttribute,
  items: OrderTreeItem<OrderReplyTDO>[] = []
): [DefaultAttribute, OrderTreeItem<OrderReplyTDO>[]] => {
  const zkzNormalized = attribute.value.padStart(6, '0');

  const reply = findFirstRecursively(items, 'children', (item) => item.data?.zkz === zkzNormalized);

  const itemsWithNewspaperTitles = mapRecursively(items, 'children', (item) => {
    if (item.data?.zkz && item.data?.zkz === zkzNormalized) {
      const zkzWithTitle = item.data.zkz + ' ' + (item.data.newspaperTitle ? item.data.newspaperTitle : '');

      return {
        ...item,
        data: {
          ...item.data,
          zkz: zkzWithTitle
        }
      };
    }
    return item;
  });

  return [
    {
      ...attribute,
      meta: reply?.data?.newspaperTitle
    },
    itemsWithNewspaperTitles
  ];
};

export const resolveMetaInfo = (
  attributes: OrderSearchAttribute[],
  items: OrderTreeItem<OrderReplyTDO>[] = []
): [OrderSearchAttribute[], OrderTreeItem<OrderReplyTDO>[]] => {
  let changedItems = items;
  const changedAttributes = attributes.map((attribute) => {
    switch (attribute.key) {
      case OrderSearchKey.ZKZ:
        const [attributeWithTitle, itemsWithTitles] = resolveNewspaperTitle(attribute, items);
        changedItems = itemsWithTitles;
        return attributeWithTitle;
      case OrderSearchKey.Beneficiary:
      case OrderSearchKey.Payer:
      case OrderSearchKey.Originator:
      case OrderSearchKey.Producer:
      case OrderSearchKey.Submitter:
        return resolveCustomerInfo(attribute, items);
      default:
        break;
    }
    return attribute;
  });
  return [changedAttributes, changedItems];
};
