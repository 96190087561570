import { Destination } from '../context/order/dtos/GetOrder';

const reduceRangeSum = (sum: number | undefined, current: string | number | undefined): number => (Number(sum) || 0) + (Number(current) || 0);

export const calculateRanges = (ranges: Destination[]): number => {
  let totalCount = 0;
  const allFrom = ranges.map((r) => r.from).reduce(reduceRangeSum, 0);
  const allTo = ranges.map((r) => r.to).reduce(reduceRangeSum, 0);

  if (allTo && allFrom && allTo >= allFrom) {
    const add = ranges.length;
    totalCount = allTo - allFrom + add;
  }

  return totalCount;
};
