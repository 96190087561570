// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customRenderer_orderNumberSpan__qjFxu {
  cursor: default;
}

.customRenderer_dhlTableCellWithIcons__H0PSp {
  background-color: transparent;
  border: none;
}

.customRenderer_wordBreak__lP4lO {
  word-break: break-all;
  max-width: 250px !important;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/table/customRenderer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B","sourcesContent":[".orderNumberSpan {\n  cursor: default;\n}\n\n.dhlTableCellWithIcons {\n  background-color: transparent;\n  border: none;\n}\n\n.wordBreak {\n  word-break: break-all;\n  max-width: 250px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderNumberSpan": `customRenderer_orderNumberSpan__qjFxu`,
	"dhlTableCellWithIcons": `customRenderer_dhlTableCellWithIcons__H0PSp`,
	"wordBreak": `customRenderer_wordBreak__lP4lO`
};
export default ___CSS_LOADER_EXPORT___;
