import { useRecommendations, UseRecommendationsReturn } from 'common/hooks/useRecommendations';
import { requestUserRecommendations, sendUserRecommendations } from 'common/services/recommendations';
import { DefaultAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';

export type SearchRecommendation = {
  value: string;
  meta?: string;
};

export type searchType = {
  type: OrderSearchKey;
};

export const useSearchRecommendations = (key: OrderSearchKey): UseRecommendationsReturn<DefaultAttribute> & searchType => {
  const keyValue = key + '-Recommendations';
  const internalReturn = useRecommendations<DefaultAttribute>({
    key: keyValue,
    get: async () => {
      return await requestUserRecommendations<DefaultAttribute>(keyValue);
    },
    set: async (values) => {
      await sendUserRecommendations(keyValue, JSON.stringify(values));
    },
    compare: (a, b) => a.value === b.value
  });

  const toRec = (customer: DefaultAttribute): DefaultAttribute => ({
    key: customer.key,
    value: customer.value,
    meta: customer.meta,
    id: new Date().toISOString()
  });
  const fromRec = (c: DefaultAttribute): DefaultAttribute => ({
    key: c.key,
    value: c.value,
    meta: c.meta,
    id: c.id
  });

  return {
    add: (customer) => internalReturn.add(toRec(customer)),
    load: () => internalReturn.load().then((x) => x.map(fromRec)),
    delete: (customer) => internalReturn.delete(toRec(customer)),
    recommendations: internalReturn.recommendations.map(fromRec),
    type: key
  };
};
