// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertDescription_moreless__dYLUs {
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
}
.AlertDescription_alertContainer__0NxCK p {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Alerts/AlertDescription/AlertDescription.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,YAAY;EACZ,UAAU;AACZ;AACA;EACE,eAAe;AACjB","sourcesContent":[".moreless {\n  background: none;\n  text-decoration: underline;\n  border: none;\n  padding: 0;\n}\n.alertContainer p {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreless": `AlertDescription_moreless__dYLUs`,
	"alertContainer": `AlertDescription_alertContainer__0NxCK`
};
export default ___CSS_LOADER_EXPORT___;
