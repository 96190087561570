// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-column-menu_ContextMenuSearchWrapper__mhQE\\+ {
  min-width: 132px;
}

.table-column-menu_hoverOnDisable__jKsfK:hover{
  cursor: not-allowed;
  color: #a8a7a7 !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/Table/head/column-menu/table-column-menu.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".ContextMenuSearchWrapper {\n  min-width: 132px;\n}\n\n.hoverOnDisable:hover{\n  cursor: not-allowed;\n  color: #a8a7a7 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContextMenuSearchWrapper": `table-column-menu_ContextMenuSearchWrapper__mhQE+`,
	"hoverOnDisable": `table-column-menu_hoverOnDisable__jKsfK`
};
export default ___CSS_LOADER_EXPORT___;
