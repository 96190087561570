import React, { useEffect, useState } from 'react';
import { AccordionItem, UseAccordionReturn } from 'common/components/Accordion';
import { AvailableProductGroup } from 'order/productGroups/common/utils/orderCreateSchema';
import { FertigungSection } from 'order/productGroups/dialogMarketing/components/FertigungSection/FertigungSection';

interface Props {
  id: number;
  register: UseAccordionReturn<unknown>;
  disabled: boolean;
  productGroup?: AvailableProductGroup;
  selectedDate?: Date;
  baseProduct?: string;
}

export const AccordionItemFertigungSection = (props: Props): JSX.Element | null => {
  const [loaded, setLoaded] = useState(false);
  const { register, id, disabled, productGroup, selectedDate, baseProduct } = props;
  const data = register(id);

  // ugly workaround due to race condition only occuring when navigation back from Step 3
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleOnOpen = () => {
    data.setOpen && data.setOpen(true);
  };

  return loaded ? (
    <AccordionItem {...data}>
      <FertigungSection disabled={disabled} productGroup={productGroup} selectedDate={selectedDate} baseProduct={baseProduct} onOpen={handleOnOpen} />
    </AccordionItem>
  ) : null;
};
