// <editor-fold desc='LineItem.Variant'>
export const lineItemVariant = ['BKD', 'NKD', 'NWE', 'WE', 'SACHETS', 'LR', 'GOGREEN', 'SH', 'VP'] as const;
// </editor-fold>

export const noInfo = 'NS';

export type LineItemVariant = typeof lineItemVariant[number];

// <editor-fold desc='MailEntity.Type'>
export const mailEntityType = ['PAL', 'TRT', 'TRA', 'BDL', 'LETTER'] as const;
// </editor-fold>

export type MailEntityType = typeof mailEntityType[number];

// <editor-fold desc='Postage.Type'>
export const postageType = ['EP', 'IV', 'PWZ', 'FS', 'PL', 'PC', 'FV', 'DV', 'AS', 'AFM'] as const;
// </editor-fold>

export type PostageType = typeof postageType[number];

// <editor-fold desc='Einlieferungskennung'>
// Werbeversand (W) is retired and was manually removed from selection
export const einlieferungskennung = ['S', 'R'] as const;
// </editor-fold>

export type Einlieferungskennung = typeof einlieferungskennung[number];
