import React, { FC, useMemo } from 'react';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import { useTranslation } from 'i18n';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { isValidDate } from 'order/common/utils/isValidDate';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCustomerRoleEnum } from 'generated';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { InternationalClusterOrderCreate } from '../../schema/internationalClusterSchema';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { PressAdditionalServiceLetterProductName } from '../../../../common/components/Overview/PressProductInfoSection/PressAdditionalServiceLetterProductName';
import style from 'App.module.css';

export type InternationalClusterOverviewProps = {
  value: InternationalClusterOrderCreate;
};

export const InternationalClusterOverview: FC<InternationalClusterOverviewProps> = ({ value }) => {
  const { getFacility } = useProductionFacilities(value?.details.date);
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const language = useAuthContext().user.language;
  const facility = getFacility(value?.details.productionPlantId || '');
  const validDate = isValidDate(value?.details.date);
  const postingDate = !validDate && value?.details.date ? new Date(value?.details.date) : value?.details.date;
  const { order, orderMode, orderCategory, orderCategoryProductKey, meta } = useOrderContext();
  const pickup = !!value?.pickupAddress;
  const { t } = useTranslation('translation');

  const parties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Originator,
        customerId: value?.originator
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: value?.submitter
      },
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: value?.payer
      },
      ...(value?.otherPartners?.map((partner) => ({
        role: partner.role,
        customerId: partner.customerId
      })) || [])
    ];
  }, [value]);

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });
  const hideEcoMix = value.productGroup === ProductGroup.BRIEF_INTERNATIONAL || value.productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL;

  return (
    <DialogPostContainer>
      <DHLFormGroup>
        <h6 style={{ marginBottom: '-5px', marginTop: '15px', fontWeight: '600' }}>{oc('orderInfo')}</h6>
        <OrderOverviewRow>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderCategory')}:`}</p>
                </td>
                <td>
                  <p>
                    {order?.orderCategoryProductKey
                      ? translateCategory(order?.orderCategoryProductKey as string)
                      : orderCategoryProductKey
                      ? translateCategory(orderCategoryProductKey as string)
                      : orderCategory}
                  </p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderName')}:`}</p>
                </td>
                <td>
                  <p>{value?.orderLabel}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}></OrderOverviewWidget>
          {order?.orderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('orderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.orderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
          {order?.customerOrderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('customerOrderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.customerOrderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>

        <OrderOverviewRow>
          <OrderOverviewWidget title={oc('basicProduct')}>
            <p>{value?.internationalBaseProduct?.descriptionShort}</p>
          </OrderOverviewWidget>

          <OrderOverviewWidget title={oc('furtherProductCharacteristics')}>
            {[LineItemVariant.GOGREEN]?.map((variant) => (
              <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
            ))}
          </OrderOverviewWidget>
        </OrderOverviewRow>
        <OrderOverviewRow>
          <div>
            <OrderOverviewWidget title={oc('transportService', { keyPrefix: 'step2Form' })}>
              <table>
                {value?.transportServices?.map((ts, i) => (
                  <>
                    <tr>
                      <td>
                        <p>
                          {`${oc('transportService', { keyPrefix: 'step2Form' })} ${
                            value?.productGroup === ProductGroup.BRIEF_INTERNATIONAL ? '' : i + 1
                          }`}
                          :
                        </p>
                      </td>
                      <td>
                        <p>{ts?.baseProduct?.description}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{oc('shipmentVolume')}</p>
                      </td>
                      <td>
                        <p>{ts?.baseProduct?.quantity?.toLocaleString(language)}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{oc('totalWeightInKg')}:</p>
                      </td>
                      <td>
                        <p>{ts?.baseProduct?.grossWeight?.toLocaleString(language)} kg</p>
                      </td>
                    </tr>
                    {value?.productGroup !== ProductGroup.DIALOGPOST_INTERNATIONAL && (
                      <tr>
                        <td>
                          <p>{oc('additionalLetterService')}:</p>
                        </td>
                        <td>
                          {ts?.discounts?.map((d) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <p>
                                      {
                                        <PressAdditionalServiceLetterProductName
                                          baseProductNumber={ts.baseProduct.productNumber}
                                          selectedDate={postingDate}
                                          productNumber={d.productNumber}
                                        />
                                      }
                                    </p>
                                  </td>
                                  <td className={style.rightAlign}>
                                    <tr>
                                      <td>
                                        <p>{d.shipmentQuantity?.toLocaleString(language)}</p>
                                      </td>
                                    </tr>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
            </OrderOverviewWidget>
          </div>
        </OrderOverviewRow>

        {value.productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL && !!value?.additionallyPaidItems && (
          <OrderOverviewRow>
            <OrderOverviewWidget title={oc('additionallyPaidItems', { keyPrefix: 'step2Form' })}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('shipmentVolume')}`}</p>
                  </td>
                  <td>
                    <p>{value?.additionallyPaidItems}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          </OrderOverviewRow>
        )}

        <OrderOverviewRow>
          <OrderPickupShippingDisplay
            data={{
              date: postingDate,
              pickup,
              time: value?.details.time,
              facility,
              submissionType: value?.details?.submissionType,
              contactForQuestions: value?.contactForQuestions,
              pickupAddress: { orderAddress: value?.pickupAddress }
            }}
          />
          <OrderOverviewWidget title={oc('bundles')}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>{oc('typeOfBundle')}</p>
                  </td>
                  <td>
                    <p>{value?.packaging?.type && tCatalogValues('mailEntityType.display.' + value?.packaging?.type)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('numberOfBundles')}</p>
                  </td>
                  <td>
                    <p>{value?.packaging?.quantity?.toLocaleString(language)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('totalGrossWeightInKg')}</p>
                  </td>
                  <td>
                    <p>{value?.destinations?.totalWeight?.toLocaleString(language)} kg</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('stackable')}</p>
                  </td>
                  <td>
                    <p>{value?.destinations?.stackable ? 'ja' : 'nein'}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </OrderOverviewWidget>
          {value.packaging.type === 'PAL' && (
            <OrderOverviewWidget title={oc('palletsPerIPZ', { keyPrefix: 'palletsInternational' })}>
              <table style={{ tableLayout: 'auto', width: 'auto' }}>
                <tbody>
                  {!Number.isNaN(value?.destinations?.priority) && (
                    <tr>
                      <td>
                        <p>{oc('priority', { keyPrefix: 'palletsInternational' })}:</p>
                      </td>
                      <td style={{ paddingLeft: '30px' }}>
                        <p>{value?.destinations?.priority}</p>
                      </td>
                    </tr>
                  )}
                  {!hideEcoMix && !Number.isNaN(value?.destinations?.economy) && (
                    <tr>
                      <td>
                        <p>{oc('economy', { keyPrefix: 'palletsInternational' })}:</p>
                      </td>
                      <td style={{ paddingLeft: '30px' }}>
                        <p>{value?.destinations?.economy}</p>
                      </td>
                    </tr>
                  )}
                  {!hideEcoMix && !Number.isNaN(value?.destinations?.mixed) && (
                    <tr>
                      <td>
                        <p>{oc('mixed', { keyPrefix: 'palletsInternational' })}:</p>
                      </td>
                      <td style={{ paddingLeft: '30px' }}>
                        <p>{value?.destinations?.mixed}</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
        <CustomerDetailSection parties={parties} step={'Overview'} validityDate={order?.orderDetail?.date} />
      </DHLFormGroup>
    </DialogPostContainer>
  );
};
