import { useSearchParams } from 'order/common/hooks/useSearchParams';
import { OrderViewRouteParams } from 'order/orderView/components/OrderView/OrderView';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useOrderView = () => {
  const urlParams = useParams<OrderViewRouteParams>();
  const [orderType] = useSearchParams('orderType');

  return {
    orderId: urlParams.orderId,
    status: urlParams.status,
    orderType
  };
};
