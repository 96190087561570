// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InternationalBaseProducts_subHeading__qLi6N {
  font-weight: bold;
}

.InternationalBaseProducts_wrapper__5MPfy {
  position: relative;
  margin-top: 24px;
}

.InternationalBaseProducts_disabledOverlay__X9kfZ {
  position: absolute;
  width: 100%;
  height: calc(100% - 120px);
  opacity: 0.4;
  background: #f5f5f5;
  z-index: 2;
  top: 120px;
  border-radius: 6px;
}

.InternationalBaseProducts_delete__ZoMW1 {
  position: absolute;
  right: 6px;
  top: -8px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/international/components/BaseProducts/InternationalBaseProducts.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX","sourcesContent":[".subHeading {\n  font-weight: bold;\n}\n\n.wrapper {\n  position: relative;\n  margin-top: 24px;\n}\n\n.disabledOverlay {\n  position: absolute;\n  width: 100%;\n  height: calc(100% - 120px);\n  opacity: 0.4;\n  background: #f5f5f5;\n  z-index: 2;\n  top: 120px;\n  border-radius: 6px;\n}\n\n.delete {\n  position: absolute;\n  right: 6px;\n  top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeading": `InternationalBaseProducts_subHeading__qLi6N`,
	"wrapper": `InternationalBaseProducts_wrapper__5MPfy`,
	"disabledOverlay": `InternationalBaseProducts_disabledOverlay__X9kfZ`,
	"delete": `InternationalBaseProducts_delete__ZoMW1`
};
export default ___CSS_LOADER_EXPORT___;
