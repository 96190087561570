import React, { useEffect, useMemo, useState } from 'react';
import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import { ProductInfoTable } from '../ProductInfoTable';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toFormattedDate } from 'common/formatting';
import { isValidDate } from 'order/common/utils/isValidDate';
import { computedValues } from 'order/productGroups/common/components/molecule/ProductInfoTable/schemas/ProductInfoTableSchemea';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { BaseProduct, LetterBaseProduct } from 'order/common/context/order/dtos/GetOrder';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { LetterBaseProduct as APILetterBaseProduct } from 'generated';
import { AddressSectionSchema } from '../../AddressSection/addressSectionSchema';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { InternationalBaseProduct } from 'order/productGroups/international/dtos/InternationalBaseProducts';
import { hierarchyProductGroup } from 'order/productGroups/international/workFlowSteps/Step2/InternationalClusterStep2';
import { PressBaseProduct } from 'order/productGroups/press/schema/pressSchema';

export interface ProductInfoTableWrapperProps {
  editing?: boolean;
  showMultipleProducts?: boolean;
}

export interface ProductInfoTableWrappereData {
  productGroup: ProductGroup | string;
  variants?: LineItemVariant[];
  orderLabel?: string;
  quantity: number;
  individualWeightValue: number;
  earliestDateTime: Date;
  time?: string;
  orderCategory?: string;
  submitter: string;
  productNumber: string;
  productionPlantId?: string;
  product?: string;
  contractor?: string;
  payer?: string;
  baseProduct?: BaseProduct[];
  packagingQuantity: number;
  packagingType?: string;
  totalWeight: number;
  letterBaseProducts?: LetterBaseProduct[];
  orderId?: string;
  partialServiceNumber?: string;
  pickupAddress?: AddressSectionSchema;
  zkz: string;
  newsPaperNumber?: string;
  nationalProducts: PressBaseProduct[];
  internationalProducts: PressBaseProduct[];
  postaktuellLetterBaseProducts?: APILetterBaseProduct[];
  internationalBaseProduct?: InternationalBaseProduct;
  transportServices: APILetterBaseProduct[];
}

export const ProductInfoTableWrapper = (props: ProductInfoTableWrapperProps) => {
  const { control, setValue, getValues } = useOrderTransferForm<OrderCreate>();
  const { order, meta } = useOrderContext();
  const [zkzTitle, setTitleZkzTitle] = useState<string | undefined>('');
  const { t } = useTranslation('catalogValues');
  const [
    productGroup,
    orderLabel,
    productNumber,
    variants,
    quantity,
    individualWeightValue,
    earliestDateTime,
    time,
    orderCategory,
    submitter,
    productionPlantId,
    packagingQuantity = 0,
    packagingType,
    totalWeight,
    product,
    contractor,
    payer,
    orderId,
    partialServiceNumber,
    pickupAddress,
    zkz,
    newsPaperNumber,
    nationalProducts,
    internationalProducts,
    PostaktuellLetterBaseProducts,
    internationalBaseProduct,
    transportServices
  ] = useWatch({
    control,
    name: [
      'productGroup',
      'orderLabel',
      'dialogpostBaseProduct',
      'furtherInfo',
      'details.quantity',
      'details.individualWeight',
      'details.date',
      'details.time',
      'orderCategory',
      'submitter',
      'details.productionPlantId',
      'packaging.quantity',
      'packaging.type',
      'destinations.totalWeight',
      'product',
      'contractor',
      'payer',
      'orderId',
      'partialServiceNumber',
      'pickupAddress',
      'journal.zkz',
      'journal.journalNumber',
      'nationalProducts',
      'internationalProducts',
      'letterBaseProducts',
      'internationalBaseProduct',
      'transportServices'
    ]
  });

  const data: ProductInfoTableWrappereData = {
    productGroup: productGroup,
    orderLabel: orderLabel,
    quantity: quantity,
    individualWeightValue: individualWeightValue,
    productNumber: productNumber,
    variants: variants,
    earliestDateTime: earliestDateTime,
    time: time,
    orderCategory: orderCategory,
    submitter: submitter,
    productionPlantId: productionPlantId,
    packagingQuantity: packagingQuantity,
    packagingType: packagingType,
    totalWeight: totalWeight,
    product: product,
    contractor: contractor,
    payer: payer,
    letterBaseProducts: order?.letterBaseProducts,
    orderId: orderId,
    partialServiceNumber: partialServiceNumber,
    pickupAddress: pickupAddress,
    zkz: zkz,
    newsPaperNumber: newsPaperNumber,
    nationalProducts: nationalProducts,
    internationalProducts: internationalProducts,
    postaktuellLetterBaseProducts: PostaktuellLetterBaseProducts as any[],
    internationalBaseProduct: internationalBaseProduct,
    transportServices: transportServices
  };

  const validDate = isValidDate(data?.earliestDateTime);
  const date = !validDate && data?.earliestDateTime ? new Date(data?.earliestDateTime) : data?.earliestDateTime;
  const dateString = toFormattedDate(date);
  const baseProducts =
    useBaseProducts(order?.letterProductGroup === ProductGroup.HYBRID_BRIEF ? ProductGroup.HYBRID_BRIEF : data.productGroup, data.earliestDateTime) ||
    [];
  const transportServiceProducts = useBaseProducts(
    'BRIEF',
    undefined,
    'POSTING',
    undefined,
    'International',
    hierarchyProductGroup[productGroup as keyof typeof hierarchyProductGroup]
  );

  const { getFacility } = useProductionFacilities(date);
  const productionFacility = data?.productionPlantId ? getFacility(data?.productionPlantId) : undefined;
  const language = useAuthContext().user.language;
  const datatype =
    data?.packagingType === 'PAL'
      ? data?.packagingQuantity > 1
        ? t('postageTypeView.display.PALDesc.more')
        : t('postageTypeView.display.PALDesc.one')
      : data?.packagingType === 'TRT'
      ? data?.packagingQuantity > 1
        ? t('postageTypeView.display.trolley.more')
        : t('postageTypeView.display.trolley.one')
      : data?.packagingType === 'TRA'
      ? data?.packagingQuantity > 1
        ? t('postageTypeView.display.container.more')
        : t('postageTypeView.display.container.one')
      : data?.packagingType === 'BDL'
      ? data?.packagingQuantity > 1
        ? t('postageTypeView.display.bundle.more')
        : t('postageTypeView.display.bundle.one')
      : data?.packagingType === 'LETTER'
      ? data?.packagingQuantity > 1
        ? t('postageTypeView.display.brief.more')
        : t('postageTypeView.display.brief.one')
      : t('mailEntityType.display.' + data?.packagingType);
  const { premium } = useDependentProducts(data?.productGroup, data?.earliestDateTime, data?.productNumber);

  const productNames = useMemo<string>(() => {
    const baseProduct = baseProducts.find((p) => p.productNumber === data?.productNumber)?.descriptionShort || '';
    if (!props.showMultipleProducts) {
      return baseProduct;
    }
    const variantList = data?.variants?.map((variant: string) => t('lineItemVariant.display.' + variant)) || [];
    return [baseProduct, ...variantList].filter((c) => !!c).join(', ');
  }, [baseProducts, premium, data?.productNumber, props.showMultipleProducts, t, data?.variants]);

  const internationalBaseProductName = data?.internationalBaseProduct?.descriptionShort || '';

  useEffect(() => {
    if (meta?.zkzTitle) {
      setTitleZkzTitle(meta?.zkzTitle);
    }
  }, [meta?.zkzTitle]);

  const values: computedValues = {
    dateString: dateString,
    title: zkzTitle,
    baseProducts: baseProducts,
    productionFacility: productionFacility,
    premium: premium,
    transportServiceProducts: transportServiceProducts,
    productNames: productNames,
    internationalBaseProductName: internationalBaseProductName,
    datatype: datatype
  };
  return <ProductInfoTable data={data} values={values} editing={props.editing} meta={{ language }} />;
};
