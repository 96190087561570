import { getPayervalidation } from 'common/services/CustomerService';

const validatePayerId = (customerId = ''): boolean => {
  return /^\d{10}$/.test(customerId.slice(0, 10));
};

export interface validateReturn {
  validates: boolean;
}

/**
 * validate the payer info using the user's id.
 * The id must be exactly ten digits after trimming.
 *
 * @param customerId the customer id
 */
export const getPayervalidationInfo = async (customerId?: string): Promise<validateReturn> => {
  const trimmed = customerId?.replace(/\s+/g, '') || '';
  if (!validatePayerId(trimmed)) {
    return { validates: false };
  }

  try {
    const data = await getPayervalidation(trimmed);
    return { validates: !!data };
  } catch (e) {
    console.error('Failed to validate for', customerId, e);
    return { validates: false };
  }
};
