import { CSVMatrixElement, matrixToCsv } from 'common/csv';

import { SearchOrderRep } from 'generated';
import moment from 'moment';
import { OrderSearchResultRepTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { deliveryDateRangeEvaluation } from '../ExportUtils';
import { csvCols } from './csvCols';
import { translate } from './translateCsv';
import { valueToString } from './valueToString';
import { OrderSearchAttribute, printAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { Filter } from '../../../../common/components/Table/dtos/Filter';

type CsvColsKeys = keyof typeof csvCols;

/**
 * Transfers a search attribute to an array of strings.
 *
 * @param attr search attribute to transfer
 * @return {string[]} strings representing the attribute
 */
const attrToString = (attr: OrderSearchAttribute): string[] => {
  const { label, value } = printAttribute(attr);
  return [label, value];
};

interface CsvTreeItem {
  order: SearchOrderRep;
  parent?: SearchOrderRep;
  children?: OrderTreeItem<SearchOrderRep>[];
}

type CsvOrder = SearchOrderRep & { jobGroup: string; partialGroup: string };

/**
 * Takes search result data and search attributes and generates a csv string.
 *
 * @param store the order search store
 * @return {string} csv string
 */
export const toCsv = ({
  attributes,
  rawResponse,
  response,
  userName,
  filter
}: {
  response?: OrderSearchResultRepTDO;
  rawResponse?: OrderSearchResultRepTDO;
  attributes: OrderSearchAttribute[];
  userName: string;
  filter: Filter;
}): string => {
  const search = attributes.map(attrToString) || [];

  const keys: string[] = Object.keys(csvCols);
  const resultRows: string[][] =
    rawResponse?.orders
      ?.slice()
      .filter((o) => !!o)
      // .sort((itemA, itemB) => moment(itemB.data?.creationDate).valueOf() - moment(itemA.data?.creationDate).valueOf())
      .flatMap((item) => {
        return item.children
          ? [{ order: item.data, children: item.children } as CsvTreeItem].concat(
              item.children.map((child) => ({ order: child.data, parent: item.data, children: child.children } as CsvTreeItem))
            )
          : [{ order: item.data, children: item.children } as CsvTreeItem];
      })
      .map(
        (csvItem) =>
          ({
            ...csvItem.order,
            firstInductionDate: ['JS', 'TE'].includes(csvItem.order.orderType || '')
              ? deliveryDateRangeEvaluation(csvItem.order, csvItem.children).replace(/^-$/, '')
              : csvItem.order.firstInductionDate,
            jobGroup:
              csvItem.order.orderType === 'JS'
                ? translate('jobGroup')
                : csvItem.parent && csvItem.parent.orderType === 'JS'
                ? csvItem.parent.orderNumber
                : '',
            partialGroup:
              csvItem.order.orderType === 'TE'
                ? translate('partialGroup')
                : csvItem.parent && csvItem.parent.orderType === 'TE'
                ? csvItem.parent.orderNumber
                : ''
          } as CsvOrder)
      )
      .map((csvOrder) => keys.map((key: string) => valueToString(csvOrder, key as CsvColsKeys))) || [];

  const contentMatrix = filter.value
    ? [
        [translate('title')],
        [],
        [translate('exportedBy'), userName],
        [translate('exportedOn'), moment().format('DD.MM.YYYY HH:mm').toString()],
        [],
        [translate('searchCriteria')],
        ...search,
        [],
        [translate('filterCriteria')],
        [translate(filter.name ?? ''), filter.value as string],
        [],
        [translate('amountFound'), response?.orders?.length || 0],
        [],
        [translate('resultTable')],
        Object.values(csvCols),
        ...resultRows
      ]
    : [
        [translate('title')],
        [],
        [translate('exportedBy'), userName],
        [translate('exportedOn'), moment().format('DD.MM.YYYY HH:mm').toString()],
        [],
        [translate('searchCriteria')],
        ...search,
        [],
        [translate('amountFound'), response?.orders?.length || 0],
        [],
        [translate('resultTable')],
        Object.values(csvCols),
        ...resultRows
      ];

  return matrixToCsv(contentMatrix as CSVMatrixElement[][]);
};
