import { Endpoint, responseOrUndefined } from 'common/utils/apiUtils';
import { ProcedureTO } from 'generated';
import masterdataApi from './index';

export type ProcedureTDO = ProcedureTO;

/**
 * Asynchrone REST-Funktion um die Verfahren abzurufen.
 * @return CustomerTDO
 */
const getAllProcedures: Endpoint<void, ProcedureTDO[]> = async () => responseOrUndefined(masterdataApi.getProcesses());

export let procedureCache: Map<string, ProcedureTDO> = new Map();

const initializeCache = async (): Promise<Map<string, ProcedureTDO>> => {
  const procedures = (await getAllProcedures()) || [];
  return procedures.reduce((map, procedure) => (procedure.id ? map.set(procedure.id, procedure) : map), new Map());
};

export const procedureCacheReload = async () => {
  await initializeCache().then((map) => (procedureCache = map));
};
