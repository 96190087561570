import { toFormattedCurrency, toFormattedDate, toFormattedNumber } from 'common/formatting';
import { OrderTreeItem } from 'order/orderView/components/OrderTree/OrderTree';

export const valueToString = (key: Exclude<keyof OrderTreeItem, 'children'>, value: Date | string | number | undefined = ''): string => {
  if (!value) {
    return '';
  }
  if (typeof value === 'object') {
    return toFormattedDate(value) || '';
  }
  if (typeof value === 'number') {
    if (key === 'totalAmount') {
      return toFormattedCurrency(value) || '';
    }
    return toFormattedNumber(value) || '';
  }
  return value;
};

export const SINGLE_ORDER_TREE_ITEM_KEYS: Exclude<keyof OrderTreeItem, 'children'>[] = [
  'orderId',
  'description',
  'zkz',
  'newspaperNumber',
  'product',
  'quantity',
  'totalAmount',
  'date',
  'state'
];
export const MULTIPLE_ORDER_TREE_ITEM_KEYS: Exclude<keyof OrderTreeItem, 'children'>[] = [
  'orderId',
  'orderType',
  'description',
  'newspaperNumber',
  'product',
  'quantity',
  'totalAmount',
  'date',
  'state'
];
export const getOrderTreeItemKeys = (showZkz: boolean = false, hasChildren: boolean = false): Exclude<keyof OrderTreeItem, 'children'>[] => {
  if (hasChildren) return MULTIPLE_ORDER_TREE_ITEM_KEYS.filter((key) => (showZkz ? key !== 'description' : key !== 'newspaperNumber'));
  else return SINGLE_ORDER_TREE_ITEM_KEYS.filter((key) => (showZkz ? key !== 'description' : key !== 'newspaperNumber' && key !== 'zkz'));
};
