import * as React from 'react';
import { classNames } from '../../utils/utils';
import { ShuttleContext } from './ShuttleContext';
import { ShuttleState } from './hooks/useShuttleState';
import { FormRow } from '../../../../components/FormRow';

export type ShuttleContainerProps = {
  /**
   * Child render function of the Shuttle Container.
   * This is where you render your Shuttle.Item components.
   */
  children: (
    store: ShuttleState,
    getItemProps: (index: number) => {
      'data-index': number;
      selected: boolean;
    }
  ) => React.ReactNode;

  /**
   * Optionally pass a className to the container
   * for CSS styles.
   */
  className?: string;
  name: string;
  headline1: string;
  headline2: string;
  error?: boolean;
} & Record<string, any>;

/**
 * Pass a child render function or a render prop.
 */
export const ShuttleContainer: React.FC<ShuttleContainerProps> = React.memo(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, ShuttleContainerProps>(({ children, className, ...rest }, ref) => {
    const { shuttleState } = React.useContext(ShuttleContext);

    // mod needed for HMR updates
    // const id = React.useRef(SHUTTLE_CONTAINERS_ARRAY[Math.floor(idInt++ % NUMBER_OF_CONTAINERS)]);

    /**
     * Pass the props to the item as you render it.
     * This is important to include since it contains
     * optimizations for click events on the shuttle
     * item.
     */
    const getItemProps = React.useCallback(
      (index: number) => {
        return {
          'data-index': index,
          selected: (shuttleState.selected as any)[rest.name].has(index)
        };
      },
      [shuttleState]
    );

    return (
      <FormRow mode="one-col">
        <FormRow mode="two-col" style={{ margin: '30px 0 -15px 0' }}>
          <h6> {rest.headline1} </h6>
          <h6 style={{ textAlign: 'right' }}> {rest.headline2} </h6>
        </FormRow>
        <div
          className={classNames('shuttle__container', className)}
          role="listbox"
          {...rest}
          data-name={rest.name}
          ref={ref}
          tabIndex={0}
          style={{
            borderColor: rest.error ? '#D40511' : ''
          }}
        >
          {children(shuttleState, getItemProps)}
        </div>
      </FormRow>
    );
  })
);

ShuttleContainer.displayName = 'Shuttle.Container';
