import React, { ReactNode, VFC } from 'react';

export type ProductInfoTableRowProps = {
  value?: ReactNode | ReactNode[];
  label: string;
  dataTestId?: string;
};

export const ProductInfoTableRow: VFC<ProductInfoTableRowProps> = ({ value, label, dataTestId }) => {
  return (
    <>
      {!value ? (
        <></>
      ) : (
        <tr>
          <td>{label}</td>
          <td data-testid={dataTestId}>{value}</td>
        </tr>
      )}
    </>
  );
};
