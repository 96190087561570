import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment/moment';
import { styles } from './EinlieferungslisteStyles';
import { GetPressDistributionOrderRep, JournalRep, OrderCustomerRoleEnum, OrderFeeRep, ProductionFacilityTO } from 'generated';
import { PostLogo } from '../pdf/PostLogo';
import { toFormattedDate } from 'common/formatting';
import { getOrderIdBarcodeImageUrl } from './EinlieferungslisteBarcodes';
import { getDeliveryPoint, getPickupDepot } from '../utils/productionFacilityUtils';
import { now } from 'common/utils/timeAndDate';
import { generateAndDownloadPdf } from '../utils/pdfUtils';
import { useAuthContext } from 'common/context/auth/AuthContext';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { BaseProductRegion } from 'order/common/dtos/BaseProductRegion';
import { PressDistributionBaseProduct } from 'order/common/dtos/PressDistributionBaseProduct';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadEinlieferungslistePdfPress = async (
  printVariantenliste: boolean,
  order: { productGroup: ProductGroup.PRESS_DISTRIBUTION } & GetPressDistributionOrderRep,
  pressBaseProducts: PressDistributionBaseProduct[],
  dependentProductDescs: string[],
  validProductionFacilities: ProductionFacilityTO[],
  version: string | undefined,
  journalInfo: JournalRep,

  finished: () => void
): Promise<void> => {
  try {
    const doc = (
      <EinlieferungslistePress
        printVariantenliste={printVariantenliste}
        order={order}
        pressBaseProducts={pressBaseProducts}
        dependentProductDescs={dependentProductDescs}
        validProductionFacilities={validProductionFacilities}
        version={version}
        journalData={journalInfo}
      />
    );
    await generateAndDownloadPdf(doc, order, 'Einlieferungsliste');
  } catch (e) {
    console.error(`${now()} - error printing Einlieferungsliste: ${e}`);
  } finally {
    finished();
  }
};

interface EinlieferungslistePressProps {
  printVariantenliste: boolean;
  order: { productGroup: ProductGroup.PRESS_DISTRIBUTION } & GetPressDistributionOrderRep;
  pressBaseProducts: PressDistributionBaseProduct[];
  dependentProductDescs: string[];
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
  journalData?: JournalRep;
}

export const EinlieferungslistePress = ({
  order,
  journalData,
  validProductionFacilities,
  version,
  pressBaseProducts
}: EinlieferungslistePressProps): JSX.Element => {
  const originator = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Originator);
  const submitter = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Submitter);
  const payer = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Payer);
  const deliveryPoint = order?.orderDetail?.pickup ? undefined : getDeliveryPoint(order?.orderDetail?.productionPlantId, validProductionFacilities);
  const pickupDepot = order?.orderDetail?.pickup ? getPickupDepot(order, validProductionFacilities) : undefined;
  // // billing
  // const getFeeByType = (type: string): OrderFeeRep | undefined => order?.orderPrice?.orderFees?.find((sc) => sc.type === type);
  // const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;
  // const sumAuftragswert = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE') + getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  // const sumEntgeltermaessigung = getFeeValueByType('OM_PORTAL_REDUCTION');
  // const sumGesamtOhneUSt = sumAuftragswert + sumEntgeltermaessigung;
  // const sumGesamtMitUSt = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');
  // const sumUSt = sumGesamtMitUSt - sumGesamtOhneUSt;
  // const sumBereitsGezahlt = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  // const sumRestforderung = sumGesamtMitUSt - sumBereitsGezahlt;
  // const currency = order.orderPrice?.orderFees?.[0]?.amount?.currency;

  // billing information calculations
  const getFeeByType = (type: string): OrderFeeRep | undefined => order?.orderPrice?.orderFees?.find((sc) => sc.type === type);
  const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;
  // Order value -- Auftragswert
  const sumAuftragswert = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE') + getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  // Fee reduction -- EntgeltermßUigung
  const sumEntgeltermaessigung = getFeeValueByType('OM_PORTAL_REDUCTION');
  // Already paid amount -- Bereits abgerechnet
  const sumBereitsGezahlt = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  // Total Sum or remaining amount -- Restforderung inkl. USt.
  const sumRestforderung = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');
  // Calculations are based on netting table -- VAT or Ust.
  const shippingChargeWithoutVAT = getFeeByType('OM_PORTAL_POSITION_RELATED_CHARGE')?.amount?.value || 0;
  const additionalChargeWithoutVAT = getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  const summeEntgelte = shippingChargeWithoutVAT + additionalChargeWithoutVAT + sumEntgeltermaessigung;
  const sumUSt = sumRestforderung - (summeEntgelte + sumBereitsGezahlt);
  // Currency
  const currency = order?.orderPrice?.orderFees?.[0]?.amount?.currency;
  const language = useAuthContext().user.language;

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '6mm', marginBottom: '6mm' }, styles.lineContainerStreched]}>
            <Text style={styles.pageHeading}>Einlieferungsliste Pressedistribution</Text>
            <Text>{`Auftragsnummer: ${order.orderId}`}</Text>
          </View>
          <View style={styles.lineContainerStreched}>
            <View>
              <View style={[{ maxWidth: '60mm' }, styles.lineContainer]}>
                <Text style={{ minWidth: '60mm' }}>{`ZKZ und Zeitungstitel:`}</Text>
                <Text>
                  {order.journal?.zkz} - {journalData?.title}
                </Text>
              </View>
              <View style={[{ marginTop: '6mm', marginBottom: '6mm', maxWidth: '60mm' }, styles.lineContainer]}>
                <Text style={{ minWidth: '60mm' }}>{`Zeitungsnummer:`}</Text>
                <Text>{order.journal?.journalNumber}</Text>
              </View>
            </View>
            {(() => {
              const barcodeUrl = getOrderIdBarcodeImageUrl(order.orderId || '');
              return barcodeUrl ? <Image style={{ minWidth: '40mm', height: '10mm' }} src={barcodeUrl} /> : <></>;
            })()}
          </View>
          {/* Kundendaten */}
          <View style={styles.lineContainer}>
            <Text style={styles.textHeader}>Kundendaten</Text>
          </View>
          <View style={styles.groupContainer}>
            <View style={styles.lineContainer}>
              <View style={[{ width: '100%' }, styles.linesContainer]}>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Absender:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${originator?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{originator?.name}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Einlieferer:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${submitter?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{submitter?.name}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Zahlungspflichtiger:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${payer?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{payer?.name}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Einlieferungsinformationen */}
          <View style={styles.lineContainer}>
            <Text style={styles.textHeader}>{order?.orderDetail?.pickup === true ? 'Abholinformationen' : 'Einlieferungsinformationen'}</Text>
          </View>
          <View style={styles.groupContainer}>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order?.orderDetail?.pickup === true ? 'Abholdatum:' : 'Einlieferungsdatum:'}</Text>
              <Text style={{ width: '100%' }}>{toFormattedDate(order.orderDetail?.date)}</Text>
            </View>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order.orderDetail?.pickup ? 'Disponierendes Depot:' : 'Einlieferungsstelle:'}</Text>
              <Text style={{ width: '100%' }}>
                {order.orderDetail?.pickup
                  ? `${pickupDepot?.catalogId || ''} ${pickupDepot?.comment || ''}`
                  : `${deliveryPoint?.address?.postCode || ''} ${deliveryPoint?.comment || ''}`}
              </Text>
            </View>
          </View>
          {/* Produktdaten for national products */}
          <View style={[styles.lineContainer, { marginTop: '10mm' }]}>
            <Text style={styles.textHeader}>Produktdaten</Text>
          </View>
          <View style={[styles.groupContainer, { marginBottom: '10mm' }]}>
            <View style={styles.gridContainer}>
              <View style={styles.gridRowContainer}>
                <Text style={[styles.gridRowItem, { paddingLeft: '0' }]}>Nationales Basisprodukt</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Sendungsmenge</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Einzelgewicht in g</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Gesamtgewicht (kg)</Text>
              </View>
              {order.nationalProducts?.map((np, i) => {
                const description = pressBaseProducts?.filter(
                  (p) => p.productNumber === np.productNumber && p.region === BaseProductRegion.NATIONAL
                )[0]?.descriptionShort;
                return (
                  <View key={i} style={styles.gridRowContainer}>
                    <Text style={styles.gridRowItem}>{description}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{np.quantity?.toLocaleString(language)}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{np.weight?.toLocaleString(language)}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>
                      {np?.weight && np?.quantity ? ((np.weight * np.quantity) / 1000)?.toLocaleString(language) : ''}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
          {/* Produktdaten for international products */}
          <View style={[styles.groupContainer, { marginBottom: '10mm' }]}>
            <View style={styles.gridContainer}>
              <View style={styles.gridRowContainer}>
                <Text style={[styles.gridRowItem, { paddingLeft: '0' }]}>Internationales Basisprodukt</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Sendungsmenge</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Einzelgewicht in g</Text>
                <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>Gesamtgewicht (kg)</Text>
              </View>
              {order.internationalProducts?.map((ip, i) => {
                const description = pressBaseProducts?.filter(
                  (p) => p.productNumber === ip.productNumber && p.region === BaseProductRegion.INTERNATIONAL
                )[0]?.descriptionShort;
                return (
                  <View key={i} style={styles.gridRowContainer}>
                    <Text style={styles.gridRowItem}>{description}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{ip.quantity?.toLocaleString(language)}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>{ip.weight?.toLocaleString(language)}</Text>
                    <Text style={[styles.gridRowItem, styles.gridRowItemRight]}>
                      {ip?.weight && ip?.quantity ? ((ip.weight * ip.quantity) / 1000).toLocaleString(language) : ''}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
          {/* Sendungsmenge und –gewicht */}
          {/* Frankierung und Abrechnungsdaten */}
          <View style={styles.lineContainerStreched}>
            <View style={{ width: '45%' }}>
              <View style={styles.lineContainer}>
                <Text style={styles.textHeader}>Abrechnungsdaten</Text>
              </View>
              <View style={styles.groupContainer}>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Auftragswert:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumAuftragswert && currency
                      ? sumAuftragswert.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Entgeltermäßigung:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumEntgeltermaessigung && currency
                      ? sumEntgeltermaessigung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Bereits abgerechnet:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumBereitsGezahlt && currency
                      ? sumBereitsGezahlt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumUSt && currency
                      ? sumUSt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={[styles.lineContainer, styles.borderTop]}>
                  <Text style={{ minWidth: '40mm', fontWeight: 'bold' }}>Restforderung inkl. USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right', fontWeight: 'bold' }}>
                    {sumRestforderung && currency
                      ? sumRestforderung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.portal ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};
