import classNames from 'classnames';
import { OrderPriceRep, ServiceClusterRep } from 'generated';
import { PSLCorrectionBillingTableHeadRow } from './PSLCorrectionBillingTableHeadRow';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import tableStyles from 'order/orderSearch/components/table/AMTable.module.css';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import styles from '../PSLBillingTable/pslbilling-table.module.css';
import { PSLCorrectionBillingTableSumRow } from './PSLCorrectionBillingTableSumRow';
import { PSLCorrectionBillingTableRow } from './PSLCorrectionBillingTableRow';

type PSLCorrectionBillingTableProps = {
  orderPrice?: OrderPriceRep;
};

export const PSLCorrectionBillingTable: VFC<PSLCorrectionBillingTableProps> = ({ orderPrice }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });

  return (
    <DHLFormGroup className={styles.correctionTable}>
      <h6 data-testid="psl-correction-table-header" style={{ textAlign: 'center' }}>
        {t('correctionSettlement')}
      </h6>
      <table className={'dhlTable table ' + tableStyles.amTable}>
        <thead className={classNames('dhlTable-sort-header ' + tableStyles.headcellalign)}>
          <PSLCorrectionBillingTableHeadRow />
        </thead>
        <tbody>
          {orderPrice?.allocationDocumentsServiceClusters?.map((item: ServiceClusterRep, index: number) => {
            return <PSLCorrectionBillingTableRow key={'correction-row-data-' + index} index={index} item={item} />;
          })}
          <PSLCorrectionBillingTableSumRow orderFee={orderPrice?.allocationDocumentsOrderFees} />
        </tbody>
      </table>
    </DHLFormGroup>
  );
};
