import React, { useEffect, useState } from 'react';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { FormRow } from 'common/components/FormRow';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { useTranslation } from 'react-i18next';
import { internationalProducts } from 'order/common/services/OrderService';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { InternationalBaseProduct } from '../../dtos/InternationalBaseProducts';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './InternationalBaseProducts.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { InternationalClusterOrderCreate } from '../../schema/internationalClusterSchema';

export interface InternationalBaseProductsProps {
  disabled?: boolean;
  name?: string;
  productGroup?: ProductGroup;
  internationalBaseProduct?: InternationalBaseProduct;
}

export const InternationalBaseProducts = (props: InternationalBaseProductsProps) => {
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { meta, upsertMetaData, order, orderMode } = useOrderContext();
  const { setValue, control, resetField } = useFormContext<InternationalClusterOrderCreate>();

  const [internationalBaseProduct] = useWatch({
    control,
    name: ['internationalBaseProduct']
  });

  // Fetching International BaseProducts
  const fetchInternationalBaseProducts = async () => {
    if (!props?.productGroup) {
      return;
    }
    if (!props?.internationalBaseProduct?.productNumber) {
      const products: InternationalBaseProduct[] = (await internationalProducts(props?.productGroup)).data;
      if (products) {
        upsertMetaData('fetchedIBP', products);
        if (orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY) {
          setValue('internationalBaseProduct', products.find((p) => p.productNumber === order?.internationalBaseProduct) || products[0]);
        } else {
          setValue('internationalBaseProduct', products[0]);
        }
      }
    }
  };

  useEffect(() => {
    fetchInternationalBaseProducts();
  }, [props?.productGroup]);

  const handleChange = (option?: InternationalBaseProduct) => {
    if (option) {
      setValue('internationalBaseProduct', option);
      resetField('transportServices', { defaultValue: [{ baseProduct: {} }] });
      upsertMetaData('selectedIBP', option);
    }
  };

  return (
    <>
      <OrderStepSection headline={t('product')}>
        <FormRow mode="two-col">
          <div className={styles.internationalBaseProductContainer}>
            <AMDHLSelectSingle
              name="internationalBaseProduct"
              data-testid={'internationalBaseProduct'}
              disabled={props?.disabled || meta?.fetchedIBP?.length === 1}
              items={meta?.fetchedIBP || []}
              placeholder={`${t('basicProduct')}`}
              valueToString={(option) => option?.descriptionShort || ''}
              value={internationalBaseProduct}
              onChange={handleChange}
            />
          </div>
        </FormRow>
      </OrderStepSection>
    </>
  );
};
