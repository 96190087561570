export enum OrderCategoryProductKey {
  DiP_EINZEL__DIALOGPOST = 'DiP_EINZEL-DIALOGPOST',
  DiP_EINZEL__DIALOGPOSTWURFSPEZIAL = 'DiP_EINZEL-DIALOGPOSTWURFSPEZIAL',
  DiP_VARIO = 'DiP_VARIO-DIALOGPOST',
  DiP_SAMMEL = 'DiP_SAMMEL-DIALOGPOST',
  DV_BRIEF = 'DV_BRIEF-BRIEF',
  AFM_BRIEF = 'AFM_BRIEF-BRIEF',
  POSTVERTRIEBSSTUECK = 'POSTVERTRIEBSSTUECK-PRESS_DISTRIBUTION',
  POSTAKTUELL = 'PA-POSTAKTUELL',
  DHL_INFOPOST = 'DHL_INFOPOST-INFOPOST_DHL',
  IP_INTERNATIONAL = 'IP_INTERNATIONAL-DIALOGPOST',
  TL_BRIEF = 'TL_BRIEF-BRIEF',
  ABHOLAUFTRAG = 'ABHOLAUFTRAG-COLLECTION',
  PRESSDISTRIBUTION = 'PRESSEDISTRIBUTION-PRESS_DISTRIBUTION',
  DIALOGPOST_INTERNATIONAL = 'IP_INTERNATIONAL-BRIEF',
  BRIEF_INTERNATIONAL = 'BRIEF_INTERNATIONAL-BRIEF',
  PRESS_INTERNATIONAL = 'PUB_INTERNATIONAL-BRIEF',
  BRIEF50_INTERNATIONAL = 'BRIEF_DMC_INTERNATIONAL-BRIEF'
}
