import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { ModalData } from 'common/dtos/ModalData';
import { Button } from 'common/components/Button';
import { publishToTopic, subscribeToTopic, unsubscribeFromTopic } from 'common/dtos/PubSubEvents';

import styles from './modal.module.css';

export const Modal = (props: ModalData): JSX.Element => {
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    const tokenClose = subscribeToTopic('am.modal.trigger.close', triggerCloseModal);

    return () => {
      unsubscribeFromTopic(tokenClose);
    };
  }, []);

  const triggerCloseModal = () => {
    setClosing(true);
    setTimeout(() => {
      publishToTopic('am.modal.close', null);
    }, 250);
  };

  const handleOnCancel = () => {
    triggerCloseModal();
    props.cancelConfig?.onCancel?.();
  };

  const handleOnSubmit = () => {
    props.submitConfig?.onSubmit?.();
  };

  const containerClass = props?.containerClassNames;

  return (
    <>
      <div className={cn(styles.backdrop, { [styles.closing]: closing })} onClick={handleOnCancel} aria-hidden="true" data-testid="modal-backdrop" />
      <div
        className={cn(
          styles.container,
          { [styles.closing]: closing },
          containerClass === 'pslIdAssignmentModalContainer' ? styles.pslIdAssignmentModalContainer : props?.contentClassNames
        )}
        data-testid="modal-container"
      >
        <div className={styles.headlineWrapper} data-testid="modal-headline-wrapper">
          <div className={styles.headline} data-testid="modal-headline">
            {props.headline}
          </div>
          <div className={styles.close} onClick={handleOnCancel} aria-hidden="true" data-testid="modal-close">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon
                fill="#878787"
                fillRule="evenodd"
                points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
              />
            </svg>
          </div>
        </div>
        <div
          className={cn(
            styles.content,
            props?.contentClassNames === 'pslIdAssignmentModalContainer' ? styles.pslIdAssignmentModalContainer : props?.contentClassNames
          )}
          data-testid="modal-content"
        >
          {props.content}
        </div>
        {props.cancelConfig || props.submitConfig ? (
          <div className={styles.footer} data-testid="modal-footer">
            {props.cancelConfig && (
              <div className={styles.cancel}>
                {props.cancelConfig.button || <Button dataTestId={'modal_cancel_btn'} label={props.cancelConfig.title} onClick={handleOnCancel} />}
              </div>
            )}
            {props.submitConfig && (
              <div className={styles.submit}>
                {props.submitConfig.button || (
                  <Button
                    variant={props.activeSpinner ? 'default' : 'primary'}
                    label={props.submitConfig.title}
                    dataTestId={'modal_submit_btn'}
                    onClick={handleOnSubmit}
                    disabled={props.activeSpinner}
                    activeSpinner={props.activeSpinner}
                    style={{ minWidth: '115px' }}
                  />
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};
