import { useState } from 'react';

import { UserRights } from 'common/dtos/userRights';
import { OrderSearchType } from '../dtos/OrderSearchType';

export const orderSearchTypes = [OrderSearchType.SIMPLE, OrderSearchType.PRESS, OrderSearchType.ADVANCED];
const orderSearchTabs = [...orderSearchTypes, 'history', 'myOrders'];
export type OrderSearchTab = typeof orderSearchTabs[number];

interface UseSearchTabsReturn {
  searchTabs: OrderSearchTab[];
  getTab: (value: number | OrderSearchType) => OrderSearchTab;
  initSearchTabs: (permissions: UserRights[]) => void;
  onTabChange: (value: number | OrderSearchType) => void;
  tab: OrderSearchTab;
  tabIndex: number;
}

export const useSearchTabs = (): UseSearchTabsReturn => {
  const [searchTabs, setSearchTabs] = useState<OrderSearchTab[]>([]);
  const [tab, setTab] = useState<OrderSearchTab>(OrderSearchType.SIMPLE);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const getTabIndex = (tab: OrderSearchTab): number => {
    return searchTabs.indexOf(tab);
  };
  const getTabByIndex = (index: number): OrderSearchTab => searchTabs[index];

  const initSearchTabs = (permissions: UserRights[]) => {
    if (!permissions.includes(UserRights.ProductPrD)) {
      const reducedSearchTypes = orderSearchTabs.filter((s) => s !== OrderSearchType.PRESS);
      setSearchTabs(reducedSearchTypes);
    } else {
      setSearchTabs(orderSearchTabs);
    }
  };

  const onTabChange = (value: number | OrderSearchType) => {
    if (typeof value === 'number') {
      // only change tab if value is valid
      if (getTabByIndex(value)) {
        setTab(getTabByIndex(value));
        setTabIndex(value);
      }
    } else {
      // only change tab if value is valid
      if (getTabIndex(value) >= 0) {
        setTab(value);
        setTabIndex(getTabIndex(value));
      }
    }
  };

  const getTab = (value: number | OrderSearchType): OrderSearchTab => {
    if (typeof value === 'number') {
      return getTabByIndex(value);
    }

    return value;
  };

  return { searchTabs, getTab, initSearchTabs, onTabChange, tab, tabIndex };
};
