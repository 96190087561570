import { useBoolean, UseBooleanReturn } from 'common/hooks/useBoolean';
import { concatNonUndefined, scrollIntoView } from 'common/utils/helpers';
import { useClickOutside, useKeyPress, useRefEventListener } from 'common/utils/hooks';
import { AllHTMLAttributes, ButtonHTMLAttributes, ChangeEventHandler, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type GetInputProps = () => Pick<
  AllHTMLAttributes<HTMLElement>,
  'value' | 'onChange' | 'onClick' | 'aria-expanded' | 'aria-haspopup' | 'disabled' | 'children' | 'type'
> & {
  ref: RefObject<HTMLInputElement>;
  'data-testid'?: string;
};

export type GetOptionProps<T> = (item: T) => Pick<
  ButtonHTMLAttributes<HTMLElement>,
  'role' | 'aria-selected' | 'onClick' | 'tabIndex' | 'type' | 'id' | 'disabled' | 'children'
> & {
  'data-testid'?: string;
};

export type GetListProps = () => Pick<AllHTMLAttributes<HTMLElement>, 'role' | 'aria-activedescendant' | 'tabIndex'> & {
  ref: RefObject<HTMLDivElement>;
  'data-testid'?: string;
};

export type GetListContainerProps = () => {
  ref: RefObject<HTMLDivElement>;
  'data-testid'?: string;
};

export type GetContextInputProps = () => {
  ref: RefObject<HTMLInputElement>;
  'data-testid'?: string;
};

export type UseSelectReturn<T> = {
  items: T[];
  isOpen: boolean;
  setIsOpen: UseBooleanReturn[1];
  selectedIndex?: number;
  activeIndex?: number;
  getInputProps: GetInputProps;
  getOptionProps: GetOptionProps<T>;
  getListProps: GetListProps;
  getListContainerProps: GetListContainerProps;
  getContextInputProps: GetContextInputProps;
  searchQuery?: string;
  inputValue: string;
};

export type UseSelectParams<T> = {
  items: T[];
  valueToString?: (value: T) => string;
  value?: T;
  onChange?: (value?: T) => void;
  itemDisabled?: (value: T) => boolean;
  name?: string;
  disabled?: boolean;
  mode?: 'select' | 'autocomplete';
  'data-testid'?: string;
  emptyValue?: T;
};

export const useSelect = <T>({
  items,
  valueToString = String,
  value: selectedItem,
  onChange,
  itemDisabled,
  name,
  disabled,
  mode = 'select',
  'data-testid': dataTestId,
  emptyValue
}: UseSelectParams<T>): UseSelectReturn<T> => {
  const buttonRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contextInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useBoolean(false);
  const [inputValue, setInputValue] = useState<string>(selectedItem ? valueToString(selectedItem) : '');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeItem, setActiveItem] = useState<T | undefined>(selectedItem || emptyValue);
  const doesItemFitQuery = useCallback(
    (item: T, value: string, startsWith = false) => {
      const itemString = valueToString(item).toLowerCase();
      const lowerCaseValue = value.toLowerCase();
      return startsWith ? itemString.startsWith(lowerCaseValue) : itemString.includes(lowerCaseValue);
    },
    [valueToString]
  );

  const itemEnabled = useCallback((item: T) => !itemDisabled?.(item), [itemDisabled]);

  const filteredItems = useMemo(() => {
    return mode !== 'autocomplete' || !searchQuery ? items : items.filter((item) => doesItemFitQuery(item, searchQuery));
  }, [doesItemFitQuery, items, mode, searchQuery]);

  const firstEnabledItem = filteredItems.find(itemEnabled);

  const encodeOption = useCallback((item: T) => filteredItems.indexOf(item).toString(), [filteredItems]);

  const createOptionId = useCallback((item: T) => `${name ? `${name}-` : ''}option-${encodeOption(item)}`, [encodeOption, name]);

  const scrollToOption = useCallback(
    (item: T) => {
      const optionElement = document.getElementById(createOptionId(item));
      optionElement && scrollIntoView(optionElement);
    },
    [createOptionId]
  );

  const onShowContext = (resetQuery = true) => {
    setIsOpen.on();
    listRef.current?.focus();
    const newActive = selectedItem || firstEnabledItem;
    setActiveItem(newActive);
    resetQuery && setSearchQuery('');
    newActive && scrollToOption(newActive);
  };

  useEffect(() => {
    disabled && setIsOpen.off();
  }, [disabled, setIsOpen]);

  useEffect(() => {
    setInputValue(selectedItem ? valueToString(selectedItem) : '');
  }, [selectedItem, valueToString]);

  useEffect(() => {
    isOpen && setActiveItem(selectedItem || emptyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useClickOutside([buttonRef, listRef, containerRef], () => {
    selectedItem && setInputValue(valueToString(selectedItem));
    setIsOpen.off();
  });

  useKeyPress([buttonRef, listRef], ['Escape'], () => {
    if (!inputValue && selectedItem) {
      onChange?.(selectedItem);
    } else {
      setActiveItem(selectedItem);
    }
    setIsOpen.off();
  });

  const onSelect = (item?: T) => {
    onChange?.(item);
    setInputValue(item ? valueToString(item) : '');
    setIsOpen.off();
  };

  useKeyPress([buttonRef], ['ArrowUp', 'ArrowDown'], (e) => {
    if (mode === 'autocomplete') {
      onShowContext(false);
    } else {
      loopTroughItems(e.key === 'ArrowUp');
    }
    e.preventDefault();
  });

  useKeyPress([buttonRef], ['Enter'], () => {
    if (isOpen) {
      setSearchQuery(inputValue);
      filteredItems.indexOf(activeItem as typeof filteredItems[number]) !== -1 ? onSelect(activeItem) : onSelect(filteredItems[0]);
      // onSelect(activeItem || firstEnabledItem);
    } else {
      onShowContext();
    }
  });

  useKeyPress(
    [buttonRef],
    ['Space'],
    () => {
      setIsOpen.toggle();
      listRef.current?.focus();
    },
    mode === 'select'
  );

  useKeyPress([listRef], ['ArrowUp', 'ArrowDown'], (e) => {
    loopTroughItems(e.key === 'ArrowUp');
    e.preventDefault();
  });

  useKeyPress([contextInputRef], ['ArrowUp', 'ArrowDown'], (e) => {
    listRef.current?.focus();
    loopTroughItems(e.key === 'ArrowUp');
    e.preventDefault();
  });

  useKeyPress([listRef], ['Enter', 'Space'], (e) => {
    filteredItems.indexOf(activeItem as typeof filteredItems[number]) !== -1 ? onSelect(activeItem) : onSelect(firstEnabledItem);
    // onSelect(activeItem);
    buttonRef.current?.focus();
    e.preventDefault();
  });

  const findNextItem = useCallback(
    (item: T, asc: boolean, predicate: (item: T) => boolean): T => {
      const increment = asc ? -1 : 1;
      const searchableItems = isOpen ? filteredItems : items;
      const currentIndex = searchableItems.indexOf(item);
      const itemAmount = searchableItems.length;
      for (let i = 1; i < itemAmount; i++) {
        const changedIndex = (itemAmount + currentIndex + i * increment) % itemAmount;
        const nextItem = searchableItems[changedIndex];
        if (predicate(nextItem)) {
          return nextItem;
        }
      }
      return item;
    },
    [filteredItems, isOpen, items]
  );

  const loopTroughItems = useCallback(
    (asc: boolean) => {
      let startItem = isOpen ? activeItem : selectedItem;
      if (typeof startItem === 'undefined') {
        startItem = firstEnabledItem;
      }
      if (typeof startItem === 'undefined') {
        return;
      }
      const endItem = findNextItem(startItem, asc, itemEnabled);

      (isOpen ? setActiveItem : onChange)?.(endItem);

      if (typeof endItem !== 'undefined') {
        scrollToOption(endItem);
      }
    },
    [isOpen, activeItem, selectedItem, firstEnabledItem, findNextItem, itemEnabled, onChange, scrollToOption]
  );

  const getNextItemByText = useCallback(
    (query: string) => {
      const currentItem = isOpen && typeof activeItem !== 'undefined' ? activeItem : selectedItem || firstEnabledItem;
      return currentItem && findNextItem(currentItem, false, (i) => doesItemFitQuery(i, query, true) && itemEnabled(i));
    },
    [activeItem, doesItemFitQuery, findNextItem, firstEnabledItem, isOpen, itemEnabled, selectedItem]
  );

  // loop trough items starting with input
  useRefEventListener(
    'keypress',
    [buttonRef],
    (e) => {
      const value = e.key;
      if (value.length === 1) {
        const nextActive = getNextItemByText(value);
        const action = isOpen ? setActiveItem : onChange;
        action?.(nextActive);
      }
    },
    {},
    mode === 'select'
  );

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (mode === 'select') {
      return;
    }
    const textValue = e.currentTarget.value;
    setInputValue(textValue);
    setSearchQuery(textValue);
    typeof activeItem === 'undefined' && setActiveItem(filteredItems[0]);
    setIsOpen.on();
  };

  const getInputProps: GetInputProps = () => ({
    ref: buttonRef,
    onChange: onChangeInput,
    onClick: () => {
      selectedItem && typeof inputValue === 'undefined' && setInputValue(valueToString(selectedItem));
      setIsOpen.on();
    },
    'aria-expanded': isOpen,
    'aria-haspopup': filteredItems.length ? 'listbox' : undefined,
    disabled,
    type: mode === 'select' ? 'button' : 'text',
    'data-testid': dataTestId
  });

  const getOptionProps: GetOptionProps<T> = (item) => ({
    role: 'option',
    'aria-selected': selectedItem === item,
    onClick: () => onSelect(item),
    tabIndex: isOpen ? 0 : -1,
    type: 'button',
    id: createOptionId(item),
    disabled: itemDisabled?.(item),
    children: valueToString(item),
    'data-testid': concatNonUndefined(dataTestId, '-option-', encodeOption(item))
  });

  const getListProps: GetListProps = () => ({
    role: 'listbox',
    tabIndex: -1,
    'aria-activedescendant': activeItem && createOptionId(activeItem),
    ref: listRef,
    'data-testid': concatNonUndefined(dataTestId, '-list')
  });

  const getListContainerProps: GetListContainerProps = () => ({
    ref: containerRef,
    'data-testid': concatNonUndefined(dataTestId, '-listbox')
  });

  const getContextInputProps: GetContextInputProps = () => ({
    ref: contextInputRef,
    'data-testid': concatNonUndefined(dataTestId, '-input')
  });

  return {
    items: filteredItems,
    isOpen,
    setIsOpen,
    getInputProps,
    getOptionProps,
    getListProps,
    getListContainerProps,
    getContextInputProps,
    searchQuery,
    selectedIndex: (<(T | undefined)[]>filteredItems).indexOf(selectedItem),
    activeIndex: (<(T | undefined)[]>filteredItems).indexOf(activeItem),
    inputValue
  };
};
