/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CSVMatrixElement } from 'common/csv';
import { OrderCustomerRoleEnum } from 'generated';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { maxiBriefDiscountAvailable } from 'order/productGroups/additionalOrders/utils/maxiBriefDeliveryDiscountDiscontinuedDate';
import { LetterTypes } from '../../../common/components/QtyAllocation/dtos/LetterTypes';
import { determineLetterTypeByFormat } from '../../../common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import { isAvailableFromJan25 } from '../../../common/utils/availabilityByDate';

const mappingKeysArray = [
  'orderNumber',
  'customerOrderId',
  'orderLabel',
  'originatorId',
  'originatorName',
  'payerId',
  'payerName',
  'procedure',
  'participation',
  'deliveryReceiptNumber',
  'category',
  'standard',
  'compact',
  'big',
  'maxi',
  'postcard',
  'standardTL',
  'compactTL',
  'bigTL',
  'maxiTL',
  'postcardTL',
  'standardId',
  'compactId',
  'bigId',
  'maxiId',
  'postcardId',
  'standardFlex',
  'compactFlex',
  'bigFlex',
  'maxiFlex',
  'postcardFlex'
] as const;

type OrderMapping<T = string> = Record<typeof mappingKeysArray[number], T>;

const revertMapping = <T extends CSVMatrixElement>(mappedValues: OrderMapping<T>): CSVMatrixElement[] =>
  mappingKeysArray.map((key) => mappedValues[key]);

export const orderHeadings = revertMapping({
  orderNumber: 'Auftragsnummer',
  customerOrderId: 'Kundenauftragsnummer',
  originatorId: 'Absender Kundennummer',
  orderLabel: 'Auftragsbezeichnung',
  originatorName: 'Absender Name',
  payerId: 'Zahlungspflichtiger Kundennummer',
  payerName: 'Zahlungspflichtiger Name',
  procedure: 'Verfahren',
  participation: 'Teilnahme',
  deliveryReceiptNumber: 'Einlieferungsbelegnummer',
  category: 'Auftragskategorie',
  standard: 'Standard',
  compact: 'Kompakt',
  big: 'Groß',
  maxi: 'Maxi',
  postcard: 'Postkarte',
  standardTL: 'Standard (TL relevant)',
  compactTL: 'Kompakt (TL relevant)',
  bigTL: 'Groß (TL relevant)',
  maxiTL: 'Maxi (TL relevant)',
  postcardTL: 'Postkarte (TL relevant)',
  standardId: 'Standard (ID-Rabatt)',
  compactId: 'Kompakt (ID-Rabatt)',
  bigId: 'Groß (ID-Rabatt)',
  maxiId: 'Maxi (ID-Rabatt)',
  postcardId: 'Postkarte (ID-Rabatt)',
  standardFlex: 'Standard (Laufzeit-Rabatt)',
  compactFlex: 'Kompakt (Laufzeit-Rabatt)',
  bigFlex: 'Groß (Laufzeit-Rabatt)',
  maxiFlex: 'Maxi (Laufzeit-Rabatt)',
  postcardFlex: 'Postkarte (Laufzeit-Rabatt)'
});

export const orderHeadingsUpdated = revertMapping({
  orderNumber: 'Auftragsnummer',
  customerOrderId: 'Kundenauftragsnummer',
  originatorId: 'Absender Kundennummer',
  orderLabel: 'Auftragsbezeichnung',
  originatorName: 'Absender Name',
  payerId: 'Zahlungspflichtiger Kundennummer',
  payerName: 'Zahlungspflichtiger Name',
  procedure: 'Verfahren',
  participation: 'Teilnahme',
  deliveryReceiptNumber: 'Einlieferungsbelegnummer',
  category: 'Auftragskategorie',
  standard: 'Standard',
  compact: 'Kompakt',
  big: 'Groß',
  maxi: 'Maxi',
  postcard: 'Postkarte',
  standardTL: 'Standard (Basis)',
  compactTL: 'Kompakt (Basis)',
  bigTL: 'Groß (Basis)',
  maxiTL: 'Maxi (Basis)',
  postcardTL: 'Postkarte (Basis)',
  standardId: 'Standard (ID)',
  compactId: 'Kompakt (ID)',
  bigId: 'Groß (ID)',
  maxiId: 'Maxi (ID)',
  postcardId: 'Postkarte (ID)',
  standardFlex: 'Standard (E+1)',
  compactFlex: 'Kompakt (E+1)',
  bigFlex: 'Groß (E+1)',
  maxiFlex: 'Maxi (E+1)',
  postcardFlex: 'Postkarte (E+1)'
});

export const allocatedOrderToCsvRows = (allocatedOrders: AllocatedOrder[]) => allocatedOrders.map(allocatedOrderToCsvRow);

export const orderCsvRowsToMatrix = (allocatedOrders: ReturnType<typeof allocatedOrderToCsvRows>) => allocatedOrders.map(revertMapping);

type Allocation = Partial<{
  format: string;
  containsIds: boolean;
  flexOption: boolean;
  idsQty: number;
  quantity: number;
  initialQty: number;
}>;

const calculateAllocationAmountFromList = (qtyAlloc: Allocation[]) => {
  const calcQty = {
    [LetterTypes.Standard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
    [LetterTypes.Compact]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
    [LetterTypes.Large]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
    [LetterTypes.Maxi]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
    [LetterTypes.Postcard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 }
  };
  for (const q of qtyAlloc) {
    if (determineLetterTypeByFormat(q.format)) {
      if (!q.containsIds && !q.flexOption) {
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount + (q.quantity ?? 0);
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + (q.quantity ?? 0);
      }
      if (q.containsIds && q.flexOption) {
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount + (q.quantity ?? 0);
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + (q.quantity ?? 0);
      }
      if (q.containsIds && !q.flexOption) {
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount + (q.quantity ?? 0);
        calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
          calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + (q.quantity ?? 0);
      }
    }
  }
  return calcQty;
};
export const getAllocationAmountFromList = (
  qtyAllocations: Allocation[],
  format: string,
  type: 'NOTHING' | 'ID' | 'FLEX' | 'TL' = 'NOTHING'
): number => {
  return (qtyAllocations.filter((alloc) => alloc.format === format) as unknown as QtyAllocation[]) // only include correct format
    .map((alloc: QtyAllocation) => {
      if (alloc.multipleContainsIds) {
        switch (type) {
          case 'ID':
            return alloc.collectedQuantities?.filter((a) => a.containsIds).reduce((acc, v) => acc + (v.quantity || 0), 0);
          case 'FLEX':
            return alloc.collectedQuantities?.filter((a) => a.flexOption).reduce((acc, v) => acc + (v.quantity || 0), 0);
          case 'TL':
            return alloc.collectedQuantities?.reduce((acc, v) => acc + (v.quantity || 0), 0);
          case 'NOTHING':
            return alloc.initialQty;
          default:
            return alloc.initialQty;
        }
      } else {
        switch (type) {
          case 'ID':
            return alloc.containsIds ? alloc.idsQty : 0;
          case 'FLEX':
            return alloc.flexOption ? alloc.idsQty : 0;
          case 'TL':
            return alloc.quantity ?? 0;
          case 'NOTHING':
            return alloc.initialQty;
          default:
            return alloc.initialQty;
        }
      }
    })
    .map((n) => n ?? 0)
    .reduce((a, b) => a + b, 0);
};

const allocatedOrderToCsvRow = (allocatedOrder: AllocatedOrder): OrderMapping<string | number | undefined> => {
  const { data, parentOrderDate } = allocatedOrder;
  const originator = data.parties?.find((p) => p.role === OrderCustomerRoleEnum.Originator);

  const payer = data.parties?.find((p) => p.role === OrderCustomerRoleEnum.Payer);

  const qtyAllocations = data.qtyAllocation ?? [];
  const calcQty = calculateAllocationAmountFromList(qtyAllocations);
  const getAllocationAmount = (format: string, type: 'NOTHING' | 'ID' | 'FLEX' | 'TL' = 'NOTHING'): number =>
    getAllocationAmountFromList(qtyAllocations, format, type);
  return isAvailableFromJan25(parentOrderDate)
    ? {
        orderNumber: data.orderNumber,
        customerOrderId: data.customerOrderId,
        orderLabel: data?.orderLabel,
        originatorId: originator?.customerId,
        originatorName: originator?.name,
        payerId: payer?.customerId?.slice(0, 10),
        payerName: payer?.name,
        procedure: data.payerProcedure,
        participation: data.payerParticipation,
        deliveryReceiptNumber: data.paymentClearingNumber,
        category: data.orderCategory,
        standard: calcQty[LetterTypes.Standard].sum + '',
        compact: calcQty[LetterTypes.Compact].sum + '',
        big: calcQty[LetterTypes.Large].sum + '',
        maxi: calcQty[LetterTypes.Maxi].sum + '',
        postcard: calcQty[LetterTypes.Postcard].sum + '',
        standardTL: calcQty[LetterTypes.Standard].partialServiceDiscount,
        compactTL: calcQty[LetterTypes.Compact].partialServiceDiscount,
        bigTL: calcQty[LetterTypes.Large].partialServiceDiscount,
        maxiTL: calcQty[LetterTypes.Maxi].partialServiceDiscount,
        postcardTL: calcQty[LetterTypes.Postcard].partialServiceDiscount,
        standardId: calcQty[LetterTypes.Standard].idDiscount,
        compactId: calcQty[LetterTypes.Compact].idDiscount,
        bigId: calcQty[LetterTypes.Large].idDiscount,
        maxiId: calcQty[LetterTypes.Maxi].idDiscount,
        postcardId: calcQty[LetterTypes.Postcard].idDiscount,
        standardFlex: calcQty[LetterTypes.Standard].termDiscount,
        compactFlex: calcQty[LetterTypes.Compact].termDiscount,
        bigFlex: calcQty[LetterTypes.Large].termDiscount,
        maxiFlex: !maxiBriefDiscountAvailable(allocatedOrder?.parentOrderDate) ? '0' : calcQty[LetterTypes.Maxi].termDiscount,
        postcardFlex: calcQty[LetterTypes.Postcard].termDiscount
      }
    : {
        orderNumber: data.orderNumber,
        customerOrderId: data.customerOrderId,
        orderLabel: data?.orderLabel,
        originatorId: originator?.customerId,
        originatorName: originator?.name,
        payerId: payer?.customerId?.slice(0, 10),
        payerName: payer?.name,
        procedure: data.payerProcedure,
        participation: data.payerParticipation,
        deliveryReceiptNumber: data.paymentClearingNumber,
        category: data.orderCategory,
        standard: getAllocationAmount('S'),
        compact: getAllocationAmount('K'),
        big: getAllocationAmount('G'),
        maxi: getAllocationAmount('M'),
        postcard: getAllocationAmount('P'),
        standardTL: getAllocationAmount('S', 'TL'),
        compactTL: getAllocationAmount('K', 'TL'),
        bigTL: getAllocationAmount('G', 'TL'),
        maxiTL: getAllocationAmount('M', 'TL'),
        postcardTL: getAllocationAmount('P', 'TL'),
        standardId: getAllocationAmount('S', 'ID'),
        compactId: getAllocationAmount('K', 'ID'),
        bigId: getAllocationAmount('G', 'ID'),
        maxiId: getAllocationAmount('M', 'ID'),
        postcardId: getAllocationAmount('P', 'ID'),
        standardFlex: getAllocationAmount('S', 'FLEX'),
        compactFlex: getAllocationAmount('K', 'FLEX'),
        bigFlex: getAllocationAmount('G', 'FLEX'),
        maxiFlex: !maxiBriefDiscountAvailable(allocatedOrder?.parentOrderDate) ? '0' : getAllocationAmount('M', 'FLEX'),
        postcardFlex: getAllocationAmount('P', 'FLEX')
      };
};

type OrderMappingValueTypes = string | number | undefined;

export const addStringNumberUndefined = (a: OrderMappingValueTypes, b: OrderMappingValueTypes) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a + b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a;
  }
  return 0;
};

export const reduceOrderMappings = (
  a?: OrderMapping<OrderMappingValueTypes>,
  b?: OrderMapping<OrderMappingValueTypes>
): OrderMapping<OrderMappingValueTypes> =>
  mappingKeysArray
    // sum up values of objects and associate with field key
    .map((key) => {
      const aValue = a?.[key];
      const bValue = b?.[key];
      return [key, addStringNumberUndefined(aValue, bValue)];
    })
    // reduce key-value-pairs to object
    .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as OrderMapping<OrderMappingValueTypes>);
