import React, { FC, useState, useEffect } from 'react';
import i18n from 'i18n';
import { regexValidator } from 'order/common/components/CustomTextInput';
import { useTranslation } from 'react-i18next';
import { translationEnglish } from './Translations/englishTranslation';
import { translationGerman } from './Translations/germanTranslation';
import { useFormContext } from 'react-hook-form';
import { FormRow } from 'common/components/FormRow';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { RecommendationInput } from 'order/productGroups/common/components/atom/RecommendationInput/RecommendationInput';
import { useGenericRecommendations } from 'common/hooks/useGenericRecommendations';

export interface MachineCodeInputProps {
  required: boolean;
  onChange?: (machineCode: string) => void;
  editing: boolean;
  name: string;
  postageMachineCode?: string;
  error?: string;
  controlledErrorState?: (errorState: boolean) => void;
  disabled?: boolean;
}

i18n.addResourceBundle('de', 'machineCodeInput', translationGerman);
i18n.addResourceBundle('en', 'machineCodeInput', translationEnglish);
const MACHINE_CODE_MAXLENGTH = 10;

export const MachineCodeInput: FC<MachineCodeInputProps> = (props) => {
  const [error, setError] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState<string | undefined>(undefined);
  const { setValue } = useFormContext();
  const { t } = useTranslation('machineCodeInput');
  const keyValue = 'machineID-Franking-Recommendations';
  const frankingIdRegex = /[1-6][D]([0-9A-Fa-f]{8})$/g;
  const { add: addRecommendation } = useGenericRecommendations(keyValue);

  useEffect(() => {
    props.controlledErrorState?.(error);
  }, [error]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length === 0) {
      if (props.required) {
        setError(true);
        setErrorMessageKey('length');
      } else {
        setError(false);
        setValue('postageMachineCode', e.target.value);
      }
    } else if (value.length < MACHINE_CODE_MAXLENGTH) {
      setError(true);
      setErrorMessageKey('length');
    } else if (regexValidator(value, frankingIdRegex) === null) {
      setError(true);
      setErrorMessageKey('format');
    } else {
      setError(false);
      setValue('postageMachineCode', value);
      addRecommendation({
        key: keyValue,
        value: value
      });
    }
    props.onChange?.(value);
  };

  return (
    <>
      <OrderStepSection headline={t('title')}>
        <FormRow mode="two-col">
          <div>
            <RecommendationInput
              required={props.required}
              label={t('label')}
              name={props.name}
              keyValue={keyValue}
              errMsg={t(`error.${errorMessageKey}`)}
              onChangeHandler={onChangeHandler}
              parentChangeHandler={props.onChange}
              defaultValue={props.postageMachineCode}
              error={error}
              setError={setError}
              maxLength={MACHINE_CODE_MAXLENGTH}
              dataTestId={'MachineCodeInput'}
              disabled={props.disabled}
            />
          </div>
        </FormRow>
      </OrderStepSection>
    </>
  );
};
