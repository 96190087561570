import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment/moment';

import { styles } from './EinlieferungslisteStyles';
import { GetInternationalOrderRep, GetOrderRep, OrderCustomerRoleEnum, OrderFeeRep, Product, ProductionFacilityTO, ProductTO } from 'generated';
import { PostLogo } from '../pdf/PostLogo';
import { toFormattedDate } from 'common/formatting';
import { getOrderIdBarcodeImageUrl } from './EinlieferungslisteBarcodes';
import { getDeliveryPoint, getPickupDepot } from '../utils/productionFacilityUtils';
import { now } from 'common/utils/timeAndDate';
import { generateAndDownloadPdf } from '../utils/pdfUtils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
import { InternationalBaseProduct } from 'order/productGroups/international/dtos/InternationalBaseProducts';
import { AdditionalLetterBaseProducts } from 'order/productGroups/international/components/AdditionalLetterService';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadEinlieferungslistePdfInternational = async (
  order: GetInternationalOrderRep,
  validProductionFacilities: ProductionFacilityTO[],
  baseProducts: InternationalBaseProduct[],
  ASL: Product[],
  version: string | undefined,
  finished: () => void
): Promise<void> => {
  try {
    const doc = (
      <EinlieferungslisteInternational
        order={order}
        validProductionFacilities={validProductionFacilities}
        baseProducts={baseProducts}
        ASL={ASL}
        version={version}
      />
    );
    await generateAndDownloadPdf(doc, order as GetOrderRep, 'Einlieferungsliste');
  } catch (e) {
    console.error(`${now()} - error printing Einlieferungsliste: ${e}`);
  } finally {
    finished();
  }
};

interface EinlieferungslisteInternationalProps {
  order: GetInternationalOrderRep;
  validProductionFacilities?: ProductionFacilityTO[];
  baseProducts: InternationalBaseProduct[];
  ASL: ProductTO[];
  version?: string;
}

export const EinlieferungslisteInternational = ({
  order,
  validProductionFacilities,
  baseProducts,
  ASL,
  version
}: EinlieferungslisteInternationalProps): JSX.Element => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: tOrderCreate } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const originator = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Originator);
  const submitter = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Submitter);
  const payer = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Payer);
  const deliveryPoint = order?.orderDetail?.pickup ? undefined : getDeliveryPoint(order?.orderDetail?.productionPlantId, validProductionFacilities);
  const pickupDepot = order?.orderDetail?.pickup ? getPickupDepot(order as GetOrderRep, validProductionFacilities) : undefined;

  // billing information calculations
  const getFeeByType = (type: string): OrderFeeRep | undefined => order?.orderPrice?.orderFees?.find((sc) => sc.type === type);
  const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;
  // Order value -- Auftragswert
  const sumAuftragswert = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE') + getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  // Fee reduction -- EntgeltermßUigung
  const sumEntgeltermaessigung = getFeeValueByType('OM_PORTAL_REDUCTION');
  // Already paid amount -- Bereits abgerechnet
  const sumBereitsGezahlt = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  // Total Sum or remaining amount -- Restforderung inkl. USt.
  const sumRestforderung = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');
  // Calculations are based on netting table -- VAT or Ust.
  const shippingChargeWithoutVAT = getFeeByType('OM_PORTAL_POSITION_RELATED_CHARGE')?.amount?.value || 0;
  const additionalChargeWithoutVAT = getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  const summeEntgelte = shippingChargeWithoutVAT + additionalChargeWithoutVAT + sumEntgeltermaessigung;
  const sumUSt = sumRestforderung - (summeEntgelte + sumBereitsGezahlt);
  // Currency
  const currency = order?.orderPrice?.orderFees?.[0]?.amount?.currency;

  const sortedBaseProds = order?.letterBaseProducts?.sort((a, b) => (a.discounts?.length || 0) - (b.discounts?.length || 0)) ?? [];

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>

        {/* page content */}
        <View>
          <View>
            <Text style={[styles.pageHeading, styles.lineContainer]}>Einlieferungsliste</Text>
            <Text style={[styles.pageHeading, styles.lineContainer]}>
              {order.orderCategoryProductKey === OrderCategoryProductKey.DIALOGPOST_INTERNATIONAL
                ? 'Dialogpost International'
                : order.orderCategoryProductKey === OrderCategoryProductKey.PRESS_INTERNATIONAL
                ? 'Presse International'
                : order.orderCategoryProductKey === OrderCategoryProductKey.BRIEF_INTERNATIONAL
                ? 'Brief Kilotarif International'
                : 'Brief 50+ International'}
            </Text>
          </View>
          <View style={[{ marginTop: '1mm' }, styles.lineContainerStreched]}>
            <Text style={[{ minWidth: '60mm' }, styles.paddingTop5]}>Auftragsbezeichnung:</Text>
            <Text style={[{ width: '70%', lineHeight: '1' }, styles.paddingTop5]}>{customWordBreakForPDF(order.orderLabel, 35) || '--'}</Text>
            <View style={[styles.internationalBarcodeConatiner]}>
              <Text style={[{ marginTop: '-16mm', marginRight: '-2mm' }]}>{`Auftragsnummer: ${order.orderId}`}</Text>
              {(() => {
                const barcodeUrl = getOrderIdBarcodeImageUrl(order.orderId || '');
                return barcodeUrl ? <Image style={{ minWidth: '58mm', height: '10mm' }} src={barcodeUrl} /> : <></>;
              })()}
            </View>
          </View>
          {order?.postage?.paymentClearingNumber ? (
            <View style={[styles.lineContainerStreched, { marginTop: '-2mm' }]}>
              <Text style={[{ minWidth: '60mm' }]}>Einlieferungsbelegnummer:</Text>
              <Text style={[{ width: '100%' }]}>{order?.postage?.paymentClearingNumber}</Text>
            </View>
          ) : (
            <></>
          )}
          {/* Kundendaten */}
          <View style={[styles.groupContainer, { marginTop: '4mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '1.5mm' }]}>Kundendaten</Text>
            <View style={styles.lineContainer}>
              <View style={[{ width: '100%' }, styles.linesContainer]}>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Absender:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${originator?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{originator?.name}</Text>
                  </View>
                </View>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Einlieferer:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${submitter?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{submitter?.name}</Text>
                  </View>
                </View>
                <View style={[{ marginBottom: '1mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Zahlungspflichtiger:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${payer?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{payer?.name}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Einlieferungsinformationen */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>
              {order?.orderDetail?.pickup === true ? 'Abholinformationen' : 'Einlieferungsinformationen'}
            </Text>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order?.orderDetail?.pickup === true ? 'Abholdatum:' : 'Einlieferungsdatum:'}</Text>
              <Text style={{ width: '100%' }}>{toFormattedDate(order.orderDetail?.date)}</Text>
              <Text style={{ minWidth: '60mm' }}>{order.orderDetail?.pickup ? 'Disponierendes Depot:' : 'Einlieferungsstelle:'}</Text>
              <Text style={{ width: '100%' }}>
                {order.orderDetail?.pickup
                  ? `${pickupDepot?.catalogId || ''} ${pickupDepot?.comment || ''}`
                  : `${deliveryPoint?.address?.postCode || ''} ${deliveryPoint?.comment || ''}`}
              </Text>
            </View>
          </View>

          {/* Produktdaten */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '5mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '1mm' }]}>Produktdaten</Text>
            <View style={styles.gridContainer}>
              <View style={[styles.gridHeaderContainer, { backgroundColor: '#fff' }]}>
                <Text style={[styles.gridHeaderItem, { fontWeight: 'bold', paddingLeft: 0, minWidth: '59mm' }]}>Basisprodukt</Text>
                <Text style={[styles.gridHeaderItem, { fontWeight: 'bold' }]}>Beförderungsleistung</Text>
                <Text style={[styles.gridHeaderItemRight, { fontWeight: 'bold' }]}>Sendungsmenge</Text>
                <Text style={[styles.gridHeaderItemRight, { fontWeight: 'bold' }]}>Gesamtgewicht in kg</Text>
              </View>
              {(() => {
                return sortedBaseProds?.map((item, idx) => {
                  return (
                    <View key={`productdata_${idx}`} style={[styles.gridContainer]}>
                      <View style={[styles.gridRowContainer, { marginBottom: 0 }]}>
                        <Text style={[styles.gridRowItem, { paddingLeft: 0, minWidth: '59mm' }]}>
                          {baseProducts.find((bp) => bp?.productNumber == (order as GetInternationalOrderRep)?.internationalBaseProduct)
                            ?.descriptionShort || ''}
                        </Text>
                        <Text style={[styles.gridRowItem, { width: '155mm', marginBottom: 0 }]}>{item?.baseProduct?.description || ''}</Text>
                        <Text style={[styles.gridRowItemRight, { width: '25mm' }]}>
                          {(item?.baseProduct?.quantity || '').toLocaleString(moment.locale())}
                        </Text>
                        <Text style={[styles.gridRowItemRight]}>{(item?.baseProduct?.grossWeight || '').toLocaleString(moment.locale())}</Text>
                      </View>
                      <View style={[{ marginBottom: 0 }]}>
                        {item?.discounts?.length ? (
                          <View>
                            <Text
                              style={[styles.gridHeaderItem, { fontWeight: 'bold', paddingLeft: 0, minWidth: '59mm', margin: '0mm 0 0.5mm 59.8mm' }]}
                            >
                              {tOrderCreate('additionalLetterServicesForBaseProduct')}:
                            </Text>
                          </View>
                        ) : (
                          <></>
                        )}
                        {item?.discounts?.length ? (
                          <View style={[styles.gridRowContainer, { border: '1px solid #b1b1b1', marginBottom: '2mm', marginLeft: '59.8mm' }]}>
                            {item?.discounts?.map((discount, k) => {
                              return (
                                <View
                                  key={`${idx}-${k}`}
                                  style={[
                                    styles.gridRowContainer,
                                    { padding: '1mm' },
                                    k !== 0 ? { borderLeft: '1px solid #b1b1b1' } : { borderLeft: 0 }
                                  ]}
                                >
                                  <Text style={[styles.gridRowItem]}>
                                    {AdditionalLetterBaseProducts[
                                      ASL?.filter((p) => p.productNumber === discount?.productNumber)[0]
                                        ?.hierarchyProductCharacteristic as keyof typeof AdditionalLetterBaseProducts
                                    ] +
                                      ':     ' +
                                      (discount?.shipmentQuantity || '').toLocaleString(moment.locale()) || ''}
                                  </Text>
                                </View>
                              );
                            })}
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  );
                });
              })()}
            </View>
          </View>

          {/* Frankierung und Abrechnungsdaten */}
          <View style={styles.lineContainerStreched}>
            <View style={{ width: '45%' }}>
              <View style={styles.groupContainer}>
                <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>Frankierung</Text>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Art der Frankierung:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {order.postage?.type ? tCatalogValues(`postageType.display.${order.postage.type}`) : '--'}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: '45%' }}>
              <View style={styles.groupContainer}>
                <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>Abrechnungsdaten</Text>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Auftragswert:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumAuftragswert && currency
                      ? sumAuftragswert.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Entgeltermäßigung:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumEntgeltermaessigung && currency
                      ? sumEntgeltermaessigung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>Bereits abgerechnet:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumBereitsGezahlt && currency
                      ? sumBereitsGezahlt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '40mm' }}>USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right' }}>
                    {sumUSt && currency
                      ? sumUSt.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
                <View style={[styles.lineContainer]}>
                  <Text style={{ minWidth: '40mm', fontWeight: 'bold' }}>Restforderung inkl. USt.:</Text>
                  <Text style={{ width: '100%', textAlign: 'right', fontWeight: 'bold' }}>
                    {sumRestforderung && currency
                      ? sumRestforderung.toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency.toString()
                        })
                      : (0.0 as number).toLocaleString(moment.locale(), {
                          style: 'currency',
                          currency: currency?.toString()
                        })}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};
