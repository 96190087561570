import { pdf } from '@react-pdf/renderer';
import { now } from 'common/utils/timeAndDate';
import { GetOrderRep } from 'generated';
import moment from 'moment/moment';

export const generateAndDownloadPdf = async (pdfElement: JSX.Element, order: GetOrderRep, pdfName: string): Promise<void> => {
  const asPdf = pdf();
  asPdf.updateContainer(pdfElement);
  if (!asPdf.container?.document?.children || asPdf.container.document.children.length <= 0) {
    console.error(`${now()} - error printing ${pdfName}: no representation of order ${order.orderId || ''}.`);
  } else {
    const blob = await asPdf.toBlob();
    const pdfURL = getUrl(blob);

    // special case Firefox: open document in new tab
    let destDocument = document;
    if (window?.navigator?.userAgent?.toLowerCase().includes('firefox')) {
      const newTab = window.open();
      if (newTab) {
        destDocument = newTab.document;
      }
    }
    if (destDocument) {
      Object.assign(destDocument.createElement('a'), {
        target: '_blank',
        href: pdfURL,
        download: `${pdfName}-${moment().format('YYYYMMDDHHmmss')}.pdf`
      }).click();
    }
  }
};

export const getUrl = (blob: Blob): string => {
  return window.URL.createObjectURL(blob);
};
