// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSearchFilter_filters__jSNUr {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.OrderSearchFilter_filters__jSNUr > * {
  flex-grow: 1;
}

.OrderSearchFilter_filters__jSNUr > :last-child {
  margin-right: 0;
}

.OrderSearchFilter_filters__jSNUr > .date-range-input-container {
  flex-grow: 0;
}

.OrderSearchFilter_filters__jSNUr > * + .date-range-input-container {
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/filter/OrderSearchFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".filters {\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 20px;\n}\n\n.filters > * {\n  flex-grow: 1;\n}\n\n.filters > :last-child {\n  margin-right: 0;\n}\n\n.filters > :global(.date-range-input-container) {\n  flex-grow: 0;\n}\n\n.filters > * + :global(.date-range-input-container) {\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `OrderSearchFilter_filters__jSNUr`
};
export default ___CSS_LOADER_EXPORT___;
