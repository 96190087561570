import { useAuthContext } from 'common/context/auth/AuthContext';
import { Order } from 'order/common/context/order/dtos/Order';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React, { VFC } from 'react';

export type FertigungDetailSectionProps = {
  value: Order;
};

export const FertigungDetailSection: VFC<FertigungDetailSectionProps> = ({ value }) => {
  const { fertigung } = useDependentProducts(value.productGroup, value.orderDetail?.date, value.baseProduct?.productNumber);
  const getDependentProduct = (productNumber?: string) => value.dependentProducts?.find(({ productNumber: p }) => p === productNumber);
  const language = useAuthContext().user.language;
  return (
    <OrderOverviewWidget title="Fertigung Paletten">
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h6>Fertigung Bund- oder Behälter</h6>
            </td>
          </tr>
          <tr>
            <td>
              <p>Leitregion:</p>
            </td>
            <td>
              <p>{getDependentProduct(fertigung.sortedTrayRegion?.productNumber)?.shipmentQuantity?.toLocaleString(language)}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>PLZ:</p>
            </td>
            <td>
              <p>{getDependentProduct(fertigung.sortedTrayPostcode?.productNumber)?.shipmentQuantity?.toLocaleString(language)}</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h6>Fertigung Paletten</h6>
            </td>
          </tr>
          <tr>
            <td>
              <p>Leitzone:</p>
            </td>
            <td>
              <p>{getDependentProduct(fertigung.sortedPalletZone?.productNumber)?.shipmentQuantity?.toLocaleString(language)}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Leitregion:</p>
            </td>
            <td>
              <p>{getDependentProduct(fertigung.sortedPalletRegion?.productNumber)?.shipmentQuantity?.toLocaleString(language)}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </OrderOverviewWidget>
  );
};
