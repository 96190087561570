import React, { ReactElement } from 'react';
import { DHLLoading } from '@gkuis/gkp-base-widgets/dist/lib';
import { uid } from 'react-uid';

import i18n from 'i18n';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { TableRow } from './TableRow';
import { ColumnConfig } from '../dtos/ColumnConfig';
import { TableLoadingState } from '../dtos/LoadingState';

import styles from '../table.module.css';

export type Props<T> = {
  /** Name, wird für die Generierung derID verwendet. */
  name: string;
  /** Tabellen Datenspeicher. */
  data: T[];
  /** Ladestatus der anzuzeigenden Daten. */
  loadingState?: string;
  /** Text der während des Ladevorgangs angezeigt wird. */
  loadingText?: JSX.Element | string | null;
  /** Ausgabe bei leeren Daten. */
  dataEmpty?: string;
  /** Name des Properties, das die ID enthält. */
  idPropertiesName: string;
  /** Kind-Komponenten. */
  cols: ColumnConfig[];
  /** Wird bei Selektion einer Tabellenzeile aufgerufen, die Daten der aktuellen Zeile werden an die Funktion übergeben. */
  selected: (event: React.MouseEvent, data: T) => void;
  /** Function used to identify (a) row(s) to be highlighted based on data the row provides */
  highlightRows?: (data: T) => boolean;
  allChildrenExpanded?: boolean;
  highlighted?: boolean;
  language?: string;
};

/** Tabellen-Body. */
export const TableBodyTree = ({
  name,
  data,
  loadingState,
  loadingText,
  dataEmpty,
  idPropertiesName,
  cols,
  selected,
  highlighted = false,
  allChildrenExpanded = false,
  ...props
}: Props<OrderTreeItem<OrderReplyTDO>>): ReactElement => {
  // TODO cellDefs = get visible columns and parse by type what component to show
  const formatter = new Map([
    ['data.totalQty', new Intl.NumberFormat(i18n.language)],
    ['data.totalAmountValue', new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR' })]
  ]);
  const visibleColumns = cols.filter((c) => !c.hidden);
  const columnCount = Array.isArray(cols) ? cols.length : 1;

  if (loadingState === TableLoadingState.LOADING) {
    return (
      <tbody data-testid={name}>
        <tr className="table-body-row">
          <td colSpan={columnCount}>
            <DHLLoading name={name + '-loading'} loading={true} />
            <div className={styles.loadingText}>{loadingText}</div>
          </td>
        </tr>
      </tbody>
    );
  }

  if (data?.length > 0) {
    return (
      <tbody data-testid={name}>
        {data.map((rowData) => (
          <TableRow
            key={uid(rowData)}
            name={name + '-r-' + rowData.id}
            colItems={visibleColumns}
            data={rowData}
            selected={selected}
            formatter={formatter}
            idPropertiesName={idPropertiesName}
            level={0}
            highlight={rowData.highlight || highlighted}
            language={props.language}
            allChildrenExpanded={allChildrenExpanded}
          />
        ))}
      </tbody>
    );
  }

  if (!loadingState || loadingState === TableLoadingState.LOADED) {
    return (
      <tbody data-testid={name}>
        <tr data-testid={name + '-empty'} className="table-body-row">
          <td colSpan={columnCount} className="table-body-empty">
            {dataEmpty}
          </td>
        </tr>
      </tbody>
    );
  }

  return <tbody data-testid={name} />;
};
