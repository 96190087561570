// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.franking-display_frankingTable__AQwvI tr + tr td {
  padding-top: 5px;
}

table.franking-display_frankingTable__AQwvI {
  table-layout: auto;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/FrankingDisplay/franking-display.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".frankingTable tr + tr td {\n  padding-top: 5px;\n}\n\ntable.frankingTable {\n  table-layout: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frankingTable": `franking-display_frankingTable__AQwvI`
};
export default ___CSS_LOADER_EXPORT___;
