import { OrderSearchResultRepTDO } from '../../../services/OrderService';
import { OrderSearchType } from '../dtos/OrderSearchType';
import { checkExpandedOrHighlighted, recursivelyFlattenOrderTree, resolveProcedureNamesRecursively } from '../refineOrderSearchResult';
import { OrderSearchAttribute } from '../dtos/OrderSearchAttribute';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { OrderSearchKey } from '../dtos/OrderSearchKey';

interface ProcessSearchResponse {
  attributes: OrderSearchAttribute[];
  response: OrderSearchResultRepTDO | undefined;
  searchType: OrderSearchType;
}

export const processSearchResponse = ({ attributes, response, searchType }: ProcessSearchResponse): OrderSearchResultRepTDO => {
  let modifiedData = response ? { ...response } : { orders: [] };

  // TODO: Is it necessary? Should be done in backend
  const isOrderTypeSearchZA = attributes.find((a) => a.key === OrderSearchKey.OrderType)?.value === OrderTypes.Zusatzauftrag;
  if (searchType !== OrderSearchType.SIMPLE && !isOrderTypeSearchZA) {
    modifiedData = {
      ...response,
      orders: recursivelyFlattenOrderTree(response?.orders || [])
    };
  }

  modifiedData = {
    ...response,
    orders: modifiedData.orders?.map((order) => checkExpandedOrHighlighted(attributes, order)).map(resolveProcedureNamesRecursively)
  };

  return modifiedData;
};
