// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchName_searchNameContainer__8586W {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px !important;
  position: relative;
}

.SearchName_searchName__Xpi0i {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.SearchName_customBtn__yS4Gn {
  padding: 0px;
  color: #323232 !important;
}

.SearchName_customBtn__yS4Gn:hover {
  background-color: #fff !important;
  opacity: 1;
}

.SearchName_customIconBtn__\\+nmMY {
  opacity: 0.6;
  margin-left: 10px;
}

.SearchName_customIconBtn__\\+nmMY:hover {
  opacity: 1;
}

.SearchName_customBtn__yS4Gn:focus {
  background-color: #fff !important;
}

.SearchName_customInput__EkKP- {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0;
  font-size: 16px;
  max-width: 650px;
  width: 100%;
}

.SearchName_editableDiv__VVlfR {
  font-size: 16px;
  visibility: hidden;
  height: 0;
  max-width: 650px;
}

.SearchName_existingNameSpan__5irmL {
  font-size: 16px;
  max-width: 650px;
  overflow-wrap: break-word;
  font-weight: bold;
}

.SearchName_addNewName__4dH4v {
  color: #d40511 !important;
  font-size: 14px;
}

.SearchName_ipBtns__r3mBD {
  display: flex;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/SearchName/SearchName.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;EAC3B,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,uBAAuB;EACvB,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".searchNameContainer {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n  margin-bottom: 10px !important;\n  position: relative;\n}\n\n.searchName {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.customBtn {\n  padding: 0px;\n  color: #323232 !important;\n}\n\n.customBtn:hover {\n  background-color: #fff !important;\n  opacity: 1;\n}\n\n.customIconBtn {\n  opacity: 0.6;\n  margin-left: 10px;\n}\n\n.customIconBtn:hover {\n  opacity: 1;\n}\n\n.customBtn:focus {\n  background-color: #fff !important;\n}\n\n.customInput {\n  outline: none !important;\n  box-shadow: none !important;\n  border: none !important;\n  padding: 0;\n  font-size: 16px;\n  max-width: 650px;\n  width: 100%;\n}\n\n.editableDiv {\n  font-size: 16px;\n  visibility: hidden;\n  height: 0;\n  max-width: 650px;\n}\n\n.existingNameSpan {\n  font-size: 16px;\n  max-width: 650px;\n  overflow-wrap: break-word;\n  font-weight: bold;\n}\n\n.addNewName {\n  color: #d40511 !important;\n  font-size: 14px;\n}\n\n.ipBtns {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchNameContainer": `SearchName_searchNameContainer__8586W`,
	"searchName": `SearchName_searchName__Xpi0i`,
	"customBtn": `SearchName_customBtn__yS4Gn`,
	"customIconBtn": `SearchName_customIconBtn__+nmMY`,
	"customInput": `SearchName_customInput__EkKP-`,
	"editableDiv": `SearchName_editableDiv__VVlfR`,
	"existingNameSpan": `SearchName_existingNameSpan__5irmL`,
	"addNewName": `SearchName_addNewName__4dH4v`,
	"ipBtns": `SearchName_ipBtns__r3mBD`
};
export default ___CSS_LOADER_EXPORT___;
