// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-step-headline_wrapper__BAeeN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.order-step-headline_title__OfgMe {
  display: block;
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

.order-step-headline_notCapitalise__01ll2{
  display: block;
  margin-bottom: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/OrderStepHeadline/order-step-headline.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,2BAA2B;AAC7B","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 20px;\n}\n\n.title {\n  display: block;\n  margin-bottom: 0 !important;\n  text-transform: capitalize;\n}\n\n.notCapitalise{\n  display: block;\n  margin-bottom: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `order-step-headline_wrapper__BAeeN`,
	"title": `order-step-headline_title__OfgMe`,
	"notCapitalise": `order-step-headline_notCapitalise__01ll2`
};
export default ___CSS_LOADER_EXPORT___;
