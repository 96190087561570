import { ColumnType } from './ColumnType';

export enum ContentAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export interface CellMetaData {
  cellId?: string | number;
  language?: string;
  isOrderViewHyperlinkDisabled?: boolean;
}

export interface ColumnConfig {
  contentAlignment?: ContentAlignment | null;
  currencyType?: string;
  customHeader?: (config: ColumnConfig, meta?: CellMetaData) => any;
  customRenderer?: (value: any, config: ColumnConfig, row: any, meta?: CellMetaData) => any;
  childRenderer?: (row: any, meta?: CellMetaData) => any;
  dateFormat?: string;
  disableMinWidth?: boolean;
  filterable?: boolean;
  hidden?: boolean;
  hideable?: boolean;
  icon?: string;
  innerClassName?: string;
  tdClassName?: string;
  className?: string;
  movable?: boolean;
  name: string | null;
  property: string;
  selected?: boolean;
  selectOptions?: (string | number | any)[];
  sortable?: boolean;
  title: string | null;
  type: ColumnType;
}
