import { GetDialogPostOrderRep, GetOrderRep, GetPressDistributionOrderRep, OrderCustomerRoleEnum } from 'generated';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { UseGetOrderOptions } from 'order/common/hooks/useGetOrder';
import { ChangeOrderSchema } from 'order/orderChange/schema/changeOrderSchema';
import { CopyOrderSchema } from 'order/orderCopy/schema/copyOrderSchema';
import { DestinationSchema } from 'order/productGroups/common/components/atom/AMDHLDestinationInput';
import { Shipment } from 'order/productGroups/common/components/molecule/DisplayQuantityWeightSection';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { usePaymentBankInfo } from 'order/productGroups/common/services/usePaymentBankInfo';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { PostageType } from '../dtos/PostageType';
import { ProductGroup } from '../dtos/ProductGroup';
import { translateChangeOrders, translateCopyOrders } from './translateOrders';
import { usePressDistributionBaseProducts } from './usePressDistributionBaseProducts';
import { BaseProductRegion } from '../dtos/BaseProductRegion';
import { usePressDistributionDependentProducts } from './usePressDistributionDependentProducts';
import { useOrderContext } from '../context/order/OrderContext';

export type GetDialogPost = GetOrderRep &
  DeepPartial<{
    baseProduct: {
      productNumber: string;
      weight: number;
      quantity: number;
    };
    additionalQuantity: number;
    destinations: DestinationSchema[];
    shipmentData: Shipment[];
    orderCategory: string;
    postageType: PostageType;
  }>;

export type useOrderTransformationReturn<TData> = {
  data?: TData;
  rawOrder?: GetOrderRep;
  isLoading: boolean;
  invalidate: Dispatch<void>;
};

export type useTransformOrderReturn<TData> = {
  data?: TData;
  isLoading: boolean;
};

export type useOrderTransformationOptions<TCopy extends boolean> = UseGetOrderOptions &
  Partial<{
    copy: TCopy;
  }>;

export const useTransformOrder = <TCopy extends boolean>(
  orderData?: GetOrderRep | null,
  options?: useOrderTransformationOptions<TCopy>
): useTransformOrderReturn<DeepPartial<TCopy extends true ? CopyOrderSchema : ChangeOrderSchema>> => {
  const [originatorLoading, setOriginatorLoading] = useState(true);
  const [submitterLoading, setSubmitterLoading] = useState(true);
  const { meta } = useOrderContext();

  const dependentProducts = useDependentProducts(
    orderData?.productGroup,
    orderData?.orderDetail?.date as Date | undefined,
    orderData?.productGroup === ProductGroup.DIALOGPOST || orderData?.productGroup === ProductGroup.DIALOGPOSTWURFSPEZIAL
      ? (orderData as GetDialogPostOrderRep)?.baseProduct?.productNumber
      : undefined
  );
  const fetchedBaseProductsByZkz = usePressDistributionBaseProducts(
    (orderData as GetPressDistributionOrderRep)?.journal?.zkz,
    orderData?.orderDetail?.date
  );

  const baseProductsNational = fetchedBaseProductsByZkz.filter(
    (p) => !!p.productNumber && !!p.descriptionShort && p.region === BaseProductRegion.NATIONAL
  );
  const { pressDistributionDependentProducts: fetchDependentProductsByZkz, isLoading: dependentProductsLoaded } =
    usePressDistributionDependentProducts((orderData as GetPressDistributionOrderRep).journal?.zkz , orderData?.orderDetail?.date );

  const fetchOriginator = async (originatorId?: string) => {
    await getCustomerInfo(originatorId);
    setOriginatorLoading(false);
  };

  const fetchSubmitter = async (submitterId?: string) => {
    await getCustomerInfo(submitterId);
    setSubmitterLoading(false);
  };
  const findByRole = (role: OrderCustomerRoleEnum) => orderData?.parties.find((p) => p.role === role);

  useEffect(() => {
    const originatorId = findByRole(OrderCustomerRoleEnum.Originator)?.customerId;
    const submitterId = findByRole(OrderCustomerRoleEnum.Submitter)?.customerId;
    fetchOriginator(originatorId);
    fetchSubmitter(submitterId);
  }, [orderData]);

  const payerId = findByRole(OrderCustomerRoleEnum.Payer)?.customerId;
  usePaymentBankInfo(payerId, orderData?.orderDetail?.date);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = useMemo<any>(
    () =>
      options?.copy
        ? translateCopyOrders(orderData, dependentProducts, baseProductsNational)
        : translateChangeOrders(orderData, dependentProducts, baseProductsNational, fetchDependentProductsByZkz, meta?.ALS),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderData, dependentProducts, dependentProducts.isLoading, dependentProductsLoaded]
  );

  return { data, isLoading: dependentProducts.isLoading || originatorLoading || submitterLoading };
};
