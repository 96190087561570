// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-input_icon__uBfzW {
  position: absolute;
  top: 11px;
  right: 11px;
  line-height: .1;
  cursor: pointer;
}

.customer-input_icon__uBfzW, .customer-input_icon__uBfzW svg {
  height: 20px;
  width: 20px;
}

.customer-input_icon__uBfzW svg * {
  fill: #d40511;
}

.customer-input_menu__oo9Xk {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/CustomerInput/customer-input.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":[".icon {\n  position: absolute;\n  top: 11px;\n  right: 11px;\n  line-height: .1;\n  cursor: pointer;\n}\n\n.icon, .icon svg {\n  height: 20px;\n  width: 20px;\n}\n\n.icon svg * {\n  fill: #d40511;\n}\n\n.menu {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `customer-input_icon__uBfzW`,
	"menu": `customer-input_menu__oo9Xk`
};
export default ___CSS_LOADER_EXPORT___;
