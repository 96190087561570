import React, { FC, useEffect, useRef } from 'react';
import { PressProductInfoSection } from 'order/common/components/Overview/PressProductInfoSection';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { OrderContentTabProps } from '../OrderContentTab';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { usePressDistributionDependentProducts } from '../../../../../common/hooks/usePressDistributionDependentProducts';
import { OrderTreeContainer } from '../../../OrderTree/OrderTreeContainer';
import { OrderCustomerRole } from 'order/common/context/order/dtos/OrderCustomerRole';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { AdditionalLetterService, discountFn, transformPressBaseProducts } from 'order/common/utils/transformPressBaseProducts';
import { Order } from 'order/common/context/order/dtos/Order';
import { dependentProductsAndVariants } from '../../../../../productGroups/press/schema/pressSchema';

export const PressDistributionTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;
  const { pressDistributionDependentProducts: dependentProductsReturn } = usePressDistributionDependentProducts(
    order.journal?.zkz,
    order.orderDetail?.date
  );

  const ALS = useRef<AdditionalLetterService[]>([]);

  useEffect(() => {
    order.internationalProducts?.forEach((product) => {
      (async () => {
        const bb = await discountFn(product.productNumber, order?.orderDetail?.date);
        ALS.current.push({ productNumber: product.productNumber || '', specialLetterProducts: bb });
      })();
    });
  }, [order?.internationalProducts]);

  const pressInvoiceRecipient =
    order.productGroup === ProductGroup.PRESS_DISTRIBUTION &&
    order?.parties?.find((party) => party.role === OrderCustomerRole.InvoiceRecipient)?.customerId === '0020000000';

  const partnerRoles = order?.parties.filter((p) => !!p.role && pressInvoiceRecipient && !p.role.includes(OrderCustomerRole.InvoiceRecipient));

  const sortingService = order?.nationalProducts?.some((bp) => bp.sortingService);

  const modifiedOrder = {
    ...order,
    nationalProducts: transformPressBaseProducts(order?.nationalProducts, sortingService, order?.packaging, order?.destinations),
    internationalProducts: transformPressBaseProducts(
      order?.internationalProducts,
      false,
      order?.packaging,
      order?.destinations,
      [],
      order.dependentProducts,
      ALS.current
    )
  };

  return (
    <div data-testid={'order-content-tab-press-testid'}>
      <PressProductInfoSection
        value={modifiedOrder as Order}
        supplements={order?.supplements}
        orderMode={OrderMode.VIEW}
        pressDependentProducts={dependentProductsReturn}
      />
      <CustomerDetailSection parties={partnerRoles} validityDate={order?.orderDetail?.date} />
      {order.hasOrderTree && <OrderTreeContainer order={order} />}
    </div>
  );
};
