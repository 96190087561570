import { sendRequest } from 'common/api-adapter';
import { getUser } from 'common/context/auth/AuthProvider';
import { BASE_URL } from 'common/utils/apiUtils';
import { mapOrder } from './mapOrder';

interface GetOrderOptions {
  includeDst?: boolean;
  includePrice?: boolean;
  includeNve?: boolean;
}

export async function getOrder<T>(orderId: string, options?: GetOrderOptions): Promise<T | null> {
  try {
    const user = getUser();
    const params: Record<string, string> = {};

    if (!options?.includeDst) {
      params['includeDst'] = 'false';
    }

    if (options?.includePrice) {
      params['includePrice'] = 'true';
    }

    if (options?.includeNve) {
      params['includeNve'] = 'true';
    }

    const { data } = await sendRequest({
      method: 'get',
      token: user._accessToken,
      extUserId: user.extUserId,
      params: Object.keys(params).length ? params : undefined,
      url: `${BASE_URL}orders/${orderId}`
    });

    if (data) {
      return mapOrder(data);
    }
    return null;
  } catch (e: any) {
    if (
      e.response &&
      e.response.status &&
      (e.response?.status === 408 || e.response?.code === 408 || e.response?.status === 504 || e.response?.code === 504)
    ) {
      return {
        ...e.response,
        timeoutError: true
      };
    }
    console.error('Failed getOrder request: ', e.response);
    return null;
  }
}
