import { getCustomer } from 'common/services/CustomerService';
import { CustomerInfoWithAddressTO } from 'generated';

const validateCustomerId = (customerId = ''): boolean => /^\d{10}$/.test(customerId);

export interface UseCustomerInfoReturn {
  customerInfo?: CustomerInfoWithAddressTO;
  isLoading: boolean;
}

const customerInfoCache: Record<string, CustomerInfoWithAddressTO> = {};

/**
 * Fetches the users info and address using the user's id.
 * The id must be exactly ten digits after trimming.
 *
 * @param customerId the customer id
 */
export const getCustomerInfo = async (customerId?: string): Promise<UseCustomerInfoReturn> => {
  const trimmed = customerId?.replace(/\s+/g, '');
  const isValid = validateCustomerId(trimmed);
  const query = isValid ? trimmed : undefined;

  if (!query) {
    return {
      customerInfo: undefined,
      isLoading: false
    };
  }

  if (customerInfoCache[`customer${trimmed}`]) {
    return {
      customerInfo: customerInfoCache[`customer${trimmed}`],
      isLoading: false
    };
  }

  if (customerId && customerId.length) {
    try {
      const data = await getCustomer(customerId);
      if (data) {
        customerInfoCache[`customer${trimmed}`] = data;

        return {
          customerInfo: data,
          isLoading: false
        };
      }
    } catch (e) {
      console.error('Failed to load customer info data for', customerId, e);

      return {
        customerInfo: undefined,
        isLoading: false
      };
    }
  }

  console.error('No customerId provided', customerId);

  return {
    customerInfo: undefined,
    isLoading: false
  };
};
