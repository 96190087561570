import { determineLetterTypeByFormat } from '../../../common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import classNames from 'classnames';
import { PricePositionRep, ServiceClusterRep } from 'generated';
import styles from './pslbilling-table.module.css';
import { BillingTableItemHeadRowProps } from 'order/orderChange/common/components/BillingTable/BillingTableItemHeadRow';
import React, { FC } from 'react';
import { PartialProductDescription } from './PSLBillingTable';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';

export type PSLBillingTableRowProps = PricePositionRep &
  BillingTableItemHeadRowProps & {
    index: number;
    item: ServiceClusterRep;
    unitAmountCol: boolean;
  };

export const PSLBillingTableRow: FC<PSLBillingTableRowProps> = ({ item, index, unitAmountCol }) => {
  const { user } = useAuthContext();
  const partial = item.pricePositions?.find((value) => value.productDescription === PartialProductDescription.PartialDiscount);
  const idDiscount = item.pricePositions?.find((value) => value.productDescription === PartialProductDescription.IdDiscount);
  const termDiscount = item.pricePositions?.find((value) => value.productDescription === PartialProductDescription.TermDiscount);
  return (
    <tr key={'row-data-' + index} className={classNames(styles.parentRow, 'dhlTable-row dhlTable-row-default leaf', styles.lastNoPadding)}>
      <td>{index + 1}</td>
      <td>{determineLetterTypeByFormat(item.baseProductFormat ?? '')}</td>
      <td className={styles.rightAlign}>{partial?.qty?.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
      {unitAmountCol && (
        <td className={styles.rightAlign}>
          {partial?.unitAmount?.value?.toLocaleString(user.language, {
            style: 'currency',
            currency: partial?.unitAmount?.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
      )}
      <td className={styles.rightAlign}>{partial?.reduction && partial.reduction + '%'}</td>
      <td className={styles.rightAlign}>
        {partial?.amount?.value?.toLocaleString(user.language, {
          style: 'currency',
          currency: partial?.amount?.currency || 'EUR',
          maximumFractionDigits: 3
        })}
      </td>
      <td className={styles.rightAlign}>{idDiscount?.qty?.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
      <td className={styles.rightAlign}>{idDiscount?.reduction && idDiscount.reduction + '%'}</td>
      <td className={styles.rightAlign}>
        {idDiscount?.amount?.value?.toLocaleString(user.language, {
          style: 'currency',
          currency: idDiscount?.amount?.currency || 'EUR',
          maximumFractionDigits: 3
        })}
      </td>
      <td className={styles.rightAlign}>{termDiscount?.qty?.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
      <td className={styles.rightAlign}>{termDiscount?.reduction && termDiscount.reduction + '%'}</td>
      <td className={styles.rightAlign}>
        {termDiscount?.amount?.value?.toLocaleString(user.language, {
          style: 'currency',
          currency: termDiscount?.amount?.currency || 'EUR',
          maximumFractionDigits: 3
        })}
      </td>
      <td className={styles.rightAlign}>
        {((partial?.amount?.value ?? 0) + (idDiscount?.amount?.value ?? 0) + (termDiscount?.amount?.value ?? 0)).toLocaleString(user.language, {
          style: 'currency',
          currency: partial?.amount?.currency || 'EUR',
          maximumFractionDigits: 3
        })}
      </td>
    </tr>
  );
};
