import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AMDHLInputWithProposals } from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals';
import { usePrevious } from 'common/utils/hooks';
import { AMDHLInputFilterContainer } from 'order/orderSearch/components/AMDHLInputFilterContainer';
import { getValidator } from 'order/orderSearch/components/filter/validate';
import { DefaultAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { useSearchContext } from '../../../common/context/search/SearchContext';
import { useAlerts } from 'common/AlertContext';
import { useSearchRecommendations } from 'order/common/hooks/useSearchRecommendations';

/**
 * Input specialised for Auftragsnummer.
 *  The general GKPOrderSearchFilterAttrSelectable component is not used because of
 *  - no selection field
 *  - only one input for four types of OrderSearchKeys: OrderId, CustomerOrderId1, CustomerOrderId2, EposLabel
 *  - no option list possible (we don't know the type before user input)
 *
 * @constructor
 */

interface Props {
  resetChild?: boolean;
  resetCallback?: () => void;
  nonNumeric?: boolean;
}
export const AMDHLOrderSearchFilterOrderNumberInput = ({ resetChild, resetCallback, nonNumeric }: Props): JSX.Element => {
  const [errorKey, setErrorKey] = useState<OrderSearchKey | undefined>();
  const { t: translateError } = useTranslation('', { keyPrefix: 'orderSearch.formError' });
  const { t } = useTranslation('', { keyPrefix: 'orderFilter.attributes' });
  const { clear } = useAlerts();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [parentInput, setParentInput] = useState<DefaultAttribute>({
    value: '',
    key: OrderSearchKey.OrderId
  });
  const [inputValue, setInputValue] = useState<DefaultAttribute>({
    value: '',
    key: OrderSearchKey.OrderId
  });
  const { addAttribute, attributes, editedAttribute, editAttribute, search, searchType, newRecommendations, tab, metadata, upsertMetaData } =
    useSearchContext();
  const previousEditAttribute = usePrevious(editedAttribute);
  const searchKeyType = [OrderSearchKey.OrderId, OrderSearchKey.CustomerOrderId1, OrderSearchKey.CustomerOrderId2, OrderSearchKey.EposLabel];
  const previousTab = usePrevious(tab);
  const data = searchKeyType.map(useSearchRecommendations);

  const recommendationsList = data.flatMap((a) => a.recommendations);
  const sortedRecommendationsList = recommendationsList.sort((a, b) => {
    if (!a.id) {
      return 1;
    }
    if (!b.id) {
      return -1;
    }
    const aDate = new Date(a.id).valueOf();
    const bDate = new Date(b.id).valueOf();

    return bDate - aDate;
  });

  useEffect(() => {
    showMenu && data.forEach(async (a) => await a.load());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  useEffect(() => {
    if (newRecommendations.length) {
      (async () => {
        for (const attribute of newRecommendations) {
          if (attribute && searchKeyType.includes(attribute.key)) {
            await data.find((a) => attribute.key === a.type)?.add(attribute);
          }
        }
      })();
    }
  }, [newRecommendations]);

  useEffect(() => {
    if (!attributes.length) {
      editAttribute({
        value: '',
        key: OrderSearchKey.OrderId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editedAttribute !== previousEditAttribute) {
      setInputValue({
        value: editedAttribute ? editedAttribute.value.trim() : '',
        key: OrderSearchKey.OrderId
      });
    }
  }, [editedAttribute, previousEditAttribute]);

  useEffect(() => {
    if (resetChild || previousTab !== tab) {
      setInputValue({
        value: '',
        key: OrderSearchKey.OrderId
      });

      editAttribute({
        value: '',
        key: OrderSearchKey.OrderId
      });

      // Clear any existing errors
      setErrorKey(undefined);

      // Call the resetCallback if it's provided
      resetCallback && resetCallback();
    }
  }, [resetChild, resetCallback, tab]);

  const onChange = (input: DefaultAttribute): void => {
    setErrorKey(undefined);
    setInputValue({
      value: '',
      key: OrderSearchKey.OrderId
    });
    const attr: DefaultAttribute = {
      ...input,
      value: input.value.trim()
    };
    const newAttributes = addAttribute(attr);
    if (newAttributes?.length === 1) {
      clear();
      search(searchType, newAttributes);
    }
  };

  useEffect(() => {
    const attr: DefaultAttribute = {
      ...parentInput,
      value: parentInput.value.trim()
    };
    upsertMetaData('simpleChange', attr);
  }, [parentInput]);

  useEffect(() => {
    metadata?.parentInputValue && setInputValue(metadata?.parentInputValue);
  }, [metadata?.parentInputValue]);

  const stringToValue = (input: string): DefaultAttribute | undefined => classifyInput(input, setErrorKey);
  const valueToString = (input: DefaultAttribute): string => input.value + (input.meta ? ' ' + input.meta : '');

  return (
    <AMDHLInputFilterContainer>
      <AMDHLInputWithProposals
        name="orderSearchFilterAuftragsnummerInput"
        options={sortedRecommendationsList}
        value={inputValue}
        setParentData={setShowMenu}
        setParentInput={setParentInput}
        stringToValue={stringToValue}
        valueToString={valueToString}
        readOnly={false}
        required={false}
        onChange={onChange}
        onDelete={(e) => data.find((a) => a.type === e.key)?.delete(e)}
        functionIcon={attributes.length ? 'plus' : 'search'}
        error={errorKey && translateError(errorKey)}
        placeholder={t('einfachPH')}
        nonNumeric={nonNumeric}
      />
    </AMDHLInputFilterContainer>
  );
};

function validateOn(
  key: Exclude<OrderSearchKey, OrderSearchKey.Date>,
  value: string,
  setError: React.Dispatch<React.SetStateAction<OrderSearchKey | undefined>>
): DefaultAttribute | undefined {
  const parsed = getValidator(key, value);
  if (parsed) {
    return { key, value };
  }
  setError(key);
}

export const classifyInput = (
  value: string,
  setError: React.Dispatch<React.SetStateAction<OrderSearchKey | undefined>>
): DefaultAttribute | undefined => {
  const amountOfDashes = (value.match(/-/g) || []).length;
  const eposLabelSuffix = value.search(/(-V|\*)/g) >= 0;
  const lengthWithoutWhitespace = value.replace(/\s+/g, '').length;

  if (amountOfDashes > 1) {
    return validateOn(OrderSearchKey.CustomerOrderId2, value, setError);
  }

  if (amountOfDashes) {
    if (eposLabelSuffix) {
      return validateOn(OrderSearchKey.EposLabel, value, setError);
    } else {
      return validateOn(OrderSearchKey.CustomerOrderId1, value, setError);
    }
  }

  if (lengthWithoutWhitespace > 14) {
    return validateOn(OrderSearchKey.EposLabel, value, setError);
  }

  return validateOn(OrderSearchKey.OrderId, value, setError);
};
