import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import React, { useEffect, useRef, useState } from 'react';
import { uid } from 'react-uid';

import { Headline, Menu } from 'common/components/ContextMenu';
import { useBoolean } from 'common/hooks/useBoolean';
import { useClickOutside } from 'common/utils/hooks';
import { HeaderVisibleCheckBox } from './HeaderVisibleCheckBox';
import { useScrollContainerMinHeightBinding } from '../hooks/useScrollContainerMinHeightBinding';
import { TableHead } from '../head/TableHead';
import { ColumnConfig } from '../dtos/ColumnConfig';
import { Button } from '../../Button';

import styles from './tableOptions.module.css';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { defaultSimpleCol, defaultPressCol, defaultAdvanceCol } from 'order/orderSearch/components/table/defaultColumns';
interface Props {
  columns: ColumnConfig[];
  t: (id: string) => string;
  onSetTableConfiguration?: (config: Record<string, boolean>, columnHistory?: PersistColumn) => void;
  setMenuVisible?: (menuVisible: boolean) => void;
  searchType?: OrderSearchType;
  persistedColumn?: PersistColumn[];
}

const TableOptionsWrapper = (props: Props) => {
  const { onSetTableConfiguration, t } = props;
  const [menuVisible, setMenuVisible] = useBoolean(false);
  const [internalHeaders, setInternalHeaders] = useState<ColumnConfig[]>([]);

  const saveHeaders = () => {
    const { columns: old } = props;
    const config: Record<string, boolean> = {};
    const updatedList: ColumnConfig[] = [];
    const columnList = (list: ColumnConfig[]) => {
      const visibleHeader: ColumnConfig[] = internalHeaders.slice().filter(({ hidden }) => !hidden);
      for (let i = 0; i < list.length; i++) {
        let match = false;
        for (let j = 0; j < visibleHeader.length; j++) {
          if (list[i].name === visibleHeader[j].name) {
            match = true;
          }
        }
        // adding list[i] to updatedList if we don't find a match.
        if (!match) {
          updatedList.push(list[i]);
        }
      }
      return updatedList.unshift(...visibleHeader);
    };
    for (let i = 0; i < old.length; i++) {
      const configTitle = old[i].name;
      if (configTitle && internalHeaders[i]?.hidden !== undefined) {
        config[configTitle] = !!internalHeaders[i]?.hidden;
      }
      old[i].hidden = internalHeaders[i]?.hidden || false;
    }
    if (props.searchType === OrderSearchType.SIMPLE) {
      const list: ColumnConfig[] = defaultSimpleCol;
      columnList(list);
    } else if (props.searchType === OrderSearchType.PRESS) {
      const list: ColumnConfig[] = defaultPressCol;
      columnList(list);
    } else if (props.searchType === OrderSearchType.ADVANCED) {
      const list: ColumnConfig[] = defaultAdvanceCol;
      columnList(list);
    }
    const newSearchColumn = { currentType: props.searchType, columnConfig: updatedList };
    onSetTableConfiguration?.(config, newSearchColumn);
    setMenuVisible.off();
  };

  const headerCellRef: React.RefObject<HTMLTableHeaderCellElement> = useRef<HTMLTableHeaderCellElement>(null);
  const focusRef = useRef<HTMLDivElement>(null);

  useClickOutside([headerCellRef, focusRef], () => setMenuVisible.off());

  useScrollContainerMinHeightBinding(headerCellRef, focusRef, menuVisible);

  useEffect(() => {
    setInternalHeaders(props.columns.filter((x) => x.title).map((x) => Object.assign({}, x)));
    props.setMenuVisible?.(menuVisible);
  }, [menuVisible, props.columns]);

  return (
    <TableHead
      key={uid(props)}
      onClick={() => setMenuVisible.toggle()}
      ref={headerCellRef}
      interactive={true}
      className="fixedColumnHeader-20px"
      innerClassName={`${styles.positionUnset} align-center`}
      data-testid={'table-options-head'}
    >
      <DHLIcon icon="option" name="table-options" />
      <div className={`${styles.anchorContextmenu} dhlTableheaderCell-anchorContextmenu`}>
        <Menu isOpen={menuVisible} ref={focusRef} className={styles.contextMenu} onClick={(e) => e.stopPropagation()}>
          <Headline>{t('contextmenu.editColumns')}</Headline>
          <div className={styles.overflowScroll}>
            {internalHeaders.map((headProps, index) => (
              <div className="my-2 mx-3" key={index} data-testid={`table-options-checkbox-${headProps.name}`}>
                {/* eslint-disable-next-line react/prop-types */}
                <HeaderVisibleCheckBox
                  {...headProps}
                  onCheck={(checked: boolean) => {
                    headProps.hidden = checked;
                  }}
                />
              </div>
            ))}
          </div>
          <Button
            onClick={saveHeaders}
            label={t('options.saveButton')}
            variant="primary"
            sizing="sm"
            className="mt-2 mx-3"
            dataTestId={'table-options-submit'}
          />
        </Menu>
      </div>
    </TableHead>
  );
};

export const TableOptions = React.memo(TableOptionsWrapper);
