import React, { HTMLProps } from 'react';
import classNames from 'classnames';

import { reference } from '../helper/reference';

declare type HeadCellProps = HTMLProps<HTMLTableCellElement> & {
  interactive?: boolean;
  innerClassName?: string;
};

export const TableHead = reference(
  'TableHead',
  'th',
  ({ interactive }: HeadCellProps) => ['dhlTableheaderCell', { 'dhlTableheaderCell-interactable': interactive }],
  (props) => {
    const { interactive, innerClassName, children, ...other } = props;
    return {
      ...other,
      role: interactive ? 'button' : undefined,
      tabIndex: interactive ? 0 : undefined,
      children: <div className={classNames('dhlTableheaderCell-inner', innerClassName)}>{children}</div>
    };
  }
);
