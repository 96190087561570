import { getUser } from 'common/context/auth/AuthProvider';
import { BASE_URL } from 'common/utils/apiUtils';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { mapOrder } from 'order/common/context/order/utils/mapOrder';

interface BulkLoadSchema {
  orderId: string;
  includeDst?: boolean;
  includePrice?: boolean;
  includeNve?: boolean;
}

export const bulkLoad = async <T>(payload: BulkLoadSchema[], size: number): Promise<OrderResponse[] | null> => {
  try {
    const user = getUser();

    const bulkLoadResponse = await fetch(`${BASE_URL}orders/bulkload?size=${size}`, {
      method: 'POST',
      headers: {
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${user._accessToken}`,
        ...(user.extUserId && { 'X-ExtUserId': user.extUserId })
      },
      body: JSON.stringify({ orders: payload })
    });

    const loadedOrders = await bulkLoadResponse?.json();

    if (loadedOrders?.orders) {
      return loadedOrders?.orders?.map((order: OrderResponse) => mapOrder(order));
    }

    return null;
  } catch (e: any) {
    if (
      e.response &&
      e.response.status &&
      (e.response?.status === 408 || e.response?.code === 408 || e.response?.status === 504 || e.response?.code === 504)
    ) {
      return {
        ...e.response,
        timeoutError: true
      };
    }
    console.error('Failed getOrders request: ', e.response);
    return null;
  }
};
