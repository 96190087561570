import React, { useCallback, useEffect, useState } from 'react';
import { DHLButton, DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import { useAlerts } from 'common/AlertContext';
import { splitOrder } from 'order/common/services/OrderService';
import { useTranslation } from 'react-i18next';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { SplitOrder, SplitOrderContent } from '../SplitOrderContent/SplitOrderContent';
import { useFormContext, UseFormSetError, useWatch } from 'react-hook-form';
import { Order } from '../../../../common/context/order/dtos/Order';
import { Button } from '../../../../../common/components/Button';
import { SplitOrderProps } from '../../../../../generated';
import { dateToISODate } from '../../../../../common/utils/helpers';
import { useHistory } from 'react-router-dom';
import { AppPaths } from '../../../../../Main';
import { ShareType } from '../SplitOrderContent/SplitOrderContent';
import { ObjectContaining } from '../../../../../common/utils/formHelpers';
import { getOrder } from '../../../../common/context/order/utils/getOrder';
import { OrderResponse } from '../../../../common/context/order/dtos/GetOrder';
import { LoadingState } from '../../../../common/context/search/dtos/LoadingState';

interface SplitOrderModal {
  show: boolean;
  onCancel: () => void;
  order: Order;
}

export const SplitOrderModal = (props: SplitOrderModal): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'splitOrder' });
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const history = useHistory();
  const { order, setDirty, setOrder, setProductGroup, setOrderId, setLoadingState, setOrderCategoryProductKey, setOrderCategory, setOrderPrice } =
    useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);

  const backWrapperRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.querySelector('button')?.focus();
      }, 0);
    }
  }, []);

  const { control, trigger, setError, clearErrors } = useFormContext<SplitOrder>();
  const [pickupAddress, submitter, splitOrderDate, selectedDestination, selectedSource, productionPlantId, consignor, shareAfterType] = useWatch({
    control,
    name: [
      'pickupAddress',
      'submitter',
      'splitOrderDate',
      'selectedDestination',
      'selectedSource',
      'productionPlantId',
      'consignor',
      'shareAfterType'
    ]
  });

  /* istanbul ignore next */
  const loadNewOrder = async (id: string) => {
    const newOrder = await getOrder<OrderResponse>(id, { includeDst: true, includePrice: true });
    setLoadingState(LoadingState.LOADING);
    if (newOrder) {
      setOrderId(id);
      setOrder(newOrder);
      setProductGroup(newOrder.productGroup);
      setOrderCategory(newOrder.orderCategory);
      setOrderCategoryProductKey(newOrder.orderCategoryProductKey);
      setLoadingState(LoadingState.LOADED);
      setOrderPrice(newOrder?.orderPrice);
    }
  };

  const splitOrderProps = (): SplitOrderProps => {
    return {
      orderLastChangedOn: props.order.lastChangedOn ? props.order.lastChangedOn : '',
      consignmentInformation: {
        separationType: shareAfterType === ShareType.VARIANT ? 'VARIANT' : 'DESTINATION',
        ...(shareAfterType !== ShareType.VARIANT && {
          aggregationLevel: shareAfterType === ShareType.REGION ? 'ROUTING_REGION' : 'ROUTING_ZONE'
        }),
        ...(shareAfterType !== ShareType.VARIANT &&
          shareAfterType !== ShareType.ZONEANDREGION && {
            destinations: selectedDestination
              ? Array.from(selectedDestination).map((item) => (item.key === '0-9' ? '**' : item.key.length < 2 ? item.key + '*' : item.key))
              : []
          }),
        ...(shareAfterType !== ShareType.VARIANT &&
          shareAfterType === ShareType.ZONEANDREGION && {
            destinations: selectedDestination ? Array.from(selectedDestination).map((item) => (item.key === '0-9' ? '*' : item.key)) : []
          }),
        ...(shareAfterType === ShareType.VARIANT && {
          shipmentNumbers: props.order?.packagesPerVariant
            ?.map((item) => (Array.from(selectedDestination).find((f) => f.key === item.description) ? item.refShipmentId : ''))
            .filter((refShp) => refShp !== '')
        })
      },
      postingDate: dateToISODate(splitOrderDate ? new Date(splitOrderDate) : new Date()),
      mailerUcp: submitter,
      ...(consignor && {
        collectionInformation: {
          name: pickupAddress?.companyName ?? '',
          phone: '',
          fax: '',
          address: {
            street: pickupAddress?.street ?? '',
            streetNumber: pickupAddress?.streetNumber ?? '',
            city: pickupAddress?.city ?? '',
            postcode: pickupAddress?.postCode ?? '',
            countryCode: pickupAddress?.country ?? ''
          }
        }
      }),
      ...(consignor && { bulkMailAcceptanceOffice: productionPlantId || 'GrASt-13-3-01' }),
      orderID: order?.orderId || ''
    };
  };

  return (
    <DHLModal
      name="confirm"
      title={t`splitOrder`}
      content={<SplitOrderContent order={props.order} />}
      actionbar={
        <>
          <div ref={backWrapperRef}>
            <DHLButton
              name={'cancel-button'}
              label={t(`cancel`)}
              onClick={() => {
                props.onCancel();
              }}
              type={'default'}
            />
          </div>
          <Button
            variant={activeSpinner ? 'default' : 'primary'}
            dataTestId="split-order-button"
            onClick={async () => {
              clearErrors(['pickupAddress', 'submitter', 'productionPlantId', 'selectedSource', 'selectedDestination']);
              const fieldName: any = ['pickupAddress', 'submitter', 'productionPlantId'];
              const isContentValid = await trigger(fieldName);
              const error = setError as unknown as UseFormSetError<ObjectContaining<SplitOrder>>;
              let validates = true;
              /* istanbul ignore next */
              if (consignor && !pickupAddress && !productionPlantId) {
                validates = false;
                error(`productionPlantId`, { type: 'required', message: 'required' });
              }
              if (selectedSource.size == 0) {
                validates = false;
                error(`selectedSource`, { type: 'required', message: 'required' });
              }
              if (selectedDestination.size == 0) {
                validates = false;
                error(`selectedDestination`, { type: 'required', message: 'required' });
              }
              const splitProps = splitOrderProps();
              /* istanbul ignore next */
              if (isContentValid && validates)
                try {
                  setActiveSpinner(true);
                  deleteAlerts();
                  const { data } = await splitOrder(splitProps);
                  const dpResponse = data.response;
                  setActiveSpinner(false);
                  props.onCancel();
                  if (dpResponse?.resultStatus !== 'ERROR') {
                    setDirty(false);
                    addAlert({
                      type: AlertTypes.Success,
                      title: t('alerts.success'),
                      description: dpResponse.message
                    });
                    props.order?.orderId === dpResponse.remainingOrderNumber && (await loadNewOrder(props.order?.orderId));
                    props.order?.orderId !== dpResponse.remainingOrderNumber &&
                      history.push(`${AppPaths.orderViewPath}/${dpResponse.remainingOrderNumber}`);
                  } else {
                    dpResponse?.amErrors?.forEach((error) => {
                      addAlert({
                        type: AlertTypes.Error,
                        title: `${error.errorCategory}!`,
                        description: error.errorDescription
                      });
                    });
                  }
                } catch (e) {
                  console.error(e);
                  setActiveSpinner(false);
                  props.onCancel();
                  addAlert({
                    type: AlertTypes.Error,
                    title: `ERROR!`,
                    description: t('responseErrors')
                  });
                }
            }}
            type="button"
            label={t('splitOrder')}
            activeSpinner={activeSpinner}
            style={{ minWidth: '275px' }}
            // disabled={formState.errors}
          />
        </>
      }
      show={props.show}
      onClickClose={() => {
        props.onCancel();
      }}
    />
  );
};
