import { triggerPrepareForCollection } from './triggerPrepareForCollection';

export const prepareForCollection = async (
  orderNumbers: (string | undefined)[],
  inductionDate: string,
  productionPlantId: string
): Promise<unknown> => {
  try {
    const forCollectionRequests: any[] = [];
    orderNumbers.forEach((o) => {
      if (o) {
        forCollectionRequests.push(triggerPrepareForCollection(o, inductionDate, productionPlantId));
      }
    });
    await Promise.all(forCollectionRequests);
    return;
  } catch (e) {
    console.error('Prepare for collection requests failed', e);
    return;
  }
};
