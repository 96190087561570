import { sendRequest } from 'common/api-adapter';
import { getUser } from 'common/context/auth/AuthProvider';
import { BASE_URL } from 'common/utils/apiUtils';

export interface GetOrderPriceOptions {
  orderId: string;
  orderVersion: string;
  orderPriceKey: string;
}

export async function getOrderPrice<T>(parameters: GetOrderPriceOptions): Promise<T | null> {
  try {
    const user = getUser();
    const { data } = await sendRequest({
      method: 'POST',
      data: parameters as any,
      token: user._accessToken,
      extUserId: user.extUserId,
      url: `${BASE_URL}orders/price`
    });

    if (data) {
      return data;
    }

    return null;
  } catch (e: any) {
    if (
      e.response &&
      e.response.status &&
      (e.response?.status === 408 || e.response?.code === 408 || e.response?.status === 504 || e.response?.code === 504)
    ) {
      return {
        ...e.response,
        timeoutError: true
      };
    }
    console.error('Failed getOrderPrice request: ', e.response);
    return null;
  }
}
