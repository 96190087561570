import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import React, { Children, FC } from 'react';
import styles from './AsideInfoBox.module.css';

export type AsideInfoBoxProps = {
  title?: string;
  className?: string;
};

export const AsideInfoBox: FC<AsideInfoBoxProps> = ({ title, children, className }) => {
  return (
    <div className={styles.infoBox}>
      {!!title && (
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'} className={className}>
          {title}
        </DHLHeadline>
      )}
      <ul className={styles.list}>
        {Children.map(children, (child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    </div>
  );
};
