import React, { useEffect, useState } from 'react';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { FormRow } from 'common/components/FormRow';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'i18n';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { InternationalDestinationSectionSchema, InternationalDestinationZipCodes } from '../../schema/internationalClusterSchema';
import { SFCProps } from '../../../../../common/utils/formHelpers';
import { useOrderContext } from '../../../../common/context/order/OrderContext';
import { Tooltip } from '../../../../../common/components/Tooltip/Tooltip';
import classes from '../../../common/components/molecule/DestinationSection/destination-section.module.css';
import { PalletsOverviewAccordian } from '../../../../orderView/components/OrderView/PalletsOverview/PalletsOverviewAccordian/PalletsOverviewAccordian';
import { OrderOverviewWidget } from '../../../common/components/molecule/OrderOverview';
import { DestinationSchema } from '../../../common/components/atom/AMDHLDestinationInput';

export type InternationalDestinationSectionProps<T extends FieldValues> = SFCProps<T, InternationalDestinationSectionSchema> & {
  showEcomony?: boolean;
  showMixed?: boolean;
  disabled?: boolean;
  totalNetWeight: number;
};

export const InternationalDestinationSection = <T extends FieldValues>({
  name,
  showEcomony,
  showMixed,
  disabled,
  totalNetWeight
}: InternationalDestinationSectionProps<T>) => {
  const { t } = useTranslation('orderCreate');
  const { setValue, control, trigger } = useFormContext();
  const { upsertMetaData, meta } = useOrderContext();
  const [priority, economy, mixed, totalWeight, packagingQuantity, netWeight, destinationMatrix] = useWatch({
    control,
    name: [`${name}.priority`, `${name}.economy`, `${name}.mixed`, `${name}.totalWeight`, 'packaging.quantity', `${name}.netWeight`, `${name}.matrix`]
  });

  const [priorityError, setPriorityError] = useState('');
  const [economyError, setEconomyError] = useState('');
  const [mixedError, setMixedError] = useState('');
  const [totalWeightError, setTotalWeightError] = useState('');

  useEffect(() => {
    if (meta?.netWeightChanged) {
      const netWeight = totalNetWeight ? parseFloat((totalNetWeight / ((priority || 0) + (economy || 0) + (mixed || 0))).toFixed(3)) : 0;
      setValue(`${name}.netWeight`, totalNetWeight as any);
      setPalletOverViewNetWeight(netWeight);
    }
  }, [priority, economy, mixed, totalNetWeight]);

  const setPalletOverViewNetWeight = (netWeight: number) => {
    let matrix = (destinationMatrix as DestinationSchema[]) || [];
    if (priority) {
      const filteredMat = matrix?.filter(({ zipCode: z }) => z === InternationalDestinationZipCodes.PRIORTY);
      const unFilteredMat = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.PRIORTY);
      const chFilterMap = filteredMat?.length
        ? filteredMat.map((val) => {
            return {
              ...val,
              quantity: priority,
              packaging: Array(priority).fill({ totalNetWeightValue: netWeight })
            };
          })
        : [
            {
              zipCode: InternationalDestinationZipCodes.PRIORTY,
              quantity: priority,
              packaging: Array(priority).fill({ totalNetWeightValue: netWeight })
            }
          ];
      matrix = [...unFilteredMat, ...chFilterMap];
    } else {
      matrix = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.PRIORTY);
    }
    if (economy) {
      const filteredMat = matrix?.filter(({ zipCode: z }) => z === InternationalDestinationZipCodes.ECONOMY);
      const unFilteredMat = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.ECONOMY);
      const chFilterMap = filteredMat?.length
        ? filteredMat.map((val) => {
            return {
              ...val,
              quantity: economy,
              packaging: Array(economy).fill({ totalNetWeightValue: netWeight })
            };
          })
        : [
            {
              zipCode: InternationalDestinationZipCodes.ECONOMY,
              quantity: economy,
              packaging: Array(economy).fill({ totalNetWeightValue: netWeight })
            }
          ];
      matrix = [...unFilteredMat, ...chFilterMap];
    } else {
      matrix = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.ECONOMY);
    }
    if (mixed) {
      const filteredMat = matrix?.filter(({ zipCode: z }) => z === InternationalDestinationZipCodes.MIXED);
      const unFilteredMat = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.MIXED);
      const chFilterMap = filteredMat?.length
        ? filteredMat.map((val) => {
            return {
              ...val,
              quantity: mixed,
              packaging: Array(mixed).fill({ totalNetWeightValue: netWeight })
            };
          })
        : [{ zipCode: InternationalDestinationZipCodes.MIXED, quantity: mixed, packaging: Array(mixed).fill({ totalNetWeightValue: netWeight }) }];
      matrix = [...unFilteredMat, ...chFilterMap];
    } else {
      matrix = matrix?.filter(({ zipCode: z }) => z !== InternationalDestinationZipCodes.MIXED);
    }
    setValue(`${name}.matrix`, matrix as any);
  };

  useEffect(() => {
    upsertMetaData('internationalPackagingSum', (priority ? priority : 0) + (economy ? economy : 0) + (mixed ? mixed : 0));
  }, [priority, economy, mixed]);

  useEffect(() => {
    const quantity = packagingQuantity || 0;
    quantity > 0 && totalWeight && totalWeight / quantity > 700
      ? setTotalWeightError(t(`palletsInternational.error.totalWeight`))
      : setTotalWeightError('');
  }, [packagingQuantity]);

  const priorityInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setPriorityError(t('error.min'));
      setValue(`${name}.priority`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setPriorityError('');
      setValue(`${name}.priority`, 0 as any);
    } else {
      setPriorityError('');
      setValue(`${name}.priority`, e?.target?.valueAsNumber as any);
    }
    upsertMetaData('netWeightChanged', true);
  };
  const economyInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setEconomyError(t('error.min'));
      setValue(`${name}.economy`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setEconomyError('');
      setValue(`${name}.economy`, 0 as any);
    } else {
      setEconomyError('');
      setValue(`${name}.economy`, e?.target?.valueAsNumber as any);
    }
    upsertMetaData('netWeightChanged', true);
  };
  const mixedInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setMixedError(t('error.min'));
      setValue(`${name}.mixed`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setMixedError('');
      setValue(`${name}.mixed`, 0 as any);
    } else {
      setMixedError('');
      setValue(`${name}.mixed`, e?.target?.valueAsNumber as any);
    }
    upsertMetaData('netWeightChanged', true);
  };

  const totalWeightInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(`${name}.totalWeight`, (e?.target?.valueAsNumber as any) ?? 0);
    trigger(`${name}.totalWeight`);
    const totalWeight = e?.target?.valueAsNumber || 0;
    const quantity = packagingQuantity || 0;
    quantity > 0 && totalWeight / quantity > 700 ? setTotalWeightError(t(`palletsInternational.error.totalWeight`)) : setTotalWeightError('');
  };

  const netWeightInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(`${name}.netWeight`, (e?.target?.valueAsNumber as any) ?? 0);
    const netWeight = e?.target?.valueAsNumber
      ? parseFloat((e?.target?.valueAsNumber / ((priority || 0) + (economy || 0) + (mixed || 0))).toFixed(3))
      : 0;
    setPalletOverViewNetWeight(netWeight);
    trigger(`${name}.netWeight`);
  };
  return (
    <>
      <OrderStepSection headline={t('palletsInternational.palletsPerIPZ')}>
        <FormRow mode="two-col">
          <CustomTextInput
            label={t('palletsInternational.priority')}
            defaultValue={priority}
            onBlur={priorityInputHandler}
            testid={`priority`}
            error={priorityError}
            disabled={disabled}
            name={`${name}.priority`}
            type="number"
          />
        </FormRow>
        {showEcomony && (
          <FormRow mode="two-col">
            <CustomTextInput
              label={t('palletsInternational.economy')}
              defaultValue={economy}
              onBlur={economyInputHandler}
              testid={`economy`}
              error={economyError}
              disabled={disabled}
              name={`${name}.economy`}
              type="number"
            />
          </FormRow>
        )}
        {showMixed && (
          <FormRow mode="two-col">
            <CustomTextInput
              label={t('palletsInternational.mixed')}
              defaultValue={mixed}
              onBlur={mixedInputHandler}
              testid={`mixed`}
              error={mixedError}
              disabled={disabled}
              name={`${name}.mixed`}
              type="number"
            />
          </FormRow>
        )}
      </OrderStepSection>
      <OrderStepSection headline={t('palletsInternational.weightOfPallets')}>
        <FormRow mode="two-col">
          <CustomTextInput
            name={`${name}.netWeight`}
            label={t('palletsInternational.netWeightLabel')}
            testid={`netWeight`}
            disabled={disabled}
            defaultValue={netWeight}
            onBlur={netWeightInputHandler}
            type="number"
          />
          <div>
            <Tooltip content={t('palletsInternational.netWeightTooltip')}>{<i className={`${classes.tooltip} icon-alert-info`} />}</Tooltip>
          </div>
        </FormRow>
        <FormRow mode="two-col">
          <CustomTextInput
            name={`${name}.totalWeight`}
            label={t('palletsInternational.totalWeightLabel')}
            testid={`totalWeight`}
            disabled={disabled}
            defaultValue={totalWeight}
            onBlur={totalWeightInputHandler}
            type="number"
            error={totalWeightError}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              name={`${name}.stackable`}
              render={({ field: { ref, ...field } }) => (
                <DHLCheckbox
                  disabled={disabled}
                  label={t('palletsInternational.stackable')}
                  {...field}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
          </div>
        </FormRow>
        <OrderOverviewWidget width={3}>
          <div style={{ borderTop: '1px solid #e6e6e6', marginBottom: -20 }}>
            <PalletsOverviewAccordian destinations={destinationMatrix || []} id={`IpPalletsTable`} />
          </div>
        </OrderOverviewWidget>
      </OrderStepSection>
    </>
  );
};
