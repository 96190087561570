import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Controller, FieldValues, UseControllerProps, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';

import { FormRow } from 'common/components/FormRow';
import { PathToType } from 'common/utils/formHelpers';
import { CustomerInfo } from 'order/productGroups/common/components/atom/CustomerInfo/CustomerInfo';
import { CustomerInput } from 'order/productGroups/common/components/atom/CustomerInput';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { PaymentBankInfo } from '../../atom/PaymentBankInfo/PaymentBankInfo';
import { checkboxConfig } from './checkboxConfig';
import { usePaymentBankInfo } from 'order/productGroups/common/services/usePaymentBankInfo';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { CustomerInfoWithAddressTO } from 'generated';

import styles from './customerSection.module.css';
import { useCustomerRecommendations } from 'order/productGroups/common/services/useCustomerRecommendations';

export type tooltip = {
  name: string;
  description?: string;
};

export type CustomerSectionProps<
  T extends FieldValues,
  TPath extends PathToType<T, string | undefined> = PathToType<T, string | undefined>
> = PropsWithChildren<{
  inputName: TPath;
  disabled?: boolean;
  headline?: string;
  customerRole: OrderSearchKey.Originator | OrderSearchKey.Submitter | OrderSearchKey.Payer;
  rules?: UseControllerProps<T, TPath>['rules'];
  checkboxConfig?: checkboxConfig;
  tooltip?: tooltip;
  placeholder?: string;
  errorMessage?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  infoPosition?: 'one-col' | 'two-col';
  withAddress?: boolean;
}>;

export const CustomerSection = <T extends FieldValues>(props: CustomerSectionProps<T>): ReactElement => {
  const { resetField } = useFormContext<Record<string, string>>();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const customerId = useWatch({
    name: props.inputName
  });

  const { paymentBankInfo } = usePaymentBankInfo(
    customerId
    /* is used with UCP only here -> validityDate for getting paymentInfo is not necessary here */
  );
  const { add } = useCustomerRecommendations(props.customerRole);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  useEffect(() => {
    if (customerInfo?.customerId) {
      add({
        customerId: customerInfo.customerId,
        name: customerInfo.name
      });
    } else if (paymentBankInfo?.settlementNumber) {
      add({
        customerId: paymentBankInfo.settlementNumber,
        name: paymentBankInfo.companyName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  const inputDisabled = false;

  return (
    <OrderStepSection headline={props.headline}>
      <FormRow mode={props.infoPosition ? props.infoPosition : 'two-col'}>
        <div>
          <CustomerInput
            placeholder={props.placeholder || `${oc('customerNumber')}`}
            name={props.inputName}
            onDelete={() => {
              resetField(props.inputName);
            }}
            customerRole={props.customerRole}
            disabled={props.disabled}
            rules={props.rules}
            errorMessage={props.errorMessage}
          />
          {props.children}
        </div>
        <div data-testid={`customer-section-aside-${props.inputName}`}>
          {customerId &&
            (props.customerRole === OrderSearchKey.Payer ? (
              <PaymentBankInfo payer={customerId} withLabel={false} withCustomerInfo={props.withAddress} />
            ) : (
              <CustomerInfo customerId={customerId} />
            ))}
        </div>
      </FormRow>
      {props.checkboxConfig && (
        <>
          <span style={{ padding: '9px' }} />
          <FormRow mode="checkboxes">
            <Controller
              name={props.checkboxConfig?.controlName || ''}
              render={() => (
                <DHLCheckbox
                  name={props.checkboxConfig?.name}
                  label={props.checkboxConfig?.label}
                  value={props.checkboxConfig?.value}
                  onChange={props.checkboxConfig?.onChange}
                  disabled={inputDisabled || props.checkboxConfig?.disabled}
                />
              )}
            />
            {props.tooltip && (
              <>
                <Tooltip placement={props.placement ? props.placement : 'top'} content={props.tooltip.description}>
                  <i className={classNames(styles.infoForTooltip, 'icon-alert-info')}></i>
                </Tooltip>
              </>
            )}
          </FormRow>
        </>
      )}
    </OrderStepSection>
  );
};
