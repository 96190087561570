// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.palletOverview_tableHead__5NThc {
  background: #e6e6e6;
}

.palletOverview_tableBody__P2Oql {
  background: #f5f5f5;
}

.palletOverview_actionBar__jNYnQ {
  background: #f5f5f5;
  border-top: 1px solid darkgrey;
}

.palletOverview_connectionsText__dudiI {
  margin: 10px 0 5px 0;
}

.palletOverview_palletsOverviewAccod__ZdDQs {
  padding: 20px 10px 20px 0px;
  /* margin: 20px 0 0; */
  /* border-top: 1px solid #e6e6e6; */
  /* border-bottom: 1px solid #e6e6e6; */
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/OrderView/PalletsOverview/palletOverview.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,mCAAmC;EACnC,sCAAsC;AACxC","sourcesContent":[".tableHead {\n  background: #e6e6e6;\n}\n\n.tableBody {\n  background: #f5f5f5;\n}\n\n.actionBar {\n  background: #f5f5f5;\n  border-top: 1px solid darkgrey;\n}\n\n.connectionsText {\n  margin: 10px 0 5px 0;\n}\n\n.palletsOverviewAccod {\n  padding: 20px 10px 20px 0px;\n  /* margin: 20px 0 0; */\n  /* border-top: 1px solid #e6e6e6; */\n  /* border-bottom: 1px solid #e6e6e6; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": `palletOverview_tableHead__5NThc`,
	"tableBody": `palletOverview_tableBody__P2Oql`,
	"actionBar": `palletOverview_actionBar__jNYnQ`,
	"connectionsText": `palletOverview_connectionsText__dudiI`,
	"palletsOverviewAccod": `palletOverview_palletsOverviewAccod__ZdDQs`
};
export default ___CSS_LOADER_EXPORT___;
