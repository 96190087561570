// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-select-searchable_contextMenu__irRuj {
  flex-direction: column;
  left: 0;
  right: 0;
  padding: 0 0 4px;
}

.input-select-searchable_contextMenu__irRuj.dhlContextmenu-visible {
  display: flex;
}

.input-select-searchable_contextMenu__irRuj .dhlContextmenu-headline {
  margin: 0 10px 10px;
}

.input-select-searchable_searchRow__ubUBd {
  margin-bottom: 10px;
  padding: 10px 10px 0;
}

.input-select-searchable_searchInput__DcCQp {
  flex-grow: 1;
}

.input-select-searchable_searchInput__DcCQp input {
  width: 100%;
}

.input-select-searchable_item__Nn7oY {
  white-space: pre-wrap;
  width: 100%;
  cursor: pointer;
  margin: 0;
  text-align: start;
}

.input-select-searchable_item__Nn7oY.active {
  background: #F5F5F5;
}

.input-select-searchable_deleteButton__gUc5J {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: inline-block;
  margin: -4px 0;
  text-align: center;
}

.input-select-searchable_deleteButton__gUc5J svg {
  width: 10px;
  height: 10px;
}

.input-select-searchable_deleteButton__gUc5J polygon {
  fill: rgba(50, 50, 50, 0.53);
}

.input-select-searchable_deleteButton__gUc5J:hover polygon {
  fill: #D40511;
}

.input-select-searchable_listBox__AA5By {
  overflow-y: auto;
  position: relative;
}

.input-select-searchable_loadingWrapper__G1YyQ {
  display: grid;
  place-items: center;
  padding: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/InputSelectSearchable/input-select-searchable.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,OAAO;EACP,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".contextMenu {\n  flex-direction: column;\n  left: 0;\n  right: 0;\n  padding: 0 0 4px;\n}\n\n.contextMenu:global(.dhlContextmenu-visible) {\n  display: flex;\n}\n\n.contextMenu :global(.dhlContextmenu-headline) {\n  margin: 0 10px 10px;\n}\n\n.searchRow {\n  margin-bottom: 10px;\n  padding: 10px 10px 0;\n}\n\n.searchInput {\n  flex-grow: 1;\n}\n\n.searchInput input {\n  width: 100%;\n}\n\n.item {\n  white-space: pre-wrap;\n  width: 100%;\n  cursor: pointer;\n  margin: 0;\n  text-align: start;\n}\n\n.item:global(.active) {\n  background: #F5F5F5;\n}\n\n.deleteButton {\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n  display: inline-block;\n  margin: -4px 0;\n  text-align: center;\n}\n\n.deleteButton svg {\n  width: 10px;\n  height: 10px;\n}\n\n.deleteButton polygon {\n  fill: rgba(50, 50, 50, 0.53);\n}\n\n.deleteButton:hover polygon {\n  fill: #D40511;\n}\n\n.listBox {\n  overflow-y: auto;\n  position: relative;\n}\n\n.loadingWrapper {\n  display: grid;\n  place-items: center;\n  padding: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenu": `input-select-searchable_contextMenu__irRuj`,
	"searchRow": `input-select-searchable_searchRow__ubUBd`,
	"searchInput": `input-select-searchable_searchInput__DcCQp`,
	"item": `input-select-searchable_item__Nn7oY`,
	"deleteButton": `input-select-searchable_deleteButton__gUc5J`,
	"listBox": `input-select-searchable_listBox__AA5By`,
	"loadingWrapper": `input-select-searchable_loadingWrapper__G1YyQ`
};
export default ___CSS_LOADER_EXPORT___;
