import { useRecommendations as useRec, UseRecommendationsReturn as Return } from 'common/hooks/useRecommendations';
import { isJournalItemArray, JournalItem } from 'order/common/dtos/JournalItem';
import { requestUserRecommendations, sendUserRecommendations } from 'common/services/recommendations';

export type JournalRecommendation = JournalItem;

export type UseRecommendationsParams = {
  key?: string;
};

export type UseRecommendationsReturn = Return<JournalRecommendation>;

export const useRecommendations = ({ key = 'am.portal.pressDist.zkz' }: UseRecommendationsParams = {}): UseRecommendationsReturn =>
  useRec<JournalRecommendation>({
    key,
    get: async () => {
      const array = await requestUserRecommendations<JournalRecommendation>(key);
      const result = isJournalItemArray(array);
      return result ? array : [];
    },
    set: (recommendations) => {
      sendUserRecommendations(key, JSON.stringify(recommendations));
    },
    compare: ({ registrationNumber: a }, { registrationNumber: b }) => a === b
  });
