import { useRecommendations, UseRecommendationsReturn } from 'common/hooks/useRecommendations';
import { requestUserRecommendations, sendUserRecommendations } from 'common/services/recommendations';
import { CustomerRole } from 'order/productGroups/common/components/atom/CustomerInput';
import { DefaultAttribute } from 'order/orderSearch/services/orderSearchAttribute';

export type CustomerRecommendation = {
  customerId: string;
  name?: string;
};
export const useCustomerRecommendations = (key: CustomerRole): UseRecommendationsReturn<CustomerRecommendation> => {
  const keyValue = key + '-Recommendations';

  const internalReturn = useRecommendations<DefaultAttribute>({
    key: keyValue,
    get: async () => {
      return await requestUserRecommendations<DefaultAttribute>(keyValue);
    },
    set: (values) => {
      sendUserRecommendations(keyValue, JSON.stringify(values));
    },
    compare: (a, b) => a.value === b.value
  });

  const toRec = (customer: CustomerRecommendation): DefaultAttribute => ({ key, value: customer.customerId, meta: customer.name });
  const fromRec = (c: DefaultAttribute): CustomerRecommendation => ({ customerId: c.value, name: c.meta });

  return {
    add: (customer) => internalReturn.add(toRec(customer)),
    load: () => internalReturn.load().then((x) => x.map(fromRec)),
    delete: (customer) => internalReturn.delete(toRec(customer)),
    recommendations: internalReturn.recommendations.map(fromRec).reverse()
  };
};
