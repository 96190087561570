import React, { useEffect, VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useTransformOrder } from 'order/common/hooks/useOrderTransformation';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useSearchParams } from 'order/common/hooks/useSearchParams';
import { CopyOrderSchema } from 'order/orderCopy/schema/copyOrderSchema';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { useTranslation } from 'i18n';
import { OrderCopyStep1 } from '../OrderCopyStep1/OrderCopyStep1';
import { PostageType } from '../../../../common/dtos/PostageType';
import { ErrorMessageContainer } from 'order/productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';
import { PressStep2 } from 'order/productGroups/press/workFlowSteps/step2/PressStep2';
import { PressStep3 } from 'order/productGroups/press/workFlowSteps/step3/PressStep3';
import { PressStep4 } from 'order/productGroups/press/workFlowSteps/step4/PressStep4';
import { LetterStep2 } from 'order/productGroups/letter/workFlowSteps/Step2/LetterStep2';
import { LetterStep3 } from 'order/productGroups/letter/workFlowSteps/Step3/LetterStep3';
import { LetterStep4 } from 'order/productGroups/letter/workFlowSteps/Step4/LetterStep4';
import { DialogMarketingStep2 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2';
import { DialogMarketingStep3 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step3/DialogMarketingStep3';
import { DialogMarketingStep4 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step4/DialogMarketingStep4';
import { InternationalClusterStep2 } from 'order/productGroups/international/workFlowSteps/Step2/InternationalClusterStep2';
import { InternationalClusterStep3 } from 'order/productGroups/international/workFlowSteps/Step3/InternationalClusterStep3';
import { InternationalClusterStep4 } from 'order/productGroups/international/workFlowSteps/Step4/InternationalClusterStep4';

interface Props {
  orderData: OrderResponse | undefined;
}

export const OrderCopySteps: VFC<Props> = ({ orderData }) => {
  const { reset } = useFormContext<CopyOrderSchema>();
  const [orderType] = useSearchParams('orderType');
  const { t: oc } = useTranslation('orderCreate');
  const { data, isLoading } = useTransformOrder(orderData as any, {
    copy: true,
    includeDst: orderType !== 'ZA'
  });
  const { step } = useOrderStep();

  useEffect(() => {
    reset(data);
  }, [isLoading]);

  if (isLoading) return <ActivityIndicator testId={'activity-indicator-copy'} message={oc('notifications.loadingOrder')} />;

  const productGroup = orderData?.productGroup;
  const orderCategoryProductKey = orderData?.orderCategoryProductKey;

  if (!isLoading && data?.postageType === PostageType.DV) {
    return <ErrorMessageContainer message={oc('errors.canNotCopy')} dataTestId={'error-specific-order-can-not-be-copied'} />;
  }

  if (step === 0) return <OrderCopyStep1 orderCategoryProductKey={orderCategoryProductKey} />;

  if (!isLoading && (productGroup === ProductGroup.DIALOGPOST || productGroup === ProductGroup.DIALOGPOSTWURFSPEZIAL)) {
    const Component = [DialogMarketingStep2, DialogMarketingStep3, DialogMarketingStep4][step - 1];
    return <Component key={`step${step - 1}`} />;
  } else if (productGroup === ProductGroup.PRESS_DISTRIBUTION) {
    const Component = [PressStep2, PressStep3, PressStep4][step - 1];
    return <Component key={`step${step - 1}`} />;
  } else if (productGroup === ProductGroup.BRIEF) {
    const Component = [LetterStep2, LetterStep3, LetterStep4][step - 1];
    return <Component key={`step${step - 1}`} />;
  } else if (
    !isLoading &&
    (productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
      productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
      productGroup === ProductGroup.PRESS_INTERNATIONAL)
  ) {
    const International = [InternationalClusterStep2, InternationalClusterStep3, InternationalClusterStep4][step - 1];
    return <International />;
  }
  return <ErrorMessageContainer message={oc('errors.canNotCopy')} dataTestId={'error-specific-order-can-not-be-copied'} />;
};
