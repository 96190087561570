import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { useMemo } from 'react';
import useSWR from 'swr/immutable';
import { ProductGroup } from '../dtos/ProductGroup';
import { requestBaseProductsForGroup } from 'order/productGroups/common/services/ProductService';
import { Product } from '../context/order/dtos/Product';

// TODO: replace useBaseProducts with this implementation

export type UseBaseProductsReturn = {
  baseProducts: Product[];
  isLoading: boolean;
};

export const useBaseProductsX = (
  productGroup?: string,
  date = new Date(),
  serviceType: string = 'POSTING',
  // isPartialServiceCapable is three state: undefined, true, false ! Default value is undefined
  //  if it is true or false, then the search is limited to some products only !
  isPartialServiceCapable: boolean | undefined = productGroup === ProductGroup.BRIEF ? true : undefined
): UseBaseProductsReturn => {
  const { data: products = [], isValidating } = useSWR(productGroup?.length ? ['productGroup', productGroup] : null, async (_, group) =>
    requestBaseProductsForGroup({ productGroup: group, serviceType, isPartialServiceCapable }).catch(() => [] as Product[])
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);

  return {
    baseProducts: filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber)),
    isLoading: isValidating
  };
};
