// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-copy_container__ItnjQ {
  max-width: 1400px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto 3rem;
}
.order-copy_center__vDQfb{
  margin:0 auto;
}`, "",{"version":3,"sources":["webpack://./src/order/orderCopy/common/components/OrderCopy/order-copy.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  max-width: 1400px;\n  width: 100%;\n  padding: 0 32px;\n  margin: 0 auto 3rem;\n}\n.center{\n  margin:0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `order-copy_container__ItnjQ`,
	"center": `order-copy_center__vDQfb`
};
export default ___CSS_LOADER_EXPORT___;
