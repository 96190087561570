import { DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { PostageType } from 'order/common/dtos/PostageType';
import { validPostageMachineCode } from 'order/productGroups/common/utils/validation';
import React, { VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type PostageInfoSectionProps = {
  type?: PostageType.AFM | PostageType.AS;
};

export const PostageInfoSection: VFC<PostageInfoSectionProps> = ({ type }) => {
  const { t } = useTranslation('orderCreate', { keyPrefix: 'postage.additionalInformation' });
  const { setError, clearErrors } = useFormContext();

  if (!type) {
    return <></>;
  }

  const errorKey = type === PostageType.AS ? 'error.postageAmountValue' : 'error.postageMachineCode';
  const labelKey = type === PostageType.AFM ? 'postageMachineCode' : 'postageAmountValue';
  const name = type === PostageType.AFM ? 'postageMachineCode' : 'postageAmountValue';
  return (
    <FormRow mode="two-col">
      <Controller
        name={name}
        rules={{
          validate: (value) => {
            if (typeof value !== 'string' || value.trim() === '') {
              return true;
            }

            const validationResult = validPostageMachineCode(value);
            if (validationResult !== true) {
              setError(name, {
                type: 'manual',
                message: t(validationResult) || t(errorKey)
              });
              return t(validationResult) || t(errorKey);
            }
            clearErrors(name);
            return true;
          }
        }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <DHLTextInput
            {...field}
            type="text"
            maxLength={10}
            placeholder={t(labelKey)}
            label={t(labelKey)}
            functionIcon={field.value ? 'close-thin' : undefined}
            onFunctionClick={() => field.onChange('')}
            error={fieldState.error ? fieldState.error.message : undefined}
          />
        )}
      />
    </FormRow>
  );
};
