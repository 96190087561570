// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.destination-detail-section_amTable__85Tu7 {
  font-size: 14px;
}

.destination-detail-section_amTable__85Tu7 td {
  height: 40px;
  background: transparent;
}

.destination-detail-section_amTable__85Tu7 tbody tr {
  overflow: visible;
  position: relative;
  border-bottom: 1px solid #323232;
  border-top: 1px solid #323232;
}

.destination-detail-section_productInfoTable__m\\+SrI tr td p:first-child {
  background: #f5f5f5;
}

.destination-detail-section_productInfoTable__m\\+SrI tr td p {
  padding-left: 10px;
}

.destination-detail-section_productInfoTable__m\\+SrI tr td {
  padding: 0;
}

.destination-detail-section_productInfoTableHead__S2Oft tr th:first-child {
  width: 134px;
  padding-left: 10px;
}
.destination-detail-section_productInfoTableHead__S2Oft tr th {
  vertical-align: top;
}
.destination-detail-section_slnTable__8TXkZ caption {
  background: #e6e6e6;
  width: 100%;
  padding: 15px 10px 24px 10px;
  caption-side: top;
  font-weight: 500;
  font-size: 14px;
  color: #323232;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Overview/DestinationDetailSection/destination-detail-section.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,4BAA4B;EAC5B,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".amTable {\n  font-size: 14px;\n}\n\n.amTable td {\n  height: 40px;\n  background: transparent;\n}\n\n.amTable tbody tr {\n  overflow: visible;\n  position: relative;\n  border-bottom: 1px solid #323232;\n  border-top: 1px solid #323232;\n}\n\n.productInfoTable tr td p:first-child {\n  background: #f5f5f5;\n}\n\n.productInfoTable tr td p {\n  padding-left: 10px;\n}\n\n.productInfoTable tr td {\n  padding: 0;\n}\n\n.productInfoTableHead tr th:first-child {\n  width: 134px;\n  padding-left: 10px;\n}\n.productInfoTableHead tr th {\n  vertical-align: top;\n}\n.slnTable caption {\n  background: #e6e6e6;\n  width: 100%;\n  padding: 15px 10px 24px 10px;\n  caption-side: top;\n  font-weight: 500;\n  font-size: 14px;\n  color: #323232;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amTable": `destination-detail-section_amTable__85Tu7`,
	"productInfoTable": `destination-detail-section_productInfoTable__m+SrI`,
	"productInfoTableHead": `destination-detail-section_productInfoTableHead__S2Oft`,
	"slnTable": `destination-detail-section_slnTable__8TXkZ`
};
export default ___CSS_LOADER_EXPORT___;
