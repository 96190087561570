import { DeliveryOption } from 'common/dtos/DeliveryOption';
import { ObjectContaining } from 'common/utils/formHelpers';
import { CreateLetterOrder, OrderCustomer, OrderCustomerRoleEnum } from 'generated';
import { BaseProduct, DependentProduct, Destination, LetterBaseProduct, OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { isValidDate } from 'order/common/utils/isValidDate';
import { OtherPartnerSchema } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { TimeCitySchema } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { UseFormSetError } from 'react-hook-form';
import { calculateRanges } from 'order/common/utils/processIdRanges';
import { PackagingSchema } from 'order/productGroups/common/components/molecule/PackagingSection';
import { ProductionState } from '../../../common/context/order/dtos/ProductionState';
import { SubProductsSchema } from 'order/productGroups/dialogMarketing/components/SubProductsSection/subProductsSchema';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { validateSettlementNumber } from '../../../../common/utils/helpers';

export type LetterOrderCreate = {
  productGroup: ProductGroup.BRIEF | string;
  postageMachineCode: string;
  orderLabel?: string;
  originator: string;
  productionState?: ProductionState;
  submitter: string;
  takeOriginatorAsSubmitter?: boolean;
  contactForQuestions?: ContactSchema;
  details: TimeCitySchema;
  otherPartners?: OtherPartnerSchema[];
  baseProduct: BaseProduct[];
  letterBaseProducts: LetterBaseProduct[];
  subProducts: SubProductsSchema;
  orderId?: string;
  deliveryOption?: string;
  additionalService?: string;
  customerOrderId?: string;
  orderType?: string;
  postingReceiptNumber?: string;
  packaging: PackagingSchema;

  payer?: string;
  paymentClearingNumber?: string;
  postageAmountValue?: number;
};

export const validateLetterOrderCreate = (
  values: LetterOrderCreate | undefined,
  setError: UseFormSetError<LetterOrderCreate>,
  step: number = 2,
  isDVBrief: boolean = false
): boolean => {
  let validates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<LetterOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;

  if (step === 2) {
    if (!isDVBrief && !values?.originator) {
      validates = false;
      error(`originator`, { type: 'required', message: 'required' });
    }
    // if (isDVBrief) {
    //   if (!values?.packaging?.quantity) {
    //     validates = false;
    //     error(`packaging.quantity`, { message: 'notMatching' });
    //   }
    //   if (values?.packaging?.type) {
    //     if (!values?.packaging?.quantity) {
    //       validates = false;
    //       error(`packaging.quantity`, { message: 'required' });
    //     }
    //   } else {
    //     if (values?.packaging?.quantity) {
    //       validates = false;
    //       error(`packaging.type`, { message: 'required' });
    //     } else {
    //       validates = true;
    //       error(`packaging.quantity`, { message: '' });
    //     }
    //   }
    // }
    if (values?.otherPartners && values?.otherPartners?.length) {
      values?.otherPartners?.map((p, i) => {
        if (p.role && !p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        } else if (!p.role && p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].role`, { type: 'required', message: 'error.partnerRole' });
        } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        }
      });
    }
    if (!values?.submitter) {
      validates = false;
      error(`submitter`, { type: 'required', message: 'required' });
    }

    if (!values?.details?.date) {
      validates = false;
      error(`details.date`, { type: 'required', message: 'required' });
    }

    if (!values?.details?.productionPlantId) {
      validates = false;
      error(`details.productionPlantId`, { type: 'required', message: 'required' });
    }
  }

  if (step === 3) {
    if (!values?.payer) {
      validates = false;
      error(`payer`, { type: 'required', message: 'required' });
    } else if (!isDVBrief && values?.payer.length && !customerIdRegex.test(values.payer)) {
      validates = false;
      error(`payer`, { type: 'required', message: 'required' });
    }
    if (values?.payer && !validateSettlementNumber(values?.payer)) {
      validates = false;
      error(`payer`, { type: 'error.required', message: 'payer.bankInformation.error.abrechnungsValidation' });
    }
  }

  return validates;
};

export const translateLetterOrder = (
  value: LetterOrderCreate,
  orderId: string | undefined,
  origOrder: OrderResponse | undefined,
  orderMode?: OrderMode
): CreateLetterOrder & { productionState?: ProductionState } => {
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: value.submitter.toString().replace(/ /g, ''),
      contact: value.contactForQuestions ? value.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Originator,
      customerId: value.originator.toString().replace(/ /g, '')
    },
    {
      role: OrderCustomerRoleEnum.Payer,
      customerId: value.payer?.toString().replace(/ /g, '')
    }
  ];

  const otherPartners =
    value.otherPartners
      ?.filter((customer) => customer.role && customer.customerId)
      .map<OrderCustomer>((customer) => ({
        role: customer.role,
        customerId: customer.customerId.toString().replace(/ /g, '')
      })) || [];

  parties.push(...otherPartners);
  const validDate = isValidDate(value.details.date);
  const detailsDate = validDate ? value.details.date : new Date(value.details.date);
  const [hour = 0, minute = 0] = value.details.time?.match(/(\d{2,})/g)?.map(Number) || [];

  const earliestDateTime = new Date(Date.UTC(detailsDate.getFullYear(), detailsDate.getMonth(), detailsDate.getDate(), hour, minute));

  let dependentProducts: any[] = origOrder?.dependentProducts ?? [];
  if (value.subProducts.origPremium) {
    dependentProducts = dependentProducts.filter((d: DependentProduct) => d.productNumber !== value.subProducts.origPremium);
  }
  if (value.subProducts.premium) {
    dependentProducts.push({ productNumber: value.subProducts.premium });
  }

  const letterBaseProducts = value.letterBaseProducts;
  letterBaseProducts.forEach((l) => {
    const hasDestinations = calculateRanges((l.destinations as Destination[]) || []);
    l.destinations = hasDestinations ? l.destinations : [];
    delete l.baseProduct?.description;
  });
  const submissionTypeValue = value.details?.submissionType ? value.details?.submissionType : null;
  return {
    productGroup: ProductGroup.BRIEF,
    customerOrderId: value.customerOrderId ? value.customerOrderId : '5037346381-B2B',
    orderLabel: value.orderLabel ? value.orderLabel : '',
    orderType: value.orderType ? value.orderType : OrderTypes.Einlieferungsauftrag,
    parties: parties.filter((party) => party.role && party.customerId),
    ...(value.deliveryOption && {
      otherAttributes: {
        ZOP: value.deliveryOption === DeliveryOption.STANDARD ? 'std' : 'flex'
      }
    }),
    packaging: value.packaging,
    dependentProducts,
    orderDetail: {
      productionPlantId: value.details.productionPlantId,
      submissionType: submissionTypeValue,
      date: earliestDateTime,
      pickup: false
    },
    productionState: value.productionState,
    orderCategory: origOrder?.orderCategory === OrderCategory.DV_BRIEF ? OrderCategory.DV_BRIEF : OrderCategory.AFM_BRIEF,
    letterBaseProducts: letterBaseProducts as any[],
    ...(orderId ? { orderId } : {}),
    postage: {
      machineCode: value.postageMachineCode
    },
    ...{ amexOrder: orderMode === OrderMode.CHANGE ? origOrder?.amexOrder : undefined }
  };
};
