import { getPayer } from 'common/services/CustomerService';
import { PaymentRep } from 'generated';
import useSWR from 'swr/immutable';
import { isValid as dateIsValid, format as dataFormat } from 'date-fns';

export const usePayerInfo = (settlementNumber?: string, validityDate?: Date): PaymentRep | undefined => {
  const validityDateStr = validityDate && dateIsValid(validityDate) ? dataFormat(new Date(validityDate), 'yyyyMMdd') : '';
  const reqPrm = { settlementNumber: settlementNumber, validityDateStr: validityDateStr };

  const { data } = useSWR(settlementNumber ? ['getPayer', reqPrm] : null, (_, { settlementNumber, validityDateStr }) =>
    getPayer(settlementNumber || '', validityDateStr)
  );
  return data;
};
