import { FormRow } from 'common/components/FormRow';
import { isValidInteger, SFCProps } from 'common/utils/formHelpers';

import i18n, { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import React, { ReactElement } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';

export type QuantityWeightSchema = {
  quantity: number;
  additionalQuantity?: number;
  individualWeight: number;
};

export type QuantityWeightSectionProps<T extends FieldValues> = SFCProps<T, QuantityWeightSchema> & {
  disabled?: boolean;
  changeOrderFormDisabled?: boolean;
};

const translationDE = {
  heading: 'Sendungsmenge und -gewicht',
  individualWeight: 'Einzelgewicht in g*',
  additionalQuantity: 'Aufgezahlte Sendungen',
  quantity: 'Sendungsmenge*',
  error: {
    required: 'Dies ist ein Pflichtfeld.',
    min: 'Der Wert muss größer als 0 sein.',
    negative: 'Der Wert darf nicht kleiner als 0 sein.',
    noValidInt: 'Der Wert muss ganzzahlig sein.',
    max4: 'Der Wert darf maximal 4 Ziffern enthalten.',
    max5: 'Der Wert darf maximal 5 Ziffern enthalten.',
    max9: 'Der Wert darf maximal 9 Ziffern enthalten.'
  }
};
const translationEN = {
  heading: 'Shipment Volume and Weight',
  individualWeight: 'Weight per Item in g*',
  additionalQuantity: 'Shipments with Additional Charges',
  quantity: 'Shipment Volume*',
  noValidInt: 'The value must be an integer without fraction.',
  error: {
    required: 'This field is required.',
    min: 'The value must be greater than 0.',
    negative: 'The value must be greater than 0.',
    noValidInt: 'The value must be an integer without fraction.',
    max4: 'The value can only be 4 digits long.',
    max5: 'The value can only be 5 digits long.',
    max9: 'The value can only be 9 digits long.'
  }
};

i18n.addResourceBundle('de', 'quantityWeight', translationDE);
i18n.addResourceBundle('en', 'quantityWeight', translationEN);

export const QuantityWeightSection = <T extends FieldValues>({
  disabled,
  name,
  changeOrderFormDisabled
}: QuantityWeightSectionProps<T>): ReactElement => {
  const { t } = useTranslation('quantityWeight');
  const { trigger } = useFormContext<T>();
  const { upsertMetaData } = useOrderContext();
  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? t(`error.${fieldState.error.message}`) : undefined;

  return (
    <OrderStepSection headline={t('heading')}>
      <FormRow>
        <TextInput
          type="number"
          name={`${name}.quantity`}
          label={t('quantity')}
          rules={{
            required: 'required',
            validate: isValidInteger,
            min: { value: 1, message: 'min' },
            max: { value: 1e9 - 1, message: 'max9' }
          }}
          disabled={disabled || changeOrderFormDisabled}
          error={getErrorMessage()}
          onChange={() => {
            upsertMetaData('netWeightChanged', true);
            trigger(`${name}.quantity` as any);
          }}
        />
      </FormRow>
      <FormRow mode="two-col">
        <TextInput
          type="number"
          name={`${name}.additionalQuantity`}
          label={t('additionalQuantity')}
          rules={{
            validate: isValidInteger,
            min: { value: 0, message: 'negative' },
            max: { value: 1e5 - 1, message: 'max5' }
          }}
          disabled={disabled}
          error={getErrorMessage()}
          onChange={() => trigger(`${name}.additionalQuantity` as any)}
        />
        <TextInput
          type="number"
          name={`${name}.individualWeight`}
          label={t('individualWeight')}
          rules={{
            required: 'required',
            validate: isValidInteger,
            min: { value: 1, message: 'min' },
            max: { value: 1e4 - 1, message: 'max4' }
          }}
          disabled={disabled}
          error={getErrorMessage()}
          onChange={() => {
            upsertMetaData('netWeightChanged', true);
            trigger(`${name}.individualWeight` as any);
          }}
        />
      </FormRow>
    </OrderStepSection>
  );
};
