// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeCitySection_checkboxWithTooltip__KDeXl {
    display: flex;
    align-items: center; /* Center items vertically */
  }
  
  .TimeCitySection_radioButtonCardInfoForTooltip__SOWn3 {
    line-height: 0.7;
    margin-left: 12px; 
   
   
  }
  
  .TimeCitySection_radioButtonCardInfoForTooltip__SOWn3::before {
    font-size: 20px;
  }
  
  .TimeCitySection_tooltipTileFlexer__EtXJx {
    display: flex;
    justify-content: flex-start;
  }
  
  .TimeCitySection_tooltipTileFlexer__EtXJx .TimeCitySection_icon-alert-info__jMeSh {
    margin-left: 15px;
    

  }
  
  .TimeCitySection_noOverFlow__V9ugl {
    white-space: nowrap;
  }
  `, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/TimeCitySection/TimeCitySection.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,4BAA4B;EACnD;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;;;EAGnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,iBAAiB;;;EAGnB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".checkboxWithTooltip {\n    display: flex;\n    align-items: center; /* Center items vertically */\n  }\n  \n  .radioButtonCardInfoForTooltip {\n    line-height: 0.7;\n    margin-left: 12px; \n   \n   \n  }\n  \n  .radioButtonCardInfoForTooltip::before {\n    font-size: 20px;\n  }\n  \n  .tooltipTileFlexer {\n    display: flex;\n    justify-content: flex-start;\n  }\n  \n  .tooltipTileFlexer .icon-alert-info {\n    margin-left: 15px;\n    \n\n  }\n  \n  .noOverFlow {\n    white-space: nowrap;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxWithTooltip": `TimeCitySection_checkboxWithTooltip__KDeXl`,
	"radioButtonCardInfoForTooltip": `TimeCitySection_radioButtonCardInfoForTooltip__SOWn3`,
	"tooltipTileFlexer": `TimeCitySection_tooltipTileFlexer__EtXJx`,
	"icon-alert-info": `TimeCitySection_icon-alert-info__jMeSh`,
	"noOverFlow": `TimeCitySection_noOverFlow__V9ugl`
};
export default ___CSS_LOADER_EXPORT___;
