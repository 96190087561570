import * as React from 'react';

import { ShuttleReducer } from '../Shuttle';
import { getIndexFromItem, getContainerMetadata, getShuttleItem, removeDisabledIndexes } from '../../../utils/utils';
import { SHUTTLE_CONTROL_TYPES } from '../reducers/index';
import { ShuttleState } from './useShuttleState';
import { SELECT_ITEM_REDUCER_ACTION } from '../reducers/selectItemReducer';

type Options = {
  setShuttleState: (args: ShuttleReducer) => void;
  shuttleState: ShuttleState;
};

export function useShuttleItemClick({ setShuttleState, shuttleState }: Options) {
  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // const target = getShuttleItem(e.target as HTMLDivElement);
      //
      // if (target && target.className.includes('shuttle__item')) {
      //   const index = getIndexFromItem(target);
      //   const container = target.closest('.shuttle__container');
      //
      //   if (container) {
      //     const { source } = getContainerMetadata(container);
      //
      //     const action = {
      //       type: SHUTTLE_CONTROL_TYPES.SELECT_ITEM,
      //       container: source ? 'source' : 'target',
      //       index
      //     };
      //
      //     setShuttleState(action);
      //   }
      // }
      const target = getShuttleItem(e.target as HTMLDivElement);

      if (target) {
        const index = getIndexFromItem(target);
        const container = target.closest('.shuttle__container');

        if (container) {
          const { containerName } = getContainerMetadata(container);

          const payload: SELECT_ITEM_REDUCER_ACTION = {
            type: 'SELECT_ITEM',
            container: containerName,
            index: Array.from(shuttleState.selected[containerName])
          };

          const selectionArray = Array.from(shuttleState.selected[containerName]);
          const lastItemInArray = selectionArray[selectionArray.length - 1];

          // if (shiftKeyPressed.current) {
          //   if (index < lastItemInArray) {
          //     let i = lastItemInArray;
          //
          //     while (i-- > index) {
          //       selectionArray.push(i);
          //     }
          //   } else if (index > lastItemInArray) {
          //     let i = lastItemInArray;
          //
          //     while (i++ < index) {
          //       selectionArray.push(i);
          //     }
          //   }
          //
          //   selectionArray = removeDisabledIndexes((target.parentElement && target.parentElement.children) || container.children, selectionArray);
          // } else {
          const foundIndex = selectionArray.indexOf(index);

          if (foundIndex === -1) {
            selectionArray.push(index);
          } else {
            selectionArray.splice(foundIndex, 1);
          }
          // }

          payload.index = selectionArray;
          setShuttleState(payload);
        }
      }
    },
    [setShuttleState]
  );

  return { onClick };
}
