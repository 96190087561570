import React, { FC } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { DHLButtonProps } from '@gkuis/gkp-base-widgets/dist/lib/components/atoms/DHLButton/DHLButton';
import classNames from 'classnames';
import styles from './IconSearchButton.module.css';

const IconSearchButton: FC<Omit<DHLButtonProps, 'iconPosition' | 'type'>> = ({ className, ...props }) => (
  <DHLButton className={classNames(className, styles.button)} iconPosition="icon" type="primary" {...props} />
);

export default IconSearchButton;
