import React, { useEffect, useRef, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { CustomTextInput } from '../CustomTextInput';
import { Button } from 'common/components/Button';
import { Pagination } from 'common/components/Paginator/Pagination';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { getCurrentSlice } from 'order/common/context/table/utils/table';
import { TableLoadingState } from 'order/common/context/table/dtos/TableColumnData';
import useError from 'common/hooks/useError';
import { determineLetterTypeByFormat } from './helper/determineLetterTypeByFormat';

import i18n from 'i18n';

import PlusIcon from 'assets/icons/plus.svg';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';

import idStyles from './idAssignment.module.css';
import styles from './qty-allocation.module.css';

import { Range } from 'generated';
import { isRangeOverlapping } from './helper/isRangeOverlapping';
import { EncodingTypeDisplay } from './EncodingTypeDisplay';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { DeliveryOptionZATL } from 'common/dtos/DeliveryOption';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { isValidDate } from 'order/common/utils/isValidDate';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';

import { Input } from 'common/components/Input';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import { Menu } from 'common/components/ContextMenu';
import contextClasses from 'common/components/ContextMenu/context-menu.module.css';
import classes from 'order/productGroups/common/components/atom/CustomerInput/customer-input.module.css';
import dropdownStyles from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals.module.css';

import { GenericRecommendation, useGenericRecommendations } from 'common/hooks/useGenericRecommendations';

interface Props {
  orderId?: string;
  productNumber?: string;
  allocQty?: number;
  allocatedValue?: string | number;
  ranges?: RawInternalHandler[] | InternalHandler[];
  onChange?: (ranges: InternalHandler[], totalRangesQty: number) => void;
  onHasFrankingIdPrefix?: () => void;
  frankingIdPrefix?: string;
  machineCode?: string;
  disabled?: boolean;
  errorHandler?: (error: boolean) => void;
  postageFrankingIdPrefix?: string;
  frankingIdEncoding?: string;
  qtyAllocation?: QtyAllocation | null;
  productFormat?: string;
  isLetter?: boolean;
  readOnly?: boolean;
  header: 'machineCode' | 'frankingIdPrefix';
  meta: { language: string };
  orderMode?: OrderMode;
  inModal?: boolean;
  id: string;
  allocatedDiscounts?: OrderReference[];
  selectedTrasitTime?: string[];
  orderDate?: Date | string;
}

const SVGIcon: React.FC<{ onClick: React.MouseEventHandler<SVGSVGElement>; testId?: string }> = ({ onClick, testId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    onClick={onClick}
    cursor={'pointer'}
    data-name={'orderSearchFilterAttrSelectableInput-function'}
    data-testid={testId}
  >
    <polygon
      fill="#d40511"
      fillRule="evenodd"
      points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
    ></polygon>
  </svg>
);

export type RawInternalHandler = Range & {
  id?: number;
};

export type InternalHandler = Omit<Range, 'to' | 'from'> & {
  id: number | string;
  to: number;
  from: number;
  frankingIdPrefix: string;
  zip: string;
  precision: string;
  shipmentQty?: number;
};

export enum ErrorKeys {
  FRANKING = 'franking',
  DESTINATION = 'destination',
  INVALID_REGION = 'invalid-region',
  FROM = 'from',
  TO = 'to',
  OVERLAP = 'overlap'
}

const defaultRange: InternalHandler = {
  from: 0,
  to: 0,
  zip: 'DE',
  frankingIdPrefix: '',
  precision: '2',
  id: 0
};

const validZipLength = [2, 5];
const invalidZipRegions = ['00', '05', '11', '62', '43', '95'];
const frankingIdRegex = /[1-6][D]([0-9A-Fa-f]{8})$/g;
const defaultPageSize = 5;

const reduceRangeSum = (sum: number | undefined, current: number | undefined) => (sum || 0) + (current || 0);

const preparePropRanges = (ranges: RawInternalHandler[] | InternalHandler[], nonStandardEncoding?: boolean) =>
  (
    ranges.map((range, index) =>
      range.id
        ? range
        : {
            ...range,
            to: nonStandardEncoding ? range.to : Number(range.to),
            from: nonStandardEncoding ? range.from : Number(range.from),
            id: new Date().getTime() + index
          }
    ) as InternalHandler[]
  )
    .filter((r) => (r.to || r.to === 0) && (r.from || r.from === 0))
    .sort((a, b) => a.from - b.from); // filter out malformed destinations

export const IdAssignment: VFC<Props> = (props) => {
  const hasNonStandardEncoding = props.frankingIdEncoding === 'HEX' || props.frankingIdEncoding === 'C40';
  const [ranges, setRanges] = useState<InternalHandler[]>(
    props.ranges && props.ranges.length > 0
      ? preparePropRanges(props.ranges, hasNonStandardEncoding)
      : [
          {
            ...defaultRange,
            frankingIdPrefix: props.postageFrankingIdPrefix || props.machineCode || '',
            id: new Date().getTime()
          }
        ]
  );
  const { setErrors, clearError, getError, hasErrors } = useError();
  const [totalCountError, setTotalCountError] = useState(false);
  const [_, setUpdated] = useState(0);
  const totalCount = useRef(props.qtyAllocation?.initialIdsQty ?? 0);
  i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
  i18n.addResourceBundle('en', 'shipmentAssignment', transEN);
  const { t } = useTranslation('shipmentAssignment');
  const letterType = determineLetterTypeByFormat(props.productFormat || '');
  const language = useAuthContext().user.language;

  // used for pagination
  const [currentSlice, setCurrentSlice] = useState<InternalHandler[]>([]);
  const page = useRef<number>(1);
  const pageSize = useRef<number>(defaultPageSize);

  const postingDate = props?.orderDate && isValidDate(new Date(props.orderDate)) ? new Date(props.orderDate) : undefined;
  const [inputValues, setInputValues] = useState(ranges.map((r) => r.frankingIdPrefix || ''));

  useEffect(() => {
    if (props.postageFrankingIdPrefix || props.machineCode || ranges[0].frankingIdPrefix) {
      props.onHasFrankingIdPrefix?.();
    }
  }, []);

  useEffect(() => {
    if (hasNonStandardEncoding) return;

    if (props.ranges?.length && totalCount.current === 1) {
      const validRanges = (props.ranges as (RawInternalHandler | InternalHandler)[]).filter((r): r is InternalHandler => {
        return !isNaN(Number(r.from)) && !isNaN(Number(r.to));
      });
      const allFrom = validRanges.map((r) => Number(r.from)).reduce(reduceRangeSum, 0);
      const allTo = validRanges.map((r) => Number(r.to)).reduce(reduceRangeSum, 0);

      if (allTo && allFrom && allTo >= allFrom) {
        const add = validRanges?.length || 0;
        totalCount.current = allTo - allFrom + add;
        setUpdated(new Date().getTime());
      } else if (validRanges.length === 0) {
        totalCount.current = props.qtyAllocation?.initialIdsQty ?? 0;
      }
    }
  }, [props.ranges]);

  // To persist total id sum on reopening the modal
  useEffect(() => {
    const validRanges = ranges.filter((r) => r.from && r.to);
    const allFrom = validRanges.map((r) => r.from).reduce(reduceRangeSum, 0);
    const allTo = validRanges.map((r) => r.to).reduce(reduceRangeSum, 0);

    if (allTo >= allFrom && allTo && allFrom) {
      const add = validRanges.length;
      totalCount.current = (props.qtyAllocation?.initialIdsQty ?? 0) + allTo - allFrom + add;
    } else if (validRanges.length === 0) {
      totalCount.current = props.qtyAllocation?.initialIdsQty ?? 0;
    }
  }, []);

  useEffect(() => {
    if (props.isLetter) {
      const newRanges = ranges.map((range) => ({ ...range, frankingIdPrefix: props.machineCode || '' }));
      setRanges(newRanges);
    }
  }, [props.machineCode, props.isLetter]);

  useEffect(() => {
    if (props.errorHandler) {
      props.errorHandler(hasErrors);
    }
  }, [hasErrors]);

  const calculateRanges = (newRanges: InternalHandler[]) => {
    if (hasNonStandardEncoding) return;

    const validRanges = newRanges.filter((r) => r.from && r.to);

    const allFrom = validRanges.map((r) => r.from).reduce(reduceRangeSum, 0);
    const allTo = validRanges.map((r) => r.to).reduce(reduceRangeSum, 0);

    if (allTo >= allFrom && allTo && allFrom) {
      const add = validRanges.length;
      totalCount.current = (props.qtyAllocation?.initialIdsQty ?? 0) + allTo - allFrom + add;
    } else if (validRanges.length === 0) {
      totalCount.current = props.qtyAllocation?.initialIdsQty ?? 0;
    }

    if (props.isLetter && props.allocQty && totalCount.current > props.allocQty) {
      setTotalCountError(true);
    } else {
      setTotalCountError(false);
    }

    props.onChange?.(newRanges, totalCount.current);
    setRanges(newRanges);
  };

  const checkOverlaps = (ranges: InternalHandler[]) => {
    const overlaps = isRangeOverlapping(ranges);
    Object.keys(overlaps).forEach((key) => {
      setErrors(key, [[ErrorKeys.OVERLAP, overlaps[parseInt(key)]]]);
    });
  };

  const validateSingleRange = (range: InternalHandler) => {
    clearError(range.id);

    if (range.to && !range.from) {
      setErrors(range.id, [[ErrorKeys.FROM, true]]);
    }

    if (range.to < range.from) {
      setErrors(range.id, [[ErrorKeys.TO, true]]);
    }

    if (range.zip) {
      setErrors(range.id, [
        [
          ErrorKeys.DESTINATION,
          !((range.zip === 'DE' && range.zip.length === 2) || (validZipLength.includes(range.zip.length) && /^[0-9]+$/g.test(range.zip)))
        ],
        [ErrorKeys.INVALID_REGION, invalidZipRegions.includes(range.zip)]
      ]);
    }
  };

  useEffect(() => {
    if (ranges.length > 1 && !hasNonStandardEncoding) {
      checkOverlaps(ranges);
    } else if (ranges.length === 1 && !hasNonStandardEncoding) {
      validateSingleRange(ranges[0]);
    }
  }, [ranges]);

  const {
    recommendations,
    load: getRecommendations,
    delete: removeRecommendation,
    add: addRecommendation
  } = useGenericRecommendations('machineID-Franking-Recommendations');

  const handleFrankingIdPrefixChange = (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
    const value = e.target.value;
    const ind = parseInt(id.toString());

    const newInputValues = [...inputValues];
    newInputValues[ind] = value;
    setInputValues(newInputValues);

    const updatedIsShowList = [...isShowList];
    updatedIsShowList[Number(id)] = value.length > 0;
    setIsShowList(updatedIsShowList);
    if (e.target.value.match(frankingIdRegex)) {
      const newRanges = [...ranges];
      newRanges.forEach((r) => {
        if (r.id === id) {
          r.frankingIdPrefix = e.target.value;
        }
      });
      if (e.target.value.length) {
        props.onHasFrankingIdPrefix?.();
      }
      props.onChange?.(newRanges, totalCount.current);
      setRanges(newRanges);
      addRecommendation({
        key: 'machineID-Franking-Recommendations',
        value: value
      });
      setErrors(id, [[ErrorKeys.FRANKING, false]]);
    } else {
      setErrors(id, [[ErrorKeys.FRANKING, true]]);
    }
  };

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
    const newRanges = [...ranges];
    newRanges.forEach((r) => {
      if (r.id === id) {
        r.zip = e.target.value;
      }
    });
    props.onChange?.(newRanges, totalCount.current);
    setRanges(newRanges);
    setErrors(id, [
      [
        ErrorKeys.DESTINATION,
        !(
          (e.target.value === 'DE' && e.target.value.length === 2) ||
          (validZipLength.includes(e.target.value.length) && /^[0-9]+$/g.test(e.target.value))
        )
      ],
      [ErrorKeys.INVALID_REGION, invalidZipRegions.includes(e.target.value)]
    ]);
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
    const newRanges = [...ranges];
    const newValue = parseInt(e.target.value);
    newRanges.forEach((r) => {
      if (r.id === id) {
        r.from = newValue;
        setErrors(id, [
          [ErrorKeys.FROM, !newValue],
          [ErrorKeys.TO, r.to < r.from]
        ]);
      }
    });
    if (newValue > 0) calculateRanges(newRanges);
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
    const newRanges = [...ranges];
    const newValue = parseInt(e.target.value);
    if (isNaN(newValue)) {
      setErrors(id, [[ErrorKeys.TO, !newValue]]);
      return;
    }
    newRanges.forEach((r) => {
      if (r.id === id) {
        r.to = newValue;
        setErrors(id, [
          [ErrorKeys.FROM, !r.from],
          [ErrorKeys.TO, newValue < r.from]
        ]);
      }
    });
    if (newValue > 0) calculateRanges(newRanges);
  };

  const handleDeleteClick = (id: number | string) => {
    let newRanges = [...ranges];
    if (ranges.length === 1) {
      newRanges[0] = {
        ...defaultRange,
        frankingIdPrefix: props.postageFrankingIdPrefix || props.machineCode || newRanges[0].frankingIdPrefix || '',
        id: newRanges[0].id
      };
      props.onHasFrankingIdPrefix?.();
      totalCount.current = props?.qtyAllocation?.initialIdsQty || 0;
      props.onChange?.(newRanges, totalCount.current);
    } else {
      newRanges = newRanges.filter((r) => r.id !== id);
      const availablePages = Math.ceil((ranges.length - 1) / pageSize.current);
      if (page.current > availablePages) handlePageChange(availablePages);
    }
    clearError(id);
    calculateRanges(newRanges);
  };

  const handleAddClick = () => {
    const newRanges = [...ranges];
    newRanges.push({
      ...defaultRange,
      frankingIdPrefix: props.postageFrankingIdPrefix || props.machineCode || newRanges[0].frankingIdPrefix || '',
      id: new Date().getTime()
    });
    setRanges(newRanges);
    const currentLastPage = Math.ceil(ranges.length / pageSize.current);
    handlePageChange(ranges.length % pageSize.current === 0 ? currentLastPage + 1 : currentLastPage);
  };

  useEffect(() => {
    const curSlice = getCurrentSlice(ranges, page.current, pageSize.current);
    setCurrentSlice(curSlice);
  }, [ranges]);

  const handlePageSizeChange = (size: number) => {
    let newSlice = [...ranges];
    page.current = 1;
    if (size > 0) {
      newSlice = getCurrentSlice(ranges, page.current, size);
    }
    pageSize.current = size;
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number) => {
    const newSlice = getCurrentSlice(ranges, p, pageSize.current);
    page.current = p;
    setCurrentSlice(newSlice);
  };

  const renderItems = props.isLetter ? currentSlice : ranges;

  const parentDO = props.allocatedDiscounts?.length
    ? props.allocatedDiscounts.find((allOrder) => allOrder.orderId === props.orderId)?.parentDeliveryOption
    : [];

  const selectedTransitTime = props.selectedTrasitTime;
  const isParentDOArray = Array.isArray(parentDO);

  const isStandardAndSchnell = (transitTime?: string[]) =>
    transitTime?.includes(DeliveryOptionZATL.STANDARD) && transitTime?.includes(DeliveryOptionZATL.SCHNELL);

  const isStandardAndFlex = (transitTime?: string[]) =>
    transitTime?.includes(DeliveryOptionZATL.STANDARD) && transitTime?.includes(DeliveryOptionZATL.FLEX);

  const isSingleOption = (transitTime?: string[], option?: DeliveryOptionZATL) => transitTime?.length === 1 && transitTime[0] === option;

  const isParentDOStandardAndSchnell = () =>
    isParentDOArray && parentDO.length === 2 && parentDO.includes(DeliveryOptionZATL.STANDARD) && parentDO.includes(DeliveryOptionZATL.SCHNELL);

  const isParentDOStandardSchnellAndFlex = () =>
    isParentDOArray &&
    parentDO.includes(DeliveryOptionZATL.STANDARD) &&
    parentDO.includes(DeliveryOptionZATL.SCHNELL) &&
    parentDO.includes(DeliveryOptionZATL.FLEX);

  const transitTime: DeliveryOptionZATL = isStandardAndSchnell(selectedTransitTime)
    ? DeliveryOptionZATL.SCHNELL
    : isStandardAndFlex(selectedTransitTime)
    ? DeliveryOptionZATL.FLEX
    : isSingleOption(selectedTransitTime, DeliveryOptionZATL.SCHNELL)
    ? DeliveryOptionZATL.SCHNELL
    : isSingleOption(selectedTransitTime, DeliveryOptionZATL.FLEX)
    ? DeliveryOptionZATL.FLEX
    : isSingleOption(selectedTransitTime, DeliveryOptionZATL.STANDARD) && isParentDOStandardAndSchnell()
    ? DeliveryOptionZATL.SCHNELL
    : isSingleOption(selectedTransitTime, DeliveryOptionZATL.STANDARD) && isParentDOStandardSchnellAndFlex()
    ? DeliveryOptionZATL.FLEX
    : DeliveryOptionZATL.FLEX;

  // Frankier-ID-Präfix recommendations
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isShowList, setIsShowList] = useState(Array(renderItems.length).fill(false));
  const getIsShowListForRow = (rowIndex: number) => {
    return isShowList[rowIndex];
  };

  useClickOutside([wrapperRef], () => setIsShowList(Array(renderItems.length).fill(false)));

  useKeyPress([wrapperRef], ['Enter', 'Escape', 'Tab'], (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    } else {
      setIsShowList(Array(renderItems.length).fill(false));
    }
  });
  useEffect(() => {
    isShowList.some(Boolean) && getRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowList.some(Boolean)]);

  return (
    <>
      {props.inModal && <h5 className={styles.modalTitle}>{t('modal.title')}</h5>}
      <table className={` ${styles.productFormat}`} style={{ lineHeight: 2 }}>
        <tbody>
          {letterType.length ? (
            <tr>
              <td>
                <span>{t('modal.format')}</span>:
              </td>
              <td className="pl-2">{t(letterType)}</td>
            </tr>
          ) : null}
          {isAvailableFromJan25(postingDate) && props.selectedTrasitTime && (
            <tr>
              <td>
                <span>{t('deliveryOption')}</span>:
              </td>
              <td className="pl-2">{t(transitTime.toLocaleLowerCase())}</td>
            </tr>
          )}
          {props?.orderMode === OrderMode.CHANGE ? (
            <tr>
              <td>
                <span>{t('modal.existingFrankingIDs')}:</span>
              </td>
              <td className="pl-2">{props?.qtyAllocation?.initialIdsQty?.toLocaleString(props?.meta?.language)}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {hasNonStandardEncoding && <EncodingTypeDisplay encoding={props.frankingIdEncoding as string} />}
      <div className={props.isLetter ? styles.modalContent + ' ' + idStyles.tableBody : styles.modalContent}>
        <div className={styles.modalContent}>
          <table className={styles.modalTable}>
            <thead className={props.isLetter ? idStyles.tableHead : ''}>
              <tr>
                <th className={styles.columnCenter}>{t(props.header)}</th>
                <th className={classNames(styles.columnZip, styles.columnCenter)}>{t('destination')}</th>
                <th className={styles.columnCenter}>{t('numberFrom')}</th>
                <th className={styles.columnMini}></th>
                <th className={styles.columnCenter}>{t('numberTo')}</th>
                {!hasNonStandardEncoding && (
                  <th className={styles.columnRight} data-testid={'column-shipment-qty'}>
                    {t('modal.numberOfFrankingIDs')}
                  </th>
                )}
                <th className={styles.columnMini} />
              </tr>
            </thead>
            <tbody>
              {renderItems.map((r, i) => {
                const totalAmount = r.to && r.from ? r.to - r.from + 1 : 0;
                return (
                  <React.Fragment key={i}>
                    <tr className={styles.rangeRow} key={`id-assignment-${i}`}>
                      <td className={styles.columnRight}>
                        {props.isLetter ? (
                          props.readOnly ? (
                            props.header === 'frankingIdPrefix' ? (
                              <span>{props.frankingIdPrefix || props.postageFrankingIdPrefix}</span>
                            ) : (
                              <span>{props.machineCode}</span>
                            )
                          ) : (
                            <span>{r.frankingIdPrefix}</span>
                          )
                        ) : (
                          <>
                            <Input
                              key={`inputKey-${i}`}
                              className={idStyles.additionalInputClass}
                              noPlaceHolderStyle={true}
                              value={inputValues[Number(r.id)] || r.frankingIdPrefix || ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFrankingIdPrefixChange(e, r.id || 0)}
                              error={getError(r.id, ErrorKeys.FRANKING)}
                              name={`id-assignment-prefix-${i}`}
                              required={true}
                              data-testid={`id-assignment-prefix-${i}`}
                              placeholder={t('frankingIdPrefix')}
                              type="text"
                              icon={
                                !props.postageFrankingIdPrefix &&
                                !props.machineCode &&
                                !props.disabled && (
                                  <SVGIcon
                                    testId="id-assignment-prefix-clear"
                                    onClick={() => {
                                      ranges.forEach((a) => {
                                        if (a.id === r.id) {
                                          a.frankingIdPrefix = '';
                                          const newInputValues = [...inputValues];
                                          newInputValues[Number(a.id)] = '';
                                          setInputValues(newInputValues);
                                        }
                                      });
                                      setErrors(r.id, [[ErrorKeys.FRANKING, false]]);
                                    }}
                                  />
                                )
                              }
                              onClick={() => {
                                const updatedIsShowList = [...isShowList];
                                updatedIsShowList[i] = !updatedIsShowList[i];
                                setIsShowList(updatedIsShowList);
                              }}
                              disabled={(!!props.postageFrankingIdPrefix || !!props.machineCode || props.disabled) ?? false}
                            />
                            {!!recommendations.length && getIsShowListForRow(i) && (
                              <div className={contextClasses.anchor} ref={wrapperRef} key={`recKey-${i}`}>
                                <Menu isOpen={true} data-testid={`id-assignment-prefix-${i}-menu `} className={classes.menu}>
                                  <p className={classNames(contextClasses.item, contextClasses.noHover)}>Last Entry</p>
                                  {recommendations.map((recommendation: GenericRecommendation, i: number) => (
                                    <div className={classNames(contextClasses.item, contextClasses.noHover)} key={i}>
                                      <button
                                        onClick={() => {
                                          ranges.forEach((a) => {
                                            if (a.id === r.id) {
                                              r.frankingIdPrefix = recommendation.value;
                                              const newInputValues = [...inputValues];
                                              newInputValues[Number(a.id)] = recommendation.value;
                                              setInputValues(newInputValues);
                                            }
                                          });
                                          setIsShowList(Array(renderItems.length).fill(false));
                                          setErrors(r.id, [[ErrorKeys.FRANKING, false]]);
                                        }}
                                        className={dropdownStyles.dropDownValue}
                                      >
                                        {recommendation.value}
                                      </button>
                                      <button className={dropdownStyles.delete} name={'amSearchDropdown-remove-' + i}>
                                        <SVGIcon
                                          testId={`prefix-delete-${i}`}
                                          onClick={async (e) => {
                                            e.stopPropagation();
                                            await removeRecommendation(recommendation);
                                          }}
                                        />
                                      </button>
                                    </div>
                                  ))}
                                </Menu>
                              </div>
                            )}

                            {getError(r.id, ErrorKeys.FRANKING) && (
                              <div className="fieldNote error text-left" data-testid={`id-assignment-prefix-${i}-error`} key={`errorKey-${i}`}>
                                {t('error.frankingPrefixError')}
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      <td className={classNames(styles.columnZip, styles.columnCenter)}>
                        {props.readOnly ? (
                          // TODO ugly quickfix since this actually needs to be handled in BE not in FE
                          <span>{r.zip === 'Q' ? 'DE' : r.zip}</span>
                        ) : (
                          <CustomTextInput
                            key={i}
                            placeholder={t('destination')}
                            defaultValue={r.zip || ''}
                            value={r.zip || ''}
                            onBlur={(e) => handleZipChange(e, r.id || 0)}
                            type={'text'}
                            testid={`id-assignment-zip-${i}`}
                            error={
                              getError(r.id, ErrorKeys.DESTINATION)
                                ? t('error.destinationError')
                                : getError(r.id, ErrorKeys.INVALID_REGION)
                                ? t('error.invalidZipRegionError')
                                : undefined
                            }
                            rules={{ regex: /[DE0-9]/g }}
                            className={styles.inputClass}
                            disabled={props.disabled}
                            onInput={(e) => (e.currentTarget.value = e.currentTarget.value.toUpperCase())}
                          />
                        )}
                      </td>
                      <td className={styles.columnCenter}>
                        {props.readOnly ? (
                          <span>{r.from.toLocaleString(language)}</span>
                        ) : (
                          <CustomTextInput
                            key={i}
                            defaultValue={r.from || ''}
                            value={r.from || ''}
                            onBlur={(e) => handleFromChange(e, r.id || 0)}
                            testid={`id-assignment-from-${i}`}
                            className={styles.inputClass}
                            disabled={props.disabled}
                            error={
                              getError(r.id, ErrorKeys.FROM) ? t('error.invalidFromRangeError') : getError(r.id, ErrorKeys.OVERLAP) ? ' ' : undefined
                            }
                            rules={{ regex: /^[0-9\b]+$/g }}
                            formatNumbers={true}
                            meta={{ language: props.meta.language }}
                          />
                        )}
                      </td>
                      <td className={classNames(styles.smallColumn, styles.centeredCell, styles.bold)}>-</td>
                      <td className={styles.columnCenter}>
                        {props.readOnly ? (
                          <span>{r.to.toLocaleString(language)}</span>
                        ) : (
                          <CustomTextInput
                            key={i}
                            defaultValue={r.to || ''}
                            value={r.to || ''}
                            onBlur={(e) => handleToChange(e, r.id || 0)}
                            testid={`id-assignment-to-${i}`}
                            className={styles.inputClass}
                            disabled={props.disabled}
                            error={
                              getError(r.id, ErrorKeys.TO) ? t('error.invalidToRangeError') : getError(r.id, ErrorKeys.OVERLAP) ? ' ' : undefined
                            }
                            rules={{ regex: /^[0-9\b]+$/g }}
                            formatNumbers={true}
                            meta={{ language: props.meta.language }}
                          />
                        )}
                      </td>
                      {!hasNonStandardEncoding && (
                        <td className={classNames(styles.mediumColumn, styles.centeredCell)}>
                          <span id="id-assignment-total">{totalAmount.toLocaleString(language)}</span>
                        </td>
                      )}
                      <td className={classNames(styles.smallColumn, styles.addDeleteAssignmentButton)}>
                        {!props.readOnly && (
                          <DHLButton
                            name={'delete-details'}
                            type="default"
                            icon="delete"
                            className={styles.addDeleteAssignmentButton}
                            onClick={() => handleDeleteClick(r.id || 0)}
                            iconPosition="icon"
                          />
                        )}
                      </td>
                    </tr>
                    {getError(r.id, ErrorKeys.OVERLAP) && (
                      <tr>
                        <td colSpan={6}>
                          <div className={idStyles.error}>{t('error.rangeOverflowError')}</div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        {totalCountError && props.isLetter ? <div className={idStyles.error}>{t('error.exceededQtyError')}</div> : null}
        <div className={props.inModal ? `${styles.actionBarInModal} ${styles.actionBar}` : styles.actionBar}>
          {!props.readOnly && (
            <div className={styles.actionLeft}>
              <Button
                label={t('addFrankingId')}
                icon={PlusIcon}
                sizing="xs"
                onClick={handleAddClick}
                className={classNames(styles.addDeleteAssignmentButton)}
                dataTestId={`btn-add-assignment`}
                disabled={props.disabled}
              />
            </div>
          )}
          {!props.isLetter && (
            <div className={styles.actionRight}>
              {`${t('modal.totalSum')}:`}
              <span>{totalCount.current.toLocaleString(props?.meta?.language)}</span>
            </div>
          )}
        </div>
        {props.isLetter && ranges.length > defaultPageSize && (
          <div className={idStyles.actionBar}>
            <Pagination
              loadingState={TableLoadingState.LOADED}
              totalItems={ranges.length}
              defaultPageSize={defaultPageSize}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
              currentPage={page.current}
            />
          </div>
        )}
      </div>
    </>
  );
};
