// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-message_message__Nijae {
  font-weight: 600;
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  font-size: 16px;
  margin: 20px 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/ResultMessage/result-message.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".message {\n  font-weight: 600;\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  font-size: 16px;\n  margin: 20px 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `result-message_message__Nijae`
};
export default ___CSS_LOADER_EXPORT___;
