import { getUser } from 'common/context/auth/AuthProvider';
import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';

export async function triggerPrepareForCollection(orderNumber: string, inductionDate: string, productionPlantId: string): Promise<unknown> {
  try {
    const user = getUser();
    const date: string = new Date(inductionDate).getDate().toString().padStart(2, '0');
    const month: string = (new Date(inductionDate).getMonth() + 1).toString().padStart(2, '0');
    const year = new Date(inductionDate).getFullYear();

    const { data } = await sendRequest({
      url: `${BASE_URL}orders/prepare-for-collection/${orderNumber}?inductionDate=${year}-${month}-${date}&productionPlantId=${productionPlantId}`,
      method: 'put',
      token: user._accessToken,
      extUserId: user.extUserId
    });
    return data;
  } catch (e) {
    console.error('Prepare for collection request failed', e);
    return false;
  }
}
