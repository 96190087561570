export enum DVHierarchyProductGroup {
  DVINTERNATIONAL = 'Brief',
  DVPZA = 'Postzustellungsauftrag',
  DVEPOSTBRIEF = 'Hybridbrief',
  DVSTRIP = 'Streifbandzeitung',
  DVBOOK = 'Warensendung',
  DVBLIND = 'Blindensendung',
  DVDVD = 'DVD-Sendung',
  INDIVIDUALTARIFF = 'Einzeltarif'
}
