import React, { useEffect, useRef, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from '@gkuis/gkp-base-widgets/dist/lib';

import { Pagination } from 'common/components/Paginator/Pagination';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { getCurrentSlice, rowContainsFilter, sortRows } from 'order/common/context/table/utils/table';
import { TableLoadingState } from 'order/common/context/table/dtos/TableColumnData';

import { OrderDestination } from 'generated';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { Table } from 'common/components/Table/Table';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { Filter } from 'common/components/Table/dtos/Filter';
import { Sort } from 'common/components/Table/dtos/Sort';
import { useOrderContext } from 'order/common/context/order/OrderContext';

import styles from './palletOverview.module.css';
import { tableConfigurationForPalletsOverview } from 'order/common/components/AllocationTable/columnConfiguration';

export interface PalletsOverviewTableData {
  palletNumber?: string;
  nve?: string;
  zip?: string;
  totalNetWeightValue?: number;
  totalWeight?: number;
}

interface Props {
  destinations?: OrderDestination[];
  orderMode?: OrderMode;
  id: string;
}

export interface TableData {
  data?: OrderDestination[];
  id: string;
  palletNumber?: string;
}

export const PalletsOverview: VFC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const language = useAuthContext().user.language;
  const { meta, upsertMetaData, order } = useOrderContext();

  const transformedData = props?.destinations?.flatMap((d, key) => {
    return d?.packaging?.map((p) => {
      return {
        ...d,
        zip: d?.zipCode,
        nve: p?.packageId && p.packageId?.length === 20 ? p?.packageId : '',
        totalNetWeight: p?.totalNetWeightValue,
        totalGrossWeight: p?.totalWeight,
        palletNumber: p?.packageId && p.packageId?.length !== 20 ? p?.packageId : p?.serialNumber,
        id: `${d?.zipCode}-${p?.packageId}-${p?.serialNumber}-${key}`
      };
    });
  });

  const transformedRanges: TableData[] =
    transformedData
      ?.map((d, i) => {
        return {
          data: d,
          id: d?.id ?? `${d?.zipCode}-${i}`,
          palletNumber: d?.palletNumber
        } as unknown as TableData;
      })
      ?.sort((a, b) => (a?.palletNumber && b.palletNumber ? +a.palletNumber - +b.palletNumber : 0)) || [];

  const [rows, setRows] = useState<TableData[]>([]);
  const [currentSlice, setCurrentSlice] = useState<TableData[]>(meta?.[`palletOTableCurrentSlice${id}`] || []);
  const filter = useRef<Filter>({ name: null, value: null });
  const sort = useRef<Sort>(meta?.[`palletOTableCurrentSort${id}`] || { name: null, order: null });
  const sorting = useRef<Sort>(meta?.[`palletOTableCurrentSorting${id}`] || { name: null, order: SortOrder.ASC, type: ColumnType.CUSTOM_CELL });
  const page = useRef<number>(meta?.[`palletOTableCurrentPage${id}`] || 1);
  const defaultPageSize = 5;
  const pageSize = useRef<number>(
    meta?.[`palletOTableCurrentPageSize${id}`] === 0 ? 0 : meta?.[`palletOTableCurrentPageSize${id}`] || defaultPageSize
  );
  const filteredRows = useRef<TableData[]>(meta?.[`palletOTableFilterRows${id}`] || []);

  const filterRows = (allocationsOrders: TableData[]) => {
    let filtered = [...allocationsOrders];
    const { name, value } = filter.current;
    if (name && value) {
      filtered = filtered.filter((r) => rowContainsFilter(name, value, r));
    }
    return filtered;
  };

  useEffect(() => {
    setRows(transformedRanges || []);
    const filtered = filterRows(transformedRanges);
    const sortedRows = sortRows(
      meta?.[`palletOTableCurrentSorting${id}`]?.name || sorting.current.name,
      filtered || [],
      meta?.[`palletOTableCurrentSorting${id}`]?.order || sorting.current.order
    );
    const curSlice = getCurrentSlice(
      sortedRows,
      meta?.[`palletOTableCurrentPage${id}`] || page.current,
      meta?.[`palletOTableCurrentPageSize${id}`] || pageSize.current
    );
    filteredRows.current = sortedRows || [];
    upsertMetaData(`palletOTableFilterRows${id}`, filteredRows.current);
    setCurrentSlice(curSlice);
  }, [props.destinations]);

  const setSorting = (name: string, order: SortOrder, type: ColumnType) => {
    const filtered = [...filteredRows.current];
    const sortedRows = sortRows(name, filtered, order);
    const currentSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    sort.current = { name, order, type };
    upsertMetaData(`palletOTableCurrentSort${id}`, { name, order, type });
    sorting.current = { name, order, type };
    upsertMetaData(`palletOTableCurrentSorting${id}`, sorting.current);
    filteredRows.current = sortedRows;
    upsertMetaData(`palletOTableFilterRows${id}`, sortedRows);
    setCurrentSlice(currentSlice);
    upsertMetaData(`palletOTableCurrentSlice${id}`, currentSlice);
  };

  const handlePageSizeChange = (size: number, id: string) => {
    let newSlice = [...filteredRows.current];

    page.current = 1;
    upsertMetaData(`palletOTableCurrentPage${id}`, 1);

    if (size > 0) {
      newSlice = getCurrentSlice(filteredRows.current, page.current, size);
    }

    pageSize.current = size;
    upsertMetaData(`palletOTableCurrentSlice${id}`, newSlice);
    upsertMetaData(`palletOTableCurrentPageSize${id}`, size);
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number, id?: string) => {
    const newSlice = getCurrentSlice(filteredRows.current, p, pageSize.current);
    page.current = p;
    upsertMetaData(`palletOTableCurrentPage${id}`, p);
    upsertMetaData(`palletOTableCurrentSlice${id}`, newSlice);
    setCurrentSlice(newSlice);
  };

  const showConnectionsText = props?.destinations?.some((d) =>
    d?.packaging?.find((p) => (p?.referencedPackageQuantity ? +p.referencedPackageQuantity > 0 : false))
  );

  return (
    <>
      <div>
        {showConnectionsText ? (
          <p data-testId={'assignedPalletsText'} className={styles.connectionsText}>
            {t('hasAssignedContainers')}
          </p>
        ) : (
          <></>
        )}
        <Table
          columnConfig={tableConfigurationForPalletsOverview()}
          currentSlice={currentSlice || []}
          loadingState={TableLoadingState.LOADED}
          t={t}
          filter={filter.current}
          sort={sort.current}
          onSortChange={setSorting}
          language={language}
          isShippingTable={true}
          highlightedRows={false}
        />
        {filteredRows.current.length > defaultPageSize && (
          <div className={styles.actionBar}>
            <Pagination
              loadingState={TableLoadingState.LOADED}
              totalItems={filteredRows.current.length}
              defaultPageSize={defaultPageSize}
              onPageChange={(e) => handlePageChange(e, id)}
              onSizeChange={(e) => handlePageSizeChange(e, id)}
              currentPage={meta?.[`palletOTableCurrentPageSize${id}`] > filteredRows.current.length ? 1 : meta?.[`palletOTableCurrentPage${id}`]}
              currentPageSize={meta?.[`palletOTableCurrentPageSize${id}`]}
            />
          </div>
        )}
      </div>
    </>
  );
};
