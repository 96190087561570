import React, { useEffect, useState, VFC } from 'react';

import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { CustomerInfoWithAddressTO } from 'generated';
import { useOrderContext } from 'order/common/context/order/OrderContext';

export type CustomerDisplayProps = {
  customerId?: string;
  role?: string;
};

export const CustomerDisplay: VFC<CustomerDisplayProps> = ({ customerId, role }) => {
  const { order, orderMode } = useOrderContext();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const filteredParty = order?.parties?.filter((p) => p.customerId == customerId && p.role == role);
  const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
    customerId: customerId,
    name: filteredParty?.[0]?.name,
    address: filteredParty?.[0]?.orderAddress
  };

  const fetchCustomerInfo = async (customerNumber?: string) => {
    if (customerNumber) {
      const { customerInfo: data } = await getCustomerInfo(customerNumber);
      if (data) {
        setCustomerInfo(data);
      } else if (transformedCustomerData) {
        setCustomerInfo(transformedCustomerData);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  if (!customerInfo) {
    return <></>;
  }

  return (
    <>
      <p>{customerInfo.name}</p>
      <p>
        {customerInfo.address?.street} {customerInfo.address?.streetNumber}
      </p>
      <p>
        {customerInfo.address?.postCode} {customerInfo.address?.city}
      </p>
      <p>{customerInfo.address?.country}</p>
    </>
  );
};
