import React, { useLayoutEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SearchTable } from './SearchTable';
import { AllocationTable } from 'order/common/components/AllocationTable/AllocationTable';
import { ResultMessage } from 'order/orderSearch/components/ResultMessage';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { allocationTableConfiguration } from 'order/common/components/AllocationTable/columnConfiguration';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import styles from './table-container.module.css';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';

export const TableContainer = (): JSX.Element => {
  const { control } = useFormContext<AdditionalOrdersOrderCreate>();
  const { response, loading } = useSearchContext();
  const { orderMode, orderCategory } = useOrderContext();

  const [filteredSelectedAllocOrders, searchedOrders] = useWatch({
    control,
    name: ['filteredSelectedAllocOrders', 'searchedOrders']
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchTable}>
        <ResultMessage
          loadingState={loading}
          filteredCount={filteredSelectedAllocOrders?.length !== searchedOrders?.length ? filteredSelectedAllocOrders?.length : undefined}
          count={(response?.orders || []).length}
        />
        <div className={styles.tableCentering}>
          <SearchTable />
        </div>
      </div>
      <AllocationTable columnConfig={allocationTableConfiguration(orderCategory, orderMode === OrderMode.CHANGE)} />
    </div>
  );
};
