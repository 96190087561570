import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { PricePositionRep, ServiceClusterRep } from 'generated';
import moment from 'moment';
import styles from 'order/orderChange/common/components/BillingTable/billing-table.module.css';
import { BillingTableItemHeadRowProps } from 'order/orderChange/common/components/BillingTable/BillingTableItemHeadRow';
import React, { FC } from 'react';

export type BillingTableRowProps = PricePositionRep &
  BillingTableItemHeadRowProps & {
    index: number;
    onClick?: () => void;
    item: ServiceClusterRep;
    isExpanded?: boolean;
  };

export const BillingTableRow: FC<BillingTableRowProps> = ({ item, index, onClick, isExpanded, ...props }) => (
  <tr key={'row-data-' + index} className={classNames(styles.parentRow, 'dhlTable-row dhlTable-row-default leaf')} onClick={onClick}>
    <td colSpan={props.showDesignation ? 2 : 1}>{index + 1}</td>
    <td colSpan={props.showDiscount ? 2 : 1}>{item.baseProductDescription}</td>
    <td colSpan={props.showTariffZone ? 2 : 1} className={styles.rightAlign}>
      {item.qty?.toLocaleString(moment.locale(), {
        maximumFractionDigits: 3
      })}
    </td>
    {props.showSingleWeight && props.showTotalWeight && <td />}
    <td colSpan={props.showZKZ ? 2 : 1} className={styles.rightAlign}>
      {item.amount?.value?.toLocaleString(moment.locale(), {
        style: 'currency',
        currency: item.amount?.currency,
        maximumFractionDigits: 3
      })}
    </td>
    <td colSpan={props.showBasicCharge ? 2 : 1}></td>
    <td>
      {
        <DHLIcon
          className={classNames(styles.expandBtn, styles.rightAlign)}
          name={'billing-table-row-expand'}
          icon={isExpanded ? 'arrow-up' : 'arrow-down'}
        />
      }
    </td>
  </tr>
);
