import styles from '../PSLBillingTable/pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const PSLCorrectionBillingTableHeadRow: VFC = () => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  return (
    <tr>
      <th className={styles.removeWhiteSpace}>{t('position')}</th>
      <th className={styles.removeWhiteSpace}>{t('product')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('shipmentQuantitySubsequentTaxation')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('basicFeePerPiece')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('VAT')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('correctionAmountWithoutVAT')}</th>
    </tr>
  );
};
