import { OrderSearchResultRepTDO } from 'order/common/services/OrderService';
import { DefaultAttribute } from '../../../../orderSearch/services/orderSearchAttribute';
import { OrderSearchKey, orderSearchKeyReplyTDOMapping } from '../dtos/OrderSearchKey';
import { SearchState } from '../dtos/SearchState';

export const addRecommendationsToExisting = (
  activeState: SearchState | undefined,
  response: OrderSearchResultRepTDO | undefined
): DefaultAttribute[] => {
  const recommendations: DefaultAttribute[] = [];

  for (const attribute of activeState?.attributes || []) {
    if (attribute.key === OrderSearchKey.Date) {
      continue;
    }

    const criterionNecessary = response?.orders?.some((reply) => {
      const replyKey = orderSearchKeyReplyTDOMapping.get(attribute.key);

      if (!replyKey) {
        return false;
      }

      let replyData = reply.data?.[replyKey];

      if (typeof replyData === 'object') {
        replyData = replyData?.customerId;
      }

      const replyValue = replyData?.toString();

      return replyKey && replyValue?.includes(attribute.value);
    });

    if (criterionNecessary) {
      recommendations.push(attribute);
    }
  }

  return recommendations;
};
