import dompurify from 'dompurify';

import { getUser } from '../context/auth/AuthProvider';
import { sendRequest } from '../api-adapter';
import { BASE_URL } from '../utils/apiUtils';
import { crc32 } from 'crc';

const RECOMMENDATION_NONCE = 'm6tyRozVw0meodtu';

export const requestUserRecommendations = async <T>(key: string): Promise<T[]> => {
  const user = getUser();
  try {
    const { data } = await sendRequest({
      url: `${BASE_URL}settings/${key}`,
      method: 'get',
      token: user._accessToken,
      extUserId: user.extUserId
    });
    if (data && data.payload) {
      const securedData = JSON.parse(dompurify.sanitize(atob(data.payload)));
      if (securedData && securedData.crc && securedData.payload) {
        const crc = crc32(RECOMMENDATION_NONCE + securedData.payload).toString(16);
        if (crc === securedData.crc) {
          return JSON.parse(securedData.payload);
        } else {
          console.error('Corrupt recommendation settings.');
        }
      }
    }

    return [];
  } catch (e: any) {
    console.error(`Error fetching user settings for ${key}`, e);
    return [];
  }
};

export const sendUserRecommendations = async <T>(key: string, payload: string): Promise<boolean> => {
  const user = getUser();
  try {
    const securedPayload = {
      payload: payload,
      crc: crc32(RECOMMENDATION_NONCE + payload).toString(16)
    };
    const securedPayloadEncoded = btoa(JSON.stringify(securedPayload));
    await sendRequest({
      url: `${BASE_URL}settings/${key}`,
      method: 'put',
      token: user._accessToken,
      extUserId: user.extUserId,
      data: {
        payload: securedPayloadEncoded
      }
    });

    return true;
  } catch (e: any) {
    console.error(`Error sending user settings for ${key}`, e);
    return false;
  }
};
