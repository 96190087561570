export enum OnlineHelpLinks {
  ORDER_SEARCH = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/auftragssuche-hgr34b.html',
  ORDER_STEP1 = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/auftragserfassung-hg5m9i.html',
  'DV_BRIEF-BRIEF_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dv-brief-auftragsinhalt-4r5n89.html',
  'DV_BRIEF-BRIEF_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dv-brief-zahlungsinformationen-4r8n59.html',
  'DV_BRIEF-BRIEF_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dv-brief-auftragsuebersicht-n594r8.html',
  'AFM_BRIEF-BRIEF_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/frankiermaschine-auftragsinhalt-lmw3aa.html',
  'AFM_BRIEF-BRIEF_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/frankiermaschine-zahlungsinformationen-klm45g.html',
  'AFM_BRIEF-BRIEF_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/frankiermaschine-auftragsuebersicht-jn7h45.html',
  'TL_BRIEF-BRIEF_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-auftragsinhalt-klht54.html',
  'TL_BRIEF-BRIEF_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-ea-zuordnen-g6n3lj.html',
  'TL_BRIEF-BRIEF_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-auftragsuebersicht-asdr56.html',
  'DiP_EINZEL-DIALOGPOST_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsinhalt-3rt6lm.html',
  'DiP_EINZEL-DIALOGPOST_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-zahlungsinformationen-b75la4.html',
  'DiP_EINZEL-DIALOGPOST_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsuebersicht-76vd32.html',
  'DiP_VARIO-DIALOGPOST_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsinhalt-3rt6lm.html',
  'DiP_VARIO-DIALOGPOST_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-zahlungsinformationen-b75la4.html',
  'DiP_VARIO-DIALOGPOST_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsuebersicht-76vd32.html',
  'DiP_SAMMEL-DIALOGPOST_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsinhalt-3rt6lm.html',
  'DiP_SAMMEL-DIALOGPOST_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-zahlungsinformationen-b75la4.html',
  'DiP_SAMMEL-DIALOGPOST_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftragsuebersicht-76vd32.html',
  'PRESSEDISTRIBUTION-PRESS_DISTRIBUTION_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-distribution-auftragsinhalt-h6tg43.html',
  'PRESSEDISTRIBUTION-PRESS_DISTRIBUTION_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-distribution-zahlungsinformationen-nj6fg3.html',
  'PRESSEDISTRIBUTION-PRESS_DISTRIBUTION_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-distribution-auftragsuebersicht-h4cd2a.html',
  'ABHOLAUFTRAG-COLLECTION_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-abholung-auftragsinhalt-g4vm8z.html',
  'ABHOLAUFTRAG-COLLECTION_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-abholung-zahlungsinformationen-abh7g4.html',
  'ABHOLAUFTRAG-COLLECTION_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-abholung-auftragsuebersicht-kgn65d.html',
  'DiP_EINZEL-DIALOGPOSTWURFSPEZIAL_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postwurfspezial-auftragsinhalt-a65gr9.html',
  'DiP_EINZEL-DIALOGPOSTWURFSPEZIAL_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postwurfspezial-zahlungsinformationen-5gr9a6.html',
  'DiP_EINZEL-DIALOGPOSTWURFSPEZIAL_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postwurfspezial-auftragsuebersicht-r9a65g.html',
  'PA-POSTAKTUELL_2' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postaktuell-auftragsinhalt-4uj92v.html',
  'PA-POSTAKTUELL_3' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postaktuell-zahlungsinformationen-j92v4u.html',
  'PA-POSTAKTUELL_4' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postaktuell-auftragsuebersicht-2v4uj9.html',
  'AFM_BRIEF-BRIEF_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/briefsendung-frankiermaschine-auftrag-anzeigen-2qk68g.html',
  'DV_BRIEF-BRIEF_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dv-brief-auftrag-anzeigen-p574x2.html',
  'DiP_EINZEL-DIALOGPOST_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftrag-anzeigen-i2p7d9.html',
  'DiP_VARIO-DIALOGPOST_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftrag-anzeigen-i2p7d9.html',
  'DiP_SAMMEL-DIALOGPOST_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-auftrag-anzeigen-i2p7d9.html',
  'PRESSEDISTRIBUTION-PRESS_DISTRIBUTION_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-distribution-auftrag-anzeigen-qk68g2.html',
  'ABHOLAUFTRAG-COLLECTION_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/zusatzauftrag-abholung-auftrag-anzeigen-8g2qk6.html',
  'DiP_EINZEL-DIALOGPOSTWURFSPEZIAL_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postwurfspezial-auftrag-anzeigen-65gr9a.html',
  'PA-POSTAKTUELL_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/postaktuell-auftrag-anzeigen-uj92v4.html',
  'DHL_INFOPOST-INFOPOST_DHL_DISPLAY' = 'https://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-schwer-auftrag-anzeigen-71ax8f.html',
  'IP_INTERNATIONAL-BRIEF_2' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-international-auftragsinhalt-hgt5ds.html',
  'IP_INTERNATIONAL-BRIEF_3' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-international-zahlungsinformationen-hjng5d.html',
  'IP_INTERNATIONAL-BRIEF_4' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-international-auftragsuebersicht-nmkih5.html',
  'IP_INTERNATIONAL-BRIEF_DISPLAY' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/dialogpost-international-auftrag-anzeigen-ng5fde.html',
  'PUB_INTERNATIONAL-BRIEF_2' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-international-auftragsinhalt-ad5p0n.html',
  'PUB_INTERNATIONAL-BRIEF_3' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-international-zahlungsinformationen-bh6fd.html',
  'PUB_INTERNATIONAL-BRIEF_4' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-international-auftragsuebersicht-fg6fds.html',
  'PUB_INTERNATIONAL-BRIEF_DISPLAY' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/presse-international-auftrag-anzeigen-lk87fd.html',
  'BRIEF_INTERNATIONAL-BRIEF_2' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-international-auftragsinhalt-t5de2a.html',
  'BRIEF_INTERNATIONAL-BRIEF_3' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-international-zahlungsinformationen-klm123.html',
  'BRIEF_INTERNATIONAL-BRIEF_4' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-international-auftragsuebersicht-hg6zba.html',
  'BRIEF_INTERNATIONAL-BRIEF_DISPLAY' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-international-auftrag-anzeigen-k9h4ad.html',
  'BRIEF_DMC_INTERNATIONAL-BRIEF_2' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-50plus-auftragsinhalt-76gf3a.html',
  'BRIEF_DMC_INTERNATIONAL-BRIEF_3' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-50plus-zahlungsinformationen-hb5g4s.html',
  'BRIEF_DMC_INTERNATIONAL-BRIEF_4' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-50plus-auftragsuebersicht-6hgf4a.html',
  'BRIEF_DMC_INTERNATIONAL-BRIEF_DISPLAY' = 'http://www.dhl.de/de/geschaeftskunden/portal/kundendesktop/online-hilfe/auftragsmanagement-brief/brief-50plus-auftrag-anzeigen-gtm9ja.html'
}
