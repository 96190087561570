export type FormatOptions = Partial<{
  english: boolean;
}>;

const getLocale = (options?: FormatOptions): string => (options?.english ? 'en-US' : 'de-DE');

export const toFormattedDate = (date?: Date, options?: FormatOptions): string | undefined =>
  date
    ? new Date(new Date(date).toDateString()).toLocaleDateString(getLocale(options), {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: undefined,
        minute: undefined
      })
    : undefined;

export const toFormattedTime = (date?: Date, options?: FormatOptions): string | undefined =>
  date
    ? new Date(date).toLocaleTimeString(getLocale(options), {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
        // timeZone: 'UTC'
      })
    : undefined;

export const toFormattedNumber = (n?: number, options?: FormatOptions): string | undefined =>
  n?.toLocaleString(getLocale(options), {
    useGrouping: true
  });

export const toFormattedInt = (n?: number, options?: FormatOptions): string | undefined =>
  n !== undefined
    ? Math.round(n).toLocaleString(getLocale(options), {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    : '';

export const toFormattedFloat = (n?: number, options?: FormatOptions): string | undefined =>
  n?.toLocaleString(getLocale(options), {
    useGrouping: true,
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  });

export type FormatCurrencyOptions = FormatOptions &
  Partial<{
    currency: string;
  }>;

export const toFormattedCurrency = (value?: number, options?: FormatCurrencyOptions): string | undefined =>
  value?.toLocaleString(getLocale(options), {
    currency: options?.currency || 'EUR',
    useGrouping: true,
    currencyDisplay: 'symbol',
    style: 'currency'
  });
