// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PressBaseProduct_shipmentPlanAccod__hJl7c {
  padding: 20px 10px 20px 0px;
  margin: 20px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.PressBaseProduct_alignMiddle__naPEu{
  align-items: center;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/press/components/PressBaseProduct/PressBaseProduct.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,cAAc;EACd,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".shipmentPlanAccod {\n  padding: 20px 10px 20px 0px;\n  margin: 20px 0;\n  border-top: 1px solid #e6e6e6;\n  border-bottom: 1px solid #e6e6e6;\n}\n.alignMiddle{\n  align-items: center;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shipmentPlanAccod": `PressBaseProduct_shipmentPlanAccod__hJl7c`,
	"alignMiddle": `PressBaseProduct_alignMiddle__naPEu`
};
export default ___CSS_LOADER_EXPORT___;
