import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { pdf } from '@react-pdf/renderer';

import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PdfExportUtils from './ExportPdfUtils';
import { getUrl } from './utils/pdfUtils';
import { useSearchContext } from '../context/search/SearchContext';
import { useTableContext } from '../context/table/TableContext';

/**
 * The pdf export button provides a pdf export of the whole, unfiltered data in the result table.
 * It uses the data and search attributes to generate a pdf file displaying the result set as well as the search conditions.
 *
 * @constructor
 */
const ExportPdfButton = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useAuthContext();
  const { attributes, rawResponse, response } = useSearchContext();
  const { filteredRows, filter } = useTableContext();

  const onClick = async () => {
    setLoading(true);
    setTimeout(downloadPdf, 200);
  };

  const downloadPdf = async () => {
    try {
      const asPdf = pdf();
      // const doc = <PdfExportUtils attributes={attributes} rawResponse={rawResponse} response={response} />;
      const doc = (
        <PdfExportUtils attributes={attributes} rawResponse={{ orders: filteredRows }} response={{ orders: filteredRows }} filter={filter} />
      );
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const pdfURL = getUrl(blob);

      Object.assign(document.createElement('a'), {
        href: pdfURL,
        download: `AMGKP-Suchergebnis-${moment().format('YYYYMMDDHHmmss')}.pdf`
      }).click();

      setLoading(false);
    } catch (e) {
      console.error(`error downloading pdf report: ${e}`);
      setLoading(false);
    }
  };

  return (
    <DHLButton
      loadingState={loading}
      size="xs"
      type="primary"
      label={t('button')}
      icon="pdf"
      iconPosition="icon-first"
      onClick={onClick}
      disabled={!hasPermission(UserRights.OrderExport) || !response?.orders?.length}
    />
  );
};

export default ExportPdfButton;
