import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'common/components/Button';
import styles from './SearchName.module.css';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { HistoryItem } from 'order/common/context/search/dtos/HistoryItem';

export interface SearchNameProps {
  historyItem: HistoryItem;
}

export const SearchName = ({ historyItem }: SearchNameProps) => {
  const [editing, setEditing] = useState(false);
  const [searchName, setSearchName] = useState<string | undefined>(historyItem?.searchName);
  const { searchHistory, triggerSearchHistoryUpdate } = useSearchContext();
  const ipRef = useRef<HTMLInputElement>(null);
  const measureRef = useRef<HTMLInputElement>(null);

  const changeSearchName = (action: 'UPDATE' | 'DEL') => {
    let itemToBeChanged = searchHistory?.find((item) => item?.id === historyItem?.id);
    if (itemToBeChanged) {
      if (action === 'DEL') {
        itemToBeChanged.searchName = undefined;
      } else {
        itemToBeChanged = {
          ...itemToBeChanged,
          searchName: searchName
        };
      }
      const searchHistoryWithoutItemToBeChanged = searchHistory.filter((item) => item?.id !== historyItem?.id);
      const searchHistoryTemp = [...searchHistoryWithoutItemToBeChanged, itemToBeChanged];
      triggerSearchHistoryUpdate(searchHistoryTemp, historyItem.id);
    }
  };

  const commonDelButton = () => (
    <Button
      sizing="xs"
      dataTestId="delName"
      className={`${styles.customBtn} ${styles.customIconBtn}`}
      onClick={() => {
        setSearchName(undefined);
        changeSearchName('DEL');
        setEditing(false);
      }}
      JSXIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
          <polygon
            fill="#878787"
            fillRule="evenodd"
            points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
          ></polygon>
        </svg>
      }
      variant="ghost"
      type="submit"
    />
  );

  useEffect(() => {
    if (editing) {
      setSearchName(historyItem?.searchName);
      ipRef.current?.focus();
    }
  }, [editing]);

  return (
    <>
      <div className={`${styles.searchNameContainer}`}>
        {editing ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 'fit-content'
              }}
            >
              <div data-testid="editingNameDiv" className={`${styles.editableDiv}`} ref={measureRef}>
                {searchName}
              </div>
              <input
                className={`${styles.customInput}`}
                data-testid="nameInput"
                value={searchName}
                ref={ipRef}
                spellCheck={false}
                maxLength={80}
                size={1}
                onChange={(e) => {
                  setSearchName(e?.target?.value.slice(0, 80));
                }}
              />
            </div>
            <div className={styles.ipBtns}>
              <Button
                sizing="xs"
                name="saveName"
                dataTestId="saveName"
                className={`${styles.customBtn} ${styles.customIconBtn}`}
                onClick={() => {
                  changeSearchName('UPDATE');
                  setEditing(false);
                }}
                JSXIcon={
                  <svg width="22" height="24" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.9675 12.7958L19.7637 0L21.1777 1.41398L7 15.5917L0 8.59167L1.3817 7.20998L6.9675 12.7958Z"
                      fill="#878787"
                      fillRule="evenodd"
                    />
                  </svg>
                }
                variant="ghost"
                type="submit"
              />
              {commonDelButton()}
            </div>
          </>
        ) : (
          <>
            {historyItem?.searchName && searchName ? (
              <div className={styles.searchName}>
                <span className={styles.existingNameSpan} data-testid="existingNameSpan">
                  {historyItem.searchName}
                </span>
                <Button
                  name="editName"
                  dataTestId="editName"
                  sizing="xs"
                  className={`${styles.customBtn} ${styles.customIconBtn}`}
                  onClick={() => {
                    setEditing(true);
                  }}
                  JSXIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" fill="none" height="21" viewBox="0 0 24 24">
                      <path
                        fill="#878787"
                        fillRule="evenodd"
                        d="M1.07148,18.9618 L5.03844,22.92876 L0,23.95098 L1.07148,18.9618 Z M19.96746,-1.90070182e-13 L24,4.03518 L5.70972,22.32714 L1.67448,18.2919 L19.96746,-1.90070182e-13 Z"
                      ></path>
                    </svg>
                  }
                  variant="ghost"
                  type="submit"
                />
                {commonDelButton()}
              </div>
            ) : (
              <Button
                name="addNewName"
                sizing="xs"
                variant="ghost"
                dataTestId="addNewName"
                className={`${styles.customBtn} ${styles.addNewName}`}
                onClick={() => {
                  setEditing(!editing);
                }}
                label={'+ Suchbezeichnung hinzufügen'}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
