// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-letter-service_row__MxeA- {
  display: flex;
  gap: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.additional-letter-service_row__MxeA- > * {
  flex-grow: 1;
  flex-basis: 0;
}

.additional-letter-service_row__MxeA- input {
  width: 100%;
}

.additional-letter-service_heading__ivp2m {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.additional-letter-service_addButton__uCkdr {
  border: inherit;
  background: inherit;
  margin-top: 10px;
  cursor: pointer;
}

.additional-letter-service_productName__NUi-M {
  font-weight: 500;
  font-size: 15px !important;
}
.additional-letter-service_infoForTooltip__A8mg7 {
  line-height: 1.1;
}

.additional-letter-service_infoForTooltip__A8mg7::before {
  font-size: 20px;
}

.additional-letter-service_mr10__tTAla {
  margin-right: 10rem;
}

.additional-letter-service_errorMessage__2Aiu5 {
  line-height: 1.25;
  margin-bottom: 10px;
}
.additional-letter-service_pl15__CIlHy {
  padding-left: 15px;
}
.additional-letter-service_pl30__jU5wv {
  padding-left: 30px;
}
.additional-letter-service_parentDiv__NAdZv .additional-letter-service_threeCustomUnevenCol__VyBtC {
  grid-template-columns: 0.8fr 1.5fr 0.7fr;
}
.additional-letter-service_parentDiv__NAdZv .additional-letter-service_twoCustomUnevenCol__d7TUW {
  grid-template-columns: 1.5fr 0.5fr;
  gap: 0;
}
.additional-letter-service_parentDiv__NAdZv .additional-letter-service_twoCustomUnevenColOption2__woLPM {
  grid-template-columns: 0.6fr 1.4fr;
}

.additional-letter-service_aslAlignment__p2nUN {
  padding: 0 35px;
}

.additional-letter-service_aslDeleteBtn__R4n58 {
  position: absolute;
  right: 0;
  top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/international/components/AdditionalLetterService/additional-letter-service.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,kCAAkC;EAClC,MAAM;AACR;AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;AACV","sourcesContent":[".row {\n  display: flex;\n  gap: 20px;\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n}\n\n.row > * {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.row input {\n  width: 100%;\n}\n\n.heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.addButton {\n  border: inherit;\n  background: inherit;\n  margin-top: 10px;\n  cursor: pointer;\n}\n\n.productName {\n  font-weight: 500;\n  font-size: 15px !important;\n}\n.infoForTooltip {\n  line-height: 1.1;\n}\n\n.infoForTooltip::before {\n  font-size: 20px;\n}\n\n.mr10 {\n  margin-right: 10rem;\n}\n\n.errorMessage {\n  line-height: 1.25;\n  margin-bottom: 10px;\n}\n.pl15 {\n  padding-left: 15px;\n}\n.pl30 {\n  padding-left: 30px;\n}\n.parentDiv .threeCustomUnevenCol {\n  grid-template-columns: 0.8fr 1.5fr 0.7fr;\n}\n.parentDiv .twoCustomUnevenCol {\n  grid-template-columns: 1.5fr 0.5fr;\n  gap: 0;\n}\n.parentDiv .twoCustomUnevenColOption2 {\n  grid-template-columns: 0.6fr 1.4fr;\n}\n\n.aslAlignment {\n  padding: 0 35px;\n}\n\n.aslDeleteBtn {\n  position: absolute;\n  right: 0;\n  top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `additional-letter-service_row__MxeA-`,
	"heading": `additional-letter-service_heading__ivp2m`,
	"addButton": `additional-letter-service_addButton__uCkdr`,
	"productName": `additional-letter-service_productName__NUi-M`,
	"infoForTooltip": `additional-letter-service_infoForTooltip__A8mg7`,
	"mr10": `additional-letter-service_mr10__tTAla`,
	"errorMessage": `additional-letter-service_errorMessage__2Aiu5`,
	"pl15": `additional-letter-service_pl15__CIlHy`,
	"pl30": `additional-letter-service_pl30__jU5wv`,
	"parentDiv": `additional-letter-service_parentDiv__NAdZv`,
	"threeCustomUnevenCol": `additional-letter-service_threeCustomUnevenCol__VyBtC`,
	"twoCustomUnevenCol": `additional-letter-service_twoCustomUnevenCol__d7TUW`,
	"twoCustomUnevenColOption2": `additional-letter-service_twoCustomUnevenColOption2__woLPM`,
	"aslAlignment": `additional-letter-service_aslAlignment__p2nUN`,
	"aslDeleteBtn": `additional-letter-service_aslDeleteBtn__R4n58`
};
export default ___CSS_LOADER_EXPORT___;
