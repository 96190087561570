import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { useAlerts } from 'common/AlertContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { deepClone } from 'common/utils/deepClone';
import { useCounter } from 'common/utils/hooks';
import i18n, { useTranslation } from 'i18n';
import { Header } from 'order/common/components/Header';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { OrderStepProvider } from 'order/common/services/OrderStepProvider';
import { useSearchParams } from 'order/common/hooks/useSearchParams';
import { OrderCopySteps } from 'order/orderCopy/common/components/OrderCopySteps';
import { getOrder } from 'order/common/context/order/utils/getOrder';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { CopyOrderSchema } from '../../../schema/copyOrderSchema';
import { LoadingOrder } from 'common/components/LoadingOrder/LoadingOrder';

import orderClasses from 'common/styles/order.module.css';
import classes from './order-copy.module.css';
import { OrderCategoryProductKey } from '../../../../common/context/order/dtos/OrderCategoryProductKey';
import { ErrorMessageContainer } from '../../../../productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';
import { SpaceErrorAlerts } from '../../../../common/components/Alerts/SpaceErrorAlerts';
import { UserRights } from 'common/dtos/userRights';

export type OrderCopyProps = {
  text?: string;
};

export type OrderCopyRouteParams = {
  orderId: string;
};

const translationDE = {
  orderCanNotCopy: 'Dieser Auftrag kann nicht kopiert werden.',
  orderCopyHeadline: 'Auftragserfassung - Kopie'
};
const translationEN = {
  orderCanNotCopy: 'This order can not be copied.',
  orderCopyHeadline: 'Order Create - Copy'
};

i18n.addResourceBundle('de', 'OrderCopy', translationDE);
i18n.addResourceBundle('en', 'OrderCopy', translationEN);

export const OrderCopy: React.VFC<OrderCopyProps> = () => {
  const methods = useForm<CopyOrderSchema>();
  const orderNumberLoading = useRef<string | undefined>(undefined);
  const [orderLoading, setOrderLoading] = useState(true);
  const rawOrder = useRef<OrderResponse | undefined>(undefined);
  const useCounterReturn = useCounter(1, 0, 3);
  const { counter: step } = useCounterReturn;
  const { alerts, clear } = useAlerts();
  const {
    setOrderMode,
    setProductGroup,
    setOrderCategory,
    setOrderCategoryProductKey,
    setOrder,
    setOrderId,
    orderCategory,
    productGroup,
    orderId,
    order,
    resetOrderReferences,
    reset,
    resetAllocations
  } = useOrderContext();
  const { checkWhitelistingKey, hasPermission, checkProductionStateWhitelistingCopy } = useAuthContext();
  const { orderId: orderIdParam } = useParams<OrderCopyRouteParams>();
  const { t } = useTranslation('OrderCopy');
  const { t: oc } = useTranslation('orderCreate');
  const isPartial = useSearchParams('orderType')?.includes('ZA');

  const fetchOrder = useCallback(async (id: string) => {
    const data = isPartial ? await getOrder<OrderResponse>(id) : await getOrder<OrderResponse>(id, { includeDst: true });
    if (data?.orderId) {
      processOrderData(data);
      rawOrder.current = data;
    }
    setOrderId(id);
    setOrderLoading(false);
  }, []);

  const handleExistingOrderData = useCallback((orderData: OrderResponse) => {
    orderData && processOrderData(orderData);
    setOrderLoading(false);
  }, []);

  const processOrderData = (orderData: OrderResponse) => {
    let clonedOrder = deepClone(orderData);
    setProductGroup(clonedOrder.productGroup);
    setOrderCategory(clonedOrder.orderCategory);
    setOrderCategoryProductKey(clonedOrder.orderCategoryProductKey);
    if (clonedOrder.orderCategory === OrderCategory.AFM_BRIEF) {
      clonedOrder?.letterBaseProducts?.forEach((b) => {
        b.baseProduct = {
          ...b.baseProduct,
          quantity: 0
        } as any;
        b.destinations = [
          {
            from: 0,
            to: 0,
            zip: 'DE',
            quantity: 0
          }
        ] as any[];
        /** Fix for OrderCopy IR checkbox
         b.discounts = []; **/
      });
    } else if (clonedOrder.orderCategoryProductKey === OrderCategoryProductKey.PRESSDISTRIBUTION) {
      clonedOrder = {
        ...clonedOrder,
        variants: [],
        orderLabel: undefined
      };
    }
    rawOrder.current = {
      ...clonedOrder,
      orderId: undefined,
      customerOrderId: undefined,
      otherAttributes: {}
    } as any;
    setOrder(rawOrder.current);
  };

  useEffect(() => {
    setOrderMode(OrderMode.COPY);
    if (orderLoading && orderId?.toString() !== orderIdParam.toString() && orderNumberLoading.current !== orderIdParam) {
      reset();
      resetOrderReferences();
      resetAllocations();
      orderNumberLoading.current = orderIdParam;
      fetchOrder(orderIdParam);
    } else if (orderLoading && order && orderId?.toString() === orderIdParam.toString()) {
      handleExistingOrderData(order as any);
    }
  }, [orderIdParam, orderId, orderLoading, order]);

  useEffect(() => {
    clear();
  }, [step]);

  if (orderLoading) {
    return <LoadingOrder headline={oc('headline.orderCopy')} />;
  }

  if (!orderLoading && !rawOrder.current) {
    return <ErrorMessageContainer message={oc('errors.notFound')} dataTestId={'error-order-not-found'} />;
  }

  if (!orderLoading) {
    const orderCopy =
      hasPermission(UserRights.OrderEdit) &&
      order?.orderCategoryProductKey &&
      checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.COPY) &&
      order?.productionState &&
      checkProductionStateWhitelistingCopy(order.productionState);
    if (!orderCopy) {
      return <ErrorMessageContainer message={t('orderCanNotCopy')} dataTestId={'error-order-can-not-be-copied'} />;
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Header type={OrderMode.COPY} activeStep={step} productGroup={productGroup} orderCategory={orderCategory} />
        <SpaceErrorAlerts alerts={alerts} />
        <div className={orderClasses.dialog}>
          <OrderStepProvider {...useCounterReturn}>
            <FormProvider {...methods}>
              <OrderCopySteps orderData={rawOrder.current} />
            </FormProvider>
          </OrderStepProvider>
        </div>
      </div>
    </>
  );
};
