import React from 'react';
import { useTranslation } from 'i18n';
import { Order, sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { FC } from 'react';
import { IdAssignment } from 'order/common/components/QtyAllocation/IdAssignment';
import { Accordion, AccordionButtonOverview, AccordionButtonOverviewDisabled, AccordionItemOverview } from 'common/components/Accordion';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { LetterBaseProduct, Product } from 'generated';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { Destination } from 'order/common/context/order/dtos/GetOrder';
import { calculateRanges } from 'order/common/utils/processIdRanges';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useAdditionalServices } from 'order/common/hooks/useAdditionalServices';
import { UseDVBriefProductsReturn } from 'order/productGroups/letter/utils/useDVBriefProducts';
import { IdAssignmentReadOnlyTable } from '../../QtyAllocation/IdAssignmentReadOnlyTable';

export type LetterBaseProductSectionProps = {
  value: Order;
  dvBriefProducts?: UseDVBriefProductsReturn;
};

export const LetterBaseProductSection: FC<LetterBaseProductSectionProps> = ({ value, dvBriefProducts }) => {
  const language = useAuthContext().user.language;
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { t: trans } = useTranslation('translation');

  // TODO provide proper enum for encoding
  const hasNonStandardEncoding =
    (value?.postage?.frankingIdEncoding as any) === 'HEX' ||
    (value?.postage?.frankingIdEncoding as any) === 'C40' ||
    (value?.postage?.frankingIdEncoding as any) === 'DEC';
  const date = value?.orderDetail?.date;
  const baseProducts = useBaseProducts(value?.letterProductGroup, date);
  const getBaseProduct = (productNumber?: string) => baseProducts.find(({ productNumber: p }) => p === productNumber);
  const baseProductOthers = useBaseProducts('OTHER', date, 'DEPENDENT');
  const digital = baseProductOthers.find((x) => x.variant === 'DIGITAL_COPY_NOTICE' && x.basicFormat === '');
  const renderFurtherProductCharacteristics = (product: LetterBaseProduct) => {
    const digitalCopy = product.discounts?.some((d) => d.productNumber === digital?.productNumber);
    if (digitalCopy) return `${t('digitalCopy')}`;
    return '';
  };
  const letterBaseProducts = value.letterBaseProducts;
  const sortedLetterBaseProducts = letterBaseProducts?.sort(
    (a, b) =>
      sortLetterTypeList.indexOf(a.baseProduct.description?.toLowerCase() || '') -
      sortLetterTypeList.indexOf(b.baseProduct.description?.toLowerCase() || '')
  );
  const additionalLetterServicesNumbers = useAdditionalServices(ProductGroup.BRIEF, date, 'SPECIAL_LETTER', false);
  const renderAdditionalLetterServices = (id?: string) => {
    // changing the mapping from dependant products to letterBaseProducts discounts array in the getOrder response will break the additional letter services per order. AM-23365
    const filteredProducts = value?.letterBaseProducts
      ?.find((lBP) => lBP?.baseProduct?.referenceShipmentId === id)
      ?.discounts?.filter((dP) => {
        if (id) return dP.referenceShipmentId === id;
        return !dP.referenceShipmentId;
      })
      .filter((dP) => additionalLetterServicesNumbers?.map((service) => service.productNumber).includes(dP.productNumber || ''));

    return (
      <table>
        <tbody>
          {filteredProducts?.map((fP, i) => (
            <tr key={i}>
              <td>
                {trans(additionalLetterServicesNumbers?.find((service) => service.productNumber === fP.productNumber)?.descriptionShort ?? '')}:
              </td>
              <td>{fP.shipmentQuantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const isDVBrief = value?.orderCategory === OrderCategory.DV_BRIEF;

  const isAdditionalServiceForLetterAvailable =
    (!dvBriefProducts?.dvPZA && !dvBriefProducts?.dvBook && !dvBriefProducts?.dvEPostBrief && !dvBriefProducts?.dvStrip) || !isDVBrief;

  // Shouble be available for all DV products
  const isAdditionalProductFeaturesAvailable =
    dvBriefProducts?.dvPZA ||
    dvBriefProducts?.dvBook ||
    dvBriefProducts?.dvEPostBrief ||
    dvBriefProducts?.dvStrip ||
    dvBriefProducts?.dvInternational ||
    dvBriefProducts?.dvBlind ||
    dvBriefProducts?.dvDVD ||
    dvBriefProducts?.dvBrief ||
    !isDVBrief;

  return (
    <>
      {sortedLetterBaseProducts?.map((product, index) => {
        const hasDestinations = hasNonStandardEncoding || calculateRanges((product.destinations as Destination[]) || []);
        return (
          <React.Fragment key={index}>
            <OrderOverviewRow noMargin={index !== 0}>
              <OrderOverviewWidget title={index === 0 ? 'Produkte' : ''} width={2}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {t('basicProduct')} {index + 1}:
                      </td>
                      <td>{getBaseProduct(product.baseProduct.productNumber)?.descriptionShort}</td>
                    </tr>
                    <tr>
                      <td>{t('shipmentVolume')}</td>
                      <td>{product.baseProduct?.quantity?.toLocaleString(language)}</td>
                    </tr>
                    {isAdditionalProductFeaturesAvailable && (
                      <tr>
                        <td>{t('furtherProductCharacteristics')}:</td>
                        <td data-testid={'further-product-characteristics-td'}>{renderFurtherProductCharacteristics(product)}</td>
                      </tr>
                    )}
                    {isAdditionalServiceForLetterAvailable ? (
                      <tr>
                        <td>{t('additionalLetterServicesForBaseProduct')}:</td>
                        <td data-testid={'further-product-characteristics-for-DV-td'}>
                          {renderAdditionalLetterServices(product.baseProduct.referenceShipmentId?.toString())}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </OrderOverviewWidget>
            </OrderOverviewRow>
            <OrderOverviewRow noMargin>
              <OrderOverviewWidget width={2}>
                {hasDestinations && product.destinations?.length ? (
                  <Accordion allowMultiple={true}>
                    {(register) => (
                      <>
                        <AccordionItemOverview {...register}>
                          <AccordionButtonOverview disabled={!product?.destinations?.length}>{t('deliveryPlan')}</AccordionButtonOverview>
                          <IdAssignmentReadOnlyTable
                            header={value.orderCategory === OrderCategory.AFM_BRIEF ? 'machineCode' : 'frankingIdPrefix'}
                            ranges={product.destinations}
                            isLetter={true}
                            readOnly={true}
                            frankingIdPrefix={value.postage?.machineCode ?? value.postage?.frankingIdPrefix}
                            frankingIdEncoding={value.postage?.frankingIdEncoding}
                            machineCode={value.orderCategory === OrderCategory.AFM_BRIEF ? value.postage?.machineCode : undefined}
                            postageFrankingIdPrefix={value.orderCategory === OrderCategory.DV_BRIEF ? value.postage?.frankingIdPrefix : undefined}
                            meta={{ language }}
                            id={`${product.baseProduct.productNumber}${index}`}
                          />
                        </AccordionItemOverview>
                      </>
                    )}
                  </Accordion>
                ) : (
                  <AccordionButtonOverviewDisabled>{t('deliveryPlan')}</AccordionButtonOverviewDisabled>
                )}
              </OrderOverviewWidget>
            </OrderOverviewRow>
          </React.Fragment>
        );
      })}
      {isAdditionalServiceForLetterAvailable && (
        <OrderOverviewRow>
          <OrderOverviewWidget title={t('additionalLetterServices')} width={2}>
            {renderAdditionalLetterServices()}
          </OrderOverviewWidget>
        </OrderOverviewRow>
      )}
    </>
  );
};
