import React, { VFC } from 'react';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderCreateStep1 } from '../OrderCreateStep1';
import { PressStep2 } from 'order/productGroups/press/workFlowSteps/step2/PressStep2';
import { PressStep3 } from 'order/productGroups/press/workFlowSteps/step3/PressStep3';
import { PressStep4 } from 'order/productGroups/press/workFlowSteps/step4/PressStep4';
import { LetterStep2 } from 'order/productGroups/letter/workFlowSteps/Step2/LetterStep2';
import { LetterStep3 } from 'order/productGroups/letter/workFlowSteps/Step3/LetterStep3';
import { LetterStep4 } from 'order/productGroups/letter/workFlowSteps/Step4/LetterStep4';
import { DialogMarketingStep2 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2';
import { DialogMarketingStep3 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step3/DialogMarketingStep3';
import { DialogMarketingStep4 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step4/DialogMarketingStep4';
import { AdditionalOrdersStep2 } from 'order/productGroups/additionalOrders/workFlowSteps/step2/additionalOrdersStep2';
import { AdditionalOrdersStep3 } from 'order/productGroups/additionalOrders/workFlowSteps/step3/additionalOrdersStep3';
import { AdditionalOrdersStep4 } from 'order/productGroups/additionalOrders/workFlowSteps/step4/additionalOrdersStep4';
import { DefaultOrderCreateStep1 } from './DefaultOrderCreateStep1/DefaultOrderCreateStep1';
import { InternationalClusterStep2 } from 'order/productGroups/international/workFlowSteps/Step2/InternationalClusterStep2';
import { InternationalClusterStep3 } from 'order/productGroups/international/workFlowSteps/Step3/InternationalClusterStep3';
import { InternationalClusterStep4 } from 'order/productGroups/international/workFlowSteps/Step4/InternationalClusterStep4';

const components: Record<OrderCreate['productGroup'], VFC[]> = {
  DIALOGPOST: [OrderCreateStep1, DialogMarketingStep2, DialogMarketingStep3, DialogMarketingStep4],
  DIALOGPOSTWURFSPEZIAL: [OrderCreateStep1, DialogMarketingStep2, DialogMarketingStep3, DialogMarketingStep4],
  PRESS_DISTRIBUTION: [OrderCreateStep1, PressStep2, PressStep3, PressStep4],
  PARTIAL: [OrderCreateStep1, AdditionalOrdersStep2, AdditionalOrdersStep3, AdditionalOrdersStep4],
  BRIEF: [OrderCreateStep1, LetterStep2, LetterStep3, LetterStep4],
  COLLECTION: [OrderCreateStep1, AdditionalOrdersStep2, AdditionalOrdersStep3, AdditionalOrdersStep4],
  BRIEF_INTERNATIONAL: [OrderCreateStep1, InternationalClusterStep2, InternationalClusterStep3, InternationalClusterStep4],
  IP_INTERNATIONAL: [OrderCreateStep1, InternationalClusterStep2, InternationalClusterStep3, InternationalClusterStep4],
  PUB_INTERNATIONAL: [OrderCreateStep1, InternationalClusterStep2, InternationalClusterStep3, InternationalClusterStep4],
  DEFAULT: [OrderCreateStep1, DefaultOrderCreateStep1]
};

export const OrderCreateSteps: VFC = () => {
  const { step } = useOrderStep();
  const { control } = useFormContext<OrderCreate>();
  const productGroup = useWatch({ control, name: 'productGroup', defaultValue: 'DEFAULT' }) as OrderCreate['productGroup'];
  const Component = components[productGroup][step];
  return <Component key={`step${step}`} />;
};
