import { DHLButton, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { isValidInteger } from 'common/utils/formHelpers';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Controller, ControllerFieldState, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './additional-letter-service.module.css';
import { Product } from 'order/productGroups/common/services/ProductService';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';
import { AMDHLSelectSingle } from '../../../../../common/components/AMDHLSelectSingle';
import { DependentProduct } from 'generated';
import { OrderCreate } from '../../../common/utils/orderCreateSchema';
import { useSpecialLetterProducts } from '../../../../common/hooks/useSpecialLetterProducts';
import { ProductGroup } from 'order/common/dtos/ProductGroup';

export type AdditionalLetterServiceProps = {
  title?: string;
  maxAmount?: number;
  name: `transportServices.${number}.discounts` | `internationalProducts.${number}.discounts`;
  disabled?: boolean;
  onDelete?: (index: number, baseProduct: Product, isLast: boolean) => void;
  productNumber?: string;
  selectedDate?: Date;
  productGroup?: string;
};
export enum ALSBaseProductsIntPress {
  'Rückschein Intern. Presse&Buch' = 'Rückschein',
  'Eigenhändig Presse&Buch' = 'Eigenhändig',
  'Einschreiben Intern. Presse&Buch' = 'Einschreiben'
}
export enum AdditionalLetterBaseProducts {
  Rückschein = 'Rückschein',
  Eigenhändig = 'Eigenhändig',
  Übergabeeinschreiben = 'Einschreiben'
}
export const AdditionalLetterService = ({
  title,
  maxAmount = 999,
  name,
  disabled,
  productNumber,
  selectedDate,
  productGroup,
  ...props
}: AdditionalLetterServiceProps): ReactElement => {
  const { control, trigger, setValue } = useOrderTransferForm<OrderCreate>();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const {
    fields: addLetterServiceFields,
    remove: removeAddLetterServiceFields,
    append: appendAddLetterServiceFields
  } = useFieldArray({ control, name });
  const availableLetServProds = ['Rückschein', 'Eigenhändig', 'Übergabeeinschreiben', 'Einschreiben'];
  const [loaded, setLoaded] = useState(false);
  const [designationInc, setDesignationInc] = useState<number>(1);
  const addLetServProds = useSpecialLetterProducts(productNumber, selectedDate).filter((val) =>
    productGroup === ProductGroup.PRESS_DISTRIBUTION
      ? availableLetServProds.find((a) => val.descriptionShort?.includes(a))
      : availableLetServProds.includes(val?.hierarchyProductCharacteristic ?? '')
  );
  const values = useWatch({
    control,
    name: name
  });
  type WatchedProduct = { id: string } & DependentProduct;
  let watchedFields = useRef<WatchedProduct[]>([]).current;
  if (values) {
    watchedFields = addLetterServiceFields.map(({ id }, index) => ({
      id,
      ...values[index]
    }));
  }

  useEffect(() => {
    const unUsedProd = addLetServProds.filter((prod) => watchedFields.every((prodData) => prodData.productNumber !== prod.productNumber));

    if (watchedFields.length && !loaded) {
      setLoaded(true);
    }

    if (loaded && watchedFields.length && unUsedProd.length === 1) {
      watchedFields.forEach((watchedProduct, index, watchedProducts) => {
        if (watchedProduct && watchedProducts && !watchedProduct.productNumber) {
          setValue(`${name}.${index}.productNumber`, unUsedProd[0].productNumber);
        }
      });
    }
  }, [watchedFields, loaded, addLetServProds]);

  const unUsedAslProd = addLetServProds.filter((prod) => watchedFields.every((prodData) => prodData.productNumber !== prod.productNumber));

  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? t(`error.${fieldState.error.message}`) : undefined;
  return (
    <>
      {title && watchedFields.length > 0 && (
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'} className={classes.aslAlignment}>
          {title}
        </DHLHeadline>
      )}
      {watchedFields.map((additionalLetterServiceProductField, index) => {
        return (
          // Important: for correct re-rendering in case of added or removed items it is necessary to use the id as key!
          <div key={`${name}.${additionalLetterServiceProductField.id}`} data-testid={`${name}.${index}`} className={classes.parentDiv}>
            <div style={{ position: 'relative' }}>
              <FormRow mode="two-col" className={classes.aslAlignment}>
                <Controller
                  name={`${name}.${index}.productNumber`}
                  rules={{
                    required: {
                      value: true,
                      message: 'baseProductInput.productNumber.empty'
                    }
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <div>
                      <AMDHLSelectSingle
                        name={`${name}.${index}.productNumber`}
                        items={unUsedAslProd}
                        placeholder={`${oc('additionalLetterService')}*`}
                        valueToString={(option) =>
                          productGroup === ProductGroup.PRESS_DISTRIBUTION
                            ? ALSBaseProductsIntPress[option?.descriptionShort as keyof typeof ALSBaseProductsIntPress] || ''
                            : AdditionalLetterBaseProducts[option?.hierarchyProductCharacteristic as keyof typeof AdditionalLetterBaseProducts] || ''
                        }
                        value={addLetServProds.find((product1) => product1.productNumber === field.value)}
                        error={getErrorMessage()({ fieldState })}
                        onChange={(item) => {
                          setValue(`${name}.${index}.productNumber`, item?.productNumber);
                          trigger(`${name}.${index}.productNumber`);
                        }}
                        data-testid={`${name}.${index}.productNumber`}
                        disabled={disabled}
                      />
                    </div>
                  )}
                />
                <TextInput
                  type="number"
                  name={`${name}.${index}.shipmentQuantity`}
                  label={`${oc('shipmentVolume')}`}
                  rules={{
                    required: {
                      value: true,
                      message: 'baseProductInput.quantity.empty'
                    },
                    validate: isValidInteger,
                    max: { value: 1e9 - 1, message: 'baseProductInput.quantity.tooBig' },
                    min: { value: 1, message: 'min' }
                  }}
                  error={getErrorMessage()}
                  onChange={(e) => {
                    trigger(`${name}.${index}.shipmentQuantity`);
                  }}
                  disabled={disabled}
                />
              </FormRow>
              <div className={classes.aslDeleteBtn}>
                <DHLButton
                  icon="delete"
                  iconPosition={'icon'}
                  size="xs"
                  onClick={() => {
                    setValue(`${name}.${index}.productNumber`, '');
                    setValue(`${name}.${index}.shipmentQuantity`, undefined);
                    removeAddLetterServiceFields(index);
                  }}
                  name={`asl-${index}-delete`}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        );
      })}
      {unUsedAslProd.length > 0 && (
        <div className={watchedFields.length > 0 ? classes.aslAlignment : ''}>
          <DHLButton
            name="asl-add"
            size="xs"
            type="ghost"
            icon="plus"
            iconPosition={'icon-first'}
            label={watchedFields.length > 0 ? `${oc('addMoreAdditionalLetterService')}` : `${oc('addAdditionalLetterService')}`}
            onClick={() => {
              setDesignationInc(designationInc + 1);
              appendAddLetterServiceFields({} as DependentProduct);
            }}
            className={classes.addButton}
            disabled={disabled || !addLetServProds.length}
          />
        </div>
      )}
    </>
  );
};
