import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import ChangeLogMD from '../CHANGELOG.md';
import classes from './changelog.module.css';

export const ChangeLog = (): JSX.Element => {
  const [markdown, setMarkdown] = useState<string>('');
  useEffect(() => {
    fetch(ChangeLogMD)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdown(text);
      });
  }, []);
  return (
    <div className={classes.container}>
      <Markdown>{markdown}</Markdown>
    </div>
  );
};
