import { DHLCheckbox, DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { FormRow } from 'common/components/FormRow';
import { ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import { rangeArray } from 'common/utils/helpers';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import React, { Dispatch, ReactElement, useEffect, useState, VFC } from 'react';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './AMDHLDestinationInput.module.css';
import { DestinationSchema } from './destinationSchema';
import { calculateRegions, Regions } from './CalculateRegions';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';
import { OrderMode } from '../../../../../common/dtos/OrderMode';

export type AMDHLDestinationInputProps<T extends FieldValues> = SFCProps<T, DestinationSchema[] | undefined> & {
  disabled?: boolean;
  showSLN?: boolean;
  productGroup?: ProductGroup;
  showOnlyDEZone?: boolean;
};

type Matrix = DestinationSchema[];

export const AMDHLDestinationInput = <T extends FieldValues>({ disabled, showSLN, ...props }: AMDHLDestinationInputProps<T>): ReactElement => {
  const DEACTIVATED_REGIONS = calculateRegions(props.productGroup);
  const SLN_REGIONS = Regions.SLN_REGIONS;
  const { field } = useController<ObjectContaining<Matrix | undefined>, string>(props as any);
  const [checks, setChecks] = useState<boolean[]>(rangeArray(11).map(() => false));
  const { value: matrix } = field;
  const isRowInMatrix = (matrix: Matrix, row: number) =>
    matrix.some(({ zipCode }) => (row === 10 ? zipCode === 'DE' : zipCode.length <= 2 && zipCode.startsWith(row.toString())));
  const { orderMode, upsertMetaData } = useOrderContext();
  const { trigger } = useFormContext();

  useEffect(() => {
    if (matrix?.length) {
      setChecks((checked) => checked.map<boolean>((_, index) => isRowInMatrix(matrix, index)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEntry = (zipCode: string) => field.value?.find(({ zipCode: z }) => z === zipCode);

  const onChangeEntry = (key: string, valueAsNumber: number | undefined, checked?: boolean) => {
    const filtered = field.value?.filter(({ zipCode: z }) => (!checked ? !z.startsWith(key) : z !== key)) || [];
    trigger('packaging.quantity' as any);
    upsertMetaData('netWeightChanged', true);
    if (typeof valueAsNumber === 'undefined') {
      field.onChange([...filtered]);
    } else {
      field.onChange([...filtered, { zipCode: key, quantity: valueAsNumber }]);
    }
  };
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });

  return (
    <div className={styles.destinationInput}>
      <FormRow mode="checkboxes" className={styles.checkBoxRow}>
        {checks.map((checked, destinationZone) => (
          <div key={destinationZone}>
            <DHLCheckbox
              name={'showZone-' + destinationZone}
              label={destinationZone < 10 ? destinationZone.toString() : '0-9'}
              value={checked}
              size={'slim'}
              onChange={() => {
                checks[destinationZone] = !checks[destinationZone];
                setChecks([...checks]);
                const key = destinationZone < 10 ? destinationZone.toString() : 'DE';
                onChangeEntry(key, undefined, checks[destinationZone]);
              }}
              disabled={disabled}
            />
          </div>
        ))}
      </FormRow>
      {checks.some((value) => value) && (
        <div className={styles.rowGap}>
          <div className={classNames(styles.zoneCol, styles.graySubHeader)}>
            <h6>{oc('numberOfPalletsPerRoutingZone')}</h6>
          </div>
          <div className={classNames(styles.regionCol, styles.graySubHeader)}>
            <h6>{oc('numberOfPalletsPerRoutingRegion')}</h6>
          </div>
        </div>
      )}
      {checks.map((checked, destinationZone) => {
        if (!checked) {
          return undefined;
        }
        const destinationZoneKey = destinationZone < 10 ? destinationZone.toString() : 'DE';
        return (
          <div key={destinationZone} className={styles.rowGap}>
            <div className={styles.zoneCol}>
              {!props.showOnlyDEZone && (
                <div className={styles.zoneInputContainer}>
                  <p className={styles.inputLabel}>{destinationZone < 10 ? destinationZone : '0-9'}</p>
                  <NumberInputField
                    onChange={(n) => onChangeEntry(destinationZoneKey, n)}
                    initialNumber={getEntry(destinationZoneKey)?.quantity}
                    name={'zone-' + destinationZoneKey}
                    disabled={disabled}
                    className={styles.zoneInput}
                  />
                </div>
              )}
              {props.showOnlyDEZone && checks.findIndex((elem) => elem) === destinationZone && checks[10] && (
                <div className={styles.zoneInputContainer}>
                  <p className={styles.inputLabel}>{'0-9'}</p>
                  <NumberInputField
                    onChange={(n) => onChangeEntry('DE', n)}
                    initialNumber={getEntry('DE')?.quantity}
                    name={'zone-DE'}
                    disabled={disabled}
                    className={styles.zoneInput}
                  />
                </div>
              )}
            </div>
            {destinationZone < 10 && (
              <div className={classNames(styles.inputRow, styles.regionCol)}>
                {rangeArray(10).map((destinationRegion, indexValue) => {
                  const regionKey = `${destinationZoneKey}${destinationRegion}`;
                  const disabledRegion = DEACTIVATED_REGIONS.includes(regionKey);
                  return (
                    <div key={destinationRegion} className={styles.paddingReset}>
                      <p className={styles.inputLabel}>{regionKey}</p>
                      <NumberInputField
                        onChange={(n) => onChangeEntry(regionKey, n)}
                        initialNumber={getEntry(regionKey)?.quantity}
                        disabled={disabled || disabledRegion}
                        name={'region-' + regionKey}
                        className={indexValue == 0 ? styles.borderRadiusLeft : indexValue == 9 ? styles.borderRadiusRight : styles.removeBorderRadius}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
      {showSLN && (
        <>
          <div className={styles.inputSpace}>
            <div className={styles.graySubHeader}>
              <h6>
                Anzahl Paletten je
                <br />
                SLN Depot
              </h6>
            </div>
          </div>
          <div key="snlDepotZone" className={styles.inputRow}>
            {SLN_REGIONS.map((destinationRegion, indexValue) => {
              const regionKey = `SLN${destinationRegion}`;
              return (
                <div key={regionKey} className={classNames(styles.alignLeitregionNum, styles.paddingReset)}>
                  <p className={styles.inputLabel}>{destinationRegion}</p>
                  <NumberInputField
                    onChange={(n) => onChangeEntry(regionKey, n)}
                    name={regionKey}
                    initialNumber={getEntry(regionKey)?.quantity}
                    className={indexValue == 0 ? styles.borderRadiusLeft : indexValue == 11 ? styles.borderRadiusRight : styles.removeBorderRadius}
                    disabled={disabled}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}

      {matrix?.some(({ zipCode }) => zipCode.length === 5 && !zipCode.startsWith('SLN')) && orderMode === OrderMode.CHANGE && (
        <>
          <p className="pt-4 m-0 pb-2" data-testid="fiveDigitZipCode">
            {oc('fiveDigitZipCode')}
          </p>
          <p className="pb-2 m-0 text-break">
            {matrix
              ?.filter(({ zipCode }) => zipCode.length === 5 && !zipCode.startsWith('SLN'))
              .map(({ zipCode }) => Number(zipCode))
              .sort((a, b) => a - b)
              .join('\u00A0\u00A0\u00A0\u00A0')}
          </p>
        </>
      )}
    </div>
  );
};

type NumberInputFieldProps = {
  onChange: Dispatch<number | undefined>;
  disabled?: boolean;
  initialNumber?: number;
  name?: string;
  className?: string;
};

const NumberInputField: VFC<NumberInputFieldProps> = (props) => {
  const [number, setNumber] = useState<number | undefined>(props.initialNumber);
  return (
    <DHLTextInput
      label=""
      name={props.name}
      value={number}
      onChange={(e) => {
        if (props.disabled) {
          return;
        }
        const { valueAsNumber } = e.target;
        if (isNaN(valueAsNumber)) {
          setNumber(undefined);
          return;
        }
        if (valueAsNumber >= 0) {
          setNumber(valueAsNumber);
        }
      }}
      type="number"
      className={props.className}
      disabled={props.disabled}
      onBlur={() => props.onChange(number)}
    />
  );
};
