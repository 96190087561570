import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { AMDHLInputWithProposals } from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { procedureCache } from 'common/services/ProcedureService';
import { usePrevious } from 'common/utils/hooks';
import { AMDHLInputFilterContainer } from 'order/orderSearch/components/AMDHLInputFilterContainer';
import { OrderSearchFilterConfiguration } from 'order/orderSearch/components/filter/OrderSearchFilterConfiguration';
import ProcedureComboBox from 'order/orderSearch/components/filter/ProcedureComboBox';
import { getValidator } from 'order/orderSearch/components/filter/validate';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { DefaultAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { useSearchContext } from '../../../common/context/search/SearchContext';
import { OrderSearchAttribute } from 'order/common/context/search/dtos/OrderSearchAttribute';
import { OrderTypeSelect } from './OrderTypeSelect';
import { OrderTypes } from '../OrderTypes';

import classes from './OrderFilter.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import OrderStatusSelect from './OrderStatusSelect';
import { ProductionState } from '../ProductionState';
import { useSearchRecommendations } from 'order/common/hooks/useSearchRecommendations';

export type AMDHLOrderSearchFilterSelectableTextInputProps = {
  searchKeys?: (OrderSearchKey | OrderSearchFilterConfiguration)[];
  placeholder?: string;
  additionalClass?: string;
  inputClass?: string;
  setSelectedAttr?: (arg: OrderSearchKey) => void;
  inputType?: 'number' | 'textarea' | 'text' | 'email' | 'password' | undefined;
  orderMode?: OrderMode;
  resetChild?: boolean;
  resetCallback?: () => void;
  nonNumeric?: boolean;
};

export const AMDHLOrderSearchFilterSelectableTextInput: FC<AMDHLOrderSearchFilterSelectableTextInputProps> = observer(
  ({ searchKeys = [], placeholder, additionalClass, inputClass, setSelectedAttr, inputType, orderMode, resetChild, resetCallback, nonNumeric }) => {
    const configurations = useMemo<OrderSearchFilterConfiguration[]>(
      () =>
        searchKeys?.map((key) => {
          return typeof key === 'object' ? key : { key };
        }),
      [searchKeys]
    );
    const [selectedAttrType, setSelectedAttrType] = useState<OrderSearchFilterConfiguration>(configurations[0]);
    const [errorKey, setErrorKey] = useState<OrderSearchKey | undefined>();
    const { t: translateError } = useTranslation('', { keyPrefix: 'orderSearch.formError' });
    const { t } = useTranslation('', { keyPrefix: 'orderSearch' });
    const { attributes, editedAttribute, addAttribute, replaceAttribute, searchType, newRecommendations, editAttribute, tab } = useSearchContext();
    const { recommendations, load, add, delete: remove } = useSearchRecommendations(selectedAttrType.key);
    const previousEditAttribute = usePrevious(editedAttribute);
    const previousTab = usePrevious(tab);
    const mandatoryAttributeUsed = attributes.some(
      (attr: OrderSearchAttribute) => attr.key !== OrderSearchKey.Date && !configurations.find((conf) => conf.key === attr.key)?.initiallyDeactivated
    );
    const [inputValue, setInputValue] = useState<DefaultAttribute>({
      value: '',
      key: selectedAttrType.key
    } as DefaultAttribute);

    useEffect(() => {
      setSelectedAttr?.(selectedAttrType?.key);
    }, [selectedAttrType?.key]);

    useEffect(() => {
      load();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttrType?.key]);

    useEffect(() => {
      if (!attributes.length && configurations) {
        setSelectedAttrType(configurations[0]);
      }
    }, [configurations, attributes.length]);

    useEffect(() => {
      if (editedAttribute !== previousEditAttribute) {
        editedAttribute?.value
          ? setSelectedAttrType({
              value: editedAttribute?.value,
              key: editedAttribute?.key
            } as DefaultAttribute)
          : setSelectedAttrType(configurations[0]);
        setInputValue({
          value: editedAttribute ? editedAttribute.value : '',
          key: editedAttribute ? editedAttribute.key : selectedAttrType.key
        } as DefaultAttribute);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedAttribute, previousEditAttribute]);

    useEffect(() => {
      if (newRecommendations) {
        (async () => {
          for (const attribute of newRecommendations) {
            if (attribute && attribute.key === selectedAttrType.key) {
              await add(attribute);
            }
          }
        })();
      }
    }, [newRecommendations]);

    useEffect(() => {
      if (resetChild || previousTab !== tab) {
        // Reset the internal state here
        setSelectedAttrType(configurations[0]);
        setErrorKey(undefined);

        setInputValue({
          value: '',
          key: configurations[0]?.key
        } as DefaultAttribute);

        editAttribute({
          value: '',
          key: OrderSearchKey.OrderId
        });
        // Call resetCallback if it's provided
        resetCallback && resetCallback();
      }
    }, [resetChild, resetCallback, tab]);

    function handleAttrTypeChanged(selectedType?: OrderSearchFilterConfiguration) {
      selectedType && setSelectedAttrType(selectedType);
      setInputValue({
        value: '',
        key: configurations[0]?.key
      } as DefaultAttribute);
      setErrorKey(undefined);
    }

    function handleAttrValueChanged(input: string) {
      const key = selectedAttrType?.key;
      if (input && key && key !== OrderSearchKey.Date) {
        const parseResult = getValidator(key, input);
        if (!parseResult.success) {
          setErrorKey(key);
          return;
        }
        setErrorKey(undefined);
        if (key === OrderSearchKey.OrderType || key === OrderSearchKey.ProductionState) {
          replaceAttribute({ key, value: input.toString().trim() }, true);
        } else {
          addAttribute({ key, value: input.toString().trim() });
        }
      }
    }

    const stringToValue = (input: string): DefaultAttribute | undefined => {
      const key = selectedAttrType?.key;
      if (input && key && key !== OrderSearchKey.Date) {
        const parseResult = getValidator(key, input);
        if (!parseResult.success) {
          setErrorKey(key);
          return;
        }
        setErrorKey(undefined);
        return { key, value: input.toString() };
      }
    };

    const valueToString = (input: DefaultAttribute): string => input.value + (input.meta ? ' ' + input.meta : '');

    if (!selectedAttrType?.key || selectedAttrType.key === OrderSearchKey.Date) {
      return <></>;
    }

    return (
      <>
        <AMDHLSelectSingle
          name="orderSearchFilterAttrSelectableOption"
          data-testid={'psl-attribute-selector'}
          items={configurations}
          value={selectedAttrType}
          onChange={handleAttrTypeChanged}
          className={classes.selectAttribute + ` ${inputClass || ''}`}
          wrapperClassName={`flex-grow-0 ${additionalClass || ''}`}
          itemDisabled={(value) => !!value.initiallyDeactivated && !mandatoryAttributeUsed}
          valueToString={(value) => t('label.' + value.key)}
          maxVisibleOptions={12}
        />
        <AMDHLInputFilterContainer>
          {selectedAttrType?.key === OrderSearchKey.Procedure ? (
            <ProcedureComboBox procedures={Array.from(procedureCache.values())} onSelect={handleAttrValueChanged} />
          ) : selectedAttrType?.key === OrderSearchKey.OrderType ? (
            <OrderTypeSelect
              defaultValue={(attributes.find((a) => a.key === OrderSearchKey.OrderType)?.value as OrderTypes) ?? OrderTypes.Einlieferungsauftrag}
              onSelect={handleAttrValueChanged}
            />
          ) : selectedAttrType?.key === OrderSearchKey.ProductionState ? (
            <OrderStatusSelect
              searchType={searchType}
              defaultValue={(attributes.find((a) => a.key === OrderSearchKey.ProductionState)?.value as ProductionState) ?? ''}
              onSelect={handleAttrValueChanged}
            />
          ) : (
            <AMDHLInputWithProposals
              name="orderSearchFilterAttrSelectableInput"
              value={inputValue}
              readOnly={false}
              required={false}
              inputType={inputType}
              nonNumeric={nonNumeric}
              options={recommendations}
              functionIcon="plus"
              error={errorKey && translateError(errorKey)}
              placeholder={placeholder || t('placeholder.' + selectedAttrType.key)}
              stringToValue={stringToValue}
              valueToString={valueToString}
              onChange={(c) => {
                addAttribute({ ...c, value: c.value.toString().trim() });

                setErrorKey(undefined);
                document.getElementById('button-search')?.focus();
              }}
              onDelete={(c) => remove(c)}
              inputClassName={`nextToSelect`}
              labelClassName={classes.fontSize12}
            />
          )}
        </AMDHLInputFilterContainer>
      </>
    );
  }
);
