// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-indicator_activityIndicatorContainer__PTlzA {
  width: 100%;
  min-height: 20vh;
  margin: auto;
  position: relative;
}

.activity-indicator_activityIndicator__FMZRF {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/order/common/components/ActivityIndicator/activity-indicator.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".activityIndicatorContainer {\n  width: 100%;\n  min-height: 20vh;\n  margin: auto;\n  position: relative;\n}\n\n.activityIndicator {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityIndicatorContainer": `activity-indicator_activityIndicatorContainer__PTlzA`,
	"activityIndicator": `activity-indicator_activityIndicator__FMZRF`
};
export default ___CSS_LOADER_EXPORT___;
