import React, { useEffect, useRef, useState } from 'react';
import { AMDHLOtherPartners } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/AMDHLOtherPartners';
import { AccordionItem, UseAccordionReturn } from 'common/components/Accordion';
import { AdditionalOrdersOrderCreate } from '../../../../productGroups/additionalOrders/schema/additionalOrdersSchema';
import { OrderCustomerRoleEnum } from '../../../../../generated';
import { useCustomerRolePermissions } from 'order/common/hooks/useCustomerRolePermissions';
import { useOrderContext } from '../../../../common/context/order/OrderContext';
import { OrderMode } from '../../../../common/dtos/OrderMode';
import { DialogMarketingOrderCreate } from 'order/productGroups/dialogMarketing/schema/dialogMarketingSchema';

interface Props {
  id: number;
  register: UseAccordionReturn<unknown>;
  name: 'otherPartners';
  disabled?: boolean;
}
export const AccordionItemOtherPartners = (props: Props): JSX.Element | null => {
  const [loaded, setLoaded] = useState(false);
  const data = props.register(props.id);
  const [partnerRoles, setPartnerRoles] = useState<OrderCustomerRoleEnum[]>([]);
  const [allowedRolesToDel, setAllowedRolesToDel] = useState<OrderCustomerRoleEnum[]>([]);
  // const partnerRoles = props.partnerRoles || PARTNER_ROLES;
  // ugly workaround due to race condition only occuring when navigation back from Step 3
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleOnOpen = () => {
    data.setOpen && data.setOpen(true);
  };
  const { orderMode, orderCategoryProductKey } = useOrderContext();
  const customerRoles = useCustomerRolePermissions();
  useEffect(() => {
    if (Object.keys(customerRoles).length) {
      for (const roleOnProd in customerRoles) {
        if (Object.prototype.hasOwnProperty.call(customerRoles, roleOnProd)) {
          for (const roleOnOrderCat in customerRoles[roleOnProd as unknown as keyof typeof customerRoles]) {
            if (roleOnOrderCat === orderCategoryProductKey) {
              setPartnerRoles(
                customerRoles[roleOnProd as unknown as keyof typeof customerRoles][roleOnOrderCat][
                  orderMode === OrderMode.CHANGE ? 'CHANGE' : 'CREATE'
                ] ?? []
              );
              setAllowedRolesToDel(customerRoles[roleOnProd as unknown as keyof typeof customerRoles][roleOnOrderCat]['DELETE'] ?? []);
            }
          }
        }
      }
    }
  }, [customerRoles]);

  return loaded ? (
    <AccordionItem {...data}>
      <AMDHLOtherPartners<DialogMarketingOrderCreate | AdditionalOrdersOrderCreate>
        name={props.name}
        disabled={props.disabled}
        roles={partnerRoles}
        allowedRolesToDelete={allowedRolesToDel}
        orderMode={orderMode}
        onOpen={handleOnOpen}
      />
    </AccordionItem>
  ) : null;
};
