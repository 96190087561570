import React, { useCallback } from 'react';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { useFormContext, useWatch } from 'react-hook-form';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import styles from 'common/components/Table/table.module.css';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { GetOrderFaultyIds } from 'order/common/context/order/OrderProvider';

interface Props {
  column: ColumnConfig;
  meta?: CellMetaData;
}

export const CustomCheckboxHeader = (props: Props): JSX.Element => {
  const { setValue, control } = useFormContext<AdditionalOrdersOrderCreate>();
  const { getOrderFaultyIds } = useOrderContext();
  const [selectedSearchedOrderIds, currentSelectedSlice, selectAll] = useWatch({
    control,
    name: ['selectedSearchedOrderIds', 'currentSelectedSlice', 'selectAll']
  });

  const handleSelectionChange = useCallback(() => {
    if (!selectAll) {
      let newSelectedSearchOrderIds = [...(selectedSearchedOrderIds || [])];
      const filteredSlice = currentSelectedSlice?.filter((c) => !selectedSearchedOrderIds?.includes(c.data?.orderNumber)) || [];
      const filteredOrderNumbers = filteredSlice?.map((f) => f.data?.orderNumber);
      newSelectedSearchOrderIds = newSelectedSearchOrderIds.concat(filteredOrderNumbers);
      (getOrderFaultyIds as GetOrderFaultyIds[])?.forEach((o) => {
        newSelectedSearchOrderIds?.forEach((no) => {
          if (o.orderId === no) {
            o.isSelected = !selectAll;
          }
        });
      });
      setValue('selectedSearchedOrderIds', newSelectedSearchOrderIds);
    } else {
      const newSelectedSearchOrderIds = [...(selectedSearchedOrderIds || [])];
      const deselectedOrderNumbers = currentSelectedSlice?.map((c) => c.data?.orderNumber);
      const filteredSliceIds = newSelectedSearchOrderIds.filter((s) => !deselectedOrderNumbers?.includes(s));
      (getOrderFaultyIds as GetOrderFaultyIds[])?.forEach((o) => {
        o.isSelected = !selectAll;
      });
      setValue('selectedSearchedOrderIds', filteredSliceIds);
    }
    setValue('selectAll', !selectAll);
  }, [selectedSearchedOrderIds, currentSelectedSlice, selectAll]);

  return (
    <DHLCheckbox
      labelClassName={styles.checkboxLabel}
      name={'selectallorders'}
      label={props.column.title || ''}
      value={selectAll}
      onChange={handleSelectionChange}
      size="slim"
    />
  );
};

export const customHeaderCheckboxRenderer = (column: ColumnConfig, meta?: CellMetaData): JSX.Element | null => {
  return <CustomCheckboxHeader meta={meta} column={column} />;
};
