import styles from '../PSLBillingTable/pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { OrderFeeRep } from 'generated';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';
type PSLCorrectionBillingTableSumRowProps = {
  orderFee?: OrderFeeRep[];
};
export const PSLCorrectionBillingTableSumRow: VFC<PSLCorrectionBillingTableSumRowProps> = (props) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const { user } = useAuthContext();
  const totalCorrectionAmountWithoutVAT = (
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_POSITION_RELATED_CHARGE_ONLY_VAT_RELEVANT')?.amount?.value ?? 0) +
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_FURTHER_CHARGE_ONLY_VAT_RELEVANT')?.amount?.value ?? 0)
  ).toLocaleString(user.language, {
    style: 'currency',
    currency: props.orderFee?.find((item) => item.type === 'OM_PORTAL_POSITION_RELATED_CHARGE_ONLY_VAT_RELEVANT')?.amount?.currency ?? 'EUR',
    maximumFractionDigits: 3
  });
  const totalCorrectionAmountWithVAT = (
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_POSITION_RELATED_CHARGE_ONLY_VAT_RELEVANT')?.amount?.value ?? 0) +
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_FURTHER_CHARGE_ONLY_VAT_RELEVANT')?.amount?.value ?? 0) +
    (props.orderFee?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.value ?? 0)
  ).toLocaleString(user.language, {
    style: 'currency',
    currency: props.orderFee?.find((item) => item.type === 'OM_PORTAL_POSITION_RELATED_CHARGE_ONLY_VAT_RELEVANT')?.amount?.currency ?? 'EUR',
    maximumFractionDigits: 3
  });
  const alreadyBilled = (
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_ALREADY_PAID_AMOUNT')?.amount?.value ?? 0) -
    (props.orderFee?.find((item) => item.type === 'OM_PORTAL_FURTHER_CHARGE_ONLY_VAT_RELEVANT')?.amount?.value ?? 0)
  ).toLocaleString(user.language, {
    style: 'currency',
    currency: props.orderFee?.find((item) => item.type === 'OM_PORTAL_ALREADY_PAID_AMOUNT')?.amount?.currency ?? 'EUR',
    maximumFractionDigits: 3
  });
  return (
    <>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={5}>
          {t('totalCorrectionAmountWithoutVAT')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>{totalCorrectionAmountWithoutVAT}</td>
      </tr>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={5}>
          {t('VAT')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>
          {(props.orderFee?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.value ?? 0).toLocaleString(
            user.language,
            {
              style: 'currency',
              currency: props.orderFee?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.currency ?? 'EUR',
              maximumFractionDigits: 3
            }
          )}
        </td>
      </tr>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={5}>
          {t('totalCorrectionAmountWithVAT')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>{totalCorrectionAmountWithVAT}</td>
      </tr>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={5}>
          {t('alreadyBilled')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>{alreadyBilled}</td>
      </tr>
      <tr className={styles.parentRow}>
        <th className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight)} colSpan={5}>
          {t('remainingClaim')}
        </th>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight)}>
          {(props.orderFee?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.value ?? 0).toLocaleString(
            user.language,
            {
              style: 'currency',
              currency: props.orderFee?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.currency ?? 'EUR',
              maximumFractionDigits: 3
            }
          )}
        </td>
      </tr>
    </>
  );
};
