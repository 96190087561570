import i18n, { useTranslation } from 'i18n';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResultMessage } from 'order/orderSearch/components/ResultMessage/ResultMessage';
import { useSearchContext } from '../../common/context/search/SearchContext';
import OrderFilter from './filter/OrderFilter';
import { TableContainer } from './table/TableContainer';
import { useTableContext } from '../../common/context/table/TableContext';
import { translationEnglish } from './Translations/OrderSearch/englishTranslation';
import { translationGerman } from './Translations/OrderSearch/germanTranslation';
import { OrderSearchAlertContainer } from './OrderSearchAlertContainer/OrderSearchAlertContainer';
import { useOrderContext } from '../../common/context/order/OrderContext';
import { useAlerts } from 'common/AlertContext';
import { AppPaths } from 'Main';
import { Button } from 'common/components/Button';
import styles from './OrderSearch.module.css';
import { OnlineHelp } from '../../common/components/OnlineHelp/OnlineHelp';
import { OnlineHelpLinks } from '../../common/dtos/OnlineHelpLink';

i18n.addResourceBundle('de', 'orderSearch', translationGerman);
i18n.addResourceBundle('en', 'orderSearch', translationEnglish);

// const validation = new ValidationRuleDataStore(new FetchAdapter(undefined), '', '');

/**
 *
 * The search form and result table display.
 *
 * @constructor
 */
export const OrderSearch = observer((): JSX.Element => {
  const { t } = useTranslation('orderSearch');
  const [loaded, setLoaded] = useState(false);
  const { loadingState, filter, setRows } = useTableContext();
  const { persistenceLoaded, response, searchType, tab, persistenceColumnLoaded } = useSearchContext();
  const { reset, resetAllocations, resetOrderReferences } = useOrderContext();
  const history = useHistory();
  const { clear } = useAlerts();

  useEffect(() => {
    if (searchType && !loaded && persistenceLoaded) {
      reset();
      resetAllocations();
      resetOrderReferences();
      setLoaded(true);
    }
    if (tab === 'history') {
      setRows([]);
    }
  }, [searchType, tab, loaded, persistenceLoaded]);

  return (
    <>
      <OrderSearchAlertContainer />
      <div className="container">
        <div className={styles.textRight}>
          <Button
            name={'neuen-auftrag'}
            label={i18n.t('viewOrderToolBar.NewOrder')}
            variant={'default'}
            disabled={false}
            dataTestId={'orderCreateButton'}
            onClick={() => {
              clear();
              history.push(AppPaths.orderCreatePath);
            }}
          />
          <OnlineHelp link={OnlineHelpLinks.ORDER_SEARCH} />
        </div>
        <OrderFilter />
      </div>
      <div className={'dhlTable-container'}>
        {tab !== 'history' && tab !== 'myOrders' && (
          <>
            <ResultMessage loadingState={loadingState} filteredCount={filter.count ? filter.count : undefined} count={response?.orders?.length} />
            <div className={styles.tableCentering}>
              <div className={styles.scrollFrame} id="scrollFrame">
                {persistenceColumnLoaded && <TableContainer />}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});
