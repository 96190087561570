import React, { useCallback, useEffect, useRef, useState } from 'react';
import i18n from 'i18n';
import { DHLButton, DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import axios from 'axios';
import { AddAlertParam, useAlerts } from 'common/AlertContext';
import { cancelOrder } from 'order/common/services/OrderService';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { useTranslation } from 'react-i18next';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { translationGerman } from 'order/orderSearch/components/Translations/OrderSearch/germanTranslation';
import { translationEnglish } from 'order/orderSearch/components/Translations/OrderSearch/englishTranslation';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';

interface CancelOrderProps {
  orderId: string;
  orderLabel: string;
  show: boolean;
  onCancel: () => void;
  orderMode?: OrderMode;
}

i18n.addResourceBundle('de', 'cancelOrder', translationGerman);
i18n.addResourceBundle('en', 'cancelOrder', translationEnglish);

export const CancelOrder = (props: CancelOrderProps): JSX.Element => {
  const { t } = useTranslation('cancelOrder');
  const [showModal, setShowModal] = useState(false);
  const scrollPos = useRef(0);
  const { setCancelOrderStatus } = useSearchContext();
  const { addAlert, clear } = useAlerts();
  const { order, setOrder } = useOrderContext();

  useEffect(() => {
    if (props.show) scrollPos.current = window.scrollY;
    setShowModal(props.show);
  }, [props.show]);

  useEffect(() => {
    window.scrollTo(0, Number(scrollPos.current));
  }, [showModal]);

  const backWrapperRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.querySelector('button')?.focus();
      }, 0);
    }
  }, []);

  return (
    <DHLModal
      name="confirm"
      title={t`modalTitle`}
      content={t`modalContent1` + (props.orderLabel ?? '') + t`modalContent2` + (props.orderId ?? '') + t`modalContent3`}
      actionbar={
        <>
          <div ref={backWrapperRef}>
            <DHLButton
              name={'cancel-button'}
              label={t(`cancel`)}
              onClick={() => {
                props.onCancel();
              }}
              type={'default'}
            />
          </div>
          <DHLButton
            name={'save-button'}
            label={t(`cancelOrder`)}
            onClick={async () => {
              props.onCancel();
              setCancelOrderStatus(undefined, '');
              clear();
              const id = props.orderId ? props.orderId : '';
              try {
                const resp = (await cancelOrder(id)).data;
                setCancelOrderStatus(resp.resultStatus, id);
                let alertAssigner: AddAlertParam;
                if (resp.amErrors) {
                  resp.amErrors.forEach((error) => {
                    if (error.errorCategory?.toString().toUpperCase() === AlertTypes.Error.toUpperCase()) {
                      alertAssigner = {
                        type: AlertTypes.Error,
                        title: t('error') + ' ' + error.code + '!',
                        description: error.errorDescription
                      };
                      addAlert(alertAssigner);
                    }
                    if (error.errorCategory?.toString().toUpperCase() === AlertTypes.Warning.toUpperCase()) {
                      alertAssigner = {
                        type: AlertTypes.Warning,
                        title: t('warning') + ' ' + error.code + '!',
                        description: error.errorDescription
                      };
                      addAlert(alertAssigner);
                    }
                  });
                }
                if (resp.resultStatus === 'OK' || resp.resultStatus === 'WARNING') {
                  if (props.orderMode === OrderMode.VIEW) {
                    if (order) {
                      const updatedOrder = { ...order, productionState: ProductionState.CANCELED };
                      setOrder(updatedOrder);
                    }
                  }
                  alertAssigner = {
                    type: AlertTypes.Success,
                    title: t(`cancelOrderTitle`),
                    description: t(`cancelOrderSuccess`)
                  };
                  addAlert(alertAssigner);
                }
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  addAlert({
                    type: AlertTypes.Error,
                    title: 'Something went wrong',
                    description: e.message
                  });
                }
              }
            }}
            type={'primary'}
          />
        </>
      }
      show={showModal}
      onClickClose={() => {
        props.onCancel();
      }}
    />
  );
};
