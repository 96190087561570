// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pendingOrder_container__Hlp8I {
  max-width: 1400px;
  width: 100%;
  padding: 0 32px;
  font-size: 14px;
  margin: 0 auto 3rem;
}

.pendingOrder_grid__GNrgj {
  display: flex;
  flex-wrap: wrap;
}

.pendingOrder_col_6__wmq7n {
  width: 50%;
  flex-grow: 1;
}

.pendingOrder_col_8__laJia {
  width: 65%;
  flex-grow: 1;
}

.pendingOrder_paddingBottom30__DBS-3 {
  padding-bottom: 30px;
}


.pendingOrder_parentbg__YHIKq {
  background-color: #f5f5f5;
  padding: 27px;
  border-radius: 4px;
}

.pendingOrder_h1__yGKyh, .pendingOrder_h2__SbJFd, .pendingOrder_h3__hEPLF, .pendingOrder_h4__k6\\+OU, .pendingOrder_h5__ZoIR3, .pendingOrder_h6__Cvd5h {
  font-weight: 600;
  line-height: 1.125;
  font-family: Delivery, Verdana, sans-serif;
  margin-bottom: 16px;
  color: #323232;
}

.pendingOrder_h4__k6\\+OU {
  font-size: 25px;
}

.pendingOrder_center__QHHs\\+ {
  text-align: center;
}

.pendingOrder_heading__9F3FR {
  padding-right: 15%;
}`, "",{"version":3,"sources":["webpack://./src/common/components/PendingOrder/pendingOrder.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;;AAGA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  max-width: 1400px;\n  width: 100%;\n  padding: 0 32px;\n  font-size: 14px;\n  margin: 0 auto 3rem;\n}\n\n.grid {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.col_6 {\n  width: 50%;\n  flex-grow: 1;\n}\n\n.col_8 {\n  width: 65%;\n  flex-grow: 1;\n}\n\n.paddingBottom30 {\n  padding-bottom: 30px;\n}\n\n\n.parentbg {\n  background-color: #f5f5f5;\n  padding: 27px;\n  border-radius: 4px;\n}\n\n.h1, .h2, .h3, .h4, .h5, .h6 {\n  font-weight: 600;\n  line-height: 1.125;\n  font-family: Delivery, Verdana, sans-serif;\n  margin-bottom: 16px;\n  color: #323232;\n}\n\n.h4 {\n  font-size: 25px;\n}\n\n.center {\n  text-align: center;\n}\n\n.heading {\n  padding-right: 15%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pendingOrder_container__Hlp8I`,
	"grid": `pendingOrder_grid__GNrgj`,
	"col_6": `pendingOrder_col_6__wmq7n`,
	"col_8": `pendingOrder_col_8__laJia`,
	"paddingBottom30": `pendingOrder_paddingBottom30__DBS-3`,
	"parentbg": `pendingOrder_parentbg__YHIKq`,
	"h1": `pendingOrder_h1__yGKyh`,
	"h2": `pendingOrder_h2__SbJFd`,
	"h3": `pendingOrder_h3__hEPLF`,
	"h4": `pendingOrder_h4__k6+OU`,
	"h5": `pendingOrder_h5__ZoIR3`,
	"h6": `pendingOrder_h6__Cvd5h`,
	"center": `pendingOrder_center__QHHs+`,
	"heading": `pendingOrder_heading__9F3FR`
};
export default ___CSS_LOADER_EXPORT___;
