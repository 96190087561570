import React, { FC } from 'react';
import { OrderContentTabFactory } from './OrderContentTabFactory';
import { OrderMode } from '../../../../common/dtos/OrderMode';
import { Order } from '../../../../common/context/order/dtos/Order';
import { ProductGroup } from '../../../../common/dtos/ProductGroup';
import { PartialServiceLetterOverview } from 'order/productGroups/additionalOrders/components/molecule/additionalOrdersOverview/additionalOrdersOverview';
import { translateGetOrderPartialServiceLetter } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';

export type OrderContentTabProps = {
  order: Order;
};
export const OrderContentTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;
  const isPartial = order.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || order.productGroup === ProductGroup.TEILLEISTUNG;
  return (
    <>
      {isPartial ? (
        <PartialServiceLetterOverview
          value={translateGetOrderPartialServiceLetter(order)}
          orderReferences={order.orderReferences ?? []}
          orderMode={OrderMode.VIEW}
        />
      ) : (
        <OrderContentTabFactory order={order} />
      )}
    </>
  );
};
