import React from 'react';

import { ColumnType } from '../dtos/ColumnType';
import { ColumnConfig } from '../dtos/ColumnConfig';
import {
  DHLTableCellCurrency,
  DHLTableCell,
  DHLTableCellCustom,
  DHLTableCellDateTime,
  DHLTableCellIconButton,
  DHLTableCellNumber
} from '@gkuis/gkp-base-widgets/dist/lib';

interface Props extends ColumnConfig {
  type: ColumnType;
  id?: number | string;
}

export const Cell = (props: Props): JSX.Element => {
  let cell = null;

  switch (props.type) {
    case ColumnType.COMMON:
      cell = (
        <DHLTableCell
          title={props.title || ''}
          propertiesName={props.property}
          key={props.name}
          sortable={props.sortable}
          customRenderer={props.customRenderer}
          contentAlignment={props.contentAlignment}
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    case ColumnType.DATE_TIME:
      cell = (
        <DHLTableCellDateTime
          dateFormat="DD.MM.YYYY"
          type="date"
          title={props.title || ''}
          propertiesName={props.property}
          key={props.name}
          sortable={props.sortable}
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    case ColumnType.CUSTOM:
      cell = (
        <DHLTableCellCustom
          title={props.title || ''}
          propertiesName={props.property}
          key={props.name}
          sortable={props.sortable}
          customRenderer={(value, row, cellId) => props.customRenderer?.(value, { ...props }, row, { cellId })}
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    case ColumnType.NUMBER:
      cell = (
        <DHLTableCellNumber
          title={props.title || ''}
          propertiesName={props.property}
          key={props.name}
          sortable={props.sortable}
          contentAlignment={props.contentAlignment}
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    case ColumnType.CURRENCY:
      cell = (
        <DHLTableCellCurrency
          title={props.title || ''}
          propertiesName={props.property}
          key={props.name}
          sortable={props.sortable}
          // currencyType="data.totalAmountCurrency"
          currencyType={props.currencyType}
          contentAlignment={props.contentAlignment}
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    case ColumnType.ICON_BUTTON:
      cell = (
        <DHLTableCellIconButton
          key="op0"
          iconName={props.icon as any}
          onClick={() => {}}
          propertiesName={props.property}
          type="iconButton"
          data-testid={`table-cell-${props.id}-${props.property}`}
        />
      );
      break;
    default:
      cell = <></>;
      break;
  }

  return <></>;
};
