import { OrderMode } from '../../../../../common/dtos/OrderMode';
import { CreatePartialOrder } from '../../../../../../generated';
import { ProductionState } from '../../../../../common/context/order/dtos/ProductionState';
import { changeCollectionOrder, changePartialOrder, createCollectionOrder, createPartialOrder } from '../../../../../common/services/OrderService';

export async function persistCollectionOrder(
  additionalOrder: CreatePartialOrder & { productionState?: ProductionState },
  orderMode: OrderMode | undefined
) {
  const { data } = orderMode === OrderMode.CHANGE ? await changeCollectionOrder(additionalOrder) : await createCollectionOrder(additionalOrder);
  return data;
}

export async function persistPartialOrder(additionalOrder: CreatePartialOrder, orderMode: OrderMode | undefined) {
  const { data } = orderMode === OrderMode.CHANGE ? await changePartialOrder(additionalOrder) : await createPartialOrder(additionalOrder);
  return data;
}
