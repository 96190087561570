import { useAuthContext } from 'common/context/auth/AuthContext';
import i18n, { useTranslation } from 'i18n';
import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { WhitelistMethod } from 'order/common/dtos/OrderMode';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useTransformOrder } from 'order/common/hooks/useOrderTransformation';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useSearchParams } from 'order/common/hooks/useSearchParams';
import { ChangeOrderSchema } from 'order/orderChange/schema/changeOrderSchema';
import React, { useEffect, useRef, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrderChangeStep1 } from '../OrderChangeStep1/OrderChangeStep1';
import { OrderResponse } from '../../../../common/context/order/dtos/GetOrder';
import { ErrorMessageContainer } from '../../../../productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';
import { PressStep2 } from 'order/productGroups/press/workFlowSteps/step2/PressStep2';
import { PressStep3 } from 'order/productGroups/press/workFlowSteps/step3/PressStep3';
import { PressStep4 } from 'order/productGroups/press/workFlowSteps/step4/PressStep4';
import { LetterStep2 } from 'order/productGroups/letter/workFlowSteps/Step2/LetterStep2';
import { LetterStep4 } from 'order/productGroups/letter/workFlowSteps/Step4/LetterStep4';
import { LetterStep3 } from 'order/productGroups/letter/workFlowSteps/Step3/LetterStep3';
import { DialogMarketingStep2 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2';
import { DialogMarketingStep3 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step3/DialogMarketingStep3';
import { DialogMarketingStep4 } from 'order/productGroups/dialogMarketing/workFlowSteps/Step4/DialogMarketingStep4';
import { AdditionalOrdersStep2 } from 'order/productGroups/additionalOrders/workFlowSteps/step2/additionalOrdersStep2';
import { AdditionalOrdersStep3 } from 'order/productGroups/additionalOrders/workFlowSteps/step3/additionalOrdersStep3';
import { AdditionalOrdersStep4 } from 'order/productGroups/additionalOrders/workFlowSteps/step4/additionalOrdersStep4';
import { UserRights } from 'common/dtos/userRights';
import { InternationalClusterStep2 } from 'order/productGroups/international/workFlowSteps/Step2/InternationalClusterStep2';
import { InternationalClusterStep3 } from 'order/productGroups/international/workFlowSteps/Step3/InternationalClusterStep3';
import { InternationalClusterStep4 } from 'order/productGroups/international/workFlowSteps/Step4/InternationalClusterStep4';
import { AdditionalLetterService, discountFn } from 'order/common/utils/transformPressBaseProducts';

const translationDE = {
  orderCanNotBeChanged: 'Dieser Auftrag kann nicht geändert werden.',
  specificOrderCanNotBeChanged: 'Aufträge vom Typ {{orderCategory}} kann nicht geändert werden.',
  notFound: 'Keine Auftragsdaten vorhanden.'
};
const translationEN = {
  orderCanNotBeChanged: 'This order can not be changed.',
  specificOrderCanNotBeChanged: 'Orders with category {{orderCategory}} cannot be edited.',
  notFound: 'No order data available.'
};

i18n.addResourceBundle('de', 'orderChange', translationDE);
i18n.addResourceBundle('en', 'orderChange', translationEN);

interface Props {
  orderData: OrderResponse | undefined;
  orderFromContext?: boolean;
}

export const OrderChangeSteps: VFC<Props> = ({ orderData, orderFromContext }) => {
  const [loaded, setLoaded] = useState(false);
  const ALS = useRef<AdditionalLetterService[]>([]);
  const [discountLoading, setDiscountLoading] = useState(true);
  const data = useRef<any>(undefined);
  const { reset } = useFormContext<ChangeOrderSchema>();
  const [orderType] = useSearchParams('orderType');
  const { t: oc } = useTranslation('orderCreate');
  const { step } = useOrderStep();
  const { checkWhitelistingKey, checkProductionStateWhitelistingChange, hasPermission } = useAuthContext();
  const { generateExistingAllocations, productGroup, resetFaultyGetOrderIds, upsertMetaData, meta } = useOrderContext();
  const { data: transformedData, isLoading } = useTransformOrder(orderData as any, {
    copy: false,
    includeDst: orderType !== 'ZA'
  });
  const { t } = useTranslation('orderChange');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(transformedData), [isLoading, meta?.ALS]);

  useEffect(() => {
    if (!loaded && !isLoading && orderData && !orderData?.pendingIds) {
      data.current = orderData;
      setLoaded(true);
    }
  }, [isLoading, orderData, loaded]);

  useEffect(() => {
    resetFaultyGetOrderIds();
    const orderProductGroup =
      orderData?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
      orderData?.productGroup === ProductGroup.TEILLEISTUNG ||
      orderData?.productGroup === ProductGroup.COLLECTION;
    const orderIds = orderData?.orderReferences?.map((ref: any) => ref.orderId || '') ?? [];
    if (orderProductGroup && !orderData?.pendingIds && !orderFromContext) {
      generateExistingAllocations(orderIds, orderData as any);
    }
  }, [orderData, orderFromContext]);

  useEffect(() => {
    if (discountLoading && orderData?.productGroup === ProductGroup.PRESS_DISTRIBUTION) {
      orderData?.internationalProducts?.forEach((product) => {
        (async () => {
          const bb = await discountFn(product.productNumber, new Date(orderData?.orderDetail?.date));
          ALS.current.push({ productNumber: product.productNumber || '', specialLetterProducts: bb });
          upsertMetaData('ALS', ALS.current);
        })();
      });
      setDiscountLoading(false);
    }
  }, [orderData?.internationalProducts]);

  if (!isLoading) {
    const isOrderEditable =
      hasPermission(UserRights.OrderEdit) &&
      orderData?.orderCategoryProductKey &&
      checkWhitelistingKey(orderData?.orderCategoryProductKey, WhitelistMethod.CHANGE) &&
      orderData?.productionState &&
      checkProductionStateWhitelistingChange(orderData.productionState);
    if (!isOrderEditable) {
      return <ErrorMessageContainer message={t('orderCanNotBeChanged')} dataTestId={'error-order-can-not-be-change'} />;
    }
  }

  // if (!isLoading && orderData?.orderCategory === OrderCategory.DIP_VARIO_TL) {
  //   return (
  //     <ErrorMessageContainer
  //       message={t('specificOrderCanNotBeChanged', { orderCategory: orderData.orderCategory })}
  //       dataTestId={'error-specific-order-can-not-be-change'}
  //     />
  //   );
  // }

  if (!isLoading && step === 0) {
    return <OrderChangeStep1 orderCategoryProductKey={data?.current?.orderCategoryProductKey} />;
  }

  const next = step - 1 < 3 ? step - 1 : 2;

  if (
    !isLoading &&
    (productGroup === ProductGroup.DIALOGPOST || productGroup === ProductGroup.DIALOGPOSTWURFSPEZIAL || productGroup === ProductGroup.POSTAKTUELL)
  ) {
    const Component = [DialogMarketingStep2, DialogMarketingStep3, DialogMarketingStep4][next];
    return <Component />;
  } else if (!isLoading && (productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || productGroup === ProductGroup.COLLECTION)) {
    const PartialComponent = [AdditionalOrdersStep2, AdditionalOrdersStep3, AdditionalOrdersStep4][next];
    return <PartialComponent />;
  } else if (!isLoading && productGroup === ProductGroup.BRIEF) {
    const Frankit = [LetterStep2, LetterStep3, LetterStep4][next];
    return <Frankit />;
  } else if (!isLoading && productGroup === ProductGroup.PRESS_DISTRIBUTION) {
    const Press = [PressStep2, PressStep3, PressStep4][next];
    return <Press />;
  } else if (
    !isLoading &&
    (productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
      productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL ||
      productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
      productGroup === ProductGroup.PRESS_INTERNATIONAL)
  ) {
    const International = [InternationalClusterStep2, InternationalClusterStep3, InternationalClusterStep4][next];
    return <International />;
  }
  return <ActivityIndicator testId={'activityIndicator'} message={oc('notifications.loadingOrder')} />;
};
