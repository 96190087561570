import { attributesEqual, OrderSearchAttribute } from './orderSearchAttribute';
import { OrderSearchType } from '../../common/context/search/dtos/OrderSearchType';
import { orderSearchTypes } from '../../common/context/search/hooks/useSearchTabs';
import { SearchState } from '../../common/context/search/dtos/SearchState';

export const getSearchTypeByIndex = (index: number): OrderSearchType => orderSearchTypes[index];

export const orderSearchStatesEqual = (a: Partial<SearchState>, b: Partial<SearchState>): boolean => {
  if (a.type !== b.type || a.attributes?.length !== b.attributes?.length) {
    return false;
  }
  return attributesInOtherArray(a.attributes || [], b.attributes || []) && attributesInOtherArray(b.attributes || [], a.attributes || []);
};

const attributesInOtherArray = (outer: OrderSearchAttribute[], inner: OrderSearchAttribute[]): boolean =>
  outer.every((a) => inner.find((b) => attributesEqual(a, b)));
