export interface ValidationResult {
  success: boolean;
  errorMessage?: string | null;
  original?: string | number;
  value?: string | number;
}

/**
 * Helper function to get rid of whitespace.
 */
// eslint-disable-next-line no-extend-native
export const stripWhiteSpace = (value: string = ''): string => value.replace(/\s+/g, '');

/**
 * Helper validation to accept numbers with 10 digits. Ignores whitespace.
 */
export const customerIdValidator = (input: string): ValidationResult => {
  const stringWithNoWhitespace = stripWhiteSpace(input);
  const transformedToNumber = Number(stringWithNoWhitespace);
  let success = true;
  let errorMessage = null;

  if (isNaN(transformedToNumber)) {
    errorMessage = 'Input is no valid number';
    success = false;
  } else if (transformedToNumber.toString().length > 10 || transformedToNumber.toString().length < 10) {
    errorMessage = `Number doesn't have 10 digits`;
    success = false;
  }

  return {
    value: transformedToNumber,
    errorMessage,
    success
  };
};
