import React, { useEffect, useState, VFC } from 'react';

import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { usePaymentBankInfo } from 'order/productGroups/common/services/usePaymentBankInfo';
import { CustomerInfoWithAddressTO } from 'generated';
import PaymentAddress from '../PaymentAddress/PaymentAddress';
import { useOrderContext } from 'order/common/context/order/OrderContext';

export type PaymentBankInfoProps = {
  payer?: string;
  withLabel?: boolean;
  withCustomerInfo?: boolean;
};

export const PaymentBankInfo: VFC<PaymentBankInfoProps> = ({ payer, withLabel, withCustomerInfo }) => {
  const { order, orderMode } = useOrderContext();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const { paymentBankInfo } = usePaymentBankInfo(payer, new Date(order?.orderDetail?.date || ''));
  const customerId = payer?.substring(0, 10);
  const filteredParty = order?.parties?.filter((p) => p.customerId === payer);
  const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
    customerId: payer,
    address: filteredParty?.[0]?.orderAddress
  };

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      } else if (transformedCustomerData && withCustomerInfo) {
        setCustomerInfo(transformedCustomerData);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  if (!paymentBankInfo) {
    return <div />;
  }

  return (
    <PaymentAddress>
      <p>{paymentBankInfo.companyName}</p>
      {withCustomerInfo && (
        <>
          <p>
            {customerInfo?.address?.street} {customerInfo?.address?.streetNumber}
          </p>
          <p>
            {customerInfo?.address?.postCode} {customerInfo?.address?.city}
          </p>
          {/* <p className={style.spacing}></p> */}
        </>
      )}
      <p>{withLabel ? `IBAN: ${paymentBankInfo.iban}` : paymentBankInfo.iban}</p>
      <p>{withLabel ? `Bank: ${paymentBankInfo.bank}` : paymentBankInfo.bank}</p>
    </PaymentAddress>
  );
};
