import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import dropdownStyles from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals.module.css';
import { Menu } from 'common/components/ContextMenu';

import contextClasses from 'common/components/ContextMenu/context-menu.module.css';
import { FormRow } from 'common/components/FormRow';
import { Input } from 'common/components/Input';
import { useBoolean } from 'common/hooks/useBoolean';
import { GenericRecommendation, useGenericRecommendations } from 'common/hooks/useGenericRecommendations';
import { validateSettlementNumber, validateTrimSettlementNumber } from 'common/utils/helpers';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import classes from 'order/productGroups/common/components/atom/CustomerInput/customer-input.module.css';
import { PaymentBankInfo } from 'order/productGroups/common/components/atom/PaymentBankInfo/PaymentBankInfo';
import { usePaymentBankInfo } from 'order/productGroups/common/services/usePaymentBankInfo';
import React, { MouseEventHandler, ReactElement, useEffect, useRef } from 'react';
import { Controller, FieldPath, FieldValues, PathValue, UnpackNestedValue, UseControllerProps, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductGroup } from '../../../../common/dtos/ProductGroup';

const inputDisabled = false;
const BILLING_POSTCARD_NUMBER = 'BILLING_POSTCARD_NUMBER';

export type BillingNumberOrPostcardNumberProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  onDelete?: MouseEventHandler;
  disabled?: boolean;
  readOnly?: boolean;
  fallBackValue?: string;
  initValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;
  onSelect?: (c: string) => void;
  required?: boolean;
  productGroup?: ProductGroup;
};

export const BillingOrPostcardNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: BillingNumberOrPostcardNumberProps<TFieldValues, TName>
): ReactElement => {
  const { control, setValue, trigger, formState, clearErrors } = useFormContext();
  const [isShowList, setIsShowList] = useBoolean(false);
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { order } = useOrderContext();
  const { t } = useTranslation('orderCreate');
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const payer = useWatch({
    control,
    name: props.name as any
  });
  const abrechnungsError = !!(formState.errors?.payment as any)?.payer;
  const { recommendations, load, add, delete: remove } = useGenericRecommendations('BILLING_POSTCARD_NUMBER');
  const isDVBrief = order?.orderCategory === OrderCategory.DV_BRIEF;
  const isPostaktuell = order?.orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL;
  useClickOutside([inputRef, wrapperRef], () => setIsShowList.off());
  const { paymentBankInfo } = usePaymentBankInfo(payer, new Date(order?.orderDetail?.date || ''));
  const isDialogPost = props.productGroup && props.productGroup === ProductGroup.DIALOGPOST;

  useEffect(() => {
    isShowList && load();
  }, [payer, isShowList]);

  const setPayerValue = (value: string) => setValue(props.name as any, value);

  const handleBlur = (value: string) => {
    const valid = validateSettlementNumber(value, !isDialogPost);
    if (valid && paymentBankInfo?.companyName) {
      add({
        value,
        key: BILLING_POSTCARD_NUMBER,
        meta: paymentBankInfo?.companyName
      });
    }
  };

  const icon = !props.disabled && (
    <DHLIcon name={props.name + '-function'} icon="close-thin" onClick={() => setPayerValue('')} className={classes.icon} />
  );

  return (
    <FormRow mode="two-col">
      <div data-testid={props.name + '-container'}>
        <Controller
          name={props.name ?? 'payer'}
          render={({ field: { ref, ...field }, fieldState }) => (
            <>
              <Input
                {...field}
                onChange={(e) => {
                  const { on, off } = setIsShowList;
                  const value = validateTrimSettlementNumber(e.currentTarget.value);
                  value.length ? off() : on();
                  field.onChange(value);
                  clearErrors(['payer']);
                }}
                data-testid={field.name}
                placeholder={
                  isDVBrief
                    ? oc('billingNumber')
                    : isPostaktuell
                    ? oc('billingNumberOrPostcardNumberOrCustomerNumber')
                    : oc('billingNumberOrPostcardnumber')
                }
                type="text"
                icon={icon}
                onClick={() => setIsShowList.on()}
                disabled={inputDisabled || isDVBrief}
                autoComplete="off"
                error={!!fieldState.error}
                onBlur={(e) => handleBlur(e.target.value)}
              />
              {!!recommendations.length && (
                <div className={contextClasses.anchor} ref={wrapperRef} data-testid={props.name + '-menuContainer'}>
                  <Menu isOpen={isShowList} data-testid={`${props.name}-menu`} className={classes.menu}>
                    <p className={classNames(contextClasses.item, contextClasses.noHover)}>Last Entry</p>
                    {recommendations.map((recommendation: GenericRecommendation, i: number) => (
                      <div className={classNames(contextClasses.item, contextClasses.noHover)} key={i}>
                        <button
                          onClick={() => {
                            setPayerValue(recommendation.value);
                            trigger(props.name ?? 'payer');
                            setIsShowList.off();
                          }}
                          className={dropdownStyles.dropDownValue}
                        >
                          {recommendation.meta ? `${recommendation.value} ${recommendation.meta}` : recommendation.value}
                        </button>
                        <button className={dropdownStyles.delete}>
                          <DHLIcon
                            name={'amSearchDropdown-remove-' + i}
                            icon={'close-thin'}
                            onClick={async (e) => {
                              e.stopPropagation();
                              await remove(recommendation);
                            }}
                          />
                        </button>
                      </div>
                    ))}
                  </Menu>
                </div>
              )}
              {!!fieldState.error && (
                <div className="fieldNote error" data-testid={field.name + '-error'}>
                  {t(fieldState.error.message as string)}
                </div>
              )}
            </>
          )}
        />
      </div>
      {payer && !abrechnungsError && <PaymentBankInfo payer={payer} />}
    </FormRow>
  );
};
