import { DHLCheckbox, DHLFormGroup, DHLTooltip, DHLTooltipIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { SFCProps } from 'common/utils/formHelpers';
import i18n, { useTranslation } from 'i18n';
import { AMDHLDestinationInput, DestinationSchema } from 'order/productGroups/common/components/atom/AMDHLDestinationInput';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import classes from './destination-section.module.css';
import { DestinationSectionSchema } from './destinationSectionSchema';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { PalletsOverviewAccordian } from '../../../../../orderView/components/OrderView/PalletsOverview/PalletsOverviewAccordian/PalletsOverviewAccordian';
import { OrderDestination } from '../../../../../../generated';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';

export type DestinationSectionProps<T extends FieldValues> = SFCProps<T, DestinationSectionSchema | undefined> & {
  disabled?: boolean;
  showOnlyRequired?: boolean;
  showSLN?: boolean;
  productGroup?: ProductGroup;
  showOnlyDEZone?: boolean;
  totalNetWeight: number;
};

const translationsDE = {
  title: 'Paletten je Leitzone oder Leitregion',
  tooltip: 'Bitte wählen Sie eine der Leitzonen aus, um die Palettenanzahl je Leitregion und -zone anzugeben.',
  netWeightTooltip: 'Wenn kein Gesamtgewicht netto eingegeben wird,  erfolgt automatisch die Berechnung basierend auf der Sendungsmenge.',
  chooseZones: 'Leitzonen auswählen',
  totalWeightLabel: 'Gesamtgewicht brutto in kg',
  netWeightLabel: 'Gesamtgewicht netto in kg',
  stackableLable: 'Stapelfähig',
  error: {
    content: {
      destinations: {
        totalWeight: {
          max: 'Bitte prüfen Sie das Gesamtgewicht und die Anzahl der Gebinde. Das Einzelgewicht pro Palette darf nicht größer als 700 kg sein.'
        }
      }
    },
    destinations: {
      totalWeight: {
        max: 'Bitte prüfen Sie das Gesamtgewicht und die Anzahl der Gebinde. Das Einzelgewicht pro Palette darf nicht größer als 700 kg sein.'
      }
    }
  }
};

const translationsEN = {
  title: 'Pallets per Routing Zone or Routing Region',
  tooltip: 'Please select one of the routing zones in order to enter the amount of pallets for each routing zone and region',
  netWeightTooltip: 'If no total net weight is entered, the calculation is made automatically based on the shipment quantity.',
  chooseZones: 'Select Routing Zones',
  totalWeightLabel: 'Total Gross Weight in kg',
  netWeightLabel: 'Total net weight in kg',
  stackableLable: 'Stackable',
  error: {
    content: {
      destinations: {
        totalWeight: {
          max: 'Please check the total weight and number of containers. The individual weight per pallet must not be greater than 700 kg.'
        }
      }
    },
    destinations: {
      totalWeight: {
        max: 'Please check the total weight and number of containers. The individual weight per pallet must not be greater than 700 kg.'
      }
    }
  }
};

i18n.addResourceBundle('de', 'destinationSection', translationsDE);
i18n.addResourceBundle('en', 'destinationSection', translationsEN);

export const DestinationSection = <T extends FieldValues>(props: DestinationSectionProps<T>): ReactElement => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation('destinationSection');
  const { trigger } = useOrderTransferForm();
  const { upsertMetaData, meta } = useOrderContext();

  const { control, setValue } = useFormContext();

  const [destinationsMatrix] = useWatch({
    control,
    name: [`${props.name}.matrix`]
  });
  const destinationMatrixQuantity =
    (destinationsMatrix as DestinationSchema[])?.length &&
    (destinationsMatrix as DestinationSchema[])?.reduce(
      (accumulator: number, currentValue: DestinationSchema) => accumulator + currentValue.quantity,
      0
    );

  useEffect(() => {
    const netWeight = props.totalNetWeight && destinationMatrixQuantity ? props.totalNetWeight / destinationMatrixQuantity : 0;
    if (meta?.netWeightChanged) {
      setValue(`${props.name}.netWeight`, parseFloat(props.totalNetWeight.toFixed(3)) as any);
      setPalletOverViewNetWeight(parseFloat(netWeight.toFixed(3)));
    }
  }, [destinationMatrixQuantity, props.totalNetWeight]);

  const setPalletOverViewNetWeight = (netWeight: number) => {
    const netWeightFiltered =
      (destinationsMatrix as Array<DestinationSchema>)?.map((val) => {
        return {
          ...val,
          packaging: val?.packaging?.length
            ? val.packaging.map((val1) => {
                return { ...val1, totalNetWeightValue: netWeight };
              })
            : [{ totalNetWeightValue: netWeight }]
        };
      }) || [];
    setValue(`${props.name}.matrix`, [...netWeightFiltered] as any);
  };

  return (
    <OrderStepSection headline={t`title`}>
      <DHLFormGroup>
        {t`chooseZones`}
        <span style={{ display: 'inline-block', marginLeft: '4px' }}>
          <DHLTooltipIcon tooltip="Tooltip" placement="right" name="destinationsTooltipIcon" render />
          <DHLTooltip
            testid="destinationTooltip"
            target="destinationsTooltipIcon"
            tooltipOpen={isTooltipOpen}
            toggle={() => setTooltipOpen((o) => !o)}
            placement="right"
          >
            {t`tooltip`}
          </DHLTooltip>
        </span>
      </DHLFormGroup>
      <DHLFormGroup>
        <AMDHLDestinationInput<DestinationSectionSchema>
          name={`${props.name}.matrix`}
          rules={{
            deps: ['packaging.quantity']
          }}
          disabled={props.disabled}
          showSLN={props.showSLN}
          productGroup={props.productGroup}
          showOnlyDEZone={props.showOnlyDEZone}
        />
      </DHLFormGroup>
      {!props.showOnlyRequired && (
        <>
          <FormRow mode="two-col" className={classes.centerRow}>
            <TextInput
              name={`${props.name}.netWeight`}
              label={t('netWeightLabel')}
              type="number"
              disabled={props.disabled}
              error={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return errorMessage && t(`error.${field.name}.${errorMessage}`);
              }}
              onChange={(e) => {
                upsertMetaData('netWeightChanged', true);
                trigger(`${props.name}.netWeight` as any);
                const netWeight = e.target.value && destinationMatrixQuantity ? e.target.value / destinationMatrixQuantity : 0;
                setPalletOverViewNetWeight(netWeight);
              }}
            />
            <div>
              <Tooltip content={t('netWeightTooltip')}>{<i className={`${classes.tooltip} icon-alert-info`} />}</Tooltip>
            </div>
          </FormRow>
          <FormRow mode="two-col" className={classes.centerRow}>
            <TextInput
              name={`${props.name}.totalWeight`}
              label={t('totalWeightLabel')}
              type="number"
              disabled={props.disabled}
              error={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return errorMessage && t(`error.${errorMessage}`);
              }}
              onChange={(e) => {
                trigger(`${props.name}.totalWeight` as any);
              }}
            />
            <Controller
              name={`${props.name}.stackable`}
              render={({ field: { ref, ...field } }) => (
                <DHLCheckbox label={t`stackableLable`} {...field} onChange={() => field.onChange(!field.value)} disabled={props.disabled} />
              )}
            />
          </FormRow>
          <div>
            <PalletsOverviewAccordian destinations={(destinationsMatrix as unknown as OrderDestination[]) || []} />
          </div>
        </>
      )}
    </OrderStepSection>
  );
};
