import React, { useEffect, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './result-message.module.css';
import { TableLoadingState } from '../../../../common/components/Table/dtos/LoadingState';
import { useTableContext } from 'order/common/context/table/TableContext';

export type ResultMessageProps = {
  loadingState?: TableLoadingState | boolean;
  filteredCount?: number;
  count?: number;
  allocated?: boolean;
};
let prevLoadingState = false;
export const ResultMessage: VFC<ResultMessageProps> = ({ loadingState, filteredCount: filtered, count, allocated }) => {
  const { filter } = useTableContext();
  const { t } = useTranslation('translation', { keyPrefix: 'gkp.msg.dataStatusIndicator' });
  useEffect(() => {
    return () => {
      prevLoadingState = false;
    };
  }, []);
  if (typeof loadingState !== 'boolean') {
    if (loadingState === TableLoadingState.LOADING) {
      return <div className={classes.message}>{t('loading')}</div>;
    }

    if (loadingState === TableLoadingState.NOT_LOADED) {
      return <div className={classes.message}>{t('notLoaded')}</div>;
    }
  } else {
    if (loadingState) {
      prevLoadingState = true;
      return <div className={classes.message}>{t('loading')}</div>;
    }

    if (!loadingState && !prevLoadingState) {
      return <div className={classes.message}>{t('notLoaded')}</div>;
    }
  }

  if (typeof filtered !== 'undefined') {
    return (
      <div className={classes.message}>
        {t(allocated ? 'allocFiltered' : 'filtered', {
          filtered,
          count
        })}
      </div>
    );
  }
  if (typeof count === 'undefined') {
    return <div className={classes.message}>{t('notLoaded')}</div>;
  }

  if (filter.count === 0) {
    return <div className={classes.message}>{t('noFilterResult')}</div>;
  }

  return <div className={classes.message}>{t(allocated ? 'allocCount' : 'rowCount', { count })}</div>;
};
