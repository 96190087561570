// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LetterStep2_actionBar__Mlha7 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.LetterStep2_actionBar__Mlha7 > div {
  flex: 1 1;
}

.LetterStep2_addDeleteAssignmentButton__7jzuE {
  font-size: 14px;
  background: transparent;
  background-color: transparent !important;
  border: none;
}

.LetterStep2_addDeleteAssignmentButton__7jzuE img {
  width: 18px;
  height: 18px;
}

.LetterStep2_errorMessage__JTGG6 {
  color: #d40511;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/letter/workFlowSteps/Step2/LetterStep2.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".actionBar {\n  display: flex;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.actionBar > div {\n  flex: 1;\n}\n\n.addDeleteAssignmentButton {\n  font-size: 14px;\n  background: transparent;\n  background-color: transparent !important;\n  border: none;\n}\n\n.addDeleteAssignmentButton img {\n  width: 18px;\n  height: 18px;\n}\n\n.errorMessage {\n  color: #d40511;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBar": `LetterStep2_actionBar__Mlha7`,
	"addDeleteAssignmentButton": `LetterStep2_addDeleteAssignmentButton__7jzuE`,
	"errorMessage": `LetterStep2_errorMessage__JTGG6`
};
export default ___CSS_LOADER_EXPORT___;
