import { OrderCustomerRoleEnum } from '../../../../../../generated';

export const PARTNER_ROLES: OrderCustomerRoleEnum[] = [
  OrderCustomerRoleEnum.Agency,
  OrderCustomerRoleEnum.Beneficiary,
  OrderCustomerRoleEnum.Producer,
  OrderCustomerRoleEnum.Consolidator,
  OrderCustomerRoleEnum.DiscountRecipient,
  OrderCustomerRoleEnum.InvoiceRecipient,
  OrderCustomerRoleEnum.Contractor,
  OrderCustomerRoleEnum.ContractorPremium,
  OrderCustomerRoleEnum.DpOptimiser
];

export const roleToString = (role: OrderCustomerRoleEnum): string => {
  const names = [
    'Agentur',
    'Berechtigter',
    'Hersteller',
    'Konsolidierer',
    'Rabattempfänger',
    'Rechnungsempfänger',
    'Vertragspartner',
    'Vertragspartner für Premiumadress',
    'DV-Dienstleister'
  ];

  return names[PARTNER_ROLES.indexOf(role)] || '';
};
