/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { DHLIcon, DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import { IconType } from '@gkuis/gkp-base-widgets/dist/lib/components/atoms/DHLIcon/DHLIcon';
import classNames from 'classnames';
import IconSearchButton from 'common/components/IconSearchButton';
import { useClickOutside, useKeyPress, usePrevious } from 'common/utils/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AMDHLInputWithProposals.module.css';

export interface AMDHLInputWithProposalsProps<T> {
  name: string;
  value: T;
  required?: boolean;
  onChange: (value: T) => void;
  readOnly?: boolean;
  functionIcon?: IconType;
  error?: string | string[];
  placeholder?: string;
  valueToString: (value: T) => string;
  stringToValue: (value: string) => T | undefined;
  options: T[];
  onDelete?: (value: T) => void;
  inputClassName?: string;
  labelClassName?: string;
  inputType?: 'number' | 'textarea' | 'text' | 'password' | 'email' | undefined;
  setParentData?: React.Dispatch<React.SetStateAction<boolean>>;
  nonNumeric?: boolean;
  setParentInput?: React.Dispatch<React.SetStateAction<T>>;
}

export function AMDHLInputWithProposals<T>({
  name,
  value,
  required,
  onChange,
  readOnly,
  functionIcon = 'plus',
  error,
  placeholder,
  stringToValue,
  valueToString,
  options,
  onDelete,
  inputClassName,
  labelClassName,
  inputType,
  setParentData,
  setParentInput,
  nonNumeric
}: AMDHLInputWithProposalsProps<T>): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'orderSearch.proposal' });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState<string>(valueToString(value));
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value !== previousValue) {
      setInputValue(valueToString(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, previousValue]);

  useEffect(() => {
    setParentData?.(showMenu);
  }, [showMenu]);
  useEffect(() => {
    const parsed = stringToValue(inputValue);
    if (parsed) {
      setParentInput?.(parsed);
    }
  }, [inputValue]);

  const onSubmit = () => {
    const parsed = stringToValue(inputValue);
    if (parsed) {
      onChange(parsed);
      setInputValue('');
    }
  };

  useClickOutside([inputRef, wrapperRef], () => {
    setShowMenu(false);
  });

  useKeyPress([inputRef, wrapperRef], ['Enter', 'Escape', 'Tab'], (e) => {
    if (e.key === 'Enter') {
      onSubmit();
      e.preventDefault();
    } else {
      setShowMenu(false);
    }
  });

  return (
    <>
      <div className={styles.inputWrapper}>
        <DHLTextInput
          data-testid={name + '-input'}
          innerRef={inputRef}
          name={name}
          maxLength={100}
          type={inputType || 'text'}
          className={classNames('full-width', { 'has-function has-primary': functionIcon }, inputClassName)}
          labelClassName={labelClassName}
          readOnly={readOnly}
          disabled={readOnly}
          onChange={(e) => {
            const numeric = /^[0-9]+$/;
            if (!nonNumeric && !numeric.test(e.target.value)) {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setInputValue(value || '');
            } else {
              setInputValue(inputRef.current?.value || '');
            }
            setShowMenu(!inputRef.current?.value);
          }}
          value={inputValue}
          label={placeholder}
          required={required}
          error={error}
          autoCompleteOff
          onFocus={() => setShowMenu(!inputValue.length)}
        />
        <div className={classNames(styles.dropdown)} ref={wrapperRef}>
          {showMenu && (
            <div className="dhlContextmenu dhlContextmenu-visible" data-testid={name + '-menu'}>
              <span className={styles.dropDownLabel}>{t('title')}</span>
              {options.map((option, i) => (
                <div className={styles.dropDownLabel} key={i}>
                  <button
                    className={styles.dhlDropdownButton}
                    onClick={() => {
                      onChange?.(option);
                      setShowMenu(false);
                    }}
                  >
                    {valueToString(option)}
                  </button>
                  {onDelete && (
                    <button className={styles.delete}>
                      <DHLIcon
                        name={'amSearchDropdown-remove-' + i}
                        icon={'close-thin'}
                        onClick={(e) => {
                          onDelete?.(option);
                          e.stopPropagation();
                        }}
                      />
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {functionIcon && <IconSearchButton name="orderSearchFilterAttrSelectableAdd" onClick={onSubmit} icon={functionIcon} />}
    </>
  );
}
