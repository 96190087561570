import React from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';

import { useOrderContext } from '../../../context/order/OrderContext';

interface Props {
  column: ColumnConfig;
}

export const CustomExpandAllHeaderCell: React.VFC<Props> = (props): JSX.Element => {
  const { displayAllAllocations, setDisplayAllAllocations } = useOrderContext();

  return (
    <DHLButton
      icon={displayAllAllocations ? 'arrow-up' : 'arrow-down'}
      onClick={() => setDisplayAllAllocations(!displayAllAllocations)}
      iconPosition="icon"
      type="primary"
    />
  );
};

export const customExpandAllHeaderRenderer = (column: ColumnConfig): JSX.Element | null => {
  return <CustomExpandAllHeaderCell column={column} />;
};
