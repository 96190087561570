import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { styles } from './EinlieferungslisteStyles';
import moment, { now } from 'moment/moment';
import { PostLogo } from '../pdf/PostLogo';
import { getOrderIdBarcodeImageUrl } from './EinlieferungslisteBarcodes';
import { getDeliveryPoint } from '../utils/productionFacilityUtils';
import { useAuthContext } from 'common/context/auth/AuthContext';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { sortLetterTypeList } from '../../../common/context/order/dtos/Order';
import { toFormattedDate, toFormattedInt } from 'common/formatting';
import { generateAndDownloadPdf } from '../utils/pdfUtils';
import { GetLetterOrderRep, GetOrderRep, OrderCustomerRoleEnum, ProductionFacilityTO } from 'generated';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
 
Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadEinlieferungslistePdfAfmBrief = async (
  order: GetLetterOrderRep,
  validProductionFacilities: ProductionFacilityTO[],
  version: string | undefined,
  finished: () => void
): Promise<void> => {
  const listName = 'Einlieferungsliste_Briefsendungen_Frankiermaschinen';

  try {
    const doc = <EinlieferungslisteAfmBrief order={order} validProductionFacilities={validProductionFacilities} version={version} />;
    await generateAndDownloadPdf(doc, order as GetOrderRep, listName);
  } catch (e) {
    console.error(`${now()} - error printing ${listName}: ${e}`);
  } finally {
    finished();
  }
};

interface EinlieferungslisteTLProps {
  order: GetLetterOrderRep;
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
}

export const EinlieferungslisteAfmBrief = ({ order, validProductionFacilities, version }: EinlieferungslisteTLProps): JSX.Element => {
  const originator = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Originator);
  const submitter = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Submitter);
  const payer = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Payer);
  const deliveryPoint = getDeliveryPoint(order?.orderDetail?.productionPlantId, validProductionFacilities);
  const lineBreak = '\n';
  const language = useAuthContext().user.language;

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={{ minHeight: '6mm' }} />
          <View style={styles.lineContainerStreched}>
            <Text style={styles.pageHeading}>Einlieferungsliste{lineBreak}Briefsendungen Frankiermaschinen</Text>
            <Text style={{ alignSelf: 'flex-end', textAlign: 'right' }}>{`Auftragsnummer: ${order.orderId || '--'}`}</Text>
          </View>
          <View style={styles.lineContainerStreched}>
            <View style={styles.linesContainer}>
              <View style={{ minHeight: '3mm' }} />
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Auftragsbezeichnung:</Text>
                <Text style={{ width: '100%' }}>{customWordBreakForPDF(order.orderLabel, 35) || '--'}</Text>
              </View>
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Maschinenkennung:</Text>
                <Text style={{ width: '100%' }}>{order.postage?.machineCode || '--'}</Text>
              </View>
            </View>
            {(() => {
              const barcodeUrl = getOrderIdBarcodeImageUrl(order.orderId || '');
              return barcodeUrl ? <Image style={{ minWidth: '40mm', height: '10mm' }} src={barcodeUrl} /> : <></>;
            })()}
          </View>
          {/* Kundendaten */}
          <View style={{ minHeight: '6mm' }} />
          <View style={styles.lineContainer}>
            <Text style={styles.textHeader}>Kundendaten</Text>
          </View>
          <View style={styles.groupContainer}>
            <View style={styles.lineContainer}>
              <View style={[{ width: '100%' }, styles.linesContainer]}>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Absender:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${originator?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{originator?.name}</Text>
                </View>
                <View style={{ minHeight: '3mm' }} />
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Einlieferer:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${submitter?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{submitter?.name}</Text>
                </View>
                <View style={{ minHeight: '3mm' }} />
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }}>Zahlungspflichtiger:</Text>
                  <Text style={{ width: '100%' }}>{`Kundennummer: ${payer?.customerId}`}</Text>
                </View>
                <View style={styles.lineContainer}>
                  <Text style={{ minWidth: '60mm' }} />
                  <Text style={{ width: '100%' }}>{payer?.name}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Einlieferungsinformationen */}
          <View style={{ minHeight: '6mm' }} />
          <View style={styles.lineContainer}>
            <Text style={styles.textHeader}> {order?.orderDetail?.pickup === true ? 'Abholinformationen' : 'Einlieferungsinformationen'}</Text>
          </View>
          <View style={styles.groupContainer}>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{order?.orderDetail?.pickup === true ? 'Abholdatum:' : 'Einlieferungsdatum:'} </Text>
              <Text style={{ width: '100%' }}>{toFormattedDate(order.orderDetail?.date)}</Text>
            </View>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>Einlieferungsstelle:</Text>
              <Text style={{ width: '100%' }}>{`${deliveryPoint?.address?.postCode || ''} ${deliveryPoint?.comment || ''}`}</Text>
            </View>
          </View>
          {/* Produktdaten */}
          <View style={{ minHeight: '6mm' }} />
          <View style={styles.lineContainer}>
            <Text style={styles.textHeader}>Produktdaten</Text>
          </View>
          <View style={styles.groupContainer}>
            <View style={styles.gridContainer}>
              <View style={styles.gridHeaderContainer}>
                <Text style={styles.gridHeaderItem}>Produkt</Text>
                <Text style={styles.gridHeaderItemRight}>Sendungsmenge</Text>
              </View>
              {(() => {
                // calculate quantities by baseproduct incl. sum
                interface ProductQuantity {
                  productName: string;
                  qty: number;
                }
                const sumName = 'Summe';
                const qtys = order.letterBaseProducts?.reduce<Map<string, ProductQuantity>>((result, current) => {
                  if (current && current.baseProduct) {
                    const productName = current.baseProduct.description || '';
                    const qty = current.baseProduct.quantity || 0;
                    const productQty = result?.get(productName) || ({ productName, qty: 0 } as ProductQuantity);
                    productQty.qty += qty;
                    result.set(productName, productQty);
                    const sumQty = result?.get(sumName) || ({ productName: sumName, qty: 0 } as ProductQuantity);
                    sumQty.qty += qty;
                    result.set(sumName, sumQty);
                  }
                  return result;
                }, new Map<string, ProductQuantity>());

                // show all lines containing at least one qty sorted
                if (qtys) {
                  const qtysToShow = Array.from(qtys.values()).filter((qty) => qty.qty > 0);
                  const qtysToShowSorted = qtysToShow?.sort((a, b) => {
                    const aSortVal = sortLetterTypeList.indexOf(a.productName?.toLowerCase() || '');
                    const bSortVal = sortLetterTypeList.indexOf(b.productName?.toLowerCase() || '');
                    return (aSortVal !== -1 ? aSortVal : 999) - (bSortVal !== -1 ? bSortVal : 999);
                  });
                  return qtysToShowSorted.map((qty, idx) => {
                    return (
                      <View style={[styles.gridRowContainer, qty?.productName === sumName ? { marginTop: '10px' } : {}]} key={`productdata_${idx}`}>
                        <Text style={styles.gridRowItem}>{qty?.productName}</Text>
                        <Text style={[styles.gridRowItemRight, qty?.productName === sumName ? { fontWeight: 'bold' } : {}]}>
                          {toFormattedInt(qty.qty)}
                        </Text>
                      </View>
                    );
                  });
                }
                return <></>;
              })()}
            </View>
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.portal ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};
