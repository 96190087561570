export const extractObjectData = (data: any, propertyName: string): any => {
  let value = data;
  if (propertyName != '') {
    propertyName.split('.').forEach((levelName) => {
      let _a;
      value = (_a = value === null || value === void 0 ? void 0 : value[levelName]) !== null && _a !== void 0 ? _a : null;
    });
  }
  return value;
};
