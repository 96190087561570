// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisplayQuantityWeightSection_DynamicRow__NJHM7 {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

.DisplayQuantityWeightSection_DynamicRow__NJHM7 > * {
  flex-grow: 1;
  flex-basis: 0;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/DisplayQuantityWeightSection/DisplayQuantityWeightSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".DynamicRow {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 20px;\n}\n\n.DynamicRow > * {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DynamicRow": `DisplayQuantityWeightSection_DynamicRow__NJHM7`
};
export default ___CSS_LOADER_EXPORT___;
