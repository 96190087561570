import React, { FC, useCallback } from 'react';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import { Order } from 'order/common/context/order/dtos/Order';
import { CollectionTab } from './tabs/CollectionTab';
import { PressDistributionTab } from './tabs/PressDistributionTab';
import { DialogPostTab } from './tabs/DialogPostTab';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { ErrorMessageContainer } from 'order/productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';
import { OrderContentTabProps } from './OrderContentTab';
import { LetterTab } from './tabs/LetterTab';
import { InternationalTab } from './tabs/InternationalTab';

export const OrderContentTabFactory: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;

  const renderTab = useCallback((order: Order) => {
    switch (order.orderCategoryProductKey) {
      case OrderCategoryProductKey.DV_BRIEF:
      case OrderCategoryProductKey.AFM_BRIEF:
        return <LetterTab order={order} />;
      case OrderCategoryProductKey.PRESSDISTRIBUTION:
        return <PressDistributionTab order={order} />;
      case OrderCategoryProductKey.ABHOLAUFTRAG:
        return <CollectionTab order={order} />;
      case OrderCategoryProductKey.DiP_EINZEL__DIALOGPOST:
      case OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL:
      case OrderCategoryProductKey.DiP_VARIO:
      case OrderCategoryProductKey.DiP_SAMMEL:
      case OrderCategoryProductKey.DHL_INFOPOST:
      case OrderCategoryProductKey.POSTAKTUELL:
        return <DialogPostTab order={order} />;
      case OrderCategoryProductKey.DIALOGPOST_INTERNATIONAL:
      case OrderCategoryProductKey.PRESS_INTERNATIONAL:
      case OrderCategoryProductKey.BRIEF_INTERNATIONAL:
      case OrderCategoryProductKey.BRIEF50_INTERNATIONAL:
        return <InternationalTab order={order} />;
      default:
        return (
          <ErrorMessageContainer
            message={`Produkt ${order.orderCategoryProductKey} kann nicht angezeigt werden.`}
            dataTestId={'error-invalid-cat-product-key'}
          />
        );
    }
  }, []);

  return (
    <DialogPostContainer>
      <DHLFormGroup>{renderTab(order)}</DHLFormGroup>
    </DialogPostContainer>
  );
};
