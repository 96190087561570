import { useState } from 'react';

import { useBoolean } from 'common/hooks/useBoolean';

export type UseRecommendationsParams<T> = {
  key: string;
  get: () => Promise<T[]> | T[];
  set: (values: T[]) => Promise<void> | void;
  compare?: (a: T, b: T) => boolean;
};

export type UseRecommendationsReturn<T> = {
  load: () => Promise<T[]>;
  add: (t: T) => Promise<boolean>;
  delete: (t: T) => Promise<boolean>;
  recommendations: T[];
};

export const useRecommendations = <T>(params: UseRecommendationsParams<T>): UseRecommendationsReturn<T> => {
  const { compare = () => false } = params;
  const [recommendations, setRecommendations] = useState<T[]>([]);
  const [isLoaded, setLoaded] = useBoolean(false);

  const load = async () => {
    setLoaded.on();
    const r = await Promise.resolve(params.get()).catch(() => [] as T[]);
    setRecommendations(r);
    return r;
  };

  const getListWithoutItem = async (newItem: T): Promise<T[]> => {
    const unfilteredList = isLoaded ? recommendations : await load();
    return unfilteredList.filter((oldItem) => !compare(oldItem, newItem));
  };

  const sendList = async (filteredList: T[]) => {
    const isAdded = await Promise.resolve(params.set(filteredList))
      .then(() => true)
      .catch(() => false);
    if (isAdded) {
      setRecommendations(filteredList);
    }
    return isAdded;
  };

  const add = async (newItem: T) => {
    const filteredList = await getListWithoutItem(newItem);
    filteredList.splice(7, 1);
    filteredList.unshift(newItem);
    return await sendList(filteredList);
  };

  const _delete = async (newItem: T) => {
    const filteredList = await getListWithoutItem(newItem);
    return await sendList(filteredList);
  };

  return {
    load,
    add,
    delete: _delete,
    recommendations
  };
};
