// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostaktuellBaseProduct_paBaseProductContainer__WPJMy {
  max-width: 50%;
}
.PostaktuellBaseProduct_paBaseProductContainerRow__qFvYO {
  margin-bottom: 25px;
}

.PostaktuellBaseProduct_paBaseProductContainerRow__qFvYO h6 {
  margin-bottom: 8px;
  font-size: 16px;
  text-align: left;
  color: #323232;
  font-weight: 600;
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  line-height: 1.125;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/dialogMarketing/components/PostaktuellBaseProducts/PostaktuellBaseProduct.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":[".paBaseProductContainer {\n  max-width: 50%;\n}\n.paBaseProductContainerRow {\n  margin-bottom: 25px;\n}\n\n.paBaseProductContainerRow h6 {\n  margin-bottom: 8px;\n  font-size: 16px;\n  text-align: left;\n  color: #323232;\n  font-weight: 600;\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  line-height: 1.125;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paBaseProductContainer": `PostaktuellBaseProduct_paBaseProductContainer__WPJMy`,
	"paBaseProductContainerRow": `PostaktuellBaseProduct_paBaseProductContainerRow__qFvYO`
};
export default ___CSS_LOADER_EXPORT___;
