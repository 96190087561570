import { isAvailableFromJan25 } from '../../../common/utils/availabilityByDate';

// MaxiBrief delivery discount will be discontinued from 1st October 2023
export const maxiBriefDeliveryDiscountDiscontinuedDate = new Date('2023-10-01');
export const maxiBriefDeliveryDiscountDiscontinuedDateAsNumber = maxiBriefDeliveryDiscountDiscontinuedDate.valueOf();

// Reusable fn that returns boolean value for maxi brief discount
export const maxiBriefDiscountAvailable = (inputDate?: Date): boolean => {
  if (!inputDate) {
    return false;
  } else {
    const date = new Date(inputDate);
    const dateToNumber = date?.valueOf();
    if (isAvailableFromJan25(date)) {
      return true;
    }
    if (dateToNumber >= maxiBriefDeliveryDiscountDiscontinuedDateAsNumber) {
      return false;
    } else if (date?.toDateString() === maxiBriefDeliveryDiscountDiscontinuedDate?.toDateString()) {
      return false;
    } else {
      return true;
    }
  }
};
