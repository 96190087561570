// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-multi-list_fullWidth__uTIKs {
  width: 100%;
}

.active-multi-list_list__ZHAaZ {
  position: absolute;
  z-index: 1;
}

.active-multi-list_hiddenList__nkT3Z {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/ActiveMultiList/active-multi-list.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;AACf","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n.list {\n  position: absolute;\n  z-index: 1;\n}\n\n.hiddenList {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": `active-multi-list_fullWidth__uTIKs`,
	"list": `active-multi-list_list__ZHAaZ`,
	"hiddenList": `active-multi-list_hiddenList__nkT3Z`
};
export default ___CSS_LOADER_EXPORT___;
