// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollectivePostingVariantsAdaptation_collectiveTable__BCngQ {
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
}
.CollectivePostingVariantsAdaptation_variantsTableHeading__6AizB {
  margin-top: 15px;
}
.CollectivePostingVariantsAdaptation_collectiveTableWithThreeCol__q1wJL {
  max-width: 89%;
  width: 100%;
}
.CollectivePostingVariantsAdaptation_collectiveTable__BCngQ thead th p {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
}

.CollectivePostingVariantsAdaptation_collectiveTable__BCngQ tbody td p {
  margin-bottom: 8px;
}

.CollectivePostingVariantsAdaptation_collectiveTable__BCngQ tbody td {
  word-break: break-all;
  max-width: 200px;
  padding-right: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/CollectivePostingVariantsAdaptation/CollectivePostingVariantsAdaptation.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".collectiveTable {\n  border-bottom: 1px solid #e6e6e6;\n  width: 100%;\n}\n.variantsTableHeading {\n  margin-top: 15px;\n}\n.collectiveTableWithThreeCol {\n  max-width: 89%;\n  width: 100%;\n}\n.collectiveTable thead th p {\n  margin-bottom: 8px;\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.collectiveTable tbody td p {\n  margin-bottom: 8px;\n}\n\n.collectiveTable tbody td {\n  word-break: break-all;\n  max-width: 200px;\n  padding-right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectiveTable": `CollectivePostingVariantsAdaptation_collectiveTable__BCngQ`,
	"variantsTableHeading": `CollectivePostingVariantsAdaptation_variantsTableHeading__6AizB`,
	"collectiveTableWithThreeCol": `CollectivePostingVariantsAdaptation_collectiveTableWithThreeCol__q1wJL`
};
export default ___CSS_LOADER_EXPORT___;
