import { useClusterCombination, UseClusterCombinationReturn } from 'order/common/hooks/useClusterCombinations';
import { useDependentProducts, UseDependentProductsReturn } from 'order/common/hooks/useDependentProducts';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DialogMarketingOrderCreate } from '../schema/dialogMarketingSchema';

const useResetField = (clusterReturn: UseDialogPostClusterCombinations) => {
  const { setValue } = useFormContext<DialogMarketingOrderCreate>();
  const { easyAvailable, nichtAutomationsfaehigAvailable, trackAndMatchAvailable } = clusterReturn;
  useEffect(() => {
    if (!easyAvailable) {
      setValue('subProducts.easy', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [easyAvailable]);

  useEffect(() => {
    if (!nichtAutomationsfaehigAvailable) {
      setValue('subProducts.nichtAutomationsfaehig', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nichtAutomationsfaehigAvailable]);

  useEffect(() => {
    if (!trackAndMatchAvailable) {
      setValue('subProducts.trackAndMatch', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackAndMatchAvailable]);

  const { sortedTrayPostcodeAvailable, sortedTrayRegionAvailable, sortedPalletRegionAvailable, sortedPalletZoneAvailable } = clusterReturn.fertigung;

  useEffect(() => {
    if (!sortedTrayPostcodeAvailable) {
      setValue('fertigung.sortedTrayPostcode', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTrayPostcodeAvailable]);

  useEffect(() => {
    if (!sortedTrayRegionAvailable) {
      setValue('fertigung.sortedTrayRegion', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTrayRegionAvailable]);

  useEffect(() => {
    if (!sortedPalletRegionAvailable) {
      setValue('fertigung.sortedPalletRegion', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedPalletRegionAvailable]);

  useEffect(() => {
    if (!sortedPalletZoneAvailable) {
      setValue('fertigung.sortedPalletZone', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedPalletZoneAvailable]);
};

export type UseDialogPostClusterCombinations = Pick<UseClusterCombinationReturn, 'isProductAvailable'> & {
  easyAvailable: boolean;
  trackAndMatchAvailable: boolean;
  nichtAutomationsfaehigAvailable: boolean;
  fertigung: Record<`${keyof UseDependentProductsReturn['fertigung']}Available`, boolean>;
};

export const useDialogPostClusterCombinations = (): UseDialogPostClusterCombinations => {
  const values = useWatch();
  const dialogpostBaseProduct = values.dialogpostBaseProduct;
  const productGroup = values?.productGroup;
  const selectedDate = values?.details?.date;
  const selectedValues = values?.subProducts;

  const dependent = useDependentProducts(productGroup, selectedDate, dialogpostBaseProduct);
  const { easy, nichtAutomationsfaehig, trackAndMatch } = dependent;

  const selectedProductNumbers = useMemo(() => {
    const set: string[] = [];
    if (selectedValues?.easy && easy?.productNumber) {
      set.push(easy.productNumber);
    }
    if (selectedValues?.nichtAutomationsfaehig && nichtAutomationsfaehig?.productNumber) {
      set.push(nichtAutomationsfaehig.productNumber);
    }
    if (selectedValues?.trackAndMatch && trackAndMatch?.productNumber) {
      set.push(trackAndMatch.productNumber);
    }
    if (selectedValues?.premium) {
      set.push(selectedValues?.premium);
    }

    return set;
  }, [
    easy?.productNumber,
    nichtAutomationsfaehig?.productNumber,
    selectedValues?.easy,
    selectedValues?.nichtAutomationsfaehig,
    selectedValues?.premium,
    selectedValues?.trackAndMatch,
    trackAndMatch?.productNumber
  ]);

  const userClusterReturn = useClusterCombination(dialogpostBaseProduct, selectedProductNumbers, selectedDate);
  const { isProductAvailable } = userClusterReturn;

  const result: UseDialogPostClusterCombinations = {
    isProductAvailable,
    easyAvailable: isProductAvailable(easy),
    trackAndMatchAvailable: isProductAvailable(trackAndMatch),
    nichtAutomationsfaehigAvailable: isProductAvailable(nichtAutomationsfaehig),
    fertigung: {
      sortedPalletZoneAvailable: isProductAvailable(dependent.fertigung.sortedPalletZone),
      sortedPalletRegionAvailable: isProductAvailable(dependent.fertigung.sortedPalletRegion),
      sortedTrayRegionAvailable: isProductAvailable(dependent.fertigung.sortedTrayRegion),
      sortedTrayPostcodeAvailable: isProductAvailable(dependent.fertigung.sortedTrayPostcode)
    }
  };

  useResetField(result);
  return result;
};
