import React from 'react';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { FormRow } from 'common/components/FormRow';
import { useTranslation } from 'i18n';
import styles from './PostaktuellBaseProduct.module.css';
import { LetterBaseProduct } from 'generated';

export interface PostaktuellBaseProductsProps {
  letterBaseProducts?: LetterBaseProduct[];
}

const PostaktuellBaseProducts = ({ letterBaseProducts }: PostaktuellBaseProductsProps) => {
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });

  return (
    <div className={styles.paBaseProductContainer}>
      {letterBaseProducts?.map((bp, i) => {
        return (
          <div data-testid={`base-product-row`} className={styles.paBaseProductContainerRow} key={`${bp.baseProduct?.description}-${i}`}>
            <h6 data-testid={`base-product-heading-${i}`}>{`${t('basProduct')} ${i + 1}`}</h6>
            <FormRow mode="one-col">
              <CustomTextInput
                testid={`base-product-description-${i}`}
                name={`base-product-description-${i}`}
                label={t('basicProduct')}
                disabled={true}
                defaultValue={bp.baseProduct?.description}
              />
            </FormRow>
            <FormRow mode="two-col">
              <CustomTextInput
                name={`base-product-quantity-${i}`}
                testid={`base-product-quantity-${i}`}
                label={`${t('quantity')}*`}
                disabled={true}
                defaultValue={bp.baseProduct?.quantity}
              />
              <CustomTextInput
                name={`base-product-weight-${i}`}
                testid={`base-product-weight-${i}`}
                label={`${t('weightPerItemInG')}*`}
                disabled={true}
                defaultValue={bp.baseProduct?.weight}
              />
            </FormRow>
          </div>
        );
      })}
    </div>
  );
};

export default PostaktuellBaseProducts;
