import { FormRow } from 'common/components/FormRow';
import { Tooltip, TooltipProps } from 'common/components/Tooltip/Tooltip';
import { ObjectContaining, PathsEndingWith, SFCProps } from 'common/utils/formHelpers';
import classNames from 'classnames';
import i18n, { useTranslation } from 'i18n';
import { Moment } from 'moment';
import { ProductionFacilitySelect } from 'order/common/components/ProductionFacilitySelect';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { DateInput } from 'order/productGroups/common/components/atom/DateInput';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TimeInput } from 'order/productGroups/common/components/atom/TimeInput';
import React, { ReactElement, useEffect, useState } from 'react';
import { FieldValues, useController, useFormContext, useWatch } from 'react-hook-form';
import styles from './TimeCitySection.module.css';
import { Path, PathValue } from 'react-hook-form';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { uid } from 'react-uid';

export type TimeCitySchema = {
  date: Date;
  time?: string;
  productionPlantId?: string;
  submissionType?: string | null;
};

export type TimeCitySectionProps<T extends FieldValues> = SFCProps<T, TimeCitySchema> & {
  editing?: boolean;
  disabled?: boolean;
  pickup?: boolean;
  selectedProductGroup?: string;
  product?: ProductGroup;
  isCollection?: boolean;
  onDateChange?: (date: Date) => void;
  disabledDate?: boolean;
  disabledTime?: boolean;
  disabledPlace?: boolean;
  minDate?: Moment;
};

const translationDE = {
  pickup: {
    headline: 'Abholuhrzeit und -datum',
    date: 'Abholdatum*',
    time: 'Abholuhrzeit*'
  },
  headline: 'Einlieferungsdatum und -ort',
  date: 'Einlieferungsdatum*',
  time: 'Einlieferungsuhrzeit*',
  city: 'Einlieferungsstelle*',
  deliveryPoint: 'Zuständiges Briefzentrum*',
  error: {
    invalid: 'Dies ist ein Pflichtfeld.'
  },
  checkboxLabel: 'Schlusskennzeichen der Teileinlieferung',
  tooltipContent: 'Das Schlusskennzeichen gibt an, dass es sich bei diesem Auftrag um den letzten Auftrag einer Teileinlieferung handelt.'
};
const translationEN = {
  pickup: {
    headline: 'Pickup Time and Date',
    date: 'Pickup Date*',
    time: 'Pickup Time*'
  },
  headline: 'Consignment Date and Place',
  date: 'Consignment Date*',
  time: 'Consignment Time*',
  city: 'Consignment Location*',
  deliveryPoint: 'Responsible Letter Center*',
  error: {
    invalid: 'This field is mandatory.'
  },
  checkboxLabel: 'Last Posting Indicator for partial delivery',
  tooltipContent: 'The final indicator indicates that this order is the last order of a partial delivery.'
};

i18n.addResourceBundle('de', 'timeCitySection', translationDE);
i18n.addResourceBundle('en', 'timeCitySection', translationEN);

export const TimeCitySection = <T extends FieldValues>({
  editing,
  pickup,
  disabled,
  name,
  selectedProductGroup,
  product,
  isCollection,
  onDateChange,
  disabledDate,
  disabledTime,
  disabledPlace,
  minDate
}: TimeCitySectionProps<T>): ReactElement => {
  const { t: tUnprefixed } = useTranslation('timeCitySection');
  const t = (key: string) => (pickup ? tUnprefixed(`pickup.${key}`) : tUnprefixed(key));
  const { fieldState, field: dateField } = useController<ObjectContaining<TimeCitySchema>, PathsEndingWith<'date'>>({ name: `${name}.date` });
  const { fieldState: timeState, field: timeField } = useController<ObjectContaining<TimeCitySchema>, PathsEndingWith<'time'>>({
    name: `${name}.time`
  });

  const { order, orderMode } = useOrderContext();
  const { setValue } = useFormContext<T>();

  useEffect(() => {
    if (!editing && !timeField.value?.length) {
      timeField.onChange('12:00');
    }
  }, []);

  const submissionType = useWatch({ name: `${name}.submissionType` });

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newChecked = !isChecked;
    const newSubmissionType = newChecked ? 'S' : 'T';
    setValue(`${name}.submissionType` as Path<T>, newSubmissionType as PathValue<T, Path<T>>);
    setIsChecked(newChecked);
  };

  useEffect(() => {
    setIsChecked(submissionType === 'S');
  }, [submissionType, editing]);

  return (
    <OrderStepSection headline={t('headline')}>
      <FormRow mode="two-col">
        <DateInput
          name={`${name}.date`}
          label={`${t('date')}`}
          disabled={disabled || disabledDate}
          error={fieldState.error ? 'invalid' : undefined}
          rules={{
            required: true
          }}
          onChange={onDateChange}
          minDate={minDate}
        />
        {(pickup || isCollection) && (
          <TimeInput<ObjectContaining<TimeCitySchema>, PathsEndingWith<'time'>>
            name={`${name}.time`}
            disabled={disabled || disabledTime}
            label={`${t('time')}`}
            error={timeState.error?.message}
            rules={{
              required: {
                value: true,
                message: 'invalid'
              }
            }}
          />
        )}
        {!pickup && !isCollection && (
          <ProductionFacilitySelect
            name={`${name}.productionPlantId`}
            rules={{ required: false }}
            selectedDate={dateField.value}
            useDepot={selectedProductGroup === 'PRESS_DISTRIBUTION'}
            useSLN={selectedProductGroup === 'PRESS_DISTRIBUTION'}
            disabled={disabled || disabledPlace}
          />
        )}
      </FormRow>
      {orderMode === OrderMode.CHANGE && (submissionType === 'T' || submissionType === 'S') && (
        <FormRow>
          <div className={classNames(styles.checkboxWithTooltip)}>
            <DHLCheckbox
              name={uid({ name })}
              label={tUnprefixed('checkboxLabel')}
              value={isChecked}
              onChange={handleCheckboxChange}
              disabled={disabled}
              data-testid="checkbox-testid"
            />
            <Tooltip placement="right" content={tUnprefixed('tooltipContent')}>
              <i className={classNames(styles.radioButtonCardInfoForTooltip, 'icon-alert-info')}></i>
            </Tooltip>
          </div>
        </FormRow>
      )}
    </OrderStepSection>
  );
};
