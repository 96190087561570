import classNames from 'classnames';
import { OrderPriceRep, ServiceClusterRep } from 'generated';
import { PSLBillingTableHeadRow } from './PSLBillingTableHeadRow';
import { PSLBillingTableRow } from './PSLBillingTableRow';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import tableStyles from 'order/orderSearch/components/table/AMTable.module.css';
import { DHLFormGroup, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import styles from './pslbilling-table.module.css';
import { PSLBillingTableSumRow } from './PSLBillingTableSumRow';

type PSLBillingTableProps = {
  orderPrice?: OrderPriceRep;
};

export enum PartialProductDescription {
  PartialDiscount = 'Teilleistung',
  IdDiscount = 'Laufzeit-Rabatt',
  TermDiscount = 'ID-Rabatt'
}

export const PSLBillingTable: VFC<PSLBillingTableProps> = ({ orderPrice }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  let unitAmountCol = false;
  let partialTotalQty = 0;
  let partialTotalPrice = 0;
  let idTotalQty = 0;
  let idTotalPrice = 0;
  let termTotalQty = 0;
  let termTotalPrice = 0;
  let currency = '';
  orderPrice?.serviceClusters?.forEach((item: ServiceClusterRep) => {
    item.pricePositions?.forEach((value) => {
      currency = value.productDescription === PartialProductDescription.PartialDiscount ? value.amount?.currency ?? '' : currency;
      partialTotalQty =
        value.productDescription === PartialProductDescription.PartialDiscount ? partialTotalQty + (value?.qty ?? 0) : partialTotalQty;
      partialTotalPrice =
        value.productDescription === PartialProductDescription.PartialDiscount ? partialTotalPrice + (value.amount?.value ?? 0) : partialTotalPrice;
      idTotalQty = value.productDescription === PartialProductDescription.IdDiscount ? idTotalQty + (value?.qty ?? 0) : idTotalQty;
      idTotalPrice = value.productDescription === PartialProductDescription.IdDiscount ? idTotalPrice + (value.amount?.value ?? 0) : idTotalPrice;
      termTotalQty = value.productDescription === PartialProductDescription.TermDiscount ? termTotalQty + (value?.qty ?? 0) : termTotalQty;
      termTotalPrice =
        value.productDescription === PartialProductDescription.TermDiscount ? termTotalPrice + (value.amount?.value ?? 0) : termTotalPrice;
      if (value.productDescription === PartialProductDescription.PartialDiscount && value.unitAmount) unitAmountCol = true;
    });
  });

  return orderPrice ? (
    <DHLFormGroup className={styles.tableMargin}>
      <DHLHeadline type="h6" className={styles.centerAlign}>
        {t('pslTableBillingSubHeader')}
      </DHLHeadline>
      <table className={'dhlTable table ' + tableStyles.amTable}>
        <thead className={classNames('dhlTable-sort-header ' + tableStyles.headcellalign)}>
          <PSLBillingTableHeadRow unitAmountCol={unitAmountCol} />
        </thead>
        <tbody>
          {orderPrice?.serviceClusters?.map((item: ServiceClusterRep, index: number) => {
            return <PSLBillingTableRow key={'row-data-' + index} index={index} item={item} unitAmountCol={unitAmountCol} />;
          })}
          <PSLBillingTableSumRow
            partialTotalQty={partialTotalQty}
            partialTotalPrice={partialTotalPrice}
            idTotalQty={idTotalQty}
            idTotalPrice={idTotalPrice}
            termTotalQty={termTotalQty}
            termTotalPrice={termTotalPrice}
            unitAmountCol={unitAmountCol}
            currency={currency}
            totalFeeReductionWithoutVAT={orderPrice?.orderFees?.find((item) => item.type === 'OM_PORTAL_REDUCTION_ONLY_VAT_RELEVANT')?.amount}
            totalFeeReductionWithVAT={orderPrice?.orderFees?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount}
          />
        </tbody>
      </table>
    </DHLFormGroup>
  ) : null;
};
