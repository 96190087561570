// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistorySearchItem_historySearchItem__jsv8Y {
  background-color: white !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  border-radius: 4px;
  border: 1px solid rgba(50, 50, 50, 0.3);
  margin-top: 20px !important;
}

.HistorySearchItem_criteriaHistory__koMZf {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px 16px;
  margin: 0;
}

.HistorySearchItem_historySearchItem__jsv8Y .HistorySearchItem_criteriaHistory__koMZf > div {
  margin: 0;
}

.HistorySearchItem_historyActions__G3FS8 {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 15px 16px;
  margin: 0;
}

.HistorySearchItem_dateTime__qEQ4H {
  font-size: 12px;
  margin-top: 10px !important;
  color: rgba(50, 50, 50, 0.7);
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/HistorySearchItem.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,uCAAuC;EACvC,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[".historySearchItem {\n  background-color: white !important;\n  display: flex;\n  align-items: center;\n  padding: 0 !important;\n  border-radius: 4px;\n  border: 1px solid rgba(50, 50, 50, 0.3);\n  margin-top: 20px !important;\n}\n\n.criteriaHistory {\n  flex-grow: 1;\n  flex-shrink: 1;\n  padding: 20px 16px;\n  margin: 0;\n}\n\n.historySearchItem .criteriaHistory > div {\n  margin: 0;\n}\n\n.historyActions {\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding: 15px 16px;\n  margin: 0;\n}\n\n.dateTime {\n  font-size: 12px;\n  margin-top: 10px !important;\n  color: rgba(50, 50, 50, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historySearchItem": `HistorySearchItem_historySearchItem__jsv8Y`,
	"criteriaHistory": `HistorySearchItem_criteriaHistory__koMZf`,
	"historyActions": `HistorySearchItem_historyActions__G3FS8`,
	"dateTime": `HistorySearchItem_dateTime__qEQ4H`
};
export default ___CSS_LOADER_EXPORT___;
