import bwipjs from 'bwip-js';

export const getOrderIdBarcodeImageUrl = (barcodeValue: string) => {
  const canvas = document.createElement('canvas');

  bwipjs.toCanvas(canvas, {
    bcid: 'interleaved2of5', // Barcode type
    text: barcodeValue, // Text to encode
    scale: 3, // 3x scaling factor
    height: 8, // Bar height, in millimeters
    includetext: false, // Show human-readable text
    textyalign: 'above', // Always good to set this
    textxalign: 'left'
  });

  return canvas.toDataURL('image/png');
};
