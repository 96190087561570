import { StyleSheet } from '@react-pdf/renderer';

const pageMargins = {
  left: '10mm',
  right: '10mm'
};

export const greyColor = '#D9D9D9';

export const styles = StyleSheet.create({
  pslTableCell: { textAlign: 'right', fontSize: '8pt', width: '100%', padding: '1mm 2mm' },
  // internationalPage: {
  //   paddingLeft: pageMargins.left,
  //   paddingRight: pageMargins.right,
  //   paddingTop: '10mm',
  //   paddingBottom: '10mm',
  //   fontFamily: 'delivery',
  //   fontSize: '10pt'
  // },
  internationalBarcodeConatiner: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'flex-end',
    marginTop: '5mm'
  },
  page: {
    paddingLeft: pageMargins.left,
    paddingRight: pageMargins.right,
    paddingTop: '10mm',
    paddingBottom: '20mm',
    fontFamily: 'delivery',
    fontSize: '10pt'
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  logo: {
    alignSelf: 'flex-end'
  },
  pageHeading: {
    fontFamily: 'delivery',
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  paddingTop5: {
    paddingTop: '5mm'
  },
  linesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    marginTop: '1mm'
  },
  lineContainerStreched: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStreched: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStrechedCenter: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  groupContainer: {
    marginTop: '1mm',
    borderTop: `1px solid ${greyColor}`
  },
  borderTop: {
    borderTop: `1px solid ${greyColor}`
  },
  borderBottom: {
    borderBottom: `1px solid ${greyColor}`
  },
  borderLeft: {
    borderLeft: `1px solid ${greyColor}`
  },
  borderRight: {
    borderRight: `1px solid ${greyColor}`
  },
  borderTopBlack: {
    borderTop: `1px solid #000`
  },
  borderBottomBlack: {
    borderBottom: `1px solid #000`
  },
  borderLeftBlack: {
    borderLeft: `1px solid #000`
  },
  borderRightBlack: {
    borderRight: `1px solid #000`
  },
  textLeft: {
    textAlign: 'left'
  },
  textCenter: {
    textAlign: 'center'
  },
  textRight: {
    textAlign: 'right'
  },
  textHeader: {
    fontFamily: 'delivery',
    fontSize: '10pt',
    fontWeight: 'bold',
    marginTop: '5mm'
  },
  subTextHeader: {
    fontFamily: 'delivery',
    fontSize: '10pt',
    fontWeight: 'bold'
  },
  textAdress: {
    fontFamily: 'delivery',
    fontSize: '10pt'
  },
  textValue: {
    fontFamily: 'delivery',
    fontSize: '24pt'
  },
  textPaletteCount: {
    fontFamily: 'delivery',
    fontSize: '24pt'
  },
  textProduct: {
    fontFamily: 'delivery',
    fontSize: '56pt',
    fontWeight: 'bold'
  },
  textTarget: {
    fontFamily: 'delivery',
    fontSize: '100pt',
    fontWeight: 'bold'
  },
  textDate: {
    fontFamily: 'delivery',
    fontSize: '24pt',
    fontWeight: 'bold'
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    padding: 0,
    marginTop: '2mm'
  },
  gridHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    backgroundColor: greyColor,
    padding: 0,
    margin: 0
  },
  gridRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    padding: 0,
    margin: 0
  },
  gridHeaderItem: {
    width: '50%',
    margin: 0,
    padding: '2px'
  },
  gridHeaderItemRight: {
    width: '50%',
    margin: 0,
    padding: '2px',
    textAlign: 'right'
  },
  gridRowItem: {
    width: '50%',
    margin: 0,
    padding: '2px',
    paddingBottom: '10px'
  },
  gridRowItemRight: {
    width: '50%',
    margin: 0,
    padding: '2px',
    textAlign: 'right'
  },
  footer: {
    position: 'absolute',
    bottom: '10mm',
    left: pageMargins.left,
    right: pageMargins.right
  }
});
