import { AppProps } from 'App';
import { ChangeLog } from 'changelog/ChangeLog';
import { AlertProvider } from 'common/AlertContext';
import Footer from 'common/components/Footer';
import { ModalWrapper } from 'common/components/modal/ModalWrapper';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { procedureCacheReload } from 'common/services/ProcedureService';
import { now } from 'common/utils/timeAndDate';

import i18n from 'i18n';
import moment from 'moment/moment';
import { Spinner } from 'order/common/components/Spinner';
import { OrderProvider } from 'order/common/context/order/OrderProvider';
import { SearchProvider } from 'order/common/context/search/SearchProvider';
import { OrderDisplayContextProvider } from 'order/orderView/components/OrderContext/OrderDisplayContextProvider';
import { OrderView } from 'order/orderView/components/OrderView';
import { OrderChangeContainer } from 'order/orderChange/OrderChangeContainer';
import { AuthorizedOrderCreateWrapper } from 'order/productGroups/common/components/molecule/OrderCreatePage/AuthorizedOrderCreateWrapper';
import { OrderCopyContainer } from 'order/orderCopy/OrderCopyContainer';
import { OrderSearchPage } from 'order/orderSearch/components/OrderSearchPage';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, MemoryRouter, Redirect, Route, Switch } from 'react-router-dom';

export const AppPaths = {
  orderSearchPath: '/ordermanagement-mail/ordersearch',
  orderCreatePath: '/ordermanagement-mail/ordercreate',
  orderChangePath: '/ordermanagement-mail/change',
  orderViewPath: '/ordermanagement-mail/orders',
  orderCopyPath: '/ordermanagement-mail/copy'
};

/**
 * The helper app function
 *
 * @constructor
 */
const Main = (props: AppProps): JSX.Element => {
  const { setAuthMgr, user, loading, permissions, setExtUserId } = useAuthContext();
  const [, setLanguage] = useState(user?.language);

  console.debug(`${now()} am-portal-frontend / appAuth: url - ${window.location}`);
  if (props) {
    console.debug(`${now()} am-portal-frontend / appAuth: props - name ${props.name}`);
    if (props.authenticationManager) {
      console.debug(`${now()} am-portal-frontend / appAuth: authManager ${props.authenticationManager ? 'available' : 'not available'}`);
      setAuthMgr(props.authenticationManager);

      if (props.extUserId) {
        console.debug(`${now()} am-portal-frontend / appAuth: props - extUserId ${props.extUserId}, path ${props.path}`);
      }
      setExtUserId(props.extUserId);
    }
  } else {
    console.debug(`${now()} am-portal-frontend / appAuth: no props.`);
  }

  const changeLanguage = async (newLang: string): Promise<void> => {
    await i18n.changeLanguage(newLang);
    localStorage.setItem('lang', newLang);
    moment.locale(newLang);
    setLanguage(newLang);
  };

  useEffect(() => {
    console.debug(`current lang: ${localStorage.getItem('lang')}`);
    if (user?.isAuthenticated && localStorage.getItem('lang') !== user?.language) {
      changeLanguage(user?.language).then(() => {
        console.debug(`new lang: ${i18n.language}`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.language]);

  useEffect(() => {
    if (user?._accessToken) {
      procedureCacheReload();
    }
  }, [user?._accessToken, user?.extUserId, loading]);

  const content = (
    <>
      <Switch>
        <Route path="/a">
          <Redirect to={AppPaths.orderCreatePath} />
        </Route>
        <Route exact path="/">
          <Redirect to={AppPaths.orderSearchPath} />
        </Route>
        <Route exact path={AppPaths.orderCreatePath} component={AuthorizedOrderCreateWrapper} />
        <Route path={`${AppPaths.orderChangePath}/:orderId`} component={OrderChangeContainer} />
        <Route path={`${AppPaths.orderCopyPath}/:orderId`} component={OrderCopyContainer} />
        <Route path={`${AppPaths.orderViewPath}/:orderId`} component={OrderView} />
        <Route path="/changelog" component={ChangeLog} />
        <Route path={AppPaths.orderSearchPath} component={OrderSearchPage} />
      </Switch>
      <ModalWrapper />
      <Footer />
    </>
  );

  return loading ? (
    <Spinner testid="spinnerTestId" />
  ) : !user || !user.isAuthenticated || !user._accessToken || !permissions ? (
    <></>
  ) : (
    <AlertProvider>
      <OrderProvider>
        <OrderDisplayContextProvider>
          <SearchProvider>
            {props?.extUserId && props?.path ? (
              <MemoryRouter initialEntries={props.path === 'search' ? [AppPaths.orderSearchPath] : [AppPaths.orderCreatePath]}>
                {content}
              </MemoryRouter>
            ) : (
              <BrowserRouter>{content}</BrowserRouter>
            )}
          </SearchProvider>
        </OrderDisplayContextProvider>
      </OrderProvider>
    </AlertProvider>
  );
};

export default Main;
