import i18n from 'i18n';

export const enum ProductionState {
  planning = 'PLA',
  groupPlanning = 'GPL',
  cancelled = 'CAN',
  cancelledAndMerged = 'CME',
  inProgress = 'IPR',
  billed = 'BIL'
}

export const orderStatusOptionsForPartial = [
  {
    id: ProductionState.planning,
    name: i18n.t('orderStatus.status.PLA')
  },
  // {
  //   id: ProductionState.groupPlanning,
  //   name: i18n.t('orderStatus.status.GPL')
  // },
  {
    id: ProductionState.inProgress,
    name: i18n.t('orderStatus.status.IPR')
  },
  {
    id: ProductionState.billed,
    name: i18n.t('orderStatus.status.BIL')
  }
];

export const orderStatusOptions = [
  {
    id: ProductionState.planning,
    name: i18n.t('orderStatus.status.PLA')
  },
  // {
  //   id: ProductionState.groupPlanning,
  //   name: i18n.t('orderStatus.status.GPL')
  // },
  {
    id: ProductionState.cancelled,
    name: i18n.t('orderStatus.status.CAN')
  },
  {
    id: ProductionState.inProgress,
    name: i18n.t('orderStatus.status.IPR')
  },
  {
    id: ProductionState.billed,
    name: i18n.t('orderStatus.status.BIL')
  }
];
