import classNames from 'classnames';
import { AppPaths } from 'Main';
import { OrderTreeItem } from 'order/orderView/components/OrderTree/OrderTree';
import { getOrderTreeItemKeys, valueToString } from 'order/orderView/components/OrderTree/utils';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './order-tree.module.css';
import { useHistory } from 'react-router-dom';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { OrderTypes } from '../../../orderSearch/components/OrderTypes';
import i18n from '../../../../i18n';

type OrderTreeRowProps = {
  order: OrderTreeItem;
  level?: number;
  uncleBelow?: boolean;
  showZkz?: boolean;
  hasChildren?: boolean;
};

export const OrderTreeRow: VFC<OrderTreeRowProps> = ({ order, level = 0, uncleBelow, showZkz, hasChildren }) => {
  const { t } = useTranslation('', { keyPrefix: 'orderStatus.status' });
  const { children = [] } = order;
  const history = useHistory();
  return (
    <>
      <tr
        key={order.orderId}
        className={classNames(classes.row, {
          [classes.level0]: level === 0,
          [classes.level1]: level === 1,
          [classes.level2]: level > 1,
          [classes.uncleBelow]: uncleBelow
        })}
      >
        {getOrderTreeItemKeys(showZkz, hasChildren)
          .map((key) => {
            const value = order[key];
            const output = valueToString(key, value);
            if (key === 'state') {
              return [key, t(output)];
            }
            return [key, output];
          })
          .map(([key, output]) => {
            if (key === 'quantity') {
              return (
                <td className={classes.textRight} key={key}>
                  {output}
                </td>
              );
            }
            if (key === 'totalAmount') {
              return (
                <td className={classes.textRight} key={key}>
                  {level < 1 ? '' : output}
                </td>
              );
            }
            if (key === 'orderType') {
              return <td key={key}>{i18n.t('orderTable.rowElement.orderType.' + output)}</td>;
            }
            if (key === 'orderId') {
              return (
                <td key={key}>
                  {order.orderType === OrderTypes.Jobgruppe || order.orderType === OrderTypes.Teileinlieferung ? (
                    <span className={classes.fontWeight}>{output}</span>
                  ) : (
                    <DHLButton
                      name="order-number"
                      size="sm"
                      type="ghost"
                      label={output}
                      onClick={() => {
                        history.push(`${AppPaths.orderViewPath}/${output}`);
                      }}
                    />
                  )}
                </td>
              );
            }
            return <td key={key}>{output}</td>;
          })}
      </tr>
      {children.map((child, index) => (
        <OrderTreeRow
          order={child}
          level={level + 1}
          key={child.orderId}
          uncleBelow={uncleBelow || (level === 0 && index < children.length - 1)}
          showZkz={showZkz}
          hasChildren={hasChildren}
        />
      ))}
    </>
  );
};
