import { OrderSearchResultRep, SearchOrder } from 'generated';
import { OrderSearchAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { convertToTDO } from 'order/orderSearch/services/orderSearchTDOConverter';
import { printRuntimeStatistics } from 'order/orderSearch/services/runtimeStatistics';
import { OrderSearchType } from '../dtos/OrderSearchType';
import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';
import { OrderSearchResultRepTDO, OrderSearchTDO } from '../../../services/OrderService';
import { getUser } from 'common/context/auth/AuthProvider';

export const requestOrderSearchData = async (
  type: OrderSearchType,
  attributes: OrderSearchAttribute[],
  meta: Record<string, any>,
  controller?: AbortController
): Promise<OrderSearchResultRep> => {
  const feStartTime = Date.now();
  const criteria = convertToTDO(
    {
      type,
      attributes
    },
    meta
  );
  const reqStartTime = Date.now();

  let data = await searchOrder(criteria, controller);

  if (!data) {
    data = {
      resultStatus: 'failed',
      durationInMillis: Date.now() - feStartTime,
      timings: {},
      orders: []
    };
  }
  // TODO find better solution after search rework
  if (data && (data === 504 || data === 408)) {
    data = {
      resultStatus: '504',
      durationInMillis: Date.now() - feStartTime,
      timings: {},
      orders: []
    };
  }
  if (data && data >= 500) {
    data = {
      resultStatus: '500',
      durationInMillis: Date.now() - feStartTime,
      timings: {},
      orders: []
    };
  }
  if (data.timings) {
    data.timings['fe_started'] = feStartTime;
    data.timings['fe_req_started'] = reqStartTime;
    data.timings['fe_req_finished'] = Date.now();
  }
  printRuntimeStatistics(data, reqStartTime);
  return data;
};

const searchOrder = async (criteria: SearchOrder, controller?: AbortController): Promise<OrderSearchTDO | OrderSearchResultRepTDO | any> => {
  try {
    const user = getUser();
    const { data, status } = await sendRequest({
      method: 'post',
      token: user._accessToken,
      extUserId: user.extUserId,
      url: `${BASE_URL}orders/search`,
      data: criteria as any,
      controller
    });

    return data;
  } catch (e: any) {
    console.error('Error during requesting search data', e);
    // TODO find better solution after search rework
    if (e?.message?.indexOf('CanceledError') > 0) {
      return 500;
    }
    if (e?.response?.code && e.response?.code >= 500) {
      return e.response?.code;
    }
    if (e?.response?.code && e.response?.code === 408) {
      return 408;
    }
    if (e?.response?.code && e.response?.code === 504) {
      return 504;
    }
    return e;
  }
};
