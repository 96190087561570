import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { OrderReplyTDO, OrderSearchResultRepTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { Filter } from 'common/components/Table/dtos/Filter';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { Table } from 'common/components/Table/Table';
import { getCurrentSlice, moveColumnLeft, moveColumnRight, rowContainsFilter, sortRows } from 'order/common/context/table/utils/table';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { Button } from 'common/components/Button';
import { Pagination } from 'common/components/Paginator/Pagination';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { Sort } from 'common/components/Table/dtos/Sort';
import styles from '../OrderMergeTableContainer/OrderMergeTableContainer.module.css';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';
import { searchTableConfigurationForOrderMerge } from 'order/productGroups/additionalOrders/workFlowSteps/step3/components/table/columnConfiguration';

const defaultPageSize = 5;

export const OrderMergeSearchTable = (): JSX.Element => {
  const { setValue, control } = useFormContext<AdditionalOrdersOrderCreate>();
  const { response: rawResponse, loading } = useSearchContext();
  // response is the search result without the parent order.
  const [response, setResponse] = useState<OrderSearchResultRepTDO | undefined>(rawResponse);
  const { generateNewAllocations, allocationsLoading, order } = useOrderContext();
  const [rows, setRows] = useState<OrderTreeItem<OrderReplyTDO>[]>([]);
  const [currentSlice, setCurrentSlice] = useState<OrderTreeItem<OrderReplyTDO>[]>([]);
  const filter = useRef<Filter>({ name: null, value: null });
  const sort = useRef<Sort>({ name: null, order: null });
  const page = useRef<number>(1);
  const pageSize = useRef<number>(defaultPageSize);
  const filteredRows = useRef<OrderTreeItem<OrderReplyTDO>[]>([]);
  const [loadingState, setLoadingState] = useState<TableLoadingState>(TableLoadingState.NOT_LOADED);
  const [columns, setColumnConfig] = useState<ColumnConfig[]>(searchTableConfigurationForOrderMerge);
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const { t: om } = useTranslation('', { keyPrefix: 'orderMerge' });
  const language = useAuthContext().user.language;

  const [selectedSearchedOrderIds] = useWatch({
    control,
    name: ['selectedSearchedOrderIds']
  });

  useEffect(() => {
    setResponse(undefined);
    if (rawResponse?.orders) {
      const responseWithoutParentOrder = rawResponse?.orders?.filter((o) => o.data?.orderNumber !== order?.orderId);
      const transformedResponse = rawResponse;
      transformedResponse.orders = responseWithoutParentOrder;
      setResponse(transformedResponse);
    }
  }, [rawResponse]);

  useEffect(() => {
    page.current = 1;
    pageSize.current = defaultPageSize;
    const curSlice = getCurrentSlice(response?.orders || [], page.current, pageSize.current);
    filteredRows.current = response?.orders || [];
    setRows(response?.orders || []);
    setCurrentSlice(curSlice);
    setValue('searchedOrders', response?.orders || []);
    setValue('filteredSelectedAllocOrders', response?.orders || []);
    setValue('currentSelectedSlice', curSlice);
  }, [response?.orders]);

  useEffect(() => {
    if (!loading && response) {
      filteredRows.current = response.orders || [];
      setValue('filteredSelectedAllocOrders', response.orders || []);
      setValue('searchedOrders', response.orders || []);
      setRows(response.orders || []);
    }
    if (loading) {
      setLoadingState(TableLoadingState.LOADING);
    }
    if (loadingState === TableLoadingState.LOADING && !loading) {
      setLoadingState(TableLoadingState.LOADED);
    }
  }, [response, loading, loadingState]);

  const resetSortAndFilters = () => {
    filteredRows.current = rows;
    const curSlice = getCurrentSlice(filteredRows.current, page.current, pageSize.current);
    setCurrentSlice(curSlice);
    setValue('currentSelectedSlice', curSlice);
  };

  const setFilter = (name: string | null, value: string | null) => {
    const property = name;
    let filtered = [...rows];
    filter.current = { name, value };
    if (property && value) {
      filtered = filtered.filter((r) => rowContainsFilter(property, value, r));
    }
    const currentSlice = getCurrentSlice(filtered, 1, pageSize.current);
    filteredRows.current = filtered;
    setCurrentSlice(currentSlice);
    setValue('filteredSelectedAllocOrders', filtered);
    setValue('currentSelectedSlice', currentSlice);
  };

  const setSorting = (name: string, order: SortOrder, type: ColumnType) => {
    const filtered = [...filteredRows.current];
    const sortedRows = sortRows(name, filtered, order);
    const currentSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    sort.current = { name, order, type };
    filteredRows.current = sortedRows;
    setCurrentSlice(currentSlice);
    setValue('currentSelectedSlice', currentSlice);
  };

  // const setColumnMove = (name: string, direction: 'left' | 'right') => {
  //   let columnConf = [...columns];
  //   if (direction === 'left') {
  //     columnConf = moveColumnLeft(columnConf, name);
  //   } else if (direction === 'right') {
  //     columnConf = moveColumnRight(columnConf, name);
  //   }

  //   setColumnConfig(columnConf);
  // };

  const handlePageSizeChange = (size: number) => {
    let newSlice = [...filteredRows.current];

    page.current = 1;

    if (size > 0) {
      newSlice = getCurrentSlice(newSlice, page.current, size);
    }

    pageSize.current = size;
    setValue('selectAll', false);
    setValue('currentSelectedSlice', newSlice);
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number) => {
    let newSlice = [...filteredRows.current];
    newSlice = getCurrentSlice(newSlice, p, pageSize.current);

    page.current = p;
    setValue('selectAll', false);
    setValue('currentSelectedSlice', newSlice);
    setCurrentSlice(newSlice);
  };

  const handleAllocationClick = useCallback(() => {
    generateNewAllocations(selectedSearchedOrderIds || [], true);
  }, [selectedSearchedOrderIds]);

  return (
    <div className={styles.wrapper}>
      <Table
        columnConfig={columns}
        currentSlice={currentSlice}
        loadingState={loadingState}
        t={t}
        filter={filter.current}
        sort={sort.current}
        onResetSortAndFilters={resetSortAndFilters}
        onFilterChange={setFilter}
        onSortChange={setSorting}
        // onMoveChange={setColumnMove}
        withTableOptions={false}
        highlightedRows={true}
        language={language}
      />
      {!loading && (
        <div className={styles.actionsBar}>
          <Pagination
            loadingState={loadingState}
            totalItems={filteredRows.current.length}
            defaultPageSize={defaultPageSize}
            onPageChange={handlePageChange}
            onSizeChange={handlePageSizeChange}
          />
        </div>
      )}
      <div className={styles.actionsBar}>
        <div />
        <div className={styles.searchTableFooter}>
          <Button
            id={'allocationsid'}
            dataTestId={'selectallocations'}
            sizing="xs"
            type="button"
            variant={'primary'}
            label={om('addOrdersForMergeButton')}
            icon="plus"
            disabled={allocationsLoading}
            onClick={handleAllocationClick}
          />
        </div>
      </div>
    </div>
  );
};
