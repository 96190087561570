import React, { useEffect, useState, VFC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import orderClasses from 'common/styles/order.module.css';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { ProductGroupInput } from 'order/productGroups/common/components/molecule/ProductGroupInput';
import { isAvailableProductGroup } from 'order/productGroups/common/utils/orderCreateSchema';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import iconZusteller from 'assets/icon-zusteller.svg';
import { BackToSearchButton } from '../../../../../common/components/BackToSearch/BackToSearchButton';
import styles from './OrderCreateStep1.module.css';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';

export const OrderCreateStep1: VFC = () => {
  const {
    isDirty,
    setOrderCategory,
    setOrderCategoryProductKey,
    setProductGroup,
    orderCategoryProductKey,
    resetAllocations,
    resetFaultyGetOrderIds
  } = useOrderContext();
  const { control, formState, reset: resetForm } = useFormContext();
  const { reset, trigger } = useOrderTransferForm();
  const { nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');

  const productGroup = useWatch({
    control,
    name: 'productGroup'
  });

  useEffect(() => {
    resetForm({
      productGroup: productGroup,
      details: {
        time: '12:00'
      }
    });
  }, []);

  const setPropsOnChange = (selectedProductGroup: string) => {
    const defaultResetValue = {
      productGroup: selectedProductGroup,
      details: {
        time: '12:00'
      }
    };
    resetFaultyGetOrderIds();
    switch (selectedProductGroup) {
      case ProductGroup.DIALOGPOST:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.DIP_EINZEL);
        setOrderCategoryProductKey(OrderCategoryProductKey.DiP_EINZEL__DIALOGPOST);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.DIP_EINZEL
        });
        break;
      case ProductGroup.DIALOGPOSTWURFSPEZIAL:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.DIP_EINZEL);
        setOrderCategoryProductKey(OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL);
        reset({
          ...defaultResetValue
        });
        break;
      case ProductGroup.BRIEF:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.AFM_BRIEF);
        setOrderCategoryProductKey(OrderCategoryProductKey.AFM_BRIEF);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.AFM_BRIEF
        });
        break;
      case ProductGroup.PRESS_DISTRIBUTION:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.PRESS_DISTRIBUTION);
        setOrderCategoryProductKey(OrderCategoryProductKey.PRESSDISTRIBUTION);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.PRESS_DISTRIBUTION
        });
        break;
      case ProductGroup.BRIEF_INTERNATIONAL:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.BRIEF_INTERNATIONAL);
        setOrderCategoryProductKey(OrderCategoryProductKey.BRIEF_INTERNATIONAL);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.BRIEF_INTERNATIONAL
        });
        break;
      case ProductGroup.DIALOGPOST_INTERNATIONAL:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.IP_INTERNATIONAL);
        setOrderCategoryProductKey(OrderCategoryProductKey.DIALOGPOST_INTERNATIONAL);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.IP_INTERNATIONAL
        });
        break;
      case ProductGroup.PRESS_INTERNATIONAL:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.PUB_INTERNATIONAL);
        setOrderCategoryProductKey(OrderCategoryProductKey.PRESS_INTERNATIONAL);
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.PUB_INTERNATIONAL
        });
        break;
      case ProductGroup.COLLECTION:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.ABHOLAUFTRAG);
        setOrderCategoryProductKey(OrderCategoryProductKey.ABHOLAUFTRAG);
        resetAllocations();
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.ABHOLAUFTRAG
        });
        break;
      case ProductGroup.PARTIAL_SERVICE_LETTER:
        setProductGroup(selectedProductGroup);
        setOrderCategory(OrderCategory.TL_BRIEF);
        setOrderCategoryProductKey(OrderCategoryProductKey.TL_BRIEF);
        resetAllocations();
        reset({
          ...defaultResetValue,
          orderCategory: OrderCategory.TL_BRIEF
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="">
      <main className={classNames(orderClasses.formContent, styles.stepOneMainContainer)}>
        <div className={classNames(styles.orderTileContainer)}>
          <div className={classNames(styles.ocstep1Header)}>
            <OrderStepHeadline icon={iconZusteller} alt="Absender Icon">
              {t('steps.productGroup')}
            </OrderStepHeadline>
          </div>
          <ProductGroupInput
            selectedKey={orderCategoryProductKey}
            onChange={(group, category, key) => {
              if (!isAvailableProductGroup(group)) {
                console.log(`Product Group ${group} is not available`);
                return;
              }
              setPropsOnChange(group);
            }}
            workflowType={OrderMode.CREATE}
          />
        </div>
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <BackToSearchButton isDirty={formState.isDirty || isDirty} />
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            const isValid = await trigger('productGroup');
            if (isValid && productGroup) {
              nextStep();
            }
          }}
          type="button"
          disabled={!productGroup}
          label={t('buttons.continue')}
        />
      </footer>
    </div>
  );
};
