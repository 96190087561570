import React from 'react';
import { orderMergeVariantTableConfiguration } from 'order/common/components/AllocationTable/columnConfiguration';
import styles from './OrderMergeTableContainer.module.css';
import { OrderMergeSearchTable } from '../OrderMergeSearchTable/OrderMergeSearchTable';
import { useTranslation } from 'i18n';
import { ResultMessage } from 'order/orderSearch/components/ResultMessage';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { OrderMergeVariantListTable } from '../OrderMergeVariantListTable/OrderMergeVariantListTable';

export const OrderMergeTableContainer = (): JSX.Element => {
  const { response, loading } = useSearchContext();
  const { t } = useTranslation('', { keyPrefix: 'orderMerge' });
  const { control } = useFormContext();
  const [filteredSelectedAllocOrders, searchedOrders] = useWatch({
    control,
    name: ['filteredSelectedAllocOrders', 'searchedOrders']
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchTable}>
        <ResultMessage
          loadingState={loading}
          filteredCount={filteredSelectedAllocOrders?.length !== searchedOrders?.length ? filteredSelectedAllocOrders?.length : undefined}
          count={(response?.orders || []).length}
        />
        <div className={styles.tableCentering}>
          <OrderMergeSearchTable />
        </div>
      </div>
      <OrderMergeVariantListTable hideTableOptions={true} columnConfig={orderMergeVariantTableConfiguration()} />
    </div>
  );
};
