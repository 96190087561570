import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import { PostageType } from 'order/common/dtos/PostageType';
import { useDenotingPrepayments } from 'order/common/hooks/useDenotingPrepayments';
import React, { ReactElement, useCallback } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductGroup } from '../../dtos/ProductGroup';

export type PostageTypeDropdownProps<T extends FieldValues> = SFCProps<T, PostageType | undefined> & {
  label?: string;
  disabled?: boolean;
  baseProduct?: string;
  date?: Date;
  'data-testid'?: string;
  required?: boolean;
  productGroup?: ProductGroup;
  error?: string;
};

export const PostageTypeDropdown = <T extends FieldValues>({
  label,
  disabled,
  baseProduct,
  date,
  required,
  productGroup,
  ...props
}: PostageTypeDropdownProps<T>): ReactElement => {
  const { field } = useController<ObjectContaining<PostageType | undefined>, string>(props as any);
  const { t: postageTypeTranslate } = useTranslation('catalogValues', { keyPrefix: 'postageType.display' });
  const { t } = useTranslation('orderCreate');
  const isInternationalProduct =
    productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
    productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL ||
    productGroup === ProductGroup.PRESS_INTERNATIONAL;
  const { catalogValues: items } = useDenotingPrepayments(baseProduct, date);
  if (isInternationalProduct) {
    items.forEach((catalogValue, index) => {
      if (catalogValue === PostageType.FS && productGroup !== ProductGroup.PRESS_INTERNATIONAL) {
        items.splice(index, 1, PostageType.FSC, PostageType.FSM);
      } else if (catalogValue === PostageType.FS && productGroup === ProductGroup.PRESS_INTERNATIONAL) {
        items.splice(index, 1, PostageType.FSM);
      }
    });
  }
  const postageTypeToString = useCallback(
    (key) => {
      if (productGroup === ProductGroup.PRESS_INTERNATIONAL && key === PostageType.FSM) {
        return postageTypeTranslate(PostageType.FS);
      } else {
        return key !== '' ? postageTypeTranslate(key) : t('step3Form.notSpecified');
      }
    },
    [postageTypeTranslate, t]
  );

  return (
    <AMDHLSelectSingle
      {...field}
      placeholder={label}
      name={props.name}
      items={required ? items : ['', ...items]}
      error={props.error}
      value={field.value || ''}
      valueToString={postageTypeToString}
      itemDisabled={(value) => value === PostageType.DV}
      disabled={disabled}
      data-testid={props['data-testid']}
    />
  );
};
