import React, { useEffect } from 'react';

export const useScrollContainerMinHeightBinding = (
  headerCellRef: React.RefObject<HTMLTableCellElement> | null,
  contextMenuRef: React.RefObject<HTMLDivElement>,
  menuVisible: boolean
): void =>
  useEffect(() => {
    if (headerCellRef) {
      const scrollFrame = headerCellRef.current?.closest<HTMLDivElement>('[id="scrollFrame"]');
      if (!menuVisible) {
        if (scrollFrame) {
          scrollFrame.style.minHeight = '0';
        }
        return;
      }
      const menu = contextMenuRef.current;
      const th = headerCellRef.current as HTMLTableCellElement;
      if (scrollFrame) {
        scrollFrame.style.minHeight = (menu?.offsetHeight || 0) + (th?.offsetHeight || 0) + 50 + 'px';
      }
    }
  }, [menuVisible, headerCellRef, contextMenuRef]);
