import i18n from 'i18n';
import { OrderTreeRow } from 'order/orderView/components/OrderTree/OrderTreeRow';
import { getOrderTreeItemKeys } from 'order/orderView/components/OrderTree/utils';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './order-tree.module.css';
import de from './translation.de.json';
import { DHLLoading } from '@gkuis/gkp-base-widgets/dist/lib';

export type OrderTreeItem = {
  orderId: string;
  product: string;
  quantity: number;
  totalAmount: number;
  date: Date;
  state: string;
  orderType?: string;
  zkz?: string;
  newspaperNumber?: string;
  description?: string;
  children?: OrderTreeItem[];
};

export type OrderTreeProps = {
  orders?: OrderTreeItem[];
  showZkz?: boolean;
  hasChildren?: boolean;
};

i18n.addResourceBundle('de', 'orderTree', de);
i18n.addResourceBundle('en', 'orderTree', de);

export const OrderTree: VFC<OrderTreeProps> = ({ orders, showZkz, hasChildren }) => {
  const { t } = useTranslation('orderTree');
  return (
    <table className={classes.table}>
      <thead className={classes.head}>
        <tr>
          {getOrderTreeItemKeys(showZkz, hasChildren).map((key) => (
            <th key={key}>{t(key)}</th>
          ))}
        </tr>
      </thead>
      {orders?.length === 0 && (
        <div style={{ position: 'absolute', left: '45%' }}>
          <DHLLoading name={'order-tree-loading'} loading={true} />
        </div>
      )}
      <tbody className={classes.body}>
        {orders?.map((order) => (
          <OrderTreeRow order={order} key={order.orderId} showZkz={showZkz} hasChildren={hasChildren} />
        ))}
      </tbody>
    </table>
  );
};
