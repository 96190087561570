import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { SelectGroup } from 'common/components/SelectGroup';
import { ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import { toggleElementInList } from 'common/utils/helpers';
import { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import React, { ReactElement } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { ProductGroup } from 'order/common/dtos/ProductGroup';

export type FurtherInfoSectionProps<T extends FieldValues> = SFCProps<T, LineItemVariant[]> & {
  disabled?: boolean;
  productGroup?: ProductGroup;
};

export const FurtherInfoSection = <T extends FieldValues>({ disabled, productGroup, ...props }: FurtherInfoSectionProps<T>): ReactElement => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const lineItemVariants: LineItemVariant[] = [LineItemVariant.LR, LineItemVariant.SACHETS, LineItemVariant.GOGREEN];
  const lineItemVariantsForPostwurfspezial: LineItemVariant[] = [LineItemVariant.SACHETS, LineItemVariant.GOGREEN];
  const lineItemVariantsForPostaktuell: LineItemVariant[] = [LineItemVariant.GOGREEN, LineItemVariant.SH];
  const isPostwurfspezial = productGroup === ProductGroup.POSTWURF_SPEZIAL;
  const isPostaktuell = productGroup === ProductGroup.POSTAKTUELL;
  const variantsList = isPostwurfspezial ? lineItemVariantsForPostwurfspezial : isPostaktuell ? lineItemVariantsForPostaktuell : lineItemVariants;

  return (
    <OrderStepSection headline={oc('furtherProductCharacteristics')}>
      <SelectGroup>
        <Controller<ObjectContaining<LineItemVariant[]>, string>
          {...(props as any)}
          render={({ field }) => (
            <>
              {variantsList.map((variant) => (
                <DHLCheckbox
                  key={variant}
                  name={`${props.name}.${variant}`}
                  label={tCatalogValues(`lineItemVariant.display.${variant}`)}
                  value={field.value?.includes(variant)}
                  onChange={() => field.onChange(toggleElementInList(field.value || [], variant))}
                  disabled={disabled}
                />
              ))}
            </>
          )}
        />
      </SelectGroup>
    </OrderStepSection>
  );
};
