import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './encodingTypeDisplay.module.css';

export const EncodingTypeDisplay = ({ encoding }: { encoding: string }) => {
  const { t } = useTranslation('shipmentAssignment');

  return (
    <div data-testid={'encoding-type-display'}>
      <span>{t('encodingType')}:</span> {encoding}
    </div>
  );
};
