import { Order } from '../../../../common/context/order/dtos/Order';

export const shuttleData = (shareType: string | undefined, order: Order): Array<{ key: string; value: string }> => {
  if (['region', 'zoneAndRegion'].includes(shareType || '')) {
    // sum up destinations according to level
    // region -> region level (2 digits)
    // zoneAndRegion -> zone level (1 digit)
    const levelDigits = shareType === 'region' ? 2 : 1;
    const destData = new Map<string, number>();
    order?.destinations?.forEach((dest) => {
      let levelZip = '';
      if (dest.zipCode === 'DE') {
        levelZip = '0-9';
      } else if (dest?.zipCode?.length && dest?.zipCode?.length > 0) {
        levelZip = dest.zipCode.substring(0, levelDigits);
      }
      if (levelZip.length > 0) {
        destData.set(levelZip, (destData.get(levelZip) || 0) + (dest.quantity || 0));
      }
    });

    return Array.from(destData.entries(), ([zip, quantity]) => ({ key: zip, value: String(quantity) }));
  }

  if (shareType === 'variant') {
    return (
      (order?.packagesPerVariant?.map((item) => {
        return { key: item.description, value: String(item.packageQuantity) };
      }) as Array<{ key: string; value: string }>) || [{ key: '', value: '' }]
    );
  }

  return [{ key: '', value: '' }];
};

/**
 * Sort destination array. Sort order: first all zones ascending, then all regions ascending, then DE (0-9)
 *
 * @param destinations - array of key, value
 */
export const sortByZonesAndRegions = (
  shareType: string | undefined,
  destinations: Array<{ key: string; value: string }>
): Array<{ key: string; value: string }> => {
  if (['region', 'zoneAndRegion'].includes(shareType || '')) {
    return destinations.sort((a, b) => {
      if (a.key.length === b.key.length) {
        // ascending
        return a.key.localeCompare(b.key);
      }
      return a.key.length - b.key.length;
    });
  }

  return destinations;
};
