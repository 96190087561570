import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import React, { AllHTMLAttributes, VFC } from 'react';
import iconKundenkonto from 'assets/icon-kundenkonto.svg';
import classes from './customer-info.module.css';

export type CustomerAddressProps = AllHTMLAttributes<HTMLDivElement>;

const CustomerAddress: VFC<CustomerAddressProps> = (props) => (
  <section data-testid={'customer-address-section'} className={classNames(classes.container, props.className)}>
    <div>{<img style={{ padding: '7px' }} className={classes.avatar} src={iconKundenkonto} alt={'user Icon'} />}</div>
    <address {...props} className={classes.text} />
  </section>
);

export default CustomerAddress;
