import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = (...names: string[]) => {
  const { search } = useLocation();
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    return names.map((name) => urlSearchParams.get(name) ?? undefined);
  }, [names, search]);
};
