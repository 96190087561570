// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_progressSpacer__QyPNW {
  max-width: 1400px;
  margin: 40px auto 30px;
  width: 100%;
}

.header_titleHelpLinkContainer__nixZK{
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Header/header.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".progressSpacer {\n  max-width: 1400px;\n  margin: 40px auto 30px;\n  width: 100%;\n}\n\n.titleHelpLinkContainer{\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressSpacer": `header_progressSpacer__QyPNW`,
	"titleHelpLinkContainer": `header_titleHelpLinkContainer__nixZK`
};
export default ___CSS_LOADER_EXPORT___;
