import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import { DialogMarketingOrderCreate } from 'order/productGroups/dialogMarketing/schema/dialogMarketingSchema';
import { PressOrderCreate } from 'order/productGroups/press/schema/pressSchema';
import { useCallback } from 'react';
import { FieldPath, FormState, get, useFormContext, UseFormReturn } from 'react-hook-form';

type UseOrderTransferFormReturn<T extends OrderCreate> = UseFormReturn<T> & {
  isDialogPost: (x: OrderCreate) => x is DialogMarketingOrderCreate;
  isPressDistribution: (x: OrderCreate) => x is PressOrderCreate;
  getError: (formState: FormState<T>, path: FieldPath<T>) => string | undefined;
};

export const useOrderTransferForm = <T extends OrderCreate>(): UseOrderTransferFormReturn<T> => {
  const useFormContextReturn = useFormContext<T>();
  const isDialogPost = useCallback((x: OrderCreate): x is DialogMarketingOrderCreate => x.productGroup === 'DIALOGPOST', []);
  const isPressDistribution = useCallback((x: OrderCreate): x is PressOrderCreate => x.productGroup === 'PRESS_DISTRIBUTION', []);
  const getError = useCallback((formState: FormState<T>, path: FieldPath<T>) => get(formState, `errors.${path}.message`), []);
  return { isDialogPost, isPressDistribution, getError, ...useFormContextReturn };
};
