// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogMarketingStep2_switchLabel__6jAoE{
  color:#D40511;
  font-weight: 600;
  padding-right: 10px;
}

.DialogMarketingStep2_radioFrame__9NkSl {
  border:2px solid lightgray;
}

.DialogMarketingStep2_buttonGroup__VLw9\\+{
    margin-left:-30px;
}

.DialogMarketingStep2_centerRow__UUO\\+L{
    align-items: center;
}

.DialogMarketingStep2_overRideCloseIconColor__RVWsz .svgIcon > svg * {
    fill: #878787 !important;
}

.DialogMarketingStep2_boxSizing__l9uEu{
    box-sizing: border-box !important;
}

#button-otherPartnerdeleteIconButton{
    background: none;
    float:right;
    padding: 0;
}

.DialogMarketingStep2_flexGrow5__SfsYg{
    flex-grow: 5 !important;
}

.DialogMarketingStep2_removeMargin__GovYq{
    margin: 0 !important;
}

#button-content-additionalPartners-addPartnerRole-add{
    padding: 0;
}

#button-content-container-otherPartnerdeleteIconButton{
    float: right;
}

.DialogMarketingStep2_addPaddingBottom__V\\+m34{
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".switchLabel{\n  color:#D40511;\n  font-weight: 600;\n  padding-right: 10px;\n}\n\n.radioFrame {\n  border:2px solid lightgray;\n}\n\n.buttonGroup{\n    margin-left:-30px;\n}\n\n.centerRow{\n    align-items: center;\n}\n\n.overRideCloseIconColor :global(.svgIcon) > svg * {\n    fill: #878787 !important;\n}\n\n.boxSizing{\n    box-sizing: border-box !important;\n}\n\n:global(#button-otherPartnerdeleteIconButton){\n    background: none;\n    float:right;\n    padding: 0;\n}\n\n.flexGrow5{\n    flex-grow: 5 !important;\n}\n\n.removeMargin{\n    margin: 0 !important;\n}\n\n:global(#button-content-additionalPartners-addPartnerRole-add){\n    padding: 0;\n}\n\n:global(#button-content-container-otherPartnerdeleteIconButton){\n    float: right;\n}\n\n.addPaddingBottom{\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchLabel": `DialogMarketingStep2_switchLabel__6jAoE`,
	"radioFrame": `DialogMarketingStep2_radioFrame__9NkSl`,
	"buttonGroup": `DialogMarketingStep2_buttonGroup__VLw9+`,
	"centerRow": `DialogMarketingStep2_centerRow__UUO+L`,
	"overRideCloseIconColor": `DialogMarketingStep2_overRideCloseIconColor__RVWsz`,
	"boxSizing": `DialogMarketingStep2_boxSizing__l9uEu`,
	"flexGrow5": `DialogMarketingStep2_flexGrow5__SfsYg`,
	"removeMargin": `DialogMarketingStep2_removeMargin__GovYq`,
	"addPaddingBottom": `DialogMarketingStep2_addPaddingBottom__V+m34`
};
export default ___CSS_LOADER_EXPORT___;
