import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTextInput } from '../../../../common/components/CustomTextInput';
import i18n from 'i18n';
import { AMDHLSelectSingle } from '../../../../../common/components/AMDHLSelectSingle';
import styles from '../BaseProductsSection/baseProductsSection.module.css';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

export interface AdditionalServiceProps {
  descriptionShort?: string;
  shipmentQuantity?: number;
  name?: string;
}
export const AdditionalService: VFC<AdditionalServiceProps> = ({ descriptionShort, shipmentQuantity, name }) => {
  const { t: trans } = useTranslation('translation');
  return (
    <div className="row" style={{ marginBottom: '15px' }}>
      <div className="col-1" />
      <div className="col-5" style={{ padding: 0 }}>
        <AMDHLSelectSingle
          data-testid={'additionalService'}
          placeholder={trans('additionalService')}
          items={[descriptionShort]}
          value={descriptionShort}
          onChange={() => {}}
          valueToString={(item) => item || ''}
          disabled={true}
        />
      </div>
      <div className="col-5" style={{ paddingRight: 0 }}>
        <CustomTextInput
          type={'text'}
          name={name}
          label={i18n.t('numberOfShipments')}
          testid={descriptionShort}
          defaultValue={shipmentQuantity}
          disabled={true}
        />
      </div>
      <div className="col-1">
        <DHLButton
          name={'delete-additional-service'}
          type="default"
          icon="delete"
          className={styles.addDeleteAssignmentButton}
          onClick={() => {}}
          iconPosition="icon"
          disabled={true}
        />
      </div>
    </div>
  );
};
