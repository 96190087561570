import { useDenotingPrepayments } from 'order/common/hooks/useDenotingPrepayments';
import { DialogMarketingOrderCreate } from 'order/productGroups/dialogMarketing/schema/dialogMarketingSchema';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { PostageType } from '../dtos/PostageType';

export const useDialogPostDependentFields = (): void => {
  const { resetField, control, setValue } = useFormContext<DialogMarketingOrderCreate>();
  const [selectedDate, dialogpostBaseProduct, postageType, additionalCharge, packagingType] = useWatch({
    control,
    name: ['details.date', 'dialogpostBaseProduct', 'postageType', 'additionalCharge', 'packaging.type']
  });

  const { catalogValues } = useDenotingPrepayments(dialogpostBaseProduct, selectedDate);

  const items = ['', ...catalogValues].sort() as (PostageType | '')[];

  useEffect(() => {
    if (postageType && !items.includes(postageType)) {
      resetField('postageType');
      resetField('paymentClearingNumber');
      resetField('postageAmountValue');
      resetField('postageMachineCode');
    }

    if (additionalCharge && !items.includes(additionalCharge)) {
      resetField('additionalCharge');
    }
  }, [additionalCharge, JSON.stringify(items), postageType, resetField]);

  useEffect(() => {
    if (packagingType !== 'PAL') {
      setValue('destinations.matrix', []);
      setValue('destinations.totalWeight', 0);
    }
  }, [packagingType, setValue]);
};
