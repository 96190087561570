import { AddressDisplay } from 'common/components/AddressDisplay';
import { toFormattedDate } from 'common/formatting';
import { concatNonUndefined } from 'common/utils/helpers';
import { AddressTO, PickupAddress, ProductionFacilityTO } from 'generated';
import { useTranslation } from 'i18n';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React from 'react';

export type PickupShippingData = Partial<{
  date: Date;
  pickup: boolean;
  time: string;
  facility: ProductionFacilityTO;
  contactForQuestions: ContactSchema;
  pickupAddress: PickupAddress;
  submissionType: string | undefined | null;
}>;

export type OrderPickupShippingDisplayProps = {
  data: PickupShippingData;
};

export const OrderPickupShippingDisplay: React.VFC<OrderPickupShippingDisplayProps> = ({ data }) => {
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });

  return (
    <OrderOverviewWidget title={t(data.pickup ? 'pickupInformation' : 'consignmentInformation')}>
      <table>
        <tbody>
          <tr>
            <td>
              <p>{t(data.pickup ? 'pickupDate' : 'consignmentDate')}</p>
            </td>
            <td>
              <p>{toFormattedDate(data.date)}</p>
            </td>
          </tr>
          {data.pickup && (
            <tr>
              <td>
                <p>{t(data.pickup ? 'pickupTime' : 'consignmentTime')}</p>
              </td>
              <td>
                <p>{concatNonUndefined(data.time, ' ', t('hr'))}</p>
              </td>
            </tr>
          )}
          {data.pickup ? (
            <tr>
              <td>
                <p>{`${t('pickupAddress')}:`}</p>
              </td>
              <td>
                <AddressDisplay address={{ ...(data.pickupAddress?.orderAddress || {}), names: [data.pickupAddress?.companyName] }} />
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                <p>{t('consignmentCity')}</p>
              </td>
              <td>
                <p>
                  {data.facility?.address?.postCode} {data.facility?.comment}
                </p>
              </td>
            </tr>
          )}
          {(data.submissionType === 'S' || data.submissionType === 'T') && (
            <tr>
              <td>
                <p style={{ width: '80%' }}>{t('lastPostingIndicator')}</p>
              </td>
              <td>
                <p style={{ marginTop: '4mm' }}>{data.submissionType === 'S' ? 'ja' : data.submissionType === 'T' ? 'nein' : ''}</p>
              </td>
            </tr>
          )}{' '}
          {data.pickupAddress?.orderAddress?.further && (
            <tr>
              <td>
                <p>{t('moreInfo')}:</p>
              </td>
              <td>
                <p>{data.pickupAddress?.orderAddress?.further}</p>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <p>{t('contactPersonForQueries')}</p>
            </td>
            <td>
              <p>
                {data.contactForQuestions?.firstName} {data.contactForQuestions?.lastName}
              </p>
              <p>{data.contactForQuestions?.telephone}</p>
              <p>{data.contactForQuestions?.email}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </OrderOverviewWidget>
  );
};
