import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { OrderReplyTDO, OrderTreeItem } from '../../../order/common/services/OrderService';
import { TableLoadingState } from './dtos/LoadingState';
import { ColumnConfig } from './dtos/ColumnConfig';
import { TableHeadRow } from './head/TableHeadRow';
import { TableOptions } from './options/TableOptions';
import { TableBodyTree } from './row/TableBodyTree';
import { SortOrder } from './dtos/SortOrder';
import { ColumnType } from './dtos/ColumnType';
import { Filter } from './dtos/Filter';
import { Sort } from './dtos/Sort';

import styles from './table.module.css';
import { useState } from 'react';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';

interface Props {
  allChildrenExpanded?: boolean;
  columnConfig: ColumnConfig[];
  currentSlice: any[];
  loadingState: TableLoadingState;
  loadingText?: JSX.Element | string | null;
  filter: Filter;
  sort: Sort;
  onFilterChange?: (name: string | null, value: string | null, type?: OrderSearchType) => void;
  onSortChange?: (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => void;
  onMoveChange?: (name: string, direction: 'left' | 'right') => void;
  onSetTableConfiguration?: (config: Record<string, boolean>, columnHistory?: PersistColumn) => void;
  onResetSortAndFilters?: () => void;
  onCheckboxChange?: (property: any, row: OrderTreeItem<OrderReplyTDO>, value: any) => void;
  onSelectChange?: (property: any, row: OrderTreeItem<OrderReplyTDO>, value: any) => void;
  onChildCallback?: (property: any, row: OrderTreeItem<OrderReplyTDO>, value: any) => void;
  t: (value: string) => string;
  withTableOptions?: boolean;
  highlightedRows?: boolean;
  language?: string;
  searchType?: OrderSearchType;
  persistedColumn?: PersistColumn[];
  saveColumnWithMove?: () => void;
  setColumnFilter?: (filter: Filter) => void;
  columnFilter?: Filter;
  rows?: OrderTreeItem<OrderReplyTDO>[];
  columnSorting?: Sort;
  setColumnSorting?: (sort: Sort) => void;
  triggerLastSearch?: () => void;
  isShippingTable?: boolean;
}

export const Table = (props: Props): JSX.Element => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [filterMenu, setFilterMenu] = useState<boolean>(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const handleTranslationCheck = (id: string) => {
    return props.t?.(id) || id;
  };
  const listner = () => {
    setFilterMenu(false);
  };
  useEffect(() => {
    tableRef.current?.addEventListener('scroll', listner);

    return () => {
      tableRef.current?.removeEventListener('scroll', listner);
      setFilterMenu(true);
    };
  }, [tableRef, filterMenu]);

  return (
    <div className={`${styles.container} ${props.isShippingTable ? styles.shippingTable : ''} ${menuVisible ? styles.minHeight : ''}`} ref={tableRef}>
      <table className={`dhlTable ${styles.amTable}`}>
        <thead className={classNames('dhlTable-sort-header ' + styles.headcellalign)}>
          <tr>
            <TableHeadRow
              t={props.t}
              language={props.language}
              withTableOptions={props.withTableOptions}
              filter={props.filter}
              sort={props.sort}
              columns={props.columnConfig || []}
              searchType={props.searchType}
              onMoveChange={props.onMoveChange}
              onSortChange={props.onSortChange}
              onFilterChange={props.onFilterChange}
              onSetTableConfiguration={props.onSetTableConfiguration}
              onResetSortAndFilters={props.onResetSortAndFilters}
              contextMenu={filterMenu}
              setMenuVisible={setMenuVisible}
              saveColumnWithMove={props.saveColumnWithMove}
              setColumnFilter={props.setColumnFilter}
              columnFilter={props.columnFilter}
              rows={props.rows}
              columnSorting={props.columnSorting}
              setColumnSorting={props.setColumnSorting}
              triggerLastSearch={props.triggerLastSearch}
            />
            {!props.withTableOptions ? null : (
              <TableOptions
                columns={props.columnConfig}
                onSetTableConfiguration={props.onSetTableConfiguration}
                t={handleTranslationCheck}
                setMenuVisible={setMenuVisible}
                searchType={props.searchType}
                persistedColumn={props.persistedColumn}
              />
            )}
          </tr>
        </thead>
        <TableBodyTree
          name="tableBody"
          data={props.currentSlice}
          dataEmpty={handleTranslationCheck('body.messageEmpty')}
          idPropertiesName="id"
          cols={props.columnConfig}
          loadingState={props.loadingState}
          loadingText={props.loadingText}
          selected={() => {}}
          highlighted={props.highlightedRows}
          language={props.language}
          allChildrenExpanded={props.allChildrenExpanded}
        />
      </table>
    </div>
  );
};
