export enum LineItemVariant {
  BKD = 'BKD',
  NKD = 'NKD',
  NWE = 'NWE',
  WE = 'WE',
  SACHETS = 'SACHETS',
  LR = 'LR',
  GOGREEN = 'GOGREEN',
  SH = 'SH',
  VP = 'VP',
  // Only used for selection
  NS = 'NS'
}
