import React from 'react';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { styles } from './transportAvisStyles';
import moment from 'moment/moment';
import {
  CustomerInfoWithAddressTO,
  GetCollectionOrderRep,
  GetDialogPostOrderRep,
  GetDialogPostWurfSpecialOrderRep,
  GetInternationalOrderRep,
  GetOrderRep,
  GetPostaktuellOrderRep,
  GetPressDistributionOrderRep,
  JournalRep,
  OrderCustomerRoleEnum,
  OrderDestination,
  ProductionFacilityTO
} from 'generated';
import { generateAndDownloadPdf } from '../utils/pdfUtils';
import { PostLogo } from '../pdf/PostLogo';
import { useTranslation } from 'react-i18next';
import { getPickupDepot } from '../utils/productionFacilityUtils';
import { OrderCategory } from '../../../common/context/order/dtos/OrderCategory';
import {
  compDispDepot,
  compOrderId,
  compOrderLabel,
  compOriginator,
  compOriginatorX,
  compPackageDetails,
  compPickupData,
  compProductGroup,
  compProductionState,
  compSubmitter
} from './transportAvisComponents';
import { toFormattedInt, toFormattedNumber } from 'common/formatting';
import { now } from 'common/utils/timeAndDate';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { useAuthContext } from 'common/context/auth/AuthContext';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

const lineBreak = '\n';

export const downloadTransportAvisPdf = async (
  order: GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetCollectionOrderRep | GetInternationalOrderRep,
  refOrders: (GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetPressDistributionOrderRep | GetInternationalOrderRep)[] | undefined,
  originatorInfo: CustomerInfoWithAddressTO | undefined,
  submitterInfo: CustomerInfoWithAddressTO | undefined,
  validProductionFacilities: ProductionFacilityTO[],
  version: string | undefined,
  journalInfo: JournalRep | undefined,
  language: string,
  finished: () => void
): Promise<void> => {
  try {
    let doc = <></>;
    if (order) {
      switch (order.orderCategory) {
        case OrderCategory.DIP_EINZEL:
        case OrderCategory.DIP_VARIO:
        case OrderCategory.DIP_SAMMEL:
          doc = (
            <TransportAvisDiP
              order={order as GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              validProductionFacilities={validProductionFacilities}
              version={version}
              language={language}
            />
          );
          break;
        case OrderCategory.POSTAKTUELL:
          doc = (
            <TransportAvisDiP
              order={order as GetPostaktuellOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              validProductionFacilities={validProductionFacilities}
              version={version}
              language={language}
            />
          );
          break;

        case OrderCategory.PRESS_DISTRIBUTION:
          doc = (
            <TransportAvisPresse
              order={order as GetPressDistributionOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              validProductionFacilities={validProductionFacilities}
              journalInfo={journalInfo}
              version={version}
              language={language}
            />
          );
          break;

        case OrderCategory.ABHOLAUFTRAG:
          doc = (
            <TransportAvisAbholauftrag
              order={order as GetCollectionOrderRep}
              refOrders={refOrders}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              validProductionFacilities={validProductionFacilities}
              version={version}
              language={language}
            />
          );
          break;

        case OrderCategory.IP_INTERNATIONAL:
        case OrderCategory.PUB_INTERNATIONAL:
        case OrderCategory.BRIEF_INTERNATIONAL:
        case OrderCategory.BRIEF_DMC_INTERNATIONAL:
          doc = (
            <TransportAvisInternational
              order={order as GetInternationalOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              validProductionFacilities={validProductionFacilities}
              version={version}
              language={language}
            />
          );
          break;
      }
    }
    await generateAndDownloadPdf(doc, order as GetOrderRep, 'Transportavis');
  } catch (e) {
    console.error(`${now()} - error printing TransportAvis: ${e}`);
  } finally {
    finished();
  }
};

export type TransportAvisDiPProps = {
  order: GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetPostaktuellOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
  language?: string;
};

export const TransportAvisDiP = ({
  order,
  originatorInfo,
  submitterInfo,
  validProductionFacilities,
  version,
  language
}: TransportAvisDiPProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const submitterContact = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const pickupDepot = getPickupDepot(order as GetOrderRep, validProductionFacilities);

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '6mm' }, styles.lineContainerStreched]}>
            <Text style={styles.pageHeading}>Transportavis</Text>
            {compOrderId(order?.orderId)}
          </View>
          <View>
            {/* Einlieferer */}
            {compSubmitter(submitterInfo, submitterContact)}
            {/* Disponierendes Depot */}
            {compDispDepot(pickupDepot)}
            {/* Auftragsdaten */}
            <View style={styles.groupContainer}>
              {compProductGroup(t, order.productGroup)}
              {compOrderLabel(order.orderLabel)}
              {compProductionState(order.productionState)}
            </View>
            {/* Abholdaten */}
            <View style={styles.groupContainer}>
              {compPickupData(order.orderDetail, order.pickupAddress)}
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Stapelfähigkeit:</Text>
                <Text style={{ width: '100%' }}>
                  {order.packaging.type === 'PAL' ? (order.packaging.stackable ? 'stapelfähig' : 'nicht stapelfähig') : ''}
                </Text>
              </View>
            </View>
            {/* Absender */}
            {compOriginator(t, order.orderLabel, originatorInfo, order?.orderDetail?.submissionType)}
            {/* Gebindedetails */}
            {compPackageDetails(order.destinations, order.packaging, order?.orderCategory, language)}
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};

export type TransportAvisPresseProps = {
  order: GetPressDistributionOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  validProductionFacilities?: ProductionFacilityTO[];
  journalInfo?: JournalRep;
  version?: string;
  language?: string;
};

export const TransportAvisPresse = ({
  order,
  originatorInfo,
  submitterInfo,
  validProductionFacilities,
  journalInfo,
  version,
  language
}: TransportAvisPresseProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const submitterContact = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const pickupDepot = getPickupDepot(order as GetOrderRep, validProductionFacilities);

  let deliveryTime = order?.destinations?.flatMap((dest) => dest.packaging).some((pkg) => pkg?.transport === 'ELN2') ? 'E+2' : undefined;
  deliveryTime = deliveryTime
    ? deliveryTime
    : order?.destinations?.flatMap((dest) => dest.packaging).some((pkg) => pkg?.transport === 'ELN4')
    ? 'E+4'
    : undefined;

  const nationalDestinations: OrderDestination[] = order?.destinations || [];
  const internationalDestinations = order?.internationalDestinations || [];
  const allDestinations = [...nationalDestinations, ...internationalDestinations];

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '6mm' }, styles.lineContainerStreched]}>
            <Text style={styles.pageHeading}>Transportavis</Text>
            {compOrderId(order?.orderId)}
          </View>
          <View>
            {/* Einlieferer */}
            {compSubmitter(submitterInfo, submitterContact)}
            {/* Disponierendes Depot */}
            {compDispDepot(pickupDepot)}
            {/* Auftragsdaten */}
            <View style={styles.groupContainer}>
              {compProductGroup(t, order.productGroup)}
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Versandservice:</Text>
                <Text style={{ width: '100%' }}>{deliveryTime || ''}</Text>
              </View>
              {compProductionState(order.productionState)}
            </View>
            {/* Abholdaten */}
            <View style={styles.groupContainer}>
              {compPickupData(order.orderDetail, order.pickupAddress)}
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Stapelfähigkeit:</Text>
                <Text style={{ width: '100%' }}>
                  {order.packaging.type === 'PAL' ? (order.packaging.stackable ? 'stapelfähig' : 'nicht stapelfähig') : ''}
                </Text>
              </View>
            </View>
            {/* Absender */}
            {compOriginatorX(t, 'Zeitungstitel', journalInfo?.title, originatorInfo, order?.orderDetail?.submissionType)}
            {/* Gebindedetails */}
            {compPackageDetails(allDestinations, order.packaging, order?.orderCategory, language)}
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};

export type TransportAvisAbholauftragProps = {
  order: GetCollectionOrderRep;
  refOrders: (GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetPressDistributionOrderRep)[] | undefined;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
  language?: string;
};

export const TransportAvisAbholauftrag = ({
  order,
  refOrders,
  originatorInfo,
  submitterInfo,
  validProductionFacilities,
  version,
  language
}: TransportAvisAbholauftragProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const submitterContact = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const pickupDepot = getPickupDepot(order as GetOrderRep, validProductionFacilities);
  const isEnglish = language === 'en';

  // calc package totals over all orders
  type PackageTotal = {
    zipCode: string;
    quantity: number;
    grossWeight: number; // Bruttogewicht, GWM
    stackable: boolean;
    netWeight: number;
  };
  const packageTotals = (refOrders?.flatMap((order) => order.destinations)?.filter((dest) => dest) as OrderDestination[] | undefined)
    ?.map((dest) => {
      if (dest.zipCode === 'DE') {
        dest.zipCode = '0-9';
      }
      return dest;
    })
    ?.reduce<PackageTotal[]>((acc, dest) => {
      if (dest.zipCode) {
        const pkgInfo = acc.find((packageInfo) => packageInfo.zipCode === dest.zipCode);
        const grossWeight = dest.packaging?.reduce<number>((acc, packaging) => (acc += packaging.totalWeight || 0), 0) || 0;
        const netWeight = dest?.packaging?.reduce<number>((acc, packaging) => (acc += packaging.totalNetWeightValue || 0), 0) || 0;
        const stackable = dest.packaging?.some((packaging) => packaging.stackable) || false;
        if (pkgInfo) {
          pkgInfo.quantity += dest.quantity || 0;
          pkgInfo.grossWeight += grossWeight;
          pkgInfo.stackable = pkgInfo.stackable && stackable;
          pkgInfo.netWeight += netWeight;
        } else {
          acc.push({
            zipCode: dest.zipCode,
            quantity: dest.quantity || 0,
            grossWeight: grossWeight,
            stackable: stackable,
            netWeight: netWeight
          } as PackageTotal);
        }
      }
      return acc;
    }, [])
    ?.sort((destA, destB) => {
      let sortCritA = 10000;
      if (destA.zipCode === 'DE' || destA.zipCode === '0-9') {
        sortCritA = 99;
      } else if (/^[0-9]{1,2}$/.test(destA.zipCode || '')) {
        sortCritA = +`1${destA.zipCode}`;
      }
      let sortCritB = 10000;
      if (destB.zipCode === 'DE' || destB.zipCode === '0-9') {
        sortCritB = 99;
      } else if (/^[0-9]{1,2}$/.test(destB.zipCode || '')) {
        sortCritB = +`1${destB.zipCode}`;
      }
      return sortCritA - sortCritB;
    });

  type EaOrder = GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetPressDistributionOrderRep;

  const totalNetWeight = toFormattedInt(refOrders?.reduce<number>((acc, order) => (acc += ((order as EaOrder)?.totalNetWeight || 0) / 1000), 0));

  return (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '6mm' }, styles.lineContainerStreched]}>
            <Text style={styles.pageHeading}>Transportavis Zusatzauftrag Abholung</Text>
            {compOrderId(order?.orderId)}
          </View>
          <View>
            {/* Einlieferer */}
            {compSubmitter(submitterInfo, submitterContact)}
            {/* Disponierendes Depot */}
            {compDispDepot(pickupDepot)}
            {/* Abholdaten */}
            <View style={styles.groupContainer}>{compPickupData(order.orderDetail, order.pickupAddress)}</View>
            {/* Absender */}
            {originatorInfo && compOriginator(t, order.orderLabel, originatorInfo, order?.orderDetail?.submissionType)}
            {/* Gebindedetails -> not contained in order, iterate over referenced orders */}
            <View style={styles.groupContainer}>
              <View style={styles.lineContainer}>
                <Text style={styles.textHeader}>Gebindedetails</Text>
              </View>
              <View style={styles.gridContainer}>
                <View style={[styles.gridHeaderContainer]} fixed>
                  <Text
                    style={[
                      { textAlign: 'center' },
                      styles.gridHeaderItem,
                      styles.borderLeft,
                      styles.borderTop,
                      styles.borderBottom,
                      styles.borderRight
                    ]}
                  >
                    Leitzone / Leitregion
                  </Text>
                  <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
                    Anzahl Paletten
                  </Text>
                  <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
                    Gewicht netto in kg
                  </Text>
                  <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
                    Gewicht brutto in kg
                  </Text>
                </View>
                {packageTotals?.map((pkg, idx) => {
                  return (
                    <View style={styles.gridRowContainer} key={`pkgDetails_${idx}`}>
                      <Text style={[styles.gridRowItem, styles.borderLeft, styles.borderBottom, styles.borderRight]}>
                        {(() => {
                          if (pkg?.zipCode === '62') {
                            return `IPZ 1`;
                          } else if (pkg?.zipCode === '36999') {
                            return `IPZ 2`;
                          } else if (pkg?.zipCode === 'DE' || pkg?.zipCode === '0-9') {
                            return `Leitzone 0-9`;
                          } else if (pkg?.zipCode?.length === 1) {
                            return `Leitzone ${pkg.zipCode}`;
                          } else if (pkg?.zipCode?.length === 2) {
                            return `Leitregion ${pkg?.zipCode}`;
                          }
                          return pkg?.zipCode;
                        })()}
                      </Text>
                      <Text style={[styles.gridRowItemCenter, styles.borderBottom, styles.borderRight]}>{pkg.quantity}</Text>
                      <Text style={[styles.gridRowItemCenter, styles.borderBottom, styles.borderRight]}>
                        {pkg?.netWeight ? toFormattedInt(pkg?.netWeight, { english: isEnglish }) : ' '}
                      </Text>
                      <Text style={[styles.gridHeaderItemRight, styles.borderBottom, styles.borderRight]}>
                        {toFormattedInt(pkg.grossWeight, { english: isEnglish })}
                      </Text>
                    </View>
                  );
                })}
              </View>
              {(order.packaging.quantity || packageTotals) && (
                <View style={[styles.lineContainer, { marginTop: '4mm' }]}>
                  <Text style={{ minWidth: '90mm' }}>Summe Anzahl Paletten je Leitzone & Leitregion:</Text>
                  <Text style={{ width: '100%' }}>
                    {toFormattedNumber(
                      packageTotals?.reduce<number>((acc, order) => (acc += order.quantity || 0), 0),
                      { english: isEnglish }
                    )}
                  </Text>
                </View>
              )}
              {totalNetWeight ? (
                <View style={[styles.lineContainer, { marginTop: '1mm' }]}>
                  <Text style={{ minWidth: '90mm' }}>Gesamtgewicht netto in kg:</Text>
                  <Text style={{ width: '100%' }}>{totalNetWeight}</Text>
                </View>
              ) : (
                <></>
              )}
              {(order.packaging.totalWeight || packageTotals) && (
                <View style={[styles.lineContainer, { marginTop: '1mm' }]}>
                  <Text style={{ minWidth: '90mm' }}>Gesamtgewicht brutto in kg:</Text>
                  <Text style={{ width: '100%' }}>
                    {toFormattedInt(
                      packageTotals?.reduce<number>((acc, order) => (acc += order.grossWeight || 0), 0),
                      { english: isEnglish }
                    )}
                  </Text>
                </View>
              )}
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '90mm' }}>Anzahl Gebinde:</Text>
                <Text style={{ width: '100%' }}>
                  {toFormattedInt(
                    refOrders?.reduce<number>((acc, order) => (acc += order.packaging.quantity || 0), 0),
                    { english: isEnglish }
                  ) || ' '}
                </Text>
              </View>
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '90mm' }}>Stapelfähig:</Text>
                <Text style={{ width: '100%' }}>
                  {packageTotals?.reduce<boolean>((acc, pkgTotal) => acc && pkgTotal.stackable, true) ? 'ja' : 'nein'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* Liste der zugeordneten Aufträge on next page(s) */}
        <View style={[{ marginTop: '6mm' }, styles.lineContainer]} break>
          <Text style={styles.pageHeading}>Liste zugeordnete Aufträge</Text>
        </View>
        <View style={styles.groupContainer}>
          <View style={[{ fontSize: '8pt' }, styles.gridContainer]}>
            <View style={[{ fontWeight: 'bold' }, styles.gridHeaderContainer]}>
              <Text style={[{ maxWidth: '25mm' }, styles.gridHeaderItem]}>Auftragsnummer</Text>
              <Text style={[{ maxWidth: '41mm' }, styles.gridHeaderItem]}>Absender</Text>
              <Text style={[{ maxWidth: '41mm' }, styles.gridHeaderItem]}>Auftragsbezeichnung</Text>
              <Text style={[{ maxWidth: '13mm' }, styles.gridHeaderItemRight]}>{`Anzahl${lineBreak}Paletten`}</Text>
              <Text style={[{ maxWidth: '23mm' }, styles.gridHeaderItemRight]}>{`Gesamtgewicht${lineBreak}netto in kg`}</Text>
              <Text style={[{ maxWidth: '26mm' }, styles.gridHeaderItem]}>Einlieferungsart</Text>
            </View>
            {refOrders
              ?.sort((ref1, ref2) => ref1.orderId?.localeCompare(ref2.orderId || '') || -1)
              .map((ea, idx) => {
                const originator = ea?.parties?.find((customer) => customer.role === OrderCustomerRoleEnum.Originator);
                return (
                  <View style={styles.gridRowContainer} key={`shipment_${idx}`}>
                    <Text style={[{ maxWidth: '25mm' }, styles.gridRowItem]}>{ea?.orderId || ''}</Text>
                    <Text style={[{ maxWidth: '41mm' }, styles.gridRowItem]}>{originator?.customerId + ' ' + originator?.name}</Text>
                    <Text style={[{ maxWidth: '41mm' }, styles.gridRowItem]}>{ea?.orderLabel || ''}</Text>
                    <Text style={[{ maxWidth: '13mm' }, styles.gridRowItemRight]}>
                      {toFormattedInt(ea?.packaging?.quantity, { english: isEnglish }) || ' '}
                    </Text>
                    <Text style={[{ maxWidth: '23mm' }, styles.gridRowItemRight]}>
                      {toFormattedInt(((ea as EaOrder)?.totalNetWeight || 0) / 1000, { english: isEnglish }) || ' '}
                    </Text>
                    <Text style={[{ maxWidth: '26mm' }, styles.gridRowItem]}>Gesamteinlieferung</Text>
                  </View>
                );
              })}
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export type TransportAvisInternationalProps = {
  order: GetInternationalOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
  language?: string;
};

export const TransportAvisInternational = ({
  order,
  originatorInfo,
  submitterInfo,
  validProductionFacilities,
  version,
  language
}: TransportAvisInternationalProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const submitterContact = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const pickupDepot = getPickupDepot(order as GetOrderRep, validProductionFacilities);

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '3mm' }, styles.lineContainerStreched, { height: '15mm' }]}>
            <View>
              <Text style={styles.pageHeading}>Transportavis</Text>
              <Text style={styles.pageHeading}>{t(`productGroups.types.${order.productGroup}`)}</Text>
            </View>
            {compOrderId(order?.orderId)}
          </View>
          <View style={styles.groupContainer}>
            {/* Einlieferer */}
            {compSubmitter(submitterInfo, submitterContact)}
            {/* Disponierendes Depot */}
            {compDispDepot(pickupDepot)}
            {/* Auftragsdaten */}
            <View style={styles.groupContainer}>
              {compProductGroup(t, order.productGroup)}
              {compOrderLabel(order.orderLabel)}
              {compProductionState(order.productionState)}
            </View>
            {/* Abholdaten */}
            <View style={styles.groupContainer}>
              {compPickupData(order.orderDetail, order.pickupAddress)}
              <View style={styles.lineContainer}>
                <Text style={{ minWidth: '60mm' }}>Stapelfähigkeit:</Text>
                <Text style={{ width: '100%' }}>{order.packaging.type === 'PAL' ? (order.packaging.stackable ? 'Ja' : 'Nein') : ''}</Text>
              </View>
            </View>
            {/* Absender */}
            {compOriginator(t, order.orderLabel, originatorInfo, order?.orderDetail?.submissionType)}
            {/* Gebindedetails */}
            {compPackageDetails(order.destinations, order.packaging, order?.orderCategory, language)}
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
};
