// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changelog_container__Xw5qq {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/changelog/changelog.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".container {\n  max-width: 1140px;\n  padding: 0 15px;\n  margin: 0 auto 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `changelog_container__Xw5qq`
};
export default ___CSS_LOADER_EXPORT___;
