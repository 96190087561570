import React, { FC } from 'react';
import defaultStyles from './space-error-alters.module.css';
import { Alert } from './Alert';
import { AlertTypes } from './dtos/AlertTypes';

export type SpaceErrorAlertsProps = {
  alerts: any[];
  styles?: any;
};
export const SpaceErrorAlerts: FC<SpaceErrorAlertsProps> = (props: SpaceErrorAlertsProps) => {
  const { alerts } = props;
  const styles = props.styles ? props.styles : defaultStyles;
  return (
    <>
      {alerts.map((a) => (
        <div className={styles.alertWrapper} key={a.id}>
          <Alert
            id={a.id}
            type={a.type}
            title={a.title}
            description={a.description}
            closeable={a.type.toString().toLowerCase() !== AlertTypes.Error.toString().toLowerCase()}
          />
        </div>
      ))}
    </>
  );
};
