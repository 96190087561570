import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DateRange, OrderSearchAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { DefaultDayRangePreset, getPartialSearchDayRanges } from 'common/components/AMDHLDateRangeInput/DayRangePreset';
import AMDHLDateRangeInput from 'common/components/AMDHLDateRangeInput';
import { getFormattedDate } from 'common/utils/dates';
import { AMDHLDateRangeInputModel, AMDHLPartialSearchDateRangeInputModel } from 'common/components/AMDHLDateRangeInput/DateRangeInputModel';
import { useSearchContext } from '../../../common/context/search/SearchContext';
import { OrderSearchType } from '../../../common/context/search/dtos/OrderSearchType';
import styles from './AMDHLOrderSearchFilterDatePicker.module.css';
import { partialSearchDateRangeInputModel } from 'common/components/AMDHLDateRangeInput/DateRangeInputModel';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductionState } from '../ProductionState';

type SelectedDateRange = DateRange | DefaultDayRangePreset;

/**
 * The filter attribute date range which contains an associated {@link AMDHLDateRangeInput}.
 *
 * @constructor
 */
export function AMDHLOrderSearchFilterDatePicker(): JSX.Element {
  const { attributes, addAttribute, replaceAttribute, tab, searchType } = useSearchContext();
  const { meta, order } = useOrderContext();
  const inputModel = new AMDHLDateRangeInputModel();
  const selectedPreset = useMemo<SelectedDateRange>(() => {
    for (const attribute of attributes) {
      if (attribute.key === OrderSearchKey.Date) {
        return attribute.value;
      }
    }
    return DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS;
  }, [attributes]);

  // Date handling for order search with status for ZA TL
  const [searchWithStatusBilled, setSearchWithStatusBilled] = useState<OrderSearchAttribute | undefined>();
  const [changedDateForBilledStatus, setChangedDateForBilledStatus] = useState<boolean>(false);
  const [statusSelected, setStatusSelected] = useState(false);
  useEffect(() => {
    const bil = attributes.find((a) => a.key === OrderSearchKey.ProductionState && a.value === ProductionState.billed);
    const statusSelected = !!attributes.find((a) => a.key === OrderSearchKey.ProductionState);
    setStatusSelected(statusSelected);
    setSearchWithStatusBilled(bil);
  }, [attributes]);

  useEffect(() => {
    // if (searchType === OrderSearchType.PARTIAL) {
    //   setInputModel(partialSearchDateRangeInputModel(false));
    // }
    if (searchType === OrderSearchType.COLLECTION_LETTER) {
      const models = partialSearchDateRangeInputModel(true);
      models.preset;
      models.getDataStore().setSelectedStartDate(getFormattedDate(meta?.pickUpDate));
      models.getDataStore().setSelectedEndDate(getFormattedDate(meta?.pickUpDate));
      models.getDataStore().commitSelectedDateAsActive();
      setInputModel(models);
    }
  }, [searchType]);

  const [model, setInputModel] = useState<AMDHLDateRangeInputModel | AMDHLPartialSearchDateRangeInputModel>(() => {
    if (searchType === OrderSearchType.PARTIAL) {
      return partialSearchDateRangeInputModel(false);
    }
    if (searchType === OrderSearchType.COLLECTION_LETTER) {
      const models = partialSearchDateRangeInputModel(true);
      models.getDataStore().setSelectedStartDate(getFormattedDate(meta?.pickUpDate));
      models.getDataStore().setSelectedEndDate(getFormattedDate(meta?.pickUpDate));
      return models;
    } else {
      if (typeof selectedPreset === 'number') {
        inputModel.preset = selectedPreset;
      } else {
        inputModel.getDataStore().setSelectedStartDate(getFormattedDate(selectedPreset.start));
        inputModel.getDataStore().setSelectedEndDate(getFormattedDate(selectedPreset.end));
      }
      return inputModel;
    }
  });

  useEffect(() => {
    const currentDateChipValue = attributes.find((a) => a.key === OrderSearchKey.Date)?.value;
    if (searchType === OrderSearchType.MERGE) {
      const models = partialSearchDateRangeInputModel(true);
      const startDate = new Date((currentDateChipValue as DateRange).start);
      const endDate = new Date((currentDateChipValue as DateRange).end);
      models.getDataStore().setSelectedStartDate(getFormattedDate(startDate));
      models.getDataStore().setSelectedEndDate(getFormattedDate(endDate));
      setInputModel(models);
    }
  }, [searchType, attributes]);

  // Date handling for order search with status for ZA TL
  useEffect(() => {
    if (searchType === OrderSearchType.PARTIAL && searchWithStatusBilled?.value === ProductionState.billed && !changedDateForBilledStatus) {
      const models = partialSearchDateRangeInputModel(false, true);
      replaceAttribute({
        key: OrderSearchKey.Date,
        value: DefaultDayRangePreset.LAST_3_DAYS
      });
      setInputModel(models);
    } else if (searchType === OrderSearchType.PARTIAL && searchWithStatusBilled?.value !== ProductionState.billed && statusSelected) {
      setChangedDateForBilledStatus(false);
    } else if (searchType === OrderSearchType.PARTIAL && !statusSelected) {
      const models = partialSearchDateRangeInputModel(false, false);
      setChangedDateForBilledStatus(false);
      // model.preset = DefaultDayRangePreset.TODAY;
      replaceAttribute({
        key: OrderSearchKey.Date,
        value: DefaultDayRangePreset.TODAY
      });
      setInputModel(models);
    }
  }, [searchType, searchWithStatusBilled, changedDateForBilledStatus]);

  const handleChange = useCallback(() => {
    let value: DateRange | DefaultDayRangePreset = model.preset;
    if (model.preset === DefaultDayRangePreset.USER_DEFINED) {
      value = {
        start: model.valueFrom,
        end: model.valueTo
      };
      if (searchType === OrderSearchType.PARTIAL && searchWithStatusBilled?.value === ProductionState.billed) {
        setChangedDateForBilledStatus(true);
      } else if (!statusSelected || searchWithStatusBilled?.value !== ProductionState.billed) {
        setChangedDateForBilledStatus(false);
      }
    }
    replaceAttribute({
      key: OrderSearchKey.Date,
      value
    });
  }, [model.preset, model.valueFrom, model.valueTo, attributes]);

  useEffect(() => {
    if (!attributes.length && (tab === OrderSearchType.PRESS || tab === OrderSearchType.ADVANCED)) {
      addAttribute({
        key: OrderSearchKey.Date,
        value: DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS
      });
    }
    if (!attributes.length && searchType === OrderSearchType.PARTIAL) {
      addAttribute({
        key: OrderSearchKey.Date,
        value: DefaultDayRangePreset.TODAY
      });
    }
    if (!attributes.length && searchType === OrderSearchType.COLLECTION_LETTER) {
      addAttribute({
        key: OrderSearchKey.Date,
        value: DefaultDayRangePreset.USER_DEFINED
      });
    }
  }, [attributes, searchType, tab]);

  return <AMDHLDateRangeInput className={styles.input} name={'dateRangePicker'} model={model} onSubmit={handleChange} required={true} />;
}
