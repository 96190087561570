import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DHLButton, DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import { useTranslation } from 'react-i18next';
import { AppPaths } from '../../../../Main';
import { useHistory } from 'react-router-dom';
import { useOrderContext } from '../../context/order/OrderContext';
import { useSearchContext } from '../../context/search/SearchContext';

interface BackToSearchProps {
  show: boolean;
  setShow?: (show: boolean) => void;
  onCancel?: () => void;
}

export const BackToSearch = (props: BackToSearchProps): JSX.Element => {
  const { t } = useTranslation('orderCreate');
  const [showModal, setShowModal] = useState(false);
  const scrollPos = useRef(0);
  const history = useHistory();
  const { setDirty } = useOrderContext();
  const { triggerLastSearch } = useSearchContext();

  useEffect(() => {
    if (props.show) scrollPos.current = window.scrollY;
    setShowModal(props.show);
  }, [props.show]);

  useEffect(() => {
    window.scrollTo(0, Number(scrollPos.current));
  }, [showModal]);
  const backWrapperRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.querySelector('button')?.focus();
      }, 0);
    }
  }, []);

  return (
    <DHLModal
      name="confirm"
      title={t`backToSearchModal.title`}
      content={t`backToSearchModal.content`}
      actionbar={
        <>
          <div ref={backWrapperRef}>
            <DHLButton
              name={'cancel-button'}
              label={t(`buttons.cancel`)}
              onClick={() => {
                props.onCancel && props.onCancel();
                props.setShow && props.setShow(false);
              }}
              type={'default'}
            />
          </div>
          <DHLButton
            name={'back-to-search-button'}
            label={t(`buttons.toOrderSearch`)}
            onClick={() => {
              setDirty(false);
              triggerLastSearch();
              history.push(AppPaths.orderSearchPath);
            }}
            type={'primary'}
            data-testId={'backToSearch'}
          />
        </>
      }
      show={showModal}
      onClickClose={() => {
        props.onCancel && props.onCancel();
        props.setShow && props.setShow(false);
      }}
    />
  );
};
