// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-partners_row__XqBhd {
  display: flex;
}

.other-partners_row__XqBhd > * {
  flex-grow: 1;
}

.other-partners_row__XqBhd > aside {
  width: 32px;
  flex-grow: 0;
}

.other-partners_info__mi-K3 {
  margin-top: 15px;
}

.other-partners_button__f6YMt {
  margin-top: .25rem;
}

.other-partners_addButton__4Yrh0 {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/AMDHLOtherPartners/other-partners.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".row {\n  display: flex;\n}\n\n.row > * {\n  flex-grow: 1;\n}\n\n.row > aside {\n  width: 32px;\n  flex-grow: 0;\n}\n\n.info {\n  margin-top: 15px;\n}\n\n.button {\n  margin-top: .25rem;\n}\n\n.addButton {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `other-partners_row__XqBhd`,
	"info": `other-partners_info__mi-K3`,
	"button": `other-partners_button__f6YMt`,
	"addButton": `other-partners_addButton__4Yrh0`
};
export default ___CSS_LOADER_EXPORT___;
