// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted-text_mark__Whh0- {
  background: transparent;
  font-weight: 600;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/HighlightedText/highlighted-text.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".mark {\n  background: transparent;\n  font-weight: 600;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mark": `highlighted-text_mark__Whh0-`
};
export default ___CSS_LOADER_EXPORT___;
