import i18n from 'i18n';
import { PayerInfo } from 'order/orderView/components/PayerInfo';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import transDE from './translation.de.json';
import { OrderCustomerRoleEnum } from 'generated';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { CustomerDisplay } from 'order/productGroups/dialogMarketing/components/Overview/CustomerDisplay';

i18n.addResourceBundle('de', 'customerRole', transDE);

export type CustomerDetail = {
  role?: OrderCustomerRoleEnum;
  customerId?: string;
};

export type CustomerDetailSectionProps = {
  parties: CustomerDetail[];
  withAddress?: boolean;
  orderCategoryProductKey?: OrderCategoryProductKey | string;
  step?: 'Overview';
  validityDate?: Date | string;
};

export const CustomerDetailSection: FC<CustomerDetailSectionProps> = ({ parties, withAddress, orderCategoryProductKey, step, validityDate }) => {
  const { t: tRole } = useTranslation('customerRole');
  return (
    <>
      <OrderOverviewRow>
        {(
          parties.filter(
            (x) => typeof x.role !== 'undefined' && typeof x.customerId !== 'undefined' && x.customerId && x.role
          ) as Required<CustomerDetail>[]
        ).map((party) =>
          party.role === OrderCustomerRoleEnum.Payer ||
          (party.role === OrderCustomerRoleEnum.Contractor && orderCategoryProductKey === OrderCategoryProductKey.TL_BRIEF) ? (
            <PayerInfo
              payerId={party.customerId}
              validityDate={validityDate}
              role={party.role as OrderCustomerRoleEnum}
              key={party.role}
              withAddress={withAddress}
              step={step}
            />
          ) : (
            <OrderOverviewWidget key={`${party.role}${party.customerId}`} title={tRole(party.role)}>
              <p>Kundennummer: {party.customerId}</p>
              <CustomerDisplay customerId={party.customerId} role={party.role} />
            </OrderOverviewWidget>
          )
        )}
      </OrderOverviewRow>
    </>
  );
};
