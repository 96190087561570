export const translationGE = {
  takeFromOriginator: 'Vom Einlieferer übernehmen',
  street: 'Straße',
  streetNumber: 'Hausnummer',
  postCode: 'PLZ',
  city: 'Ort',
  country: 'Land',
  errors: {
    required: 'Dies ist ein Pflichtfeld.',
    maxLength: 'Der Wert darf maximal 120 Zeichen lang sein.',
    maxLength35: 'Der Text darf maximal 35 Zeichen lang sein.'
  }
};

export const translationEN = {
  takeFromOriginator: 'Same as consignors address',
  street: 'Street',
  streetNumber: 'House Number',
  postCode: 'PostCode',
  city: 'City',
  country: 'Country',
  errors: {
    required: 'This is a mandatory field.',
    maxLength: 'The value can be a maximum of 120 characters long.',
    maxLength35: 'The value can be a maximum of 35 characters long.'
  }
};
