// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarginalColumn_mainBox__Grql\\+ {
  padding-top: 10px;
  color: darkgray;
  font-size: 0.8rem;
}

.MarginalColumn_aside__mNj7R {
  display: flex;
  flex-direction: column;
}

.MarginalColumn_aside__mNj7R:not(.expanded) .hideOnAside {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/MarginalColumn/MarginalColumn.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".mainBox {\n  padding-top: 10px;\n  color: darkgray;\n  font-size: 0.8rem;\n}\n\n.aside {\n  display: flex;\n  flex-direction: column;\n}\n\n.aside:not(:global(.expanded)) :global(.hideOnAside) {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBox": `MarginalColumn_mainBox__Grql+`,
	"aside": `MarginalColumn_aside__mNj7R`
};
export default ___CSS_LOADER_EXPORT___;
