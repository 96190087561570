import i18n from 'i18n';
import React from 'react';

import { CustomerNameTO } from 'generated';
import { ColumnConfig, ContentAlignment } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { customCheckboxRenderer } from './custom-cells/CustomCheckboxCell';
import {
  customRendererForFrankierung,
  customRendererForOrderValue,
  customRendererForPackagingQty,
  customRendererForProcedure,
  customRendererForProduct,
  customRendererForTotalQty,
  customRendererWithWordBreak,
  statusRenderer
} from 'order/orderSearch/components/table/functions/customRendererFunctions';
import { customHeaderCheckboxRenderer } from './custom-cells/CustomCheckboxHeader';

import styles from './table-container.module.css';

const customerRenderer = (value: CustomerNameTO): string => (value?.name ? `${value.customerId} ${value.name}` : value?.customerId || '');

const translate = (key: string) => i18n.t('orderTable.column.config.' + key);

export const defaultColumnConfig: ColumnConfig[] = [
  {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('orderNumberBreaking'),
    type: ColumnType.READONLY,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  {
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('customerOrderNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  {
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM
  },
  {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('actionNameBreaking'),
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    customRenderer: customRendererWithWordBreak
  }
];

export const searchTableConfigurationForPSL: ColumnConfig[] = [
  {
    name: 'checkbox',
    property: 'checkbox',
    customRenderer: customCheckboxRenderer,
    customHeader: customHeaderCheckboxRenderer,
    title: translate('checkbox'),
    type: ColumnType.CHECKBOX
  },
  ...[...defaultColumnConfig],
  {
    name: 'frankierung',
    property: 'data.frankierung',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('postageType'),
    customRenderer: customRendererForFrankierung,
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'procedure',
    property: 'data.procedure',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('procedure'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForProcedure
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'participation',
    property: 'data.participation',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('participation'),
    type: ColumnType.COMMON
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'paymentClearingNumber',
    property: 'data.paymentClearingNumber',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('deliveryReceiptNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    movable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForTotalQty,
    type: ColumnType.CUSTOM_CELL
  },
  {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE
  },
  {
    contentAlignment: ContentAlignment.LEFT,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    movable: false,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM
  }
];

export const searchTableConfigurationForCollection: ColumnConfig[] = [
  {
    name: 'checkbox',
    property: 'checkbox',
    customRenderer: customCheckboxRenderer,
    customHeader: customHeaderCheckboxRenderer,
    title: translate('checkbox'),
    type: ColumnType.CHECKBOX
  },
  ...[...defaultColumnConfig],
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'product',
    property: 'data.product',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('product'),
    customRenderer: customRendererForProduct,
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'packagingQty',
    property: 'data.packagingQty',
    customRenderer: customRendererForPackagingQty,
    sortable: true,
    filterable: false,
    title: translate('palletsQty'),
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    movable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForTotalQty,
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE
  },
  {
    contentAlignment: ContentAlignment.LEFT,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM
  }
];

export const searchTableConfigurationForOrderMerge: ColumnConfig[] = [
  {
    name: 'checkbox',
    property: 'checkbox',
    customRenderer: customCheckboxRenderer,
    customHeader: customHeaderCheckboxRenderer,
    title: translate('checkbox'),
    type: ColumnType.CHECKBOX
  },
  {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('orderNumberBreaking'),
    type: ColumnType.READONLY,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  {
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('customerOrderNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('actionNameBreaking'),
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    customRenderer: customRendererWithWordBreak
  },

  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'packagingQty',
    property: 'data.packagingQty',
    customRenderer: customRendererForPackagingQty,
    sortable: true,
    filterable: false,
    title: translate('palletsQty'),
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    movable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForTotalQty,
    type: ColumnType.CUSTOM_CELL
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    movable: false,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE
  },
  {
    name: 'orderValue',
    property: 'data.totalAmountValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL
  }
];
