import React, { useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { OrderMode } from 'order/common/dtos/OrderMode';
import i18n, { useTranslation } from 'i18n';
import { changeLetterOrder, createLetterOrder } from 'order/common/services/OrderService';
import { AppPaths } from 'Main';
import { useAlerts } from 'common/AlertContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import orderClasses from 'common/styles/order.module.css';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { FrankItOverview } from '../../components/Overview/LetterOverview';
import { LetterOrderCreate, translateLetterOrder } from '../../schemea/LetterSchema';
import { OrderCategory } from '../../../../common/context/order/dtos/OrderCategory';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';
import { fetchErrorCode } from '../../../../common/utils/errorCodeHelper';

export const LetterStep4: VFC = observer(() => {
  const { t } = useTranslation('orderCreate');
  const { watch, handleSubmit } = useFormContext<LetterOrderCreate>();
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { hasPermission } = useAuthContext();
  const values = watch();
  const { reset: resetSearchContext, triggerAlert, updateMyOrders, myOrders } = useSearchContext();
  const history = useHistory();
  const { orderMode, orderId, reset: resetOrderContext, order, orderCategoryProductKey } = useOrderContext();
  const { isDirty, setDirty } = useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);

  return (
    <>
      <main className={orderClasses.formContent}>
        <FrankItOverview value={values} orderPhase={OrderMode.CREATE} />
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
            dataTestId="btnBack"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant={activeSpinner ? 'default' : 'primary'}
          disabled={!hasPermission(UserRights.OrderEdit) || activeSpinner}
          dataTestId="btnSubmit"
          onClick={async () => {
            await handleSubmit(async (values) => {
              try {
                deleteAlerts();
                const frk = translateLetterOrder(values, orderId as string | undefined, order, orderMode);
                let dpResponse: any;
                setActiveSpinner(true);
                if (orderMode === OrderMode.CHANGE) {
                  if (order?.orderCategory === OrderCategory.DV_BRIEF) {
                    const { data } = await changeLetterOrder(frk, 'dv');
                    dpResponse = data;
                  } else {
                    const { data } = await changeLetterOrder(frk, 'frankit');
                    dpResponse = data;
                  }
                } else {
                  const { data } = await createLetterOrder(frk);
                  dpResponse = data;
                }
                if (dpResponse?.resultStatus !== 'ERROR') {
                  setActiveSpinner(false);
                  resetSearchContext();

                  // For persisting my orders
                  const myOrderData: MyOrder = {
                    id: dpResponse?.orderId,
                    myOrderNumbers: [dpResponse?.orderId],
                    orderCategory: order?.orderCategory,
                    orderCategoryProductKey: order?.orderCategoryProductKey || orderCategoryProductKey,
                    orderLabel: values?.orderLabel,
                    creationDate: new Date()?.getTime(),
                    sortingDate: new Date()?.getTime()
                  };

                  const found = myOrders
                    ?.map((myOrder) => myOrder?.myOrderNumbers)
                    ?.filter((myOrderArr) => myOrderArr?.length === 1)
                    ?.find((myOrderNums) => (dpResponse?.orderId ? myOrderNums?.includes(dpResponse.orderId) : undefined));
                  if (!found?.length) {
                    updateMyOrders(myOrderData, 'ADD');
                  } else {
                    updateMyOrders(myOrderData, 'UPDATE');
                  }

                  resetOrderContext();
                  setDirty(false);
                  triggerAlert(dpResponse);
                  history.push(AppPaths.orderSearchPath);
                } else {
                  setActiveSpinner(false);
                  dpResponse?.amErrors?.forEach((error: { code: string; errorCategory: string; errorDescription: string }) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory} ${error.code}!`,
                      description: error.errorDescription
                    });
                  });
                }
              } catch (e: any) {
                console.error(e);
                setActiveSpinner(false);
                addAlert({
                  type: AlertTypes.Error,
                  title: orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.title') : i18n.t('responseErrors.create.title'),
                  description:
                    (orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.description') : i18n.t('responseErrors.create.description')) +
                    fetchErrorCode(e?.response)
                });
              } finally {
                nextStep();
              }
            }, console.error)();
          }}
          type="button"
          label={orderMode === OrderMode.CHANGE ? t('buttons.createOrderConfirm') : t('buttons.submitOrder')}
          activeSpinner={activeSpinner}
          style={{ minWidth: '275px' }}
        />
      </footer>
    </>
  );
});
