import React, { AllHTMLAttributes, VFC } from 'react';
import classes from '../CustomerAddress/customer-info.module.css';
import payerInfoIcon from 'assets/icon-payer-info.svg';

export type PaymentAddressProps = AllHTMLAttributes<HTMLDivElement>;

const PaymentAddress: VFC<PaymentAddressProps> = (props) => (
  <section className={classes.container}>
    <div>
      <img
        data-testid="payerIcon"
        className={classes.avatar}
        style={{ padding: '6px' }}
        id={'paymentAddressICon'}
        src={payerInfoIcon}
        alt={'payer info icon'}
      />
    </div>
    <address {...props} className={classes.text} />
  </section>
);

export default PaymentAddress;
