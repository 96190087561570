// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderCreateStep1_ocstep1Header__26uq8 {
  margin-bottom: 35px;
}
.OrderCreateStep1_HinwesTextContainer__UuF1\\+ {
  margin-bottom: 35px;
}
.OrderCreateStep1_HinwesTextContainer__UuF1\\+ p {
  color: #878787;
  line-height: 20px;
}
.OrderCreateStep1_HinwesTextContainer__UuF1\\+ h4 {
  color: #323232;
  font-size: 25px;
  font-weight: 600;
}
.OrderCreateStep1_HinwesTextContainer__UuF1\\+ a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/OrderCreateStep1/OrderCreateStep1.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".ocstep1Header {\n  margin-bottom: 35px;\n}\n.HinwesTextContainer {\n  margin-bottom: 35px;\n}\n.HinwesTextContainer p {\n  color: #878787;\n  line-height: 20px;\n}\n.HinwesTextContainer h4 {\n  color: #323232;\n  font-size: 25px;\n  font-weight: 600;\n}\n.HinwesTextContainer a {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ocstep1Header": `OrderCreateStep1_ocstep1Header__26uq8`,
	"HinwesTextContainer": `OrderCreateStep1_HinwesTextContainer__UuF1+`
};
export default ___CSS_LOADER_EXPORT___;
