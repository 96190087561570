import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import IconSearchButton from 'common/components/IconSearchButton';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';

import styles from './ProcedureComboBox.module.css';
import { orderStatusOptions, orderStatusOptionsForPartial } from '../ProductionState';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';

type OrderStatusSelectProps = {
  onSelect: (value: string) => void;
  defaultValue: string;
  searchType?: OrderSearchType;
};

const labelForOrderStatusOption = (s: string): string => orderStatusOptions.find((os) => os.id === s)?.name ?? '';

export const OrderStatusSelect: FC<OrderStatusSelectProps> = ({ onSelect, defaultValue, searchType }) => {
  const { t } = useTranslation('', { keyPrefix: 'orderSearch' });
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const selectionHandler = (newValue?: string): void => {
    if (newValue) {
      onSelect(newValue);
      setValue(newValue);
    }
  };

  return (
    <>
      <div className={styles.comboBox}>
        <AMDHLSelectSingle
          name={`order-type-select`}
          items={searchType === OrderSearchType.PARTIAL ? orderStatusOptionsForPartial.map((os) => os.id) : orderStatusOptions.map((os) => os.id)}
          label={t('label.' + OrderSearchKey.ProductionState)}
          valueToString={labelForOrderStatusOption}
          value={value}
          onChange={selectionHandler}
          data-testid={`order-status-select`}
        />
      </div>
      <IconSearchButton name="orderSearchFilterAttrSelectableAdd" onClick={() => selectionHandler(value)} icon={'plus'} />
    </>
  );
};

export default OrderStatusSelect;
