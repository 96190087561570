import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import IconSearchButton from 'common/components/IconSearchButton';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { orderTypeOptions } from '../OrderTypes';

import styles from './ProcedureComboBox.module.css';

type OrderTypeSelectProps = {
  onSelect: (value: string) => void;
  defaultValue: string;
};

const labelForOrderTypeOption = (v: string): string => orderTypeOptions.find((orderType) => orderType.id === v)?.name ?? '';

export const OrderTypeSelect: FC<OrderTypeSelectProps> = ({ onSelect, defaultValue }) => {
  const { t } = useTranslation('', { keyPrefix: 'orderSearch' });
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const selectionHandler = (newValue?: string): void => {
    if (newValue) {
      onSelect(newValue);
      setValue(newValue);
    }
  };

  return (
    <>
      <div className={styles.comboBox}>
        <AMDHLSelectSingle
          name={`order-type-select`}
          items={orderTypeOptions.map((orderType) => orderType.id)}
          label={t('label.' + OrderSearchKey.OrderType)}
          valueToString={labelForOrderTypeOption}
          value={value}
          onChange={selectionHandler}
          data-testid={`order-type-select`}
        />
      </div>
      <IconSearchButton name="orderSearchFilterAttrSelectableAdd" onClick={() => selectionHandler(value)} icon={'plus'} />
    </>
  );
};

export default OrderTypeSelect;
