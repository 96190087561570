// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AMDHLOrderSearchFilterDatePicker_input__FTkGK + .dhl-date-range-input-picker-anchorpoint > .dhlDateRangePicker {
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/filter/AMDHLOrderSearchFilterDatePicker.module.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV","sourcesContent":[".input + :global(.dhl-date-range-input-picker-anchorpoint) > :global(.dhlDateRangePicker) {\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `AMDHLOrderSearchFilterDatePicker_input__FTkGK`
};
export default ___CSS_LOADER_EXPORT___;
