import { StyleSheet } from '@react-pdf/renderer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBdIt from '../fonts/Delivery_BdIt.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryIt from '../fonts/Delivery_It.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../fonts/Delivery_Rg.ttf';

export const styles = StyleSheet.create({
  body: {
    paddingTop: 45.3543,
    paddingBottom: 40,
    paddingRight: 28.3464567,
    paddingLeft: 51.0236
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    fontFamily: 'delivery'
  },
  header: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    color: 'gray'
  },
  headerLogo: {
    height: 30,
    width: 180,
    opacity: 0.8
  },
  searchCriteriaSection: {
    justifyContent: 'flex-start',
    marginBottom: 20,
    textAlign: 'left',
    fontFamily: 'delivery',
    width: '50%'
  },
  searchResultSection: {
    marginBottom: 20
  },
  sectionTitle: {
    fontFamily: 'delivery',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10
  },
  searchCriteria: {
    width: '45%',
    fontFamily: 'delivery',
    fontSize: 10,
    color: 'black'
  },
  searchValue: {
    width: '100%',
    fontFamily: 'delivery',
    fontSize: 10,
    color: 'black',
    textAlign: 'left'
  },
  sectionData: {
    fontFamily: 'delivery',
    fontSize: 10,
    color: 'black'
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: 0,
    marginTop: 10
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto 0 auto',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: 0,
    borderLeftWidth: 1
  },
  tableRowFixBorder: {
    justifyContent: 'flex-start',
    borderLeftWidth: 0
  },
  tableColHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: 1,
    borderLeftWidth: 0
  },
  tableCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol5: {
    width: '5%'
  },
  tableCol6: {
    width: '6%'
  },
  tableCol7: {
    width: '7%'
  },
  tableCol8: {
    width: '8%'
  },
  tableCol11: {
    width: '11%'
  },
  tableCol13: {
    width: '13%'
  },
  tableCol21: {
    width: '21%'
  },
  tableCellHeader: {
    paddingLeft: 1,
    paddingRight: 1,
    fontFamily: 'delivery',
    fontSize: 8,
    fontWeight: 'semibold',
    margin: '0 auto 0 auto',
    color: 'black',
    textAlign: 'center'
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 1,
    paddingRight: 1,
    fontFamily: 'delivery',
    fontSize: 8,
    margin: '0 auto 0 auto',
    color: 'black',
    textAlign: 'center'
  },
  tableCellBarcode: {
    justifyContent: 'flex-start',
    paddingLeft: 1,
    paddingRight: 1,
    fontFamily: 'delivery',
    textAlign: 'left',
    fontSize: 8,
    color: 'black'
  },
  barcodeImage: {
    paddingTop: 1,
    paddingBottom: 2,
    paddingLeft: 1,
    paddingRight: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '85%'
  },
  footerPageNumber: {
    position: 'absolute',
    fontSize: 10,
    fontFamily: 'delivery',
    bottom: 23.6771654,
    right: 28.3464567,
    color: 'gray',
    textAlign: 'right'
  },
  footerDate: {
    position: 'absolute',
    fontFamily: 'delivery',
    fontSize: 10,
    bottom: 23.6771654,
    left: 51.0236,
    color: 'gray'
  },
  footer: {
    position: 'absolute',
    fontSize: 10,
    bottom: 22.6771654,
    left: 0,
    color: 'gray'
  }
});
