// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.netting-table_nettingTable__IUrL7 {
  width: 100%;
}

.netting-table_nettingTable__IUrL7 th {
  background-color: #e6e6e6;
  padding: 16px 10px 16px 10px;
}

.netting-table_nettingP__jgCfQ {
  margin-top: 30px;
  margin-bottom: 15px !important;
}

.netting-table_nettingTable__IUrL7 th,
.netting-table_nettingTable__IUrL7 td {
  text-align: right;
  min-width: 40px;
  vertical-align: middle;
}

.netting-table_nettingTable__IUrL7 td:first-child {
  text-align: left;
}
.netting-table_nettingTable__IUrL7 th:last-child,
.netting-table_nettingTable__IUrL7 td:last-child {
  padding-right: 27px;
}
.netting-table_nettingTable__IUrL7 td {
  padding: 16px 10px 16px 10px;
}

.netting-table_nettingTable__IUrL7 hr {
  width: 100%;
}

table.netting-table_nettingTable__IUrL7 h6 {
  margin-bottom: 0 !important;
}

.netting-table_nettingTable__IUrL7 .netting-table_trWithBorder__uimQw {
  border-bottom: 1px solid #e6e6e6;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderChange/common/components/NettingTable/netting-table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;;EAEE,iBAAiB;EACjB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;AACA;;EAEE,mBAAmB;AACrB;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".nettingTable {\n  width: 100%;\n}\n\n.nettingTable th {\n  background-color: #e6e6e6;\n  padding: 16px 10px 16px 10px;\n}\n\n.nettingP {\n  margin-top: 30px;\n  margin-bottom: 15px !important;\n}\n\n.nettingTable th,\n.nettingTable td {\n  text-align: right;\n  min-width: 40px;\n  vertical-align: middle;\n}\n\n.nettingTable td:first-child {\n  text-align: left;\n}\n.nettingTable th:last-child,\n.nettingTable td:last-child {\n  padding-right: 27px;\n}\n.nettingTable td {\n  padding: 16px 10px 16px 10px;\n}\n\n.nettingTable hr {\n  width: 100%;\n}\n\ntable.nettingTable h6 {\n  margin-bottom: 0 !important;\n}\n\n.nettingTable .trWithBorder {\n  border-bottom: 1px solid #e6e6e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nettingTable": `netting-table_nettingTable__IUrL7`,
	"nettingP": `netting-table_nettingP__jgCfQ`,
	"trWithBorder": `netting-table_trWithBorder__uimQw`
};
export default ___CSS_LOADER_EXPORT___;
