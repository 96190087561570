import React, { useLayoutEffect, useState } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { Age, AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomDeleteCell = (props: Props): JSX.Element => {
  const { setValue, control } = useFormContext<AdditionalOrdersOrderCreate>();
  const { orderReferences, deleteAllocation, setAllocatedDiscounts, allocatedDiscounts } = useOrderContext();
  const [disabled, setDisabled] = useState(false);

  const [selectedSearchedOrderIds] = useWatch({
    control,
    name: ['selectedSearchedOrderIds']
  });

  useLayoutEffect(() => {
    if (props.row.age === Age.OLD) {
      const curRef = orderReferences.find((r) => r.orderId === props.row.data.orderNumber);
      /*
        if (curRef && curRef.containsIds !== OrderReferenceContainsIdsEnum.None) {
          setDisabled(true);
        }
       */

      if (curRef?.pendingIds) {
        setDisabled(true);
      }
    }
  }, []);

  const handleDeleteCell = () => {
    const currentRef = allocatedDiscounts.find((r) => r.orderId === props.row.data.orderNumber);
    const newSelectedAlloc = (selectedSearchedOrderIds || []).filter((s) => s !== props.row.data?.orderNumber);
    setValue('selectedSearchedOrderIds', newSelectedAlloc);
    deleteAllocation(props.row.data?.orderNumber || '');
    currentRef && setAllocatedDiscounts(currentRef, 'DELETE');
  };

  return (
    <DHLButton
      name={props.row.data?.orderNumber + '-details'}
      type="default"
      icon="delete"
      onClick={handleDeleteCell}
      iconPosition="icon"
      disabled={disabled}
    />
  );
};

export const customDeleteRenderer = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomDeleteCell value={value} row={row} column={column} />;
};
