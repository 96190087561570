import i18n, { useTranslation } from 'i18n';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React, { ReactElement } from 'react';

export type ShipmentQuantityWeightWidgetProps = {
  dialogPostValue: Order;
  meta: { language: string };
};

const translationDE = {
  shipmentQuantityWeight: 'Sendungsmenge und-gewicht',
  shipmentQuantity: 'Sendungsmenge:',
  paidShipments: 'Aufgezahlte Sendungen:',
  singleWeight: 'Einzelgewicht:'
};
const translationEN = {
  shipmentQuantityWeight: 'Shipment quantity and weight',
  shipmentQuantity: 'Shipment quantity:',
  paidShipments: 'Paid shipments:',
  singleWeight: 'Single weight:'
};

i18n.addResourceBundle('de', 'ShipmentQuantityWeightWidget', translationDE);
i18n.addResourceBundle('en', 'ShipmentQuantityWeightWidget', translationEN);

export const ShipmentQuantityWeightWidget = (props: ShipmentQuantityWeightWidgetProps): ReactElement => {
  const { t } = useTranslation('ShipmentQuantityWeightWidget');
  const language = props.meta.language;

  return (
    <OrderOverviewWidget title={t('shipmentQuantityWeight')}>
      <table>
        <tbody>
          <tr>
            <td>
              <p>{t('shipmentQuantity')}</p>
            </td>
            <td>
              <p>{props.dialogPostValue.baseProduct?.quantity?.toLocaleString(language)}</p>
            </td>
          </tr>
          {props.dialogPostValue?.orderCategoryProductKey !== OrderCategoryProductKey.DHL_INFOPOST && (
            <tr>
              <td>
                <p>{t('paidShipments')}</p>
              </td>
              <td>
                <p>{props.dialogPostValue?.additionalQuantity?.toLocaleString(language)}</p>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <p>{t('singleWeight')}</p>
            </td>
            <td>
              <p>{props.dialogPostValue.baseProduct?.weight?.toLocaleString(language)} g</p>
            </td>
          </tr>
        </tbody>
      </table>
    </OrderOverviewWidget>
  );
};
