import React, { useEffect, useRef, useState } from 'react';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { uid } from 'react-uid';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';
import { GetOrderFaultyIds } from 'order/common/context/order/OrderProvider';

interface Props {
  value: string;
  column: ColumnConfig;
  row: OrderTreeItem<OrderReplyTDO>;
  meta?: CellMetaData;
}

export const CustomCheckboxCell = (props: Props): JSX.Element => {
  const { getValues, setValue, control } = useFormContext<AdditionalOrdersOrderCreate>();
  const [selected, setSelected] = useState(false);
  const { allocationsOrders, getOrderFaultyIds } = useOrderContext();

  const [selectedSearchedOrderIds] = useWatch({
    control,
    name: ['selectedSearchedOrderIds']
  });

  useEffect(() => {
    const isSingleSelected = selectedSearchedOrderIds?.find((s) => s === props.row.data?.orderNumber);
    const isAllocSelected = allocationsOrders?.find((s) => s.data.orderNumber === props.row.data?.orderNumber);

    if (isSingleSelected || isAllocSelected) {
      setSelected(true);
    } else {
      setSelected(false);
      setValue('selectAll', false);
    }
  }, [allocationsOrders, props.row, selectedSearchedOrderIds]);

  const handleSelectionChange = () => {
    if (selected) {
      const newSelectedAlloc = (selectedSearchedOrderIds || []).filter((s) => s !== props.row.data?.orderNumber);
      setValue('selectedSearchedOrderIds', newSelectedAlloc);
    } else {
      const newSelectedAlloc = [...(selectedSearchedOrderIds || [])];
      if (props.row.data?.orderNumber) {
        newSelectedAlloc.push(props.row.data?.orderNumber);
      }
      setValue('selectedSearchedOrderIds', newSelectedAlloc);
    }
    setSelected(!selected);
    (getOrderFaultyIds as GetOrderFaultyIds[])?.forEach((o) => {
      if (o?.orderId === props.row.data?.orderNumber) {
        if (o.isSelected) {
          o.isSelected = false;
        } else {
          o.isSelected = true;
        }
      }
    });
  };

  return <DHLCheckbox name={uid(props.row.data?.orderNumber)} label={''} value={selected} onChange={handleSelectionChange} size="slim" />;
};

export const customCheckboxRenderer = (
  value: string,
  column: ColumnConfig,
  row: OrderTreeItem<OrderReplyTDO>,
  meta?: CellMetaData
): JSX.Element | null => {
  return <CustomCheckboxCell value={value} row={row} meta={meta} column={column} />;
};
