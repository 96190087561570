import classNames from 'classnames';
import { concatNonUndefined } from 'common/utils/helpers';
import React, { AllHTMLAttributes, VFC } from 'react';
import classes from './StatusProgressBar.module.css';

export type StatusProgressBarProps = AllHTMLAttributes<HTMLDivElement> & {
  steps: string[];
  active?: number;
  'data-testid'?: string;
};

export const StatusProgressBar: VFC<StatusProgressBarProps> = ({ steps, active = 0, ...props }) => (
  <div
    role="meter"
    aria-valuemax={steps.length + 1}
    aria-valuemin={-1}
    aria-valuenow={active}
    aria-labelledby={concatNonUndefined(props.id, '-label-', active < 0 ? undefined : active)}
    {...props}
  >
    <ul className={classes.progressbar}>
      {steps.map((step, index) => (
        <li
          key={index}
          className={classNames(classes.label, {
            [classes.active]: index === active,
            [classes.done]: index <= active
          })}
          id={concatNonUndefined(props.id, '-label-', index)}
          data-testid={concatNonUndefined(props['data-testid'], '-label-', index, index === active ? '-active' : index <= active ? '-done' : '')}
        >
          {step}
        </li>
      ))}
    </ul>
  </div>
);
