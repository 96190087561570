import { DependentProduct } from 'generated';
import { UseDependentProductsReturn } from 'order/common/hooks/useDependentProducts';

export type SubProductsSchema = Partial<{
  easy: boolean;
  nichtAutomationsfaehig: boolean;
  trackAndMatch: boolean;
  premium: string | boolean;
  origPremium?: string;
  startPointSelection?: string;
  deliveryAreaSelection?: string;
  seasonalSurcharge?: string;
}>;

export const subProductsToDependentProducts = (data: SubProductsSchema, dependentProducts: UseDependentProductsReturn): DependentProduct[] => {
  const { easy, nichtAutomationsfaehig, trackAndMatch, premium, startPointSelection, deliveryAreaSelection, seasonalSurcharge } = data;

  const products: DependentProduct[] = [];

  const easyProductNumber = dependentProducts.easy?.productNumber;

  if (easy && easyProductNumber) {
    products.push({ productNumber: easyProductNumber });
  }

  const nichtAutomationsFaehigProductNumber = dependentProducts.nichtAutomationsfaehig?.productNumber;
  if (nichtAutomationsfaehig && nichtAutomationsFaehigProductNumber) {
    products.push({ productNumber: nichtAutomationsFaehigProductNumber });
  }

  const trackAndMatchProductNumber = dependentProducts.trackAndMatch?.productNumber;
  if (trackAndMatch && trackAndMatchProductNumber) {
    products.push({ productNumber: trackAndMatchProductNumber });
  }

  if (premium) {
    products.push({ productNumber: premium.toString() });
  }
  if (startPointSelection) {
    products.push({ productNumber: startPointSelection });
  }
  if (deliveryAreaSelection) {
    products.push({ productNumber: deliveryAreaSelection });
  }
  if (seasonalSurcharge) {
    products.push({ productNumber: seasonalSurcharge });
  }

  return products;
};
