import classNames from 'classnames';
import { useAccordion, UseAccordionProps, UseAccordionReturn } from 'common/components/Accordion/useAccordion';
import { reference } from 'common/utils/helpers';
import React, { ReactElement, VFC } from 'react';
import classes from './accordion.module.css';

export type AccordionProps = {
  children?: (values: UseAccordionReturn<HTMLDetailsElement>) => undefined | ReactElement | ReactElement[];
} & Omit<UseAccordionProps<HTMLDetailsElement>, 'children'>;

export const Accordion: VFC<AccordionProps> = ({ children, ...props }) => {
  const values = useAccordion(props);
  return <>{children?.(values)}</>;
};

Accordion.displayName = 'Accordion';

export const AccordionItem = reference('AccordionItem', 'details', classes.Accordion);

export const AccordionButton = reference('AccordionButton', 'summary', classes.AccordionButton);

export const AccordionItemOverview = reference('AccordionItem', 'details', classNames([classes.Accordion, classes.AccordionOverview]));

export const AccordionButtonOverview = reference(
  'AccordionButton',
  'summary',
  classNames([classes.AccordionButton, classes.AccordionButtonOverview])
);
export const AccordionButtonOverviewDisabled = reference(
  'AccordionButton',
  'summary',
  classNames([classes.AccordionButton, classes.AccordionButtonOverview, classes.AccordionButtonOverviewDisabled])
);
