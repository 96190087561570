// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
.AMDHLInputFilterContainer_amInputContainerInner__kB\\+jq {
  display: flex;
  justify-content: stretch;
}

.AMDHLInputFilterContainer_amInputContainerInner__kB\\+jq input.dhlInput.has-select {
  border-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/AMDHLInputFilterContainer/AMDHLInputFilterContainer.module.css"],"names":[],"mappings":"AAAA;;;EAGE;AACF;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/*\n * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.\n * This code is licensed and the sole property of Deutsche Post DHL Group.\n */\n.amInputContainerInner {\n  display: flex;\n  justify-content: stretch;\n}\n\n.amInputContainerInner input:global(.dhlInput.has-select) {\n  border-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amInputContainerInner": `AMDHLInputFilterContainer_amInputContainerInner__kB+jq`
};
export default ___CSS_LOADER_EXPORT___;
