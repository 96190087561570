import { OrderSearchResultRepTDO } from 'order/common/services/OrderService';

type Runtime = {
  events: string;
  'duration (ms)': number;
};

const calculateDuration = (start: number, end: number): number => (start && end ? end - start : 0);

/**
 * Creates a console output of the runtimes for the order/search endpoint.
 *
 * @param reply reply tdo containing the timings
 * @param startTime timestamp of the beginning request
 */
export const printRuntimeStatistics = (reply: OrderSearchResultRepTDO, startTime: number): void => {
  const statistics: Runtime[] = [];

  if (reply && reply.timings) {
    const feStartedTime = reply.timings['fe_started'];
    const feReqStartedTime = reply.timings['fe_req_started'];
    const feReqFinishedTime = reply.timings['fe_req_finished'];
    let beStartedTime = reply.timings['be_started'];
    let beFinishedTime = reply.timings['be_finished'];
    // adjust offset of server time according to ntp algo -> deactivated
    const beOffset = 0;
    beStartedTime += beOffset;
    beFinishedTime += beOffset;
    statistics.push({ events: 'Frontend: Search triggered -> request sent', 'duration (ms)': calculateDuration(feStartedTime, feReqStartedTime) });
    statistics.push({ events: 'Network: frontend -> backend', 'duration (ms)': calculateDuration(feReqStartedTime, beStartedTime) });

    const amexStarted = reply.timings['amex_started'] + beOffset;
    const amexFinished = reply.timings['amex_finished'] + beOffset;
    statistics.push({
      events: 'Backend: preprocessing',
      'duration (ms)': calculateDuration(beStartedTime, amexStarted)
    });
    statistics.push({
      events: 'Exchange: request to exchange -> response from exchange',
      'duration (ms)': calculateDuration(amexStarted, amexFinished)
    });
    let bgStepFinished = amexFinished;

    if (reply.timings['amex_started_topLevel'] && reply.timings['amex_finished_topLevel']) {
      const amexStartedTopLevel = reply.timings['amex_started_topLevel'] + beOffset;
      const amexFinishedTopLevel = reply.timings['amex_finished_topLevel'] + beOffset;
      if (amexStartedTopLevel - bgStepFinished > 0) {
        statistics.push({
          events: 'Backend: processing',
          'duration (ms)': calculateDuration(bgStepFinished, amexStartedTopLevel)
        });
      }
      statistics.push({
        events: 'Exchange: top level request to exchange -> response from exchange',
        'duration (ms)': calculateDuration(amexStartedTopLevel, amexFinishedTopLevel)
      });
      bgStepFinished = amexFinishedTopLevel;
    }

    if (reply.timings['fill_customers_started'] && reply.timings['fill_customers_finished']) {
      const fillCustomersStarted = reply.timings['fill_customers_started'] + beOffset;
      const fillCustomersFinished = reply.timings['fill_customers_finished'] + beOffset;
      if (fillCustomersStarted - bgStepFinished > 0) {
        statistics.push({
          events: 'Backend: processing',
          'duration (ms)': calculateDuration(bgStepFinished, fillCustomersStarted)
        });
      }
      statistics.push({
        events: 'Backend: fill customers',
        'duration (ms)': calculateDuration(fillCustomersStarted, fillCustomersFinished)
      });
      bgStepFinished = fillCustomersFinished;
    }

    if (reply.timings['fill_newspaper_name_started'] && reply.timings['fill_newspaper_name_finished']) {
      const fillNewspaperNameStarted = reply.timings['fill_newspaper_name_started'] + beOffset;
      const fillNewspaperNameFinished = reply.timings['fill_newspaper_name_finished'] + beOffset;
      if (fillNewspaperNameStarted - bgStepFinished > 0) {
        statistics.push({
          events: 'Backend: processing',
          'duration (ms)': calculateDuration(bgStepFinished, fillNewspaperNameStarted)
        });
      }
      statistics.push({
        events: 'Backend: fill newspaper name',
        'duration (ms)': calculateDuration(fillNewspaperNameStarted, fillNewspaperNameFinished)
      });
      bgStepFinished = fillNewspaperNameFinished;
    }

    statistics.push({
      events: 'Backend: postprocessing',
      'duration (ms)': calculateDuration(bgStepFinished, beFinishedTime)
    });
    statistics.push({ events: 'Network: backend -> frontend', 'duration (ms)': calculateDuration(beFinishedTime, feReqFinishedTime) });
    statistics.push({ events: 'Frontend: response received -> response in store', 'duration (ms)': calculateDuration(beFinishedTime, Date.now()) });
  }

  console.groupCollapsed(
    `%c ⏱️ orders/search ${reply.durationInMillis ? new Date(reply.durationInMillis).toLocaleTimeString() : ''} (${reply.resultStatus})`,
    `background: ${reply.resultStatus === 'succeeded' ? 'darkgreen' : 'darkred'}; color: white; padding: 4px`
  );
  console.log('Request sent:', new Date(startTime));
  console.log(`Complete duration: ${Math.max(0, Date.now() - startTime)} ms`);
  console.log(`Duration of last Exchange request: ${reply?.durationInMillis || 0} ms`);
  if (statistics.length) {
    console.table([...statistics]);
  }
  console.groupEnd();
};
