import PubSub from '../utils/pubsub';

export type PubSubTopic =
  | 'am.modal.open'
  | 'am.modal.close' // closes a modal
  | 'am.modal.closed' // fires after closing a modal
  | 'am.modal.trigger.close'; // fires pre-closing event

export type SubscriptionToken = string;

export const subscribeToTopic = (topic: PubSubTopic, func: (topic: PubSubTopic | string, data: any) => void) =>
  PubSub.subscribe(topic, func) as SubscriptionToken;
export const publishToTopic = (topic: PubSubTopic, data: any) => PubSub.publish(topic, data);
export const unsubscribeFromTopic = (token: SubscriptionToken) => PubSub.unsubscribe(token);
