// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section_row__ixfkH {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#button-contactForQuestions-onclick-add{
  padding-left: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/ContactSection/contact-section.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n:global #button-contactForQuestions-onclick-add{\n  padding-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `contact-section_row__ixfkH`
};
export default ___CSS_LOADER_EXPORT___;
