import { OrderDestination } from '../../../../../generated';
import { Order } from '../../../../common/context/order/dtos/Order';
import { SourceOrder } from './MovePalletContent';

type PalletDataType = Array<{ key: string; value: string; sourceOrder: SourceOrder; submissionId: string; postageDate: Date }>;

const shuttleDataRegion = (orderDest: OrderDestination[] | undefined, order: Order): PalletDataType => {
  return (
    (orderDest
      ?.filter((item) => item?.zipCode?.length && item?.zipCode?.length < 3)
      .map((item: OrderDestination) => {
        if (item.zipCode === 'DE') {
          return {
            key: '0-9',
            value: String(item.quantity),
            sourceOrder: { orderNumber: order.orderId, lastChangedOn: order.lastChangedOn },
            submissionId: order.submissionId,
            postageDate: order.orderDetail?.date
          };
        } else
          return {
            key: item?.zipCode,
            value: String(item.quantity),
            sourceOrder: { orderNumber: order.orderId, lastChangedOn: order.lastChangedOn },
            submissionId: order.submissionId,
            postageDate: order.orderDetail?.date
          };
      }) as PalletDataType) || [{ key: '', value: '', sourceOrder: { orderNumber: '', lastChangedOn: '' }, submissionId: '', postageDate: '' }]
  );
};

export const shuttleData = (shareType: string | undefined, order: Order): PalletDataType => {
  if (shareType === 'region') {
    return shuttleDataRegion(order?.destinations, order);
  }
  if (shareType === 'zoneAndRegion') {
    const dataRegiontoZone: Array<{ zipCode: string; quantity: number }> = (order?.destinations?.map((item: OrderDestination) => {
      if (item?.zipCode?.length && item?.zipCode?.length == 2 && item.zipCode !== 'DE') {
        return {
          zipCode: item.zipCode?.substr(0, 1),
          quantity: item.quantity
        };
      } else {
        return {
          zipCode: item.zipCode,
          quantity: item.quantity
        };
      }
    }) as Array<{ zipCode: string; quantity: number }>) || [{ zipCode: '', quantity: '' }];

    return shuttleDataRegion(
      Array.from(
        dataRegiontoZone.reduce((m, { zipCode, quantity }) => m.set(zipCode, (m.get(zipCode) || 0) + quantity), new Map()),
        ([zipCode, quantity]) => ({ zipCode, quantity })
      ),
      order
    );
  }
  if (shareType === 'variant') {
    return (
      (order?.packagesPerVariant?.map((item) => {
        return {
          key: item.description,
          value: String(item.packageQuantity),
          sourceOrder: { orderNumber: order.orderId, lastChangedOn: order.lastChangedOn },
          submissionId: order.submissionId,
          postageDate: order.orderDetail?.date
        };
      }) as PalletDataType) || [{ key: '', value: '', sourceOrder: { orderNumber: '', lastChangedOn: '' }, submissionId: '', postageDate: '' }]
    );
  }
  return [{ key: '', value: '', sourceOrder: { orderNumber: '', lastChangedOn: '' }, submissionId: '', postageDate: new Date() }];
};
