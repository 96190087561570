import { CSVMatrix, CSVMatrixElement } from 'common/csv';
import { collectBy } from 'common/utils/collectBy';
import { GetPartialOrderRep } from 'generated';
import { allocatedOrderToCsvRows, reduceOrderMappings } from 'order/orderView/components/ExportPartialOrderToCsv/partialOrdersToMatrix';

const mappingKeysArray = [
  'originatorId',
  'originatorName',
  'category',
  'standardTL',
  'compactTL',
  'bigTL',
  'maxiTL',
  'postcardTL',
  'standardId',
  'compactId',
  'bigId',
  'maxiId',
  'postcardId',
  'standardFlex',
  'compactFlex',
  'bigFlex',
  'maxiFlex',
  'postcardFlex'
] as const;

type SubmitterMapping<T = string> = Record<typeof mappingKeysArray[number], T>;

const revertMapping = <T extends CSVMatrixElement>(mappedValues: SubmitterMapping<T>): CSVMatrixElement[] =>
  mappingKeysArray.map((key) => mappedValues[key]);

export const partialOrderSubmitterHeadings = revertMapping({
  originatorId: 'Absender Kundennummer',
  originatorName: 'Absender Name',
  category: 'Auftragskategorie',
  standardTL: 'Standard (TL relevant)',
  compactTL: 'Kompakt (TL relevant)',
  bigTL: 'Groß (TL relevant)',
  maxiTL: 'Maxi (TL relevant)',
  postcardTL: 'Postkarte (TL relevant)',
  standardId: 'Standard (ID-Rabatt)',
  compactId: 'Kompakt (ID-Rabatt)',
  bigId: 'Groß (ID-Rabatt)',
  maxiId: 'Maxi (ID-Rabatt)',
  postcardId: 'Postkarte (ID-Rabatt)',
  standardFlex: 'Standard (Laufzeit-Rabatt)',
  compactFlex: 'Kompakt (Laufzeit-Rabatt)',
  bigFlex: 'Groß (Laufzeit-Rabatt)',
  maxiFlex: 'Maxi (Laufzeit-Rabatt)',
  postcardFlex: 'Postkarte (Laufzeit-Rabatt)'
});

export const partialOrderSubmitterHeadingsUpdated = revertMapping({
  originatorId: 'Absender Kundennummer',
  originatorName: 'Absender Name',
  category: 'Auftragskategorie',
  standardTL: 'Standard (Basis)',
  compactTL: 'Kompakt (Basis)',
  bigTL: 'Groß (Basis)',
  maxiTL: 'Maxi (Basis)',
  postcardTL: 'Postkarte (Basis)',
  standardId: 'Standard (ID)',
  compactId: 'Kompakt (ID)',
  bigId: 'Groß (ID)',
  maxiId: 'Maxi (ID)',
  postcardId: 'Postkarte (ID)',
  standardFlex: 'Standard (E+1)',
  compactFlex: 'Kompakt (E+1)',
  bigFlex: 'Groß (E+1)',
  maxiFlex: 'Maxi (E+1)',
  postcardFlex: 'Postkarte (E+1)'
});

export const partialOrderSubmitterToMatrix = (order: GetPartialOrderRep, allocatedOrders: ReturnType<typeof allocatedOrderToCsvRows>): CSVMatrix => {
  const mappedByOriginators = allocatedOrders.reduce(
    collectBy((o) => o.originatorId),
    new Map()
  );

  const reducedByOrder = Array.from(mappedByOriginators.values()).map((a) => a.reduce(reduceOrderMappings));

  return reducedByOrder.map(revertMapping);
};
