import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useAlerts } from 'common/AlertContext';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { OrderCustomerRoleEnum, OrderPriceRep, OrderPriceResponse } from 'generated';
import { AllocationTable } from 'order/common/components/AllocationTable/AllocationTable';
import { orderViewAllocationTableConfiguration } from 'order/common/components/AllocationTable/columnConfiguration';
import { FrankingDisplay } from 'order/common/components/FrankingDisplay';
import { Order as Order } from 'order/common/context/order/dtos/Order';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { PayerInfo } from 'order/orderView/components/PayerInfo';
import { BillingTable } from 'order/orderChange/common/components/BillingTable';
import { NettingTable } from 'order/orderChange/common/components/NettingTable';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React, { useEffect, useState, VFC } from 'react';
import { FooterSection } from '../FooterSection';
import { HeaderSection } from '../HeaderSection';
import { PSLBillingTable } from '../PSLBillingTable/PSLBillingTable';
import { PSLBillingTable as PSLBillingTableUpdated } from '../PSLBillingTableUpdated/PSLBillingTable';
import { PSLCorrectionBillingTable } from '../PSLCorrectionBillingTable/PSLCorrectionBillingTable';
import { ToolBar } from '../ToolBar';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from '../../../../common/components/Tooltip/Tooltip';
import headlines from 'common/styles/Headline.module.css';
import styles from './OrderViewDetail.module.css';
import { OrderContentTab } from './OrderContentTab/OrderContentTab';
import { Alerts } from '../../../common/components/Alerts/Alerts';
import { CollectionBillingTable } from '../CollectionOrderBillingTable';
import { OrderVersion, OrderVersionDialog } from './OrderVersion/OrderVersionDialog';
import { Spinner } from 'order/common/components/Spinner';
import { getOrderVersion, internationalProducts } from 'order/common/services/OrderService';
import { getOrderPrice } from 'order/common/context/order/utils/getOrderPrice';
import { InternationalBaseProduct } from 'order/productGroups/international/dtos/InternationalBaseProducts';
import { isAvailableFromJan25 } from '../../../common/utils/availabilityByDate';
import { getPayervalidationInfo } from 'order/productGroups/common/services/getPayerValidation';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';

export type OrderViewDetailProps = {
  order: Order;
  orderFromContext?: boolean;
};

export const OrderViewDetail: VFC<OrderViewDetailProps> = ({ order, orderFromContext }) => {
  const { alerts } = useAlerts();
  const {
    generateExistingAllocations,
    productGroup,
    order: rawOrder,
    setOrderPrice: setOP,
    orderPrice: orderPriceContext,
    upsertMetaData,
    meta
  } = useOrderContext();
  const { user, hasPermission } = useAuthContext();
  const tabClasses = (p: { selected: boolean }) => classNames('tab-item tab-item-primary', styles.tabItem, { 'tab-item-primary-active': p.selected });
  const { t } = useTranslation('', { keyPrefix: 'partial' });

  const payrollTooltip: TooltipProps = {
    content: t('tooltip.payrollTooltip'),
    placement: 'right',
    disabled: false
  };
  useEffect(() => {
    if (
      (order?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
        order?.productGroup === ProductGroup.TEILLEISTUNG ||
        order?.productGroup === ProductGroup.COLLECTION) &&
      !rawOrder?.pendingIds &&
      !orderFromContext
    ) {
      const orderIds = order.orderReferences?.map((ref) => ref.orderId) ?? [];
      generateExistingAllocations(orderIds, order as any);
    }

    return () => {
      // resetAllocations();
      // resetOrderReferences();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const payer = order?.parties.find((x) => x.role === OrderCustomerRoleEnum.Payer)?.customerId;
  const postage = order?.postage;
  const showNetPayrollToolTip: boolean = payer?.substring(10, 2) === '38';
  const isPartial = order?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER;
  const isCollection = order?.productGroup === ProductGroup.COLLECTION;
  const showFrankingDisplay = !isPartial && !!postage && !isCollection;
  const originator =
    order?.parties.find((x) => x.role === OrderCustomerRoleEnum.Originator)?.customerId ??
    order?.parties.find((x) => x.role === OrderCustomerRoleEnum.Submitter)?.customerId;

  // State management for orderVersions and price
  const [orderPriceLoading, setOrderPriceLoading] = useState<boolean>(true);
  const [orderVersions, setOrderVersions] = useState<OrderVersion[]>([]);
  const [orderPrice, setOrderPrice] = useState<OrderPriceRep>();
  const [payerEKP, setPayerEKP] = useState<string | undefined>('');

  // Fetching order versions
  const fetchOrderVersions = async () => {
    if (!rawOrder?.orderId) {
      return;
    }
    const data = await getOrderVersion(rawOrder.orderId);
    if (data) {
      const orderVersions: OrderVersion[] = data?.data?.orderVersions?.map((o: OrderVersion) => o);
      setOrderVersions(orderVersions);
      upsertMetaData('orderVersions', orderVersions);
    }
  };

  // Fetching order price
  const fetchOrderPrice = async () => {
    if (!rawOrder?.orderId || !rawOrder?.orderPriceKey || !orderVersions.length) {
      return;
    }

    const orderPriceReq = {
      orderId: rawOrder.orderId,
      orderPriceKey: rawOrder.orderPriceKey,
      orderVersion: meta?.selectedOrderVersion?.orderVersion || rawOrder?.orderVersion
    };

    setOrderPriceLoading(true);

    const data = (await getOrderPrice(orderPriceReq)) as OrderPriceResponse;

    if (data) {
      if (!meta?.selectedOrderVersion?.orderVersion) {
        setOP(data?.orderPriceRep);
      }

      setOrderPrice(data?.orderPriceRep);

      setOrderPriceLoading(false);
    }
  };

  // Fetch order versions on mount
  useEffect(() => {
    fetchOrderVersions();
  }, []);

  // Fetch order price on mount & orderVersion change
  useEffect(() => {
    if (orderVersions?.length > 0) {
      fetchOrderPrice();
    }
  }, [orderVersions, meta?.selectedOrderVersion?.orderVersion]);

  // Fetch International BaseProducts
  const fetchInternationalBaseProducts = async () => {
    const products: InternationalBaseProduct[] = (await internationalProducts(order.productGroup!)).data;
    if (products) {
      const bpValue = products.find((p) => p.productNumber === order?.internationalBaseProduct)?.descriptionShort;
      upsertMetaData('intbaseProductVal', bpValue);
    }
  };

  const validateInvoiceCenter = async () => {
    if (order.parties) {
      const payerID = order.parties.find((p) => p.role === OrderCustomerRoleEnum.Payer)?.customerId;
      const trimmedPayer = payerID && payerID.slice(0, 10);
      const validate = await getPayervalidationInfo(trimmedPayer);
      upsertMetaData('isPayerValidated', validate.validates);
      validate && setPayerEKP(trimmedPayer);

      if (!validate.validates) {
        const invoiceRecipient = order.parties.find((p) => p.role === OrderCustomerRoleEnum.InvoiceRecipient)?.customerId;
        const validate = await getPayervalidationInfo(invoiceRecipient);
        upsertMetaData('isPayerValidated', validate.validates);
        validate && setPayerEKP(invoiceRecipient);
      }
    }
  };

  useEffect(() => {
    fetchInternationalBaseProducts();
    validateInvoiceCenter();
  }, []);

  const remainingClaim =
    orderPrice?.allocationDocumentsOrderFees?.find((item) => item.type === 'OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT')?.amount?.value ?? 0;

  const origin = window.location.origin;

  const getInvoiceCenterLink = () => {
    if (hasPermission(UserRights.Personal)) {
      return `${origin}/billing/invoice/overview?billing-q=${order.orderId}`;
    } else if (hasPermission(UserRights.Impersonated)) {
      return `${origin}/customeradministration/customeradministrationhub/billing/billingsearch?ekp=${payerEKP}&selectedUserId=${user.extUserId}&billing-q=${order.orderId}`;
    } else {
      return '';
    }
  };

  const invoiceCenterLink = getInvoiceCenterLink();

  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.grid, styles.paddingBottom30)}>
          <div className={classNames(styles.col_8)}>
            <h2 className={classNames(styles.heading, headlines.h4, headlines.end)}>Auftragsanzeige</h2>
          </div>
          <div className={classNames(styles.col_4_1)}>
            <ToolBar
              order={order}
              productionState={order?.productionState}
              isPayerValidated={meta?.isPayerValidated}
              invoiceCenterLink={invoiceCenterLink}
            />
          </div>
        </div>
        <Alerts alerts={alerts} />
        <div className={classNames(styles.parentbg)}>
          <div className={classNames(styles.headerBox)}>
            <HeaderSection order={order} productionState={order?.productionState} />
          </div>
          <div className={classNames(styles.tabGroup)}>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.List className={classNames('nav nav-tabs dhlTabNavigation primary', styles.tabList)} as="ul" id="orderFilterTabs">
                <div className={classNames('dhlTabNavigationItemContainer primary', styles.tabContainer)}>
                  <Tab as="li" className={tabClasses}>
                    Auftragsinhalt
                  </Tab>
                  <Tab as="li" className={tabClasses}>
                    Zahlungsinformationen
                  </Tab>
                </div>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className={classNames(styles.tabPanel)}>
                  <OrderContentTab order={order} />
                </Tab.Panel>
                <Tab.Panel className={classNames(styles.tabPanel)}>
                  <OrderOverviewRow>
                    {orderVersions?.length > 1 && (
                      <OrderOverviewWidget width={3}>
                        <ErrorBoundary context={'OrderVerionDialog'}>
                          <div className={styles.OrderVerionDialogContainer}>
                            <OrderVersionDialog orderVersions={meta?.orderVersions || orderVersions} />
                          </div>
                        </ErrorBoundary>
                      </OrderOverviewWidget>
                    )}
                    <PayerInfo
                      payerId={payer}
                      validityDate={order?.orderDetail?.date}
                      role={!isPartial ? OrderCustomerRoleEnum.Payer : OrderCustomerRoleEnum.Contractor}
                      width={3}
                      withAddress={productGroup === ProductGroup.BRIEF && order.orderCategory === OrderCategory.AFM_BRIEF}
                      toolTip={isPartial && showNetPayrollToolTip ? payrollTooltip : undefined}
                    />
                    <OrderOverviewWidget width={3}>
                      <hr className={styles.divider} />
                    </OrderOverviewWidget>
                    {showFrankingDisplay && (
                      <>
                        <FrankingDisplay
                          orderDate={order?.orderDetail?.date}
                          postage={postage}
                          productGroup={order.productGroup}
                          width={1}
                          category={order.orderCategory}
                        />
                        <OrderOverviewWidget width={3}>
                          <hr className={styles.divider} />
                        </OrderOverviewWidget>
                      </>
                    )}
                    <>
                      {orderPriceLoading && !isPartial && !isCollection ? (
                        <OrderOverviewWidget width={3}>
                          <div className={styles.spinnerContiner}>
                            <Spinner />
                          </div>
                        </OrderOverviewWidget>
                      ) : (
                        <OrderOverviewWidget width={3}>
                          {!isPartial && !isCollection && (
                            <>
                              <ErrorBoundary context={'BillingTable'}>
                                <BillingTable orderPrice={orderPriceContext} productGroup={order?.productGroup} />
                              </ErrorBoundary>
                              <ErrorBoundary context={'NettingTable'}>
                                <NettingTable orderPrice={orderPriceContext} />
                              </ErrorBoundary>
                            </>
                          )}
                        </OrderOverviewWidget>
                      )}
                      {orderPriceLoading && isCollection ? (
                        <OrderOverviewWidget width={3}>
                          <div className={styles.spinnerContiner}>
                            <Spinner />
                          </div>
                        </OrderOverviewWidget>
                      ) : (
                        <OrderOverviewWidget width={3}>
                          {isCollection && (
                            <>
                              <ErrorBoundary context={'BillingTable'}>
                                <CollectionBillingTable orderPrice={orderPriceContext} originator={originator} />
                              </ErrorBoundary>
                              <ErrorBoundary context={'NettingTable'}>
                                {<NettingTable orderPrice={orderPriceContext} isCollectionOrder={true} />}
                              </ErrorBoundary>
                            </>
                          )}
                        </OrderOverviewWidget>
                      )}
                    </>
                  </OrderOverviewRow>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        {isPartial && selectedIndex ? (
          <>
            {orderPriceLoading ? (
              <OrderOverviewWidget width={3}>
                <div className={styles.spinnerContiner}>
                  <Spinner />
                </div>
              </OrderOverviewWidget>
            ) : (
              <>
                <ErrorBoundary context={'PSLBillingTable'}>
                  {isAvailableFromJan25(order?.orderDetail?.date) ? (
                    <PSLBillingTableUpdated orderPrice={orderPriceContext} />
                  ) : (
                    <PSLBillingTable orderPrice={orderPriceContext} />
                  )}
                </ErrorBoundary>
                <ErrorBoundary context={'PSLCorrectionBillingTable'}>
                  {remainingClaim > 0 ? <PSLCorrectionBillingTable orderPrice={orderPriceContext} /> : null}
                </ErrorBoundary>
              </>
            )}
          </>
        ) : null}
        {(isPartial || isCollection) && order && !selectedIndex ? (
          <div data-testid="allocation-table">
            <ErrorBoundary context={'AllocationTable'}>
              <AllocationTable
                columnConfig={orderViewAllocationTableConfiguration(order?.productGroup as ProductGroup)}
                showActionBar={!isCollection}
              />
            </ErrorBoundary>
          </div>
        ) : null}
        <div className={classNames(styles.parentbg)}>
          <FooterSection
            order={order}
            productionState={order?.productionState}
            isPayerValidated={meta?.isPayerValidated}
            invoiceCenterLink={invoiceCenterLink}
          />
        </div>
      </div>
    </>
  );
};
