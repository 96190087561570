import React, { useState, useEffect } from 'react';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { AMDHLOrderSearchFilterDatePicker } from 'order/orderSearch/components/filter/AMDHLOrderSearchFilterDatePicker';
import { AMDHLOrderSearchFilterSelectableTextInput } from 'order/orderSearch/components/filter/AMDHLOrderSearchFilterSelectableTextInput';
import { OrderSearchFilterContainer } from 'order/orderSearch/components/filter/OrderSearchFilterContainer';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderMergeTableContainer } from '../components/OrderMergeTableContainer/OrderMergeTableContainer';
import styles from './OrderMerge.module.css';

export const OrderMerge = () => {
  const { control } = useFormContext();
  const { setValue } = useFormContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { setSearchType, loading, reset } = useSearchContext();
  const { allocationsLoading, meta, orderMode } = useOrderContext();
  const [filteredSelectedAllocOrders, searchedOrders] = useWatch({
    control,
    name: ['filteredSelectedAllocOrders', 'searchedOrders']
  });
  const [selectedAttrType, setSelectedAttrType] = useState<OrderSearchKey>();

  useEffect(() => {
    setSearchType(OrderSearchType.MERGE);
    reset();
  }, []);

  useEffect(() => {
    if (loading) {
      setValue('selectAll', false);
    }
  }, [loading]);

  const setSelectedAttr = (type: OrderSearchKey) => {
    setSelectedAttrType(type);
  };

  return (
    <>
      <OrderSearchFilterContainer additionalSpace={styles.additionalSpace} disabled={allocationsLoading} meta={meta}>
        <AMDHLOrderSearchFilterSelectableTextInput
          orderMode={orderMode}
          nonNumeric={selectedAttrType === OrderSearchKey.ActionDescription || selectedAttrType === OrderSearchKey.Participation}
          setSelectedAttr={setSelectedAttr}
          searchKeys={[
            OrderSearchKey.Originator,
            OrderSearchKey.Submitter,
            OrderSearchKey.Payer,
            OrderSearchKey.Producer,
            OrderSearchKey.OrderId,
            {
              key: OrderSearchKey.ActionDescription,
              initiallyDeactivated: true
            },
            {
              key: OrderSearchKey.SettlementNumber,
              initiallyDeactivated: true
            },
            {
              key: OrderSearchKey.Procedure,
              initiallyDeactivated: true
            },
            {
              key: OrderSearchKey.Participation,
              initiallyDeactivated: true
            },
            {
              key: OrderSearchKey.PaymentClearingNumber,
              initiallyDeactivated: true
            },
            {
              key: OrderSearchKey.ProductionState,
              initiallyDeactivated: true
            }
          ]}
        />
        <AMDHLOrderSearchFilterDatePicker />
      </OrderSearchFilterContainer>
      <OrderMergeTableContainer />
    </>
  );
};
