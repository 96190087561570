export enum DeliveryOption {
  STANDARD = 'STANDARD',
  FLEX = 'FLEX',
  EMPTY = '',
  NONE = 'NONE'
}
export enum DeliveryOptionZATL {
  STANDARD = 'STANDARD',
  FLEX = 'FLEX',
  SCHNELL = 'SCHNELL',
  EMPTY = '',
  NONE = 'NONE'
}

export function mapDeliveryOptionToOtherAttribute(option: DeliveryOption | undefined): string | undefined {
  let mapped: string | undefined = undefined;

  switch (option) {
    case DeliveryOption.FLEX:
      mapped = 'flex';
      break;
    case DeliveryOption.STANDARD:
      mapped = 'std';
      break;
    default:
      break;
  }

  return mapped;
}

export function mapOtherAttributeToDeliverOption(option: string | undefined): DeliveryOption | undefined {
  let mapped: DeliveryOption | undefined;

  switch (option) {
    case 'flex':
      mapped = DeliveryOption.FLEX;
      break;
    case 'std':
      mapped = DeliveryOption.STANDARD;
      break;
    default:
      mapped = DeliveryOption.EMPTY;
      break;
  }

  return mapped;
}

export const labelForDeliveryOption = (v: DeliveryOption): string => {
  switch (v) {
    case DeliveryOption.STANDARD:
      return 'E+1';
    case DeliveryOption.FLEX:
      return 'E+1 bis 2';
    default:
      return '';
  }
};
