import React, { MouseEvent } from 'react';
import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import './AMDHLChip.css';

type DHLChipTagProps = {
  /** Name of the component, used for generating test ids. */
  name: string;
  /** Label for the chip. */
  children?: React.ReactNode;
  /** Handler for chip icon onClick-events. */
  onClickDelete?: React.EventHandler<MouseEvent>;
  onClickEdit?: React.EventHandler<MouseEvent>;
};

/**
 * The AMDHLChip is a simple component to display a data unit.
 * It's commonly used in a list or group of data context.
 * Upon providing an <code>onClickDelete</code>-handler, the user gets an 'x' icon button and a way to start a deletion process.
 *
 * @param name name for the test id of the
 * @param children children nodes to be displayed before the deletion icon
 * @param onClickDelete delete handler called upon clicking the deletion icon
 * @param onClickEdit edit handler called upon clicking the edit icon

 * @constructor
 */
const AMDHLChip = ({ name, children, onClickDelete, onClickEdit }: DHLChipTagProps): JSX.Element => (
  <div className="dhlChipTag">
    {children}
    {onClickEdit && <DHLIcon name={name + '-edit'} icon={'edit'} onClick={onClickEdit} />}
    {onClickDelete && <DHLIcon name={name + '-remove'} icon={'close-thin'} onClick={onClickDelete} />}
  </div>
);

export default AMDHLChip;
