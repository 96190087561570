import React from 'react';
import { DHLLoading } from '@gkuis/gkp-base-widgets/dist/lib';

import styles from './activity-indicator.module.css';
import { Spinner } from '../Spinner';

interface Props {
  testId?: string;
  message?: string | JSX.Element;
}

function ActivityIndicatorComponent(props: Props) {
  return (
    <div className={styles.activityIndicatorContainer} data-testid={`activity-indicator-${props.testId}`}>
      <div className={styles.activityIndicator}>
        {/* <DHLLoading name={'ActivityIndicator-loading'} loading={true} info={'Loading'} /> */}
        <Spinner testid={props.testId} message={props.message} />
      </div>
    </div>
  );
}

export const ActivityIndicator = React.memo(ActivityIndicatorComponent);
