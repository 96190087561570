// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSearchAlertContainer_orderSearchAlert__IeFIC {
  margin: 10px auto;
  width: 50%;
}

.OrderSearchAlertContainer_orderSearchWarningAlert__cYGGm {
  margin: 10px auto;
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/OrderSearchAlertContainer/OrderSearchAlertContainer.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".orderSearchAlert {\n  margin: 10px auto;\n  width: 50%;\n}\n\n.orderSearchWarningAlert {\n  margin: 10px auto;\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderSearchAlert": `OrderSearchAlertContainer_orderSearchAlert__IeFIC`,
	"orderSearchWarningAlert": `OrderSearchAlertContainer_orderSearchWarningAlert__cYGGm`
};
export default ___CSS_LOADER_EXPORT___;
