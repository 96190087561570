import React, { FC, Fragment } from 'react';
import { useTranslation } from 'i18n';
import { Order } from 'order/common/context/order/dtos/Order';
import { DestinationDetailSection } from 'order/common/components/Overview/DestinationDetailSection';
import { FertigungDetailSection } from 'order/common/components/Overview/FertigungDetailSection';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { PalletsOverviewAccordian } from 'order/orderView/components/OrderView/PalletsOverview/PalletsOverviewAccordian/PalletsOverviewAccordian';

export type ZoneRegionProps = {
  value: Order;
};
export const ZoneRegion: FC<ZoneRegionProps> = ({ value }) => {
  const { t: tCatalogValues } = useTranslation('catalogValues');

  return (
    <>
      <OrderOverviewRow>
        {value.destinations?.length !== 0 ? (
          <OrderOverviewWidget width={2}>
            <DestinationDetailSection destinations={value.destinations || []} />
          </OrderOverviewWidget>
        ) : (
          ''
        )}
        {value.productGroup !== ProductGroup.DIALOGPOSTWURFSPEZIAL && value.orderCategoryProductKey !== OrderCategoryProductKey.DHL_INFOPOST ? (
          <>
            {value.productGroup !== ProductGroup.POSTAKTUELL && <FertigungDetailSection data-testid={'fertigung-section'} value={value} />}
            <OrderOverviewWidget data-testid={'variant-section'} width={3}>
              {value.productGroup !== ProductGroup.POSTAKTUELL && (
                <p data-testid="variant-heading">
                  <span className="h6" style={{ display: 'inline' }}>
                    Zielgruppe
                  </span>
                </p>
              )}
              {value.variants?.map((variant) =>
                variant === 'NKD' || variant === 'BKD' ? (
                  <p data-testid={'variant-p'} key={variant}>
                    {tCatalogValues(`lineItemVariant.display.${variant}`)}
                  </p>
                ) : (
                  ''
                )
              )}
            </OrderOverviewWidget>
          </>
        ) : (
          <></>
        )}
      </OrderOverviewRow>
      {value?.destinations?.length ? (
        <div style={{ borderBottom: '1px solid #e6e6e6' }}>
          <PalletsOverviewAccordian destinations={value?.destinations || []} id="palletOverview" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
