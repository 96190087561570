import { useSWRConfig } from 'swr';

export type UseSWRCacheReturn = ReturnType<typeof useSWRConfig> & {
  deletePartialKey: (partialKey: string) => void;
};

export const useSWRCache = (): UseSWRCacheReturn => {
  const config = useSWRConfig();
  const { cache } = config;
  return {
    ...config,
    deletePartialKey: (partialKey: string) =>
      (cache as Map<string, unknown>).forEach((_, key) => {
        if (key.includes(partialKey)) {
          cache.delete(key);
        }
      })
  };
};
