import { DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import React, { ReactElement } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type TimeInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  disabled?: boolean;
  error?: string;
  label?: string;
};

export const TimeInput = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  disabled,
  error,
  label,
  ...props
}: TimeInputProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { ref, ...field },
    fieldState: { error: errorMessage }
  } = useController({
    ...props,
    rules: {
      pattern: props.rules?.pattern || {
        value: /^([01]\d|2[0-3]):([0-5]\d)$/,
        message: 'invalid'
      },
      ...props.rules
    }
  });
  const { t } = useTranslation('orderCreate');
  return (
    <DHLTextInput
      {...field}
      label={label}
      onFocus={() => (field.value as any)?.length || field.onChange('12:00')}
      disabled={disabled}
      error={errorMessage ? t(`error.${props.name}.${error}`) : null}
    />
  );
};
