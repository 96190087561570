import { DateRangePickerDataStore, FetchAdapter, LocalizationPack, ResourceDataStore } from '@gkuis/gkp-base-widgets/dist/lib';
import NoOpResource from 'common/services/DefaultNoOpResource';
import i18n from 'i18n';
import {
  DayRangePreset,
  DefaultDayRangePreset,
  getDefaultDayRange,
  getDefaultDayRangeEntries,
  getDefaultDayRanges,
  getPartialSearchDayRanges,
  getPartialSearchRangeEntries
} from './DayRangePreset';

/**
 * The {@link AMDHLDateRangeInputModel} wraps the gkp internal `DateRangePickerDataStore` and adds simple transformation methods
 * providing {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date|Date} objects for the backend.
 */
export class AMDHLDateRangeInputModel {
  private readonly dataStore: DateRangePickerDataStore;

  constructor() {
    const defaultResourceStore = new DefaultResourceDataStore();
    this.dataStore = new DateRangePickerDataStore(defaultResourceStore, getDefaultDayRanges());
    defaultResourceStore.presetButtonTextSupplier = () => getDefaultDayRange(this.preset)?.getLocalization() || 'Benutzerdefiniert';
  }

  get valueFrom(): Date {
    return this.dataStore.selectedStartDate.toDate();
  }

  get valueTo(): Date {
    return this.dataStore.selectedEndDate.toDate();
  }

  get preset(): DefaultDayRangePreset {
    const entry = getDefaultDayRangeEntries().find(([_, preset]) => preset === this.dataStore.selectedPreset);
    if (!entry) {
      return DefaultDayRangePreset.USER_DEFINED;
    }

    if (this.selectedRangeMatchesPreset(entry[1])) {
      return entry[0];
    }
    return DefaultDayRangePreset.USER_DEFINED;
  }

  set preset(preset: DefaultDayRangePreset) {
    const dayRange = getDefaultDayRange(preset);
    const presetKey = String(getDefaultDayRanges().findIndex((value) => value === dayRange));
    this.getDataStore().applyPresetRangeKey(presetKey);
    this.getDataStore().commitSelectedDateAsActive();
  }

  private selectedRangeMatchesPreset(presetRange: DayRangePreset): boolean {
    return (
      presetRange.getStartDate().startOf('day').diff(this.dataStore.selectedStartDate.startOf('day'), 'days') === 0 &&
      presetRange.getEndDate().startOf('day').diff(this.dataStore.selectedEndDate.startOf('day'), 'days') === 0
    );
  }

  public getDataStore(): DateRangePickerDataStore {
    return this.dataStore;
  }
}

/**
 * The default [[ResourceDataStore]] for the {@link AMDHLDateRangeInputModel}.
 * Contains localized strings for the ui as well as an automatic button text representing the dropdowns selection.
 * The text is supplied by the {@code presetButtonTextSupplier}.
 *
 * @constructor
 */
class DefaultResourceDataStore extends NoOpResource {
  presetButtonTextSupplier = (): string => 'Benutzerdefiniert';

  getLabel(key: string, params?: string[]): string {
    if (key === 'dateRangePicker.presetSelect') {
      return this.presetButtonTextSupplier();
    }
    return this.localizationPack.getLabel(key, params);
  }
}

/**
 * The {@link AMDHLDateRangeInputModel} wraps the gkp internal `DateRangePickerDataStore` and adds simple transformation methods
 * providing {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date|Date} objects for the backend.
 */
export class AMDHLPartialSearchDateRangeInputModel {
  private readonly dataStore: DateRangePickerDataStore;
  private isCollection: boolean;

  constructor(isCollection: boolean) {
    this.isCollection = isCollection;
    const defaultResourceStore = new PartialResourceDataStore(isCollection);
    this.dataStore = new DateRangePickerDataStore(defaultResourceStore, getPartialSearchDayRanges(isCollection));
    defaultResourceStore.presetButtonTextSupplier = () =>
      getDefaultDayRange(this.preset)?.getLocalization() || (isCollection ? 'Benutzerdefiniert' : 'Heute');
  }

  get valueFrom(): Date {
    return this.dataStore.selectedStartDate.toDate();
  }

  get valueTo(): Date {
    return this.dataStore.selectedEndDate.toDate();
  }

  get preset(): DefaultDayRangePreset {
    const entry = getPartialSearchRangeEntries(this.isCollection).find(([_, preset]) => preset === this.dataStore.selectedPreset);
    if (!entry) {
      return DefaultDayRangePreset.USER_DEFINED;
    }

    if (this.selectedRangeMatchesPreset(entry[1])) {
      return entry[0];
    }
    return DefaultDayRangePreset.USER_DEFINED;
  }

  set preset(preset: DefaultDayRangePreset) {
    const dayRange = getDefaultDayRange(preset);
    const presetKey = String(getPartialSearchDayRanges(this.isCollection).findIndex((value) => value === dayRange));
    this.getDataStore().applyPresetRangeKey(presetKey);
    this.getDataStore().commitSelectedDateAsActive();
  }

  private selectedRangeMatchesPreset(presetRange: DayRangePreset): boolean {
    return (
      presetRange.getStartDate().startOf('day').diff(this.dataStore.selectedStartDate.startOf('day'), 'days') === 0 &&
      presetRange.getEndDate().startOf('day').diff(this.dataStore.selectedEndDate.startOf('day'), 'days') === 0
    );
  }

  public getDataStore(): DateRangePickerDataStore {
    return this.dataStore;
  }
}

class I18NextLocalizationPack extends LocalizationPack {
  constructor() {
    super('partial', new Map<string, string>());
  }

  getMessage(key: string, params?: string[]): string {
    return i18n.t(key, Object.assign({}, params));
  }
}

class PartialResourceDataStore extends ResourceDataStore {
  static fetchAdapter = new FetchAdapter(undefined);
  static localicationPack = new I18NextLocalizationPack();
  private isCollection: boolean;
  presetButtonTextSupplier = (isCollection: boolean): string => (isCollection ? 'Benutzerdefiniert' : 'Heute');

  constructor(isCollection: boolean) {
    super(PartialResourceDataStore.fetchAdapter, '', '', '');
    this.isCollection = isCollection;
  }

  get localizationPack(): I18NextLocalizationPack {
    return PartialResourceDataStore.localicationPack;
  }

  getLabel(k: string, params?: string[]): string {
    if (k === 'dateRangePicker.presetSelect') {
      return this.presetButtonTextSupplier(this.isCollection);
    }
    return this.localizationPack.getLabel(k, params);
  }
}

export const partialSearchDateRangeInputModel = (isCollection: boolean, isStatusBIL?: boolean): AMDHLPartialSearchDateRangeInputModel => {
  const partialSearchInputModel = new AMDHLPartialSearchDateRangeInputModel(isCollection);
  partialSearchInputModel.preset = isCollection
    ? DefaultDayRangePreset.USER_DEFINED
    : isStatusBIL
    ? DefaultDayRangePreset.LAST_3_DAYS
    : DefaultDayRangePreset.TODAY;
  return partialSearchInputModel;
};
