import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { format as formatDate } from 'date-fns';
import { downloadCsvBlob } from 'order/common/utils/downloadBlob';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../common/context/search/SearchContext';
import { toCsv } from './csv/toCsv';
import { useTableContext } from '../context/table/TableContext';
import { OrderSearchResultRepTDO } from '../services/OrderService';

/**
 * The csv export button provides a CSV export of the whole, unfiltered data in the result table.
 * It uses the data and search attributes to generate a csv file displaying the result set as well as the search conditions.
 *
 * @constructor
 */
const ExportCsvButton = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'csv' });
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useAuthContext();
  const userName = useAuthContext().user.userName;
  const { attributes, rawResponse, response } = useSearchContext();
  const { filteredRows, filter } = useTableContext();

  const onClick = async () => {
    setLoading(true);
    setTimeout(downloadCsv, 200);
  };

  async function downloadCsv() {
    try {
      setLoading(true);
      const title = `AMGKP-Suchergebnis-${formatDate(new Date(), 'yyyyMMddHHmmss')}.csv`;
      // const content = toCsv({
      //   attributes,
      //   rawResponse,
      //   response
      // });
      const content = toCsv({
        attributes,
        rawResponse: { orders: filteredRows },
        response: { orders: filteredRows },
        userName: userName ? userName : '',
        filter
      });
      downloadCsvBlob(title, content);

      setLoading(false);
    } catch (e) {
      console.error(`error downloading pdf report: ${e}`);
      setLoading(false);
    }
  }

  return (
    <DHLButton
      size="xs"
      type="primary"
      label={t('button')}
      icon="csv"
      iconPosition="icon-first"
      loadingState={loading}
      onClick={onClick}
      disabled={!hasPermission(UserRights.OrderExport) || !response?.orders?.length}
    />
  );
};

export default ExportCsvButton;
