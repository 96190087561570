import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useAlerts } from '../../AlertContext';

import styles from './loadingOrder.module.css';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from '../../../order/common/components/ActivityIndicator/ActivityIndicator';
import { Alerts } from '../../../order/common/components/Alerts/Alerts';

interface Props {
  headline: string;
}
export const LoadingOrder = (props: Props): JSX.Element => {
  const { alerts, clear } = useAlerts();
  const { t: oc } = useTranslation('orderCreate');

  useEffect(() => {
    return () => clear();
  }, []);

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.grid, styles.paddingBottom30)}>
        <div className={classNames(styles.col_8)}>
          <h2 className={classNames(styles.h4, styles.center)}>{props.headline}</h2>
        </div>
      </div>
      <ActivityIndicator testId={'activity-indicator'} message={oc('notifications.loadingOrder')} />
      <Alerts alerts={alerts} />
    </div>
  );
};
