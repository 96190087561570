import React from 'react';
import ReactPDF, { Path, Svg } from '@react-pdf/renderer';

export const PostLogo = ({ viewBox, style }: ReactPDF.SVGProps): JSX.Element => {
  return (
    <Svg id="DP_x5F_Leistungsmarke" viewBox={viewBox} style={style} data-testid="postLogoTestId">
      <Path
        d="M858.3806,138.5h27.9846L858.105,167h-27.9847L858.3806,138.5z M820.6326,138.5L792.3741,167h27.9847l28.2599-28.5H820.6326
	z M892.8574,138.5l28.2603,28.5h27.9861l-28.2602-28.5H892.8574z M930.6054,138.5l28.2601,28.5H986.85l-28.2601-28.5H930.6054z
	 M887.4883,132.9739c-73.3513-0.0257-101.7063-63.7964-97.8907-112.1242l21.5439-6.8524
	c-3.5054,27.4972,5.0126,47.3349,10.8813,56.4239C815.0344,31.5191,844.397,0.0257,881.1561,0
	c33.061-0.0224,60.4082,24.1226,60.279,58.7631c4.8472-13.2216,8.2234-33.3504,8.9228-47.44L1000,23.679
	C996.0312,81.9537,952.325,132.9945,887.4883,132.9739z M920.99,59.9861c0-21.9436-17.7869-39.7323-39.7321-39.7323
	c-21.9438,0-39.7324,17.7888-39.7324,39.7323c0,21.9461,17.7886,39.734,39.7324,39.734
	C903.2031,99.7201,920.99,81.9322,920.99,59.9861z M726.7043,59.1088l-15.4922,4.886v13.4663h-9.2954v12.9896h9.057v25.9793
	c0,8.0249,1.6279,13.8238,4.8861,17.3989c3.2557,3.5751,8.778,5.3626,16.5646,5.3626c1.5875,0,2.9985-0.0405,4.2307-0.1191
	c1.2298-0.0811,2.3621-0.2002,3.3963-0.3575v-12.8705c-0.6362,0.0811-1.3323,0.1406-2.0854,0.1787
	c-0.7554,0.0406-1.4515,0.0596-2.0856,0.0596c-1.511,0-2.8409-0.1382-3.9921-0.4171c-1.1537-0.2765-2.1069-0.8128-2.8602-1.6089
	c-0.7554-0.7937-1.3109-1.8663-1.6684-3.2176c-0.3574-1.349-0.5363-3.0578-0.5363-5.1244V90.4508h13.228V77.4612h-13.347V59.1088z
	 M225.6134,59.1088l-15.4922,4.886v13.4663h-9.2953v12.9896h9.0571v25.9793c0,8.0249,1.6278,13.8238,4.8859,17.3989
	c3.2557,3.5751,8.7782,5.3626,16.5648,5.3626c1.5873,0,2.9984-0.0405,4.2306-0.1191c1.2298-0.0811,2.3619-0.2002,3.3963-0.3575
	v-12.8705c-0.6364,0.0811-1.3323,0.1406-2.0855,0.1787c-0.7556,0.0406-1.4515,0.0596-2.0855,0.0596
	c-1.5111,0-2.841-0.1382-3.9922-0.4171c-1.1536-0.2765-2.1069-0.8128-2.8601-1.6089c-0.7556-0.7937-1.3109-1.8663-1.6684-3.2176
	c-0.3575-1.349-0.5363-3.0578-0.5363-5.1244V90.4508h13.2279V77.4612h-13.3472V59.1088z M334.2744,125.8446
	c-4.3712,0-7.7866-1.43-10.2487-4.2902c-2.4644-2.86-3.6942-6.7927-3.6942-11.7978v-4.0519c0-5.0052,1.2108-8.9378,3.6347-11.798
	c2.4216-2.86,5.7798-4.2901,10.0699-4.2901c4.2091,0,7.944,1.6303,11.2021,4.886l7.9843-10.1296
	c-2.6217-2.7004-5.7606-4.7668-9.4145-6.1968c-3.6561-1.4301-7.5077-2.1452-11.5596-2.1452c-4.2114,0-8.044,0.7151-11.4999,2.1452
	c-3.456,1.4299-6.4162,3.4178-8.8782,5.9585c-2.4644,2.543-4.3498,5.6224-5.6606,9.2358
	c-1.3109,3.6156-1.9663,7.6079-1.9663,11.9767v4.7668c0,4.4499,0.6745,8.4826,2.0259,12.0957
	c1.349,3.6157,3.2367,6.6951,5.6606,9.2358c2.4215,2.5431,5.3817,4.5095,8.8782,5.899c3.494,1.3896,7.3885,2.0855,11.6787,2.0855
	c8.5803,0,15.7307-2.779,21.4508-8.3419l-7.8653-10.1296C342.4162,124.2167,338.4836,125.8446,334.2744,125.8446z
	 M279.9325,102.0104l-7.0311-1.5493c-4.6881-1.0319-7.0311-3.0983-7.0311-6.1968c0-1.8258,0.8128-3.2557,2.443-4.2902
	c1.6279-1.032,3.9113-1.5492,6.8524-1.5492c5.6391,0,10.6849,1.6684,15.1346,5.0052l6.197-10.2487
	c-6.4353-4.7668-14.0623-7.1503-22.8809-7.1503c-7.4697,0-13.3091,1.6685-17.5182,5.0053
	c-4.2114,3.3367-6.3161,7.9057-6.3161,13.7046c0,4.7668,1.4086,8.7401,4.2306,11.9171c2.8196,3.1795,6.9716,5.3626,12.4534,6.5544
	l7.0311,1.5492c2.3834,0.4766,4.1113,1.1917,5.1839,2.1451c1.0726,0.9533,1.6088,2.2261,1.6088,3.8134
	c0,4.2115-3.3773,6.3161-10.1295,6.3161c-6.1182,0-11.7191-1.9449-16.803-5.8393l-6.4352,10.1295
	c3.3368,2.7814,6.8715,4.8264,10.6063,6.1373c3.7324,1.3107,8.0226,1.9662,12.8703,1.9662c8.261,0,14.6963-1.6873,19.3058-5.0647
	c4.6072-3.3749,6.9118-8.1227,6.9118-14.241C296.6164,110.4334,291.0535,104.3938,279.9325,102.0104z M679.2742,102.0104
	l-7.1502-1.5493c-4.6094-1.0319-6.9118-3.0983-6.9118-6.1968c0-1.8258,0.8127-3.2557,2.4429-4.2902
	c1.6281-1.032,3.9112-1.5492,6.8523-1.5492c5.5606,0,10.6063,1.6684,15.1349,5.0052l6.0777-10.2487
	c-6.4352-4.7668-14.0242-7.1503-22.7617-7.1503c-7.4697,0-13.3281,1.6685-17.5776,5.0053
	c-4.2521,3.3367-6.3758,7.9057-6.3758,13.7046c0,4.7668,1.4301,8.7401,4.2902,11.9171c2.8599,3.1795,7.031,5.3626,12.513,6.5544
	l6.9117,1.5492c4.6072,0.9533,6.912,2.9411,6.912,5.9585c0,4.2115-3.4178,6.3161-10.2487,6.3161
	c-3.0199,0-5.9204-0.4767-8.6995-1.43c-2.7814-0.9533-5.4437-2.4215-7.9844-4.4093l-6.5544,10.1295
	c3.3367,2.7814,6.8904,4.8264,10.6657,6.1373c3.773,1.3107,8.0441,1.9662,12.8109,1.9662c8.342,0,14.7963-1.6873,19.3652-5.0647
	c4.5667-3.3749,6.8523-8.1227,6.8523-14.241C695.839,110.5121,690.3166,104.4749,679.2742,102.0104z M174.673,119.171
	c-1.1131,1.8281-2.5837,3.3582-4.4094,4.5881c-1.8281,1.2323-4.0137,1.8471-6.5543,1.8471c-3.2582,0-5.6606-0.9915-7.2098-2.9793
	c-1.5492-1.9855-2.3239-4.8454-2.3239-8.5803V77.4612h-15.8497v37.7771c0,7.7081,1.6089,13.6261,4.8265,17.7566
	c3.2176,4.1328,7.8056,6.1968,13.7643,6.1968c4.4474,0,8.2228-1.0939,11.3212-3.2771s5.5605-5.0647,7.3885-8.6399V138h14.8964
	V77.4612H174.673V119.171z M121.2197,83.8964c4.5666,5.2434,6.8523,12.4748,6.8523,21.6892v7.031H89.9373
	c0.3955,4.6882,1.8662,8.1847,4.4093,10.487c2.5406,2.3049,6.0777,3.456,10.6062,3.456c2.9388,0,5.5797-0.417,7.9249-1.2512
	c2.3429-0.8343,4.6668-2.0855,6.9715-3.754l6.6736,10.0105c-3.0197,2.4644-6.4162,4.3902-10.1891,5.7797
	c-3.7753,1.3896-7.9654,2.0855-12.5726,2.0855c-4.5285,0-8.6018-0.6768-12.215-2.0258c-3.6156-1.349-6.7141-3.2773-9.2953-5.7798
	c-2.5837-2.5025-4.5499-5.5796-5.899-9.2358c-1.3513-3.6537-2.0259-7.7461-2.0259-12.2746v-4.7668
	c0-4.5285,0.634-8.5994,1.9068-12.215c1.2704-3.6133,3.0984-6.6926,5.4819-9.2358c2.3834-2.5408,5.2626-4.488,8.6399-5.8394
	c3.3749-1.349,7.1693-2.0259,11.3808-2.0259C110.1558,76.031,116.6507,78.6529,121.2197,83.8964z M112.8181,102.487
	c-0.2384-4.2901-1.2729-7.646-3.0985-10.0699c-1.8281-2.4216-4.6094-3.6347-8.342-3.6347c-3.7347,0-6.5352,1.2131-8.4015,3.6347
	c-1.8685,2.424-2.8815,5.7798-3.0388,10.0699H112.8181z M471.2614,83.8964c4.5666,5.2434,6.8524,12.4748,6.8524,21.6892v7.031
	h-38.1348c0.3955,4.6882,1.8662,8.1847,4.4093,10.487c2.5406,2.3049,6.0777,3.456,10.6063,3.456
	c2.9387,0,5.5796-0.417,7.9248-1.2512c2.3429-0.8343,4.6667-2.0855,6.9715-3.754l6.6736,10.0105
	c-3.0199,2.4644-6.4162,4.3902-10.1892,5.7797c-3.7753,1.3896-7.9653,2.0855-12.5724,2.0855c-4.5285,0-8.6018-0.6768-12.215-2.0258
	c-3.6156-1.349-6.7141-3.2773-9.2953-5.7798c-2.5836-2.5025-4.55-5.5796-5.899-9.2358c-1.3515-3.6537-2.0259-7.7461-2.0259-12.2746
	v-4.7668c0-4.5285,0.6339-8.5994,1.9069-12.215c1.2703-3.6133,3.0983-6.6926,5.4818-9.2358
	c2.3835-2.5408,5.2626-4.488,8.6399-5.8394c3.3748-1.349,7.1693-2.0259,11.3808-2.0259
	C460.1976,76.031,466.6924,78.6529,471.2614,83.8964z M462.8598,102.487c-0.2383-4.2901-1.2726-7.646-3.0983-10.0699
	c-1.8282-2.4216-4.6096-3.6347-8.342-3.6347c-3.7349,0-6.5163,1.2131-8.342,3.6347c-1.8282,2.424-2.8602,5.7798-3.0984,10.0699
	H462.8598z M395.0515,76.2694c-4.2901,0-7.9057,0.994-10.8445,2.9794c-2.941,1.9877-5.3627,4.6477-7.2694,7.9845V54.5804H361.088
	V138h15.8497V96.4093c1.1106-1.8257,2.6217-3.3964,4.5285-4.7072c1.9068-1.3109,4.209-1.9664,6.912-1.9664
	c3.3368,0,5.7797,1.013,7.329,3.0389c1.5491,2.0258,2.3237,4.9074,2.3237,8.6399V138h15.8498v-37.7771
	c0-7.7843-1.6303-13.7237-4.886-17.8161C405.7365,78.3169,401.0887,76.2694,395.0515,76.2694z M638.2199,93.1322
	c1.3109,3.6156,1.9663,7.6866,1.9663,12.215v4.7668c0,4.5285-0.6555,8.6018-1.9663,12.215
	c-1.3109,3.6156-3.1985,6.695-5.6606,9.2358c-2.4645,2.543-5.4818,4.4904-9.0569,5.8393c-3.5752,1.349-7.5889,2.0258-12.0364,2.0258
	c-4.4499,0-8.4611-0.6768-12.0362-2.0258c-3.5752-1.349-6.5949-3.2963-9.0569-5.8393c-2.4645-2.5408-4.3498-5.6201-5.6606-9.2358
	c-1.3109-3.6133-1.9664-7.6866-1.9664-12.215v-4.7668c0-4.5285,0.6555-8.5994,1.9664-12.215
	c1.3109-3.6133,3.1962-6.6926,5.6606-9.2358c2.462-2.5408,5.4818-4.488,9.0569-5.8394c3.5751-1.349,7.5863-2.0259,12.0362-2.0259
	c4.4475,0,8.4612,0.677,12.0364,2.0259c3.5751,1.3514,6.5925,3.2987,9.0569,5.8394
	C635.0214,86.4395,636.9091,89.5189,638.2199,93.1322z M624.3365,105.7046c0-5.4818-1.1321-9.5718-3.3963-12.2746
	c-2.2643-2.7004-5.4224-4.0519-9.4742-4.0519c-4.0516,0-7.2097,1.3514-9.4741,4.0519c-2.2643,2.7028-3.3963,6.7928-3.3963,12.2746
	v4.0519c0,5.4818,1.132,9.5742,3.3963,12.2746c2.2643,2.7029,5.4224,4.0519,9.4741,4.0519c4.0519,0,7.21-1.349,9.4742-4.0519
	c2.2642-2.7003,3.3963-6.7928,3.3963-12.2746V105.7046z M575.3572,73.7669c1.43,3.0198,2.145,6.4352,2.145,10.2487v2.3834
	c0,3.8135-0.6959,7.2504-2.0854,10.3084c-1.3919,3.0602-3.3963,5.6416-6.0181,7.7461c-2.6218,2.1069-5.8013,3.7348-9.5336,4.886
	c-3.7349,1.1536-7.9465,1.7279-12.6321,1.7279h-13.1088V138h-16.5648V59.3472h29.6736c4.6071,0,8.7781,0.5767,12.5129,1.7279
	c3.7324,1.1537,6.9119,2.8007,9.5337,4.9457C571.9012,68.1659,573.9271,70.7495,575.3572,73.7669z M560.9376,84.373
	c0-3.7323-1.1727-6.5735-3.5156-8.5207c-2.3453-1.9449-5.7416-2.9196-10.189-2.9196h-13.1088v24.5491h13.1088
	c4.4474,0,7.8438-0.9724,10.189-2.9196c2.343-1.9449,3.5156-4.7859,3.5156-8.5207V84.373z M55.5336,68.7021
	c6.2756,6.2375,9.4146,15.1562,9.4146,26.7539v6.4352c0,11.6001-3.139,20.5188-9.4146,26.7539
	C49.2556,134.8825,40.2798,138,28.601,138H0V59.3472h28.601C40.2798,59.3472,49.2556,62.4672,55.5336,68.7021z M48.3834,96.171
	c0-15.0942-6.5949-22.6425-19.7824-22.6425H16.5647v50.2901H28.601c13.1875,0,19.7824-7.546,19.7824-22.6425V96.171z"
        fill="gray"
      />
    </Svg>
  );
};
