// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-change_container__4vS\\+E {
  max-width: 1400px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderChange/common/components/OrderChange/order-change.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".container {\n  max-width: 1400px;\n  width: 100%;\n  padding: 0 32px;\n  margin: 0 auto 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `order-change_container__4vS+E`
};
export default ___CSS_LOADER_EXPORT___;
