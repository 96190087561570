import i18n, { useTranslation } from 'i18n';
import React, { useEffect, useRef, useState, VFC } from 'react';

import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { OrderViewDetail } from 'order/orderView/components/OrderViewDetail';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { CustomOrderAlert } from 'common/components/PendingOrder/CustomOrderAlert';
import { LoadingOrder } from 'common/components/LoadingOrder/LoadingOrder';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { getOrder } from 'order/common/context/order/utils/getOrder';

import { ErrorMessageContainer } from '../../../productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';

export type OrderViewContainerProps = {
  orderId?: string;
  status?: string;
  orderType?: string;
};

const translationDE = {
  orderCanNotBeDisplayed: 'Dieser Auftrag kann nicht angezeigt werden.',
  notFound: 'Auftrag konnte nicht gefunden werden.'
};
const translationEN = {
  orderCanNotBeDisplayed: 'This order can not be displayed.',
  notFound: 'The order does not exist.'
};

i18n.addResourceBundle('de', 'OrderDisplay', translationDE);
i18n.addResourceBundle('en', 'OrderDisplay', translationEN);

export const OrderViewContainer: VFC<OrderViewContainerProps> = ({ orderId, orderType }) => {
  const rawOrder = useRef<OrderResponse | undefined>(undefined);
  const orderNumberLoading = useRef<string | undefined>(undefined);
  const [orderLoading, setOrderLoading] = useState<boolean>(true);
  const {
    setOrderMode,
    setProductGroup,
    setOrder,
    setOrderCategory,
    setOrderId,
    setOrderCategoryProductKey,
    orderId: orderIdContext,
    order,
    reset,
    resetOrderReferences,
    resetAllocations
  } = useOrderContext();
  const { checkWhitelistingKey } = useAuthContext();
  const { t } = useTranslation('OrderDisplay');
  const { t: oc } = useTranslation('orderCreate');
  const orderFromContext = useRef(true);

  const fetchOrder = async (id: string | undefined) => {
    if (!id) {
      return setOrderLoading(false);
    }
    const data = await getOrder<OrderResponse>(id, { includeDst: orderType !== 'ZA', includePrice: false });
    if (data) {
      setOrder(data);
      setProductGroup(data.productGroup);
      setOrderCategory(data.orderCategory);
      setOrderCategoryProductKey(data.orderCategoryProductKey);
      rawOrder.current = data;
    }
    setOrderId(id);
    setOrderLoading(false);
  };

  useEffect(() => {
    setOrderMode(OrderMode.VIEW);
    if (orderId?.toString() !== orderIdContext?.toString() && orderNumberLoading.current !== orderId) {
      orderFromContext.current = false;
      reset();
      resetOrderReferences();
      resetAllocations();
      orderNumberLoading.current = orderId;
      fetchOrder(orderId);
    } else if (orderLoading && orderId?.toString() === orderIdContext?.toString() && order) {
      rawOrder.current = order;
      setOrderLoading(false);
    }
  }, [orderId, orderIdContext, orderLoading, order]);

  useEffect(() => {
    return () => {
      // reset();
    };
  }, []);

  if (orderLoading) {
    return <LoadingOrder headline={oc('headline.orderDisplay')} />;
  }

  if (!rawOrder.current) {
    return <ErrorMessageContainer message={t('notFound')} dataTestId={'error-not-found'} />;
  }

  if ((rawOrder.current as any).timeoutError) {
    return (
      <CustomOrderAlert headline={oc('headline.orderDisplay')} message={oc(`notifications.timeoutWarning`)} title={oc('notifications.notice')} />
    );
  }

  if ((rawOrder.current as any).pendingIds) {
    return (
      <CustomOrderAlert headline={oc('headline.orderDisplay')} message={oc(`notifications.pendingIdsWarning`)} title={oc('notifications.warning')} />
    );
  }

  if (
    !checkWhitelistingKey(rawOrder.current.orderCategoryProductKey, WhitelistMethod.GET) ||
    rawOrder.current.orderCategory === OrderCategory.DIP_VARIO_TL
  ) {
    return <ErrorMessageContainer message={t('orderCanNotBeDisplayed')} dataTestId={'error-order-can-not-be-displayed'} />;
  }

  return (
    <ErrorBoundary context={'OderViewContainer'}>
      <OrderViewDetail order={(order || rawOrder.current) as any} orderFromContext={orderFromContext.current} />
    </ErrorBoundary>
  );
};
