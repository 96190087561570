import * as React from 'react';
import { useCallback, useReducer, useRef } from 'react';

import { OrderContext } from './OrderContext';
import { defaultReducerState, orderReducer, OrderReducerActions } from './reducers/order';
import { LoadingState } from '../search/dtos/LoadingState';
import { fetchJournalPaymentInfo } from './utils/fetchJournalPaymentInfo';
import { Age, AllocatedOrder } from './dtos/AllocatedOrder';
import { OrderResponse } from './dtos/GetOrder';
import { OrderReference } from './dtos/OrderReference';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { createAllocationOrderData } from './utils/createAllocationOrder';
import { createOrderReference } from './utils/createOrderReference';
import { createOrderReferenceFromExisting } from './utils/createOrderReferenceFromExisting';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { Occurrence } from 'common/dtos/Occurrence';
import { OrderCategory } from './dtos/OrderCategory';
import { OrderCategoryProductKey } from './dtos/OrderCategoryProductKey';
import { OrderPriceRep } from 'generated';
import { allocationConvertion } from 'order/common/components/QtyAllocation/helper/allocationConvertion';
import { computeBLChunks } from 'order/common/bulkLoad/helpers/computeBLChunks';
import { bulkLoad } from 'order/common/bulkLoad/api/bulkLoad';

export interface GetOrderFaultyIds {
  orderId: string;
  isSelected: boolean;
}

export const OrderProvider: React.FC = (props): JSX.Element => {
  const [orderData, dispatchOrderData] = useReducer(orderReducer, defaultReducerState);
  const allocatedOrderIds = useRef<string[]>([]);
  const loadedAllocationsCounter = useRef<number>(0); // used only internally for counting
  const fetchedAllocationsCounter = useRef<number>(0); // used only internally for counting
  const fetchingAllocationsCounter = useRef<number>(0); // used only internally for counting
  const existingAllocationsCount = useRef<number>(0); // used only internally for counting
  const existingAllocationsCollector = useRef<any[]>([]); // used only internally for collecting
  const existingOrderRefsCollector = useRef<any[]>([]); // used only internally for collecting
  const stopFetchingAllocationsLoop = useRef(false);
  const getOrderFaultyIds = useRef<GetOrderFaultyIds[]>([]);
  const parentOrderDate = useRef<Date | string>();

  if (existingOrderRefsCollector.current) {
    existingOrderRefsCollector.current.forEach((orderRef) => {
      const newRefData = allocationConvertion(orderRef);
      if (newRefData) {
        const exists = orderData.allocatedDiscounts.some((item) => item.orderId === newRefData.orderId);
        if (!exists) {
          orderData.allocatedDiscounts.push(newRefData);
        }
      }
    });
  }

  const setDirty = useCallback((dirty: boolean) => {
    dispatchOrderData({ type: OrderReducerActions.SET_DIRTY, data: dirty });
  }, []);

  const setLoadingState = useCallback((state: LoadingState) => {
    dispatchOrderData({ type: OrderReducerActions.SET_LOADING, data: state });
  }, []);

  const getJournalPaymentData = useCallback(async (zkz: string) => {
    const journalPaymentInfo = await fetchJournalPaymentInfo(zkz);
    dispatchOrderData({ type: OrderReducerActions.SET_JOURNAL_PAYMENT_DATA, data: journalPaymentInfo });
  }, []);

  const setDisplayAllAllocations = useCallback((display: boolean) => {
    dispatchOrderData({ type: OrderReducerActions.SET_DISPLAY_ALL_ALLOCATIONS, data: display });
  }, []);

  const setOrderMode = useCallback((orderMode: OrderMode) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER_MODE, data: orderMode });
  }, []);

  const changeOrderReference = useCallback((orderNumber: string, data: OrderReference) => {
    dispatchOrderData({ type: OrderReducerActions.CHANGE_ORDER_REFERENCE, data: { orderNumber, data } });
  }, []);

  const resetAllocations = useCallback(() => {
    allocatedOrderIds.current = [];
    dispatchOrderData({ type: OrderReducerActions.RESET_ALLOCATIONS });
  }, []);

  const resetOrderReferences = useCallback(() => {
    dispatchOrderData({ type: OrderReducerActions.RESET_ORDER_REFERENCES });
  }, []);

  const resetFaultyGetOrderIds = useCallback(() => {
    getOrderFaultyIds.current = [];
    dispatchOrderData({ type: OrderReducerActions.RESET_FAULTY_GETORDER_IDS });
  }, []);

  const setAllocatedOrdersCount = useCallback((orderIds: string[]): string[] => {
    const dedupedOrderIds = allocatedOrderIds.current.concat(orderIds).reduce((acc: string[], cur: string) => {
      if (!acc.includes(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
    dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATIONS_TOTAL, data: dedupedOrderIds.length });
    return dedupedOrderIds;
  }, []);

  const existingAllocationsLoaded = useCallback(() => {
    dispatchOrderData({ type: OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS, data: false });
    dispatchOrderData({ type: OrderReducerActions.ADD_EXISTING_ALLOCATED_ORDERS, data: existingAllocationsCollector.current });
    dispatchOrderData({ type: OrderReducerActions.ADD_ORDER_REFERENCES, data: existingOrderRefsCollector.current });
    existingAllocationsCollector.current = [];
    existingOrderRefsCollector.current = [];
    fetchingAllocationsCounter.current = 0;
    fetchedAllocationsCounter.current = 0;
  }, []);

  const loadNewAllocations = useCallback(async (orderIds: string[], editable: boolean) => {
    let loadedAllocationsCounter = allocatedOrderIds.current.length || 0;
    const allocations: any[] = [];
    const refs: any[] = [];

    const bulkLoadChucks = computeBLChunks(orderIds);
    await Promise.all(
      bulkLoadChucks?.map(async (chunk) => {
        const payload = chunk?.map((o) => ({ orderId: o })).filter((o) => !allocatedOrderIds.current.includes(o.orderId));
        if (payload?.length !== 0) {
          const orders = await bulkLoad(payload, chunk?.length);
          orders?.forEach((order: OrderResponse) => {
            if (!allocatedOrderIds.current.includes(order?.orderId)) {
              allocatedOrderIds.current.push(order?.orderId);
              if (stopFetchingAllocationsLoop.current) {
                return;
              }

              if (!order?.orderId) {
                getOrderFaultyIds.current.push({ orderId: order?.orderId, isSelected: true });
                setFaultyGetOrderIds(getOrderFaultyIds.current);
              }

              if (order && !(order as any)?.timeoutError) {
                loadedAllocationsCounter++;
                dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATIONS_COUNT, data: loadedAllocationsCounter });
                const { orderReference, qtyAlloc, totalQty, initialTotalQty } = createOrderReference(
                  order,
                  order?.qtyAllocation ?? [],
                  parentOrderDate.current ?? new Date().toLocaleDateString()
                );

                const allocationOrderData = createAllocationOrderData(order, parentOrderDate.current ?? new Date().toLocaleDateString());
                const packagingQty = order?.packaging?.quantity ?? 0;
                const allocatedOrder: AllocatedOrder = {
                  data: {
                    ...allocationOrderData,
                    productionState: order.productionState,
                    packagingQty,
                    totalNetWeight: order.totalNetWeight,
                    totalGrossWeight: order.packaging?.totalWeight ?? 0,
                    product: order.productGroup,
                    orderCategory: order.orderCategory,
                    totalQty,
                    allocatedQty: totalQty,
                    idsQty: orderReference.idsQty || 0,
                    qtyAllocation: qtyAlloc,
                    initialTotalQty
                  },
                  age: Age.NEW,
                  editable,
                  newlyAdded: editable,
                  id: new Date().getTime(),
                  highlight: true
                };

                allocations.push(allocatedOrder);
                refs.push(orderReference);
              }
            }
          });
        }
      })
    );

    dispatchOrderData({ type: OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS, data: false });
    dispatchOrderData({ type: OrderReducerActions.ADD_NEW_ALLOCATED_ORDERS, data: allocations });
    dispatchOrderData({ type: OrderReducerActions.ADD_ORDER_REFERENCES, data: refs });
  }, []);

  const loadExistingAllocations = useCallback(async (orderIds: string[], originalOrder: OrderResponse) => {
    if (!orderIds.length) {
      return;
    }

    const bulkLoadChucks = computeBLChunks(orderIds);
    bulkLoadChucks?.forEach(async (chunk) => {
      const payload = chunk?.map((o) => ({ orderId: o }));
      const resChunk = await bulkLoad(payload, chunk?.length);
      resChunk?.forEach((order: OrderResponse) => {
        if (!order?.orderId) {
          getOrderFaultyIds.current.push({ orderId: order?.orderId, isSelected: true });
          setFaultyGetOrderIds(getOrderFaultyIds.current);
        }

        if (order && !(order as any)?.timeoutError) {
          allocatedOrderIds.current.push(order?.orderId);
          loadedAllocationsCounter.current++;
          dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATIONS_COUNT, data: loadedAllocationsCounter.current });
          let origOrderReference: OrderReference | undefined | null = originalOrder?.orderReferences.find((r) => order?.orderId === r?.orderId);
          const { orderReference, qtyAlloc, totalQty, initialTotalQty } = createOrderReferenceFromExisting(originalOrder, order);
          let reference: OrderReference | null = {
            ...orderReference,
            initialTotalQty,
            qtyAllocation: qtyAlloc
          };

          let allocationOrderData: Partial<AllocatedOrder> | null = createAllocationOrderData(
            order,
            parentOrderDate.current ?? new Date().toLocaleDateString()
          );

          const packagingQty = order.packaging?.quantity ?? 0;
          let allocatedOrder: AllocatedOrder | null = {
            data: {
              ...allocationOrderData,
              containsIds: origOrderReference?.containsIds ?? Occurrence.NONE,
              flexOption: origOrderReference?.flexOption ?? Occurrence.NONE,
              productionState: order.productionState,
              packagingQty,
              totalNetWeight: order.totalNetWeight ?? 0,
              totalGrossWeight: order.packaging?.totalWeight ?? 0,
              stackable: !!order.packaging?.stackable,
              product: order.productGroup,
              orderCategory: order.orderCategory,
              totalQty,
              allocatedQty: totalQty,
              idsQty: totalQty,
              includeTotalQty: origOrderReference?.includeTotalQty ?? Occurrence.NONE,
              qtyAllocation: qtyAlloc,
              initialTotalQty,
              destinations: order.destinations,
              age: Age.OLD
            },
            age: Age.OLD,
            editable: false,
            newlyAdded: false,
            id: new Date().getTime(),
            highlight: true
          };

          existingAllocationsCollector.current.push(allocatedOrder);
          existingOrderRefsCollector.current.push(reference);
          // reset for clearing some memory
          origOrderReference = null;
          reference = null;
          allocationOrderData = null;
          allocatedOrder = null;
        }

        if (existingAllocationsCollector.current.length === orderIds.length) {
          existingAllocationsLoaded();
          return;
        }
      });
    });
  }, []);

  const generateExistingAllocations = useCallback((orderIds: string[], originalOrder: OrderResponse): void => {
    if (originalOrder) {
      // deduplicate
      const dedupedOrderIds = setAllocatedOrdersCount(orderIds);
      stopFetchingAllocationsLoop.current = false;
      existingAllocationsCollector.current = [];
      existingOrderRefsCollector.current = [];
      fetchingAllocationsCounter.current = 0;
      fetchedAllocationsCounter.current = 0;
      loadedAllocationsCounter.current = 0;
      existingAllocationsCount.current = dedupedOrderIds.length;
      dispatchOrderData({ type: OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS, data: true });
      loadExistingAllocations(orderIds, originalOrder);
    }
  }, []);

  const generateNewAllocations = useCallback((orderIds: string[], editable: boolean = true): void => {
    // deduplicate
    setAllocatedOrdersCount(orderIds);
    const dedupedOrderIds = orderIds.reduce((acc: string[], cur: string) => {
      if (!acc.includes(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
    if (!allocatedOrderIds.current.length) {
      dispatchOrderData({ type: OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS, data: true });
    }
    stopFetchingAllocationsLoop.current = false;
    loadNewAllocations(dedupedOrderIds, editable);
  }, []);

  const deleteAllocation = useCallback((orderId: string) => {
    allocatedOrderIds.current = allocatedOrderIds.current.filter((o) => o !== orderId);
    dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATIONS_COUNT, data: allocatedOrderIds.current.length });
    dispatchOrderData({ type: OrderReducerActions.DELETE_ALLOCATION, data: orderId });
    dispatchOrderData({ type: OrderReducerActions.DELETE_ORDER_REFERENCE, data: orderId });
  }, []);

  const setProductGroup = useCallback((productGroup: ProductGroup | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_PRODUCT_GROUP, data: productGroup });
  }, []);

  const setOrderCategory = useCallback((orderCategory: OrderCategory | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER_CATEGORY, data: orderCategory });
  }, []);

  const setOrderCategoryProductKey = useCallback((orderCategoryProductKey: OrderCategoryProductKey | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER_CATEGORY_PRODUCT_KEY, data: orderCategoryProductKey });
  }, []);

  const setOrder = useCallback((order: OrderResponse | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER, data: order });
    parentOrderDate.current = order?.orderDetail?.date;
  }, []);

  const setOrderPrice = useCallback((orderPrice: OrderPriceRep | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER_PRICE, data: orderPrice });
  }, []);

  const setOrderId = useCallback((orderId: string | number | undefined) => {
    dispatchOrderData({ type: OrderReducerActions.SET_ORDER_ID, data: orderId });
  }, []);

  const setFaultyGetOrderIds = useCallback((ids: GetOrderFaultyIds[]) => {
    dispatchOrderData({ type: OrderReducerActions.SET_FAULTY_GETORDER_IDS, data: ids });
  }, []);

  const hasErrors = useCallback(() => {
    return !!(orderData.errors && orderData.errors.length);
  }, [orderData.errors]);

  const addError = useCallback((key: string, data: unknown) => {
    dispatchOrderData({ type: OrderReducerActions.ADD_ERROR, data: { key, data } });
  }, []);

  const deleteError = useCallback((key: string) => {
    dispatchOrderData({ type: OrderReducerActions.DELETE_ERROR, data: key });
  }, []);

  const reset = useCallback(() => {
    allocatedOrderIds.current = [];
    stopFetchingAllocationsLoop.current = true;
    dispatchOrderData({ type: OrderReducerActions.RESET });
  }, []);

  const upsertMetaData = useCallback((key: string, value: unknown) => {
    dispatchOrderData({ type: OrderReducerActions.UPSERT_META_DATA, data: { key, value } });
    if (key === 'pickUpDate') {
      parentOrderDate.current = value as Date | string;
    }
  }, []);

  const setAllocatedDiscounts = (data: OrderReference, mode: 'ADD' | 'DELETE' | 'UPDATE') => {
    const allocatedDiscountsList: OrderReference[] = orderData.allocatedDiscounts || [];

    switch (mode) {
      case 'ADD':
        const newDiscountList = [...allocatedDiscountsList, data];
        orderData.allocatedDiscounts = newDiscountList;
        dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATED_DISCOUNTS, data: newDiscountList });
        break;

      case 'DELETE':
        const updatedDiscountList = allocatedDiscountsList.filter((item) => item.orderId !== data.orderId);
        orderData.allocatedDiscounts = updatedDiscountList;
        dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATED_DISCOUNTS, data: updatedDiscountList });
        break;

      case 'UPDATE':
        const itemIndex = allocatedDiscountsList.findIndex((item) => item.orderId === data.orderId);
        if (itemIndex !== -1) {
          const updatedList = [...allocatedDiscountsList];
          updatedList[itemIndex] = { ...data };
          orderData.allocatedDiscounts = updatedList;
          dispatchOrderData({ type: OrderReducerActions.SET_ALLOCATED_DISCOUNTS, data: updatedList });
        } else {
          console.error(`Item with orderId ${data.orderId} not found`);
        }
        break;

      default:
        break;
    }
  };

  return (
    <OrderContext.Provider
      value={{
        ...orderData,
        allocationsLoading: orderData.allocations.loading,
        allocationsCount: orderData.allocations.count,
        allocationsTotal: orderData.allocations.total,
        allocationsOrders: orderData.allocations.orders,
        changeOrderReference,
        deleteAllocation,
        generateExistingAllocations,
        generateNewAllocations,
        getJournalPaymentData,
        hasErrors,
        addError,
        deleteError,
        reset,
        resetAllocations,
        resetOrderReferences,
        resetFaultyGetOrderIds,
        setDisplayAllAllocations,
        setOrder,
        setOrderPrice,
        setOrderId,
        setOrderMode,
        setProductGroup,
        setOrderCategoryProductKey,
        setOrderCategory,
        setLoadingState,
        setDirty,
        upsertMetaData,
        setFaultyGetOrderIds,
        setAllocatedDiscounts
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
