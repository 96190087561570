import { createContext, useContext } from 'react';
import { OrderSearchType } from './dtos/OrderSearchType';
import { SearchContextValues } from './dtos/SearchContextValues';

export const defaultSearchValues: SearchContextValues = {
  activeSearchUpdated: false,
  activeSearchDifferent: false,
  attributes: [],
  addAttribute: () => [],
  cancelOrderStatus: '',
  changeTab: () => new Promise((r) => r(undefined)),
  editAttribute: () => undefined,
  editedAttribute: undefined,
  goToHistoryItem: () => undefined,
  initSearchTabs: () => undefined,
  loading: false,
  myOrdersLoaded: false,
  mandatoryAttributesPresent: () => false,
  newRecommendations: [],
  persistenceLoaded: false,
  persistenceColumnLoaded: false,
  rawResponse: undefined,
  removeAttribute: () => undefined,
  replaceAttribute: () => [],
  response: undefined,
  reset: () => undefined,
  search: () => undefined,
  searchHistory: [],
  columnHistory: [],
  myOrders: [],
  searchType: OrderSearchType.SIMPLE,
  setCancelOrderStatus: () => undefined,
  setSearchType: () => undefined,
  setCurrentSearchValueFromHistory: () => new Promise((r) => r(undefined)),
  setTableConfiguration: () => undefined,
  sortedAttributes: () => [],
  tab: OrderSearchType.SIMPLE,
  tabIndex: 0,
  tableConfiguration: undefined,
  triggerSearchForAttribute: () => undefined,
  triggerSearchWithAttributes: () => undefined,
  triggerLastSearch: () => undefined,
  triggerSearchHistoryUpdate: () => undefined,
  searchTabs: [],
  updateSearchHistory: () => undefined,
  updateColumnHistory: () => undefined,
  triggerAlert: () => undefined,
  updateMyOrders: () => undefined,
  triggerMultipleAlerts: () => undefined,
  upsertMetaData: () => undefined
};

export const SearchContext = createContext(defaultSearchValues);

export const useSearchContext = () => useContext(SearchContext);
