export enum PostageType {
  EP = 'EP',
  IV = 'IV',
  PWZ = 'PWZ',
  FS = 'FS',
  FSC = 'FS-C',
  FSM = 'FS-M',
  PL = 'PL',
  PC = 'PC',
  FV = 'FV',
  DV = 'DV',
  AS = 'AS',
  AFM = 'AFM'
}
