import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';
import { getUser } from 'common/context/auth/AuthProvider';
import { CustomerRolePermissions, CustomerRolesOnProduct } from 'order/common/dtos/CustomerRolePermissions';

export const requestCustomerRolePermissions = async (): Promise<CustomerRolePermissions> => {
  const user = getUser();
  try {
    const { data } = await sendRequest({
      method: 'get',
      url: `${BASE_URL}config/order/customerRolePermissions`,
      token: user._accessToken,
      extUserId: user.extUserId
    });

    if (data) {
      return data;
    }

    return { permissions: {} as CustomerRolesOnProduct };
  } catch (e: any) {
    console.error('Fetching customer role permissions failed', e);
    return { permissions: {} as CustomerRolesOnProduct };
  }
};
