import { useMemo } from 'react';
import useSWR from 'swr/immutable';
import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { requestPressDistributionDependentProductsBasedOnZkz } from '../services/pressDistributionDependentProducts';
import { PressDistributionDependentProduct } from '../dtos/PressDistributionDependentProduct';
export type UsePressDistributionDependentProductReturn = {
  isLoading: boolean;
  pressDistributionDependentProducts: PressDistributionDependentProduct[];
};
export const usePressDistributionDependentProducts = (zkz?: string, date?:Date): UsePressDistributionDependentProductReturn => {
  const { data: products = [], isValidating } = useSWR(zkz?.length ? ['pressDistributionDependentProducts', zkz] : [], async (_, zkz) =>
    requestPressDistributionDependentProductsBasedOnZkz(zkz).catch(() => [] as PressDistributionDependentProduct[])
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date ? date : new Date())), [products, date]);

  return {
    isLoading: isValidating,
    pressDistributionDependentProducts: filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber))
  };
};
