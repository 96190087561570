import { concatNonUndefined } from 'common/utils/helpers';
import React, { HTMLAttributes } from 'react';

export type Address = Partial<{
  names: (string | undefined)[];
  street: string;
  streetNumber: string;
  postCode: string;
  city: string;
  country: string;
}>;

export type AddressDisplayProps = HTMLAttributes<HTMLElement> & {
  address?: Address;
  'data-testid'?: string;
};

export const AddressDisplay: React.VFC<AddressDisplayProps> = ({ address, ...props }) => (
  <>
    {address?.names
      ?.filter((name) => !!name?.length)
      .map((name, i) => (
        <p key={i} data-testid={concatNonUndefined(props['data-testid'], '-name-', i)}>
          {name}
        </p>
      ))}
    {address?.street && (
      <p data-testid={concatNonUndefined(props['data-testid'], '-street')}>
        {address.street}
        {concatNonUndefined(' ', address.streetNumber)}
      </p>
    )}
    {(address?.postCode || address?.city) && (
      <p data-testid={concatNonUndefined(props['data-testid'], '-city')}>
        {address?.postCode}
        {concatNonUndefined(' ', address?.city)}
      </p>
    )}
    {address?.country && <p data-testid={concatNonUndefined(props['data-testid'], '-country')}>{address?.country}</p>}
  </>
);
