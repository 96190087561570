import { ReactElement } from 'react';
import { AnyTableCellProps } from './AnyTableCellProps';

export enum TableColumnType {
  COMMON = 'common',
  CUSTOM = 'custom',
  DATE_TIME = 'dateTime',
  NUMBER = 'number',
  ICON_BUTTON = 'iconButton'
}

export interface TableColumnData {
  cell: ReactElement<AnyTableCellProps>;
  contentAlignment?: string | undefined;
  disableMinWidth?: boolean;
  hidden?: boolean;
  hideable?: boolean;
  icon?: string;
  innerClassName?: string;
  movable?: boolean;
  name: string | null;
  property: string;
  sortable?: boolean;
  title: string;
  type: TableColumnType;
}

export declare enum TableColumnSortOrder {
  ASC = 1,
  DESC = -1
}

export enum TableLoadingState {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  LOADING = 'LOADING'
}
