import React, { memo, VFC } from 'react';
import classes from './highlighted-text.module.css';

export type HighlightedTextProps = {
  children?: string | number;
  highlighted?: string | number;
};

export const HighlightedText: VFC<HighlightedTextProps> = memo(({ children, highlighted }) => {
  if (typeof children === 'undefined') {
    return <mark className={classes.mark}>{highlighted}</mark>;
  }
  return !highlighted?.toString().trim().length ? (
    <>{children}</>
  ) : (
    <>
      {children
        ?.toString()
        .split(new RegExp(`(${highlighted})`, 'gi'))
        .map((s, i) =>
          s.toLowerCase() === highlighted.toString().toLowerCase() ? (
            <mark key={i} className={classes.mark}>
              {s}
            </mark>
          ) : (
            s
          )
        )}
    </>
  );
});

HighlightedText.displayName = 'HighlightedText';
