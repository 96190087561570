import React, { FC, useCallback, useState, useEffect } from 'react';
import { OrderTree, OrderTreeItem } from './OrderTree';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { requestOrderSearchData } from 'order/common/context/search/fetcher/requestOrderSearch';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { OrderSearchResultRepTDO } from 'order/common/services/OrderService';
import i18n from 'i18n';
import { Order } from '../../../common/context/order/dtos/Order';

export const OrderTreeContainer: FC<{ order: Order }> = ({ order }) => {
  const baseProducts = useBaseProducts(order?.productGroup, order?.orderDetail?.date);
  const getBaseProduct = useCallback((productNumber?: string) => baseProducts.find(({ productNumber: p }) => p === productNumber), [baseProducts]);
  const [orderTreeValues, setOrderTreeValues] = useState<Array<OrderTreeItem>>([]);
  useEffect(() => {
    let orderTreeProps: OrderTreeItem[] | undefined = [];
    if (order.hasOrderTree) {
      requestOrderSearchData(
        OrderSearchType.PARTIAL,
        [
          {
            key: OrderSearchKey.OrderId,
            value: order.orderId ?? ''
          }
        ],
        {}
      ).then((data: OrderSearchResultRepTDO) => {
        data?.orders?.forEach((item, itemIndex) => {
          orderTreeProps?.push({
            orderId: item.data?.orderNumber || '',
            orderType: item?.data?.orderType || '',
            newspaperNumber: item?.data?.orderLabel || item?.data?.newspaperNumber || '',
            description: item?.data?.orderLabel ?? '',
            product: item?.data?.product ? i18n.t('orderFilter.productList.types.' + item?.data?.product) || '' : '',
            quantity: item?.data?.totalQty || 0,
            totalAmount: item?.data?.totalAmountValue || 0,
            date: new Date(item?.data?.firstInductionDate ?? ''),
            state: item?.data?.state || '',
            children: []
          });
          item?.children?.forEach((childItem) => {
            orderTreeProps &&
              orderTreeProps[itemIndex]?.children?.push({
                orderId: childItem?.data?.orderNumber || '',
                orderType: childItem?.data?.orderType || '',
                newspaperNumber: childItem?.data?.orderLabel || childItem?.data?.newspaperNumber || '',
                description: item?.data?.orderLabel ?? '',
                product: childItem?.data?.product ? i18n.t('orderFilter.productList.types.' + childItem?.data?.product) || '' : '',
                quantity: childItem?.data?.totalQty || 0,
                totalAmount: childItem?.data?.totalAmountValue || 0,
                date: new Date(childItem?.data?.firstInductionDate ?? ''),
                state: childItem?.data?.state || '',
                children:
                  childItem?.children?.map((secondLevelChildItem) => ({
                    orderId: secondLevelChildItem?.data?.orderNumber || '',
                    orderType: secondLevelChildItem?.data?.orderType || '',
                    newspaperNumber: secondLevelChildItem?.data?.orderLabel || secondLevelChildItem?.data?.newspaperNumber || '',
                    description: secondLevelChildItem?.data?.orderLabel ?? '',
                    product: secondLevelChildItem?.data?.product
                      ? i18n.t('orderFilter.productList.types.' + secondLevelChildItem?.data?.product) || ''
                      : '',
                    quantity: secondLevelChildItem?.data?.totalQty || 0,
                    totalAmount: secondLevelChildItem?.data?.totalAmountValue || 0,
                    date: new Date(secondLevelChildItem?.data?.firstInductionDate ?? ''),
                    state: secondLevelChildItem?.data?.state || ''
                  })) || []
              });
          });
        });
        setOrderTreeValues(orderTreeProps ?? []);
      });
    }
    if (!order.hasOrderTree) {
      orderTreeProps = [
        {
          orderId: order?.orderId || '',
          product: getBaseProduct(order?.baseProduct?.productNumber)?.descriptionShort || '',
          quantity: order?.baseProduct?.quantity || 0,
          totalAmount: order?.orderPrice?.serviceClusters?.[0]?.amount?.value || 0,
          date: new Date(order?.orderDetail?.date ?? ''),
          state: order?.productionState || '',
          description: order?.orderLabel
        }
      ];
      setOrderTreeValues(orderTreeProps ?? []);
    }
  }, [order]);

  return <OrderTree orders={orderTreeValues} showZkz={order?.productGroup === 'PRESS_DISTRIBUTION'} hasChildren={order.hasOrderTree} />;
};
