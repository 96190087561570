// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderCopyStep1_ocstep1Header__RcyIJ,
.OrderCopyStep1_HinwesTextContainer__HRw0M {
  margin-bottom: 35px;
}
.OrderCopyStep1_HinwesTextContainer__HRw0M p {
  color: #878787;
  line-height: 20px;
}
.OrderCopyStep1_HinwesTextContainer__HRw0M h4 {
  color: #323232;
  font-size: 25px;
  font-weight: 600;
}
.OrderCopyStep1_HinwesTextContainer__HRw0M a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderCopy/common/components/OrderCopyStep1/OrderCopyStep1.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".ocstep1Header,\n.HinwesTextContainer {\n  margin-bottom: 35px;\n}\n.HinwesTextContainer p {\n  color: #878787;\n  line-height: 20px;\n}\n.HinwesTextContainer h4 {\n  color: #323232;\n  font-size: 25px;\n  font-weight: 600;\n}\n.HinwesTextContainer a {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ocstep1Header": `OrderCopyStep1_ocstep1Header__RcyIJ`,
	"HinwesTextContainer": `OrderCopyStep1_HinwesTextContainer__HRw0M`
};
export default ___CSS_LOADER_EXPORT___;
