import classNames from 'classnames';
import moment from 'moment';
import styles from 'order/orderChange/common/components/BillingTable/billing-table.module.css';
import { BillingTableItemHeadRowProps } from 'order/orderChange/common/components/BillingTable/BillingTableItemHeadRow';
import React, { VFC } from 'react';
import { Price } from './BillingTable';

export type BillingTableItemRowProps = BillingTableItemHeadRowProps & {
  item: Price;
};

export const BillingTableItemRow: VFC<BillingTableItemRowProps> = (props) => (
  <tr key={'row-data-child' + props.index}>
    <td className={`${styles.removeBorder} ${styles.productDescription}`}>{props.item.productDescription}</td>
    <td className={classNames(styles.removeBorder, styles.rightAlign)}>
      {props.item.qty?.toLocaleString(moment.locale(), { maximumFractionDigits: 3 })}
    </td>
    <td className={classNames(styles.removeBorder, styles.rightAlign)}>
      {props.item.unitAmount?.value?.toLocaleString(moment.locale(), {
        style: 'currency',
        currency: props.item.unitAmount?.currency,
        maximumFractionDigits: 3
      })}
    </td>
    {props.showSingleWeight && (
      <td className={classNames(styles.removeBorder, styles.rightAlign)}>
        {(props.item.unitWeight?.value || 0) * (props.item.unitWeight?.unit === 'kg' ? 1000 : 1)}
      </td>
    )}
    {props.showTotalWeight && (
      <td className={classNames(styles.removeBorder, styles.rightAlign)}>
        {props.item.totalWeight?.toLocaleString(moment.locale(), { maximumFractionDigits: 3 })}
      </td>
    )}
    {props.showBasicCharge && (
      <td className={classNames(styles.removeBorder, styles.rightAlign)}>
        {props.item.unitPaymentKilo?.value?.toLocaleString(moment.locale(), {
          style: 'currency',
          currency: props.item.unitPaymentKilo?.currency,
          maximumFractionDigits: 3
        })}
      </td>
    )}
    {props.showDiscount && <td className={styles.removeBorder}>{props.item.reduction}</td>}
    {props.showTariffZone && <td className={styles.removeBorder}>{props.item.tariffZone}</td>}
    {props.showZKZ && <td className={styles.removeBorder}>{props.item.zkz}</td>}
    {props.showDesignation && <td className={styles.removeBorder}>{props.item.designation}</td>}
    <td className={classNames(styles.removeBorder, styles.rightAlign)}>{props.item.taxRate} %</td>
    <td className={classNames(styles.removeBorder, styles.rightAlign)}>
      {props.item.amount?.value?.toLocaleString(moment.locale(), {
        style: 'currency',
        currency: props.item.amount?.currency,
        maximumFractionDigits: 3
      })}
    </td>
  </tr>
);
