import React, { FC } from 'react';

import { toFormattedTime } from 'common/formatting';
import i18n, { useTranslation } from 'i18n';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { Accordion, AccordionButtonOverview, AccordionButtonOverviewDisabled, AccordionItemOverview } from 'common/components/Accordion';
import { Order, sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import { OrderCustomerRoleEnum } from 'generated';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { calculateRanges } from 'order/common/utils/processIdRanges';
import { Destination } from 'order/common/context/order/dtos/GetOrder';
import { IdAssignmentReadOnlyTable } from '../../QtyAllocation/IdAssignmentReadOnlyTable';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { InternationalDestinationZipCodes } from 'order/productGroups/international/schema/internationalClusterSchema';
import { PressAdditionalServiceLetterProductName } from '../PressProductInfoSection/PressAdditionalServiceLetterProductName';
import style from 'App.module.css';
import { PalletsOverviewAccordian } from 'order/orderView/components/OrderView/PalletsOverview/PalletsOverviewAccordian/PalletsOverviewAccordian';

export type ProductInfoSectionProps = {
  value: Order;
};

export const InternationalProductInfoSection: FC<ProductInfoSectionProps> = ({ value }) => {
  const { t } = useTranslation('translation');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const language = useAuthContext().user.language;
  const date = value.orderDetail?.date;
  const { facilities } = useProductionFacilities(date);
  const facility = facilities.find(({ catalogId }) => catalogId === value.orderDetail?.productionPlantId);
  const contactForQuestions = value.parties.find((p) => p.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const { t: tOrderCreate } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { meta } = useOrderContext();

  const letterBaseProducts = value.letterBaseProducts;
  const sortedLetterBaseProducts = letterBaseProducts?.sort(
    (a, b) =>
      sortLetterTypeList.indexOf(a.baseProduct.description?.toLowerCase() || '') -
      sortLetterTypeList.indexOf(b.baseProduct.description?.toLowerCase() || '')
  );

  const hideEcoMix = value.productGroup === ProductGroup.BRIEF_INTERNATIONAL || value.productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL;

  // TODO provide proper enum for encoding
  const hasNonStandardEncoding =
    (value?.postage?.frankingIdEncoding as any) === 'HEX' ||
    (value?.postage?.frankingIdEncoding as any) === 'C40' ||
    (value?.postage?.frankingIdEncoding as any) === 'DEC';

  return (
    <>
      <OrderOverviewRow>
        <OrderOverviewWidget title="Basisprodukt">
          <p data-testid="base-product-testid">{meta?.intbaseProductVal}</p>
        </OrderOverviewWidget>

        <OrderOverviewWidget title={t('furtherProductFeatures')}>
          {value.variants
            ?.filter((data) => data !== LineItemVariant.NS && data !== LineItemVariant.NKD && data !== LineItemVariant.BKD)
            .map((variant) => (
              <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
            ))}
        </OrderOverviewWidget>
      </OrderOverviewRow>
      {sortedLetterBaseProducts?.map((product, index) => {
        const hasDestinations = hasNonStandardEncoding || calculateRanges((product.destinations as Destination[]) || []);
        return (
          <React.Fragment key={index}>
            <OrderOverviewRow noMargin={index !== 0}>
              <OrderOverviewWidget title={index === 0 ? tOrderCreate('transportService') : ''} width={2}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {tOrderCreate('transportService')} {index + 1}:
                      </td>
                      <td>{product.baseProduct.description}</td>
                    </tr>
                    <tr>
                      <td>{tOrderCreate('shipmentVolume')}</td>
                      <td>{product.baseProduct?.quantity?.toLocaleString(language)}</td>
                    </tr>
                    <tr>
                      <td>{tOrderCreate('totalWeight')}</td>
                      <td>{product.baseProduct?.grossWeight?.toLocaleString(language)}</td>
                    </tr>
                    {product.discounts && product.discounts.length > 0 ? (
                      <tr>
                        <td>{tOrderCreate('additionalLetterServicesForBaseProduct')}:</td>
                        <td data-testid={'further-product-characteristics-for-DV-td'}>
                          {product?.discounts?.map((d) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <p>
                                      {
                                        <PressAdditionalServiceLetterProductName
                                          baseProductNumber={product.baseProduct.productNumber}
                                          selectedDate={date}
                                          productNumber={d.productNumber}
                                        />
                                      }
                                    </p>
                                  </td>
                                  <td className={style.rightAlign}>
                                    <tr>
                                      <td>
                                        <p>{d.shipmentQuantity}</p>
                                      </td>
                                    </tr>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </OrderOverviewWidget>
            </OrderOverviewRow>
            <OrderOverviewRow noMargin>
              <OrderOverviewWidget width={2}>
                {hasDestinations && product.destinations?.length ? (
                  <Accordion allowMultiple={true}>
                    {(register) => (
                      <>
                        <AccordionItemOverview {...register}>
                          <AccordionButtonOverview disabled={!product.destinations?.length}>{tOrderCreate('deliveryPlan')}</AccordionButtonOverview>
                          <IdAssignmentReadOnlyTable
                            postageFrankingIdPrefix={value?.postage?.frankingIdPrefix || value?.postage?.machineCode}
                            frankingIdEncoding={value?.postage?.frankingIdEncoding}
                            header={'frankingIdPrefix'}
                            id={`${product.baseProduct.productNumber}${index}`}
                            ranges={product.destinations}
                            readOnly={true}
                            showShipmentQtyColumn
                            meta={{ language }}
                          />
                        </AccordionItemOverview>
                      </>
                    )}
                  </Accordion>
                ) : (
                  <AccordionButtonOverviewDisabled>{tOrderCreate('deliveryPlan')}</AccordionButtonOverviewDisabled>
                )}
              </OrderOverviewWidget>
            </OrderOverviewRow>
          </React.Fragment>
        );
      })}
      {value.productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL && !!value?.additionalQuantity && (
        <OrderOverviewRow>
          <OrderOverviewWidget title={tOrderCreate('additionallyPaidItems', { keyPrefix: 'step2Form' })}>
            <table>
              <tr>
                <td>
                  <p>{`${tOrderCreate('shipmentVolume')}`}</p>
                </td>
                <td>
                  <p>{value?.additionalQuantity}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
        </OrderOverviewRow>
      )}
      <OrderOverviewRow>
        <OrderPickupShippingDisplay
          data={{
            date: date,
            pickup: !!value.pickupAddress,
            time: toFormattedTime(date),
            submissionType: value?.orderDetail?.submissionType,
            facility,
            pickupAddress: value.pickupAddress,
            contactForQuestions: contactForQuestions
          }}
        />
        <OrderOverviewWidget title="Gebinde">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>{tOrderCreate('typeOfBundle')}</p>
                </td>
                <td>
                  <p>{value.packaging?.type && tCatalogValues('mailEntityType.display.' + value.packaging?.type)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('numberOfBundles')}</p>
                </td>
                <td>
                  <p>{value?.packaging?.quantity?.toLocaleString(language)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('totalGrossWeightInKg')}</p>
                </td>
                <td>
                  <p>{value?.packaging?.totalWeight?.toLocaleString(language)} kg</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('stackable')}</p>
                </td>
                <td>
                  <p>{value.packaging?.stackable ? i18n.t('yes') : i18n.t('no')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
        {value.packaging.type === 'PAL' && (
          <OrderOverviewWidget title={tOrderCreate('palletsPerIPZ', { keyPrefix: 'palletsInternational' })}>
            <table style={{ tableLayout: 'auto', width: 'auto' }}>
              <tbody>
                {!Number.isNaN(value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.PRIORTY)?.quantity) && (
                  <tr>
                    <td>
                      <p>{tOrderCreate('priority', { keyPrefix: 'palletsInternational' })}:</p>
                    </td>
                    <td style={{ paddingLeft: '30px' }}>
                      <p>{value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.PRIORTY)?.quantity}</p>
                    </td>
                  </tr>
                )}
                {!hideEcoMix && !Number.isNaN(value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.ECONOMY)?.quantity) && (
                  <tr>
                    <td>
                      <p>{tOrderCreate('economy', { keyPrefix: 'palletsInternational' })}:</p>
                    </td>
                    <td style={{ paddingLeft: '30px' }}>
                      <p>{value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.ECONOMY)?.quantity}</p>
                    </td>
                  </tr>
                )}
                {!hideEcoMix && !Number.isNaN(value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.MIXED)?.quantity) && (
                  <tr>
                    <td>
                      <p>{tOrderCreate('mixed', { keyPrefix: 'palletsInternational' })}:</p>
                    </td>
                    <td style={{ paddingLeft: '30px' }}>
                      <p>{value?.destinations?.find((a) => a.zipCode === InternationalDestinationZipCodes.MIXED)?.quantity}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </OrderOverviewWidget>
        )}
      </OrderOverviewRow>
      {value?.destinations?.length ? (
        <div style={{ borderBottom: '1px solid #e6e6e6' }}>
          <PalletsOverviewAccordian destinations={value?.destinations || []} id="palletOverview" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
