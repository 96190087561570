import { createContext, useContext } from 'react';
import { AuthenticationManager } from '@gkuis/gkp-authentication/dist/AuthenticationManager';

import { AuthUser, defaultAuthUserData } from 'common/dtos/auth';
import { UserRights } from 'common/dtos/userRights';
import { AuthManager } from './authManager';
import { Whitelisting } from 'common/dtos/Whitelisting';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { WhitelistMethod } from 'order/common/dtos/OrderMode';

export interface AuthValues {
  loading: boolean;
  permissions: UserRights[];
  user: AuthUser;
  extUserId: string;
  whitelisting: Whitelisting | undefined;

  hasPermission(permission: UserRights): boolean;
  checkWhitelistingKey(orderCategoryProductKey: OrderCategoryProductKey, mode: WhitelistMethod): boolean;
  checkProductionStateWhitelistingChange(productionState: ProductionState): boolean;
  checkProductionStateWhitelistingGet(productionState: ProductionState): boolean;
  checkProductionStateWhitelistingCopy(productionState: ProductionState): boolean;
  checkProductionStateWhitelistingDelete(productionState: ProductionState): boolean;
  checkWhitelistingWithProductionState(key: OrderCategoryProductKey, productionState: ProductionState, method: WhitelistMethod): boolean;

  logout(): Promise<void>;

  addEventHandlers(authManager: AuthenticationManager | AuthManager): void;

  removeEventHandlers(authManager: AuthenticationManager | AuthManager): void;

  resetUser(): void;

  setExtUserId(extUserId: string | undefined): void;

  setAuthMgr(authManager: AuthenticationManager | AuthManager): void;

  // only exposed for test purposes
  triggerTestActions(authManager: AuthenticationManager | AuthManager): void;
}

export const defaultAuthValues: AuthValues = {
  addEventHandlers: () => null,
  hasPermission: () => false,
  checkWhitelistingKey: () => false,
  checkProductionStateWhitelistingChange: () => false,
  checkProductionStateWhitelistingGet: () => false,
  checkProductionStateWhitelistingCopy: () => false,
  checkProductionStateWhitelistingDelete: () => false,
  checkWhitelistingWithProductionState: () => false,
  logout: () => Promise.resolve(),
  loading: false,
  permissions: [],
  removeEventHandlers: () => null,
  resetUser: () => null,
  setAuthMgr: () => null,
  setExtUserId: () => null,
  user: defaultAuthUserData,
  whitelisting: undefined,
  // only used for test purposes
  triggerTestActions: () => null,
  extUserId: ''
};

export const AuthContext = createContext(defaultAuthValues);

export const useAuthContext = () => useContext(AuthContext);
