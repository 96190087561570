import { DHLRadioButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import React, { AllHTMLAttributes, ReactNode, VFC } from 'react';
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip';
import styles from './AMDHLRadioButtonCard.module.css';

export type AMDHLRadioButtonCardProps = Pick<AllHTMLAttributes<HTMLInputElement>, 'disabled'> & {
  title: string;
  children?: ReactNode | ReactNode[];
  moreLink: string;
  isSelected: boolean;
  onSelect: () => void;
  tooltip?: TooltipProps;
};

export const AMDHLRadioButtonCard: VFC<AMDHLRadioButtonCardProps> = ({ children, isSelected, onSelect, title, moreLink, disabled, tooltip }) => (
  <button className={styles.card} onClick={onSelect} disabled={disabled}>
    <div className={classNames(styles.row)}>
      <div className={classNames(tooltip ? styles.noOverFlow : styles.truncate)}>
        <div className={classNames(styles.tooltipTileFlexer)}>
          <h3 className={classNames(styles.title)}>{title}</h3>
          {tooltip && (
            <Tooltip placement={tooltip.placement} content={tooltip.content}>
              <i className={classNames(styles.radioButtonCardInfoForTooltip, 'icon-alert-info')}></i>
            </Tooltip>
          )}
        </div>
      </div>
      <DHLRadioButton
        size="slim"
        name={'cardRadioButton_' + title}
        label={''}
        selectValue={title}
        value={isSelected ? title : undefined}
        onChange={onSelect}
        className="cardRadioButton"
        disabled={disabled}
      />
    </div>
    <p className={classNames(styles.text)}>{children}</p>
    <a
      className={classNames(styles.moreLink)}
      href={moreLink}
      target="_blank"
      data-testid="readMoreTestid"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      Mehr...
    </a>
  </button>
);
