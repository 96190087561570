import React, { useEffect, useRef, useState } from 'react';
import { Pagination } from 'common/components/Paginator/Pagination';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { Filter } from 'common/components/Table/dtos/Filter';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { Sort } from 'common/components/Table/dtos/Sort';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { Table } from 'common/components/Table/Table';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { getCurrentSlice, moveColumnLeft, moveColumnRight, rowContainsFilter, sortRows } from 'order/common/context/table/utils/table';
import { useTranslation } from 'react-i18next';
import styles from '../OrderMergeTableContainer/OrderMergeTableContainer.module.css';
import { Age } from 'order/common/context/order/dtos/AllocatedOrder';
import { DuplicateOrders, OrderMergeAlloctions } from '../../schema/OrderMergeSchema';
import { useFormContext, useWatch } from 'react-hook-form';
import { removeDuplicatesFromArrayForDuplicateOrders } from '../../utils/removeDuplicatesFromArrayForDuplicateOrders';

const defaultPageSize = 5;

interface Props {
  columnConfig: ColumnConfig[];
  change?: boolean;
  showActionBar?: boolean;
  hideTableOptions?: boolean;
}

export const OrderMergeVariantListTable = (props: Props): JSX.Element | null => {
  const {
    allocationsOrders,
    allocationsCount,
    allocationsTotal,
    allocationsLoading,
    displayAllAllocations,
    setDisplayAllAllocations,
    order,
    upsertMetaData
  } = useOrderContext();
  const [rows, setRows] = useState<OrderMergeAlloctions[]>([]);
  const [currentSlice, setCurrentSlice] = useState<OrderMergeAlloctions[]>([]);
  const filter = useRef<Filter>({ name: null, value: null });
  const sort = useRef<Sort>({ name: null, order: null });
  const sorting = useRef<Sort>({ name: null, order: SortOrder.DESC, type: ColumnType.CUSTOM_CELL });
  const page = useRef<number>(1);
  const pageSize = useRef<number>(defaultPageSize);
  const filteredRows = useRef<OrderMergeAlloctions[]>([]);
  const [columns, setColumnConfig] = useState<ColumnConfig[]>(props.columnConfig);
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const language = useAuthContext().user.language;
  const { t: om } = useTranslation('', { keyPrefix: 'orderMerge' });
  const { setValue, control } = useFormContext();

  const [selectedSearchedOrderIds] = useWatch({
    control,
    name: ['selectedSearchedOrderIds']
  });

  const filterRows = (allocationsOrders: OrderMergeAlloctions[]) => {
    let filtered = [...allocationsOrders];
    const { name, value } = filter.current;
    if (name && value) {
      filtered = filtered.filter((r) => rowContainsFilter(name, value, r));
    }
    return filtered;
  };

  // Manipulation of allocated Orders to show the variants in the table
  const transformedOrderMergeVariants = useRef<OrderMergeAlloctions[]>([]);
  useEffect(() => {
    transformedOrderMergeVariants.current = [];

    // For adding primary order variants to the table
    order?.mergeOrderVariants?.forEach((v, key) => {
      if (transformedOrderMergeVariants.current.every((fd) => fd.id !== `${v.orderNumber}-${key}-${v.variantLabel}`)) {
        transformedOrderMergeVariants.current.push({
          data: {
            orderNumber: v.orderNumber,
            orderLabel: v.orderLabel,
            variantLabel: v.variantLabel,
            variantQuantity: v.variantQuantity
          },
          lastChangedOn: order.lastChangedOn,
          age: Age.OLD,
          newlyAdded: false,
          highlight: false,
          editable: false,
          orderNumber: v.orderNumber,
          id: `${v.orderNumber}-${key}-${v.variantLabel}`
        });
      }
    });

    // For adding the variants in the table other than the primary order
    allocationsOrders.forEach((d) => {
      d.data.mergeOrderVariants?.forEach((v, key) => {
        if (transformedOrderMergeVariants.current.every((fd) => fd.id !== `${v.orderNumber}-${key}-${v.variantLabel}`)) {
          transformedOrderMergeVariants.current.push({
            data: {
              orderNumber: v.orderNumber,
              orderLabel: v.orderLabel,
              variantLabel: v.variantLabel,
              variantQuantity: v.variantQuantity
            },
            lastChangedOn: d.data.lastChangedOn,
            age: d.data.age,
            newlyAdded: d.newlyAdded,
            highlight: d.highlight,
            editable: d.editable,
            orderNumber: d.data.orderNumber,
            id: `${v.orderNumber}-${key}-${v.variantLabel}`
          });
        }
      });
    });
    // For Duplicate Orders to be sent in the payload
    const allocatedOrdersForMergeWithoutParent = transformedOrderMergeVariants.current.filter((o) => o.data?.orderNumber !== order?.orderId);
    const duplicateOrders: DuplicateOrders[] = allocatedOrdersForMergeWithoutParent.flatMap((ao) => {
      return {
        lastChangedOn: ao.lastChangedOn,
        orderNumber: ao.data?.orderNumber
      };
    });

    const uniqueDuplicateOrders = removeDuplicatesFromArrayForDuplicateOrders(duplicateOrders);
    const uniqueSelectedOrders = uniqueDuplicateOrders.filter((u) => u.orderNumber);
    setValue('selectedSearchedOrderIds', uniqueSelectedOrders);
    upsertMetaData('duplicateOrdersForOrderMerge', uniqueDuplicateOrders);
  }, [allocationsOrders, order]);

  useEffect(() => {
    page.current = 1;
    setRows(transformedOrderMergeVariants.current || []);
    const filtered = filterRows(transformedOrderMergeVariants.current);
    const sortedRows = sortRows(sorting.current.name, filtered || [], sorting.current.order);
    const curSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    filteredRows.current = sortedRows || [];
    setCurrentSlice(curSlice);
  }, [transformedOrderMergeVariants.current, allocationsOrders]);

  const resetSortAndFilters = () => {
    filteredRows.current = rows;
    const curSlice = getCurrentSlice(filteredRows.current, page.current, pageSize.current);
    setCurrentSlice(curSlice);
  };

  const setFilter = (name: string | null, value: string | null) => {
    filter.current = { name, value };
    const filtered = filterRows(rows);
    filteredRows.current = filtered;
    page.current = 1;
    const currentSlice = getCurrentSlice(filtered, 1, pageSize.current);
    setCurrentSlice(currentSlice);
  };

  const setSorting = (name: string, order: SortOrder, type: ColumnType) => {
    const filtered = [...filteredRows.current];
    const sortedRows = sortRows(name, filtered, order);
    const currentSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    sort.current = { name, order, type };
    sorting.current = { name, order, type };
    filteredRows.current = sortedRows;
    setCurrentSlice(currentSlice);
  };

  const setColumnMove = (name: string, direction: 'left' | 'right') => {
    let columnConf = [...columns];
    if (direction === 'left') {
      columnConf = moveColumnLeft(columnConf, name);
    } else if (direction === 'right') {
      columnConf = moveColumnRight(columnConf, name);
    }

    setColumnConfig(columnConf);
  };

  const handlePageSizeChange = (size: number) => {
    let newSlice = [...filteredRows.current];

    page.current = 1;

    if (size > 0) {
      newSlice = getCurrentSlice(filteredRows.current, page.current, size);
    }

    pageSize.current = size;
    setDisplayAllAllocations(false);
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number) => {
    const newSlice = getCurrentSlice(filteredRows.current, p, pageSize.current);

    page.current = p;
    setDisplayAllAllocations(false);
    setCurrentSlice(newSlice);
  };

  return allocationsOrders?.length || allocationsTotal > 0 ? (
    <div className={styles.searchTable}>
      <div data-testid={'variant-list-heading'} className={styles.message}>
        {om('variantTableHeading')}
      </div>
      <div className={styles.tableCentering}>
        <div className={styles.wrapper}>
          <Table
            columnConfig={columns}
            currentSlice={currentSlice || []}
            loadingState={allocationsLoading ? TableLoadingState.LOADING : TableLoadingState.LOADED}
            loadingText={`${t('result.loaded')}`}
            t={t}
            filter={filter.current}
            sort={sort.current}
            onResetSortAndFilters={resetSortAndFilters}
            onFilterChange={setFilter}
            onSortChange={setSorting}
            onMoveChange={setColumnMove}
            highlightedRows={true}
            language={language}
            withTableOptions={!props.hideTableOptions}
            allChildrenExpanded={displayAllAllocations}
          />
          <div className={styles.actionsBar}>
            <Pagination
              loadingState={TableLoadingState.LOADED}
              totalItems={filteredRows.current.length}
              defaultPageSize={defaultPageSize}
              currentPage={page.current}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
