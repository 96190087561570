// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__L8tMi {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite Spinner_spin__fJ3LE;
}
.Spinner_spinnerWhite__m2hDW {
  color: #fff;
}
.Spinner_spinnerRed__94AK7 {
  color: #d40511;
}
.Spinner_spinner_xs__hBxC3 {
  width: 0.8rem;
  height: 0.8rem;
  border-width: 0.12em;
}
.Spinner_spinner_sm__0On6A {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
}
.Spinner_spinner_md__7Jmyh {
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.175em;
}
.Spinner_spinner_lg__R57yI {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}
@keyframes Spinner_spin__fJ3LE {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Spinner_spinnerWrapper__BE-S0 {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  justify-content: space-around;
}

.Spinner_message__nONOM {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,iCAAiC;EACjC,+BAA+B;EAC/B,kBAAkB;EAClB,oDAAqC;AACvC;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".spinner {\n  display: inline-block;\n  width: 2rem;\n  height: 2rem;\n  vertical-align: middle;\n  border: 0.25em solid currentColor;\n  border-right-color: transparent;\n  border-radius: 50%;\n  animation: 0.75s linear infinite spin;\n}\n.spinnerWhite {\n  color: #fff;\n}\n.spinnerRed {\n  color: #d40511;\n}\n.spinner_xs {\n  width: 0.8rem;\n  height: 0.8rem;\n  border-width: 0.12em;\n}\n.spinner_sm {\n  width: 1rem;\n  height: 1rem;\n  border-width: 0.15em;\n}\n.spinner_md {\n  width: 1.4rem;\n  height: 1.4rem;\n  border-width: 0.175em;\n}\n.spinner_lg {\n  width: 1.5rem;\n  height: 1.5rem;\n  border-width: 0.2em;\n}\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.spinnerWrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  text-align: center;\n  position: relative;\n  justify-content: space-around;\n}\n\n.message {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__L8tMi`,
	"spin": `Spinner_spin__fJ3LE`,
	"spinnerWhite": `Spinner_spinnerWhite__m2hDW`,
	"spinnerRed": `Spinner_spinnerRed__94AK7`,
	"spinner_xs": `Spinner_spinner_xs__hBxC3`,
	"spinner_sm": `Spinner_spinner_sm__0On6A`,
	"spinner_md": `Spinner_spinner_md__7Jmyh`,
	"spinner_lg": `Spinner_spinner_lg__R57yI`,
	"spinnerWrapper": `Spinner_spinnerWrapper__BE-S0`,
	"message": `Spinner_message__nONOM`
};
export default ___CSS_LOADER_EXPORT___;
