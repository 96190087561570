import { QtyAllocation } from '../dtos/Order';
import { deepClone } from 'common/utils/deepClone';
import { ProductGroup } from '../../../dtos/ProductGroup';

export function preProcessExistingQtyAllocations(
  qtyAllocation: QtyAllocation[], // with potentially corrupted (ZA) (initial loaded)
  origReferencedQtyAllocations: QtyAllocation[], // with EA content (dependent-loaded)
  productGroup?: ProductGroup
): QtyAllocation[] {
  let curQtyAllocation: QtyAllocation[] | null = qtyAllocation;
  const clonedQtyAllocations = deepClone(curQtyAllocation);
  curQtyAllocation = null;
  const newQtyAllocations: QtyAllocation[] = [];
  let qtyAllocationsByFormat: Record<string, QtyAllocation> = {};

  clonedQtyAllocations.forEach((q) => {
    const origQtyAllocation = origReferencedQtyAllocations.find((o) => o.format === q.format);
    if (q.format !== null) {
      const exists = qtyAllocationsByFormat[q.format];
      const initialQty = origQtyAllocation?.quantity ?? 0;
      const quantity = (exists?.quantity ?? 0) + q.quantity;
      const initialIdsQty = exists?.containsIds
        ? q.containsIds
          ? (exists.initialIdsQty || 0) + q.quantity
          : exists.initialIdsQty || 0
        : q.containsIds
        ? q.quantity
        : 0;
      const idsQty = exists?.containsIds ? exists.quantity : q.containsIds ? q.quantity : 0;
      const combinedCounter = (exists?.combinedCounter ?? 0) + 1;
      // const reducible = initialQty > initialIdsQty;
      const reducible = true;
      const containsIds = (exists && exists.containsIds && exists.quantity > 0) || (q.containsIds && q.quantity > 0);
      const partialServiceItem = exists && exists.containsIds ? exists.partialServiceItem : q.partialServiceItem;
      const includeTotalQty = initialQty === quantity;
      const multipleContainsIds = exists?.multipleContainsIds ? true : exists?.containsIds && q.containsIds;

      const collectedQuantities = exists?.collectedQuantities || [];
      collectedQuantities.push({
        ...q,
        initialQty: q.quantity,
        fromOriginal: undefined,
        multipleContainsIds: undefined,
        collectedQuantities: undefined
      });

      qtyAllocationsByFormat[q.format] = {
        ...q,
        ...(exists && { ...qtyAllocationsByFormat[q.format] }),
        containsIds,
        idsQty,
        flexOption: exists?.flexOption || q.flexOption,
        includeTotalQty,
        initialQty,
        originalAssignedQty: quantity,
        quantity,
        initialIdsQty,
        combinedCounter,
        reducible,
        refShipmentIdZA: exists?.refShipmentIdZA ?? q.refShipmentIdZA,
        idItem: exists?.idItem ?? q.idItem,
        flexItem: exists?.flexItem ?? q.flexItem,
        partialServiceItem,
        multipleContainsIds,
        collectedQuantities
      };
    }
  });

  Object.keys(qtyAllocationsByFormat).forEach((f) => {
    qtyAllocationsByFormat[f].ranges?.forEach((r) => {
      if (r.zip === 'Q') {
        r.zip = 'DE';
      }
    });
    newQtyAllocations.push(qtyAllocationsByFormat[f]);
  });

  // Add leftovers from EA that are not in the ZA
  origReferencedQtyAllocations.forEach((o) => {
    if (!newQtyAllocations.find((n) => n.format !== null && o.format !== null && n.format === o.format)) {
      newQtyAllocations.push({
        ...o,
        quantity: productGroup === ProductGroup.COLLECTION ? o.quantity : 0,
        initialQty: o.quantity,
        idsQty: 0,
        initialIdsQty: 0,
        includeTotalQty: false,
        containsIds: false,
        flexOption: false,
        fromOriginal: true,
        reducible: true
      });
    }
  });

  qtyAllocationsByFormat = {};

  return newQtyAllocations;
}
