export const enum OrderTypes {
  Teileinlieferung = 'TE',
  Einlieferungsauftrag = 'EA',
  Zusatzauftrag = 'ZA',
  Jobgruppe = 'JS'
}

export const orderTypeOptions = [
  {
    id: OrderTypes.Einlieferungsauftrag,
    name: 'Einlieferungsauftrag'
  },
  {
    id: OrderTypes.Zusatzauftrag,
    name: 'Zusatzauftrag'
  }
];
