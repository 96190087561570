import { extractObjectData } from '@gkuis/gkp-base-widgets/dist/lib';

import i18n from 'i18n';
import { Leaves2 } from 'common/utils/nestedObjectsTypes';
import { OrderReplyTDO } from '../../../common/services/OrderService';
import { csvCols } from './csvCols';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';

type OrderReplyTDOUnnestedKeys = Leaves2<OrderReplyTDO>;
type CsvColsVirtualKeys = keyof Pick<typeof csvCols, 'jobGroup' | 'partialGroup'>;

/**
 * Transfers a search result value to string.
 *
 * @param rowData data row to transfer
 * @param csvKey key of the column to display
 * @return {string} string representation of value
 */
export const valueToString = (rowData: OrderReplyTDO, csvKey: OrderReplyTDOUnnestedKeys | CsvColsVirtualKeys): string => {
  const value = extractObjectData(rowData, csvKey);

  // handle virtual columns -> nothing to do at this moment

  // handle cols by type
  switch (typeof value) {
    case 'string':
      // handle special cols
      switch (csvKey) {
        case 'state':
          return i18n.t('orderStatus.status.' + value);

        case 'product':
          return i18n.t('orderFilter.productList.types.' + value);

        case 'frankierung':
          return i18n.t('orderFilter.postageType.display.' + value);

        default:
          break;
      }
      return value;

    case 'number':
      // handle special cols
      let isCurrency = false;
      let currencySymbol;
      switch (csvKey) {
        case 'totalAmountValue':
          if (rowData.orderType === OrderTypes.Zusatzauftrag) {
            // in case of ZA the value is invalid -> discard it
            return '';
          }
          isCurrency = true;
          currencySymbol = rowData?.['totalAmountCurrency'];
          break;

        case 'totalQty':
          if (rowData.orderType === OrderTypes.Zusatzauftrag) {
            // in case of ZA the value is invalid -> discard it
            return '';
          }
          break;

        default:
          break;
      }
      return value.toLocaleString('de-DE', {
        useGrouping: true,
        currency: currencySymbol,
        minimumFractionDigits: isCurrency ? 2 : 0
      });
    default:
      break;
  }

  return value;
};
