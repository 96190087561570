import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccordionButton } from 'common/components/Accordion';
import { ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import { OrderCustomerRoleEnum } from 'generated';
import { AddPartnerButton } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/AddPartnerButton';
import { DeletePartnerButton } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/DeletePartnerButton';
import { OtherPartnerInput } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/OtherPartnerInput';
import { OtherPartnerSchema } from './otherPartnerSchema';
import classes from './other-partners.module.css';
import { OrderMode } from '../../../../../common/dtos/OrderMode';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';

type AMDHLOtherPartnersProps<T extends FieldValues> = SFCProps<T, OtherPartnerSchema[] | undefined> & {
  allowedRolesToDelete: OrderCustomerRoleEnum[];
  roles: OrderCustomerRoleEnum[];
  orderMode?: OrderMode;
  onOpen?: () => void;
  disabled?: boolean;
};

export const AMDHLOtherPartners = <T extends FieldValues>(props: AMDHLOtherPartnersProps<T>): ReactElement => {
  const [loaded, setLoaded] = useState(false);
  const { setValue, control, trigger } = useFormContext<ObjectContaining<OtherPartnerSchema[]>>();
  const { fields, append, remove, update } = useFieldArray<ObjectContaining<OtherPartnerSchema[]>, string>({ control, name: props.name });
  const unusedRoles = props.roles.filter((role) => fields.every((roleData) => roleData.role !== role));
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { order } = useOrderContext();
  const initiallyUsedRoles: OrderCustomerRoleEnum[] =
    props.orderMode === OrderMode.CHANGE ? order?.parties?.map((party) => party.role as OrderCustomerRoleEnum) || [] : [];
  // useFieldArray alone is not sufficient for rerender (?!)
  const values = useWatch({
    control,
    name: props.name
  });
  let watchedFields: { id: string | number; customerId: string; role: OrderCustomerRoleEnum }[] = [];

  if (values) {
    watchedFields = fields.map(({ id }, index) => ({
      id,
      ...(values as any)[index]
    }));
  }
  const checkPermissionForRemove = (dataRole: OrderCustomerRoleEnum): boolean => {
    return initiallyUsedRoles?.includes(dataRole) && !props.allowedRolesToDelete.includes(dataRole);
  };

  useEffect(() => {
    if (watchedFields.length < 1) {
      setValue(props.name as string, [{} as OtherPartnerSchema]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields]);

  useEffect(() => {
    if (watchedFields.length && !loaded) {
      setLoaded(true);
      const filledDataExists = watchedFields.find((f) => !!f.role || !!f.customerId);
      if (filledDataExists) {
        props.onOpen && props.onOpen();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields, loaded]);

  return (
    <>
      <AccordionButton data-testid={'additional-partners'}>{oc('additionalPartnersOptional')}</AccordionButton>
      {watchedFields.map((data, index) => {
        const prefix = `${props.name}.${index}` as `${string}.${number}`;
        return (
          <div key={data.id} className={classes.row} data-testid={'xxxxx'}>
            <OtherPartnerInput
              prefix={prefix}
              data={data}
              availableRoles={unusedRoles}
              disabled={props.disabled || checkPermissionForRemove(data.role)}
            />
            <aside>
              <DeletePartnerButton
                fields={fields}
                disabled={props.disabled || checkPermissionForRemove(data.role)}
                onClick={() => {
                  trigger(`${prefix}.role`);
                  if (fields.length > 1) {
                    remove(index);
                  } else {
                    update(index, { role: '' as OrderCustomerRoleEnum, customerId: '' });
                  }
                }}
                customerId={data.customerId}
              />
            </aside>
          </div>
        );
      })}
      <AddPartnerButton
        fields={fields}
        onClick={() => {
          append({ role: '' as OrderCustomerRoleEnum, customerId: '' });
        }}
        id={fields.length - 1}
        customerId={watchedFields[watchedFields.length - 1]?.customerId}
        roles={props.roles}
      />
    </>
  );
};
