import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import catalogValuesDE from './de/catalogValues.json';
import catalogValuesEN from './en/catalogValues.json';
import orderCreateDE from './de/orderCreate.json';
import orderCreateEN from './en/orderCreate.json';
import transDE from './de/translation.json';
import transEN from './en/translation.json';

i18n.use(initReactI18next).init({
  ns: ['translation', 'orderCreate'],
  defaultNS: 'translation',
  lng: localStorage.getItem('lang') || 'de',
  resources: {
    en: {
      translation: transEN,
      orderCreate: orderCreateEN,
      catalogValues: catalogValuesEN
    },
    de: {
      translation: transDE,
      orderCreate: orderCreateDE,
      catalogValues: catalogValuesDE
    }
  }
});

moment.locale(i18n.language);

export { useTranslation } from 'react-i18next';

export default i18n;
