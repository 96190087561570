import useSWR from 'swr/immutable';
import { isEntityWithDatesValid } from 'common/utils/helpers';
import { PressDistributionSupplementProduct } from '../dtos/PressDistributionSupplementProduct';
import { requestPressDistributionSupplementProductsBasedOnZkz } from '../services/pressDistributionSupplementProducts';

export const usePressDistributionSupplementProducts = (
  zkz?: string,
  productNumbers?: string[],
  validityPoint?: string
): PressDistributionSupplementProduct[] => {
  const { data: products = [] } = useSWR(
    zkz?.length ? ['pressDistributionSupplementProducts', zkz, productNumbers, validityPoint] : [],
    async (_, zkz, productNumbers, validityPoint) =>
      requestPressDistributionSupplementProductsBasedOnZkz(zkz, productNumbers, validityPoint).catch(() => [] as PressDistributionSupplementProduct[])
  );

  return products;
};
