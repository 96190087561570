import { flatten, groupBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { ProductClusterCombination } from 'order/productGroups/common/services/ProductClusterCombinationService';

export const computeAvailableCombinations = (
  combinations: ProductClusterCombination[],
  productNumbers: string[],
  selectedDate = new Date()
): ProductClusterCombination[] => {
  const combinationsWithValidDates = combinations.filter((e) => isEntityWithDatesValid(e, selectedDate));

  const grouped = groupBy(combinationsWithValidDates, 'productGroupNumber');

  const combinationMatrix = Object.entries(grouped)
    .filter(([, combinations]) =>
      productNumbers.every((productNumber) => !productNumber || combinations.some((combination) => combination.productNumber === productNumber))
    )
    .map(([, combination]) => combination);

  return flatten(combinationMatrix);
};
