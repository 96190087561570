import { getUser } from 'common/context/auth/AuthProvider';
import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';
import { PressDistributionDependentProduct } from '../dtos/PressDistributionDependentProduct';

export const requestPressDistributionDependentProductsBasedOnZkz = async (zkz?: string): Promise<PressDistributionDependentProduct[]> => {
  const user = getUser();
  try {
    const { data } = await sendRequest({
      method: 'get',
      url: `${BASE_URL}masterdata/pressdistribution/dependentProducts`,
      params: {
        zkz
      },
      token: user._accessToken,
      extUserId: user.extUserId
    });

    if (data) {
      return data;
    }

    return [];
  } catch (e: any) {
    console.error('Fetching press distribution dependent products failed', e);
    return [];
  }
};
