import React, { FC, useState } from 'react';
import classNames from 'classnames';
import styles from './AlertDescription.module.css';
import i18n, { useTranslation } from 'i18n';
import { translationEnglish } from '../Translations/englishTranslation';
import { translationGerman } from '../Translations/germanTranslation';

export type AlertDescription = {
  children?: string;
  limit: number;
};

i18n.addResourceBundle('de', 'alert', translationGerman);
i18n.addResourceBundle('en', 'alert', translationEnglish);

export const AlertDescription: FC<AlertDescription> = ({ children, limit }) => {
  const { t } = useTranslation('alert');
  const [showAll, setShowAll] = useState(false);
  const readMore = () => setShowAll(true);
  const readLess = () => setShowAll(false);
  const slicedString = children?.toString()?.substring(0, limit) + '... ';
  if ((children?.toString().length || 0) <= limit) {
    return <div data-testid={'showLessContainer'}>{children}</div>;
  }
  if (showAll) {
    return (
      <>
        <div data-testid={'showAllContainer'}>{children}</div>
        <button data-testid="readLessBtn" className={classNames(styles.moreless)} onClick={readLess}>
          {t('showLessLabel')}
        </button>
      </>
    );
  }
  return (
    <div>
      <div data-testid={'sliceStringContainer'}>{slicedString}</div>
      <button data-testid="readMoreBtn" className={classNames(styles.moreless)} onClick={readMore}>
        {t('showMoreLabel')}
      </button>
    </div>
  );
};
