import React from 'react';
import { Button, ButtonProps } from 'common/components/Button';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useTranslation } from 'i18n';
import { FormRow } from 'common/components/FormRow';
import { Controller, useFormContext } from 'react-hook-form';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import styles from './TakeOriginatorAsPayer.module.css';
import classNames from 'classnames';

export interface TakeOriginatorAsPayerProps {
  hasTooltip?: boolean;
}

export const TakeOriginatorAsPayer = ({ hasTooltip }: TakeOriginatorAsPayerProps) => {
  const { t } = useTranslation('orderCreate');
  const { getValues, setValue, clearErrors } = useFormContext();

  return (
    <>
      <FormRow mode="checkboxes">
        <>
          <Controller
            render={({ field: { ref, ...field } }) => (
              <Button
                label={t('payer.takeFromOriginator.label')}
                {...field}
                onClick={() => {
                  setValue('payer', getValues('originator'));
                  clearErrors(['payer']);
                }}
                sizing="sm"
                disabled={false}
                dataTestId={'takeOriginatorAsPayerButton'}
              />
            )}
            name="takeOriginatorAsPayer"
            data-testid={'takeOriginatorAsPayer'}
          />
          {hasTooltip && (
            <div className={classNames(styles.tooptipContainer)}>
              <Tooltip placement={'right'} content={t('payer.takeFromOriginator.tooltip')}>
                <i className={classNames('icon-alert-info', styles.tooltipIcon)}></i>
              </Tooltip>
            </div>
          )}
        </>
      </FormRow>
    </>
  );
};
