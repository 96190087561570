import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import dropdownStyles from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals.module.css';
import { Menu } from 'common/components/ContextMenu';
import contextClasses from 'common/components/ContextMenu/context-menu.module.css';
import { Input } from 'common/components/Input';
import { useBoolean } from 'common/hooks/useBoolean';
import { concatNonUndefined } from 'common/utils/helpers';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import React, { MouseEventHandler, ReactElement, useEffect, useRef } from 'react';
import { FieldPath, FieldValues, PathValue, UnpackNestedValue, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './customer-input.module.css';
import { useCustomerRecommendations } from 'order/productGroups/common/services/useCustomerRecommendations';
import { validateCustomerNumber } from 'common/utils/helpers';

export type CustomerRole =
  | OrderSearchKey.Beneficiary
  | OrderSearchKey.Payer
  | OrderSearchKey.Submitter
  | OrderSearchKey.Originator
  | OrderSearchKey.Producer;

export type CustomerInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  onDelete?: MouseEventHandler;
  disabled?: boolean;
  readOnly?: boolean;
  fallBackValue?: string;
  initValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;
  onSelect?: (c: string) => void;
  customerRole: CustomerRole;
  required?: boolean;
  placeholder?: string;
  errorMessage?: string;
};

export const CustomerInput = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: CustomerInputProps<TFieldValues, TName>
): ReactElement => {
  const { t } = useTranslation('orderCreate');
  const { field, fieldState } = useController({
    name: props.name,
    rules: {
      pattern: /^\s*(?:\d\s*){10}$/,
      ...props.rules
    },
    defaultValue: props.defaultValue
  });

  const { recommendations, load, delete: remove } = useCustomerRecommendations(props.customerRole);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isShowList, setIsShowList] = useBoolean(false);
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  useClickOutside([inputRef, wrapperRef], () => setIsShowList.off());

  useKeyPress([inputRef, wrapperRef], ['Enter', 'Escape', 'Tab'], (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    } else {
      setIsShowList.off();
    }
  });

  useEffect(() => {
    isShowList && load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, isShowList]);

  const icon = props.onDelete && !props.disabled && (
    <DHLIcon name={'orderSearchFilterAttrSelectableInput-function'} icon="close-thin" onClick={() => field.onChange('')} className={classes.icon} />
  );

  return (
    <div>
      <Input
        {...field}
        onChange={(e) => {
          const { on, off } = setIsShowList;
          const customerno = validateCustomerNumber(e.currentTarget.value);
          customerno.length ? off() : on();
          field.onChange(customerno);
        }}
        data-testid={field.name}
        placeholder={props.placeholder}
        type="text"
        icon={icon}
        onClick={() => setIsShowList.on()}
        disabled={props.disabled}
        autoComplete="off"
        error={!!fieldState.error}
      />
      {!!recommendations.length && (
        <div className={contextClasses.anchor} ref={wrapperRef}>
          <Menu isOpen={isShowList} data-testid={props.name + '-menu'} className={classes.menu}>
            <p className={classNames(contextClasses.item, contextClasses.noHover)}>Last Entry</p>
            {recommendations.map((recommendation, i) => (
              <div className={classNames(contextClasses.item, contextClasses.noHover)} key={i}>
                <button
                  onClick={() => {
                    field.onChange(recommendation.customerId);
                    props.onSelect?.(recommendation.customerId);
                    field.onBlur();
                    setIsShowList.off();
                  }}
                  className={dropdownStyles.dropDownValue}
                >
                  {recommendation.customerId}
                  {concatNonUndefined(' ', recommendation.name)}
                </button>
                <button className={dropdownStyles.delete}>
                  <DHLIcon
                    name={'amSearchDropdown-remove-' + i}
                    icon={'close-thin'}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await remove(recommendation);
                    }}
                  />
                </button>
              </div>
            ))}
          </Menu>
        </div>
      )}
      {/* {props.customerRole === OrderSearchKey.Payer
        ? !!fieldState.error && (
            <div className="fieldNote error" data-testid={field.name + '-error'}>
              {t('error.constractor')}
            </div>
          )
        : !!fieldState.error && (
            <div className="fieldNote error" data-testid={field.name + '-error'}>
              {t('error.customerIdUnknown')}
            </div>
          )} */}

      {!!fieldState.error && (
        <div className="fieldNote error" data-testid={field.name + '-error'}>
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};
