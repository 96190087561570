import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import './translation';
import { JobTitleInput } from './JobTitleInput/JobTitleInput';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';

export type TargetGroupLabelSection = {
  orderLabel?: string;
  targetGroup?: LineItemVariant.NKD | LineItemVariant.BKD;
};

export type JobTitleSectionProps<T> = {
  disabled?: boolean;
  controllerName?: string;
  product?: ProductGroup;
  editing?: boolean;
  orderLabel?: string;
  required?: boolean;
  hasTargetGroup?: boolean;
};

export const JobTitleSection = <T,>({ disabled, controllerName, product, ...props }: JobTitleSectionProps<T>): ReactElement => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { orderMode } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  type TargetGroup = Extract<LineItemVariant, LineItemVariant.NKD | LineItemVariant.BKD> | LineItemVariant.NS;

  return (
    <OrderStepSection headline={oc('orderName')}>
      <FormRow mode="two-col">
        <JobTitleInput
          required={props.required}
          editing={orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY}
          orderLabel={props.orderLabel}
          defaultValue={props.orderLabel}
          name={controllerName ? `${controllerName}.orderLabel` : 'orderLabel'}
        />
        {props.hasTargetGroup && (
          <Controller
            name={controllerName ? `${controllerName}.targetGroup` : 'targetGroup'}
            render={({ field: { ref, ...field } }) => (
              <AMDHLSelectSingle<TargetGroup>
                data-testid={'targetGroupId'}
                placeholder={oc('targetGroup')}
                {...field}
                onChange={(value) => field.onChange(value || 'NS')}
                valueToString={(item) => (item ? tCatalogValues('lineItemVariant.display.' + item) : oc('notSpecified'))}
                items={[LineItemVariant.NS, LineItemVariant.NKD, LineItemVariant.BKD]}
                disabled={disabled}
              />
            )}
          />
        )}
      </FormRow>
    </OrderStepSection>
  );
};
