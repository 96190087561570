import { useEffect, useState } from 'react';

type Errors = {
  [key: string]: ErrorObject;
};

type ErrorObject = {
  [id: number | string]: boolean;
};

export const useError = () => {
  const [errorObject, setErrorObject] = useState<Errors>({});
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    setHasErrors(Object.values(errorObject).some((err) => Object.values(err).some(Boolean)));
  }, [errorObject]);

  const setErrors = (id: number | string, values: [string, boolean][]) => {
    setErrorObject((prev) => {
      const errorCopy = { ...prev };
      values.forEach((pair) => {
        const [key, value] = pair;
        errorCopy[key] = {
          ...errorCopy[key],
          [id]: value
        };
      });
      return errorCopy;
    });
  };

  const clearError = (id: number | string) => {
    setErrorObject((prev) => {
      const errorCopy = { ...prev };
      Object.keys(errorCopy).forEach((key) => {
        if (errorCopy[key] && errorCopy[key][id]) {
          delete errorCopy[key][id];
        }
      });
      return errorCopy;
    });
  };

  const getError = (id: number | string, key: string) => {
    if (errorObject && errorObject[key] && errorObject[key][id]) return true;
    return false;
  };

  return {
    setErrors,
    clearError,
    getError,
    hasErrors
  };
};

export default useError;
