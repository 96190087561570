import { useOrderContext } from 'order/common/context/order/OrderContext';
import { isValidDate } from 'order/common/utils/isValidDate';
import React, { VFC } from 'react';
import { NewQtyAllocator } from './NewQtyAllocator';
import { QtyAllocator } from './QtyAllocator';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { OrderMode } from 'order/common/dtos/OrderMode';

export interface QtyAllocationData {
  orderNumber?: string;
}

export const QtyAllocation: VFC<QtyAllocationData> = ({ orderNumber }) => {
  const { meta, order, orderMode } = useOrderContext();
  const currDate = new Date(orderMode === OrderMode.VIEW ? order?.orderDetail.date : meta?.pickUpDate);
  const validDate = currDate ? isValidDate(currDate) : false;
  const postingDate = validDate ? currDate : undefined;

  return <>{isAvailableFromJan25(postingDate) ? <NewQtyAllocator orderNumber={orderNumber} /> : <QtyAllocator orderNumber={orderNumber} />}</>;
};
