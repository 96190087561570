export function splitArrayIntoChunks(array: any[], chunkSize: number) {
  return array.reduce((newArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!newArray[chunkIndex]) {
      newArray[chunkIndex] = []; // start a new chunk
    }

    newArray[chunkIndex].push(item);

    return newArray;
  }, []);
}
