// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-info_container__tp9Mk {
  display: flex;
  gap: 12px;
}

.customer-info_avatar__jX1T4 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: #E6E6E6;
}

.customer-info_avatar__jX1T4 > svg {
  max-height: 15px;
}

.customer-info_text__9vxyb, .customer-info_text__9vxyb * {
  text-align: left;
  font-size: 14px !important;
  color: #707070 !important;
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/CustomerAddress/customer-info.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,yBAAyB;EACzB,oBAAoB;AACtB","sourcesContent":[".container {\n  display: flex;\n  gap: 12px;\n}\n\n.avatar {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  display: grid;\n  place-items: center;\n  background: #E6E6E6;\n}\n\n.avatar > svg {\n  max-height: 15px;\n}\n\n.text, .text * {\n  text-align: left;\n  font-size: 14px !important;\n  color: #707070 !important;\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `customer-info_container__tp9Mk`,
	"avatar": `customer-info_avatar__jX1T4`,
	"text": `customer-info_text__9vxyb`
};
export default ___CSS_LOADER_EXPORT___;
