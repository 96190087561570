import { Image, Text, View } from '@react-pdf/renderer';
import { getOrderIdBarcodeImageUrl } from './transportAvisBarcodes';
import React from 'react';
import { styles } from './transportAvisStyles';
import {
  CustomerInfoWithAddressTO,
  OrderContact,
  OrderDestination,
  OrderDetail,
  OrderPackaging,
  PickupAddress,
  ProductionFacilityTO
} from 'generated';
import CountryList from '../../../productGroups/common/dtos/CountryList.json';
import { OrderStatus } from '../../../common/context/search/dtos/OrderStatus';
import { TFunction } from 'react-i18next';
import { toFormattedDate, toFormattedTime, toFormattedInt, toFormattedNumber } from 'common/formatting';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useAuthContext } from 'common/context/auth/AuthContext';

export const compOrderId = (orderId?: string): JSX.Element => {
  return (
    <>
      <Text>{`Auftragsnummer: ${orderId || ''}`}</Text>
      {(() => {
        const barcodeUrl = orderId ? getOrderIdBarcodeImageUrl(orderId) : undefined;
        return barcodeUrl ? (
          <Image style={{ position: 'absolute', top: '6mm', right: '0mm', width: '40mm', height: '10mm' }} src={barcodeUrl} />
        ) : (
          <></>
        );
      })()}
    </>
  );
};

export const compSubmitter = (submitterInfo?: CustomerInfoWithAddressTO, submitterContact?: OrderContact): JSX.Element => {
  return (
    <>
      <View style={[{ marginTop: '4mm' }, styles.lineContainerStreched]}>
        <Text style={styles.textHeader}>Einlieferer</Text>
      </View>
      <View style={styles.lineContainer}>
        <View style={[{ width: '100%' }, styles.linesContainer]}>
          <View style={styles.lineContainer}>
            <Text style={{ minWidth: '60mm' }}>Kundennummer:</Text>
            <Text style={{ width: '100%' }}>{submitterInfo?.customerId}</Text>
          </View>
          <View style={styles.lineContainer}>
            <Text style={{ minWidth: '60mm' }}>Firmenname:</Text>
            <Text style={{ width: '100%' }}>{submitterInfo?.name}</Text>
          </View>
        </View>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Straße oder Postfach:</Text>
        <Text style={{ width: '100%' }}>{`${submitterInfo?.address?.street || ''} ${submitterInfo?.address?.streetNumber || ''}`}</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>PLZ und Ort:</Text>
        <Text style={{ width: '100%' }}>{`${submitterInfo?.address?.postCode || ''} ${submitterInfo?.address?.city || ''}`}</Text>
      </View>
      <View style={[styles.lineContainer, styles.paddingBottom]}>
        <Text style={{ minWidth: '60mm' }}>Land:</Text>
        <Text style={{ width: '100%' }}>{submitterInfo?.address?.country}</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Kontakt für Rückfragen:</Text>
        <Text style={{ width: '100%' }}>{`${submitterContact?.firstName || ''} ${submitterContact?.lastName || ''}`}</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Telefon:</Text>
        <Text style={{ width: '100%' }}>{submitterContact?.telephone}</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>E-Mail:</Text>
        <Text style={{ width: '100%' }}>{submitterContact?.email}</Text>
      </View>
    </>
  );
};

export const compOriginator = (
  t: TFunction,
  orderLabel?: string,
  originatorInfo?: CustomerInfoWithAddressTO,
  submissionType?: string | null
): JSX.Element => {
  return compOriginatorX(t, 'Auftragsbezeichnung', orderLabel, originatorInfo, submissionType);
};
export const compOriginatorX = (
  t: TFunction,
  col3Label: string,
  col3Value?: string,
  originatorInfo?: CustomerInfoWithAddressTO,
  submissionType?: string | null
): JSX.Element => {
  return (
    <View style={styles.groupContainer}>
      <View style={styles.lineContainer}>
        <Text style={styles.textHeader}>Absender</Text>
      </View>
      <View style={styles.gridRowContainer}>
        <Text style={[styles.gridHeaderItem, styles.textHeader, { paddingLeft: '0' }]}>Kundennummer</Text>
        <Text style={[styles.gridHeaderItem, styles.textHeader]}>Firmenname</Text>
        <Text style={[styles.gridHeaderItem, styles.textHeader]}>{col3Label}</Text>
        <Text style={[styles.gridHeaderItem, styles.textHeader]}>Einlieferungsart</Text>
      </View>
      <View style={styles.gridRowContainer}>
        <Text style={[styles.gridRowItem, { paddingLeft: '0' }]}>{originatorInfo?.customerId}</Text>
        <Text style={[styles.gridRowItem]}>{originatorInfo?.name}</Text>
        <Text style={[styles.gridRowItem]}>{customWordBreakForPDF(col3Value, 18) || ''}</Text>
        <Text style={[styles.gridRowItem]}>{t(`submissionTypes.${submissionType}`) || ''}</Text>
      </View>
    </View>
  );
};

export const compDispDepot = (pickupDepot?: ProductionFacilityTO): JSX.Element => {
  return (
    <View style={styles.groupContainer}>
      <View style={styles.lineContainer}>
        <Text style={styles.textHeader}>Depot</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Disponierendes Depot:</Text>
        <Text style={{ width: '100%' }}>
          {pickupDepot
            ? `${pickupDepot.catalogId || ''} ${pickupDepot.comment || ''} (Das disponierende Depot kann in Einzelfällen von dieser Angabe abweichen)`
            : ''}
        </Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Telefon:</Text>
        <Text style={{ width: '100%' }}>{pickupDepot ? pickupDepot.phone : ''}</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>E-Mail:</Text>
        <Text style={{ width: '100%' }}>{pickupDepot ? pickupDepot.email : ''}</Text>
      </View>
    </View>
  );
};

export const compPickupData = (orderDetail?: OrderDetail, pickupAddress?: PickupAddress): JSX.Element => {
  return (
    <>
      <View style={styles.lineContainer}>
        <Text style={styles.textHeader}>Abholdaten</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Straße:</Text>
        <Text style={{ width: '100%' }}>
          {orderDetail?.pickup && pickupAddress
            ? `${pickupAddress?.orderAddress?.street || ''} ${pickupAddress?.orderAddress?.streetNumber || ''}`
            : ''}
        </Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>PLZ und Ort:</Text>
        <Text style={{ width: '100%' }}>
          {orderDetail?.pickup && pickupAddress ? `${pickupAddress?.orderAddress?.postCode || ''} ${pickupAddress?.orderAddress?.city || ''}` : ''}
        </Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Land:</Text>
        <Text style={{ width: '100%' }}>
          {orderDetail?.pickup && pickupAddress?.orderAddress
            ? CountryList[(pickupAddress.orderAddress?.country || 'DE') as keyof typeof CountryList]
            : ''}
        </Text>
      </View>
      {orderDetail?.pickup && pickupAddress && pickupAddress.orderAddress?.further && (
        <View style={styles.lineContainer}>
          <Text style={{ minWidth: '60mm' }}>Weitere Informationen zur Abholung:</Text>
          <Text style={{ width: '100%' }}>{pickupAddress?.orderAddress?.further}</Text>
        </View>
      )}

      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Abholdatum und -zeit:</Text>
        <Text style={{ width: '100%' }}>
          {orderDetail?.pickup ? `${toFormattedDate(orderDetail?.date)}, ${toFormattedTime(orderDetail?.date)}` : ''}
        </Text>
      </View>
    </>
  );
};

export const compProductGroup = (t: TFunction, productGroup: string): JSX.Element => {
  return (
    <>
      <View style={styles.lineContainer}>
        <Text style={styles.textHeader}>Produktdaten</Text>
      </View>
      <View style={styles.lineContainer}>
        <Text style={{ minWidth: '60mm' }}>Sendungsart (Produktgruppe):</Text>
        <Text style={{ width: '100%' }}>{t(`productGroups.types.${productGroup}`)}</Text>
      </View>
    </>
  );
};

export const compOrderLabel = (orderLabel?: string): JSX.Element => {
  return (
    <View style={styles.lineContainer}>
      <Text style={{ minWidth: '60mm' }}>Auftragsbezeichnung:</Text>
      <Text style={{ width: '100%' }}>{customWordBreakForPDF(orderLabel, 50)}</Text>
    </View>
  );
};

export const compProductionState = (productionState?: string): JSX.Element => {
  return (
    <View style={styles.lineContainer}>
      <Text style={{ minWidth: '60mm' }}>Status:</Text>
      <Text style={{ width: '100%' }}>
        {productionState && Object.keys(OrderStatus).includes(productionState)
          ? Object.values(OrderStatus)[Object.keys(OrderStatus).indexOf(productionState)]
          : productionState}
      </Text>
    </View>
  );
};

export const compPackageDetails = (
  destinations?: OrderDestination[],
  packaging?: OrderPackaging,
  orderCategory?: string,
  language?: string
): JSX.Element => {
  const isClusterInternational =
    orderCategory === OrderCategory.IP_INTERNATIONAL ||
    orderCategory === OrderCategory.PUB_INTERNATIONAL ||
    orderCategory === OrderCategory.BRIEF_INTERNATIONAL ||
    orderCategory === OrderCategory.BRIEF_DMC_INTERNATIONAL;
  const sortDestination = ['62', '36999', '0-9', 'DE'];
  const isEnglish = language === 'en';

  const sortedDestinations = isClusterInternational
    ? destinations?.sort((a, b) => {
        const indexA = sortDestination.indexOf(a.zipCode?.toLowerCase() || '');
        const indexB = sortDestination.indexOf(b.zipCode?.toLowerCase() || '');

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      })
    : destinations?.sort((destA, destB) => {
        let sortCritA = 10000;
        if (destA.zipCode === 'DE' || destA.zipCode === '0-9') {
          sortCritA = 99;
        } else if (/^[0-9]{1,2}$/.test(destA.zipCode || '')) {
          sortCritA = +`1${destA.zipCode}`;
        }
        let sortCritB = 10000;
        if (destB.zipCode === 'DE' || destB.zipCode === '0-9') {
          sortCritB = 99;
        } else if (/^[0-9]{1,2}$/.test(destB.zipCode || '')) {
          sortCritB = +`1${destB.zipCode}`;
        }
        return sortCritA - sortCritB;
      });

  return (
    <View style={styles.groupContainer}>
      <View style={styles.lineContainer}>
        <Text style={styles.textHeader}>Gebindedetails</Text>
      </View>
      <View style={styles.gridContainer}>
        <View style={styles.gridHeaderContainer} fixed>
          <Text
            style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderLeft, styles.borderTop, styles.borderBottom, styles.borderRight]}
          >
            Leitzone / Leitregion
          </Text>
          <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
            Anzahl Paletten
          </Text>
          <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
            Gewicht netto in kg
          </Text>
          <Text style={[{ textAlign: 'center' }, styles.gridHeaderItem, styles.borderTop, styles.borderBottom, styles.borderRight]}>
            Gewicht brutto in kg
          </Text>
        </View>
        {sortedDestinations
          ? sortedDestinations.map((dest, idx) => {
              return (
                <View style={styles.gridRowContainer} key={`pkgDetails_${idx}`}>
                  <Text style={[{ textAlign: 'left' }, styles.gridRowItem, styles.borderLeft, styles.borderBottom, styles.borderRight]}>
                    {(() => {
                      if (dest?.zipCode === '62') {
                        return `IPZ 1`;
                      } else if (dest?.zipCode === '36999') {
                        return `IPZ 2`;
                      } else if (dest?.zipCode === 'DE' || dest?.zipCode === '0-9') {
                        return `Leitzone 0-9`;
                      } else if (dest?.zipCode?.length === 1) {
                        return `Leitzone ${dest.zipCode}`;
                      } else if (dest?.zipCode?.length === 2) {
                        return `Leitregion ${dest?.zipCode}`;
                      }
                      return dest?.zipCode;
                    })()}
                  </Text>
                  <Text style={[{ textAlign: 'center' }, styles.gridRowItem, styles.borderBottom, styles.borderRight]}>{dest.quantity}</Text>
                  <Text style={[{ textAlign: 'center' }, styles.gridRowItem, styles.borderBottom, styles.borderRight]}>
                    {toFormattedInt(
                      dest?.packaging?.reduceRight<number>((acc, pkg) => acc + (pkg?.totalNetWeightValue || 0), 0),
                      { english: isEnglish }
                    ) ?? ' '}
                  </Text>
                  <Text style={[{ textAlign: 'right' }, styles.gridRowItem, styles.borderBottom, styles.borderRight]}>
                    {toFormattedInt(
                      dest.packaging?.reduceRight<number>((acc, pkg) => acc + (pkg.totalWeight || 0), 0),
                      { english: isEnglish }
                    )}
                  </Text>
                </View>
              );
            })
          : null}
      </View>
      {packaging?.quantity && (
        <View style={[styles.lineContainer, { marginTop: '4mm' }]}>
          <Text style={{ minWidth: '90mm' }}>Summe Anzahl Paletten je Leitzone & Leitregion:</Text>
          <Text style={{ width: '100%' }}>{toFormattedNumber(packaging.quantity, { english: isEnglish })}</Text>
        </View>
      )}
      {packaging?.totalNetWeightValue ? (
        <View style={[styles.lineContainer, { marginTop: '1mm' }]}>
          <Text style={{ minWidth: '90mm' }}>Gesamtgewicht netto in kg:</Text>
          <Text style={{ width: '100%' }}>{toFormattedInt(packaging.totalNetWeightValue, { english: isEnglish })}</Text>
        </View>
      ) : (
        <></>
      )}
      {packaging?.totalWeight && (
        <View style={[styles.lineContainer, { marginTop: '1mm' }]}>
          <Text style={{ minWidth: '90mm' }}>Gesamtgewicht brutto in kg:</Text>
          <Text style={{ width: '100%' }}>{toFormattedInt(packaging.totalWeight, { english: isEnglish })}</Text>
        </View>
      )}
    </View>
  );
};
