import React from 'react';
import { AlertTypes } from '../dtos/AlertTypes';

export const alertSVG = {
  ErrorSVG: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#D40511"
        fillRule="evenodd"
        d="M12.0000288,1.13686838e-13 C18.6270187,1.13686838e-13 24,5.3729813 24,11.9999712 C24,18.6269611 18.6270187,23.9999424 12.0000288,23.9999424 C5.3729813,23.9999424 0,18.6269611 0,11.9999712 C0,5.3729813 5.3729813,1.13686838e-13 12.0000288,1.13686838e-13 Z M12.0000288,1.9999856 C6.48598521,1.9999856 1.9999856,6.48598521 1.9999856,11.9999712 C1.9999856,17.5139572 6.48598521,21.9999568 12.0000288,21.9999568 C17.5140148,21.9999568 22.0000144,17.5139572 22.0000144,11.9999712 C22.0000144,6.48598521 17.5140148,1.9999856 12.0000288,1.9999856 Z M13.4142229,11.9999712 L16.9497656,15.535514 L15.535514,16.949708 L12.0000288,13.4141653 L8.46448603,16.949708 L7.05023436,15.535514 L10.5857771,11.9999712 L7.05023436,8.46442843 L8.46448603,7.05023436 L12.0000288,10.5857771 L15.535514,7.05023436 L16.9497656,8.46442843 L13.4142229,11.9999712 Z"
      ></path>
    </svg>
  ),
  WarningSVG: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#323232"
        fillRule="evenodd"
        d="M12.0000288,1.13686838e-13 C18.6270187,1.13686838e-13 24,5.3729813 24,11.9999712 C24,18.6269611 18.6270187,23.9999424 12.0000288,23.9999424 C5.3730389,23.9999424 0,18.6269611 0,11.9999712 C0,5.3729813 5.3730389,1.13686838e-13 12.0000288,1.13686838e-13 L12.0000288,1.13686838e-13 Z M12.0000288,1.9999856 C6.48598521,1.9999856 2.0000432,6.48598521 2.0000432,11.9999712 C2.0000432,17.5139572 6.48598521,21.9999568 12.0000288,21.9999568 C17.5140148,21.9999568 22.0000144,17.5139572 22.0000144,11.9999712 C22.0000144,6.48598521 17.5140148,1.9999856 12.0000288,1.9999856 Z M11.000036,9.9999856 L13.0000216,9.9999856 L13.0000216,17.9999856 L11.000036,17.9999856 L11.000036,9.9999856 Z M11.000036,5.9999568 L13.0000216,5.9999568 L13.0000216,8 L11.000036,8 L11.000036,5.9999568 Z"
        transform="matrix(1 0 0 -1 0 24)"
      ></path>
    </svg>
  ),
  SuccessSVG: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#3C763D"
        fillRule="evenodd"
        d="M12,2 C17.514,2 22,6.486 22,12 C22,17.514 17.514,22 12,22 C6.486,22 2,17.514 2,12 C2,6.486 6.486,2 12,2 L12,2 Z M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 L12,0 Z M18.25,8.891 L16.829,7.482 L10.724,13.7 L7.646,10.763 L6.25,12.199 L10.75,16.518 L18.25,8.891 L18.25,8.891 Z"
      />
    </svg>
  ),
  NeutralSVG: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#323232"
        fillRule="evenodd"
        d="M12,0 C18.627,0 24,5.373 24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 Z M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2 Z M13,10 L13,18 L11,18 L11,10 L13,10 Z M13,6 L13,8 L11,8 L11,6 L13,6 Z"
      />
    </svg>
  )
};

export const alertSvgSwitch = (type: string | undefined) => {
  let svg;
  switch (type) {
    case AlertTypes.Error:
      svg = alertSVG.ErrorSVG;
      break;
    case AlertTypes.Warning:
      svg = alertSVG.WarningSVG;
      break;
    case AlertTypes.Success:
      svg = alertSVG.SuccessSVG;
      break;
    default:
      return alertSVG.NeutralSVG;
  }
  return svg;
};
