/* eslint-disable @typescript-eslint/no-explicit-any */
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { MoneyDT, OrderPriceRep, ServiceClusterRep } from 'generated';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import tableStyles from 'order/orderSearch/components/table/AMTable.module.css';
import styles from 'order/orderChange/common/components/BillingTable/billing-table.module.css';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';

type CollectionBillingTableProps = {
  orderPrice?: OrderPriceRep;
  originator?: string;
};
type CollectionListProps = {
  orderId?: string;
  product?: string;
  numberOfPallets?: number;
  basicFeePiece?: MoneyDT;
  taxRate?: number;
  feeWithoutVat?: MoneyDT;
};

export const CollectionBillingTable: VFC<CollectionBillingTableProps> = ({ orderPrice, originator }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const filteredCollectionList: Array<CollectionListProps> = [];
  const { user } = useAuthContext();
  orderPrice?.serviceClusters?.forEach((item: ServiceClusterRep) => {
    filteredCollectionList.push({
      orderId: item?.singleOrderId,
      product: item?.pricePositions && item?.pricePositions[0]?.productDescription,
      numberOfPallets: item?.pricePositions && item?.pricePositions[0]?.qty,
      basicFeePiece: item?.pricePositions && item?.pricePositions[0]?.unitAmount,
      taxRate: item?.pricePositions && item?.pricePositions[0]?.taxRate,
      feeWithoutVat: item?.pricePositions && item?.pricePositions[0]?.amount
    });
  });
  filteredCollectionList.sort((a, b) => Number(a.orderId) - Number(b.orderId));
  return (
    <>
      <DHLHeadline type="h6">{t('settlementOfOrderItems')}</DHLHeadline>
      <table className={classNames('dhlTable table ' + tableStyles.amTable, tableStyles.AutoLayout)}>
        <thead className={classNames('dhlTable-sort-header ' + tableStyles.headcellalign)}>
          <tr>
            <th className={classNames(styles.rightAlign, tableStyles.noPaddingLeft)}>{t('position')}</th>
            <th>{t('ordernumber')}</th>
            <th>{t('sender')}</th>
            <th>{t('product')}</th>
            <th className={styles.rightAlign}>{t('palletsQty')}</th>
            <th className={styles.rightAlign}>{t('basicFeePerPiece')}</th>
            <th className={styles.rightAlign}>{t('VAT')}</th>
            <th className={styles.rightAlign}>{t('feeWithoutVat')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollectionList?.map((item, index: number) => {
            return (
              <tr key={'row-data-' + index} className={classNames(styles.whiteBg, 'dhlTable-row dhlTable-row-default leaf')}>
                <td className={styles.rightAlign}>{index + 1}</td>
                <td>{item?.orderId}</td>
                <td>{originator}</td>
                <td>{item?.product}</td>
                <td className={styles.rightAlign}>{item?.numberOfPallets}</td>
                <td className={styles.rightAlign}>
                  {item.basicFeePiece?.value?.toLocaleString(user.language, {
                    style: 'currency',
                    currency: item.basicFeePiece?.currency,
                    maximumFractionDigits: 3
                  })}
                </td>
                <td className={styles.rightAlign}>{item.taxRate} %</td>
                <td className={styles.rightAlign}>
                  {item.feeWithoutVat?.value?.toLocaleString(user.language, {
                    style: 'currency',
                    currency: item.basicFeePiece?.currency,
                    maximumFractionDigits: 3
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
