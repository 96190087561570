import React, { ReactNode, useReducer } from 'react';
import OrderDisplayContext from './OrderDisplayContext';
import { defaultOrderState, fetchJournalPaymentInfo, orderDisplayReducer } from './reducers/OrderDisplayReducer';

interface Props {
  children: ReactNode;
}

export const OrderDisplayContextProvider = (props: Props) => {
  const [order, orderDispatch] = useReducer(orderDisplayReducer, defaultOrderState);

  const getJournalPaymentData = async (zkz: string) => {
    const journalPaymentInfo = await fetchJournalPaymentInfo({ zkz });
    orderDispatch({ type: 'setJournalPaymentData', data: journalPaymentInfo });
    return journalPaymentInfo;
  };

  return (
    <OrderDisplayContext.Provider
      value={{
        getJournalPaymentData,
        journalPaymentInfo: order.journalPaymentInfo
      }}
    >
      {props.children}
    </OrderDisplayContext.Provider>
  );
};
