import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import { useTranslation } from 'i18n';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { isValidDate } from 'order/common/utils/isValidDate';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { CollectivePostingVariantsAdaptation } from '../../../common/components/atom/CollectivePostingVariantsAdaptation/CollectivePostingVariantsAdaptation';
import { OrderCustomerRoleEnum } from 'generated';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useAuthContext } from 'common/context/auth/AuthContext';
import iconDataEdit from 'assets/icon-data-edit.svg';
import styles from './dialog-post-overview.module.css';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { DialogMarketingOrderCreate } from '../../schema/dialogMarketingSchema';

export type GetDialogPost = DialogMarketingOrderCreate & { orderNumber?: string };

export type DialogMarketingOverviewProps = {
  value: GetDialogPost;
  orderPhase?: OrderMode;
};

export const DialogMarketingOverview: FC<DialogMarketingOverviewProps> = observer(({ value, orderPhase }) => {
  const baseProduct = useBaseProducts(value.productGroup, value.details?.date).find((x) => x.productNumber === value.dialogpostBaseProduct);
  const { premium } = useDependentProducts(value.productGroup, value.details?.date, baseProduct?.productNumber);
  const premiumName = premium.find(({ productNumber: p }) => p === value.subProducts.premium)?.descriptionShort;
  const { getFacility } = useProductionFacilities(value.details.date);
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const language = useAuthContext().user.language;
  const facility = getFacility(value.details.productionPlantId || '');
  const validDate = isValidDate(value.details.date);
  const postingDate = !validDate && value.details.date ? new Date(value.details.date) : value.details.date;

  const pickup = !!value.pickupAddress;
  let furtherInfo = value.furtherInfo || [];
  if (value.targetGroup) {
    furtherInfo = furtherInfo.filter((v) => v !== LineItemVariant.NS && v !== LineItemVariant.NKD && v !== LineItemVariant.BKD);
    // furtherInfo.push(value.targetGroup);
  }
  const { order, orderMode, orderCategory, orderCategoryProductKey } = useOrderContext();
  const isPostaktuell = value.productGroup === ProductGroup.POSTAKTUELL;
  const { t } = useTranslation('translation');

  const parties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Originator,
        customerId: value.originator
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: value.submitter
      },
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: value?.payer
      },
      ...(value.otherPartners?.map((partner) => ({
        role: partner.role,
        customerId: partner.customerId
      })) || [])
    ];
  }, [value]);

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });

  return (
    <DialogPostContainer>
      <DHLFormGroup>
        <OrderStepHeadline icon={iconDataEdit} alt={'data Edit Icon'}>
          {oc('orderOverview')}
        </OrderStepHeadline>
        <h6 className={styles.onlyTitle}>{oc('orderInfo')}</h6>
        <OrderOverviewRow>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderCategory')}:`}</p>
                </td>
                <td>
                  <p>
                    {order?.orderCategoryProductKey
                      ? translateCategory(order?.orderCategoryProductKey as string)
                      : orderCategoryProductKey
                      ? translateCategory(orderCategoryProductKey as string)
                      : orderCategory}
                  </p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderName')}:`}</p>
                </td>
                <td>
                  <p>{value?.orderLabel}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}></OrderOverviewWidget>
          {order?.orderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('orderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.orderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
          {order?.customerOrderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('customerOrderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.customerOrderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
        {isPostaktuell ? (
          <>
            <h6 className={styles.onlyTitle}>{t('Produkte')}</h6>
            <OrderOverviewRow>
              <OrderOverviewWidget title={t('Basisprodukt')} width={1}>
                <div className={styles.patableContainer}>
                  <table>
                    <tbody>
                      {value?.letterBaseProducts?.map((bp, i) => {
                        return (
                          <tr data-testid={`base-product-${i}`} key={`baseproductRow${i}`} id={`baseProductRowId${i}`}>
                            <td>
                              <p data-testid={`base-product-description-${i}`}>{bp?.baseProduct?.description}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </OrderOverviewWidget>
              <OrderOverviewWidget title={t('Sendungsmenge')} width={1}>
                <div className={styles.patableContainer}>
                  <table>
                    <tbody>
                      {value?.letterBaseProducts?.map((bp, i) => {
                        return (
                          <tr data-testid={`base-product-${i}`} key={`baseproductRow${i}`} id={`baseProductRowId${i}`}>
                            <td>
                              <p data-testid={`base-product-quantity-${i}`}>{bp?.baseProduct?.quantity?.toLocaleString(language)}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </OrderOverviewWidget>
              <OrderOverviewWidget title={t('Einzelgewicht')} width={1}>
                <div className={styles.patableContainer}>
                  <table>
                    <tbody>
                      {value?.letterBaseProducts?.map((bp, i) => {
                        return (
                          <tr data-testid={`base-product-${i}`} key={`baseproductRow${i}`} id={`baseProductRowId${i}`}>
                            <td>
                              <p data-testid={`base-product-weight-${i}`}>{bp?.baseProduct?.weight?.toLocaleString(language)} g</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </OrderOverviewWidget>
            </OrderOverviewRow>
            <OrderOverviewRow>
              <OrderOverviewWidget title="Zusatzleistungen">
                {value.subProducts.deliveryAreaSelection && <p>{t('deliveryAreaSelection')}</p>}
                {value.subProducts.seasonalSurcharge && <p>{t('seasonalSurcharge')}</p>}
              </OrderOverviewWidget>
              <OrderOverviewWidget title={oc('furtherProductCharacteristics')}>
                {furtherInfo
                  // ?.filter((data) => data !== LineItemVariant.NKD && data !== LineItemVariant.BKD)
                  .map((variant) => (
                    <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
                  ))}
              </OrderOverviewWidget>
            </OrderOverviewRow>
          </>
        ) : (
          <OrderOverviewRow>
            <OrderOverviewWidget title={oc('basicProduct')}>
              <p>{baseProduct?.descriptionShort}</p>
            </OrderOverviewWidget>
            <OrderOverviewWidget title={oc('additionalServices')}>
              {value.subProducts.easy && <p>{'Easy'}</p>}
              {value.subProducts.nichtAutomationsfaehig && <p>{'Nicht Automationsfähig'}</p>}
              {value.subProducts.trackAndMatch && <p>{'Track & Match'}</p>}
              {value.subProducts.seasonalSurcharge && <p>{t('seasonalSurcharge')}</p>}
              <p>{premiumName}</p>
            </OrderOverviewWidget>
            <OrderOverviewWidget title={oc('furtherProductCharacteristics')}>
              {furtherInfo
                // ?.filter((data) => data !== LineItemVariant.NKD && data !== LineItemVariant.BKD)
                .map((variant) => (
                  <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
                ))}
            </OrderOverviewWidget>
          </OrderOverviewRow>
        )}
        <OrderOverviewRow>
          <OrderPickupShippingDisplay
            data={{
              date: postingDate,
              pickup,
              time: value.details.time,
              submissionType: value?.details?.submissionType,
              facility,
              contactForQuestions: value.contactForQuestions,
              pickupAddress: { orderAddress: value.pickupAddress }
            }}
          />
          <OrderOverviewWidget title={oc('bundles')}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>{oc('typeOfBundle')}</p>
                  </td>
                  <td>
                    <p>{value.packaging?.type && tCatalogValues('mailEntityType.display.' + value.packaging?.type)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('numberOfBundles')}</p>
                  </td>
                  <td>
                    <p>{value.packaging?.quantity?.toLocaleString(language)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('totalGrossWeightInKg')}</p>
                  </td>
                  <td>
                    <p>{value.destinations?.totalWeight?.toLocaleString(language)} kg</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('stackable')}</p>
                  </td>
                  <td>
                    <p>{value.destinations?.stackable ? 'ja' : 'nein'}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </OrderOverviewWidget>
          {!(value.orderCategory === OrderCategory.DIP_SAMMEL || value.orderCategory === OrderCategory.DIP_VARIO || isPostaktuell) && (
            <OrderOverviewWidget title={oc('shipmentVolumeAndWeight')}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>{oc('shipmentVolume')}</p>
                    </td>
                    <td>
                      <p>{parseInt(value.details?.quantity as unknown as string)?.toLocaleString(language)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('shipmentsWithAdditionalCharges')}</p>
                    </td>
                    <td>
                      <p>{value.details?.additionalQuantity?.toLocaleString(language)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('weightPerItemInG')}</p>
                    </td>
                    <td>
                      <p>{value.details?.individualWeight?.toLocaleString(language)} g</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
        {(value.orderCategory === OrderCategory.DIP_SAMMEL || value.orderCategory === OrderCategory.DIP_VARIO) &&
          value.shipmentData &&
          !isPostaktuell && (
            <CollectivePostingVariantsAdaptation meta={{ language }} orderCategory={value.orderCategory} shipmentData={value.shipmentData} />
          )}
        <CustomerDetailSection parties={parties} step={'Overview'} validityDate={order?.orderDetail?.date} />
      </DHLFormGroup>
    </DialogPostContainer>
  );
});
