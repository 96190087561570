// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconSearchButton_button__WOVs- {
  border-radius: 0 4px 4px 0;
  background-color: #D40511 !important;
  cursor: pointer;
 }

.IconSearchButton_button__WOVs- path {
  fill: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/IconSearchButton/IconSearchButton.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,eAAe;CAChB;;AAED;EACE,sBAAsB;AACxB","sourcesContent":[".button {\n  border-radius: 0 4px 4px 0;\n  background-color: #D40511 !important;\n  cursor: pointer;\n }\n\n.button path {\n  fill: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `IconSearchButton_button__WOVs-`
};
export default ___CSS_LOADER_EXPORT___;
