import classNames from 'classnames';
import React, { FC } from 'react';

import Plus from 'assets/icons/plus-white.svg';

import classes from './button.module.css';
import { Spinner, SpinnerColors } from 'order/common/components/Spinner';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sizing?: 'lg' | 'md' | 'sm' | 'xs';
  variant?: 'default' | 'primary' | 'secondary' | 'ghost';
  icon?: string | any;
  iconClass?: string;
  label?: string;
  onClick?: React.MouseEventHandler<any>;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  dataTestId?: string;
  disabled?: boolean;
  activeSpinner?: boolean;
  spinnerColor?: SpinnerColors;
  JSXIcon?: JSX.Element;
}

export const Button: FC<ButtonProps> = ({
  sizing = 'md',
  variant = 'default',
  type = 'button',
  dataTestId,
  label,
  icon,
  iconClass,
  disabled,
  activeSpinner,
  spinnerColor,
  JSXIcon,
  ...props
}) => {
  const hasIcon = icon && <img src={icon === 'plus' ? Plus : icon} alt={label} className={disabled ? classNames(classes.disableIcon) : ''} />;
  const iconElement = iconClass && <span className={classNames('icon', iconClass, disabled ? classes.disableIcon : '')} />;

  return (
    <button
      {...props}
      className={classNames(classes.button, classes[variant], classes['button_' + sizing], classes.icon, `${props.className || ''}`)}
      type={type}
      onClick={props.onClick}
      data-testid={dataTestId}
      disabled={disabled}
    >
      {activeSpinner ? (
        <Spinner testid={'button-Spinner'} spinnerColor={spinnerColor} sizing={sizing} />
      ) : (
        <>
          {hasIcon || iconElement || JSXIcon} {label}
        </>
      )}
    </button>
  );
};
