// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__bLf38 {
  margin-top: 16px;
  text-align: center;
}

.Footer_footer__bLf38 .svgIcon-alert-info svg {
  width: 1rem;
  height: 1rem;
  margin-top: -0.2rem;
}

.Footer_links__p1VZ3 {
  display: flex;
  gap: 0.25rem;
}

.Footer_version__GU7rl {
  font-size: 80%;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.Footer_workFlow__ncHqy select {
  padding: 1px 7px;
  border-radius: 4px;
  border: 1px solid rgba(50, 50, 50, 0.3);
  font-size: 14px;
  color: rgb(50, 50, 50);
  font-weight: 400;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uCAAuC;EACvC,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".footer {\n  margin-top: 16px;\n  text-align: center;\n}\n\n.footer :global(.svgIcon-alert-info) svg {\n  width: 1rem;\n  height: 1rem;\n  margin-top: -0.2rem;\n}\n\n.links {\n  display: flex;\n  gap: 0.25rem;\n}\n\n.version {\n  font-size: 80%;\n  font-weight: 400;\n  width: 100%;\n  text-align: center;\n}\n\n.workFlow select {\n  padding: 1px 7px;\n  border-radius: 4px;\n  border: 1px solid rgba(50, 50, 50, 0.3);\n  font-size: 14px;\n  color: rgb(50, 50, 50);\n  font-weight: 400;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__bLf38`,
	"links": `Footer_links__p1VZ3`,
	"version": `Footer_version__GU7rl`,
	"workFlow": `Footer_workFlow__ncHqy`
};
export default ___CSS_LOADER_EXPORT___;
