import { deepClone } from 'common/utils/deepClone';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { Occurrence } from 'common/dtos/Occurrence';
import { DeliveryOptionZATL } from 'common/dtos/DeliveryOption';

export const allocationConvertion = (orderReference?: OrderReference) => {
  const orderRefs = deepClone(orderReference);
  if (orderRefs) {
    const formattedAlloc = [];
    for (const alloc of orderRefs.qtyAllocation) {
      if (alloc.multipleContainsIds) {
        alloc.collectedQuantities?.map((q) => {
          if (!q.containsIds && !q.flexOption) {
            q.includeTotalQty = false;
          }
        });
        formattedAlloc.push(alloc.collectedQuantities || []);
      } else {
        const copyOfAllocation = { ...alloc };
        delete copyOfAllocation.combinedCounter;
        delete copyOfAllocation.originalAssignedQty;
        delete copyOfAllocation.multipleContainsIds;
        formattedAlloc.push([copyOfAllocation]);
      }
    }
    const flattenedAlloc = formattedAlloc.flat();
    orderRefs.qtyAllocation = flattenedAlloc;

    const newQtyAlloc: QtyAllocation[] = [];
    // if (orderRefs.allocatedQty !== orderRefs.idsQty) {
    const qtyAllocation = deepClone(orderRefs.qtyAllocation);
    qtyAllocation.forEach((q) => {
      let duplicatedQtyAlloc = undefined;
      q.collectedQuantities = undefined;
      q.flexOption = q.containsIds ? q.flexOption : false;

      if (q.idsQty && q.quantity !== q.idsQty) {
        const qtyForDuplicate = q.quantity - (q.idsQty ?? 0);
        if (qtyForDuplicate >= 0) {
          q.quantity = q.idsQty ?? 0;
          duplicatedQtyAlloc = {
            ...q,
            quantity: qtyForDuplicate,
            ranges: undefined,
            containsIds: false,
            flexOption: false,
            includeTotalQty: false,
            refShipmentIdZA: null
          };
        }
      }
      newQtyAlloc.push(q);

      if (duplicatedQtyAlloc) {
        newQtyAlloc.push(duplicatedQtyAlloc);
      }
    });
    orderRefs.qtyAllocation = newQtyAlloc;
    // }

    const allocsWithContainsIds = orderRefs.qtyAllocation.filter((q) => q.containsIds && q.quantity);
    const allocsWithFlexOptions = orderRefs.qtyAllocation.filter((q) => q.flexOption && q.quantity);
    const allocsWithIncludeTotalQty = orderRefs.qtyAllocation.filter((q) => q.includeTotalQty);

    orderRefs.containsIds =
      allocsWithContainsIds.length === orderRefs.qtyAllocation.length
        ? Occurrence.ALL
        : allocsWithContainsIds.length > 0
        ? Occurrence.SOME
        : Occurrence.NONE;
    orderRefs.flexOption =
      allocsWithFlexOptions.length === orderRefs.qtyAllocation.length
        ? Occurrence.ALL
        : allocsWithFlexOptions.length > 0
        ? Occurrence.SOME
        : Occurrence.NONE;
    orderRefs.includeTotalQty =
      allocsWithIncludeTotalQty.length === orderRefs.qtyAllocation.length
        ? Occurrence.ALL
        : allocsWithIncludeTotalQty.length > 0
        ? Occurrence.SOME
        : Occurrence.NONE;

    const DeliveryOptionList: DeliveryOptionZATL[] = [];

    if (orderRefs.qtyAllocation) {
      const { qtyAllocation } = orderRefs;

      const conditions = [
        { option: DeliveryOptionZATL.STANDARD, containsIds: false, flexOption: false },
        { option: DeliveryOptionZATL.FLEX, containsIds: true, flexOption: true },
        { option: DeliveryOptionZATL.SCHNELL, containsIds: true, flexOption: false }
      ];

      conditions.forEach(({ option, containsIds, flexOption }) => {
        if (qtyAllocation.find((q) => q.containsIds === containsIds && q.flexOption === flexOption && q.quantity)) {
          DeliveryOptionList.push(option);
        }
      });
    }

    orderRefs.parentDeliveryOption = DeliveryOptionList;
  }
  return orderRefs;
};
