import React from 'react';
import classNames from 'classnames';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useTranslation } from 'react-i18next';
import orderClasses from '../order-create-steps.module.css';
import { Button } from 'common/components/Button';

export const DefaultOrderCreateStep1: React.FC = () => {
  const { previousStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <p data-testid={'defaultText'}>
              <em>No product was selected in WFS1 or the selected product has not been implemented yet.</em>
            </p>
          </main>
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
        </div>
      </footer>
    </>
  );
};
