import React, { FC } from 'react';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { OrderContentTabProps } from '../OrderContentTab';
import { OrderTreeContainer } from '../../../OrderTree/OrderTreeContainer';
import { InternationalProductInfoSection } from 'order/common/components/Overview/InternationalProductInfoSection/InternationalProductInfoSection';

export const InternationalTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;

  return (
    <div data-testid={'order-content-tab-International-testid'}>
      <InternationalProductInfoSection value={order} />
      <CustomerDetailSection
        parties={order?.parties}
        // withAddress={false}
        orderCategoryProductKey={order.orderCategoryProductKey}
        validityDate={order?.orderDetail?.date}
      />
      {order.hasOrderTree && <OrderTreeContainer order={order} />}
    </div>
  );
};
