import React, { AllHTMLAttributes } from 'react';
import { reference } from 'common/utils/helpers';
import styles from './Toggle.module.css';
import classNames from 'classnames';

type Variant = 'lg' | 'sm' | 'xs';

export type ToggleProps = Omit<AllHTMLAttributes<HTMLInputElement>, 'type'> & {
  variant?: Variant;
};

/**
 * Toggle switch implemented after the GKDS specification.
 * The toggle is a styled input with a checkbox type.
 * The switch has a size parameter named variant with the sizes lg, sm and xs.
 */
const Toggle = reference<HTMLInputElement, ToggleProps>(
  'Toggle',
  'input',
  ({ variant = 'lg' }) => classNames(styles.toggle, styles['size-' + variant]),
  ({ variant, ...props }) => ({
    ...props,
    type: 'checkbox'
  })
);

export default Toggle;
