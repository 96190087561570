import { splitArrayIntoChunks } from '../../../common/utils/splitArrayIntoChunks';
import { getOrder } from '../context/order/utils/getOrder';
import { GetOrderRep } from '../../../generated';

let stopFetchingAdditionalOrders = false;

export const setStopFetchingAdditionalOrders = (value: boolean) => (stopFetchingAdditionalOrders = value);

export const loadAdditionalOrdersForPrintingDialogue = async <T>(orderIds: string[], chunkSize: number): Promise<T[]> => {
  stopFetchingAdditionalOrders = false;
  // dedupOrderIds
  const dedupedOrderIds = orderIds.reduce((acc: string[], cur: string) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }
    return acc;
  }, []);

  const orders: T[] = [];
  const chunkedOrderIds = splitArrayIntoChunks(dedupedOrderIds, chunkSize);

  for (let i = 0; i < chunkedOrderIds.length; i++) {
    await Promise.all(
      chunkedOrderIds[i].map(async (o: string) => {
        const order = await getOrder<GetOrderRep>(o, { includeDst: false });

        if (stopFetchingAdditionalOrders) {
          return;
        }

        orders.push(order as any);
      })
    );

    if (stopFetchingAdditionalOrders) {
      break;
    }
  }

  return orders;
};
