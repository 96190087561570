import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { OrderFeeRep, OrderPriceRep } from 'generated';
import moment from 'moment';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './netting-table.module.css';

type NettingTableProps = {
  orderPrice?: OrderPriceRep;
  isCollectionOrder?: boolean;
};

export const NettingTable: VFC<NettingTableProps> = ({ orderPrice, isCollectionOrder }) => {
  const getFeeByType = (type: string): OrderFeeRep | undefined => orderPrice?.orderFees?.find((sc) => sc.type === type);
  const getFeeValueByType = (type: string): number => getFeeByType(type)?.amount?.value || 0;

  // Shipping Charges
  const shippingChargeWithoutVAT = getFeeByType('OM_PORTAL_POSITION_RELATED_CHARGE');
  const filteredShippingChargeWithoutVAT = shippingChargeWithoutVAT?.amount?.value || 0;
  const filteredShippingChargeWithVAT = getFeeValueByType('OM_PORTAL_POSITION_RELATED_CHARGE_ONLY_VAT_RELEVANT');

  // Additional Charges
  const filteredAdditionalWithoutVAT = getFeeValueByType('OM_PORTAL_FURTHER_CHARGE');
  const filteredAdditionalWithVAT = getFeeValueByType('OM_PORTAL_FURTHER_CHARGE_ONLY_VAT_RELEVANT');

  // Discounts
  const filteredPortalReduction = getFeeValueByType('OM_PORTAL_REDUCTION');
  const filteredPortalReductionWithVAT = getFeeValueByType('OM_PORTAL_REDUCTION_ONLY_VAT_RELEVANT');

  // Already Billed Amount
  const filteredAlreadyPaidAmount = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT');
  const filteredAlreadyPaidAmountWithVAT = getFeeValueByType('OM_PORTAL_ALREADY_PAID_AMOUNT_ONLY_VAT_RELEVANT');

  // TotalSum
  const TotalSum = getFeeValueByType('OPEN_AMOUNT_INCL_OTHER_CHARGE_INCL_VAT');

  // Currency
  const currency = shippingChargeWithoutVAT?.amount?.currency || 'EUR';

  // Sum
  const UStfreiSum =
    filteredShippingChargeWithoutVAT -
    filteredShippingChargeWithVAT +
    (filteredAdditionalWithoutVAT - filteredAdditionalWithVAT) +
    (filteredPortalReduction - filteredPortalReductionWithVAT);
  const UStpflichtigiSum = filteredShippingChargeWithVAT + filteredAdditionalWithVAT + filteredPortalReductionWithVAT;
  const SummeEntgelteTop = filteredShippingChargeWithoutVAT + filteredAdditionalWithoutVAT + filteredPortalReduction;

  const { t } = useTranslation('');

  return (
    <>
      <DHLHeadline type="h6" className={classes.nettingP}>
        {t('Saldierung')}
      </DHLHeadline>
      {!isCollectionOrder && (
        <table className={classNames(classes.nettingTable)}>
          <thead>
            <th> </th>
            <th>{t('USt.-frei')}</th>
            <th>{t('USt.-pflichtig')}</th>
            <th>{t('Summe Entgelte')}</th>
            <th> </th>
            <th> </th>
            <th> </th>
          </thead>
          <tbody>
            <tr>
              <td>{t('Sendungsentgelte')}</td>
              <td>
                {(filteredShippingChargeWithoutVAT - filteredShippingChargeWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredShippingChargeWithVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredShippingChargeWithoutVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
            </tr>
            <tr>
              <td>{t('Weitere Entgelte')}</td>
              <td>
                {(filteredAdditionalWithoutVAT - filteredAdditionalWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAdditionalWithVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAdditionalWithoutVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
            </tr>
            <tr className={classNames(classes.trWithBorder)}>
              <td>{t('Entgeltermäßigungen')}</td>
              <td>
                {(filteredPortalReduction - filteredPortalReductionWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredPortalReductionWithVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredPortalReduction.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
            </tr>
            <tr>
              <td>{t('Summe Entgelte')} </td>
              <td>
                {UStfreiSum.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {UStpflichtigiSum.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td data-testid="totalCharges">
                {SummeEntgelteTop.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
            </tr>
            <tr>
              <td>{t('Bereits abgerechnet')}</td>
              <td>
                {(filteredAlreadyPaidAmount - filteredAlreadyPaidAmountWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAlreadyPaidAmountWithVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAlreadyPaidAmount.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
            </tr>
            <tr>
              <th> </th>
              <th>Summe USt.-frei</th>
              <th>Summe USt.-pflichtig</th>
              <th>{t('Summe Entgelte')}</th>
              <th>{t('USt.')}</th>
              <th>Entgelte inkl.USt.</th>
              <th> </th>
            </tr>
            <tr>
              <td>{t('Restforderung')}</td>
              <td>
                {(UStfreiSum + (filteredAlreadyPaidAmount - filteredAlreadyPaidAmountWithVAT)).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {(UStpflichtigiSum + filteredAlreadyPaidAmountWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {(SummeEntgelteTop + filteredAlreadyPaidAmount).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td data-testid="VAT">
                {(TotalSum - (SummeEntgelteTop + filteredAlreadyPaidAmount)).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                <DHLHeadline textAlignment="right" type="h6">
                  {TotalSum.toLocaleString(moment.locale(), {
                    style: 'currency',
                    currency: currency
                  })}
                </DHLHeadline>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {isCollectionOrder && (
        <table className={classNames(classes.nettingTable)}>
          <thead>
            <th> </th>
            <th>Summe USt.-frei</th>
            <th>Summe USt.-pflichtig</th>
            <th>{t('Summe Entgelte')}</th>
            <th>{t('USt.')}</th>
            <th>Entgelte inkl.-USt.</th>
            {/* <th>
              
                {' '}
              
            </th> */}
          </thead>
          <tbody>
            <tr>
              <td>{t('Restforderung')}</td>
              <td>
                {(filteredAdditionalWithoutVAT - filteredAdditionalWithVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAdditionalWithVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                {filteredAdditionalWithoutVAT.toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td data-testid="COLLECTION-VAT">
                {(TotalSum - filteredAdditionalWithoutVAT).toLocaleString(moment.locale(), {
                  style: 'currency',
                  currency: currency
                })}
              </td>
              <td>
                <DHLHeadline textAlignment="right" type="h6">
                  {TotalSum.toLocaleString(moment.locale(), {
                    style: 'currency',
                    currency: currency
                  })}
                </DHLHeadline>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};
