import i18n from 'i18n';

export const translation = {
  headline: {
    variant: 'Sendungsmenge und -gewicht der Varianten',
    collective: 'Sendungsmenge und -gewicht bei Sammeleinlieferungen'
  },
  description: 'Bezeichnung',
  quantity: 'Sendungsmenge',
  additionalQuantity: 'Aufgezahlt',
  individualWeight: 'Einzelgewicht in G',
  errors: {
    required: 'Bitte prüfen Sie Ihre Eingabe. Dies ist ein Pflichtfeld.',
    individualWeight: 'Bitte prüfen Sie Ihre Eingabe. Der eingegebene Wert darf maximal vier Ziffern enthalten.',
    description: 'Bitte prüfen Sie Ihre Eingabe. Der eingegebene Text ist zu lang.'
  }
};

i18n.addResourceBundle('de', 'DisplayQuantityWeightSection', translation);
i18n.addResourceBundle('en', 'DisplayQuantityWeightSection', translation);
