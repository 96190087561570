import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import React, { MouseEventHandler, useEffect, useState, VFC } from 'react';
import classes from './other-partners.module.css';
import { CustomerInfoWithAddressTO } from '../../../../../../generated';

export type DeletePartnerButtonProps = {
  fields: unknown[];
  customerId?: string;
  onClick: MouseEventHandler<any>;
  disabled?: boolean;
};

export const DeletePartnerButton: VFC<DeletePartnerButtonProps> = ({ customerId, onClick, disabled }) => {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  if (!customerInfo) {
    return <div />;
  }

  return (
    <DHLButton
      name={'otherPartnerdeleteIconButton'}
      label={'deleteIconButton'}
      iconPosition={'icon'}
      icon={'delete'}
      size={'xs'}
      onClick={onClick}
      disabled={disabled}
      className={classNames('trashBtn', classes.button)}
    />
  );
};
