import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { useMemo } from 'react';
import useSWR from 'swr/immutable';
import { ProductGroup } from '../dtos/ProductGroup';
import { Product, requestBaseProductsForGroup } from 'order/productGroups/common/services/ProductService';

export const useAdditionalServices = (
  productGroup: ProductGroup,
  date = new Date(),
  serviceType: string = 'SPECIAL_LETTER',
  // isPartialServiceCapable is three state: undefined, true, false ! Default value is undefined
  //  if it is true or false, then the search is limited to some products only !
  isPartialServiceCapable: boolean | undefined = false
): Product[] => {
  const { data: products = [] } = useSWR(serviceType?.length ? ['serviceType', serviceType] : null, async (_, serviceType) =>
    requestBaseProductsForGroup({ productGroup, serviceType: serviceType, isPartialServiceCapable }).catch(() => [] as Product[])
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);

  return filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
};
