import classNames from 'classnames';
import { AMDHLDropdownButton } from 'common/components/AMDHLDropdownButton';
import selectClasses from 'common/components/AMDHLSelectSingle/AMDHLSelectSingle.module.css';
import { HighlightedText } from 'common/components/HighlightedText';
import inputSelectSearchableClasses from 'common/components/InputSelectSearchable/input-select-searchable.module.css';
import { Item } from 'common/components/MultiList';
import multiListClasses from 'common/components/MultiList/MultiList.module.css';
import { useSelect, UseSelectParams } from 'common/hooks/useSelect';
import { concatNonUndefined } from 'common/utils/helpers';
import React, { AllHTMLAttributes, ReactElement } from 'react';
import classes from './active-multi-list.module.css';

export type ActiveMultiListProps<T> = Omit<AllHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange'> &
  UseSelectParams<T> & {
    contextWidth?: 'fit' | 'full';
    wrapperClassName?: string;
    error?: string;
    'data-testid'?: string;
    maxVisibleOptions?: number | string;
  };

export const ActiveMultiList = <T,>({
  valueToString = String,
  contextWidth = 'fit',
  wrapperClassName,
  maxVisibleOptions = 10,
  ...props
}: ActiveMultiListProps<T>): ReactElement => {
  const { items, getListProps, getInputProps, getOptionProps, searchQuery, isOpen, activeIndex, inputValue } = useSelect<T>({
    ...props,
    valueToString,
    mode: 'autocomplete'
  });

  const testId = (value: string = '') => concatNonUndefined(props['data-testid'], value);

  return (
    <div className={wrapperClassName} data-testid={testId('-container')}>
      <AMDHLDropdownButton
        {...getInputProps()}
        className={classNames({ placeholder: props.placeholder }, props.className)}
        disabled={props.disabled}
        wrapperClassName="flex-grow-1"
        placeholder={props.placeholder}
        error={!!props.error}
        type="text"
        name={props.name}
        value={isOpen ? inputValue : props.value ? valueToString(props.value) : ''}
      />
      <div className={selectClasses.anchor}>
        <div
          {...getListProps()}
          className={classNames(
            { [classes.fullWidth]: contextWidth === 'full', [classes.hiddenList]: !isOpen },
            multiListClasses.multiList,
            inputSelectSearchableClasses.listBox,
            classes.list
          )}
          data-testid={testId('-list')}
          style={{ maxHeight: typeof maxVisibleOptions === 'string' ? maxVisibleOptions : 30 * maxVisibleOptions + 'px' }}
        >
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Item {...getOptionProps(item)} data-testid={testId('-option-' + index)} type="button" active={activeIndex === index}>
                <HighlightedText highlighted={searchQuery}>{valueToString?.(item)}</HighlightedText>
              </Item>
            </React.Fragment>
          ))}
          {!items.length && props.children && <div className={classNames(multiListClasses.item, multiListClasses.inactive)}>{props.children}</div>}
        </div>
      </div>
      {!!props.error && (
        <div data-testid={testId('-error')} className="fieldNote error">
          {props.error}
        </div>
      )}
    </div>
  );
};
