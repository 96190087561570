import { isEntityWithDatesValid } from 'common/utils/helpers';
import { ProductionFacility, getProductionFacilities } from 'order/productGroups/common/services/ProductionFacilitiesService';
import { useCallback } from 'react';
import useSWR from 'swr/immutable';

export type UseProductionFacilitiesReturn = {
  facilities: ProductionFacility[];
  getFacility: (id: string) => ProductionFacility | undefined;
  isLoading: boolean;
};

export const useProductionFacilities = (selectedDate = new Date()): UseProductionFacilitiesReturn => {
  const { data = [], isValidating } = useSWR<ProductionFacility[] | undefined>('productionFacilities', async () => getProductionFacilities());

  const getFacility = useCallback((catalogId: string) => data.find(({ catalogId: c }) => c === catalogId), [data]);

  return {
    facilities: data.filter((facility) => isEntityWithDatesValid(facility, selectedDate || new Date())),
    getFacility,
    isLoading: isValidating
  };
};
