import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button } from 'common/components/Button';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { ProductGroupInput } from 'order/productGroups/common/components/molecule/ProductGroupInput';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

import orderClasses from 'common/styles/order.module.css';
import styles from './OrderChangeStep1.module.css';

import iconZusteller from 'assets/icon-zusteller.svg';

export const OrderChangeStep1: VFC<{ orderCategoryProductKey?: OrderCategoryProductKey }> = (props) => {
  const { nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const isDvBrief = props.orderCategoryProductKey === OrderCategoryProductKey.DV_BRIEF;
  const { isDirty } = useOrderContext();

  return (
    <>
      <main className={classNames(orderClasses.formContent, styles.stepOneMainContainer)}>
        <div className={classNames(styles.ocstep1Header)}>
          <OrderStepHeadline icon={iconZusteller} alt="Absender Icon">
            {t('steps.productGroup')}
          </OrderStepHeadline>
        </div>
        <div className={classNames(styles.orderTileContainer)}>
          <ProductGroupInput selectedKey={props.orderCategoryProductKey} disabled={true} isDvBriefOrder={isDvBrief} workflowType={OrderMode.CHANGE} />
        </div>
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <BackToSearchButton isDirty={isDirty} />
        <Button variant="primary" onClick={() => nextStep()} type="button" label={t('buttons.continue')} dataTestId="btnSubmit" />
      </footer>
    </>
  );
};
