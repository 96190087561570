import { LoadingState } from '../../search/dtos/LoadingState';
import { JournalPaymentInfo } from '../dtos/JournalPaymentInfo';
import { AllocatedOrder } from '../dtos/AllocatedOrder';
import { OrderResponse } from '../dtos/GetOrder';
import { OrderReference } from '../dtos/OrderReference';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { deepClone } from 'common/utils/deepClone';
import { OrderCategory } from '../dtos/OrderCategory';
import { OrderCategoryProductKey } from '../dtos/OrderCategoryProductKey';
import { OrderPriceRep, OrderPriceResponse } from 'generated';
import { GetOrderFaultyIds } from '../OrderProvider';

interface CollectedError {
  key: string;
  data: unknown;
}

export interface OrderReducerState {
  allocations: {
    count: number;
    loading: boolean;
    orders: AllocatedOrder[];
    total: number;
  };
  orderReferences: OrderReference[];
  allocatedDiscounts: OrderReference[];
  displayAllAllocations: boolean;
  journalPaymentInfo: JournalPaymentInfo | undefined;
  loadingState: LoadingState;
  order: OrderResponse | undefined;
  orderPrice: OrderPriceRep | undefined;
  orderMode?: OrderMode;
  orderId?: string | number;
  productGroup?: ProductGroup | undefined;
  orderCategory?: OrderCategory | undefined;
  orderCategoryProductKey?: OrderCategoryProductKey | undefined;
  errors: CollectedError[];
  isDirty: boolean;
  getOrderFaultyIds: GetOrderFaultyIds[];
  meta?: Record<string, any>;
}

export enum OrderReducerActions {
  ADD_EXISTING_ALLOCATED_ORDERS = 'addExistingAllocatedOrders',
  ADD_NEW_ALLOCATED_ORDERS = 'addNewAllocatedOrders',
  ADD_ORDER_REFERENCES = 'addOrderReferences',
  ADD_ERROR = 'addError',
  CHANGE_ORDER_REFERENCE = 'changeOrderReference',
  DELETE_ALLOCATION = 'deleteAllocation',
  DELETE_ERROR = 'deleteError',
  DELETE_ORDER_REFERENCE = 'deleteOrderReference',
  RESET = 'reset',
  RESET_ALLOCATIONS = 'resetAllocations',
  RESET_ERRORS = 'resetErrors',
  RESET_ORDER_REFERENCES = 'resetOrderReferences',
  RESET_FAULTY_GETORDER_IDS = 'resetFaultyGetOrderIds',
  SET_ALLOCATIONS_COUNT = 'setAllocationsCount',
  SET_ALLOCATIONS_TOTAL = 'setAllocationTotal',
  SET_LOADING_ALLOCATED_ORDERS = 'setLoadingAllocationOrders',
  SET_DISPLAY_ALL_ALLOCATIONS = 'setDiaplsyAllAllocation',
  SET_JOURNAL_PAYMENT_DATA = 'setJournalPaymentData',
  SET_LOADING = 'setLoading',
  SET_ORDER = 'setOrder',
  SET_ORDER_PRICE = 'setOrderPrice',
  SET_ORDER_ID = 'setOrderId',
  SET_ORDER_MODE = 'setOrderMode',
  SET_PRODUCT_GROUP = 'setProductGroup',
  SET_ORDER_CATEGORY = 'setOrderCategory',
  SET_ORDER_CATEGORY_PRODUCT_KEY = 'setOrderCategoryProductKey',
  SET_DIRTY = 'setDirty',
  UPSERT_META_DATA = 'upsertMetaData',
  SET_FAULTY_GETORDER_IDS = 'setFaultyGetOrderIds',
  SET_ALLOCATED_DISCOUNTS = 'setAllocatedDiscounts'
}

export type Action =
  | { type: OrderReducerActions.ADD_EXISTING_ALLOCATED_ORDERS; data: AllocatedOrder[] }
  | { type: OrderReducerActions.ADD_NEW_ALLOCATED_ORDERS; data: AllocatedOrder[] }
  | { type: OrderReducerActions.ADD_ERROR; data: { key: string; data: unknown } }
  | { type: OrderReducerActions.ADD_ORDER_REFERENCES; data: OrderReference[] }
  | { type: OrderReducerActions.CHANGE_ORDER_REFERENCE; data: { orderNumber: string; data: OrderReference } }
  | { type: OrderReducerActions.DELETE_ALLOCATION; data: string }
  | { type: OrderReducerActions.DELETE_ERROR; data: string }
  | { type: OrderReducerActions.DELETE_ORDER_REFERENCE; data: string }
  | { type: OrderReducerActions.RESET }
  | { type: OrderReducerActions.RESET_ALLOCATIONS }
  | { type: OrderReducerActions.RESET_ERRORS }
  | { type: OrderReducerActions.RESET_ORDER_REFERENCES }
  | { type: OrderReducerActions.RESET_FAULTY_GETORDER_IDS }
  | { type: OrderReducerActions.SET_ALLOCATIONS_COUNT; data: number }
  | { type: OrderReducerActions.SET_ALLOCATIONS_TOTAL; data: number }
  | { type: OrderReducerActions.SET_DISPLAY_ALL_ALLOCATIONS; data: boolean }
  | { type: OrderReducerActions.SET_JOURNAL_PAYMENT_DATA; data: JournalPaymentInfo | undefined }
  | { type: OrderReducerActions.SET_LOADING; data: LoadingState }
  | { type: OrderReducerActions.SET_ORDER; data: OrderResponse | undefined }
  | { type: OrderReducerActions.SET_ORDER_PRICE; data: OrderPriceRep | undefined }
  | { type: OrderReducerActions.SET_ORDER_ID; data: string | number | undefined }
  | { type: OrderReducerActions.SET_ORDER_MODE; data: OrderMode }
  | { type: OrderReducerActions.SET_PRODUCT_GROUP; data: ProductGroup | undefined }
  | { type: OrderReducerActions.SET_ORDER_CATEGORY; data: OrderCategory | undefined }
  | { type: OrderReducerActions.SET_ORDER_CATEGORY_PRODUCT_KEY; data: OrderCategoryProductKey | undefined }
  | { type: OrderReducerActions.SET_DIRTY; data: boolean }
  | { type: OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS; data: boolean }
  | { type: OrderReducerActions.SET_FAULTY_GETORDER_IDS; data: GetOrderFaultyIds[] }
  | { type: OrderReducerActions.UPSERT_META_DATA; data: { key: string; value: unknown } }
  | { type: OrderReducerActions.SET_ALLOCATED_DISCOUNTS; data: OrderReference[] };

export const defaultReducerState: OrderReducerState = {
  allocations: {
    count: 0,
    loading: false,
    orders: [],
    total: 0
  },
  orderReferences: [],
  allocatedDiscounts: [],
  displayAllAllocations: false,
  journalPaymentInfo: undefined,
  loadingState: LoadingState.NOT_LOADED,
  order: undefined,
  orderPrice: undefined,
  orderMode: undefined,
  errors: [],
  getOrderFaultyIds: [],
  isDirty: false
};

export function orderReducer(state: OrderReducerState, action: Action): OrderReducerState {
  switch (action.type) {
    case OrderReducerActions.ADD_EXISTING_ALLOCATED_ORDERS: {
      const curAllocatedOrders = deepClone(state.allocations.orders);

      const allocatedOrders = action.data.concat(curAllocatedOrders);

      return {
        ...state,
        allocations: {
          ...state.allocations,
          loading: false,
          orders: allocatedOrders,
          count: allocatedOrders.length,
          total: allocatedOrders.length
        }
      };
    }
    case OrderReducerActions.ADD_NEW_ALLOCATED_ORDERS: {
      const curAllocatedOrders = deepClone(state.allocations.orders);

      const allocatedOrders = action.data.concat(curAllocatedOrders);

      return {
        ...state,
        allocations: {
          ...state.allocations,
          loading: false,
          orders: allocatedOrders,
          count: allocatedOrders.length,
          total: allocatedOrders.length
        }
      };
    }
    case OrderReducerActions.ADD_ERROR: {
      const clonedErrors = deepClone(state.errors);
      const exists = clonedErrors.find((e) => e.key === action.data.key);
      if (exists) {
        clonedErrors.forEach((e) => {
          if (e.key === action.data.key) {
            e.data = action.data.data;
          }
        });
      } else {
        clonedErrors.push({ key: action.data.key, data: action.data.data });
      }
      return {
        ...state,
        errors: clonedErrors
      };
    }
    case OrderReducerActions.ADD_ORDER_REFERENCES: {
      const orderReferences = deepClone(state.orderReferences);

      const newOrderReferences = orderReferences.concat(action.data);

      return {
        ...state,
        orderReferences: newOrderReferences
      };
    }
    case OrderReducerActions.CHANGE_ORDER_REFERENCE: {
      let newOrderReferences = [...state.orderReferences];
      newOrderReferences = newOrderReferences.map((a) => {
        if (a.orderId === action.data.orderNumber) {
          return action.data.data;
        }
        return a;
      });
      return {
        ...state,
        orderReferences: newOrderReferences
      };
    }
    case OrderReducerActions.DELETE_ALLOCATION: {
      let newAllocatedOrders = [...state.allocations.orders];
      newAllocatedOrders = newAllocatedOrders.filter((a) => a.data.orderNumber !== action.data);
      return {
        ...state,
        allocations: {
          ...state.allocations,
          count: newAllocatedOrders.length,
          total: newAllocatedOrders.length,
          orders: newAllocatedOrders
        }
      };
    }
    case OrderReducerActions.DELETE_ERROR: {
      return {
        ...state,
        errors: state.errors.filter((e) => e.key !== action.data)
      };
    }
    case OrderReducerActions.DELETE_ORDER_REFERENCE: {
      let newOrderReferences = [...state.orderReferences];
      newOrderReferences = newOrderReferences.filter((a) => a.orderId !== action.data);
      return {
        ...state,
        orderReferences: newOrderReferences
      };
    }
    case OrderReducerActions.RESET: {
      return defaultReducerState;
    }
    case OrderReducerActions.RESET_ALLOCATIONS: {
      return {
        ...state,
        displayAllAllocations: false,
        allocatedDiscounts: [],
        allocations: {
          ...state.allocations,
          orders: [],
          loading: false,
          count: 0,
          total: 0
        }
      };
    }
    case OrderReducerActions.RESET_ERRORS: {
      return {
        ...state,
        errors: []
      };
    }
    case OrderReducerActions.RESET_ORDER_REFERENCES: {
      return {
        ...state,
        orderReferences: []
      };
    }
    case OrderReducerActions.RESET_FAULTY_GETORDER_IDS: {
      return {
        ...state,
        getOrderFaultyIds: []
      };
    }
    case OrderReducerActions.SET_ALLOCATIONS_COUNT: {
      return {
        ...state,
        allocations: {
          ...state.allocations,
          count: action.data
        }
      };
    }
    case OrderReducerActions.SET_ALLOCATIONS_TOTAL: {
      return {
        ...state,
        allocations: {
          ...state.allocations,
          total: action.data
        }
      };
    }
    case OrderReducerActions.SET_LOADING_ALLOCATED_ORDERS: {
      return {
        ...state,
        allocations: {
          ...state.allocations,
          loading: true
        }
      };
    }
    case OrderReducerActions.SET_DISPLAY_ALL_ALLOCATIONS: {
      return {
        ...state,
        displayAllAllocations: action.data
      };
    }
    case OrderReducerActions.SET_LOADING: {
      return {
        ...state,
        loadingState: action.data
      };
    }
    case OrderReducerActions.SET_JOURNAL_PAYMENT_DATA: {
      return {
        ...state,
        journalPaymentInfo: action.data
      };
    }
    case OrderReducerActions.SET_ORDER: {
      return {
        ...state,
        order: action.data
      };
    }
    case OrderReducerActions.SET_ORDER_PRICE: {
      return {
        ...state,
        orderPrice: action.data
      };
    }
    case OrderReducerActions.SET_ORDER_ID: {
      return {
        ...state,
        orderId: action.data
      };
    }
    case OrderReducerActions.SET_ORDER_MODE: {
      return {
        ...state,
        orderMode: action.data
      };
    }
    case OrderReducerActions.SET_PRODUCT_GROUP: {
      return {
        ...state,
        productGroup: action.data
      };
    }
    case OrderReducerActions.SET_ORDER_CATEGORY: {
      return {
        ...state,
        orderCategory: action.data
      };
    }
    case OrderReducerActions.SET_ORDER_CATEGORY_PRODUCT_KEY: {
      return {
        ...state,
        orderCategoryProductKey: action.data
      };
    }
    case OrderReducerActions.SET_DIRTY: {
      return {
        ...state,
        isDirty: action.data
      };
    }
    case OrderReducerActions.SET_FAULTY_GETORDER_IDS: {
      return {
        ...state,
        getOrderFaultyIds: action.data
      };
    }
    case OrderReducerActions.UPSERT_META_DATA: {
      const meta = state.meta ?? {};
      meta[action.data.key] = action.data.value;
      return {
        ...state,
        meta
      };
    }
    case OrderReducerActions.SET_ALLOCATED_DISCOUNTS: {
      return {
        ...state,
        allocatedDiscounts: action.data
      };
    }
    default:
      return defaultReducerState;
  }
}
