import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DHLDataFilterChipGroup } from '@gkuis/gkp-base-widgets/dist/lib';

import AMDHLChip from 'common/components/AMDHLChip';
import { DefaultAttribute, OrderSearchAttribute, printAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { getDefaultDayRange } from 'common/components/AMDHLDateRangeInput/DayRangePreset';

export interface AMDHLDataFilterChipGroupProps {
  attributes: OrderSearchAttribute[];
  handleDeleteAll?: () => void;
  handleDeleteChip?: (attribute: OrderSearchAttribute, e: React.MouseEvent) => void;
  handleEditChip?: (attribute: DefaultAttribute, e: React.MouseEvent) => void;
}

/**
 * Display chips and tags according to orderSearchState.
 *
 * @param orderSearchState
 * @param handleDeleteAll - (optional) show button for resetting all except mandatory (date range)
 * @param handleDeleteChip - (optional) show button for deleting chip
 *
 */
const AMDHLDataFilterChipGroup: FC<AMDHLDataFilterChipGroupProps> = observer(({ attributes, handleDeleteAll, handleDeleteChip, handleEditChip }) => {
  const { t } = useTranslation('', { keyPrefix: 'chip' });
  return (
    <DHLDataFilterChipGroup name="chipGroup" resetText={handleDeleteAll && t('reset')} onReset={() => handleDeleteAll?.()}>
      {attributes.map((attr, index) => (
        <AMDHLChip
          key={`chip_attr_${index}`}
          name={`chip${attr.key}`}
          onClickDelete={!handleDeleteChip || attr.key === OrderSearchKey.Date ? undefined : (evt) => handleDeleteChip?.(attr, evt)}
          onClickEdit={!handleEditChip || attr.key === OrderSearchKey.Date ? undefined : (evt) => handleEditChip?.(attr, evt)}
        >
          <ChipContent attribute={attr} />
        </AMDHLChip>
      ))}
    </DHLDataFilterChipGroup>
  );
});

export default AMDHLDataFilterChipGroup;

type ChipContentProps = {
  attribute: OrderSearchAttribute;
};

const ChipContent: FC<ChipContentProps> = ({ attribute }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'chip' });
  let { value } = printAttribute(attribute);
  if (typeof attribute.value === 'number') {
    const preset = getDefaultDayRange(attribute.value);
    if (preset) {
      value = preset.getLocalization();
    }
  }
  if ('meta' in attribute && attribute.meta) {
    value += ' ' + attribute.meta;
  }
  return (
    <>
      {t(attribute.key)}: {value}
    </>
  );
};
