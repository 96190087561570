import React, { useLayoutEffect, useState } from 'react';
import { OrderCopy, OrderCopyProps } from './common/components/OrderCopy';
import { useSearchContext } from '../common/context/search/SearchContext';
import { Authorized } from 'common/components/Authorized/Authorized';
import { UserRights } from 'common/dtos/userRights';
import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { CustomOrderAlert } from 'common/components/PendingOrder/CustomOrderAlert';
import { useTranslation } from 'i18n';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { getDisableEditingAlertMsgId } from '../common/utils/alertHelper';

export const OrderCopyContainer: React.VFC<OrderCopyProps> = (props: OrderCopyProps) => {
  const [loaded, setLoaded] = useState(false);
  const { reset } = useSearchContext();
  const { order } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate');

  useLayoutEffect(() => {
    reset();
    setLoaded(true);
  }, []);

  if ((order as any)?.disableEditing) {
    return (
      <CustomOrderAlert
        headline={oc('headline.orderDisplay')}
        message={oc(getDisableEditingAlertMsgId(order, `notifications.disableEditingWarningMessage`))}
        title={oc('notifications.warning')}
      />
    );
  }

  return (
    <Authorized userRight={UserRights.OrderEdit}>
      {!loaded ? <ActivityIndicator testId={'activityIndicator'} /> : <OrderCopy {...props} />}
    </Authorized>
  );
};
