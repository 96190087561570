import React, { FC, useEffect, useState } from 'react';
import { toFormattedTime } from 'common/formatting';
import i18n, { useTranslation } from 'i18n';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { Order } from 'order/common/context/order/dtos/Order';
import { GetPressDistributionOrderRep, OrderCustomerRoleEnum } from 'generated';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { BaseProductRegion } from 'order/common/dtos/BaseProductRegion';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { PressDistributionDependentProduct } from 'order/common/dtos/PressDistributionDependentProduct';
import { usePressDistributionBaseProducts } from 'order/common/hooks/usePressDistributionBaseProducts';
import { usePressDistributionSupplementProducts } from 'order/common/hooks/usePressDistributionSupplementProducts';
import { dependentProductsAndVariants, PressBaseProduct } from 'order/productGroups/press/schema/pressSchema';
import { PressAdditionalServiceLetterProductName } from './PressAdditionalServiceLetterProductName';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import styles from './PressProductSection.module.css';
import { Supplements } from 'order/productGroups/press/components/SupplementInput/SupplementSchema';
import { PressIPContainerSection } from './PressIPContainerSection';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { getDateOnly } from 'common/utils/helpers';
import { Accordion, AccordionItemOverview, AccordionButtonOverview, AccordionButtonOverviewDisabled } from 'common/components/Accordion';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { IdAssignmentReadOnlyTable } from '../../QtyAllocation/IdAssignmentReadOnlyTable';
import { PalletsOverviewAccordian } from 'order/orderView/components/OrderView/PalletsOverview/PalletsOverviewAccordian/PalletsOverviewAccordian';
import moment from 'moment';

export type PressProductInfoSectionProps = {
  value: Order;
  orderMode?: OrderMode;
  pressDependentProducts?: PressDistributionDependentProduct[];
  nationalProdWithIBP?: string;
  supplements?: Supplements[];
};

export type PressDiscount = {
  baseProductNumber?: string;
  selectedDate?: Date;
  productNumber?: string;
  productGroup?: string;
  shipmentQuantity?: number;
};

export const PressProductInfoSection: FC<PressProductInfoSectionProps> = ({
  value,
  orderMode,
  pressDependentProducts,
  nationalProdWithIBP,
  supplements
}) => {
  const { t } = useTranslation('translation');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: tJournal } = useTranslation('translation', { keyPrefix: 'journal' });
  const { t: tOrderCreate } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const language = useAuthContext().user.language;

  const formattedDate = value?.orderDetail?.date ? getDateOnly(value.orderDetail.date) : undefined;
  const baseProductNumbers = (value as GetPressDistributionOrderRep).nationalProducts?.map((bp) => bp.productNumber ?? '');
  const fetchedBaseProductsByZkz = usePressDistributionBaseProducts(value?.journal?.zkz, formattedDate);
  const date = value.orderDetail?.date;
  const { facilities } = useProductionFacilities(date);
  const fetchSupplementProductsByZkz = usePressDistributionSupplementProducts(
    value?.journal?.zkz,
    baseProductNumbers,
    moment(date).format('yyyy-MM-DD')
  );
  const getDependentProduct = (productNumber?: string) => value.dependentProducts?.find(({ productNumber: p }) => p === productNumber);
  const facility = facilities.find(({ catalogId }) => catalogId === value.orderDetail?.productionPlantId);
  const contactForQuestions = value.parties.find((p) => p.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const { order, orderCategory, meta, orderCategoryProductKey, productGroup } = useOrderContext();
  const [zkzTitle, setTitleZkzTitle] = useState<string | undefined>('');

  useEffect(() => {
    if (meta?.zkzTitle) {
      setTitleZkzTitle(meta?.zkzTitle);
    }
  }, [meta?.zkzTitle]);

  // const hasZKZ = value?.supplements?.some((sp) => sp.type === 'MV' && !!sp.otherAttribute?.value);
  // const [zkzColumn, setZkzColumn] = useState(hasZKZ);

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });

  const computeAssignedSupplements = (np?: PressBaseProduct) => {
    // const sups: any = {};
    const supsArr: Supplements[] = [];
    np?.variants?.flatMap((variant) => {
      supplements?.map((sp) =>
        sp?.assignments?.forEach((a) => {
          if (a?.referenceShipmentId == variant?.referenceShipmentId) {
            if (sp?.productNumber) {
              // sups[sp?.productNumber] = sp;
              supsArr.push(sp);
            }
          }
        })
      );
    });
    return supsArr as Supplements[];
    // return Object.values(sups) as Supplements[];
  };
  const suppClassName = !isAvailableFromJan25(value?.orderDetail?.date) ? `${styles.suppTable}` : `${styles.variantsTable}`;
  const suppStyle = !isAvailableFromJan25(value?.orderDetail?.date) ? {} : { maxWidth: '80%' };

  return (
    <>
      {orderMode !== OrderMode.VIEW ? (
        <OrderOverviewRow>
          <OrderOverviewWidget width={1} title={tOrderCreate('orderInfo')}>
            <table>
              <tbody>
                <tr>
                  <td>{tOrderCreate('orderCategory') + ':'}</td>
                  {orderMode === OrderMode.CHANGE ? (
                    <td>
                      {order?.orderCategoryProductKey
                        ? translateCategory(order?.orderCategoryProductKey as string)
                        : orderCategoryProductKey
                        ? translateCategory(orderCategoryProductKey)
                        : orderCategory}
                    </td>
                  ) : (
                    <td>{orderCategory}</td>
                  )}
                </tr>
              </tbody>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}></OrderOverviewWidget>
          <OrderOverviewWidget width={1}></OrderOverviewWidget>
          {order?.orderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>{`${tOrderCreate('orderNumber')}:`}</td>
                  <td>{order?.orderId}</td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
          {order?.customerOrderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${tOrderCreate('customerOrderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.customerOrderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
      ) : (
        <></>
      )}
      <OrderOverviewRow>
        <OrderOverviewWidget title={tOrderCreate('pressArticle')}>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>{tJournal('zkz') + ':'}</p>
                </td>
                <td>
                  <p>{value?.journal?.zkz}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p data-testid="newspaperTitle">{tJournal('newspaperTitle')}</p>
                </td>
                <td>
                  <p>{zkzTitle}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tJournal('newspaperNumber')}</p>
                </td>
                <td>
                  <p>{value?.journal?.journalNumber}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tJournal('issueNumberFrom')}</p>
                </td>
                <td>
                  {
                    <p>
                      {value?.journal?.issueNumberRange?.from?.issueNumber && value?.journal?.issueNumberRange?.from?.year
                        ? value?.journal?.issueNumberRange?.from?.issueNumber + '/' + value?.journal?.issueNumberRange?.from?.year
                        : ''}
                    </p>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tJournal('issueNumberTo')}</p>
                </td>
                <td>
                  <p>
                    {value?.journal?.issueNumberRange?.to?.issueNumber && value?.journal?.issueNumberRange?.to?.year ? (
                      <p>{value?.journal?.issueNumberRange?.to?.issueNumber + '/' + value?.journal?.issueNumberRange?.to?.year}</p>
                    ) : (
                      <></>
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tJournal('postingIdentifier')}</p>
                </td>
                <td>
                  <p>
                    {value.journal?.postingIdentifier === 'R'
                      ? tJournal('regularShipping')
                      : value.journal?.postingIdentifier === 'S'
                      ? tJournal('specialNumber')
                      : ''}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
        <OrderOverviewWidget title="Zusatzleistungen">
          <p>
            {getDependentProduct(
              pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.trackAndMatch)
                ?.productNumber
            ) && 'Track & Match'}
          </p>
          <p>
            {getDependentProduct(
              pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.premium)
                ?.productNumber
            ) && 'Premiumaddress'}
          </p>
          <p>
            {getDependentProduct(
              pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.coverPlus)
                ?.productNumber
            ) && 'CoverPlus'}
          </p>
          <p>
            {getDependentProduct(
              pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.sortingService)
                ?.productNumber
            ) && 'Sortierservice'}
          </p>
          <p>
            {getDependentProduct(
              pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.additionalFeeSLN)
                ?.productNumber
            ) && 'Zusatzentgelt SLN'}
          </p>
        </OrderOverviewWidget>
        {value.productGroup !== ProductGroup.DIALOGPOSTWURFSPEZIAL ? (
          <OrderOverviewWidget title={t('furtherProductFeatures')}>
            {value.variants
              ?.filter((data) => data !== LineItemVariant.NKD)
              .map((variant) => (
                <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
              ))}
          </OrderOverviewWidget>
        ) : (
          <></>
        )}
      </OrderOverviewRow>
      {value?.nationalProducts?.map((np, i) => {
        return (
          <div key={i} style={{ borderBottom: '1px solid #e6e6e6', padding: '25px 0' }}>
            {orderMode !== OrderMode.VIEW ? <h4 className={styles.orderTitle}>{`Auftrag ${i + 1}`}</h4> : <></>}
            <div style={{ maxWidth: '60%' }}>
              <DHLHeadline type={'h6'}>{`Nationale Produkte`}</DHLHeadline>
              <table className={styles.tableNP}>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: '7px' }}>{t('Basisprodukt')}:</td>
                    <td style={{ paddingBottom: '7px', paddingLeft: orderMode === OrderMode.VIEW ? '85px' : '90px' }}>
                      {
                        fetchedBaseProductsByZkz?.filter((p) => p?.productNumber === np?.productNumber && p.region === BaseProductRegion.NATIONAL)[0]
                          ?.descriptionShort
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>{oc('totalShipmentWeight')}:</td>
                    <td style={{ paddingLeft: orderMode === OrderMode.VIEW ? '85px' : '90px' }}>
                      {np?.variants
                        ?.reduce((acc, curr) => {
                          return acc + (curr?.quantity || 0);
                        }, 0)
                        ?.toLocaleString(language)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <OrderOverviewWidget width={1}>
              <table className={styles.variantsTable} style={{ maxWidth: '80%' }}>
                <thead>
                  <tr>
                    <th>{t('Bezeichnung')}</th>
                    <th>{'Zugeordnete Beilage'}</th>
                    <th style={{ textAlign: 'right' }}>{t('Sendungsmenge')}</th>
                    <th style={{ textAlign: 'right' }}>{t('Einzelgewicht in g')}</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {np?.variants?.map((variant, j) => {
                      const assignedSupplements: string[] = [];
                      supplements?.flatMap((sp) =>
                        sp?.assignments?.forEach((a) =>
                          a?.referenceShipmentId == variant.referenceShipmentId && sp?.description
                            ? assignedSupplements.push(sp?.description)
                            : undefined
                        )
                      );
                      return (
                        <>
                          <tr key={`nationalProductRow${j}`} id={`nationalProductRowId${j}`}>
                            <td>
                              <p style={{ wordBreak: 'break-all' }}>{variant?.description}</p>
                            </td>
                            <td style={{ wordBreak: 'break-all' }}>{assignedSupplements?.length ? assignedSupplements?.join(', ') : 'Keine'}</td>
                            <td style={{ textAlign: 'right' }}>
                              <p>{variant?.quantity?.toLocaleString(language)}</p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>{variant?.weight?.toLocaleString(language)}</p>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                </tbody>
              </table>
              {supplements?.length !== 0 && (
                <div>
                  {/* {computeAssignedSupplements(np)} */}
                  {computeAssignedSupplements(np)?.map((supp, i) => {
                    return (
                      <>
                        {i == 0 ? <DHLHeadline type={'h6'}>{t('Beilagen')}</DHLHeadline> : ''}
                        <table className={suppClassName} style={suppStyle} key={i}>
                          {i == 0 ? (
                            <tr>
                              <th>{t('Bezeichnung')}</th>
                              <th>{t('Beilage')}</th>
                              <th style={{ textAlign: 'right' }}>{t('Sendungsmenge')}</th>
                              <th style={{ textAlign: 'right' }}>{t('Einzelgewicht in g')}</th>
                              {!isAvailableFromJan25(value?.orderDetail?.date) && (
                                <th style={{ textAlign: 'right', paddingRight: '60px' }}>{t('ZKZ')}</th>
                              )}
                            </tr>
                          ) : (
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              {!isAvailableFromJan25(value?.orderDetail?.date) && <th></th>}
                            </tr>
                          )}
                          <tbody>
                            <tr>
                              <td style={{ wordBreak: 'break-all', paddingRight: '20px' }}>{supp?.description}</td>
                              <td style={{ wordBreak: 'break-all' }}>
                                <p>
                                  {fetchSupplementProductsByZkz?.filter(
                                    (p) => p.supplementType === supp?.type && p.productNumber === supp?.productNumber
                                  )[0]?.descriptionShort ?? supp?.productNumber}
                                </p>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <p>
                                  {(supp as Supplements)?.assignments
                                    ?.filter((a) => np?.variants?.find((v) => v?.referenceShipmentId == a?.referenceShipmentId))
                                    ?.map((a) => a?.quantity)
                                    ?.reduce((acc, curr) => (acc || 0) + (curr || 0), 0)
                                    ?.toLocaleString(language)}
                                </p>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <p>{supp?.weight?.toLocaleString(language)}</p>
                              </td>
                              {supp?.type == 'MV' && !!supp?.otherAttribute?.value && (
                                <td style={{ textAlign: 'right', paddingRight: '60px' }}>{supp?.otherAttribute?.value}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </>
                    );
                  })}
                </div>
              )}
              {orderMode === OrderMode.VIEW ? (
                <div style={{ marginTop: supplements?.length !== 0 ? 0 : '-15px', marginBottom: '-15px' }}>
                  <OrderOverviewRow>
                    <OrderOverviewWidget width={3}>
                      <div className={styles.shipmentPlanAccod}>
                        {value?.destinations?.length !== 0 ? (
                          <Accordion allowMultiple={true}>
                            {(register) => (
                              <>
                                <AccordionItemOverview {...register}>
                                  <AccordionButtonOverview disabled={!value.destinations?.length}>
                                    {tOrderCreate('deliveryPlan')}
                                  </AccordionButtonOverview>
                                  <IdAssignmentReadOnlyTable
                                    header={value.orderCategory === OrderCategory.AFM_BRIEF ? 'machineCode' : 'frankingIdPrefix'}
                                    ranges={np?.shipmentPlan}
                                    isLetter={true}
                                    readOnly={true}
                                    frankingIdPrefix={value.postage?.machineCode ?? value.postage?.frankingIdPrefix}
                                    frankingIdEncoding={value.postage?.frankingIdEncoding}
                                    machineCode={value.orderCategory === OrderCategory.AFM_BRIEF ? value.postage?.machineCode : undefined}
                                    postageFrankingIdPrefix={
                                      value.orderCategory === OrderCategory.DV_BRIEF ? value.postage?.frankingIdPrefix : undefined
                                    }
                                    showShipmentQtyColumn
                                    meta={{ language }}
                                    id={`${np?.productNumber}${i}`}
                                    showShipmentPlanExistsText={np?.shipmentPlan?.some((sp) => sp?.bid)}
                                  />
                                </AccordionItemOverview>
                              </>
                            )}
                          </Accordion>
                        ) : (
                          <AccordionButtonOverviewDisabled>{tOrderCreate('deliveryPlan')}</AccordionButtonOverviewDisabled>
                        )}
                      </div>
                    </OrderOverviewWidget>

                    <OrderOverviewWidget title={oc('bundles')}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p>{oc('typeOfBundle')}</p>
                            </td>
                            <td>
                              <p>{np?.packaging?.type && tCatalogValues('mailEntityType.display.' + np?.packaging?.type)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>{oc('numberOfBundles')}</p>
                            </td>
                            <td>
                              <p>{np?.packaging?.quantity?.toLocaleString(language)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>{oc('totalGrossWeightInKg')}</p>
                            </td>
                            <td>
                              <p>{np?.destinations?.totalWeight?.toLocaleString(language)} kg</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>{oc('stackable')}</p>
                            </td>
                            <td>
                              <p>{np?.destinations?.stackable ? 'ja' : 'nein'}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </OrderOverviewWidget>
                  </OrderOverviewRow>
                  {value?.destinations?.length ? (
                    <div style={{ marginBottom: '-25px' }}>
                      <PalletsOverviewAccordian destinations={order?.destinations || []} id={`NpPalletsTable`} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {np?.baseProductType === nationalProdWithIBP && orderMode !== OrderMode.VIEW ? (
                <div>
                  {value?.internationalProducts?.flatMap((ip, k) => {
                    return (
                      <>
                        <div key={`${k}`} className={styles.ipContent}>
                          <DHLHeadline type={'h6'}>{`Internationale Produkte`}</DHLHeadline>
                          <OrderOverviewWidget width={3}>
                            <tr>
                              <td>
                                <p>{t('Basisprodukt')}:</p>
                              </td>
                              <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                                <p>
                                  {
                                    fetchedBaseProductsByZkz?.filter(
                                      (p) => p.productNumber === ip?.productNumber && p.region === BaseProductRegion.INTERNATIONAL
                                    )[0]?.descriptionShort
                                  }
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>{oc('totalShipmentWeight')}:</p>
                              </td>
                              <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                                {ip?.variants
                                  ?.reduce((acc, curr) => {
                                    return acc + (curr?.quantity || 0);
                                  }, 0)
                                  ?.toLocaleString(language)}
                              </td>
                            </tr>
                          </OrderOverviewWidget>
                          {ip?.discounts && ip?.discounts?.length > 0 && (
                            <OrderOverviewWidget width={3}>
                              <tr>
                                <td>{oc('additionalLetterService')}: </td>
                                <td style={{ paddingLeft: '120px' }}>
                                  {ip?.discounts?.map((d, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <p>
                                            <PressAdditionalServiceLetterProductName
                                              baseProductNumber={ip?.productNumber}
                                              selectedDate={date}
                                              productNumber={d?.productNumber}
                                              productGroup={value.productGroup}
                                            />
                                          </p>
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          <p
                                            style={{
                                              paddingLeft: '15px'
                                            }}
                                          >
                                            {d.shipmentQuantity}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </td>
                              </tr>
                            </OrderOverviewWidget>
                          )}
                          <table style={{ marginTop: '15px' }}>
                            <thead>
                              <tr>
                                <th>{t('Bezeichnung')}</th>
                                <th style={{ textAlign: 'right' }}>{t('Sendungsmenge')}</th>
                                <th style={{ textAlign: 'right' }}>{t('Einzelgewicht in g')}</th>
                              </tr>
                            </thead>
                            {ip.variants?.map((variant, j) => {
                              return (
                                <tbody key={j}>
                                  <tr key={`interNationalProductRow${j}`} id={`interNationalProductRowId${j}`}>
                                    <td style={{ wordBreak: 'break-all' }}>
                                      <p>{variant?.description}</p>
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                      <p>{variant?.quantity?.toLocaleString(language)}</p>
                                    </td>

                                    <td style={{ textAlign: 'right' }}>
                                      <p>{variant?.weight?.toLocaleString(language)}</p>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                        <div className={styles.ipSeparator}></div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </OrderOverviewWidget>
          </div>
        );
      })}

      {orderMode === OrderMode.VIEW ? (
        <div>
          {value?.internationalProducts?.flatMap((ip, k) => {
            return (
              <>
                <div key={`${k}`} style={{ maxWidth: '46%', paddingBottom: '10px' }} className={`${styles.ipContent} ${styles.ipContentOverview}`}>
                  <DHLHeadline type={'h6'}>{`Internationale Produkte`}</DHLHeadline>
                  <OrderOverviewWidget width={1}>
                    <tr>
                      <td>
                        <p>{t('Basisprodukt')}:</p>
                      </td>
                      <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                        <p>
                          {
                            fetchedBaseProductsByZkz?.filter(
                              (p) => p.productNumber === ip?.productNumber && p.region === BaseProductRegion.INTERNATIONAL
                            )[0]?.descriptionShort
                          }
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{oc('totalShipmentWeight')}:</p>
                      </td>
                      <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                        {ip?.variants
                          ?.reduce((acc, curr) => {
                            return acc + (curr?.quantity || 0);
                          }, 0)
                          ?.toLocaleString(language)}
                      </td>
                    </tr>
                  </OrderOverviewWidget>
                  {ip?.discounts && ip?.discounts?.length > 0 && (
                    <OrderOverviewWidget width={3}>
                      <tr>
                        <td>{oc('additionalLetterService')}: </td>
                        <td style={{ paddingLeft: '120px' }}>
                          {ip?.discounts?.map((d, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <PressAdditionalServiceLetterProductName
                                      baseProductNumber={ip?.productNumber}
                                      selectedDate={date}
                                      productNumber={d?.productNumber}
                                      productGroup={value.productGroup}
                                    />
                                  </p>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <p
                                    style={{
                                      paddingLeft: '15px'
                                    }}
                                  >
                                    {d.shipmentQuantity}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </td>
                      </tr>
                    </OrderOverviewWidget>
                  )}
                  <table style={{ marginTop: '15px', width: '100%' }}>
                    <thead>
                      <tr>
                        <th>{t('Bezeichnung')}</th>
                        <th style={{ textAlign: 'right' }}>{t('Sendungsmenge')}</th>
                        <th style={{ textAlign: 'right' }}>{t('Einzelgewicht in g')}</th>
                      </tr>
                    </thead>
                    {ip?.variants?.map((variant, j) => {
                      return (
                        <tbody key={j}>
                          <tr key={`interNationalProductRow${j}`} id={`interNationalProductRowId${j}`}>
                            <td style={{ wordBreak: 'break-all' }}>
                              <p>{variant?.description}</p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>{variant?.quantity?.toLocaleString(language)}</p>
                            </td>

                            <td style={{ textAlign: 'right' }}>
                              <p>{variant?.weight?.toLocaleString(language)}</p>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
                <div className={styles.ipSeparatorOverview}></div>
              </>
            );
          })}
        </div>
      ) : (
        <></>
      )}

      {orderMode === OrderMode.VIEW && order?.internationalProducts?.length !== 0 ? <PressIPContainerSection /> : <></>}

      {!value?.nationalProducts?.length && value?.internationalProducts?.length && orderMode !== OrderMode.VIEW ? (
        <div style={{ borderBottom: '1px solid #e6e6e6', padding: '25px 0 0 0' }}>
          {<DHLHeadline className={styles.singleIPTitle} type={'h4'}>{`Auftrag 1`}</DHLHeadline>}
          <OrderOverviewWidget width={1}>
            {value?.internationalProducts?.map((ip, k) => {
              return (
                <>
                  <div key={`${k}`} className={styles.ipContent}>
                    <DHLHeadline type={'h6'}>{`Internationale Produkte`}</DHLHeadline>
                    <OrderOverviewWidget width={3}>
                      <tr>
                        <td>
                          <p>{t('Basisprodukt')}:</p>
                        </td>
                        <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                          <p>
                            {
                              fetchedBaseProductsByZkz?.filter(
                                (p) => p.productNumber === ip?.productNumber && p.region === BaseProductRegion.INTERNATIONAL
                              )[0]?.descriptionShort
                            }
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>{oc('totalShipmentWeight')}:</p>
                        </td>
                        <td style={{ textAlign: 'left', paddingLeft: '90px' }}>
                          {ip?.variants
                            ?.reduce((acc, curr) => {
                              return acc + (curr?.quantity || 0);
                            }, 0)
                            ?.toLocaleString(language)}
                        </td>
                      </tr>
                    </OrderOverviewWidget>
                    {ip?.discounts && ip?.discounts?.length > 0 && (
                      <OrderOverviewWidget width={3}>
                        <tr>
                          <td>{oc('additionalLetterService')}: </td>
                          <td style={{ paddingLeft: '120px' }}>
                            {ip?.discounts?.map((d, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <p>
                                      <PressAdditionalServiceLetterProductName
                                        baseProductNumber={ip?.productNumber}
                                        selectedDate={date}
                                        productNumber={d?.productNumber}
                                        productGroup={value.productGroup}
                                      />
                                    </p>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <p
                                      style={{
                                        paddingLeft: '15px'
                                      }}
                                    >
                                      {d.shipmentQuantity}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </td>
                        </tr>
                      </OrderOverviewWidget>
                    )}
                    <table style={{ marginTop: '15px' }}>
                      <thead>
                        <tr>
                          <th>{t('Bezeichnung')}</th>
                          <th style={{ textAlign: 'right' }}>{t('Sendungsmenge')}</th>
                          <th style={{ textAlign: 'right' }}>{t('Einzelgewicht in g')}</th>
                        </tr>
                      </thead>
                      {ip.variants?.map((variant, j) => {
                        return (
                          <tbody key={j}>
                            <tr key={`interNationalProductRow${j}`} id={`interNationalProductRowId${j}`}>
                              <td style={{ wordBreak: 'break-all' }}>
                                <p>{variant?.description}</p>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <p>{variant?.quantity?.toLocaleString(language)}</p>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <p>{variant?.weight?.toLocaleString(language)}</p>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                  <div className={styles.ipSeparator}></div>
                </>
              );
            })}
          </OrderOverviewWidget>
        </div>
      ) : (
        <></>
      )}
      <div style={{ borderBottom: '1px solid #e6e6e6' }}>
        <OrderOverviewRow>
          <OrderPickupShippingDisplay
            data={{
              date: date,
              pickup: !!value.pickupAddress,
              time:
                orderMode === OrderMode.VIEW
                  ? toFormattedTime(date)
                  : date &&
                    new Date(date).toLocaleTimeString('de-DE', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hourCycle: 'h23'
                      // timeZone: 'UTC'
                    }),
              facility,
              submissionType: value?.orderDetail?.submissionType,
              pickupAddress: value.pickupAddress,
              contactForQuestions: contactForQuestions
            }}
          />
        </OrderOverviewRow>
      </div>
      {/* {orderMode === OrderMode.VIEW && value.destinations?.length ? (
        <OrderOverviewRow>
          <DestinationDetailSection destinations={value.destinations || []} />
        </OrderOverviewRow>
      ) : (
        <></>
      )} */}
    </>
  );
};
