// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableOptions_contextMenu__WIqhx {
  left: auto;
  right: 0;
  top: 12px;
  position: absolute
}

.tableOptions_overflowScroll__gG3j4 {
  max-height: 15rem;
  overflow-y: scroll;
}
.tableOptions_anchorContextmenu__rdNKQ {
  top: 47px;
  bottom: auto;
  left: auto;
  /* right: 0; */
}
.tableOptions_positionUnset__cfLXD{
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/common/components/Table/options/tableOptions.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;EACT;AACF;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,YAAY;EACZ,UAAU;EACV,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".contextMenu {\n  left: auto;\n  right: 0;\n  top: 12px;\n  position: absolute\n}\n\n.overflowScroll {\n  max-height: 15rem;\n  overflow-y: scroll;\n}\n.anchorContextmenu {\n  top: 47px;\n  bottom: auto;\n  left: auto;\n  /* right: 0; */\n}\n.positionUnset{\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenu": `tableOptions_contextMenu__WIqhx`,
	"overflowScroll": `tableOptions_overflowScroll__gG3j4`,
	"anchorContextmenu": `tableOptions_anchorContextmenu__rdNKQ`,
	"positionUnset": `tableOptions_positionUnset__cfLXD`
};
export default ___CSS_LOADER_EXPORT___;
