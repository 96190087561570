import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

import AMDHLDataFilterChipGroup from './AMDHLDataFilterChipGroup';
import { DefaultAttribute, OrderSearchAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { useSearchContext } from '../../../common/context/search/SearchContext';
import { useTableContext } from 'order/common/context/table/TableContext';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { useAlerts } from 'common/AlertContext';

import styles from './OrderSearchFilter.module.css';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';

interface Props {
  children: ReactNode;
  additionalSpace?: string;
  disabled?: boolean;
  meta?: Record<string, any>;
}

type EnhancedChildProps = {
  resetChild: boolean;
  resetCallback: () => void;
};

export function OrderSearchFilterContainer({ children, additionalSpace, disabled, meta }: Props): JSX.Element {
  const {
    activeSearchUpdated,
    attributes,
    searchType,
    setSearchType,
    editAttribute,
    loading,
    reset,
    removeAttribute,
    search,
    sortedAttributes,
    addAttribute,
    metadata,
    tab,
    triggerLastSearch,
    upsertMetaData
  } = useSearchContext();
  const { clear } = useAlerts();
  const { setRows, setLoadingState } = useTableContext();
  const { resetAllocations, resetOrderReferences } = useOrderContext();
  const [resetChild, setResetChild] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('', { keyPrefix: 'orderSearch.button' });

  useEffect(() => {
    if (searchType === OrderSearchType.MERGE || searchType === OrderSearchType.PARTIAL || searchType === OrderSearchType.COLLECTION_LETTER) {
      setSearchType(tab as OrderSearchType);
      triggerLastSearch();
    }
  }, []);

  useEffect(() => {
    if (searchType !== OrderSearchType.SIMPLE && attributes.length >= 2) {
      setDisableBtn(false);
    } else if (searchType === OrderSearchType.SIMPLE) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [attributes.length]);

  const handleAllChipsDelete = () => {
    setRows([]);
    reset();
    clear();
    if (searchType === OrderSearchType.MERGE) {
      resetAllocations();
      resetOrderReferences();
    }
    setLoadingState(TableLoadingState.NOT_LOADED);
    setResetChild(true);
  };
  const handleChipDelete = (attrIdx: OrderSearchAttribute) => removeAttribute(attrIdx);
  const handleEditChip = (attrIdx: DefaultAttribute) => editAttribute(attrIdx);
  const simpleChange1 = () => {
    const newAttributes = metadata?.simpleChange && addAttribute(metadata?.simpleChange);
    if (newAttributes?.length === 1) {
      clear();
      search(searchType, newAttributes);
      upsertMetaData('parentInputValue', {
        value: '',
        key: OrderSearchKey.OrderId
      });
    }
  };

  const handleSubmit = async () => {
    clear();
    attributes.length === 0 && simpleChange1();
    search(searchType, attributes, { meta });
  };

  const enhancedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement<EnhancedChildProps>(child)) {
      return React.cloneElement<EnhancedChildProps>(child, {
        resetChild: resetChild,
        resetCallback: () => setResetChild(false)
      });
    }
    return child;
  });

  return (
    <div className={classNames('dhlDataFilterContainer')}>
      <div className={styles.filters}>{enhancedChildren}</div>
      <AMDHLDataFilterChipGroup
        attributes={sortedAttributes()}
        handleDeleteChip={handleChipDelete}
        handleEditChip={handleEditChip}
        handleDeleteAll={handleAllChipsDelete}
      />
      <div className={'row ' + `${additionalSpace || ''}`}>
        <div className="col text-center" ref={buttonWrapperRef}>
          <DHLButton
            name="search"
            disabled={disabled || loading || disableBtn}
            onClick={handleSubmit}
            label={activeSearchUpdated ? t('update') : t('start')}
            type="primary"
          />
        </div>
      </div>
    </div>
  );
}
