// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchHistory_searchHistory__SXFzu {
  background-color: #f5f5f5;
  padding: 20px 27px 27px 27px;
}

.SearchHistory_searchHistory__SXFzu > * + * {
  margin: 10px 0 0;
}

.SearchHistory_savedHeaderContainer__rERJ9 {
  display: flex;
}

.SearchHistory_subMessage__nFlJ7 {
  font-weight: 500;
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  font-size: 16px;
  color: #818181;
  margin-left: 8px;
  margin-top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/SearchHistory.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".searchHistory {\n  background-color: #f5f5f5;\n  padding: 20px 27px 27px 27px;\n}\n\n.searchHistory > * + * {\n  margin: 10px 0 0;\n}\n\n.savedHeaderContainer {\n  display: flex;\n}\n\n.subMessage {\n  font-weight: 500;\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  font-size: 16px;\n  color: #818181;\n  margin-left: 8px;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchHistory": `SearchHistory_searchHistory__SXFzu`,
	"savedHeaderContainer": `SearchHistory_savedHeaderContainer__rERJ9`,
	"subMessage": `SearchHistory_subMessage__nFlJ7`
};
export default ___CSS_LOADER_EXPORT___;
