import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import classes from './order-step-section.module.css';

export type OrderStepSectionProps = PropsWithChildren<{
  headline?: ReactNode;
  sub?: boolean;
  className?: string;
}>;

export const OrderStepSection: React.VFC<OrderStepSectionProps> = (props) => (
  <section
    className={classNames(
      classes.wrapper,
      {
        [classes.sub]: props.sub
      },
      props.className
    )}
  >
    {props?.headline && (
      <DHLHeadline type="h6" color="gray">
        {props.headline}
      </DHLHeadline>
    )}
    {props.children}
  </section>
);
