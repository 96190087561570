import React, { useLayoutEffect, useState } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { Age, AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomDeleteCellForVariantList = (props: Props): JSX.Element => {
  const { setValue, control } = useFormContext();
  const { orderReferences, deleteAllocation, order } = useOrderContext();
  const [disabled, setDisabled] = useState(props?.row?.data?.orderNumber === order?.orderId);

  const [selectedSearchedOrderIds] = useWatch({
    control,
    name: ['selectedSearchedOrderIds']
  });

  // useLayoutEffect(() => {
  //   if (props.row.age === Age.OLD) {
  //     const curRef = orderReferences.find((r) => r.orderId === props?.row?.data?.orderNumber);
  //     if (curRef?.pendingIds) {
  //       setDisabled(true);
  //     }
  //   }
  // }, []);

  const handleDeleteCell = () => {
    const newSelectedAlloc = (selectedSearchedOrderIds || []).filter((s: number) => s !== props.row.id);
    setValue('selectedSearchedOrderIds', newSelectedAlloc);
    deleteAllocation(props.row.data?.orderNumber || '');
  };

  return (
    <DHLButton
      name={props.row.data?.orderNumber + '-details'}
      type="default"
      icon="delete"
      onClick={handleDeleteCell}
      iconPosition="icon"
      disabled={disabled}
    />
  );
};

export const customDeleteRendererForOrderMergeVariantsList = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomDeleteCellForVariantList value={value} row={row} column={column} />;
};
