import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';

import styles from './Payer.module.css';
import { LetterOrderCreate } from '../../schemea/LetterSchema';
import { TakeOriginatorAsPayer } from 'order/common/components/TakeOriginatorAsPayer/TakeOriginatorAsPayer';

export const Payer: FC = () => {
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });

  return (
    <section>
      <DHLHeadline type="h6">{oc('payer')}</DHLHeadline>
      <TakeOriginatorAsPayer hasTooltip={true} />
      <div className={classNames(styles.payerInputContainer)}>
        <CustomerSection<LetterOrderCreate>
          inputName="payer"
          customerRole={OrderSearchKey.Originator}
          errorMessage={t('error.customerIdUnknown')}
          rules={{
            required: true
          }}
          data-testid={'payerInput'}
        />
      </div>
    </section>
  );
};
