import { DHLButton, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { SFCProps } from 'common/utils/formHelpers';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './contact-section.module.css';

export type ContactSectionProps<T extends FieldValues> = SFCProps<T, ContactSchema | undefined> & {
  disabled?: boolean;
};

export const ContactSection = <T extends FieldValues>({ name, disabled }: ContactSectionProps<T>): ReactElement => {
  const [showContact, setShowContact] = useState(false);
  const { getValues, resetField } = useFormContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });

  const inputValue = getValues([`${name}.firstName`, `${name}.lastName`, `${name}.telephone`, `${name}.email`]);
  const inputDataEmpty = !inputValue[0] && !inputValue[1] && !inputValue[2] && !inputValue[3];

  useEffect(() => {
    !inputDataEmpty ? setShowContact(true) : setShowContact(false);
  }, [inputDataEmpty]);

  const onDelete = () => {
    setShowContact(false);
    resetField(name, { defaultValue: {} as any });
  };

  if (!showContact) {
    return (
      <OrderStepSection>
        <Controller
          name={`${name}.onclick`}
          render={({ field }) => (
            <DHLButton
              name={`${field.name}.add`}
              label={oc('contactPersonForQueries')}
              type={'ghost'}
              iconPosition={'icon-first'}
              icon={'plus'}
              onClick={() => {
                setShowContact(true);
                field.onChange({
                  contact: {}
                });
              }}
              disabled={disabled}
              size="sm"
            />
          )}
        />
      </OrderStepSection>
    );
  }
  return (
    <OrderStepSection>
      <div className={classes.row}>
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'} className="m-0">
          {oc('contactPersonForQueries')}
        </DHLHeadline>
        <Controller
          name={`${name}.ondelete`}
          render={({ field }) => (
            <DHLButton
              name={`${field.name}.delete`}
              label={'deleteIconButton'}
              iconPosition={'icon'}
              icon={'delete'}
              onClick={onDelete}
              disabled={disabled}
            />
          )}
        />
      </div>
      <FormRow mode="two-col">
        <TextInput name={`${name}.firstName`} label={oc('firstName')} disabled={disabled} type="text" />
        <TextInput name={`${name}.lastName`} label={oc('lastName')} disabled={disabled} type="text" />
      </FormRow>
      <FormRow mode="two-col">
        <TextInput name={`${name}.telephone`} label={oc('phoneNumber')} disabled={disabled} />
        <TextInput name={`${name}.email`} label={oc('email')} disabled={disabled} type="email" />
      </FormRow>
    </OrderStepSection>
  );
};
