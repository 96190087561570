import { createApi, Endpoint, responseOrUndefined } from 'common/utils/apiUtils';
import { GetOrderRep, OrderOperationsApiFactory, OrderSearchResultRep, OrderTreeItem as GOTI, SearchOrder, SearchOrderRep } from 'generated';

export type OrderReplyTDO = SearchOrderRep;
export type OrderSearchTDO = SearchOrder;

export type OrderTreeItem<T> = Pick<GOTI, 'id'> & {
  data?: T;
  children?: OrderTreeItem<T>[];
  autoExpanded?: boolean;
  highlight?: boolean;
};

export type OrderSearchResultRepTDO = Omit<OrderSearchResultRep, 'orders'> & {
  orders?: OrderTreeItem<OrderReplyTDO>[];
};

const { createDialogPostOrder, createLetterOrder, createPartialOrder, createPressOrder, createCollectionOrder, createInternationalOrder } =
  createApi(OrderOperationsApiFactory);
const {
  changeDialogPostOrder,
  changeLetterOrder,
  changePartialOrder,
  changePressOrder,
  changeCollectionOrder,
  changePostaktuellOrder,
  changeInternationalOrder
} = createApi(OrderOperationsApiFactory);
const { getOrder1: internalGetOrder } = createApi(OrderOperationsApiFactory);
const { searchOrders } = createApi(OrderOperationsApiFactory);
const { cancelOrder } = createApi(OrderOperationsApiFactory);
const { splitOrder } = createApi(OrderOperationsApiFactory);
const { movePallet } = createApi(OrderOperationsApiFactory);
const { mergeOrder } = createApi(OrderOperationsApiFactory);
const { getOrderVersion } = createApi(OrderOperationsApiFactory);
const { getInternationalProducts: internationalProducts } = createApi(OrderOperationsApiFactory);

export {
  createDialogPostOrder,
  createLetterOrder,
  createPartialOrder,
  createPressOrder,
  createCollectionOrder,
  cancelOrder,
  changeDialogPostOrder,
  changeLetterOrder,
  changePartialOrder,
  changePressOrder,
  changeCollectionOrder,
  changePostaktuellOrder,
  createInternationalOrder,
  splitOrder,
  movePallet,
  mergeOrder,
  getOrderVersion,
  internationalProducts,
  changeInternationalOrder
};
export const getOrder = async (...args: Parameters<typeof internalGetOrder>): Promise<GetOrderRep | undefined> =>
  responseOrUndefined(internalGetOrder(...args));

export const searchOrder: Endpoint<OrderSearchTDO, OrderSearchResultRepTDO> = async (orderSearch) => responseOrUndefined(searchOrders(orderSearch));
