import {
  AllHTMLAttributes,
  createElement,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  PropsWithChildren,
  PropsWithoutRef,
  RefAttributes
} from 'react';
import classNames, { Argument } from 'classnames';

export type FunctionOrReturn<P, R> = ((props: P) => R) | R;

export const reference = <T extends HTMLElement, P extends AllHTMLAttributes<T> = AllHTMLAttributes<T>>(
  displayName: string,
  type: string,
  classMap: FunctionOrReturn<P, Argument>,
  propFilter: FunctionOrReturn<P, Partial<P>> = (p) => p
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>> => {
  const componentRef = forwardRef<T, P>((props: P, ref: ForwardedRef<T>): JSX.Element => {
    const { children, ...filteredProps } = typeof propFilter === 'function' ? propFilter(propsWithRefAndClasses(props, ref, classMap)) : propFilter;
    return createElement(type, filteredProps, children);
  });
  componentRef.displayName = displayName;
  return componentRef;
};

const propsWithRefAndClasses = <T extends HTMLElement, P extends HTMLProps<T>>(
  props: PropsWithChildren<P>,
  ref: ForwardedRef<T>,
  classMap: FunctionOrReturn<PropsWithChildren<P>, Argument>
) => ({
  ...props,
  className: classNames(props.className, typeof classMap === 'function' ? classMap(props) : classMap),
  ref
});
