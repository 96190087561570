import React, { FC, InputHTMLAttributes, useState } from 'react';
import Styles from './Checkbox.module.css';
import classNames from 'classnames';

export interface checkboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  datatestid?: string;
  verticalCenter?: boolean;
}
export const Checkbox: FC<checkboxProps> = (props) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);
  };

  return (
    <div className={classNames(Styles.checkboxWrapper, props.verticalCenter && Styles.centerItem)}>
      <label className={classNames(Styles.alignCenter, props.disabled && Styles.cursorNotAllowed)}>
        <input
          {...props}
          className={classNames(props.checked ? Styles.checked : '', props.disabled && Styles.cursorNotAllowed)}
          type="checkbox"
          name={props.name}
          checked={!!props.checked}
          defaultChecked={!!props.defaultChecked}
          onChange={onChangeHandler}
          data-testid={props?.datatestid}
          disabled={props?.disabled}
        />
        <span>{props?.label}</span>
      </label>
    </div>
  );
};
