import { DHLFormGroup, DHLHeadline, DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import { paramCase } from 'change-case';
import React, { useEffect, VFC } from 'react';
import styles from './ZahlungspflichtigerNichtBearbeitbar.module.css';
import { useFormContext } from 'react-hook-form';
import { PressOrderCreate } from '../../schema/pressSchema';

export type ZahlungspflichtigerInfo = {
  kundennummer?: string;
  kundenname?: string;
  kontoIban?: string;
  kontoBankName?: string;
};

export type ZahlungspflichtigerNichtBearbeitbarProps = {
  name?: string;
  fieldTitle: string;
  fieldPlaceholder: string;
  infoIcon: string;
  customerInfo?: ZahlungspflichtigerInfo;
};

export const ZahlungspflichtigerNichtBearbeitbar: VFC<ZahlungspflichtigerNichtBearbeitbarProps> = ({
  name,
  fieldTitle,
  fieldPlaceholder,
  infoIcon,
  customerInfo
}) => {
  const { setValue } = useFormContext<PressOrderCreate>();
  useEffect(() => {
    setValue('payer', customerInfo?.kundennummer ?? '');
  }, [customerInfo]);
  return (
    <section>
      <header>
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
          {fieldTitle}
        </DHLHeadline>
      </header>
      <DHLFormGroup className="row">
        <section className={styles.container} data-testid="ZahlungspflichtigerNichtBearbeitbar-input">
          <DHLTextInput
            className={styles.flex50pct}
            name={paramCase(`${name}-text`)}
            value={customerInfo?.kundennummer}
            type="text"
            placeholder={fieldPlaceholder}
            label={fieldPlaceholder}
            disabled={true}
          />
          <section className={`${styles.flex50pct} ${styles.infoContainer}`}>
            {customerInfo ? (
              <>
                <div>
                  <img
                    className={styles.avatar}
                    style={{ padding: '6px' }}
                    id={paramCase(`${name}-info-icon`)}
                    src={infoIcon}
                    alt={'payer info icon'}
                  />
                </div>
                <section className={styles.text}>
                  <p>{customerInfo.kundenname}</p>
                  <p>{customerInfo.kontoIban}</p>
                  <p>{customerInfo.kontoBankName}</p>
                </section>
              </>
            ) : (
              <></>
            )}
          </section>
        </section>
      </DHLFormGroup>
    </section>
  );
};
