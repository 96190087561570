import { JournalPaymentInfoRep } from 'generated/api';
import { createContext, useContext } from 'react';

export interface OrderDisplayValues {
  getJournalPaymentData: (zkz: string) => void;
  journalPaymentInfo: JournalPaymentInfoRep | undefined;
}

const defaultValues: OrderDisplayValues = {
  getJournalPaymentData: () => null,
  journalPaymentInfo: undefined
};

const OrderDisplayContext = createContext(defaultValues);

export default OrderDisplayContext;
export const useOrderContext = () => useContext(OrderDisplayContext);
