import { DeliveryOption } from 'common/dtos/DeliveryOption';

export const determineDeliveryOption = (attributes: Record<string, string>): DeliveryOption => {
  const attribute = attributes['ZOP'];
  let deliveryOption = DeliveryOption.NONE;

  switch (attribute) {
    case 'flex':
      deliveryOption = DeliveryOption.FLEX;
      break;
    case 'std':
      deliveryOption = DeliveryOption.STANDARD;
      break;
    default:
      break;
  }

  return deliveryOption;
};
