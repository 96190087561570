// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderVersionDialog_orderVerionDialogBox__KWdlw {
  text-align: left;
  border-radius: 8px;
  padding: 23px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.OrderVersionDialog_success__oxXa0 {
  background-color: rgba(76, 175, 80, 0.2);
  transition: 0.5s all ease-in-out;
}

.OrderVersionDialog_warning__CjYc8 {
  background-color: rgba(255, 204, 0, 0.3);
  transition: 0.5s all ease-in-out;
}

.OrderVersionDialog_orderVersionOptionsContainer__IT7Ch {
  width: 270px;
}

.OrderVersionDialog_orderVerionDialogTextBox__DpoG0 {
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  align-items: top;
}

.OrderVersionDialog_orderVerionDialogTextBox__DpoG0 p {
  color: #323232;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 0;
}

.OrderVersionDialog_orderVersionOptionsDropdown__2D2Tp input {
  font-size: 14px;
}

.OrderVersionDialog_tooltip__eJJ7v {
  font-size: 20px;
  margin: 0;
}

.OrderVersionDialog_tooltipContainer__F0LOS {
  margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/OrderViewDetail/OrderVersion/OrderVersionDialog.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".orderVerionDialogBox {\n  text-align: left;\n  border-radius: 8px;\n  padding: 23px 30px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.success {\n  background-color: rgba(76, 175, 80, 0.2);\n  transition: 0.5s all ease-in-out;\n}\n\n.warning {\n  background-color: rgba(255, 204, 0, 0.3);\n  transition: 0.5s all ease-in-out;\n}\n\n.orderVersionOptionsContainer {\n  width: 270px;\n}\n\n.orderVerionDialogTextBox {\n  padding-right: 25px;\n  display: flex;\n  flex-direction: row;\n  align-items: top;\n}\n\n.orderVerionDialogTextBox p {\n  color: #323232;\n  font-size: 14px;\n  font-weight: 800;\n  margin-bottom: 0;\n}\n\n.orderVersionOptionsDropdown input {\n  font-size: 14px;\n}\n\n.tooltip {\n  font-size: 20px;\n  margin: 0;\n}\n\n.tooltipContainer {\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderVerionDialogBox": `OrderVersionDialog_orderVerionDialogBox__KWdlw`,
	"success": `OrderVersionDialog_success__oxXa0`,
	"warning": `OrderVersionDialog_warning__CjYc8`,
	"orderVersionOptionsContainer": `OrderVersionDialog_orderVersionOptionsContainer__IT7Ch`,
	"orderVerionDialogTextBox": `OrderVersionDialog_orderVerionDialogTextBox__DpoG0`,
	"orderVersionOptionsDropdown": `OrderVersionDialog_orderVersionOptionsDropdown__2D2Tp`,
	"tooltip": `OrderVersionDialog_tooltip__eJJ7v`,
	"tooltipContainer": `OrderVersionDialog_tooltipContainer__F0LOS`
};
export default ___CSS_LOADER_EXPORT___;
