import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getUser } from '../context/auth/AuthProvider';
import { BASE_URL } from './apiUtils';
import { transformResponse } from './helpers';

function createAxiosConfig(): AxiosRequestConfig {
  return {
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    },
    transformResponse
  };
}

export const getAxiosClient = (): AxiosInstance => {
  /**
   * Create a new instance of axios with our custom config.
   */
  const axiosClient = axios.create(createAxiosConfig());
  axiosClient.interceptors.request.use(
    function (config) {
      const { _accessToken, isAuthenticated, extUserId } = getUser();
      if (config && config?.headers) {
        if (_accessToken && isAuthenticated) config.headers['Authorization'] = `Bearer ${_accessToken}`;
        if (extUserId) config.headers['X-ExtUserId'] = extUserId;
        return config;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return axiosClient;
};
