import axios from 'axios';
import i18n from 'i18n';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, useController, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DHLCheckbox, DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { HighlightedText } from 'common/components/HighlightedText';
import { InputGroup } from 'common/components/InputGroup';
import { DeleteMethod, InputSelectSearchable } from 'common/components/InputSelectSearchable';
import { FetchMethod } from 'common/components/InputSelectSearchable/useFetcher';
import { getIssueSequenceNumber, getJournals, JournalItem } from 'common/services/JournalService';
import { SFCProps } from 'common/utils/formHelpers';
import { rangeArray } from 'common/utils/helpers';
import { JournalRep } from 'generated';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { einlieferungskennung as einlieferungskennungCatalogValues } from 'order/productGroups/common/dtos/CatalogValues';
import { JournalInputSchema } from './journalInputSchema';
import { useRecommendations } from './useRecommendations';
import { OrderMode } from 'order/common/dtos/OrderMode';
import translation from './journalInput.json';
import classes from './journal-input.module.css';
import { Checkbox } from 'common/components/Checkbox/Checkbox';
import { usePrevious } from 'common/utils/hooks';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';

export type JournalInputProps<T extends FieldValues> = SFCProps<T, JournalInputSchema | undefined> & {
  disabled?: boolean;
  orderMode?: OrderMode;
  setResetFields?: React.Dispatch<React.SetStateAction<boolean>>;
};
export enum JournalError {
  TooMany = 'TooMany',
  QueryTooShort = 'QueryTooShort',
  NoResults = 'NoResults'
}

export const JournalInput = <T extends FieldValues>({ name, disabled, orderMode, setResetFields }: JournalInputProps<T>): ReactElement => {
  i18n.addResourceBundle('de', 'journalInput', translation);
  i18n.addResourceBundle('en', 'journalInput', translation);

  const { order, upsertMetaData, meta } = useOrderContext();
  const { trigger, control, setValue, watch, getValues, reset } = useFormContext();
  const [isDoubleNumber, setIsDoubleNumber] = useState<boolean>(!!meta?.isDoubleNumber);
  const [isSequenceNumberLoaded, setIsSequenceNumberLoaded] = useState(true);
  const values = watch();
  const {
    field: { value: zkz, onChange: changeZkz }
  } = useController({ name: `${name}.zkz` });

  const issueNumberTo = useWatch({
    control,
    name: `journal.issueNumberRange.to.issueNumber` as any
  });
  const { t } = useTranslation('orderCreate');
  const { t: catalogValuesT } = useTranslation('catalogValues', { keyPrefix: 'einlieferungskennung.display' });
  const { recommendations, load: getRecommendations, add: addRecommendation, delete: removeRecommendation } = useRecommendations();
  const ZKZ = order?.journal?.zkz ?? '';
  const [selected, setSelected] = useState<JournalRep | undefined>(recommendations.find((x) => x.registrationNumber === zkz));
  const previousZKZ = usePrevious(zkz);

  // DOUBLE NUMBER HANDLING
  useEffect(() => {
    if (orderMode === OrderMode.CHANGE && !!issueNumberTo) {
      setIsDoubleNumber(!!issueNumberTo);
      upsertMetaData('isDoubleNumber', !!issueNumberTo);
    }
  }, [issueNumberTo]);

  // ZKZ TITLE HANDLING
  const getDataZKZ = async (zkz: string) => {
    const journals = await getJournals(zkz);
    const item = { registrationNumber: zkz, title: journals?.data?.[0]?.title, hasTariffCode: journals?.data?.[0]?.hasTariffCode };
    setSelected(item);
    upsertMetaData('zkzTitle', item?.title);
    upsertMetaData('hasTariffCode', journals?.data?.[0]?.hasTariffCode);
  };

  useEffect(() => {
    getRecommendations();
    if ((orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY) && zkz !== '') {
      setSelected({ registrationNumber: ZKZ, title: '' });
      getDataZKZ(ZKZ);
    }

    if (orderMode === OrderMode.COPY && zkz !== '') loadSequenceNumber(ZKZ, 'R');
  }, [order]);

  // SEQUENCE NUMBER API HANDLING
  const loadSequenceNumber = async (zkz?: string, postingIdentifier?: string) => {
    if (!zkz || !postingIdentifier) {
      return;
    }

    setIsSequenceNumberLoaded(false);
    await getIssueSequenceNumber(zkz, postingIdentifier)
      .then((d) => {
        if (d.data?.magazineSequenceNumber) {
          setValue(`journal.issueNumberRange.from.year`, d.data.magazineSequenceYear);
          setValue(`journal.issueNumberRange.from.issueNumber`, d.data.magazineSequenceNumber);
          setIsSequenceNumberLoaded(true);
        } else {
          setValue(`journal.issueNumberRange.from.year`, null);
          setValue(`journal.issueNumberRange.from.issueNumber`, null);
          setIsSequenceNumberLoaded(true);
        }
      })
      .catch(() => {
        setValue(`journal.issueNumberRange.from.year`, null);
        setValue(`journal.issueNumberRange.from.issueNumber`, null);
        setIsSequenceNumberLoaded(true);
      });
  };

  // ON SEARCH EVENT HANDLING
  const onSearch: FetchMethod<JournalItem[]> = async (searchText: string) => {
    const trimmedQuery = searchText.trim().replace(/\s+/g, ' ');
    if (trimmedQuery.length < 3) {
      throw new Error(JournalError.QueryTooShort);
    }
    try {
      const journals = await getJournals(trimmedQuery);
      if (journals.status === 200 && journals.data) {
        return journals.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 413) {
        throw new Error(JournalError.TooMany);
      }
    }
    throw new Error(JournalError.NoResults);
  };

  // DELETE EVENT HANDLING
  const onDelete: DeleteMethod<JournalItem> = (_, item) => {
    removeRecommendation(item);
  };

  const translateError = useCallback((key?: string) => (!key ? undefined : t(`journalInput.${key}`)), [t]);
  const [isLoaded, setIsLoaded] = useState(false);

  // ERORR HANDLING
  const currentYear = new Date().getFullYear();
  const yearRules = {
    required: {
      value: true,
      message: 'error.range.from.required'
    },
    min: {
      value: currentYear - 1,
      message: 'error.range.from.min'
    },
    max: {
      value: currentYear + 4,
      message: 'error.range.from.max'
    }
  };
  const rangeNumberRules = {
    required: {
      value: true,
      message: 'error.range.from.required'
    },
    min: {
      value: 1,
      message: 'error.range.from.min'
    },
    max: {
      value: 1e3 - 1,
      message: 'error.range.from.max'
    },
    pattern: {
      value: /^\d+$/,
      message: 'error.range.from.invalid'
    }
  };

  return (
    <section>
      <FormRow mode="two-col-min">
        <Controller
          name="journal"
          rules={{ required: t(`error.zkz.empty`) }}
          render={({ field: { ref, ...field }, fieldState }) => (
            <InputSelectSearchable<JournalItem>
              fetcher={onSearch}
              value={ZKZ ? selected : field.value.registrationNumber === undefined ? '' : field.value}
              headline={isLoaded ? undefined : t('journalInput.lastSearched')}
              onSearched={(r) => setIsLoaded(r.state === 'finished')}
              valueToString={(i) => `${i.registrationNumber} - ${i.title}`}
              placeholder={t('journalInput.dropdownPlaceholder') + '*'}
              searchPlaceholder={t('journalInput.searchPlaceholder')}
              name="journal"
              data-testid="journal"
              onDelete={onDelete}
              error={fieldState.error?.message}
              onChange={(item) => {
                if (item?.registrationNumber !== previousZKZ) {
                  setResetFields?.(true);
                }
                setSelected(item);
                upsertMetaData('hasTariffCode', item?.hasTariffCode);
                addRecommendation({ registrationNumber: item?.registrationNumber, title: item?.title, hasTariffCode: item?.hasTariffCode });
                field.onChange(item);
                changeZkz(item?.registrationNumber);
                // setValue(`${name}.postingIdentifier`, 'R' as any);
                upsertMetaData('zkzTitle', item?.title);
                loadSequenceNumber(item?.registrationNumber, 'R');
              }}
              cached={recommendations}
              disabled={orderMode === OrderMode.CHANGE}
            >
              {(result, query) => {
                if (result.state !== 'error') {
                  return undefined;
                }
                if (result.error.message === JournalError.TooMany || result.error.message === JournalError.QueryTooShort) {
                  return (
                    <div className={classes.errorMessage} data-testid="journal-error">
                      <Trans ns="orderCreate" i18nKey={'error.tooManyResults'} values={{ query }}>
                        <HighlightedText highlighted={query}>{`Für den Suchbegriff ${query} gibt es zu viele Treffer.`}</HighlightedText>
                        <br />
                        Bitte geben sie einen genaueren Suchbegriff ein.
                      </Trans>
                    </div>
                  );
                }
                if (result.error.message === JournalError.NoResults) {
                  return (
                    <div className={classes.errorMessage} data-testid="journal-error">
                      <Trans ns="orderCreate" i18nKey={'error.noResults'} values={{ query }}>
                        <HighlightedText highlighted={query}>{`Für den Suchbegriff ${query} gibt es keine Treffer.`}</HighlightedText>
                        <br />
                        Bitte geben sie einen anderen Suchbegriff ein.
                      </Trans>
                    </div>
                  );
                }
              }}
            </InputSelectSearchable>
          )}
        />
        {!isAvailableFromJan25(values?.details?.date) && (
          <div className={classes.alignItemCenter}>
            <Controller
              name="journal"
              render={({ field: { ref, ...field } }) => (
                <DHLCheckbox
                  label={t('journalInput.tariffZoneBilling')}
                  value={field.value.hasTariffCode || selected?.hasTariffCode}
                  name="journal.tariffZoneBilling"
                  disabled={true}
                />
              )}
            />
          </div>
        )}
      </FormRow>
      <FormRow mode="two-col">
        <TextInput
          rules={{ required: 'error.requiredInput' }}
          name={`${name}.journalNumber`}
          label={t('journalInput.journalNumber') + '*'}
          error={({ fieldState }) => translateError(fieldState.error?.message)}
          disabled={disabled}
          data-testid={'journalInput.journalNumber'}
          onChange={() => trigger(`${name}.journalNumber` as any)}
        />
        <Controller
          name={`${name}.postingIdentifier`}
          rules={{ required: 'error.required' }}
          render={({ field: { ref, ...field }, fieldState }) => (
            <AMDHLSelectSingle
              {...field}
              value={field.value as string}
              placeholder={t('journalInput.einlieferungskennung') + '*'}
              items={einlieferungskennungCatalogValues.slice()}
              valueToString={catalogValuesT}
              onChange={(v) => {
                field.onChange(v);
                loadSequenceNumber(zkz, v);
                // if (orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY) {
                //   console.log('should not run on create');
                //   loadSequenceNumber(zkz, v);
                // }
                trigger(`${name}.postingIdentifier` as any);
              }}
              data-testid={`journal-einlieferungskennung`}
              error={translateError(fieldState.error?.message)}
              disabled={disabled}
              required
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          label={t('journalInput.doubleNumber')}
          checked={isDoubleNumber}
          datatestid="journalInput.doubleNumber"
          onChange={(e) => {
            setIsDoubleNumber(!isDoubleNumber);
            upsertMetaData('isDoubleNumber', !isDoubleNumber);
            reset({
              ...getValues(),
              journal: {
                ...values.journal,
                issueNumberRange: {
                  from: values.journal?.issueNumberRange.from,
                  to: {} as any
                }
              }
            });
          }}
          name={`${name}.isDoubleNumber`}
          disabled={!isSequenceNumberLoaded || disabled}
        />
      </FormRow>
      <FormRow mode="two-col">
        <InputGroup>
          <TextInput
            name={`${name}.issueNumberRange.from.issueNumber`}
            rules={rangeNumberRules}
            type="number"
            className={classes.rangeNumber}
            label={t('journalInput.heftfolgenummer.from') + '*'}
            error={({ fieldState }) => translateError(fieldState.error?.message)}
            disabled={!isSequenceNumberLoaded || disabled}
            onChange={() => trigger(`${name}.issueNumberRange.from.issueNumber` as any)}
          />
          <Controller
            name={`${name}.issueNumberRange.from.year`}
            rules={yearRules}
            render={({ field: { ref, ...field }, fieldState }) => (
              <AMDHLSelectSingle<number>
                {...field}
                value={field.value as number}
                placeholder={t('journalInput.heftfolgenummer.year') + '*'}
                items={rangeArray(6).map((i) => i + currentYear - 1)}
                valueToString={(i) => i.toString()}
                wrapperClassName="flex-grow-1"
                error={translateError(fieldState.error?.message)}
                disabled={!isSequenceNumberLoaded || disabled}
                data-testid={`journal-heftfolgenummer-year`}
                onChange={(v) => {
                  field.onChange(v);
                  trigger(`${name}.issueNumberRange.from.year` as any);
                }}
              />
            )}
          />
        </InputGroup>
        {isDoubleNumber ? (
          <div>
            <DHLIcon icon="date-picker-separator" name="journal-range-separator" className={classes.rangeSeparator} />
            <InputGroup>
              <TextInput
                name={`${name}.issueNumberRange.to.issueNumber`}
                type="number"
                rules={rangeNumberRules}
                className={classes.rangeNumber}
                label={t('journalInput.heftfolgenummer.to') + '*'}
                error={({ fieldState }) => translateError(fieldState.error?.message)}
                disabled={disabled}
                onChange={() => trigger(`${name}.issueNumberRange.to.issueNumber` as any)}
              />
              <Controller
                name={`${name}.issueNumberRange.to.year`}
                rules={yearRules}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <AMDHLSelectSingle<number>
                    placeholder={t('journalInput.heftfolgenummer.year') + '*'}
                    {...field}
                    value={field.value as number}
                    items={rangeArray(6).map((i) => i + currentYear - 1)}
                    valueToString={(i) => i.toString()}
                    required
                    wrapperClassName="flex-grow-1"
                    error={translateError(fieldState.error?.message)}
                    disabled={disabled}
                    data-testid={'journalInput-heftfolgenummer-year'}
                    onChange={(v) => {
                      field.onChange(v);
                      trigger(`${name}.issueNumberRange.to.year` as any);
                    }}
                  />
                )}
              />
            </InputGroup>
          </div>
        ) : (
          <div />
        )}
      </FormRow>
    </section>
  );
};
