import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { ActiveMultiList } from 'common/components/ActiveMultiList';
import { isValid, format as dataFormat } from 'date-fns';
import { ProductionFacilityTO } from 'generated';
import { useTranslation } from 'i18n';
import { sortProductionFacilities } from 'order/common/components/ProductionFacilitySelect/sortProductionFacilities';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useProductionFacility } from 'order/common/hooks/useProductionFacility';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export interface ResponsibleProductionFacilitySelectProps {
  name?: string;
  disabled?: boolean;
  pickupAddress: any;
  onChange?: (item: any) => void;
}

export const ResponsibleProductionFacilitySelect: React.VFC<ResponsibleProductionFacilitySelectProps> = ({ disabled, ...props }) => {
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('DE');
  const { meta } = useOrderContext();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (meta?.productionPlantError) {
      setError(meta.productionPlantError);
    } else {
      setError(false);
    }
  }, [meta?.productionPlantError]);

  useEffect(() => {
    if (props.pickupAddress) {
      setSelectedCountry(props.pickupAddress.country);
      setSelectedRegion(props.pickupAddress.postCode);
    }
  }, [props.pickupAddress]);

  const formattedDate = dataFormat(new Date(meta?.pickUpDate), 'yyyy-MM-dd');

  const facilityQuery = React.useMemo(() => {
    return selectedCountry === 'DE'
      ? { region: selectedRegion, inductionDate: formattedDate }
      : { country: selectedCountry, inductionDate: formattedDate };
  }, [selectedRegion, selectedCountry, formattedDate]);

  const { facilities } = useProductionFacility(facilityQuery);
  const { t: tCity } = useTranslation('timeCitySection');
  const [value, setValue] = useState<ProductionFacilityTO | undefined>(undefined);

  const productionFacilitiesData = useMemo<ProductionFacilityTO[]>(() => (facilities ? facilities.sort(sortProductionFacilities) : []), [facilities]);

  useEffect(() => {
    if (productionFacilitiesData.length === 1) {
      props.onChange?.(productionFacilitiesData[0]);
      setValue(productionFacilitiesData[0]);
    } else if (facilities && !productionFacilitiesData.find((facility) => facility.catalogId === value?.catalogId)) {
      props.onChange?.(undefined);
      setValue(undefined);
    }
  }, [productionFacilitiesData]);

  const handleOnChange = useCallback((item: ProductionFacilityTO | undefined) => {
    setValue(item);
    props.onChange?.(item);
    if (!item) {
      setError(true);
    } else {
      setError(false);
    }
  }, []);

  return (
    <>
      {/* <ActiveMultiList
        name={`pickupAddress.productionPlantId`}
        onChange={(item) => {
          handleOnChange(item);
        }}
        valueToString={(pf) =>
          `${pf.catalogId?.startsWith('GrASt') ? `${pf.address?.postCode} ${pf.comment}` : `${pf.catalogId} ${pf.address?.city}`}`
        }
        value={value}
        items={productionFacilitiesData}
        placeholder={tCity(`deliveryPoint`)}
        data-testid={'responsibleProductionFacilityId'}
        disabled={disabled || productionFacilitiesData.length <= 1}
        error={error ? tCity(`error.invalid`) : undefined}
      /> */}
      <AMDHLSelectSingle
        name={`pickupAddress.productionPlantId`}
        onChange={(item) => {
          handleOnChange(item);
        }}
        valueToString={(pf) =>
          `${pf.catalogId?.startsWith('GrASt') ? `${pf.address?.postCode} ${pf.comment}` : `${pf.catalogId} ${pf.address?.city}`}`
        }
        value={value}
        items={productionFacilitiesData}
        placeholder={tCity(`deliveryPoint`)}
        data-testid={'responsibleProductionFacilityId'}
        disabled={disabled || productionFacilitiesData.length <= 1}
        error={error ? tCity(`error.invalid`) : undefined}
      />
    </>
  );
};
