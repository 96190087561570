import { createApi, Endpoint, responseOrUndefined } from 'common/utils/apiUtils';
import { PaymentApiFactory, PaymentRep } from 'generated';

export type PaymentDataRequest = {
  settlementNumber: string;
  validityDateStr: string | undefined;
};

export type PaymentData = PaymentRep;

const paymentBankDataApi = createApi(PaymentApiFactory);

export const getPaymentData: Endpoint<PaymentDataRequest, PaymentData> = async ({ settlementNumber, validityDateStr }) =>
  responseOrUndefined(paymentBankDataApi.getPaymentDetailsForPayer(settlementNumber, validityDateStr));
