import { isRangeOverlapping } from 'order/common/components/QtyAllocation/helper/isRangeOverlapping';
import { RawInternalHandler, InternalHandler } from 'order/common/components/QtyAllocation/IdAssignment';
import { LetterBaseProduct } from 'order/common/context/order/dtos/GetOrder';

export const validateDestinationsInBaseProducts = (baseProducts: LetterBaseProduct[], frankingIdEncoding: string | undefined) => {
  const destinations = baseProducts.flatMap((baseProduct, i) => baseProduct.destinations) as unknown as InternalHandler[];
  if (destinations && destinations.length > 1 && frankingIdEncoding !== 'C40' && frankingIdEncoding !== 'HEX') {
    const overlaps = isRangeOverlapping(destinations);
    const baseProductValidity = baseProducts.map((baseProduct) => baseProduct.destinations?.map((d) => overlaps[(d as any).id] ?? false));
    return { validityRange: baseProductValidity, valid: !Object.values(overlaps).some(Boolean) };
  }
  return { validityRange: [], valid: true };
};
