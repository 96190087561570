import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    paddingTop: '8.5mm',
    paddingLeft: '9.5mm'
  },
  linesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStretched: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStretchedCenter: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldContainerStretchedLeft: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  fieldContainerStretchedRight: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  fieldContainerStretchedBottom: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  borderTop: {
    borderTop: '1px solid black'
  },
  borderBottom: {
    borderBottom: '1px solid black'
  },
  borderLeft: {
    borderLeft: '1px solid black'
  },
  borderRight: {
    borderRight: '1px solid black'
  },
  textLabel: {
    fontFamily: 'delivery',
    fontSize: '10pt',
    fontWeight: 'bold'
  },
  textAdress: {
    fontFamily: 'delivery',
    fontSize: '10pt',
    lineHeight: 1.1
  },
  textValue: {
    fontFamily: 'delivery',
    fontSize: '12pt'
  },
  textValueBig: {
    fontFamily: 'delivery',
    fontSize: '24pt'
  },
  textPaletteCount: {
    fontFamily: 'delivery',
    fontSize: '20pt'
  },
  textProduct: {
    fontFamily: 'delivery',
    fontSize: '56pt',
    fontWeight: 'bold'
  },
  textProductMedium: {
    fontFamily: 'delivery',
    fontSize: '36pt',
    fontWeight: 'bold'
  },
  textTarget: {
    fontFamily: 'delivery',
    fontSize: '100pt',
    fontWeight: 'bold'
  },
  textDate: {
    fontFamily: 'delivery',
    fontSize: '20pt',
    fontWeight: 'bold'
  },
  textBold: {
    fontWeight: 'bold'
  }
});
