import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { Product, requestBaseProductsForGroup } from 'order/productGroups/common/services/ProductService';
import { useMemo } from 'react';
import useSWR from 'swr';
import { ProductGroup } from '../dtos/ProductGroup';

export const useBaseProducts = (
  productGroup?: string,
  date = new Date(),
  serviceType: string = 'POSTING',
  isPartialServiceCapable?: boolean,
  hierarchyDestination?: string,
  hierarchyProductGroup?: string
): Product[] => {
  const isInternational =
    productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
    productGroup === ProductGroup.PRESS_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL;

  const { data: products = [] } = useSWR(productGroup?.length && !isInternational ? ['productGroup', productGroup] : null, async (_, group) =>
    requestBaseProductsForGroup({ productGroup: group, serviceType, isPartialServiceCapable, hierarchyDestination, hierarchyProductGroup }).catch(
      () => [] as Product[]
    )
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);

  return filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
};
