import React, { FC } from 'react';

import { toFormattedTime } from 'common/formatting';
import i18n, { useTranslation } from 'i18n';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { CollectivePostingVariantsAdaptation } from 'order/productGroups/common/components/atom/CollectivePostingVariantsAdaptation/CollectivePostingVariantsAdaptation';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { Shipment } from 'order/productGroups/common/components/molecule/DisplayQuantityWeightSection';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { ShipmentQuantityWeightWidget } from './ShipmentQuantityWeightWidget';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderCustomerRoleEnum } from 'generated';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export type ProductInfoSectionProps = {
  value: Order;
};

export const ProductInfoSection: FC<ProductInfoSectionProps> = ({ value }) => {
  const { t } = useTranslation('translation');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const language = useAuthContext().user.language;
  const date = value.orderDetail?.date;
  const baseProductNumber = value.baseProduct?.productNumber;
  const { facilities } = useProductionFacilities(date);
  const baseProducts = useBaseProducts(value.productGroup, date);
  const { premium, easy, nichtAutomationsfaehig, trackAndMatch, seasonalSurcharge } = useDependentProducts(
    value.productGroup,
    date,
    baseProductNumber
  );
  const getBaseProduct = (productNumber?: string) => baseProducts.find(({ productNumber: p }) => p === productNumber);
  const getDependentProduct = (productNumber?: string) => value.dependentProducts?.find(({ productNumber: p }) => p === productNumber);
  const facility = facilities.find(({ catalogId }) => catalogId === value.orderDetail?.productionPlantId);
  const contactForQuestions = value.parties.find((p) => p.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const shipmentDataLength = value.shipmentData?.length ? value.shipmentData.length : 0;
  const { t: tOrderCreate } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });

  return (
    <>
      <OrderOverviewRow>
        <OrderOverviewWidget title="Basisprodukt">
          <p data-testid="base-product-testid">{getBaseProduct(baseProductNumber)?.descriptionShort}</p>
        </OrderOverviewWidget>
        {value.orderCategoryProductKey !== OrderCategoryProductKey.DHL_INFOPOST && (
          <>
            <OrderOverviewWidget title="Zusatzleistungen">
              <p>{getDependentProduct(easy?.productNumber) && 'Easy'}</p>
              <p>{getDependentProduct(nichtAutomationsfaehig?.productNumber) && 'Nicht automationsfähig'}</p>
              <p>{getDependentProduct(trackAndMatch?.productNumber) && 'Track & Match'}</p>
              <p>
                {seasonalSurcharge?.find((ss) => value.dependentProducts?.some((p) => p.productNumber == ss.productNumber)) &&
                  `${t('seasonalSurcharge')}`}
              </p>
              <p>{premium.find(({ productNumber: p }) => value.dependentProducts?.some(({ productNumber: d }) => d == p))?.descriptionShort}</p>
            </OrderOverviewWidget>
            <OrderOverviewWidget title={t('furtherProductFeatures')}>
              {value.variants
                ?.filter((data) => data !== LineItemVariant.NS && data !== LineItemVariant.NKD && data !== LineItemVariant.BKD)
                .map((variant) => (
                  <p key={variant}>{tCatalogValues(`lineItemVariant.display.${variant}`)}</p>
                ))}
            </OrderOverviewWidget>
          </>
        )}
      </OrderOverviewRow>
      <OrderOverviewRow>
        <OrderPickupShippingDisplay
          data={{
            date: date,
            pickup: !!value.pickupAddress,
            time: toFormattedTime(date),
            submissionType: value.orderDetail?.submissionType,
            facility,
            pickupAddress: value.pickupAddress,
            contactForQuestions: contactForQuestions
          }}
        />
        <OrderOverviewWidget title="Gebinde">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>{tOrderCreate('typeOfBundle')}</p>
                </td>
                <td>
                  <p>{value.packaging?.type && tCatalogValues('mailEntityType.display.' + value.packaging?.type)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('numberOfBundles')}</p>
                </td>
                <td>
                  <p>{value?.packaging?.quantity?.toLocaleString(language)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('totalNetWeightInKg')}</p>
                </td>
                <td>
                  <p>{value?.packaging?.totalNetWeightValue?.toLocaleString(language)} kg</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('totalGrossWeightInKg')}</p>
                </td>
                <td>
                  <p>{value?.packaging?.totalWeight?.toLocaleString(language)} kg</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('stackable')}</p>
                </td>
                <td>
                  <p>{value.packaging?.stackable ? i18n.t('yes') : i18n.t('no')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
        {shipmentDataLength <= 1 && <ShipmentQuantityWeightWidget meta={{ language }} dialogPostValue={value} />}
      </OrderOverviewRow>
      {shipmentDataLength > 1 && (
        <CollectivePostingVariantsAdaptation
          meta={{ language }}
          orderCategory={value.orderCategory}
          shipmentData={value?.shipmentData as Shipment[]}
        />
      )}
    </>
  );
};
