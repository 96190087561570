import React, { useLayoutEffect, useRef, useState } from 'react';

import { UserRights } from 'common/dtos/userRights';
import { OrderChange, OrderChangeProps } from './common/components/OrderChange';
import { Spinner } from 'order/common/components/Spinner';
import { Authorized } from 'common/components/Authorized/Authorized';
import { useTranslation } from '../../i18n';
import { CustomOrderAlert } from 'common/components/PendingOrder/CustomOrderAlert';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { getDisableEditingAlertMsgId } from '../common/utils/alertHelper';

export const OrderChangeContainer: React.VFC<OrderChangeProps> = (props: OrderChangeProps) => {
  const [loaded, setLoaded] = useState(false);
  const { order } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate');

  useLayoutEffect(() => {
    setLoaded(true);
  }, []);

  if ((order as any)?.disableEditing) {
    return (
      <CustomOrderAlert
        headline={oc('headline.orderDisplay')}
        message={oc(getDisableEditingAlertMsgId(order, `notifications.disableEditingWarningMessage`))}
        title={oc('notifications.warning')}
      />
    );
  }

  return (
    <Authorized userRight={UserRights.OrderEdit}>
      {!loaded ? <Spinner testid="spinnerTestId" message={oc('notifications.loadingOrder')} /> : <OrderChange {...props} />}
    </Authorized>
  );
};
