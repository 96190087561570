import { DefaultDayRangePreset, getDefaultDayRange } from 'common/components/AMDHLDateRangeInput/DayRangePreset';
import { procedureCache } from 'common/services/ProcedureService';
import { deDateFormatter } from 'common/utils/helpers';

import i18n from 'i18n';
import { OrderSearchKey } from '../../common/context/search/dtos/OrderSearchKey';
import { orderTypeOptions } from '../components/OrderTypes';
import { formatSettlementNumber } from './recommendationService';
import { orderStatusOptions } from '../components/ProductionState';

export interface DateRange {
  start: Date;
  end: Date;
}

export type DateAttribute = {
  key: OrderSearchKey.Date;
  value: DateRange | DefaultDayRangePreset;
};

export type DefaultAttribute = {
  key: Exclude<OrderSearchKey, OrderSearchKey.Date>;
  value: string;
  /**
   * Additional meta information.
   * Is not compared when comparing attributes.
   */
  meta?: string;
  id?: string;
};

export type OrderSearchAttribute = DefaultAttribute | DateAttribute;

export const calculateDateRange = (attribute: DateAttribute): DateRange | undefined => {
  if (typeof attribute.value !== 'number') {
    return attribute.value;
  }
  const range = getDefaultDayRange(attribute.value);
  if (range) {
    return {
      start: range?.getStartDate().toDate(),
      end: range?.getEndDate().toDate()
    };
  }
};

export const printAttribute = (attribute: OrderSearchAttribute): { label: string; value: string } => {
  let value = '';
  switch (attribute.key) {
    case OrderSearchKey.SettlementNumber:
      value = formatSettlementNumber(attribute.value);
      break;
    case OrderSearchKey.Procedure:
      const procedure = procedureCache.get(attribute.value);
      if (procedure) {
        value = `${procedure.id} ${procedure.name}`;
      }
      break;
    case OrderSearchKey.OrderType:
      const orderType = orderTypeOptions.find((type) => type.id === attribute.value);
      if (orderType) {
        value = `${orderType.name}`;
      }
      break;
    case OrderSearchKey.ProductionState:
      const orderStatus = orderStatusOptions.find((type) => type.id === attribute.value);
      if (orderStatus) {
        value = `${orderStatus.name}`;
      }
      break;
    case OrderSearchKey.Date:
      const range = calculateDateRange(attribute);
      const start = deDateFormatter.format(new Date(range?.start || ''));
      const end = deDateFormatter.format(new Date(range?.end || ''));
      value = `${start} - ${end}`;
      break;
    default:
      value = attribute.value;
  }

  return { label: i18n.t('chip.' + attribute.key), value };
};

export const attributesEqual = (a: OrderSearchAttribute, b: OrderSearchAttribute): boolean => {
  if (a.key === OrderSearchKey.Date && b.key === OrderSearchKey.Date && typeof a.value === 'object' && typeof b.value === 'object') {
    return dateRangeEqual(a.value, b.value);
  }
  return a.key === b.key && a.value === b.value;
};

const dateRangeEqual = (a: DateRange, b: DateRange): boolean =>
  new Date(a.start).getTime() === new Date(b.start).getTime() && new Date(a.end).getTime() === new Date(b.end).getTime();

export const sortAttributes = ({ key: keyA }: OrderSearchAttribute, { key: keyB }: OrderSearchAttribute): number => {
  if (keyA === OrderSearchKey.Date || keyB === OrderSearchKey.Date) {
    return keyA === OrderSearchKey.Date ? -1 : 1;
  }
  return keyA.toString().localeCompare(keyB.toString());
};
