import classNames from 'classnames';
import { AMDHLDropdownButton } from 'common/components/AMDHLDropdownButton';
import contextClasses from 'common/components/ContextMenu/context-menu.module.css';
import { Label } from 'common/components/ContextMenu/Label';
import { Menu } from 'common/components/ContextMenu/Menu';
import { useSelect, UseSelectParams } from 'common/hooks/useSelect';
import { concatNonUndefined } from 'common/utils/helpers';
import React, { AllHTMLAttributes, ReactNode } from 'react';

export type AMDHLSelectSingleProps<T> = Omit<AllHTMLAttributes<HTMLSelectElement>, 'selected' | 'onChange' | 'value'> &
  UseSelectParams<T> & {
    wrapperClassName?: string;
    error?: ReactNode;
    maxVisibleOptions?: number;
    'data-testid'?: string;
    emptyValue?: T;
  };

export const AMDHLSelectSingle = <T,>({ valueToString = String, maxVisibleOptions = 10, ...props }: AMDHLSelectSingleProps<T>): JSX.Element => {
  const {
    items: options,
    getInputProps,
    getOptionProps,
    getListProps,
    isOpen,
    selectedIndex,
    activeIndex
  } = useSelect<T>({
    ...props,
    valueToString,
    mode: 'select'
  });

  const testId = (value: string = '') => concatNonUndefined(props['data-testid'], value);

  const placeholder = props.placeholder || props.label;

  return (
    <div className={classNames(props.wrapperClassName, 'inputContainer-inner')} data-testid={testId('-container')}>
      <AMDHLDropdownButton
        {...getInputProps()}
        className={classNames({ placeholder: placeholder }, props.className)}
        placeholder={placeholder}
        error={!!props.error}
        value={props.value && props.emptyValue !== props.value ? valueToString(props.value) : ''}
        data-testid={props['data-testid']}
      />
      <div className={contextClasses.anchor}>
        <Menu isOpen={isOpen} {...getListProps()} style={{ maxHeight: 30 * maxVisibleOptions + 18 + 'px' }}>
          {options.map((props, index) => (
            <Label {...getOptionProps(props)} key={index} active={index === activeIndex} checked={index === selectedIndex} />
          ))}
        </Menu>
      </div>
      {!!props.error && (
        <div data-testid={testId('-error')} className="fieldNote error">
          {props.error}
        </div>
      )}
    </div>
  );
};
