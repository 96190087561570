export const downloadBlob = (title: string, blob: Blob): void => {
  const url = window.URL.createObjectURL(blob);
  Object.assign(document.createElement('a'), {
    href: url,
    download: title
  }).click();
};

export const downloadCsvBlob = (title: string, content: string): void => {
  const blob = new Blob(['\uFEFF' + content], { type: 'text/csv;charset=utf8' });
  downloadBlob(title, blob);
};
