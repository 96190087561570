// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.am-dhl-dropdown-button_arrowDown__WfZDX {
  position: absolute;
  right: 6px;
  top: 10px;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/AMDHLDropdownButton/am-dhl-dropdown-button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,oBAAoB;AACtB","sourcesContent":[".arrowDown {\n  position: absolute;\n  right: 6px;\n  top: 10px;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowDown": `am-dhl-dropdown-button_arrowDown__WfZDX`
};
export default ___CSS_LOADER_EXPORT___;
