export interface SettlementNumber {
  payer: string;
  process: string;
  participation: string;
  paymentClearingNumber?: string;
}

/**
 * Extracts a {@link SettlementNumber} from an input {@link string}.
 *
 * Eine Abrechnungsnummern besteht aus einer EKP (10-stellig numerisch), dem Verfahren (2-stellig numerisch)
 * und der Teilnahme (2-stellig alphanumerisch) und ist damit in Summe immer mindestens 14-stellig.
 * Die Einlieferungsbelegnummer kann nur zusätzlich bei DV-Aufträgen hinzugefügt werden,
 * die das Verfahren 10 (national) oder 50 (international) haben. Sie ist numerisch, 1-5 stellig.
 *
 * @param value input string
 */
export const extractSettlementNumber = (value: string): SettlementNumber => ({
  payer: value.slice(0, 10),
  process: value.slice(10, 12),
  participation: value.slice(12, 14),
  paymentClearingNumber: value.slice(14, 19) || undefined
});
