import { useMemo } from 'react';
import useSWR from 'swr/immutable';

import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { requestSpecialLetterProducts } from 'order/common/services/specialLetterProducts';
import { ProductTO } from '../../../generated';

export const useSpecialLetterProducts = (productNumber?: string, date = new Date()): ProductTO[] => {
  const date1: string = new Date(date).getDate().toString().padStart(2, '0');
  const month: string = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
  const year = new Date(date).getFullYear();
  const { data: products = [] } = useSWR(productNumber?.length ? ['specialLetterProducts', productNumber] : [], async (_, productNumber) =>
    requestSpecialLetterProducts(productNumber, `${year}-${month}-${date1}`).catch(() => [] as ProductTO[])
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);

  return filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
};
