// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.idAssignment_tableHead__LQhby {
  background: #e6e6e6;
}

.idAssignment_tableBody__Od69V {
  background: #f5f5f5;
}

.idAssignment_actionBar__BrcGq {
  background: #f5f5f5;
  border-top: 1px solid darkgrey;
}

.idAssignment_error__iY818 {
  color: #d40511;
  text-align: center;
}

.idAssignment_frankingIDEncodingText__qaz4P {
  margin-top: 12px;
  font-size: 14px;
  /* font-weight: 600; */
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  color: #323232;
}

.idAssignment_frankingIDEncodingText__qaz4P span {
  font-weight: 400;
}

.idAssignment_shipmentPlanExists__9GWIy {
  margin: 10px 0 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/QtyAllocation/idAssignment.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".tableHead {\n  background: #e6e6e6;\n}\n\n.tableBody {\n  background: #f5f5f5;\n}\n\n.actionBar {\n  background: #f5f5f5;\n  border-top: 1px solid darkgrey;\n}\n\n.error {\n  color: #d40511;\n  text-align: center;\n}\n\n.frankingIDEncodingText {\n  margin-top: 12px;\n  font-size: 14px;\n  /* font-weight: 600; */\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  color: #323232;\n}\n\n.frankingIDEncodingText span {\n  font-weight: 400;\n}\n\n.shipmentPlanExists {\n  margin: 10px 0 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": `idAssignment_tableHead__LQhby`,
	"tableBody": `idAssignment_tableBody__Od69V`,
	"actionBar": `idAssignment_actionBar__BrcGq`,
	"error": `idAssignment_error__iY818`,
	"frankingIDEncodingText": `idAssignment_frankingIDEncodingText__qaz4P`,
	"shipmentPlanExists": `idAssignment_shipmentPlanExists__9GWIy`
};
export default ___CSS_LOADER_EXPORT___;
