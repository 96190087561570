import { OrderResponse } from '../dtos/GetOrder';
import { hasNonStandardEncoding } from '../../../utils/hasNonStandardEncoding';

function ensureDestinationRangesAreNumber(order: OrderResponse) {
  order.letterBaseProducts?.forEach((lbp) => {
    lbp.destinations?.forEach((d) => {
      d.from = Number(d.from);
      d.to = Number(d.to);
    });
  });
}

export function mapOrder(order: OrderResponse): any {
  if (hasNonStandardEncoding(order.postage?.frankingIdEncoding)) {
    // handleNonStandard
  } else {
    ensureDestinationRangesAreNumber(order);
  }
  return order;
}
