// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogPostContainer_container__8fq50 {
  display: flex;
  align-items: stretch;
  /* padding: 30px; */
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0 0 5px rgb(0 0 0 / 25%); */
}

.DialogPostContainer_container__8fq50 > :first-child {
  width: 65%;
  padding-right: 30px;
  flex-grow: 1;
}

/* .container > :first-child > * + * {
  margin-top: 30px;
} */

.DialogPostContainer_container__8fq50 > :nth-child(2n) {
  width: 35%;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-left: rgb(209, 209, 209) 2px solid;
  padding-left: 30px;
  padding-right: 0;
}

.DialogPostContainer_container__8fq50 > :first-child .form-group.row {
  gap: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.DialogPostContainer_container__8fq50 > :first-child .form-group.row > * {
  flex-grow: 1;
  flex-basis: 0;
}

.DialogPostContainer_container__8fq50 > :first-child .select-group.row {
  justify-content: flex-start;
}

.DialogPostContainer_container__8fq50 > :first-child .select-group.row .dhlLabelContainer {
  white-space: nowrap;
}

.DialogPostContainer_container__8fq50 > :first-child .select-group.row > * {
  flex-grow: 0;
}

.DialogPostContainer_container__8fq50 .flex-grow-3 {
  flex-grow: 3 !important;
}

.DialogPostContainer_container__8fq50 .flex-grow-9 {
  flex-grow: 9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/DialogPostContainer/DialogPostContainer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,YAAY;AACd;;AAEA;;GAEG;;AAEH;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,2BAA2B;EAC3B,oBAAoB;EACpB,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  display: flex;\n  align-items: stretch;\n  /* padding: 30px; */\n  background-color: white;\n  border-radius: 8px;\n  /* box-shadow: 0 0 5px rgb(0 0 0 / 25%); */\n}\n\n.container > :first-child {\n  width: 65%;\n  padding-right: 30px;\n  flex-grow: 1;\n}\n\n/* .container > :first-child > * + * {\n  margin-top: 30px;\n} */\n\n.container > :nth-child(2n) {\n  width: 35%;\n  flex-grow: 1;\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: flex-start;\n  align-items: stretch;\n  border-left: rgb(209, 209, 209) 2px solid;\n  padding-left: 30px;\n  padding-right: 0;\n}\n\n.container > :first-child :global(.form-group.row) {\n  gap: 20px;\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n}\n\n.container > :first-child :global(.form-group.row) > * {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.container > :first-child :global(.select-group.row) {\n  justify-content: flex-start;\n}\n\n.container > :first-child :global(.select-group.row .dhlLabelContainer) {\n  white-space: nowrap;\n}\n\n.container > :first-child :global(.select-group.row) > * {\n  flex-grow: 0;\n}\n\n.container :global(.flex-grow-3) {\n  flex-grow: 3 !important;\n}\n\n.container :global(.flex-grow-9) {\n  flex-grow: 9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DialogPostContainer_container__8fq50`
};
export default ___CSS_LOADER_EXPORT___;
