/**
 * Information about a allowed product cluster combination.
 */
import { createApi, responseOrUndefined } from 'common/utils/apiUtils';
import { AllowedProductClusterCombinationTO, MasterdataResourceApiFactory } from 'generated';

export type ProductClusterCombination = AllowedProductClusterCombinationTO;

const productApi = createApi(MasterdataResourceApiFactory);

export const getProductClusterCombinations = async (productNumber: string): Promise<ProductClusterCombination[] | undefined> =>
  responseOrUndefined(productApi.getClusterCombinations(productNumber));
