import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    paddingLeft: '10mm',
    paddingRight: '10mm',
    paddingTop: '10mm',
    paddingBottom: '20mm',
    fontFamily: 'delivery',
    fontSize: '10pt'
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  logo: {
    alignSelf: 'flex-end'
  },
  pageHeading: {
    fontFamily: 'delivery',
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  linesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    marginTop: '1mm'
  },
  lineContainerStreched: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStreched: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 3
  },
  fieldContainerStrechedCenter: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  groupContainer: {
    marginTop: '3mm',
    borderTop: '1px solid #e6e6e6'
  },
  borderTop: {
    borderTop: '1px solid black'
  },
  borderBottom: {
    borderBottom: '1px solid black'
  },
  borderLeft: {
    borderLeft: '1px solid black'
  },
  borderRight: {
    borderRight: '1px solid black'
  },
  textHeader: {
    fontFamily: 'delivery',
    fontSize: '10pt',
    fontWeight: 'bold'
  },
  textAdress: {
    fontFamily: 'delivery',
    fontSize: '10pt'
  },
  textValue: {
    fontFamily: 'delivery',
    fontSize: '24pt'
  },
  textPaletteCount: {
    fontFamily: 'delivery',
    fontSize: '24pt'
  },
  textProduct: {
    fontFamily: 'delivery',
    fontSize: '56pt',
    fontWeight: 'bold'
  },
  textTarget: {
    fontFamily: 'delivery',
    fontSize: '100pt',
    fontWeight: 'bold'
  },
  textDate: {
    fontFamily: 'delivery',
    fontSize: '24pt',
    fontWeight: 'bold'
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    padding: 0,
    marginTop: '1mm'
  },
  gridHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    backgroundColor: '#D9D9D9',
    padding: 0,
    margin: 0
  },
  gridRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    padding: 0,
    margin: 0
  },
  gridHeaderItem: {
    width: '50%',
    margin: 0,
    padding: '2px'
  },
  gridHeaderItemRight: {
    width: '50%',
    margin: 0,
    padding: '2px',
    textAlign: 'right'
  },
  gridRowItem: {
    width: '50%',
    margin: 0,
    padding: '2px'
  },
  gridRowItemRight: {
    width: '50%',
    margin: 0,
    padding: '2px',
    textAlign: 'right'
  },
  gridRowItemCenter: {
    width: '50%',
    margin: 0,
    padding: '2px',
    textAlign: 'center'
  },
  footer: {
    position: 'absolute',
    bottom: '5mm',
    left: '10mm',
    right: '10mm'
  },
  paddingBottom: {
    paddingBottom: '2mm'
  }
});
