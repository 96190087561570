// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductGroupInput_group__gAUQ0 + .ProductGroupInput_group__gAUQ0 {
  margin-top: 30px;
}

.ProductGroupInput_products__GLcy9 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/ProductGroupInput/ProductGroupInput.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB","sourcesContent":[".group + .group {\n  margin-top: 30px;\n}\n\n.products {\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": `ProductGroupInput_group__gAUQ0`,
	"products": `ProductGroupInput_products__GLcy9`
};
export default ___CSS_LOADER_EXPORT___;
