import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';

import { PageElement } from './PageElement';

import styles from './pagination.module.css';

interface Props {
  pageCut: number;
  pages: number;
  page: number;
  onSelect: (page: number) => void;
  selectedPage?: number;
}

export const Pages = ({ page, ...props }: Props): JSX.Element => {
  const [showAllPages, setShowAllPages] = useState(props.pages < 6);
  let pageCutLow = page - props.pageCut;
  let pageCutHigh = page + props.pageCut;

  useLayoutEffect(() => {
    setShowAllPages(props.pages < 6);
  }, [props]);

  const handleNextClick = () => {
    let nextPage = page + 1;
    if (nextPage >= props.pages) {
      nextPage = props.pages;
    }
    props.onSelect?.(nextPage);
  };

  const handlePrevClick = () => {
    let prevPage = page - 1;
    if (prevPage <= 1) {
      prevPage = 1;
    }
    props.onSelect?.(prevPage);
  };

  const handlePageClick = (pageNumber: number | string) => {
    if (typeof pageNumber !== 'number') {
      return;
    }
    props.onSelect?.(pageNumber);
  };

  const generatePages = (curPage: number, totalPages: number) => {
    const pageElements = [];
    if (showAllPages) {
      for (let p = 1; p <= totalPages; p++) {
        pageElements.push(<PageElement active={curPage === p} label={p} onSelect={handlePageClick} labelId={p} />);
      }
    } else {
      pageElements.push(<PageElement active={curPage === 1} label={1} onSelect={handlePageClick} labelId={`first`} />);
      if (curPage > 3) {
        pageElements.push(<PageElement active={false} label={'...'} onSelect={handlePageClick} labelId={`first-dots`} />);
      }

      // Determine how many pages to show after the current page index
      if (curPage === 1) {
        pageCutHigh += 2;
      } else if (curPage === 2) {
        pageCutHigh += 1;
      }

      // Determine how many pages to show before the current page index
      if (curPage === totalPages) {
        pageCutLow -= 2;
      } else if (page === totalPages - 1) {
        pageCutLow -= 1;
      }

      // Output the indexes for pages that fall inside the range of pageCutLow and pageCutHigh
      for (let p = pageCutLow; p <= pageCutHigh; p++) {
        if (p > totalPages || p === 0) {
          continue;
        }
        if (p !== 1) {
          pageElements.push(<PageElement active={curPage === p} label={p} onSelect={handlePageClick} labelId={p} />);
        }
      }

      // Show the very last page preceded by a "..." at the end of the pagination section (before the Next button)
      if (curPage < totalPages - 1) {
        if (curPage < totalPages - 2) {
          pageElements.push(<PageElement active={false} label={'...'} onSelect={handlePageClick} labelId={`last-dots`} />);
        }
        pageElements.push(<PageElement active={curPage === totalPages} label={totalPages} onSelect={handlePageClick} labelId={`last`} />);
      }
    }
    return pageElements;
  };

  return (
    <ul data-testid={'pagination-button-list'} className={'dhlPagination'}>
      <li
        className={classNames(styles.previousPageButton, styles.paginationItem, { [styles.prevPageButtonActive]: page !== 1 })}
        data-testid={'prev-page-link-list-item'}
        key={`pagerName-page-prev-icon`}
      >
        <button data-testid={'pagerName-previous'} className={styles.pageButton} disabled={page === 1} onClick={handlePrevClick}>
          <div className={classNames(styles.svgIcon, { [styles.svgIconActive]: page !== 1 })}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon
                fill="#878787"
                fillRule="evenodd"
                points="14.13 12 7 0 9.956 0 17.087 12 9.956 24 7 24"
                transform="matrix(-1 0 0 1 24.087 0)"
              />
            </svg>
          </div>
        </button>
      </li>
      {generatePages(page, props.pages)}
      <li
        className={classNames(styles.nextPageButton, styles.paginationItem, { [styles.nextPageButtonActive]: page !== props.pages })}
        data-testid={'next-page-link-list-item'}
        key={`pagerName-page-next-icon`}
      >
        <button data-testid={'pagerName-next'} className={styles.pageButton} disabled={page === props.pages} onClick={handleNextClick}>
          <div className={`${classNames(styles.svgIcon, { [styles.svgIconActive]: page !== props.pages })} svgIcon-link-arrow-next`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon fill="#878787" fillRule="evenodd" points="14.13 12 7 0 9.956 0 17.087 12 9.956 24 7 24" />
            </svg>
          </div>
        </button>
      </li>
    </ul>
  );
};
