import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import iconPayer from 'assets/icon-payer.svg';
import iconPayerInfo from 'assets/icon-payer-info.svg';
import { Button } from 'common/components/Button';
import classNames from 'classnames';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import styles from './PressStep3.module.css';
import {
  ZahlungspflichtigerInfo,
  ZahlungspflichtigerNichtBearbeitbar
} from 'order/productGroups/press/components/ZahlungspflichtigerNichtBearbeitbar';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useFormContext } from 'react-hook-form';
import { useOrderContext } from '../../../../common/context/order/OrderContext';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { PressOrderCreate, validatePressOrderCreate } from 'order/productGroups/press/schema/pressSchema';

export const PressStep3: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const { getValues, trigger, setError, clearErrors } = useFormContext<PressOrderCreate>();
  const { isDirty, meta } = useOrderContext();

  const payerInfo = useMemo<ZahlungspflichtigerInfo>(
    () => ({
      kundennummer: meta?.journalPaymentPayer?.customerId,
      kundenname: meta?.journalPaymentPayer?.name,
      kontoIban: meta?.journalPaymentPayer?.iban,
      kontoBankName: meta?.journalPaymentPayer?.bankName
    }),
    [meta]
  );

  return (
    <>
      <div className={styles.payerContainer}>
        <DialogPostContainer>
          <DHLFormGroup>
            <OrderStepHeadline icon={iconPayer} alt="Payee Icon">
              {t('pressDistribution.zahlungspflichtiger.headline')}
            </OrderStepHeadline>
            <section>
              <ZahlungspflichtigerNichtBearbeitbar
                name="payer"
                fieldTitle={t('pressDistribution.zahlungspflichtiger.title')}
                fieldPlaceholder={t('pressDistribution.zahlungspflichtiger.placeholder')}
                infoIcon={iconPayerInfo}
                customerInfo={payerInfo}
              />
            </section>
          </DHLFormGroup>
          <MarginalColumn />
        </DialogPostContainer>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between', styles.marginTop26)}>
        <div>
          <Button style={{ marginRight: '10px' }} variant="default" onClick={() => previousStep()} type="button" label={t('buttons.back')} />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            const isContentValid = await trigger(['payer']);
            const areDependentFieldsValid = validatePressOrderCreate(getValues(), setError, clearErrors, 3);
            if (isContentValid && areDependentFieldsValid) {
              nextStep();
            }
          }}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
