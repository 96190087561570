import React, { useState } from 'react';
import styles from './OrderVersionDialog.module.css';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { useTranslation } from 'i18n';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderVersionTypes } from './dtos/OrderVersionTypes';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { Tooltip } from 'common/components/Tooltip/Tooltip';

export interface OrderVersion {
  lastChangedOn?: string;
  orderVersion?: string;
}

export interface OrderVersionDialogProps {
  orderVersions?: OrderVersion[];
}

export const OrderVersionDialog = (props: OrderVersionDialogProps) => {
  const { t } = useTranslation('', { keyPrefix: 'orderVersionDialog' });
  const { upsertMetaData, order, meta } = useOrderContext();
  const { user } = useAuthContext();

  const latestOrderVersion = props?.orderVersions?.find((item: OrderVersion) => item.orderVersion === order?.orderVersion);

  const [selectedOrderVersionOption, setSelectedOrderVersionOption] = useState<OrderVersion | undefined>(
    meta?.selectedOrderVersion || latestOrderVersion
  );

  const handleOrderVersionChange = (option?: OrderVersion) => {
    setSelectedOrderVersionOption(option);
    upsertMetaData('selectedOrderVersion', option);
  };

  const latestOrderVersionDate = latestOrderVersion?.lastChangedOn ? new Date(latestOrderVersion?.lastChangedOn) : undefined;
  const selectedVersionLastChangedOnDate = selectedOrderVersionOption?.lastChangedOn
    ? new Date(selectedOrderVersionOption?.lastChangedOn)
    : undefined;

  const isLatestOrderVerionSelected = latestOrderVersion?.orderVersion === selectedOrderVersionOption?.orderVersion;

  const valueToStringHandler = (option: OrderVersion) => {
    const lastChangedOnDate = option?.lastChangedOn ? new Date(option?.lastChangedOn) : undefined;
    switch (option.orderVersion) {
      case OrderVersionTypes.IS:
      case OrderVersionTypes.SO:
      case OrderVersionTypes.AK:
        return t(`versions.${option?.orderVersion?.toString()}` || '');
      default:
        return `${t(`versions.${option?.orderVersion?.toString()}`)} ${lastChangedOnDate?.toLocaleDateString(user?.language)}`;
    }
  };

  return (
    <>
      <div className={`${styles.orderVerionDialogBox} ${isLatestOrderVerionSelected ? styles.success : styles.warning}`}>
        <div className={styles.orderVerionDialogTextBox}>
          <div>
            <p>
              {`${t(`latestVersionText.${latestOrderVersion?.orderVersion}`)} (${latestOrderVersionDate?.toLocaleDateString(
                user?.language
              )}, ${latestOrderVersionDate?.toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC'
              })} ${t('hour')})`}
            </p>
            {!isLatestOrderVerionSelected && (
              <>
                <p>{`${t(`notLatestVersionText.${selectedOrderVersionOption?.orderVersion}`)}`}</p>
                <p>
                  {`(${selectedVersionLastChangedOnDate?.toLocaleDateString(user?.language)}, ${selectedVersionLastChangedOnDate?.toLocaleTimeString(
                    'de-DE',
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                      timeZone: 'UTC'
                    }
                  )} ${t('hour')})`}
                </p>
              </>
            )}
          </div>
          {!isLatestOrderVerionSelected && (
            <div className={styles.tooltipContainer}>
              <Tooltip content={t('tooltip')}>{<i className={`${styles.tooltip} icon-alert-info`} />}</Tooltip>
            </div>
          )}
        </div>
        <div className={`${styles.orderVersionOptionsContainer}`}>
          <AMDHLSelectSingle
            name="orderVersionOptions"
            data-testid={'orderVersionOptions'}
            wrapperClassName={styles.orderVersionOptionsDropdown}
            items={props?.orderVersions || []}
            valueToString={(option) => valueToStringHandler(option)}
            value={selectedOrderVersionOption}
            onChange={handleOrderVersionChange}
          />
        </div>
      </div>
    </>
  );
};
