import React, { ErrorInfo } from 'react';

import { ErrorMessageContainer } from '../../../order/productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';

export interface ErrorBoundaryProps {
  context?: string;
  useErrorUI?: JSX.Element;
  useDefaultError?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`ErrorBoundary::${this.props.context} `, error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.useErrorUI ? this.props.useErrorUI : <ErrorMessageContainer message={'An error occurred'} dataTestId={'boundary-error'} />;
    }

    return this.props.children;
  }
}
