import { useMemo } from 'react';
import useSWR from 'swr/immutable';

import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { PressDistributionBaseProduct } from '../dtos/PressDistributionBaseProduct';
import { requestPressDistributionBaseProductsBasedOnZkz } from '../services/pressDistributionBaseProducts';

export const usePressDistributionBaseProducts = (zkz?: string, date = new Date()): PressDistributionBaseProduct[] => {
  const { data: products = [] } = useSWR(zkz?.length ? ['pressDistributionBaseProducts', zkz] : [], async (_, zkz) =>
    requestPressDistributionBaseProductsBasedOnZkz(zkz).catch(() => [] as PressDistributionBaseProduct[])
  );

  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);

  return filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
};
