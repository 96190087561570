import { IDENTITY, ReducerTuple, uuid } from 'common/utils/helpers';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import React, { createContext, Dispatch, ReactElement, Reducer, useContext, useReducer, VFC } from 'react';

export type Alert = {
  id: string;
  type: AlertTypes;
  title?: string;
  description?: string;
  created: Date;
};

type State = Map<string, Alert>;
type Action = ['clear'] | ['remove', string] | ['add', string, Alert];

const reducer: Reducer<State, Action> = (state, action) => {
  const [type] = action;
  switch (type) {
    case 'clear':
      return new Map<string, Alert>();
    case 'add': {
      const [, id, alertObject] = action;
      return new Map(state.set(id, alertObject));
    }
    case 'remove': {
      const [, id] = action;
      const newMap = new Map(state);
      newMap.delete(id);
      return newMap;
    }
    default:
      return state;
  }
};

const Context = createContext<ReducerTuple<typeof reducer>>([new Map<string, Alert>(), IDENTITY]);

export const AlertProvider: VFC<{ children?: ReactElement }> = ({ children }) => {
  const reducerReturn = useReducer(reducer, new Map<string, Alert>(), IDENTITY);
  return <Context.Provider value={reducerReturn}>{children}</Context.Provider>;
};

export type AddAlertParam = {
  type: AlertTypes;
  title?: string;
  description?: string;
};

export interface UseAlertReturn {
  alerts: Alert[];
  addAlert: (alert: AddAlertParam) => void;
  clear: Dispatch<void>;
  remove: Dispatch<string>;
  getAlert: (id: string) => Alert | undefined;
}

export const useAlerts = (): UseAlertReturn => {
  const [state, dispatch] = useContext(Context);
  const alerts = Array.from(state.values()).sort((a, b) => a.created.getTime() - b.created.getTime());
  return {
    alerts,
    addAlert: (alert) => {
      const id = uuid();
      const created = new Date();
      dispatch(['add', id, { id, created, title: alert.title, type: alert.type, description: alert.description }]);
    },
    clear: () => dispatch(['clear']),
    remove: (id) => dispatch(['remove', id]),
    getAlert: (id) => state.get(id)
  };
};
