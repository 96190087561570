import { ArrayPath, Control, FieldPath, FieldValues, FormState, get, Path, PathValue, RegisterOptions, Validate } from 'react-hook-form';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';

export type PathsEndingWith<End extends string> = `${string}.${End}`;

export type PathToType<TFieldValues extends FieldValues = FieldValues, TSubTypes = unknown> = FieldPath<TFieldValues> extends `${infer TFullPath}`
  ? TFullPath extends FieldPath<TFieldValues>
    ? PathValue<TFieldValues, TFullPath> extends TSubTypes
      ? TFullPath
      : never
    : never
  : never;

export type PathToArrayType<
  TFieldValues extends FieldValues = FieldValues,
  TSubTypes = unknown
> = ArrayPath<TFieldValues> extends `${infer TFullPath}`
  ? TFullPath extends ArrayPath<TFieldValues>
    ? PathValue<TFieldValues, TFullPath> extends Array<TSubTypes>
      ? TFullPath
      : never
    : never
  : never;

export type SubFormComponentProps<TFieldValues extends FieldValues, TSubType = unknown> = {
  name: PathsEndingWith<PathToType<TFieldValues, TSubType>> | PathToType<TFieldValues, TSubType>;
  rules?: Omit<
    RegisterOptions<TFieldValues, Path<TFieldValues>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled' | 'value' | 'validate'
  > & {
    value?: TSubType;
    validate?: Validate<TSubType, any>;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/ban-types
  control?: Control<any, object>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContaining<S> = {
  [k: string]: S;
};

export type SFCProps<T extends FieldValues, S> = SubFormComponentProps<T, S>;

export const getError = <T extends FieldValues>(formState: FormState<T>, path: string): string | undefined =>
  get(formState, `errors.${path}.message`);

export const createErrorConditional =
  (errorMessage: string) =>
  ({ fieldState }: { fieldState: ControllerFieldState }) =>
    fieldState.error ? errorMessage : undefined;

export const isValidInteger = (value: number) => {
  return !value || value % 1 === 0 ? true : 'noValidInt';
};

export const isNotBlank = (value: string): boolean => {
  return !isBlank(value);
};

export const isBlank = (value: string): boolean => {
  return !value || value.trim().length == 0;
};

export const isValidPostCode = (value: string): boolean => {
  const pickupAddressPostCodeRegex = /^\s*(?!-)(?!.*-.*-)(?!.*\s.*\s)(?=[A-Za-z0-9- ]{3,10}$)[A-Za-z0-9]+(?:[-\s][A-Za-z0-9]*(?!-)\b)?\s*$/;
  return pickupAddressPostCodeRegex.test(value);
};
