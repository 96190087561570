import React, { useRef, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { Menu } from 'common/components/ContextMenu';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { useBoolean } from 'common/hooks/useBoolean';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { PostageType } from 'order/common/dtos/PostageType';
import { PrintDialog } from 'order/orderView/components/PrintDialog/PrintDialog';
import { CancelOrder } from 'order/productGroups/common/components/molecule/CancelOrder/CancelOrder';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { GetOrderRep } from 'generated';

import styles from './ToolBar.module.css';
import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { useOrderContext } from '../../../common/context/order/OrderContext';
import { OrderCategoryProductKey } from '../../../common/context/order/dtos/OrderCategoryProductKey';
import { Button } from 'common/components/Button';
import { OnlineHelpLinks } from '../../../common/dtos/OnlineHelpLink';
import { SplitOrderModal } from '../SplitOrder/SplitOrderModal/SplitOrderModal';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderCreate } from '../../../productGroups/common/utils/orderCreateSchema';
import { OrderMergeModal } from '../OrderMerge/components/OrderMergeModal/OrderMergeModal';
import { OnlineHelp } from '../../../common/components/OnlineHelp/OnlineHelp';
import { MovePalletModal } from 'order/orderView/components/MovePallet/MovePalletModal/MovePalletModal';

export type ToolBarProps = {
  order: Order;
  productionState?: ProductionState;
  isPayerValidated?: boolean;
  invoiceCenterLink?: string;
};

export const ToolBar: VFC<ToolBarProps> = ({ order, productionState, isPayerValidated, invoiceCenterLink }) => {
  const [menuVisible, setMenuVisible] = useBoolean(false);
  const headerCellRef = useRef<HTMLDivElement>(null);
  const focusRef = useRef<HTMLDivElement>(null);
  useClickOutside([headerCellRef], () => setMenuVisible.off());
  useKeyPress([focusRef], ['Enter', 'Escape'], () => {
    setMenuVisible.off();
  });
  const { hasPermission, checkWhitelistingKey, checkWhitelistingWithProductionState } = useAuthContext();
  const [isPrintDialogOpen, setPrintDialogOpen] = useBoolean(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useBoolean(false);
  const [isSplitOrderDialogOpen, setSplitOrderDialogOpen] = useBoolean(false);
  const [isMovePalletDialogOpen, setMovePalletDialogOpen] = useBoolean(false);
  const [isMergeOrderDialogOpen, setMergeOrderDialogOpen] = useBoolean(false);
  const history = useHistory();
  const { clear, addAlert } = useAlerts();
  const { setCancelOrderStatus } = useSearchContext();
  const { t } = useTranslation('orderSearch');
  const isPartial = order.orderType === OrderTypes.Zusatzauftrag;
  const {
    allocationsLoading,
    order: rawOrder,
    orderPrice,
    allocationsOrders,
    setOrderCategoryProductKey,
    resetAllocations,
    reset,
    resetOrderReferences
  } = useOrderContext();

  // Checks for Pallets move, split & merge buttons
  const isPalletMoveAllowed =
    hasPermission(UserRights.PalletsMove) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.PALLETSMOVE);
  const isSplitAllowed =
    hasPermission(UserRights.OrderSplit) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.SPLIT);

  const isMergeAllowed =
    hasPermission(UserRights.OrderMerge) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.MERGE);

  const isInvoiceCenterAllowed =
    hasPermission(UserRights.InvoiceCenter) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.INVOICE);

  const methods = useForm<OrderCreate>({
    mode: 'onBlur'
  });
  const helpLinkOnProduct = (): OnlineHelpLinks => {
    return OnlineHelpLinks[(order?.orderCategoryProductKey + '_DISPLAY') as keyof typeof OnlineHelpLinks];
  };

  const isPrintReady =
    rawOrder?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
    rawOrder?.productGroup === ProductGroup.TEILLEISTUNG ||
    rawOrder?.productGroup === ProductGroup.COLLECTION
      ? true
      : !!orderPrice?.lastChangedAt;

  const disableEdit =
    rawOrder?.pendingIds ||
    rawOrder?.disableEditing ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.CHANGE)) ||
    !hasPermission(UserRights.OrderEdit) ||
    productionState === ProductionState.CANCELED ||
    productionState === ProductionState.CANCELED_AND_MERGED ||
    productionState === ProductionState.BILLED ||
    productionState === ProductionState.BEING_PROCESSED;

  const disableDelete =
    rawOrder?.pendingIds ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.DELETE)) ||
    !hasPermission(UserRights.OrderEdit) ||
    productionState === ProductionState.CANCELED ||
    productionState === ProductionState.CANCELED_AND_MERGED ||
    productionState === ProductionState.BILLED ||
    productionState === ProductionState.BEING_PROCESSED;

  const disableCopy =
    rawOrder?.pendingIds ||
    rawOrder?.disableEditing ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.COPY)) ||
    !hasPermission(UserRights.OrderEdit) ||
    isPartial ||
    order.postage?.type === PostageType.DV ||
    order.orderCategory === OrderCategory.DIP_SAMMEL ||
    order.orderCategory === OrderCategory.DIP_VARIO ||
    order.orderCategory === OrderCategory.DV_BRIEF;

  const disablePrint =
    rawOrder?.pendingIds ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.PRINT)) ||
    !hasPermission(UserRights.OrderPrint) ||
    ((order.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || order.productGroup === ProductGroup.TEILLEISTUNG) && allocationsLoading) ||
    !isPrintReady;
  return (
    <>
      <ErrorBoundary context={'OderView-Toolbar-PrintDialog'}>
        <PrintDialog
          show={isPrintDialogOpen}
          orderId={order.orderId || ''}
          onCancel={() => setPrintDialogOpen.off()}
          rawOrder={{ ...order, orderPrice: orderPrice } as GetOrderRep}
          allocatedOrders={allocationsOrders}
          orderCategory={order?.orderCategory}
        />
      </ErrorBoundary>
      <CancelOrder
        orderId={order.orderId || ''}
        orderLabel={order.orderLabel || ''}
        show={isCancelDialogOpen}
        onCancel={() => {
          setCancelDialogOpen.off();
        }}
        orderMode={OrderMode.VIEW}
      />
      <FormProvider {...methods}>
        <SplitOrderModal order={order} show={isSplitOrderDialogOpen} onCancel={() => setSplitOrderDialogOpen.off()} />
        <MovePalletModal order={order} show={isMovePalletDialogOpen} onCancel={() => setMovePalletDialogOpen.off()} />
      </FormProvider>

      <FormProvider {...methods}>
        <OrderMergeModal order={order} show={isMergeOrderDialogOpen} onCancel={() => setMergeOrderDialogOpen.off()} />
      </FormProvider>
      <div className={classNames(styles.toolbarList)}>
        <div className={classNames(styles.toolbarLists)}>
          <DHLButton
            name={'neuen-auftrag'}
            label={i18n.t('viewOrderToolBar.NewOrder')}
            type={'default'}
            size="sm"
            disabled={!hasPermission(UserRights.OrderEdit)}
            onClick={() => {
              clear();
              reset();
              resetOrderReferences();
              resetAllocations();
              setOrderCategoryProductKey(OrderCategoryProductKey.DiP_EINZEL__DIALOGPOST);
              history.push(AppPaths.orderCreatePath);
            }}
          />
        </div>
        <div className={classNames(styles.toolbarLists)} ref={focusRef} data-testid="focusWrapper">
          <DHLButton
            name={'more-functions'}
            icon={'more-functions'}
            iconPosition={'icon'}
            type={'default'}
            size="sm"
            onClick={() => {
              setMenuVisible.toggle();
            }}
          />

          <Menu ref={headerCellRef} isOpen={menuVisible} className={classNames(styles.toolbarcontextMenu)} data-testid="subMenu">
            <DHLButton
              name={'auftrag-bearbeiten'}
              label={i18n.t('viewOrderToolBar.toggleMenu.edit')}
              icon={'edit'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={disableEdit}
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderChangePath}/${order.orderId}${isPartial ? '?orderType=ZA' : ''}`);
              }}
            />
            <DHLButton
              name={'auftrag-stornieren'}
              label={i18n.t('viewOrderToolBar.toggleMenu.delete')}
              icon={'delete'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={disableDelete}
              onClick={async () => {
                setCancelDialogOpen.on();
                setCancelOrderStatus(undefined, '');
              }}
            />
            <DHLButton
              name={'auftrag-drucken'}
              label={i18n.t('viewOrderToolBar.toggleMenu.print')}
              icon={'print'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={disablePrint}
              onClick={() => {
                setMenuVisible.off();
                setPrintDialogOpen.on();
              }}
            />
            <DHLButton
              name={'auftrag-duplizieren'}
              label={i18n.t('viewOrderToolBar.toggleMenu.copy')}
              icon={'copy'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={disableCopy}
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderCopyPath}/${order.orderId}`);
              }}
            />
            {isInvoiceCenterAllowed && isPayerValidated && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-rechnungscenter'}
                  label={i18n.t('viewOrderFooter.menu.invoice')}
                  // iconClass={'icon-integration-rechnungscenter'}
                  JSXIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                      <path
                        fill="#D40511"
                        fillRule="evenodd"
                        d="M20.1071,0 C21.1518,0 22,0.8482 22,1.8929 L22,1.8929 L22,22.1072 C22,23.1519 21.1518,24 20.1071,24 L20.1071,24 L3.8928,24 C2.8481,24 2,23.1519 2,22.1072 L2,22.1072 L2,1.8929 C2,0.8482 2.8481,0 3.8928,0 L3.8928,0 Z M20,2 L4,2 L4,22 L20,22 L20,2 Z M13.1847,5 C14.7479,5 16.0205,5.5111 17,6.5384 L17,6.5384 L15.5834,8.0486 C15.237,7.7082 14.8995,7.47 14.5742,7.3302 C14.186,7.1634 13.7693,7.0795 13.3236,7.0795 C12.1025,7.0795 11.2576,7.7209 10.8069,9.026 L10.8069,9.026 L10.6968,9.3453 L13.8112,9.3453 L13.8112,10.8641 L10.4962,10.8641 L10.4962,12.4491 L13.8112,12.4491 L13.8112,13.968 L10.7015,13.968 L10.8059,14.2842 C11.2422,15.6045 12.0875,16.2538 13.3236,16.2538 C13.7693,16.2538 14.186,16.1698 14.5742,16.0031 C14.8995,15.8632 15.237,15.6251 15.5834,15.2847 L15.5834,15.2847 L17,16.7949 C16.0205,17.8222 14.7479,18.3333 13.1847,18.3333 C11.9368,18.3333 10.8709,17.9732 9.9886,17.2485 C9.1016,16.5198 8.5167,15.4884 8.2252,14.159 L8.2252,14.159 L8.1834,13.968 L7,13.968 L7,12.4491 L8.0322,12.4491 L8.0322,10.8641 L7,10.8641 L7,9.3453 L8.1811,9.3453 L8.2246,9.1569 C8.529,7.8392 9.1178,6.8135 9.9992,6.0842 C10.8749,5.3598 11.9373,5 13.1847,5 Z"
                      />
                    </svg>
                  }
                  variant="ghost"
                  type={'button'}
                  sizing="sm"
                  dataTestId="InvoiceOrderButton"
                  onClick={() => {
                    window.open(invoiceCenterLink, '_blank');
                  }}
                />
              </div>
            )}
            {isSplitAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-teilen'}
                  label={i18n.t('viewOrderFooter.menu.share')}
                  // JSXIcon={<span className="icon icon-split"></span>}
                  JSXIcon={
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                      <path
                        fill="#D40511"
                        fillRule="evenodd"
                        d="M554.667 487.006v366.327h-85.333v-347.381l-256.010-256.011v141.065h-85.333v-284.339h284.339v85.333h-136.267l226.466 226.466 226.466-226.466h-131.661v-85.333h284.339v284.339h-85.333v-145.67l-241.673 241.67z"
                      ></path>
                    </svg>
                  }
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="splitOrderButton"
                  onClick={() => {
                    setSplitOrderDialogOpen.on();
                    clear();
                  }}
                />
              </div>
            )}
            {isMergeAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-zusammenfuhren'}
                  label={i18n.t('viewOrderFooter.menu.merge')}
                  // JSXIcon={<span className="icon icon-merge"></span>}
                  JSXIcon={
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                      <path
                        fill="#D40511"
                        fillRule="evenodd"
                        d="M371.505 371.503l97.83-97.83v284.657l-264.611 264.61 60.34 60.341 247.164-247.166 247.166 247.166 60.339-60.341-265.065-265.065v-284.644l98.274 98.272 60.339-60.339-201.058-201.058-201.058 201.058 60.339 60.339z"
                      ></path>
                    </svg>
                  }
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="mergeOrderButton"
                  onClick={() => {
                    resetOrderReferences();
                    resetAllocations();
                    setMergeOrderDialogOpen.on();
                  }}
                />
              </div>
            )}
            {isPalletMoveAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-paletten-verschieben'}
                  label={i18n.t('viewOrderFooter.menu.movePallets')}
                  // JSXIcon={<span className="icon icon-move"></span>}
                  JSXIcon={
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                      <path
                        fill="#D40511"
                        fillRule="evenodd"
                        d="M469.335 241.478l-77.993 77.993-60.339-60.339 181.020-181.020 181.018 181.020-60.339 60.339-78.033-78.033v227.895h239.934l-77.99-77.99 60.339-60.339 181.020 181.020-181.020 181.018-60.339-60.339 78.035-78.035h-239.979v229.086l78.033-78.033 60.339 60.339-181.018 181.020-181.020-181.020 60.339-60.339 77.993 77.99v-229.043h-229.090l78.035 78.035-60.339 60.339-181.020-181.018 181.020-181.020 60.339 60.339-77.99 77.99h229.045v-227.855z"
                      ></path>
                    </svg>
                  }
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="movePalletsButton"
                  onClick={() => {
                    setMovePalletDialogOpen.on();
                    clear();
                  }}
                />
              </div>
            )}
          </Menu>
        </div>
        <OnlineHelp link={helpLinkOnProduct()} />
      </div>
    </>
  );
};
