import { PressDistributionSupplementProduct } from '../dtos/PressDistributionSupplementProduct';
import { getUser } from 'common/context/auth/AuthProvider';
import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';

export const requestPressDistributionSupplementProductsBasedOnZkz = async (
  zkz?: string,
  productNumbers?: string[],
  validityPoint?: string
): Promise<PressDistributionSupplementProduct[]> => {
  const user = getUser();
  try {
    const { data } = await sendRequest({
      method: 'get',
      url: `${BASE_URL}masterdata/pressdistribution/supplementProducts`,
      params: {
        zkz,
        productNumbers,
        validityPoint
      },
      token: user._accessToken,
      extUserId: user.extUserId
    });

    if (data) {
      return data;
    }

    return [];
  } catch (e: any) {
    console.error('Fetching press distribution supplement products failed', e);
    return [];
  }
};
