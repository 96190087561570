import { FetchAdapter, LocalizationPack, ResourceDataStore } from '@gkuis/gkp-base-widgets/dist/lib';
import i18n from 'i18n';

export class I18NextLocalizationPack extends LocalizationPack {
  constructor() {
    super('gkp', new Map<string, string>());
  }

  getMessage(key: string, params?: string[]): string {
    return i18n.t(key, Object.assign({}, params));
  }
}

class NoOpResource extends ResourceDataStore {
  static fetchAdapter = new FetchAdapter(undefined);

  static localicationPack = new I18NextLocalizationPack();

  constructor() {
    super(NoOpResource.fetchAdapter, '', '', '');
  }

  get localizationPack(): I18NextLocalizationPack {
    return NoOpResource.localicationPack;
  }
}

export default NoOpResource;

export const DefaultNoOpResource = new NoOpResource();
