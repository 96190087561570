import { AuthenticationManager } from '@gkuis/gkp-authentication/dist/AuthenticationManager';

import { AuthManager } from 'common/context/auth/authManager';
import { AuthProvider } from 'common/context/auth/AuthProvider';
import React from 'react';

import styles from './App.module.css';
import Main from './Main';

export interface AppProps {
  name?: string;
  authenticationManager?: AuthManager | AuthenticationManager;
  extUserId?: string;
  path?: 'search' | 'create';
}

/**
 * The main app function
 *
 * @constructor
 */
const App = (props: AppProps): JSX.Element | null => {
  return !props.authenticationManager || props.authenticationManager.authenticated ? (
    <div className={styles.app}>
      <AuthProvider>
        <Main {...props} />
      </AuthProvider>
    </div>
  ) : null;
};

export default App;
