import { ProductionFacilityTO } from 'generated';
import { getProductionFacility } from 'order/productGroups/common/services/ProductionFacilityService';
import { useCallback } from 'react';
import useSWR from 'swr/immutable';

export type UseProductionFacilityReturn = {
  facilities: ProductionFacilityTO[] | undefined;
  getFacility: (id: string) => ProductionFacilityTO | undefined;
  isLoading: boolean;
};

export const useProductionFacility = ({
  region,
  country,
  inductionDate
}: {
  region?: string;
  country?: string;
  inductionDate?: string;
}): UseProductionFacilityReturn => {
  const validRequest = (region || (region && inductionDate) || country || (country && inductionDate)) && !(region && country && inductionDate);
  const { data, isValidating } = useSWR<ProductionFacilityTO[] | undefined>(
    validRequest ? ['productionFacilityss', region, country, inductionDate] : null,
    async () => await getProductionFacility({ region: region?.slice(0, 2), country, inductionDate })
  );

  const getFacility = useCallback((catalogId: string) => data?.find(({ catalogId: c }) => c === catalogId), [data]);

  return {
    facilities: validRequest ? data : [],
    getFacility,
    isLoading: isValidating
  };
};
