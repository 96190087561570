// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.destination-section_centerRow__iMRGf {
  align-items: center;
}
.destination-section_tooltip__LL5Oq {
  font-size: 20px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/DestinationSection/destination-section.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,SAAS;AACX","sourcesContent":[".centerRow {\n  align-items: center;\n}\n.tooltip {\n  font-size: 20px;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerRow": `destination-section_centerRow__iMRGf`,
	"tooltip": `destination-section_tooltip__LL5Oq`
};
export default ___CSS_LOADER_EXPORT___;
