import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { CreateInternationalOrder, LetterBaseProduct, OrderCustomer, OrderCustomerRoleEnum, OrderDestination } from '../../../../generated';
import { PackagingSchema } from 'order/productGroups/common/components/molecule/PackagingSection';
import { InternationalBaseProduct } from '../dtos/InternationalBaseProducts';
import { PostageType } from '../../../common/dtos/PostageType';
import { TimeCitySchema } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { AddressSectionSchema } from 'order/productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OtherPartnerSchema } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { UseFormSetError } from 'react-hook-form';
import { ObjectContaining } from 'common/utils/formHelpers';
import { isValidDate } from 'order/common/utils/isValidDate';
import { DestinationSchema } from 'order/productGroups/common/components/atom/AMDHLDestinationInput';
import { validateSettlementNumber } from '../../../../common/utils/helpers';

export type InternationalDestinationSectionSchema = {
  priority?: number;
  economy?: number;
  mixed?: number;
  totalWeight: number;
  netWeight: number;
  stackable?: boolean;
  matrix?: DestinationSchema[];
};

export enum InternationalDestinationZipCodes {
  PRIORTY = '62',
  ECONOMY = '36999',
  MIXED = 'DE'
}

export type InternationalClusterOrderCreate = {
  orderNumber?: string;
  productGroup: ProductGroup;
  orderCategory?: string;
  productionState?: ProductionState;
  orderId?: string;
  internationalBaseProduct: InternationalBaseProduct;
  transportServices: LetterBaseProduct[];
  additionallyPaidItems?: number;
  subProducts?: Partial<{
    gogreen: boolean;
  }>;
  orderLabel: string;
  originator: string;
  submitter: string;
  takeOriginatorAsSubmitter?: boolean;
  pickupAddress?: AddressSectionSchema;
  contactForQuestions?: ContactSchema;
  packaging: PackagingSchema;
  destinations: InternationalDestinationSectionSchema;
  details: TimeCitySchema;
  postageType?: PostageType;
  additionalCharge?: PostageType;
  paymentClearingNumber?: string;
  postageAmountValue?: number;
  postageMachineCode?: string;
  payer?: string;
  otherPartners?: OtherPartnerSchema[];
  changeOrder?: CreateInternationalOrder;
  preservedAmexOrder?: string;
};

export enum dependentProductsAndVariants {
  goGreen = 'GOGREEN'
}

export const validateInternationalClusterOrderCreate = (
  values: InternationalClusterOrderCreate | undefined,
  setError: UseFormSetError<InternationalClusterOrderCreate>,
  step: number = 2
): boolean => {
  let validates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<InternationalClusterOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;

  if (step === 2) {
    if (values?.packaging?.type) {
      if (!values?.packaging?.quantity) {
        validates = false;
        error(`packaging.quantity`, { message: 'required' });
      }
    } else {
      if (values?.packaging?.quantity) {
        validates = false;
        error(`packaging.type`, { message: 'required' });
      }
    }
    if (values?.packaging?.type === 'PAL') {
      const destinationSum = (values?.destinations?.economy ?? 0) + (values?.destinations?.mixed ?? 0) + (values?.destinations?.priority ?? 0);
      if (destinationSum !== values?.packaging?.quantity) {
        validates = false;
        error(`packaging.quantity`, { message: 'internationalPackagingNotMatching' });
      } else {
        validates = true;
        error(`packaging.quantity`, { message: '' });
      }
    }
    if (values?.otherPartners && values?.otherPartners?.length) {
      values?.otherPartners?.map((p, i) => {
        if (p.role && !p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        } else if (!p.role && p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].role`, { type: 'required', message: 'error.customerIdUnknown' });
        } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        }
      });
    }
    if (values?.orderLabel && values?.orderLabel.length >= 80) {
      validates = false;
    }
    if (values?.additionallyPaidItems && values?.additionallyPaidItems.toString().length > 9) {
      validates = false;
    }
    if (!values?.submitter) {
      validates = false;
      error(`submitter`, { type: 'required', message: 'required' });
    }
    if (!values?.originator) {
      validates = false;
      error(`originator`, { type: 'required', message: 'required' });
    }
    if (!values?.pickupAddress && !values?.details?.productionPlantId) {
      validates = false;
      error(`details.productionPlantId`, { type: 'required', message: 'required' });
    }
    const totalWeight = values?.destinations?.totalWeight || 0;
    const quantity = values?.packaging?.quantity || 0;

    if (quantity > 0 && totalWeight / quantity > 700) {
      validates = false;
      error(`destinations.totalWeight`, { type: 'max', message: 'palletsInternational.error.totalWeight' });
    }
  }

  if (step === 3) {
    if (!values?.payer) {
      validates = false;
      error(`payer`, { type: 'required', message: 'error.required' });
    }
    if (values?.payer && !validateSettlementNumber(values?.payer, values?.productGroup !== ProductGroup.DIALOGPOST_INTERNATIONAL)) {
      validates = false;
      error(`payer`, { type: 'error.required', message: 'payer.bankInformation.error.abrechnungsValidation' });
    }
  }

  return validates;
};

export const translateInternationalCluster = (
  // { content, payment, orderCategory, orderId, productionState }: DialogMarketingOrderCreate,
  values: InternationalClusterOrderCreate,
  // dependent: UseDependentProductsReturn,
  change: boolean = false
): CreateInternationalOrder & { productionState?: ProductionState } => {
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: values.takeOriginatorAsSubmitter ? values.originator.toString().replace(/ /g, '') : values.submitter.toString().replace(/ /g, ''),
      contact: values.contactForQuestions ? values.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Originator,
      customerId: values.originator.toString().replace(/ /g, '')
    }
  ];
  if (values.payer) {
    parties.push({
      role: OrderCustomerRoleEnum.Payer,
      customerId: values.payer.toString().replace(/ /g, '')
    });
  }

  const otherPartners =
    values.otherPartners
      ?.filter((customer) => customer.role && customer.customerId)
      .map<OrderCustomer>((customer) => ({
        role: customer.role as OrderCustomerRoleEnum,
        customerId: customer.customerId.toString().replace(/ /g, '')
      })) || [];

  parties.push(...otherPartners);

  // const products = dependent.fertigung;
  // const dependentProducts: DependentProduct[] = products ? subProductsToDependentProducts(values.subProducts, dependent) : [];

  // const { quantity, additionalQuantity, individualWeight: individualWeightValue } = values.details;

  const validDate = isValidDate(values.details.date!);
  const detailsDate = validDate ? values.details.date : new Date(values.details.date || '');
  const [hour = 0, minute = 0] = values.details.time?.match(/(\d{2,})/g)?.map(Number) || [];

  const earliestDateTime = new Date(Date.UTC(detailsDate.getFullYear()!, detailsDate.getMonth(), detailsDate?.getDate(), hour, minute));

  const matrix: DestinationSchema[] = [];

  if (values.destinations?.priority) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.PRIORTY, quantity: values.destinations?.priority });
  }
  if (values.destinations?.economy) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.ECONOMY, quantity: values.destinations?.economy });
  }
  if (values.destinations?.mixed) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.MIXED, quantity: values.destinations?.mixed });
  }

  const letterBaseProducts = values.transportServices;
  letterBaseProducts?.forEach((l: LetterBaseProduct) => {
    delete l.baseProduct.description;
    if (change) {
      l.destinations = l.destinations ? l.destinations : undefined;
      l.discounts = l.discounts?.length ? l.discounts : [];
    }
  });
  const submissionTypeValue = values.details?.submissionType ? values.details?.submissionType : null;

  return {
    productGroup: values.productGroup,
    customerOrderId: '5037346381-B2B',
    orderLabel: values.orderLabel,
    orderType: 'EA',
    orderCategory: values.orderCategory,
    orderId: values.orderId,
    packaging: values.packaging.type && {
      ...(change && { ...values.changeOrder?.packaging }),
      type: values.packaging.type,
      quantity: values.packaging.quantity,
      stackable: values.destinations?.stackable,
      totalWeight: values.destinations?.totalWeight,
      totalNetWeightValue: values.destinations?.netWeight,
      packagingEditable: values?.packaging?.packagingEditable
    },
    destinations: values?.destinations?.matrix as OrderDestination[],
    productionState: values.productionState,
    parties: parties.filter((party) => party.role && party.customerId),
    variants: [dependentProductsAndVariants.goGreen],
    orderDetail: {
      ...(change && { pickup: values.changeOrder?.orderDetail?.pickup }),
      productionPlantId: values.details.productionPlantId,
      date: earliestDateTime,
      submissionType: submissionTypeValue
    },
    pickupAddress: values.pickupAddress
      ? {
          orderAddress: values.pickupAddress
        }
      : undefined,
    dependentProducts: [],
    postage: {
      type: values.postageType,
      additionalCharge: values.additionalCharge,
      paymentClearingNumber: values.postageType && values.postageType === PostageType.DV ? values.paymentClearingNumber : undefined,
      machineCode: values.postageType && values.postageType === PostageType.AFM ? values.postageMachineCode : undefined,
      amount: values.postageAmountValue
    },
    letterBaseProducts: letterBaseProducts as LetterBaseProduct[],
    ...(values.productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL && { additionalQuantity: values.additionallyPaidItems }),
    ...(change && values.orderNumber && { orderId: values.orderNumber }),
    ...(change && { amexOrder: values.preservedAmexOrder })
  };
};
