import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppPaths } from 'Main';
import { Button } from 'common/components/Button';
import { useSearchContext } from '../../context/search/SearchContext';
import { BackToSearch } from './BackToSearch';

interface Props {
  isDirty: boolean;
  onCancel?: () => void;
}

export const BackToSearchButton = ({ isDirty, onCancel }: Props): JSX.Element => {
  const { t } = useTranslation('orderCreate');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { triggerLastSearch } = useSearchContext();

  return (
    <>
      <Button
        variant="default"
        onClick={() => {
          if (isDirty) setShowModal(true);
          else {
            triggerLastSearch();
            history.push(AppPaths.orderSearchPath);
          }
        }}
        dataTestId="toOrderSearch"
        label={t('buttons.toOrderSearch')}
        type="button"
      />
      <BackToSearch show={showModal} setShow={setShowModal} onCancel={onCancel} />
    </>
  );
};
