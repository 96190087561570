import classes from 'common/components/ContextMenu/context-menu.module.css';
import commonClasses from 'common/styles/common.module.css';
import { reference } from 'common/utils/helpers';
import { AllHTMLAttributes } from 'react';

export type LabelProps = AllHTMLAttributes<HTMLButtonElement> & {
  checked?: boolean;
  active?: boolean;
  noHover?: boolean;
};
export const Label = reference<HTMLButtonElement, LabelProps>(
  'Label',
  'button',
  ({ checked, active, noHover }) => [
    classes.item,
    commonClasses.textOverflow,
    { [classes.checked]: checked, [classes.active]: active, [classes.noHover]: noHover }
  ],
  ({ checked, active, noHover, ...props }) => ({ ...props, type: 'button' })
);
