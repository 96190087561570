import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FieldValues, useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { ObjectContaining, PathsEndingWith, SFCProps } from 'common/utils/formHelpers';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import styles from './SplitOrderContent.module.css';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import '../../../../../common/styles/dhlComponentCustomization.css';
import { DateInput } from '../../../../productGroups/common/components/atom/DateInput';
import { TimeCitySchema } from '../../../../productGroups/common/components/molecule/TimeCitySection';
import { CustomerSection } from '../../../../productGroups/common/components/molecule/CustomerSection';
import { OrderSearchKey } from '../../../../common/context/search/dtos/OrderSearchKey';
import { AddressSection } from '../../../../productGroups/common/components/molecule/AddressSection';
import { ProductionFacilitySelect } from '../../../../common/components/ProductionFacilitySelect';
import { AddressSectionSchema } from '../../../../productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { Order } from '../../../../common/context/order/dtos/Order';
import { OrderCustomerRole } from '../../../../common/context/order/dtos/OrderCustomerRole';
import { shuttleData, sortByZonesAndRegions } from './splitOrderHelper';
import { Shuttle, useShuttleState } from 'common/customComponent/select-many-shuttle';
import 'common/customComponent/select-many-shuttle/styles/shuttle.css';
import { ShuttleState } from 'common/customComponent/select-many-shuttle/components/Shuttle/hooks/useShuttleState';

export type SplitOrder = {
  orderDate: Date;
  splitOrderDate: Date;
  productionPlantId?: string;
  pickupAddress?: AddressSectionSchema;
  submitter: string;
  selectedDestination: Set<{ key: string; value: string }>;
  selectedSource: Set<{ key: string; value: string }>;
  consignor: boolean;
  shareAfterType: ShareType;
};

export enum ShareType {
  ZONEANDREGION = 'zoneAndRegion',
  REGION = 'region',
  VARIANT = 'variant'
}

interface SplitOrderContent {
  order: Order;
}

export const updateData = (
  state: ShuttleState,
  action: {
    type: string;
    data: Array<{ key: string; value: string }>;
  }
): ShuttleState => {
  action.type === 'source' ? (state.source = action.data) : (state.target = action.data);
  return { ...state };
};
export const SplitOrderContent = <T extends FieldValues>({ order }: SplitOrderContent): ReactElement => {
  const { t } = useTranslation('', { keyPrefix: 'splitOrder' });
  const { t: oc } = useTranslation('orderCreate');
  const shareAfterOptions = Object.values(ShareType).filter(
    (item) => item !== ShareType.VARIANT || (item === ShareType.VARIANT && order.constraints?.splittableByVariant)
  );
  const [differentConsignor, setDifferentConsignor] = useState(false);
  const { setValue, control, formState, clearErrors } = useFormContext<SplitOrder>();
  const [pickupAddress, submitter, splitOrderDate, selectedDestination, shareAfterType] = useWatch({
    control,
    name: ['pickupAddress', 'submitter', 'splitOrderDate', 'selectedDestination', 'shareAfterType']
  });
  const { fieldState, field: dateField } = useController<ObjectContaining<TimeCitySchema>, PathsEndingWith<'date'>>({ name: `${name}.date` });
  const pickup = !!pickupAddress;
  const submitterCheckboxConfig = {
    name: 'pickupAddress.toggle',
    label: `${t('pickupDesired')}`,
    value: pickup,
    onChange: () => {
      if (pickup) {
        setValue('pickupAddress', undefined, { shouldTouch: true });
      } else {
        setValue(
          'pickupAddress',
          {
            companyName: '',
            street: '',
            streetNumber: '',
            postCode: '',
            city: '',
            country: '',
            further: ''
          },
          { shouldTouch: true }
        );
        setValue('productionPlantId', '');
      }
    }
  };
  useEffect(() => {
    clearErrors(['pickupAddress', 'submitter', 'productionPlantId', 'selectedSource', 'selectedDestination']);
  }, []);
  useEffect(() => {
    setValue('orderDate', order.orderDetail?.date || new Date());
    setValue('splitOrderDate', order.orderDetail?.date || new Date());
    setValue('submitter', order.parties.find((p) => p.role === OrderCustomerRole.Submitter)?.customerId || '');
    setValue('consignor', false);
    setValue('shareAfterType', ShareType.ZONEANDREGION);
    updateData(shuttle.shuttleState, { type: 'source', data: shuttleData(ShareType.ZONEANDREGION, order) || [] });
  }, [order]);

  const shuttle = useShuttleState({
    source: shuttleData(shareAfterType, order) || [],
    target: []
  });

  useEffect(() => {
    setValue('selectedDestination', new Set(shuttle.shuttleState.target));
    setValue('selectedSource', new Set(shuttle.shuttleState.source));
  }, [shuttle.shuttleState]);

  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const datePickerClickHandler = () => {
    datePickerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <FormRow mode="two-col" className={styles.dividerPosition}>
        <OrderStepSection headline={t('originalOrder')}>
          <div className={styles.shareAfter}>
            <AMDHLSelectSingle
              name={'shareAfter'}
              data-testid={'share-after'}
              placeholder={t('shareAfter')}
              items={shareAfterOptions}
              valueToString={(item) => t(item)}
              value={shareAfterType}
              onChange={(item) => {
                /* istanbul ignore next */
                setValue('shareAfterType', (item as ShareType) ?? 'zoneAndRegion');
                setValue('selectedDestination', new Set());
                updateData(shuttle.shuttleState, { type: 'target', data: [] });
                updateData(shuttle.shuttleState, { type: 'source', data: shuttleData(item as ShareType, order) || [] });
                setValue('selectedSource', new Set(shuttleData(item as ShareType, order) || []));
              }}
            />
          </div>
        </OrderStepSection>
        <div className={styles.headerDivider}></div>
        <div className={styles.newOrder}>
          <OrderStepSection headline={t('newOrder')} />
        </div>
      </FormRow>
      <Shuttle {...shuttle}>
        <Shuttle.Container
          name={'source'}
          headline1={shareAfterType === 'variant' ? t('designation') : t('line')}
          headline2={t('numberOfPallets')}
          error={Boolean(formState.errors.selectedSource)}
        >
          {({ source, selected }, getItemProps) =>
            sortByZonesAndRegions(shareAfterType, source).map((item, index) => (
              <Shuttle.Item {...getItemProps(index)} key={item.key} value={item.value} selected={selected.source.has(index)}>
                <FormRow mode="two-col" style={{ margin: '8px 0' }}>
                  <span>{item.key}</span>
                  <span style={{ textAlign: 'right' }}>{item.value}</span>
                </FormRow>
              </Shuttle.Item>
            ))
          }
        </Shuttle.Container>
        <Shuttle.Controls />
        <Shuttle.Container
          name={'target'}
          headline1={shareAfterType === 'variant' ? t('designation') : t('line')}
          headline2={t('numberOfPallets')}
          error={Boolean(formState.errors.selectedDestination)}
        >
          {({ target, selected }, getItemProps) =>
            sortByZonesAndRegions(shareAfterType, target).map((item, index) => (
              <Shuttle.Item {...getItemProps(index)} key={item.key} value={item.value} selected={selected.target.has(index)}>
                <FormRow mode="two-col" style={{ margin: '8px 0' }}>
                  <span>{item.key}</span>
                  <span style={{ textAlign: 'right' }}>{item.value}</span>
                </FormRow>
              </Shuttle.Item>
            ))
          }
        </Shuttle.Container>
      </Shuttle>
      {(formState.errors.selectedDestination || formState.errors.selectedSource) && (
        <div data-testid="shuttle-error" className="fieldNote error" style={{ margin: '-10px 0 0 0' }}>
          {t('errors.shuttleError')}
        </div>
      )}
      <FormRow mode="two-col" className={styles.dividerPosition}>
        <OrderStepSection headline={t('deliveryDate')}>
          <div className={styles.shareAfter}>
            <DateInput name={`orderDate`} label={`${t('date')}`} disabled={true} error={fieldState.error ? 'invalid' : undefined} />
          </div>
        </OrderStepSection>
        <div className={styles.headerDivider}></div>
        <div className={styles.newOrder}>
          <OrderStepSection headline={t('deliveryDate')} />
          <button className={styles.datePickerScrollBtn} onClick={datePickerClickHandler}>
            <DateInput
              name={`splitOrderDate`}
              label={`${t('date')}`}
              disabled={false}
              error={fieldState.error ? 'invalid' : undefined}
              rules={{
                required: false
              }}
            />
          </button>
          <div ref={datePickerRef}></div>
          {!differentConsignor && (
            <DHLButton
              name={'addConsignor'}
              label={t('consignor')}
              type={'ghost'}
              iconPosition={'icon-first'}
              icon={'plus'}
              disabled={!order?.constraints?.splittableWithSubmitter}
              onClick={() => {
                setDifferentConsignor(true);
                setValue('consignor', true);
              }}
              size="sm"
              className={styles.addDifferentConsignor}
            />
          )}
          {differentConsignor && (
            <>
              <FormRow mode={'two-col'} style={{ gap: 0 }}>
                <OrderStepSection headline={t('consignor')} />
                <DHLButton
                  name={'removeConsignor'}
                  label={'removeConsignor'}
                  iconPosition={'icon'}
                  icon={'delete'}
                  onClick={() => {
                    setDifferentConsignor(false);
                    setValue('consignor', false);
                  }}
                  className={styles.removeDifferentConsignor}
                />
              </FormRow>
              <div style={{ marginTop: '-30px' }}>
                <CustomerSection<SplitOrder>
                  inputName="submitter"
                  customerRole={OrderSearchKey.Submitter}
                  errorMessage={oc('error.customerIdUnknown')}
                  infoPosition={'one-col'}
                  checkboxConfig={submitterCheckboxConfig}
                />
              </div>
              {pickup && (
                <AddressSection<SplitOrder> name="pickupAddress" addressAlignment="one-col" fallbackCustomerId={submitter} splitOrder={true} />
              )}
              {!pickup && (
                <>
                  <OrderStepSection headline={t('city')} />
                  <ProductionFacilitySelect
                    name={`productionPlantId`}
                    rules={{ required: false }}
                    selectedDate={splitOrderDate}
                    useDepot={order.productGroup === 'PRESS_DISTRIBUTION'}
                    useSLN={order.productGroup === 'PRESS_DISTRIBUTION'}
                  />
                </>
              )}
            </>
          )}
        </div>
      </FormRow>
    </>
  );
};
