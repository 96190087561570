import React, { useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useAlerts } from 'common/AlertContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import orderClasses from 'common/styles/order.module.css';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';

import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import iconDataEdit from 'assets/icon-data-edit.svg';
import { InternationalClusterOrderCreate, translateInternationalCluster } from '../../schema/internationalClusterSchema';
import { InternationalClusterOverview } from '../../components/InternationalClusterOverview/InternationalClusterOverview';
import { changeInternationalOrder, createInternationalOrder } from 'order/common/services/OrderService';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';
import { fetchErrorCode } from '../../../../common/utils/errorCodeHelper';

export enum InternationalClusterReqURL {
  DIALOGPOST_INTERNATIONAL = 'dialogpost-international',
  PRESS_INTERNATIONAL = 'pressdistribution-international',
  BRIEF_INTERNATIONAL = 'letter-international',
  BRIEF_DMC_INTERNATIONAL = 'dmc-letter-international'
}

export const InternationalClusterStep4: VFC = observer(() => {
  const { t } = useTranslation('orderCreate');
  const { watch, handleSubmit } = useFormContext<InternationalClusterOrderCreate>();
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { hasPermission } = useAuthContext();
  const values = watch();
  const { reset: resetSearchContext, triggerAlert, updateMyOrders, myOrders } = useSearchContext();
  const history = useHistory();
  const { reset: resetOrderContext, isDirty, setDirty, orderMode, order, orderCategoryProductKey } = useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const packagingEditable = order?.constraints?.packageSectionEditable;

  const createRequest = async (translatedOrder: any) => {
    switch (values?.productGroup) {
      case ProductGroup.DIALOGPOST_INTERNATIONAL:
        const dpData = await createInternationalOrder(translatedOrder, InternationalClusterReqURL.DIALOGPOST_INTERNATIONAL);
        return dpData.data;
      case ProductGroup.PRESS_INTERNATIONAL:
        const pressData = await createInternationalOrder(translatedOrder, InternationalClusterReqURL.PRESS_INTERNATIONAL);
        return pressData.data;
      case ProductGroup.BRIEF_INTERNATIONAL:
        const briefData = await createInternationalOrder(translatedOrder, InternationalClusterReqURL.BRIEF_INTERNATIONAL);
        return briefData.data;
      default:
        return;
    }
  };

  const changeRequest = async (translatedOrder: any) => {
    switch (values?.productGroup) {
      case ProductGroup.DIALOGPOST_INTERNATIONAL:
        const dpData = await changeInternationalOrder(translatedOrder, InternationalClusterReqURL.DIALOGPOST_INTERNATIONAL);
        return dpData.data;
      case ProductGroup.PRESS_INTERNATIONAL:
        const pressData = await changeInternationalOrder(translatedOrder, InternationalClusterReqURL.PRESS_INTERNATIONAL);
        return pressData.data;
      case ProductGroup.BRIEF_INTERNATIONAL:
        const briefData = await changeInternationalOrder(translatedOrder, InternationalClusterReqURL.BRIEF_INTERNATIONAL);
        return briefData.data;
      case ProductGroup.BRIEF_DMC_INTERNATIONAL:
        const briefDMCData = await changeInternationalOrder(translatedOrder, InternationalClusterReqURL.BRIEF_DMC_INTERNATIONAL);
        return briefDMCData.data;
      default:
        return;
    }
  };

  return (
    <>
      <main className={orderClasses.formContent}>
        <OrderStepHeadline icon={iconDataEdit} alt={'data Edit Icon'}>
          {t('steps.overview')}
        </OrderStepHeadline>
        <InternationalClusterOverview value={values} />
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
            dataTestId="btnBack"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant={activeSpinner ? 'default' : 'primary'}
          disabled={!hasPermission(UserRights.OrderEdit) || activeSpinner}
          dataTestId="btnSubmit"
          onClick={async () => {
            await handleSubmit(async (values: InternationalClusterOrderCreate) => {
              try {
                deleteAlerts();
                let dpResponse: any;
                if (orderMode === OrderMode.CHANGE) {
                  setActiveSpinner(true);
                  values.packaging.packagingEditable = packagingEditable;
                  const translatedOrder = translateInternationalCluster(values, true, order);
                  const res = await changeRequest(translatedOrder);
                  dpResponse = res;
                } else {
                  setActiveSpinner(true);
                  const translatedOrder = translateInternationalCluster(values, false, order);
                  const res = await createRequest(translatedOrder);
                  dpResponse = res;
                }
                if (dpResponse?.resultStatus !== 'ERROR') {
                  setActiveSpinner(false);
                  resetSearchContext();

                  // For persisting my orders
                  const myOrderData: MyOrder = {
                    id: dpResponse?.orderId,
                    myOrderNumbers: [dpResponse?.orderId],
                    orderCategory: values?.orderCategory,
                    orderCategoryProductKey: order?.orderCategoryProductKey || orderCategoryProductKey,
                    orderLabel: values?.orderLabel,
                    creationDate: new Date()?.getTime(),
                    sortingDate: new Date()?.getTime()
                  };

                  const found = myOrders
                    ?.map((myOrder) => myOrder?.myOrderNumbers)
                    ?.filter((myOrderArr) => myOrderArr?.length === 1)
                    ?.find((myOrderNums) => (dpResponse?.orderId ? myOrderNums?.includes(dpResponse.orderId) : undefined));
                  if (!found?.length) {
                    updateMyOrders(myOrderData, 'ADD');
                  } else {
                    updateMyOrders(myOrderData, 'UPDATE');
                  }

                  resetOrderContext();
                  setDirty(false);
                  triggerAlert(dpResponse);
                  history.push(AppPaths.orderSearchPath);
                } else {
                  setActiveSpinner(false);
                  dpResponse?.amErrors?.map((error: { code: string; errorCategory: string; errorDescription: string }) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory} ${error.code}!`,
                      description: error.errorDescription
                    });
                  });
                }
              } catch (e: any) {
                console.error(e);
                setActiveSpinner(false);
                addAlert({
                  type: AlertTypes.Error,
                  title: orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.title') : i18n.t('responseErrors.create.title'),
                  description:
                    (orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.description') : i18n.t('responseErrors.create.description')) +
                    fetchErrorCode(e?.response)
                });
              } finally {
                nextStep();
              }
            }, console.error)();
          }}
          type="button"
          label={orderMode === OrderMode.CHANGE ? t('buttons.createOrderConfirm') : t('buttons.submitOrder')}
          activeSpinner={activeSpinner}
          style={{ minWidth: '275px' }}
        />
      </footer>
    </>
  );
});
