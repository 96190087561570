import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components/Button';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useFormContext, useWatch } from 'react-hook-form';
import { BackToSearchButton } from 'order/common/components/BackToSearch/BackToSearchButton';
import orderClasses from 'common/styles/order.module.css';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import iconAccountDelivery from 'assets/icon-account-delivery.svg';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import { InternationalBaseProducts } from '../../components/BaseProducts/InternationalBaseProducts';
import { TransportServices } from '../../components/TransportServices';
import { useBaseProducts } from '../../../../common/hooks/useBaseProducts';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { PackagingSection } from 'order/productGroups/common/components/molecule/PackagingSection';

import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { InternationalClusterOrderCreate, validateInternationalClusterOrderCreate } from '../../schema/internationalClusterSchema';
import { AdditionallyPaidItems } from '../../components/AdditionallyPaidItems';
import { JobTitleSection } from 'order/productGroups/common/components/molecule/JobTitleSection';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';
import { AddressSection } from 'order/productGroups/common/components/molecule/AddressSection';
import { ContactSection } from 'order/productGroups/common/components/molecule/ContactSection';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useBoolean } from 'common/hooks/useBoolean';
import { TimeCitySection } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { Accordion } from 'common/components/Accordion';
import { AccordionItemOtherPartners } from 'order/orderChange/common/components/AccordionItems/OtherPartners';
import { InternationalDestinationSection } from '../../components/InternationalDestinationSection/InternationalDestinationSection';
import { PressBaseProductVariant } from '../../../press/schema/pressSchema';
import { LetterBaseProduct } from '../../../../../generated';

export const hierarchyProductGroup = {
  BRIEF_INTERNATIONAL: 'Brief',
  PUB_INTERNATIONAL: 'Presse und Buch',
  IP_INTERNATIONAL: 'Infopost'
};

const inputDisabled = false;
export const InternationalClusterStep2: React.FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { order, isDirty, meta, orderCategory, orderMode, setDirty } = useOrderContext();
  const { control, setError, setValue, trigger, getValues } = useFormContext<InternationalClusterOrderCreate>();
  const currentOriginator = useRef<string | undefined>(undefined);
  const [
    productGroup,
    internationalBaseProduct,
    orderLabel,
    takeOriginatorAsSubmitter,
    originatorUcp,
    submitterUcp,
    pickupAddress,
    packagingType,
    additionallyPaidItems,
    selectedDate
  ] = useWatch({
    control,
    name: [
      'productGroup',
      'internationalBaseProduct',
      'orderLabel',
      'takeOriginatorAsSubmitter',
      'originator',
      'submitter',
      'pickupAddress',
      'packaging.type',
      'additionallyPaidItems',
      'details.date'
    ]
  });
  const pickup = !!pickupAddress;

  useEffect(() => {
    if (currentOriginator.current !== originatorUcp) {
      currentOriginator.current = originatorUcp;

      if (takeOriginatorAsSubmitter) {
        setValue('submitter', originatorUcp);
      }
    }
  }, [originatorUcp, takeOriginatorAsSubmitter]);

  const checked = takeOriginatorAsSubmitter;
  const [einliefererUbernehmen, setEinliefererUbernehmen] = useBoolean(takeOriginatorAsSubmitter);

  const originatorCheckboxConfig = {
    controlName: 'takeOriginatorAsSubmitter',
    name: 'einliefererUbernehmen',
    label: `${oc('alsoAcceptAsConsignor')}`,
    value: einliefererUbernehmen || checked,
    onChange: () => {
      setEinliefererUbernehmen.toggle();
      setValue('submitter', einliefererUbernehmen ? '' : getValues('originator'), { shouldValidate: true });
      setValue('takeOriginatorAsSubmitter', !einliefererUbernehmen);
    }
  };
  const submitterCheckboxConfig = {
    name: 'pickupAddress.toggle',
    label: `${oc('pickupDesired')}`,
    value: pickup,
    onChange: () => {
      if (pickup) {
        setValue('pickupAddress', undefined, { shouldTouch: true });
      } else {
        setValue(
          'pickupAddress',
          {
            companyName: '',
            street: '',
            streetNumber: '',
            postCode: '',
            city: '',
            country: '',
            further: ''
          },
          { shouldTouch: true }
        );
        setValue('details.productionPlantId', '');
      }
    }
  };

  const transportServiceProducts = useBaseProducts(
    'BRIEF',
    undefined,
    'POSTING',
    undefined,
    'International',
    hierarchyProductGroup[productGroup as keyof typeof hierarchyProductGroup]
  );

  useEffect(() => {
    setDirty(true);
  }, []);
  const isTTRDisable = packagingType === 'TRT';
  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconAccountDelivery} alt="Icon Auftragsinhalt">
              {oc('title')}
            </OrderStepHeadline>

            <InternationalBaseProducts
              name="internationalBaseProduct"
              internationalBaseProduct={internationalBaseProduct}
              disabled={order?.includesDestinations && orderMode === OrderMode.CHANGE}
              productGroup={productGroup}
            />

            <TransportServices
              disabled={order?.includesDestinations && orderMode === OrderMode.CHANGE}
              items={transportServiceProducts}
              selectedIBP={internationalBaseProduct}
              productGroup={productGroup}
              name={'transportServices'}
              selectedDate={selectedDate}
            />

            {orderCategory === OrderCategory.IP_INTERNATIONAL && (
              <AdditionallyPaidItems name="additionallyPaidItems" defaultValue={additionallyPaidItems} />
            )}

            <OrderStepSection headline={oc('furtherProductCharacteristics')}>
              {[LineItemVariant.GOGREEN].map((variant) => (
                <DHLCheckbox
                  key={variant}
                  name={`subProducts.${variant}`}
                  label={tCatalogValues(`lineItemVariant.display.${variant}`)}
                  disabled={true}
                  value={true}
                />
              ))}
            </OrderStepSection>

            <JobTitleSection<InternationalClusterOrderCreate>
              // hasTargetGroup={productGroup === ProductGroup.I || order?.productGroup === ProductGroup.DIALOGPOST}
              disabled={order?.pendingIds || inputDisabled}
              orderLabel={orderLabel}
            />
            <CustomerSection<InternationalClusterOrderCreate>
              headline={oc('sender')}
              inputName="originator"
              disabled={order?.pendingIds || orderMode === OrderMode.CHANGE ? true : inputDisabled}
              customerRole={OrderSearchKey.Originator}
              errorMessage={t('error.customerIdUnknown')}
              checkboxConfig={originatorCheckboxConfig}
            />
            <CustomerSection<InternationalClusterOrderCreate>
              headline={oc('consignor')}
              inputName="submitter"
              disabled={order?.pendingIds || inputDisabled || takeOriginatorAsSubmitter}
              customerRole={OrderSearchKey.Submitter}
              errorMessage={t('error.customerIdUnknown')}
              checkboxConfig={submitterCheckboxConfig}
            />
            {pickup && (
              <AddressSection<InternationalClusterOrderCreate>
                name="pickupAddress"
                fallbackCustomerId={takeOriginatorAsSubmitter ? originatorUcp : submitterUcp}
              />
            )}
            <ContactSection<InternationalClusterOrderCreate> name="contactForQuestions" disabled={order?.pendingIds || inputDisabled} />
            <TimeCitySection<InternationalClusterOrderCreate>
              name="details"
              disabled={order?.pendingIds || inputDisabled}
              pickup={pickup}
              selectedProductGroup={ProductGroup.DIALOGPOST_INTERNATIONAL}
            />

            <PackagingSection<InternationalClusterOrderCreate>
              name={'packaging'}
              disabled={order?.constraints?.packageSectionEditable}
              internationalPackagingSum={meta?.internationalPackagingSum || 0}
              isTTRDisable={isTTRDisable}
            />
            {packagingType === 'PAL' && (
              <InternationalDestinationSection<InternationalClusterOrderCreate>
                name={'destinations'}
                disabled={order?.constraints?.packageSectionEditable}
                showEcomony={productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL || productGroup === ProductGroup.PRESS_INTERNATIONAL}
                showMixed={productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL || productGroup === ProductGroup.PRESS_INTERNATIONAL}
                totalNetWeight={
                  getValues('transportServices')?.reduce((accumulator: number, lbp: LetterBaseProduct) => {
                    return accumulator + (lbp?.baseProduct.grossWeight || 0);
                  }, 0) || 0
                }
              />
            )}
            <OrderStepSection>
              <Accordion>
                {(register) => <AccordionItemOtherPartners name={'otherPartners'} id={1} register={register} disabled={inputDisabled} />}
              </Accordion>
            </OrderStepSection>
          </main>
          <MarginalColumn orderCategory={order?.orderCategory} />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant="primary"
          dataTestId="btnSubmit"
          disabled={order?.pendingIds}
          onClick={async () => {
            const fieldName: any = [
              'internationalBaseProduct',
              'transportServices',
              'details.date',
              'details.productionPlantId',
              'originator',
              'submitter',
              'pickupAddress'
            ];
            pickup && fieldName.push('details.time');
            const isContentValid = await trigger(fieldName);
            const areDependentFieldsValid = validateInternationalClusterOrderCreate(getValues(), setError, 2);
            if (isContentValid && areDependentFieldsValid) {
              nextStep();
            }
          }}
          label={t('buttons.continue')}
          type="button"
        />
      </footer>
    </>
  );
};
