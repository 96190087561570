import classNames from 'classnames';
import React, { useState } from 'react';
import classes from './tooltip.module.css';

export type TooltipProps = {
  // description: string | undefined;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  placement?: 'top' | 'right' | 'left' | 'bottom';
  content?: string;
};

export const Tooltip = ({ placement = 'top', ...props }: TooltipProps): JSX.Element => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={classNames(classes.tooltipWrapper, props.className)}
      data-testid="tooltipComponent"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {props.children}
      {active && (
        <div className={classNames(classes.tooltipTip, classes[placement])} data-testid="tooltipContent">
          {props.content}
        </div>
      )}
    </div>
  );
};
