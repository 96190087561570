import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { Occurrence } from 'common/dtos/Occurrence';

import styles from 'order/productGroups/additionalOrders/workFlowSteps/step3/components/table/table-container.module.css';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomPlanWithFrankingIdsRendererCell = (props: Props): JSX.Element => {
  const { orderReferences } = useOrderContext();
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const [containsIds, setContainsIds] = useState(false);

  useLayoutEffect(() => {
    const ref = orderReferences?.find((o) => o.orderId === props.row.data?.orderNumber);

    if (ref) {
      const contains = ref.includesDestinations || ref.containsIds === Occurrence.ALL || ref.containsIds === Occurrence.SOME;
      setContainsIds(contains);
    }
  }, [orderReferences]);

  return (
    <div className={styles.flex}>
      <div className={cn(styles.icon, { [styles.grey]: !containsIds, [styles.green]: containsIds })}>
        <span className={styles.iconWrapper}>
          {!containsIds ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon
                fill="#878787"
                fillRule="evenodd"
                points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon fill="#878787" fillRule="evenodd" points="24 5.2 11.2 18 8 21.2 4.8 18 0 13.2 3.2 10 8 14.8 20.8 2 24 5.2" />
            </svg>
          )}
        </span>
      </div>
      {containsIds ? t('cell.frankingIdsIncluded') : t('cell.noFrankingIdsIncluded')}
    </div>
  );
};

export const customPlanWithFrankingIdsRendererCellRenderer = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomPlanWithFrankingIdsRendererCell value={value} row={row} column={column} />;
};
