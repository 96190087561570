import React from 'react';

import { TableHeadCell } from './TableHeadCell';
import { ColumnConfig } from '../dtos/ColumnConfig';
import { ColumnType } from '../dtos/ColumnType';
import { Filter } from '../dtos/Filter';
import { SortOrder } from '../dtos/SortOrder';
import { Sort } from '../dtos/Sort';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';

interface Props {
  columns: ColumnConfig[];
  filter?: Filter;
  sort?: Sort;
  t: (value: string) => string;
  language?: string;
  onFilterChange?: (name: string | null, value: string | null, type?: OrderSearchType) => void;
  onMoveChange?: (name: string, direction: 'left' | 'right') => void;
  onSetTableConfiguration?: (config: Record<string, boolean>, columnHistory?: PersistColumn) => void;
  onSortChange?: (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => void;
  onResetSortAndFilters?: () => void;
  withTableOptions?: boolean;
  contextMenu?: boolean;
  setMenuVisible?: (menuVisible: boolean) => void;
  saveColumnWithMove?: () => void;
  searchType?: OrderSearchType;
  setColumnFilter?: (filter: Filter) => void;
  columnFilter?: Filter;
  rows?: OrderTreeItem<OrderReplyTDO>[];
  columnSorting?: Sort;
  setColumnSorting?: (sort: Sort) => void;
  triggerLastSearch?: () => void;
}

export const TableHeadRow = (props: Props): JSX.Element => {
  let visibleColumns = props.columns;

  if (props.withTableOptions) {
    visibleColumns = props.columns.slice(0, -1).filter((c) => !c.hidden);
  }
  return (
    <>
      {visibleColumns.map((config: ColumnConfig, key) => (
        <TableHeadCell
          {...props}
          sort={props.sort}
          filter={props.filter}
          sortOrder={props.sort?.order}
          key={key}
          {...config}
          contextMenu={props.contextMenu}
          setMenuVisible={props.setMenuVisible}
          saveColumnWithMove={props.saveColumnWithMove}
          setColumnFilter={props.setColumnFilter}
          columnFilter={props.columnFilter}
          rows={props.rows}
          columnSorting={props.columnSorting}
          setColumnSorting={props.setColumnSorting}
          triggerLastSearch={props.triggerLastSearch}
        />
      ))}
    </>
  );
};
