import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import styles from 'order/productGroups/dialogMarketing/workFlowSteps/Step2/DialogMarketingStep2.module.css';
import React, { MouseEventHandler, useEffect, useState, VFC } from 'react';
import classes from './other-partners.module.css';
import { CustomerInfoWithAddressTO } from '../../../../../../generated';

export type DeletePartnerButtonProps = {
  fields: unknown[];
  customerId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: MouseEventHandler<any>;
  disabled?: boolean;
  id: number;
  roles: string[];
};

export const AddPartnerButton: VFC<DeletePartnerButtonProps> = ({ id, fields, customerId, onClick, disabled, roles }) => {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(customerId);
  }, [customerId]);

  if (id === roles?.length - 1 || !customerInfo) {
    return <></>;
  }
  return (
    <>
      {customerId && (
        <DHLButton
          name="addPartnerRole"
          label={'Partner hinzufügen'}
          type={'ghost'}
          iconPosition={'icon-first'}
          icon={'plus'}
          size="xs"
          onClick={onClick}
          className={classNames(styles.addPaddingBottom, classes.addButton)}
          disabled={disabled}
        />
      )}
    </>
  );
};
