import { PayerInfoWithAddressTO } from 'generated';
import React, { createContext, Dispatch, ReactElement, SetStateAction, useContext, useState, VFC } from 'react';

export type UsePayerReturn = [PayerInfoWithAddressTO | undefined, Dispatch<SetStateAction<PayerInfoWithAddressTO | undefined>>];

const Context = createContext<UsePayerReturn>([{}, () => {}]);

export const PayerProvider: VFC<{ children?: ReactElement }> = ({ children }) => {
  const reducerReturn = useState<PayerInfoWithAddressTO | undefined>({});
  return <Context.Provider value={reducerReturn}>{children}</Context.Provider>;
};

export const usePayer = (): UsePayerReturn => useContext(Context);
