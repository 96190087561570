import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { AsideInfoBox } from 'order/productGroups/common/components/atom/AsideInfoBox';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { usePaymentBankInfo } from 'order/productGroups/common/services/usePaymentBankInfo';
import React, { FC, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './MarginalColumn.module.css';
import { ProductInfoTableWrapper } from '../ProductInfoTable/Wrappers/ProductInfoTableWrapper';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { CustomerInfoWithAddressTO } from '../../../../../../generated';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';

export interface AsideProps {
  className?: string;
  editing?: boolean;
  orderCategory?: OrderCategory;
}

export const MarginalColumn: FC<AsideProps> = observer(({ className, editing, orderCategory }) => {
  const { control } = useOrderTransferForm();
  const { meta, order } = useOrderContext();
  const [productGroup, payer, submitter, originator, contractor] = useWatch({
    control,
    name: ['productGroup', 'payer', 'submitter', 'originator', 'contractor']
  });
  const [originatorInfo, setOriginatorInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const [submitterInfo, setSubmitterInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);

  const fetchCustomerInfo = async (customerId?: string, cb?: (data: any) => void) => {
    const filteredParty = order?.parties?.filter((p) => p.customerId == customerId);
    const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
      name: filteredParty?.[0]?.name
    };
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        cb?.(data);
      } else if (transformedCustomerData.name) {
        cb?.(transformedCustomerData);
      }
    } else {
      cb?.(undefined);
    }
  };
  useEffect(() => {
    fetchCustomerInfo(originator, setOriginatorInfo);
    fetchCustomerInfo(submitter, setSubmitterInfo);
  }, [originator, submitter]);
  const { paymentBankInfo: contractorInfoForPartialService } = usePaymentBankInfo(contractor, new Date(order?.orderDetail?.date || ''));
  const { paymentBankInfo: payerInfo } = usePaymentBankInfo(payer, new Date(order?.orderDetail?.date || ''));

  const { t } = useTranslation('orderCreate');
  return (
    <aside className={classNames(styles.aside, className)}>
      <>
        <section>
          <AsideInfoBox title={t('aside.orderInfo')}>
            <ProductInfoTableWrapper editing={editing} />
          </AsideInfoBox>
        </section>
        <section>
          {productGroup === ProductGroup.PARTIAL_SERVICE_LETTER && !!contractorInfoForPartialService?.companyName && (
            <AsideInfoBox title={t('aside.contractor')} className="pt-2">
              {contractorInfoForPartialService.companyName}
            </AsideInfoBox>
          )}
          {productGroup === ProductGroup.COLLECTION && !!payerInfo?.companyName && (
            <AsideInfoBox title={`${t('step2Form.customer')}`} className="pt-2">
              {payerInfo.companyName}
            </AsideInfoBox>
          )}
          {!!originatorInfo?.name && (
            <AsideInfoBox title="Absender" className="pt-2">
              {originatorInfo?.name}
            </AsideInfoBox>
          )}
          {!!submitterInfo?.name && (
            <AsideInfoBox title="Einlieferer" className="pt-2">
              {submitterInfo?.name}
            </AsideInfoBox>
          )}
          {productGroup !== ProductGroup.PRESS_DISTRIBUTION &&
            productGroup !== ProductGroup.COLLECTION &&
            productGroup !== ProductGroup.PARTIAL_SERVICE_LETTER &&
            !!payerInfo?.companyName && (
              <AsideInfoBox title={`${t('aside.payer')}`} className="pt-2">
                {payerInfo.companyName}
              </AsideInfoBox>
            )}
        </section>
      </>
      {!!meta?.journalPaymentPayer?.name && productGroup === ProductGroup.PRESS_DISTRIBUTION ? (
        <AsideInfoBox title={t('aside.payer')} className="pt-2">
          {meta?.journalPaymentPayer?.name}
        </AsideInfoBox>
      ) : (
        ''
      )}
    </aside>
  );
});
