// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space-error-alters_alertWrapper__9zpPr {
    margin: -10px auto 20px auto;
    width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Alerts/space-error-alters.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,UAAU;AACd","sourcesContent":[".alertWrapper {\n    margin: -10px auto 20px auto;\n    width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertWrapper": `space-error-alters_alertWrapper__9zpPr`
};
export default ___CSS_LOADER_EXPORT___;
