import React, { useState, useCallback } from 'react';
import { DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import { useAlerts } from 'common/AlertContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { Order } from 'order/common/context/order/dtos/Order';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OrderMerge } from '../../OrderMerge/OrderMerge';
import styles from './OrderMergeModal.module.css';
import { Button } from 'common/components/Button';
import { mergeOrder } from 'order/common/services/OrderService';
import { DuplicateOrders, MergeOrderRep } from '../../schema/OrderMergeSchema';
import { getOrder } from 'order/common/context/order/utils/getOrder';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { LoadingState } from 'order/common/context/search/dtos/LoadingState';
import { getOrderPrice } from 'order/common/context/order/utils/getOrderPrice';
import { OrderPriceResponse } from 'generated';

interface MergeOrderModal {
  show: boolean;
  onCancel: () => void;
  order: Order;
}

export const OrderMergeModal = (props: MergeOrderModal) => {
  const { t } = useTranslation('', { keyPrefix: 'orderMerge' });
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const {
    setDirty,
    meta,
    setOrder,
    setProductGroup,
    setOrderId,
    setLoadingState,
    setOrderCategoryProductKey,
    setOrderCategory,
    upsertMetaData,
    order: rawOrder,
    setOrderPrice: setOP
  } = useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const backWrapperRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.querySelector('button')?.focus();
      }, 0);
    }
  }, []);

  const mergeOrderPayloadConverter = (duplicateOrders: DuplicateOrders[]): MergeOrderRep => {
    return {
      orderLastChangedOn: props.order?.lastChangedOn,
      duplicateOrders: duplicateOrders,
      orderId: props.order?.orderId
    };
  };

  const loadNewOrder = async (id: string) => {
    const newOrder = await getOrder<OrderResponse>(id, { includeDst: true, includePrice: true });
    setLoadingState(LoadingState.LOADING);
    if (newOrder) {
      setOrderId(id);
      setOrder(newOrder);
      setProductGroup(newOrder.productGroup);
      setOrderCategory(newOrder.orderCategory);
      setOrderCategoryProductKey(newOrder.orderCategoryProductKey);
      setLoadingState(LoadingState.LOADED);
    }
  };

  const loadOrderPrice = async () => {
    if (!rawOrder?.orderId || !rawOrder?.orderPriceKey) {
      return;
    }

    const orderPriceReq = {
      orderId: rawOrder.orderId,
      orderPriceKey: rawOrder.orderPriceKey,
      orderVersion: meta?.selectedOrderVersion?.orderVersion || rawOrder?.orderVersion
    };

    const data = (await getOrderPrice(orderPriceReq)) as OrderPriceResponse;

    if (data) {
      if (!meta?.selectedOrderVersion?.orderVersion) {
        setOP(data?.orderPriceRep);
      }
    }
  };

  return (
    <DHLModal
      name="confirm"
      title={t`orderMerge`}
      content={<OrderMerge />}
      className={styles.mergeOrderModal}
      actionbar={
        <>
          <div ref={backWrapperRef}>
            <Button
              name={'cancel-button'}
              label={t(`mergeOrderCancelButton`)}
              onClick={() => {
                props.onCancel();
              }}
              type="button"
              dataTestId="cancel-merge-button"
            />
          </div>
          <Button
            variant={activeSpinner ? 'default' : 'primary'}
            dataTestId="mergeOrder"
            onClick={async () => {
              try {
                setActiveSpinner(true);
                deleteAlerts();
                const mergeProps = mergeOrderPayloadConverter(meta?.duplicateOrdersForOrderMerge);
                const { data } = await mergeOrder(mergeProps);
                const mergeOrderResponse = data.response;
                setActiveSpinner(false);
                props.onCancel();
                if (mergeOrderResponse?.resultStatus !== 'ERROR') {
                  setDirty(false);
                  addAlert({
                    type: AlertTypes.Success,
                    title: t('alerts.success'),
                    description: mergeOrderResponse.message
                  });
                  const id = props.order.orderId ?? '';
                  await loadNewOrder(id);
                  loadOrderPrice();
                } else {
                  mergeOrderResponse?.amErrors?.forEach((error) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory}!`,
                      description: error.errorDescription
                    });
                  });
                }
              } catch (e) {
                console.error(e);
                setActiveSpinner(false);
                props.onCancel();
                addAlert({
                  type: AlertTypes.Error,
                  title: `ERROR!`,
                  description: t('responseErrors')
                });
              }
            }}
            type="submit"
            label={t('mergeOrderConfirmButton')}
            activeSpinner={activeSpinner}
            style={{ minWidth: '275px' }}
            disabled={meta?.duplicateOrdersForOrderMerge?.length <= 0}
          />
        </>
      }
      show={props.show}
      onClickClose={() => {
        props.onCancel();
      }}
    />
  );
};
