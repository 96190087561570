import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

import { ActiveMultiList } from 'common/components/ActiveMultiList';
import { FormRow } from 'common/components/FormRow';
import { isValidPostCode, ObjectContaining, SFCProps } from 'common/utils/formHelpers';
import i18n from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import CountryList from 'order/productGroups/common/dtos/CountryList.json';
import { AddressSectionSchema } from './addressSectionSchema';
import { CustomerInfoWithAddressTO } from 'generated';
import { translationGE, translationEN } from './addressSectionTranslation';

type AMDHLAddressBlockProps<T extends FieldValues> = SFCProps<T, AddressSectionSchema | undefined> & {
  fallbackCustomerId?: string;
  withProductionPlant?: boolean;
  disabled?: boolean;
  onChange?: (item: any) => void;
  addressAlignment?: 'one-col' | 'two-col';
  splitOrder?: boolean;
};

i18n.addResourceBundle('de', 'addressInput', translationGE);
i18n.addResourceBundle('en', 'addressInput', translationEN);

export const AddressSection = <T extends FieldValues>({
  fallbackCustomerId,
  withProductionPlant,
  name: path,
  disabled,
  ...props
}: AMDHLAddressBlockProps<T>): ReactElement => {
  const name = path as string;
  const { t } = useTranslation('addressInput');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { t: error } = useTranslation('orderCreate', { keyPrefix: 'error' });
  const { setValue, getValues } = useFormContext<ObjectContaining<AddressSectionSchema>>();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const { trigger } = useFormContext();
  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? t(`errors.${fieldState.error.message}`) : undefined;

  const getAllErrorsMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? t(`${fieldState.error.message}`) : undefined;

  const [selectedRegion, setSelectedRegion] = useState<string>(() => getValues(`${name}.postCode`) || '');
  const [selectedCountry, setSelectedCountry] = useState<string>(() => getValues(`${name}.country`) || 'DE');

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      }
    }
  };

  const pickupAddressPostCodeRegex = /^\s*(?!-)(?!.*-.*-)(?!.*\s.*\s)(?=[A-Za-z0-9- ]{3,10}$)[A-Za-z0-9]+(?:[-\s][A-Za-z0-9]*(?!-)\b)?\s*$/;

  useEffect(() => {
    fetchCustomerInfo(fallbackCustomerId);
  }, [fallbackCustomerId]);

  const onReset = () => {
    if (!customerInfo) {
      return;
    }
    /* istanbul ignore next */
    const { postCode = '', city = '', street = '', streetNumber = '', country = '' } = customerInfo.address || {};
    const companyName = customerInfo.name || '';
    setValue(`${name}.companyName`, companyName, { shouldTouch: true });
    setValue(`${name}.postCode`, postCode, { shouldTouch: true });
    setValue(`${name}.city`, city, { shouldTouch: true });
    setValue(`${name}.street`, street, { shouldTouch: true });
    setValue(`${name}.streetNumber`, streetNumber, { shouldTouch: true });
    setValue(`${name}.country`, country || 'DE', { shouldTouch: true });
    setSelectedRegion(postCode?.slice(0, 2));
    setSelectedCountry(country || 'DE');
  };

  return (
    <OrderStepSection headline={oc('addPickupAddress')} sub>
      <FormRow mode="one-col">
        <DHLButton
          name={'vom_einlieferer_ubernehmen'}
          label={oc('sameAsConsignorsAddress')}
          type={'default'}
          onClick={onReset}
          size="sm"
          disabled={disabled}
        />
      </FormRow>
      <FormRow mode={props.addressAlignment ? props.addressAlignment : 'two-col'}>
        {props.splitOrder ? (
          <TextInput
            name={`${name}.companyName`}
            label={oc(`companyName`)}
            className="flex-grow-9"
            rules={{ maxLength: { value: 35, message: 'maxLength35' } }}
            error={getErrorMessage()}
            disabled={disabled}
            onChange={() => trigger(`${name}.companyName`)}
          />
        ) : (
          <TextInput name={`${name}.companyName`} label={oc(`companyName`)} className="flex-grow-9" disabled={disabled} />
        )}
      </FormRow>
      <FormRow mode={props.addressAlignment ? props.addressAlignment : 'two-col'}>
        <TextInput
          name={`${name}.street`}
          label={oc(`street`)}
          className="flex-grow-9"
          rules={{ required: 'required' }}
          error={getErrorMessage()}
          disabled={disabled}
          onChange={() => trigger(`${name}.street`)}
        />
        <TextInput name={`${name}.streetNumber`} label={oc('houseNumber')} className="flex-grow-3" disabled={disabled} />
      </FormRow>
      <FormRow mode={props.addressAlignment ? props.addressAlignment : 'two-col'}>
        <TextInput
          name={`${name}.postCode`}
          onBlur={(e) => setSelectedRegion(e.currentTarget.value?.slice(0, 2))}
          label={oc(`postcode`)}
          className="flex-grow-3"
          rules={{
            required: { value: true, message: error('required') },
            onChange: isValidPostCode,
            minLength: { value: 4, message: error('minLength') },
            maxLength: { value: 10, message: error('maxLength') },
            pattern: { value: pickupAddressPostCodeRegex, message: error('postCodeInvalidFormat') }
          }}
          disabled={disabled}
          error={getAllErrorsMessage()}
          onChange={() => trigger(`${name}.postCode`)}
        />
        <TextInput
          name={`${name}.city`}
          label={oc('city')}
          className="flex-grow-9"
          rules={{ required: 'required' }}
          error={getErrorMessage()}
          disabled={disabled}
          onChange={() => trigger(`${name}.city`)}
        />
      </FormRow>
      <FormRow mode={props.addressAlignment ? props.addressAlignment : 'two-col'}>
        <Controller<ObjectContaining<AddressSectionSchema>, `${string}.country`>
          name={`${name}.country`}
          defaultValue="DE"
          rules={{ required: 'required' }}
          render={({ field: { ref, ...field }, fieldState }) => (
            <ActiveMultiList
              data-testid={'country-Id'}
              name={field.name}
              onChange={(item) => {
                setSelectedCountry(item || 'DE');
                field.onChange(item);
                trigger(`${name}.country`);
              }}
              disabled={disabled}
              onBlur={field.onBlur}
              valueToString={(countryIsoCode) => CountryList[countryIsoCode as keyof typeof CountryList]}
              value={field.value}
              items={Object.keys(CountryList)}
              placeholder={oc`country`}
              error={getErrorMessage()({ fieldState })}
            />
          )}
        />
      </FormRow>
      <FormRow mode={props.addressAlignment ? props.addressAlignment : 'two-col'}>
        <TextInput
          name={`${name}.further`}
          label={oc(`pickupMoreInfo`)}
          className="flex-grow-9"
          rules={{ maxLength: { value: 120, message: 'maxLength' } }}
          error={getErrorMessage()}
          disabled={disabled}
          onChange={() => trigger(`${name}.further`)}
        />
      </FormRow>
    </OrderStepSection>
  );
};
