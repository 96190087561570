import React from 'react';
import { useTranslation } from 'react-i18next';

import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';

import styles from '../table-container.module.css';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomNewlyAddedCell: React.VFC<Props> = (props): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });

  return (
    <div className={styles.newlyAdded}>
      {props.row.newlyAdded ? <span data-testid={`${props.row.id}-newlyadded`}>{t('cell.newlyAdded')}</span> : ''}
    </div>
  );
};

export const customNewlyAddedCellRenderer = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomNewlyAddedCell value={value} row={row} column={column} />;
};
