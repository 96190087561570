import { getJournalPaymentData } from 'common/services/JournalService';
import { JournalPaymentInfoRep } from 'generated/api';

export type OrderState = {
  journalPaymentInfo: JournalPaymentInfoRep | undefined;
};

export interface FetchJournalPaymentInfoConfig {
  zkz: string;
}

type Action = { type: 'setJournalPaymentData'; data: JournalPaymentInfoRep | undefined };

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

export const defaultOrderState: OrderState = {
  journalPaymentInfo: undefined
};

export function orderDisplayReducer(state: OrderState, action: Action) {
  switch (action.type) {
    case 'setJournalPaymentData': {
      return {
        ...state,
        journalPaymentInfo: action.data
      };
    }
    default:
      return defaultOrderState;
  }
}

export const fetchJournalPaymentInfo = async (data: FetchJournalPaymentInfoConfig) => {
  const { zkz } = data;
  try {
    const data = (await getJournalPaymentData(zkz)).data;
    return data;
  } catch (e) {
    console.error(`Fetching order data for ${zkz} failed`, e);
  }
};
