import { OrderReplyTDO } from 'order/common/services/OrderService';

export enum OrderSearchKey {
  Date = 'DATE',
  OrderId = 'ORDER_ID', // Auftragsnummer
  CustomerOrderId1 = 'CUSTOMER_ORDER_ID_TYPE_1', // Kundenauftragsnummer Typ1
  CustomerOrderId2 = 'CUSTOMER_ORDER_ID_TYPE_2', // Kundenauftragsnummer Typ 2
  EposLabel = 'EPOS_LABEL', // Chargenauftragsnummer, since AM.exchange WSDL 5.0
  Payer = 'PAYER', // Zahlungspflichtiger
  Submitter = 'SUBMITTER', // Einlieferer
  Producer = 'PRODUCER', // Hersteller
  Originator = 'ORIGINATOR', // Absender
  Beneficiary = 'BENEFICIARY', // Berechtigter
  Procedure = 'PROCEDURE', // Verfahren, since AM.exchange WSDL 5.0
  Participation = 'PARTICIPATION', // Teilnahme, since AM.exchange WSDL 5.0
  SheetNumber = 'SHEET_NUMBER', // Entgeltabrechnungsnummer
  ActionDescription = 'ACTION_DESCRIPTION', // Auftragsbezeichnung
  ZKZ = 'ZKZ', // Zeitungsnummer
  SettlementNumber = 'SETTLEMENT_NUMBER', // Abrechnungsnummer
  PaymentClearingNumber = 'PAYMENT_CLEARING_NUMBER', // Einlieferungsbelegnummer, since AM.exchange WSDL 5.0,
  OrderCategoryGroup = 'ORDER_CATEGORY_GROUP',
  OrderCategories = 'ORDER_CATEGORIES',
  OrderType = 'ORDER_TYPE',
  V_SEARCH_MODE = 'V_SEARCH_MODE',
  ProductionState = 'PRODUCTION_STATE'
}

export const orderSearchKeyReplyTDOMapping = new Map<OrderSearchKey, keyof OrderReplyTDO>([
  [OrderSearchKey.OrderId, 'orderNumber'],
  [OrderSearchKey.CustomerOrderId1, 'customerOrderId'],
  [OrderSearchKey.CustomerOrderId2, 'customerOrderId'],
  [OrderSearchKey.EposLabel, 'customerOrderId'],
  [OrderSearchKey.Payer, 'payer'],
  [OrderSearchKey.Submitter, 'submitter'],
  [OrderSearchKey.Producer, 'producer'],
  [OrderSearchKey.Originator, 'originator'],
  [OrderSearchKey.Beneficiary, 'beneficiary'],
  [OrderSearchKey.Procedure, 'procedure'],
  [OrderSearchKey.Participation, 'participation'],
  [OrderSearchKey.ActionDescription, 'orderLabel'],
  [OrderSearchKey.ZKZ, 'zkz'],
  [OrderSearchKey.PaymentClearingNumber, 'paymentClearingNumber'],
  [OrderSearchKey.ProductionState, 'state']
]);
