import { ProductTO } from 'generated';
import { LetterBaseProduct } from 'order/common/context/order/dtos/GetOrder';
import { DVHierarchyProductGroup } from './DVHierarchyProductGroup';

export type UseDVBriefProductsReturn = {
  dvBrief: boolean;
  dvInternational: boolean;
  dvPZA: boolean;
  dvEPostBrief: boolean;
  dvStrip: boolean;
  dvBook: boolean;
  dvBlind: boolean;
  dvDVD: boolean;
  dvEPostBriefWithPartialService: boolean;

  dvBriefList: ProductTO[];
  dvInternationalList: ProductTO[];
  dvPZAList: ProductTO[];
  dvEPostBriefList: ProductTO[];
  dvStripList: ProductTO[];
  dvBookList: ProductTO[];
  dvBlindList: ProductTO[];
  dvDVDList: ProductTO[];
};

export const useDVBriefProducts = (
  baseProducts: ProductTO[],
  letterBaseProducts?: LetterBaseProduct[],
  isDVBrief: boolean = false
): UseDVBriefProductsReturn => {
  const baseProductNumber = letterBaseProducts?.map((b) => b.baseProduct?.productNumber);
  const dvBriefList = isDVBrief ? baseProducts.filter((b) => b.partialServiceCapable === true) : [];
  const dvBrief = dvBriefList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvInternationalList = isDVBrief
    ? baseProducts.filter(
        (b) =>
          b.hierarchyProductGroup === DVHierarchyProductGroup.DVINTERNATIONAL &&
          b.hierarchyProductCharacteristic === DVHierarchyProductGroup.INDIVIDUALTARIFF
      )
    : [];
  const dvInternational = dvInternationalList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvPZAList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVPZA) : [];
  const dvPZA = dvPZAList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvEPostBriefList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVEPOSTBRIEF) : [];
  const dvEPostBrief = dvEPostBriefList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvEPostBriefWithPartialService = baseProducts
    .filter((b) => b?.hierarchyProductGroup === DVHierarchyProductGroup.DVEPOSTBRIEF && b?.partialServiceCapable)
    .some((a) => baseProductNumber?.includes(a.productNumber));

  const dvStripList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVSTRIP) : [];
  const dvStrip = dvStripList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvBookList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVBOOK) : [];
  const dvBook = dvBookList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvBlindList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVBLIND) : [];
  const dvBlind = dvBlindList.some((a) => baseProductNumber?.includes(a.productNumber));

  const dvDVDList = isDVBrief ? baseProducts.filter((b) => b.hierarchyProductGroup === DVHierarchyProductGroup.DVDVD) : [];
  const dvDVD = dvDVDList.some((a) => baseProductNumber?.includes(a.productNumber));

  return {
    dvBrief: dvBrief,
    dvInternational: dvInternational,
    dvPZA: dvPZA,
    dvEPostBrief: dvEPostBrief,
    dvStrip: dvStrip,
    dvBook: dvBook,
    dvBlind: dvBlind,
    dvDVD: dvDVD,
    dvEPostBriefWithPartialService: dvEPostBriefWithPartialService,
    dvBriefList: dvBriefList,
    dvInternationalList: dvInternationalList,
    dvPZAList: dvPZAList,
    dvEPostBriefList: dvEPostBriefList,
    dvStripList: dvStripList,
    dvBookList: dvBookList,
    dvBlindList: dvBlindList,
    dvDVDList: dvDVDList
  };
};
