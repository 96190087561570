// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingOrder_container__I\\+DRD {
  max-width: 1400px;
  width: 100%;
  padding: 0 32px;
  font-size: 14px;
  margin: 0 auto 3rem;
}

.loadingOrder_grid__mSo-a {
  display: flex;
  flex-wrap: wrap;
}

.loadingOrder_col_6__fiAv1 {
  width: 50%;
  flex-grow: 1;
}

.loadingOrder_col_8__PY81w {
  width: 65%;
  flex-grow: 1;
}

.loadingOrder_paddingBottom30__tJ506 {
  padding-bottom: 30px;
}


.loadingOrder_parentbg__SWFYK {
  background-color: #f5f5f5;
  padding: 27px;
  border-radius: 4px;
}

.loadingOrder_h1__t\\+Vtd, .loadingOrder_h2__5zyEm, .loadingOrder_h3__DOICF, .loadingOrder_h4__rjbT-, .loadingOrder_h5__T\\+gtP, .loadingOrder_h6__9xXeQ {
  font-weight: 600;
  line-height: 1.125;
  font-family: Delivery, Verdana, sans-serif;
  margin-bottom: 16px;
  color: #323232;
}

.loadingOrder_h4__rjbT- {
  font-size: 25px;
}

.loadingOrder_center__cDE87 {
  text-align: center;
}

.loadingOrder_heading__W\\+Ywi {
  padding-right: 15%;
}`, "",{"version":3,"sources":["webpack://./src/common/components/LoadingOrder/loadingOrder.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;;AAGA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  max-width: 1400px;\n  width: 100%;\n  padding: 0 32px;\n  font-size: 14px;\n  margin: 0 auto 3rem;\n}\n\n.grid {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.col_6 {\n  width: 50%;\n  flex-grow: 1;\n}\n\n.col_8 {\n  width: 65%;\n  flex-grow: 1;\n}\n\n.paddingBottom30 {\n  padding-bottom: 30px;\n}\n\n\n.parentbg {\n  background-color: #f5f5f5;\n  padding: 27px;\n  border-radius: 4px;\n}\n\n.h1, .h2, .h3, .h4, .h5, .h6 {\n  font-weight: 600;\n  line-height: 1.125;\n  font-family: Delivery, Verdana, sans-serif;\n  margin-bottom: 16px;\n  color: #323232;\n}\n\n.h4 {\n  font-size: 25px;\n}\n\n.center {\n  text-align: center;\n}\n\n.heading {\n  padding-right: 15%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `loadingOrder_container__I+DRD`,
	"grid": `loadingOrder_grid__mSo-a`,
	"col_6": `loadingOrder_col_6__fiAv1`,
	"col_8": `loadingOrder_col_8__PY81w`,
	"paddingBottom30": `loadingOrder_paddingBottom30__tJ506`,
	"parentbg": `loadingOrder_parentbg__SWFYK`,
	"h1": `loadingOrder_h1__t+Vtd`,
	"h2": `loadingOrder_h2__5zyEm`,
	"h3": `loadingOrder_h3__DOICF`,
	"h4": `loadingOrder_h4__rjbT-`,
	"h5": `loadingOrder_h5__T+gtP`,
	"h6": `loadingOrder_h6__9xXeQ`,
	"center": `loadingOrder_center__cDE87`,
	"heading": `loadingOrder_heading__W+Ywi`
};
export default ___CSS_LOADER_EXPORT___;
