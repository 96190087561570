import { defaultReducerState, OrderReducerState } from '../reducers/order';
import { LoadingState } from '../../search/dtos/LoadingState';
import { OrderReference } from './OrderReference';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderResponse } from './GetOrder';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderCategory } from './OrderCategory';
import { AllocatedOrder } from './AllocatedOrder';
import { OrderCategoryProductKey } from './OrderCategoryProductKey';
import { OrderPriceRep } from 'generated';
import { GetOrderFaultyIds } from '../OrderProvider';

export interface OrderContextValues extends OrderReducerState {
  allocationsLoading: boolean;
  allocationsCount: number;
  allocationsTotal: number;
  allocationsOrders: AllocatedOrder[];
  changeOrderReference: (orderId: string, data: OrderReference) => void;
  deleteAllocation: (orderId: string) => void;
  generateExistingAllocations: (orderIds: string[], originalOrder: OrderResponse) => void;
  generateNewAllocations: (orderIds: string[], editable?: boolean) => void;
  getJournalPaymentData: (zkz: string) => void;
  hasErrors: () => boolean;
  addError: (key: string, data: unknown) => void;
  deleteError: (key: string) => void;
  reset: () => void;
  resetAllocations: () => void;
  resetOrderReferences: () => void;
  resetFaultyGetOrderIds: () => void;
  setDisplayAllAllocations: (display: boolean) => void;
  setOrder: (order: OrderResponse | undefined) => void;
  setOrderPrice: (orderPrice?: OrderPriceRep) => void;
  setOrderId: (orderId: string | number | undefined) => void;
  setOrderMode: (orderMode: OrderMode) => void;
  setLoadingState: (state: LoadingState) => void;
  setProductGroup: (productGroup: ProductGroup | undefined) => void;
  setOrderCategory: (orderCategory: OrderCategory | undefined) => void;
  setOrderCategoryProductKey: (orderCategoryProductKey: OrderCategoryProductKey | undefined) => void;
  setDirty: (dirty: boolean) => void;
  upsertMetaData: (key: string, value: unknown) => void;
  setFaultyGetOrderIds: (ids: GetOrderFaultyIds[]) => void;
  setAllocatedDiscounts: (data: OrderReference, mode: 'ADD' | 'UPDATE' | 'DELETE') => void;
}

export const defaultOrderContextValues: OrderContextValues = {
  ...defaultReducerState,
  allocationsCount: 0,
  allocationsOrders: [],
  allocationsTotal: 0,
  allocationsLoading: false,
  changeOrderReference: () => undefined,
  deleteAllocation: () => undefined,
  generateExistingAllocations: () => undefined,
  generateNewAllocations: () => undefined,
  getJournalPaymentData: () => null,
  hasErrors: () => false,
  addError: () => undefined,
  deleteError: () => undefined,
  reset: () => undefined,
  resetAllocations: () => null,
  resetOrderReferences: () => null,
  resetFaultyGetOrderIds: () => null,
  setDisplayAllAllocations: () => null,
  setOrder: () => undefined,
  setOrderPrice: () => undefined,
  setOrderId: () => undefined,
  setOrderMode: () => undefined,
  setLoadingState: () => undefined,
  setProductGroup: () => undefined,
  setOrderCategory: () => undefined,
  setOrderCategoryProductKey: () => undefined,
  setDirty: () => undefined,
  upsertMetaData: () => undefined,
  setFaultyGetOrderIds: () => undefined,
  setAllocatedDiscounts: () => undefined
};
