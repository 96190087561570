import classNames from 'classnames';
import { Button } from 'common/components/Button';
import orderClasses from 'common/styles/order.module.css';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { ProductGroupInput } from 'order/productGroups/common/components/molecule/ProductGroupInput';
import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import React, { VFC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import iconZusteller from 'assets/icon-zusteller.svg';
import styles from './OrderCopyStep1.module.css';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export const OrderCopyStep1: VFC<{ orderCategoryProductKey?: OrderCategoryProductKey }> = (props) => {
  const { nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  return (
    <>
      <main className={classNames(orderClasses.formContent, styles.stepOneMainContainer)}>
        <div className={classNames(styles.orderTileContainer)}>
          <div className={classNames(styles.ocstep1Header)}>
            <OrderStepHeadline icon={iconZusteller} alt="Absender Icon">
              {t('steps.productGroup')}
            </OrderStepHeadline>
          </div>
          <ProductGroupInput selectedKey={props.orderCategoryProductKey} disabled={true} workflowType={OrderMode.COPY} />
        </div>
      </main>
      <footer className={classNames('d-flex', 'justify-content-end')}>
        <Button variant="primary" onClick={() => nextStep()} type="button" label={t('buttons.continue')} dataTestId="btnSubmit" />
      </footer>
    </>
  );
};
