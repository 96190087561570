// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZahlungspflichtigerNichtBearbeitbar_formHeader__sXkhX {
  display: flex;
  justify-content: space-between;
}

.ZahlungspflichtigerNichtBearbeitbar_container__kOaf6 {
  display: flex;
  gap: 20px;
}

.ZahlungspflichtigerNichtBearbeitbar_infoContainer__ybqKY {
  display: flex;
  gap: 12px;
}

.ZahlungspflichtigerNichtBearbeitbar_flex50pct__g\\+5wY {
  flex-basis: 50%;
  overflow: hidden;
}

.ZahlungspflichtigerNichtBearbeitbar_avatar__7xD9W {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: #E6E6E6;
}

.ZahlungspflichtigerNichtBearbeitbar_avatar__7xD9W > svg {
  max-height: 15px;
}

.ZahlungspflichtigerNichtBearbeitbar_text__sEPRt, .ZahlungspflichtigerNichtBearbeitbar_text__sEPRt * {
  text-align: left;
  font-size: 14px !important;
  color: #707070 !important;
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/press/components/ZahlungspflichtigerNichtBearbeitbar/ZahlungspflichtigerNichtBearbeitbar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".formHeader {\n  display: flex;\n  justify-content: space-between;\n}\n\n.container {\n  display: flex;\n  gap: 20px;\n}\n\n.infoContainer {\n  display: flex;\n  gap: 12px;\n}\n\n.flex50pct {\n  flex-basis: 50%;\n  overflow: hidden;\n}\n\n.avatar {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  display: grid;\n  place-items: center;\n  background: #E6E6E6;\n}\n\n.avatar > svg {\n  max-height: 15px;\n}\n\n.text, .text * {\n  text-align: left;\n  font-size: 14px !important;\n  color: #707070 !important;\n  margin: 0 !important;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formHeader": `ZahlungspflichtigerNichtBearbeitbar_formHeader__sXkhX`,
	"container": `ZahlungspflichtigerNichtBearbeitbar_container__kOaf6`,
	"infoContainer": `ZahlungspflichtigerNichtBearbeitbar_infoContainer__ybqKY`,
	"flex50pct": `ZahlungspflichtigerNichtBearbeitbar_flex50pct__g+5wY`,
	"avatar": `ZahlungspflichtigerNichtBearbeitbar_avatar__7xD9W`,
	"text": `ZahlungspflichtigerNichtBearbeitbar_text__sEPRt`
};
export default ___CSS_LOADER_EXPORT___;
