import React from 'react';

export const formatSettlementNumber = (settlementNumber: string): string => {
  const formattedValue = settlementNumber.replace(/\s+/g, '');

  const payer = formattedValue.slice(0, 10);
  const procedure = formattedValue.slice(10, 12);
  const participation = formattedValue.slice(12, 14);
  if (procedure === '10' || procedure === '50') {
    const paymentClearingNumber = formattedValue.slice(14, 19);
    if (paymentClearingNumber) {
      return `${payer} ${procedure} ${participation} ${paymentClearingNumber}`;
    }
  }
  return `${payer} ${procedure} ${participation}`;
};
