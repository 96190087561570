import { computeAvailableCombinations } from 'order/common/utils/computeAvailableCombinations';
import { ProductClusterCombination, getProductClusterCombinations } from 'order/productGroups/common/services/ProductClusterCombinationService';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr/immutable';

export type EntityWithProductNumber = { productNumber?: string };

export type UseClusterCombinationReturn = {
  all: ProductClusterCombination[];
  available: ProductClusterCombination[];
  isProductAvailable: (product?: EntityWithProductNumber) => boolean;
};

export const useClusterCombination = (
  baseProduct?: string,
  selectedProducts: string[] = [],
  selectedDate = new Date()
): UseClusterCombinationReturn => {
  const { data: combinations = [] } = useSWR(baseProduct?.length ? ['clusterCombinations', baseProduct] : null, async (_, baseProduct) =>
    getProductClusterCombinations(baseProduct).catch(() => [] as ProductClusterCombination[])
  );

  const available = useMemo(
    () => computeAvailableCombinations(combinations, [baseProduct || '', ...selectedProducts], selectedDate),
    [baseProduct, combinations, selectedDate, selectedProducts]
  );

  const isProductAvailable = useCallback(
    (entity?: EntityWithProductNumber): boolean => {
      if (combinations.length === 0) {
        return true;
      }
      return available.some((combination) => combination.productNumber === entity?.productNumber);
    },
    [available, combinations.length]
  );

  return {
    all: combinations,
    available,
    isProductAvailable: isProductAvailable
  };
};
