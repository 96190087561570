// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additionalOrdersStep3_flexGrowWidth__ke8MO{
    width: 20%;
}
.additionalOrdersStep3_inputFullWidth__H7Dx-{
    width: 100%;
}
.additionalOrdersStep3_additionalSpace__pbYEc{
    padding-top: 10%;
}
.partialStep3 .date-picker{
    pointer-events: auto !important;
}

.additionalOrdersStep3_tableContainer__xeyix {
    /* width: calc(100vw); */
    position: relative;
    /* left: -167px; */
}

.additionalOrdersStep3_counterHeadline__roIKO {
    margin-top: 24px;
}

.additionalOrdersStep3_misMatchedInductionDateOrders__1Kwdx {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
}

.additionalOrdersStep3_misMatchedInductionDateOrderNumber__T\\+P0n {
    padding: 6px;
    background-color: #E6E6E6;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/order/productGroups/additionalOrders/workFlowSteps/step3/additionalOrdersStep3.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;AACA;IACI,+BAA+B;AACnC;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".flexGrowWidth{\n    width: 20%;\n}\n.inputFullWidth{\n    width: 100%;\n}\n.additionalSpace{\n    padding-top: 10%;\n}\n:global(.partialStep3 .date-picker){\n    pointer-events: auto !important;\n}\n\n.tableContainer {\n    /* width: calc(100vw); */\n    position: relative;\n    /* left: -167px; */\n}\n\n.counterHeadline {\n    margin-top: 24px;\n}\n\n.misMatchedInductionDateOrders {\n    display: flex;\n    flex-direction: row;\n    max-width: 100%;\n    flex-wrap: wrap;\n}\n\n.misMatchedInductionDateOrderNumber {\n    padding: 6px;\n    background-color: #E6E6E6;\n    border-radius: 8px;\n    margin-right: 20px;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexGrowWidth": `additionalOrdersStep3_flexGrowWidth__ke8MO`,
	"inputFullWidth": `additionalOrdersStep3_inputFullWidth__H7Dx-`,
	"additionalSpace": `additionalOrdersStep3_additionalSpace__pbYEc`,
	"tableContainer": `additionalOrdersStep3_tableContainer__xeyix`,
	"counterHeadline": `additionalOrdersStep3_counterHeadline__roIKO`,
	"misMatchedInductionDateOrders": `additionalOrdersStep3_misMatchedInductionDateOrders__1Kwdx`,
	"misMatchedInductionDateOrderNumber": `additionalOrdersStep3_misMatchedInductionDateOrderNumber__T+P0n`
};
export default ___CSS_LOADER_EXPORT___;
