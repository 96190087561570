// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transport-services_row__RJ7pA {
  display: flex;
  gap: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.transport-services_row__RJ7pA > * {
  flex-grow: 1;
  flex-basis: 0;
}

.transport-services_row__RJ7pA input {
  width: 100%;
}

.transport-services_heading__CnHcy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:40px;
}

.transport-services_addButton__iII-9 {
  border: inherit;
  background: inherit;
  margin-top: 10px;
  cursor: pointer;
  text-transform: uppercase;
}

.transport-services_productName__j5RoP {
  font-weight: 500;
  font-size: 15px !important;
}
.transport-services_infoForTooltip__6vB2I {
  line-height: 1.1;
}

.transport-services_infoForTooltip__6vB2I::before {
  font-size: 20px;
}

.transport-services_mr10__6V41X {
  margin-right: 10rem;
}

.transport-services_errorMessage__APsQe {
  line-height: 1.25;
  margin-bottom: 10px;
}
.transport-services_pl15__63Kzf {
  padding-left: 15px;
}
.transport-services_pl30__VlcXY {
  padding-left: 30px;
}
.transport-services_parentDiv__FHwxw .transport-services_threeCustomUnevenCol__fa2OD {
  grid-template-columns: 0.8fr 1.5fr 0.7fr;
}
.transport-services_parentDiv__FHwxw .transport-services_twoCustomUnevenCol__U2Odp {
  grid-template-columns: 1.5fr 0.5fr;
  gap: 0;
}
.transport-services_parentDiv__FHwxw .transport-services_twoCustomUnevenColOption2__8fC5e {
  grid-template-columns: 0.6fr 1.4fr;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/international/components/TransportServices/transport-services.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,kCAAkC;EAClC,MAAM;AACR;AACA;EACE,kCAAkC;AACpC","sourcesContent":[".row {\n  display: flex;\n  gap: 20px;\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n}\n\n.row > * {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.row input {\n  width: 100%;\n}\n\n.heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top:40px;\n}\n\n.addButton {\n  border: inherit;\n  background: inherit;\n  margin-top: 10px;\n  cursor: pointer;\n  text-transform: uppercase;\n}\n\n.productName {\n  font-weight: 500;\n  font-size: 15px !important;\n}\n.infoForTooltip {\n  line-height: 1.1;\n}\n\n.infoForTooltip::before {\n  font-size: 20px;\n}\n\n.mr10 {\n  margin-right: 10rem;\n}\n\n.errorMessage {\n  line-height: 1.25;\n  margin-bottom: 10px;\n}\n.pl15 {\n  padding-left: 15px;\n}\n.pl30 {\n  padding-left: 30px;\n}\n.parentDiv .threeCustomUnevenCol {\n  grid-template-columns: 0.8fr 1.5fr 0.7fr;\n}\n.parentDiv .twoCustomUnevenCol {\n  grid-template-columns: 1.5fr 0.5fr;\n  gap: 0;\n}\n.parentDiv .twoCustomUnevenColOption2 {\n  grid-template-columns: 0.6fr 1.4fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `transport-services_row__RJ7pA`,
	"heading": `transport-services_heading__CnHcy`,
	"addButton": `transport-services_addButton__iII-9`,
	"productName": `transport-services_productName__j5RoP`,
	"infoForTooltip": `transport-services_infoForTooltip__6vB2I`,
	"mr10": `transport-services_mr10__6V41X`,
	"errorMessage": `transport-services_errorMessage__APsQe`,
	"pl15": `transport-services_pl15__63Kzf`,
	"pl30": `transport-services_pl30__VlcXY`,
	"parentDiv": `transport-services_parentDiv__FHwxw`,
	"threeCustomUnevenCol": `transport-services_threeCustomUnevenCol__fa2OD`,
	"twoCustomUnevenCol": `transport-services_twoCustomUnevenCol__U2Odp`,
	"twoCustomUnevenColOption2": `transport-services_twoCustomUnevenColOption2__8fC5e`
};
export default ___CSS_LOADER_EXPORT___;
