import { isEntityWithDatesValid } from 'common/utils/helpers';
import { postageType } from 'order/productGroups/common/dtos/CatalogValues';
import { MethodOfDenotingPrepayment, requestMethodOfDenotingPrepayment } from 'order/productGroups/common/services/ProductService';
import { useMemo } from 'react';
import useSWR from 'swr/immutable';

export type UseDenotingPrepaymentsReturn = {
  methods: MethodOfDenotingPrepayment[];
  catalogValues: string[];
};

export const useDenotingPrepayments = (baseProduct?: string, selectedDate = new Date()): UseDenotingPrepaymentsReturn => {
  const { data = [] } = useSWR(baseProduct?.length ? ['productGroup', baseProduct] : null, async (_, baseProduct) => {
    const data = (await requestMethodOfDenotingPrepayment(baseProduct)) || [];
    if (!data.length) {
      // if no methodOfDenotingPrepayments are found for this product -> all methodOfDenotingPrepayments are allowed
      return postageType.map((type) => ({
        methodOfDenotingPrepaymentCatalog: type,
        methodOfDenotingPrepayment: type,
        validFrom: '1900-01-01',
        validTo: '9999-01-01'
      }));
    }
    return data.filter((catalogValue) => {
      // suppress undefined catalog values
      if (!catalogValue.methodOfDenotingPrepaymentCatalog) {
        console.error(`Frankierart ${catalogValue.methodOfDenotingPrepaymentCatalog} ohne Katalogwert. Wird ignoriert.`);
      }
      return !!catalogValue.methodOfDenotingPrepaymentCatalog;
    });
  });

  const catalog = useMemo(
    () =>
      data
        .filter((method) => isEntityWithDatesValid(method, selectedDate))
        .map((c) => c.methodOfDenotingPrepaymentCatalog)
        .filter((s) => !!s) as string[],
    [data, selectedDate]
  );

  return {
    methods: data,
    catalogValues: catalog
  };
};
