import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';

import i18n from '../../../i18n';
import { AppPaths } from '../../../Main';
import { UserRights } from '../../dtos/userRights';
import { useAlerts } from '../../AlertContext';

import styles from './pendingOrder.module.css';
import { AlertTypes } from '../../../order/common/components/Alerts/dtos/AlertTypes';
import { useSearchContext } from '../../../order/common/context/search/SearchContext';
import { useAuthContext } from '../../context/auth/AuthContext';
import { Alerts } from '../../../order/common/components/Alerts/Alerts';

interface Props {
  headline: string;
  title: string;
  message: string;
}
export const CustomOrderAlert = (props: Props): JSX.Element => {
  const { alerts, addAlert, clear } = useAlerts();
  const { triggerLastSearch } = useSearchContext();
  const history = useHistory();
  const { hasPermission } = useAuthContext();

  useEffect(() => {
    addAlert({
      type: AlertTypes.Warning,
      description: props.message,
      title: props.title
    });

    return () => clear();
  }, []);

  return (
    <div className={classNames(styles.container)} data-testid={'custom-order-alert'}>
      <div className={classNames(styles.grid, styles.paddingBottom30)}>
        <div className={classNames(styles.col_8)}>
          <h2 className={classNames(styles.h4, styles.center)}>{props.headline}</h2>
        </div>
      </div>
      <Alerts alerts={alerts} />
      <div className={classNames(styles.parentbg)}>
        <div className={classNames(styles.grid)}>
          <div className={classNames(styles.col_6, styles.text_left)}>
            <DHLButton
              name={'auftrag-suchen'}
              label={i18n.t('viewOrderFooter.jobSearchButton')}
              type={'primary'}
              onClick={() => {
                triggerLastSearch();
                history.push(AppPaths.orderSearchPath);
              }}
              disabled={!hasPermission(UserRights.OrderSearch)}
            />
          </div>
          <div className={classNames(styles.col_6)}></div>
        </div>
      </div>
    </div>
  );
};
