import React, { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { useTranslation } from 'i18n';
import { BackToSearchButton } from 'order/common/components/BackToSearch/BackToSearchButton';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn/MarginalColumn';
import { AMDHLOrderSearchFilterDatePicker } from 'order/orderSearch/components/filter/AMDHLOrderSearchFilterDatePicker';
import { AMDHLOrderSearchFilterSelectableTextInput } from 'order/orderSearch/components/filter/AMDHLOrderSearchFilterSelectableTextInput';
import { OrderSearchFilterContainer } from 'order/orderSearch/components/filter/OrderSearchFilterContainer';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { publishToTopic } from 'common/dtos/PubSubEvents';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import orderClasses from 'common/styles/order.module.css';
import styles from './additionalOrdersStep3.module.css';
import depositOrder from 'assets/deposit-order.svg';
import { AdditionalOrdersOrderCreate } from '../../schema/additionalOrdersSchema';
import { TableContainer } from './components/table/TableContainer';
import { GetOrderFaultyIds } from 'order/common/context/order/OrderProvider';

export const AdditionalOrdersStep3: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { control, setValue } = useFormContext<AdditionalOrdersOrderCreate>();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { setSearchType, loading, reset } = useSearchContext();
  const { orderReferences, hasErrors, errors, orderCategory, allocationsLoading, allocationsOrders, meta, orderMode, getOrderFaultyIds } =
    useOrderContext();
  const { isDirty } = useOrderContext();
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [filteredSelectedAllocOrders, searchedOrders, productGroup] = useWatch({
    control,
    name: ['filteredSelectedAllocOrders', 'searchedOrders', 'productGroup']
  });
  const [selectedAttrType, setSelectedAttrType] = useState<OrderSearchKey>();

  useEffect(() => {
    setSearchType(orderCategory === OrderCategory.ABHOLAUFTRAG ? OrderSearchType.COLLECTION_LETTER : OrderSearchType.PARTIAL);
    reset();
  }, []);

  useEffect(() => {
    if (loading) {
      setValue('selectAll', false);
    }
  }, [loading]);

  const setSelectedAttr = (type: OrderSearchKey) => {
    setSelectedAttrType(type);
  };

  return (
    <>
      <div className={orderClasses.partialServiceWrapper}>
        <div className={classNames(orderClasses.formContent, 'partialStep3')}>
          <div className={orderClasses.rowWithSidebar}>
            <main>
              <OrderStepHeadline notCapitalise icon={depositOrder} alt={`Icon ${oc('depositOrder')}`}>
                {oc('depositOrder')}
              </OrderStepHeadline>
              <OrderSearchFilterContainer additionalSpace={styles.additionalSpace} disabled={allocationsLoading} meta={meta}>
                <AMDHLOrderSearchFilterSelectableTextInput
                  orderMode={orderMode}
                  nonNumeric={selectedAttrType === OrderSearchKey.ActionDescription || selectedAttrType === OrderSearchKey.Participation}
                  additionalClass={styles.flexGrowWidth}
                  inputClass={styles.inputFullWidth}
                  setSelectedAttr={setSelectedAttr}
                  searchKeys={[
                    OrderSearchKey.Submitter,
                    OrderSearchKey.Originator,
                    OrderSearchKey.Payer,
                    OrderSearchKey.Producer,
                    OrderSearchKey.OrderId,
                    {
                      key: OrderSearchKey.ActionDescription,
                      initiallyDeactivated: true
                    },
                    {
                      key: OrderSearchKey.SettlementNumber,
                      initiallyDeactivated: true
                    },
                    {
                      key: OrderSearchKey.Procedure,
                      initiallyDeactivated: true
                    },
                    {
                      key: OrderSearchKey.Participation,
                      initiallyDeactivated: true
                    },
                    {
                      key: OrderSearchKey.PaymentClearingNumber,
                      initiallyDeactivated: true
                    },
                    {
                      key: OrderSearchKey.ProductionState,
                      initiallyDeactivated: true
                    }
                  ]}
                />
                <AMDHLOrderSearchFilterDatePicker />
              </OrderSearchFilterContainer>
            </main>
            <MarginalColumn />
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <TableContainer />
      </div>
      {saveTriggered && !orderReferences.length && (
        <div className="fieldNote error" data-testid={'orderReferences-error'}>
          {t(`error.allocation`)}
        </div>
      )}
      {saveTriggered && errors.length
        ? errors.map((e) => (
            <div className="fieldNote error" data-testid={`orderAllocation-error-${e.key}`} key={`orderAllocation-error-${e.key}`}>
              {t(`error.withinAllocation`, { orderNumber: e?.key?.replace('-totalError', ''), format: (e.data as any)?.format })}
            </div>
          ))
        : null}
      <div className={classNames(orderClasses.partialServiceWrapper, orderClasses.partialServiceFooter)}>
        <footer className={classNames('d-flex', 'justify-content-between')}>
          <div>
            <Button
              style={{ marginRight: '10px' }}
              variant="default"
              onClick={() => previousStep()}
              dataTestId="btnBack"
              label={t('buttons.back')}
              type="button"
            />
            <BackToSearchButton isDirty={isDirty} />
          </div>
          <Button
            variant="primary"
            dataTestId="btnSubmit"
            onClick={async () => {
              setSaveTriggered(true);
              if (orderCategory === OrderCategory.TL_BRIEF && orderReferences.length && !hasErrors()) {
                nextStep();
              } else if (orderCategory === OrderCategory.ABHOLAUFTRAG && allocationsOrders.length) {
                const inductionDate = new Date(meta?.pickUpDate).getDate();
                const inductionMonth = new Date(meta?.pickUpDate).getMonth();
                const inductionYear = new Date(meta?.pickUpDate).getFullYear();
                const misMatchedInductionDateOrders = allocationsOrders.filter((a) => {
                  const date = new Date(a.data.firstInductionDate || '').getDate();
                  const month = new Date(a.data.firstInductionDate || '').getMonth();
                  const year = new Date(a.data.firstInductionDate || '').getFullYear();
                  const notInPlanning = a.data.productionState !== ProductionState.IN_PLANNING;
                  const notInProcessingWithWrongDate =
                    a.data.productionState === ProductionState.BEING_PROCESSED &&
                    (date !== inductionDate || month !== inductionMonth || year !== inductionYear);

                  return notInPlanning && notInProcessingWithWrongDate;
                });

                if (misMatchedInductionDateOrders.length) {
                  publishToTopic('am.modal.open', {
                    content: (
                      <div data-testid={`order-mismatch-modal-content`}>
                        {t('errors.mismatchedCollectionAllocatedOrdersInductionDateAndState')}
                        <div className={styles.counterHeadline}>{t('headline.orderNumbers')}</div>
                        <p className={styles.misMatchedInductionDateOrders}>
                          {misMatchedInductionDateOrders.map((a, i) => (
                            <div
                              className={styles.misMatchedInductionDateOrderNumber}
                              key={`order-mismatch-${i}`}
                              data-testid={`order-mismatch-${i}`}
                            >
                              {a.data.orderNumber}
                            </div>
                          ))}
                        </p>
                      </div>
                    ),
                    headline: t('notifications.notice'),
                    cancelConfig: {
                      button: <div></div>
                    },
                    submitConfig: {
                      title: t(`buttons.ok`),
                      onSubmit: () => {
                        publishToTopic('am.modal.close', null);
                      }
                    }
                  });
                } else {
                  nextStep();
                }
              }
            }}
            disabled={allocationsLoading || !!(getOrderFaultyIds as GetOrderFaultyIds[])?.find((fo) => fo.isSelected === true)}
            label={t('buttons.continue')}
            type="button"
          />
        </footer>
      </div>
    </>
  );
};
