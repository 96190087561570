// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SplitOrderContent_shareAfter__BHNuf {
  padding-right: 2.5em;
}
.SplitOrderContent_selectShuttle__Lf4Fn {
  width: 85%;
  margin: 0 auto;
}
ul.SplitOrderContent_dhlSelectManyShuttle-input-inner__3TsGT li {
  white-space: pre;
}
.SplitOrderContent_headerDivider__KpRmx {
  border-left: 1px solid rgba(50, 50, 50, 0.4);
  height: 100%;
  position: absolute;
  left: 50%;
  top: 10px;
}
.SplitOrderContent_newOrder__AaYF3 {
  padding-left: 3.25em;
  padding-right: 2.5em;
}
.SplitOrderContent_shuttleTitle__g4cZG {
  padding: 2em 0 0 1em;
  display: flex;
  gap: 8em;
  width: 85%;
  /*margin :0 auto -18px auto;*/
  margin: 0 auto;
}
.SplitOrderContent_shuttleTitleElem__HNAhb {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.SplitOrderContent_dividerPosition__Fc62G {
  position: relative;
}

.SplitOrderContent_addDifferentConsignor__kCi4c {
  margin-top: 16px;
  margin-left: -16px;
}
.SplitOrderContent_removeDifferentConsignor__YuOfi {
  float: right;
  top: 20px;
  background: none !important;
}
.SplitOrderContent_datePickerScrollBtn__KA6xe,
.SplitOrderContent_datePickerScrollBtn__KA6xe:hover,
.SplitOrderContent_datePickerScrollBtn__KA6xe:focus {
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  box-shadow: none;
}
.SplitOrderContent_secondOrder__U9FwO {
  padding-left: 3.5em;
  padding-right: 0.25em;
}
.SplitOrderContent_firstOrder__NjHpo {
  padding-right: 3.5em;
}

.SplitOrderContent_changedOrder__WKaNn{
  font-size:12px;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/SplitOrder/SplitOrderContent/SplitOrderContent.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,SAAS;AACX;AACA;EACE,oBAAoB;EACpB,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,aAAa;EACb,QAAQ;EACR,UAAU;EACV,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;AACA;;;EAGE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,UAAU;EACV,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".shareAfter {\n  padding-right: 2.5em;\n}\n.selectShuttle {\n  width: 85%;\n  margin: 0 auto;\n}\nul.dhlSelectManyShuttle-input-inner li {\n  white-space: pre;\n}\n.headerDivider {\n  border-left: 1px solid rgba(50, 50, 50, 0.4);\n  height: 100%;\n  position: absolute;\n  left: 50%;\n  top: 10px;\n}\n.newOrder {\n  padding-left: 3.25em;\n  padding-right: 2.5em;\n}\n.shuttleTitle {\n  padding: 2em 0 0 1em;\n  display: flex;\n  gap: 8em;\n  width: 85%;\n  /*margin :0 auto -18px auto;*/\n  margin: 0 auto;\n}\n.shuttleTitleElem {\n  width: 50%;\n  display: flex;\n  justify-content: space-between;\n}\n.dividerPosition {\n  position: relative;\n}\n\n.addDifferentConsignor {\n  margin-top: 16px;\n  margin-left: -16px;\n}\n.removeDifferentConsignor {\n  float: right;\n  top: 20px;\n  background: none !important;\n}\n.datePickerScrollBtn,\n.datePickerScrollBtn:hover,\n.datePickerScrollBtn:focus {\n  width: 100%;\n  border: none;\n  background: transparent;\n  padding: 0;\n  outline: none;\n  box-shadow: none;\n}\n.secondOrder {\n  padding-left: 3.5em;\n  padding-right: 0.25em;\n}\n.firstOrder {\n  padding-right: 3.5em;\n}\n\n.changedOrder{\n  font-size:12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareAfter": `SplitOrderContent_shareAfter__BHNuf`,
	"selectShuttle": `SplitOrderContent_selectShuttle__Lf4Fn`,
	"dhlSelectManyShuttle-input-inner": `SplitOrderContent_dhlSelectManyShuttle-input-inner__3TsGT`,
	"headerDivider": `SplitOrderContent_headerDivider__KpRmx`,
	"newOrder": `SplitOrderContent_newOrder__AaYF3`,
	"shuttleTitle": `SplitOrderContent_shuttleTitle__g4cZG`,
	"shuttleTitleElem": `SplitOrderContent_shuttleTitleElem__HNAhb`,
	"dividerPosition": `SplitOrderContent_dividerPosition__Fc62G`,
	"addDifferentConsignor": `SplitOrderContent_addDifferentConsignor__kCi4c`,
	"removeDifferentConsignor": `SplitOrderContent_removeDifferentConsignor__YuOfi`,
	"datePickerScrollBtn": `SplitOrderContent_datePickerScrollBtn__KA6xe`,
	"secondOrder": `SplitOrderContent_secondOrder__U9FwO`,
	"firstOrder": `SplitOrderContent_firstOrder__NjHpo`,
	"changedOrder": `SplitOrderContent_changedOrder__WKaNn`
};
export default ___CSS_LOADER_EXPORT___;
