import { useCallback, useState } from 'react';

export type UseBooleanParam = boolean | (() => boolean);

export type UseBooleanReturn = readonly [
  boolean,
  {
    on: () => void;
    off: () => void;
    toggle: () => void;
  }
];

export const useBoolean = (defaultValue: UseBooleanParam = false): UseBooleanReturn => {
  const [value, setValue] = useState<boolean>(defaultValue);

  const on = useCallback(() => setValue(true), []);
  const off = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((s) => !s), []);

  return [value, { on, off, toggle }] as const;
};
