import classNames from 'classnames';

import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { DeliveryOption, DeliveryOptionZATL } from 'common/dtos/DeliveryOption';
import { Occurrence } from 'common/dtos/Occurrence';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import styles from 'order/productGroups/additionalOrders/workFlowSteps/step3/components/table/table-container.module.css';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { allocationConvertion } from '../../QtyAllocation/helper/allocationConvertion';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomDeliveryOptionCell = (props: Props): JSX.Element => {
  const { orderReferences, orderMode, changeOrderReference, meta, setAllocatedDiscounts, allocatedDiscounts, order } = useOrderContext();
  const orderReference = useRef<OrderReference | null>(null);
  const [selected, setSelected] = useState(DeliveryOption.EMPTY);
  const [editable, setEditable] = useState(props.row.editable);
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const [displayValue, setDisplayValue] = useState<string>('');
  const options = [
    {
      label: '',
      id: DeliveryOption.NONE
    },
    {
      label: 'E+1',
      id: DeliveryOption.STANDARD
    },
    {
      label: 'E+1 bis 2',
      id: DeliveryOption.FLEX
    }
  ];

  useLayoutEffect(() => {
    const ref = orderReferences?.find((o) => o.orderId === props.row.data?.orderNumber);

    if (ref) {
      orderReference.current = { ...ref };
      setSelected(orderReference.current?.selectedDeliveryOption ?? DeliveryOption.EMPTY);

      if (!editable) {
        const parentDO = allocatedDiscounts?.length
          ? Array.from(
              new Set(
                allocatedDiscounts
                  .find((allOrder) => allOrder.orderId === ref.orderId)
                  ?.parentDeliveryOption?.map((a) => t(`${a.toLocaleLowerCase()}`))
                  .sort()
              )
            ).join('/')
          : t(`${DeliveryOptionZATL.NONE.toLocaleLowerCase()}`);
        isAvailableFromJan25(orderMode === OrderMode.VIEW ? order?.orderDetail.date : meta?.pickUpDate)
          ? setDisplayValue(parentDO)
          : setDisplayValue(determineDisplayValue(ref));
      }

      if (ref.pendingIds) {
        setEditable(false);
      }
    }
  }, [orderReferences]);

  const determineDisplayValue = (ref: OrderReference): string => {
    if (ref.containsIds === Occurrence.ALL) {
      setEditable(false);
      if (ref.flexOption === Occurrence.ALL) {
        return 'E+1 bis 2';
      } else if (ref.flexOption === Occurrence.NONE) {
        return 'E+1';
      } else if (ref.flexOption === Occurrence.SOME) {
        return t('cell.noStatement');
      }
    } else if (ref.containsIds === Occurrence.NONE && ref.flexOption === Occurrence.NONE) {
      if (!ref.pendingIds) {
        setEditable(true);
      }

      // use predetermined delivery option (from preprocessing) from the EA
      setSelected(ref.selectedDeliveryOption ?? DeliveryOption.STANDARD);
      return 'E+1';
    } else if (ref.containsIds === Occurrence.SOME) {
      setEditable(false);
      if (ref.flexOption === Occurrence.NONE) {
        return 'E+1';
      } else if (ref.flexOption === Occurrence.SOME) {
        return 'E+1 / E+1 bis 2';
      }
    }
    return '';
  };

  const handleChange = (e: any) => {
    const flex = e.target.value === DeliveryOption.FLEX;
    const none = e.target.value === DeliveryOption.NONE;
    const std = e.target.value === DeliveryOption.STANDARD;
    if (orderReference.current) {
      const newOrderReference = { ...orderReference.current };
      newOrderReference.flexOption = flex ? Occurrence.ALL : Occurrence.NONE;
      newOrderReference.selectedDeliveryOption = flex ? DeliveryOption.FLEX : std ? DeliveryOption.STANDARD : DeliveryOption.NONE;

      const hasNoDeliveryAndNoFlexOption = none && newOrderReference.flexOption === Occurrence.NONE;

      newOrderReference.qtyAllocation?.forEach((q) => {
        q.flexOption = q.containsIds && (flex || (isAvailableFromJan25(meta?.pickUpDate) && hasNoDeliveryAndNoFlexOption));
      });
      orderReference.current = newOrderReference;
      changeOrderReference(newOrderReference.orderId || '', newOrderReference);
      if (isAvailableFromJan25(meta?.pickUpDate)) {
        const newData = allocationConvertion(orderReference.current);
        if (newData) {
          setAllocatedDiscounts(newData, 'UPDATE');
        }
      }
    }
    setSelected(e.target.value);
  };

  return (
    <div className={styles.inputSelectContainerInner}>
      {editable && orderMode !== OrderMode.VIEW ? (
        <>
          <select
            data-testid={`custom-select-cell-${orderReference.current?.orderId}`}
            className={styles.inputSelect}
            autoComplete=""
            value={selected}
            onChange={handleChange}
          >
            {options.map((o, i) => {
              return (
                <option
                  value={o.id}
                  key={`custom-select-cell-option-${o}-${i}`}
                  data-testid={`custom-select-cell-option-${o.id}-${orderReference.current?.orderId}`}
                >
                  {o.label}
                </option>
              );
            })}
          </select>
          <div className={classNames(styles.inputFunction, styles.notInteractive, 'arrow-down')}>
            <div data-testid="pagerName-pagesize-function" className="svgIcon svgIcon-arrow-down">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <polygon fill="#878787" fillRule="evenodd" points="12 13.655 21 8 21 10.345 12 16 3 10.345 3 8" />
              </svg>
            </div>
          </div>
        </>
      ) : null}
      {!editable || orderMode === OrderMode.VIEW ? <div data-testid="DeliveryOption-displayValue">{displayValue}</div> : null}
    </div>
  );
};

export const customDeliveryOption = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomDeliveryOptionCell value={value} row={row} column={column} />;
};
