import { GetOrderRep } from 'generated';
import { bulkLoad } from '../bulkLoad/api/bulkLoad';
import { computeBLChunks } from '../bulkLoad/helpers/computeBLChunks';

export const loadAdditionalOrdersForPrintingInBulk = async (orderIds: string[]): Promise<GetOrderRep[] | undefined> => {
  const allOrders: GetOrderRep[] = [];

  const bulkLoadChucks = computeBLChunks(orderIds);

  await Promise.all(
    bulkLoadChucks?.map(async (chunk) => {
      const orders = await bulkLoad(
        chunk?.map((o) => ({ orderId: o })),
        chunk?.length
      );
      allOrders.push(orders as any);
    })
  );

  return allOrders?.flatMap((o) => o);
};
