// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSearch_searchTableFooter__vzEIr > * + * {
  margin: 0 0 0 6px;
}

.OrderSearch_tableCentering__6JVy8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.OrderSearch_scrollFrame__qCzGo {
  min-width: 1110px;
  max-width: 100%;
  /* width: fit-content; */
  width: -moz-fit-content;
  overflow-x: auto;
  overflow-y: hidden;
}
.OrderSearch_textRight__DhchK {
  text-align: right;
}

@media (min-width: 768px) {
  .OrderSearch_scrollFrame__qCzGo {
    min-width: 680px;
  }
}

@media (min-width: 992px) {
  .OrderSearch_scrollFrame__qCzGo {
    min-width: 920px;
  }
}

@media (min-width: 1200px) {
  .OrderSearch_scrollFrame__qCzGo {
    min-width: 1110px;
  }
}

.OrderSearch_tableContainer__v5zGP {
  /* width: fit-content; */
  min-width: 100%;
}

.OrderSearch_tableContainer__v5zGP .dhlTable-navigationbar {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderSearch/components/OrderSearch.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,wBAAwB;EACxB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".searchTableFooter > * + * {\n  margin: 0 0 0 6px;\n}\n\n.tableCentering {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.scrollFrame {\n  min-width: 1110px;\n  max-width: 100%;\n  /* width: fit-content; */\n  width: -moz-fit-content;\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n.textRight {\n  text-align: right;\n}\n\n@media (min-width: 768px) {\n  .scrollFrame {\n    min-width: 680px;\n  }\n}\n\n@media (min-width: 992px) {\n  .scrollFrame {\n    min-width: 920px;\n  }\n}\n\n@media (min-width: 1200px) {\n  .scrollFrame {\n    min-width: 1110px;\n  }\n}\n\n.tableContainer {\n  /* width: fit-content; */\n  min-width: 100%;\n}\n\n.tableContainer :global(.dhlTable-navigationbar) {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchTableFooter": `OrderSearch_searchTableFooter__vzEIr`,
	"tableCentering": `OrderSearch_tableCentering__6JVy8`,
	"scrollFrame": `OrderSearch_scrollFrame__qCzGo`,
	"textRight": `OrderSearch_textRight__DhchK`,
	"tableContainer": `OrderSearch_tableContainer__v5zGP`
};
export default ___CSS_LOADER_EXPORT___;
