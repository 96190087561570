// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.journal-input_rangeSeparator__OLTpp {
  position: absolute;
  margin: 10px 0 0 -22px;
}

div.journal-input_rangeNumber__SOvrB {
  width: 60%;
}

.journal-input_errorMessage__JkWt8 {
  line-height: 1.25;
  margin-bottom: 10px;
}

.journal-input_alignItemCenter__upJdN{
  align-items: center;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/press/components/JournalInput/journal-input.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".rangeSeparator {\n  position: absolute;\n  margin: 10px 0 0 -22px;\n}\n\ndiv.rangeNumber {\n  width: 60%;\n}\n\n.errorMessage {\n  line-height: 1.25;\n  margin-bottom: 10px;\n}\n\n.alignItemCenter{\n  align-items: center;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeSeparator": `journal-input_rangeSeparator__OLTpp`,
	"rangeNumber": `journal-input_rangeNumber__SOvrB`,
	"errorMessage": `journal-input_errorMessage__JkWt8`,
	"alignItemCenter": `journal-input_alignItemCenter__upJdN`
};
export default ___CSS_LOADER_EXPORT___;
