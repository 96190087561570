// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Headline_h1__Ycy15, .Headline_h2__ZHS4T, .Headline_h3__FevK-, .Headline_h4__AoVPB, .Headline_h5__Cnaud, .Headline_h6__mYBke {
  font-weight: 600;
  line-height: 1.125;
  font-family: Delivery, Verdana, sans-serif;
  margin-bottom: 16px;
  color: #323232;
}

.Headline_h5__Cnaud, .Headline_h6__mYBke {
  margin-bottom: 8px;
}

.Headline_h1__Ycy15 {
  font-size: 49px;
}

.Headline_h2__ZHS4T {
  font-size: 39px;
}

.Headline_h3__FevK- {
  font-size: 31px;
}

.Headline_h4__AoVPB {
  font-size: 25px;
}

.Headline_h5__Cnaud {
  font-size: 20px;
}

.Headline_h6__mYBke {
  font-size: 16px;
}

@media (max-width: 992px) {
  .Headline_h1__Ycy15 {
    font-size: 39px;
  }

  .Headline_h2__ZHS4T {
    font-size: 31px;
  }

  .Headline_h3__FevK- {
    font-size: 25px;
  }

  .Headline_h5__Cnaud {
    font-size: 16px;
  }
}

.Headline_center__s119L {
  text-align: center;
}

.Headline_start__n-CP4 {
  text-align: start;
}

.Headline_end__VI3ZX {
  text-align: end;
}

.Headline_red__HIYqd {
  color: #D40511;
}



`, "",{"version":3,"sources":["webpack://./src/common/styles/Headline.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".h1, .h2, .h3, .h4, .h5, .h6 {\n  font-weight: 600;\n  line-height: 1.125;\n  font-family: Delivery, Verdana, sans-serif;\n  margin-bottom: 16px;\n  color: #323232;\n}\n\n.h5, .h6 {\n  margin-bottom: 8px;\n}\n\n.h1 {\n  font-size: 49px;\n}\n\n.h2 {\n  font-size: 39px;\n}\n\n.h3 {\n  font-size: 31px;\n}\n\n.h4 {\n  font-size: 25px;\n}\n\n.h5 {\n  font-size: 20px;\n}\n\n.h6 {\n  font-size: 16px;\n}\n\n@media (max-width: 992px) {\n  .h1 {\n    font-size: 39px;\n  }\n\n  .h2 {\n    font-size: 31px;\n  }\n\n  .h3 {\n    font-size: 25px;\n  }\n\n  .h5 {\n    font-size: 16px;\n  }\n}\n\n.center {\n  text-align: center;\n}\n\n.start {\n  text-align: start;\n}\n\n.end {\n  text-align: end;\n}\n\n.red {\n  color: #D40511;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1": `Headline_h1__Ycy15`,
	"h2": `Headline_h2__ZHS4T`,
	"h3": `Headline_h3__FevK-`,
	"h4": `Headline_h4__AoVPB`,
	"h5": `Headline_h5__Cnaud`,
	"h6": `Headline_h6__mYBke`,
	"center": `Headline_center__s119L`,
	"start": `Headline_start__n-CP4`,
	"end": `Headline_end__VI3ZX`,
	"red": `Headline_red__HIYqd`
};
export default ___CSS_LOADER_EXPORT___;
