// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customerSection_infoForTooltip__Y3Mp7 {
  line-height: 1.1;
}

.customerSection_infoForTooltip__Y3Mp7::before {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/CustomerSection/customerSection.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".infoForTooltip {\n  line-height: 1.1;\n}\n\n.infoForTooltip::before {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoForTooltip": `customerSection_infoForTooltip__Y3Mp7`
};
export default ___CSS_LOADER_EXPORT___;
