import i18n from 'i18n';
import React from 'react';

import { CustomerNameTO } from 'generated';
import { ColumnConfig, ContentAlignment } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import {
  customRendererForOrderNumber,
  customRendererForOrderType,
  customRendererForFrankierung,
  customRendererForProduct,
  statusRenderer,
  customRendererForOrderValue,
  customRendererForQuantity,
  customRendererForIcons,
  customRendererForProcedure,
  customRendererForPackagingType,
  customRendererForPackagingQty
} from './functions/customRendererFunctions';
import styles from './AMTable.module.css';

const customerRenderer = (value: CustomerNameTO): string => (value?.name ? `${value.customerId} ${value.name}` : value?.customerId || '');

const translate = (key: string) => i18n.t('orderTable.column.config.' + key);

export const defaultSimpleCol: ColumnConfig[] = [
  {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    filterable: true,
    title: translate('orderNumber'),
    customRenderer: customRendererForOrderNumber,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    name: 'orderType',
    property: 'data.orderType',
    sortable: true,
    filterable: true,
    title: translate('orderType'),
    customRenderer: customRendererForOrderType,
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedTextAndSmaller,
    movable: true,
    hidden: false
  },
  {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    filterable: true,
    title: translate('actionName'),
    type: ColumnType.COMMON,
    tdClassName: styles.tdColumnWithWrappedForOrderLabel,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    name: 'product',
    property: 'data.product',
    sortable: true,
    filterable: true,
    title: translate('product'),
    customRenderer: customRendererForProduct,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForQuantity,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    name: 'orderValue',
    property: 'data.orderValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    hidden: true,
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'deliverer',
    property: 'data.submitter',
    sortable: true,
    filterable: true,
    title: translate('deliverer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'payer',
    property: 'data.payer',
    sortable: true,
    filterable: true,
    title: translate('payer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'producer',
    property: 'data.producer',
    sortable: true,
    filterable: true,
    title: translate('manufacturer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'beneficiary',
    property: 'data.beneficiary',
    sortable: true,
    filterable: true,
    title: translate('authorizedPerson'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingType',
    property: 'data.packagingType',
    sortable: true,
    filterable: true,
    title: translate('packagingType'),
    customRenderer: customRendererForPackagingType,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingQty',
    property: 'data.packagingQty',
    sortable: true,
    filterable: false,
    title: translate('packagingQty'),
    customRenderer: customRendererForPackagingQty,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'frankierung',
    property: 'data.frankierung',
    sortable: true,
    filterable: true,
    title: translate('postage'),
    customRenderer: customRendererForFrankierung,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'procedure',
    property: 'data.procedure',
    sortable: true,
    filterable: true,
    title: translate('procedure'),
    customRenderer: customRendererForProcedure,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'participation',
    property: 'data.participation',
    sortable: true,
    filterable: true,
    title: translate('participation'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    hidden: true,
    name: 'paymentClearingNumber',
    property: 'data.paymentClearingNumber',
    sortable: true,
    filterable: true,
    title: translate('deliveryReceiptNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'zkz',
    property: 'data.zkz',
    sortable: true,
    filterable: true,
    title: translate('newspaperID'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'newspaperNumber',
    property: 'data.newspaperNumber',
    sortable: true,
    filterable: true,
    title: translate('newspaperNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    filterable: true,
    title: translate('customerOrderNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'settlementNumber',
    property: 'data.settlementNumber',
    sortable: true,
    filterable: true,
    title: translate('billingNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: false,
    sortable: false,
    movable: false,
    name: null,
    property: 'iconsTableCell',
    title: '',
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForIcons,
    hidden: false
  },
  {
    hideable: false,
    icon: 'arrow-down',
    sortable: false,
    movable: false,
    name: null,
    property: 'treeExpand',
    title: null,
    type: ColumnType.TREE_EXPAND,
    hidden: false
  }
];
export const defaultPressCol: ColumnConfig[] = [
  {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    filterable: true,
    title: translate('orderNumber'),
    customRenderer: customRendererForOrderNumber,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    hideable: false,
    hidden: false,
    name: 'zkz',
    property: 'data.zkz',
    sortable: true,
    filterable: true,
    title: translate('newspaperID'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: false,
    name: 'newspaperNumber',
    property: 'data.newspaperNumber',
    sortable: true,
    filterable: true,
    title: translate('newspaperNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    name: 'product',
    property: 'data.product',
    sortable: true,
    filterable: true,
    title: translate('product'),
    customRenderer: customRendererForProduct,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForQuantity,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    name: 'orderValue',
    property: 'data.orderValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    hidden: true,
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'deliverer',
    property: 'data.submitter',
    sortable: true,
    filterable: true,
    title: translate('deliverer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'payer',
    property: 'data.payer',
    sortable: true,
    filterable: true,
    title: translate('payer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'producer',
    property: 'data.producer',
    sortable: true,
    filterable: true,
    title: translate('manufacturer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'beneficiary',
    property: 'data.beneficiary',
    sortable: true,
    filterable: true,
    title: translate('authorizedPerson'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingType',
    property: 'data.packagingType',
    sortable: true,
    filterable: true,
    title: translate('packagingType'),
    customRenderer: customRendererForPackagingType,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingQty',
    property: 'data.packagingQty',
    sortable: true,
    filterable: false,
    title: translate('packagingQty'),
    customRenderer: customRendererForPackagingQty,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    filterable: true,
    title: translate('customerOrderNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'settlementNumber',
    property: 'data.settlementNumber',
    sortable: true,
    filterable: true,
    title: translate('billingNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: false,
    sortable: false,
    movable: false,
    name: null,
    property: 'iconsTableCell',
    customRenderer: customRendererForIcons,
    title: '',
    type: ColumnType.CUSTOM_CELL
  },
  {
    hideable: false,
    icon: 'arrow-down',
    sortable: false,
    movable: false,
    name: null,
    property: 'treeExpand',
    title: null,
    type: ColumnType.TREE_EXPAND,
    disableMinWidth: true
  }
];
export const defaultAdvanceCol: ColumnConfig[] = [
  {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    filterable: true,
    title: translate('orderNumber'),
    customRenderer: customRendererForOrderNumber,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    name: 'orderType',
    property: 'data.orderType',
    sortable: true,
    filterable: true,
    title: translate('orderType'),
    customRenderer: customRendererForOrderType,
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedTextAndSmaller,
    movable: true,
    hidden: false
  },
  {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    filterable: true,
    title: translate('actionName'),
    type: ColumnType.COMMON,
    tdClassName: styles.tdColumnWithWrappedForOrderLabel,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    name: 'product',
    property: 'data.product',
    sortable: true,
    filterable: true,
    title: translate('product'),
    customRenderer: customRendererForProduct,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForQuantity,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    name: 'orderValue',
    property: 'data.orderValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL,
    movable: true,
    hidden: false
  },
  {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM,
    movable: true,
    hidden: false
  },
  {
    hideable: true,
    hidden: true,
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'deliverer',
    property: 'data.submitter',
    sortable: true,
    filterable: true,
    title: translate('deliverer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'payer',
    property: 'data.payer',
    sortable: true,
    filterable: true,
    title: translate('payer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'producer',
    property: 'data.producer',
    sortable: true,
    filterable: true,
    title: translate('manufacturer'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'beneficiary',
    property: 'data.beneficiary',
    sortable: true,
    filterable: true,
    title: translate('authorizedPerson'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    className: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingType',
    property: 'data.packagingType',
    sortable: true,
    filterable: true,
    title: translate('packagingType'),
    customRenderer: customRendererForPackagingType,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'packagingQty',
    property: 'data.packagingQty',
    sortable: true,
    filterable: false,
    title: translate('packagingQty'),
    customRenderer: customRendererForPackagingQty,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'frankierung',
    property: 'data.frankierung',
    sortable: true,
    filterable: true,
    title: translate('postage'),
    customRenderer: customRendererForFrankierung,
    type: ColumnType.CUSTOM_CELL,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'procedure',
    property: 'data.procedure',
    sortable: true,
    filterable: true,
    title: translate('procedure'),
    customRenderer: customRendererForProcedure,
    type: ColumnType.CUSTOM_CELL,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'participation',
    property: 'data.participation',
    sortable: true,
    filterable: true,
    title: translate('participation'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    hidden: true,
    name: 'paymentClearingNumber',
    property: 'data.paymentClearingNumber',
    sortable: true,
    filterable: true,
    title: translate('deliveryReceiptNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'zkz',
    property: 'data.zkz',
    sortable: true,
    filterable: true,
    title: translate('newspaperID'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'newspaperNumber',
    property: 'data.newspaperNumber',
    sortable: true,
    filterable: true,
    title: translate('newspaperNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    filterable: true,
    title: translate('customerOrderNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: true,
    hidden: true,
    name: 'settlementNumber',
    property: 'data.settlementNumber',
    sortable: true,
    filterable: true,
    title: translate('billingNumber'),
    type: ColumnType.COMMON,
    movable: true
  },
  {
    hideable: false,
    sortable: false,
    movable: false,
    name: null,
    property: 'iconsTableCell',
    title: '',
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForIcons,
    hidden: false
  },
  {
    hideable: false,
    icon: 'arrow-down',
    sortable: false,
    movable: false,
    name: null,
    property: 'treeExpand',
    title: null,
    type: ColumnType.TREE_EXPAND,
    hidden: false
  }
];
