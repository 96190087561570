import React, { FC, useEffect, useRef } from 'react';
import { useController, ValidationRule } from 'react-hook-form';
import { Input } from 'common/components/Input';
import { useBoolean } from 'common/hooks/useBoolean';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import { Menu } from 'common/components/ContextMenu';
import contextClasses from 'common/components/ContextMenu/context-menu.module.css';
import classes from 'order/productGroups/common/components/atom/CustomerInput/customer-input.module.css';
import dropdownStyles from 'common/components/AMDHLInputWithProposals/AMDHLInputWithProposals.module.css';

import classNames from 'classnames';
import { GenericRecommendation, useGenericRecommendations } from 'common/hooks/useGenericRecommendations';

export interface RecommendationInputProps {
  required: boolean;
  label: string;
  errMsg?: string;
  name: string;
  keyValue: string;
  pattern?: ValidationRule<RegExp>;
  defaultValue?: string;
  controlledErrorState?: (errorState: boolean) => void;
  disabled?: boolean;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  maxLength?: number;
  dataTestId?: string;
  parentChangeHandler?: (machineCode: string) => void;
}

export type RecommendationInputAttribute = {
  key: string;
  value: string;
  meta?: string;
  id?: string;
};

export const RecommendationInput: FC<RecommendationInputProps> = (props) => {
  const { recommendations, load: getRecommendations, delete: removeRecommendation } = useGenericRecommendations(props.keyValue);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isShowList, setIsShowList] = useBoolean(false);
  useClickOutside([wrapperRef], () => setIsShowList.off());

  useKeyPress([wrapperRef], ['Enter', 'Escape', 'Tab'], (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    } else {
      setIsShowList.off();
    }
  });

  const { field } = useController({
    name: props.name,
    rules: {
      pattern: props.pattern
    },
    defaultValue: props.defaultValue
  });

  useEffect(() => {
    isShowList && getRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowList]);

  const icon = !props.disabled && (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={() => {
        field.onChange('');
        props.parentChangeHandler?.('');
      }}
      cursor={'pointer'}
      data-name={'orderSearchFilterAttrSelectableInput-function'}
      data-testid={'clear-function'}
    >
      <polygon
        fill="#d40511"
        fillRule="evenodd"
        points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
      ></polygon>
    </svg>
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChangeHandler) {
      props.onChangeHandler(e);
    }

    const { on, off } = setIsShowList;
    const value = e.target.value;
    value.length ? off() : on();
  };

  return (
    <>
      <Input
        {...field}
        onChange={handleInputChange}
        name={props.name}
        required={props.required}
        maxLength={props.maxLength}
        data-testid={props.dataTestId}
        placeholder={props.label}
        type="text"
        icon={icon}
        onClick={() => setIsShowList.on()}
        disabled={props.disabled}
        autoComplete="off"
        error={props.error}
      />
      {!!recommendations.length && (
        <div className={contextClasses.anchor} ref={wrapperRef}>
          <Menu isOpen={isShowList} data-testid={props.name + '-menu'} className={classes.menu}>
            <p className={classNames(contextClasses.item, contextClasses.noHover)}>Last Entry</p>
            {recommendations.map((recommendation: GenericRecommendation, i: number) => (
              <div className={classNames(contextClasses.item, contextClasses.noHover)} key={i}>
                <button
                  onClick={() => {
                    field.onChange(recommendation.value);
                    props.parentChangeHandler?.(recommendation.value);
                    field.onBlur();
                    setIsShowList.off();
                    props.setError?.(false);
                  }}
                  className={dropdownStyles.dropDownValue}
                >
                  {recommendation.value}
                </button>
                <button className={dropdownStyles.delete} name={'amSearchDropdown-remove-' + i} data-testid={'amSearchDropdown-remove-' + i}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await removeRecommendation(recommendation);
                    }}
                  >
                    <polygon
                      fill="#d40511"
                      fillRule="evenodd"
                      points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
                    ></polygon>
                  </svg>
                </button>
              </div>
            ))}
          </Menu>
        </div>
      )}

      {props.error && (
        <div className="fieldNote error" data-testid={field.name + '-error'}>
          {props.errMsg}
        </div>
      )}
    </>
  );
};
