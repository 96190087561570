import React, { ChangeEvent, useLayoutEffect, useMemo, useRef, useState, useEffect } from 'react';
import { uid } from 'react-uid';
import classNames from 'classnames';

import { HideMenu, MoveMenu, SortMenu } from './column-menu/TableColumnMenu';
import { ColumnConfig } from '../dtos/ColumnConfig';
import { TableHead } from './TableHead';
import { ColumnType } from '../dtos/ColumnType';
import { Headline, Menu } from '../../ContextMenu';
import { Input } from '../../Input';
import { useClickOutside, useKeyPress } from '../../../utils/hooks';
import { useScrollContainerMinHeightBinding } from '../hooks/useScrollContainerMinHeightBinding';
import { SortOrder } from '../dtos/SortOrder';

import filterIcon from 'assets/search/filter-icon.svg';
import moreIcon from 'assets/search/more-icon.svg';
import sortAscIcon from 'assets/search/asc-icon.svg';
import sortDescIcon from 'assets/search/dec-icon.svg';
import { Sort } from '../dtos/Sort';
import { Filter } from '../dtos/Filter';

import styles from '../table.module.css';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';
import { defaultAdvanceCol, defaultPressCol, defaultSimpleCol } from 'order/orderSearch/components/table/defaultColumns';
import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';

interface Props extends ColumnConfig {
  sortOrder?: SortOrder | null;
  onFilterChange?: (name: string | null, value: string | null, type?: OrderSearchType) => void;
  onMoveChange?: (name: string, direction: 'left' | 'right') => void;
  onSetTableConfiguration?: (config: Record<string, boolean>, columnHistory?: PersistColumn) => void;
  onSortChange?: (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => void;
  onResetSortAndFilters?: () => void;
  language?: string;
  t: (value: string) => string;
  sort?: Sort;
  filter?: Filter;
  contextMenu?: boolean;
  setMenuVisible?: (menuVisible: boolean) => void;
  saveColumnWithMove?: () => void;
  searchType?: OrderSearchType;
  columns: ColumnConfig[];
  setColumnFilter?: (filter: Filter) => void;
  columnFilter?: Filter;
  rows?: OrderTreeItem<OrderReplyTDO>[];
  columnSorting?: Sort;
  setColumnSorting?: (sort: Sort) => void;
  triggerLastSearch?: () => void;
}

export const TableHeadCell = (props: Props) => {
  const interactive = useMemo(() => props.sortable || props.movable || props.hideable, [props.hideable, props.movable, props.sortable]);
  const headerCellRef = useRef<HTMLTableCellElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [inputDefaultValue, setInputDefaultValue] = useState('');
  const [internalHeaders, setInternalHeaders] = useState<ColumnConfig[]>([]);
  const filterInput = useRef<HTMLInputElement>(null);
  const focusRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const customHeader = props.customHeader;
  useLayoutEffect(() => {
    if (menuVisible) {
      setTimeout(() => {
        filterInput.current?.focus();
      }, 0);
    }
    props.setMenuVisible?.(menuVisible);
  }, [menuVisible]);

  useClickOutside([headerCellRef], () => setMenuVisible(false), props.sortable);
  useScrollContainerMinHeightBinding(headerCellRef, focusRef, menuVisible);
  useKeyPress([focusRef], ['Enter', 'Escape', 'Tab'], (e) => {
    if (e.key === 'Enter') {
      setMenuVisible(false);
    } else if (e.key === 'Escape') {
      if (menuVisible) {
        props.onFilterChange?.(props.name || '', null, props.searchType);
        setMenuVisible(false);
      }
    } else if (e.key === 'Tab') {
      if (document.activeElement?.classList.contains('dhlContextmenuMoveRight')) {
        setTimeout(() => {
          filterInput.current?.focus();
        }, 0);
      }
    }
  });

  useEffect(() => {
    if (props.contextMenu === false) {
      setMenuVisible(false);
    }
  }, [menuVisible, props.contextMenu]);

  useEffect(() => {
    setInternalHeaders(props.columns.filter((x) => x.title).map((x) => Object.assign({}, x)));
  }, [props.columns]);

  useEffect(() => {
    if ((props.filter?.name === null && props.filter?.value === null) || props.filter?.name !== props.name) setInputDefaultValue('');
  }, [props.filter]);

  const lastFilterTrigger = () => {
    if (props.searchType) {
      if (props.columnFilter && props.rows?.length) {
        if (props.columnFilter.name === props.name) {
          props.onFilterChange?.(props.columnFilter.name, props.columnFilter.value, props.columnFilter.type);
          setInputDefaultValue(props.columnFilter.value || '');
        }
      }
    }
  };
  const lastSortingTrigger = () => {
    if (props.searchType) {
      if (props.columnSorting && props.rows?.length) {
        if (
          props.columnSorting.name === props.name &&
          (props.columnSorting.order === SortOrder.ASC || props.columnSorting.order === SortOrder.DESC)
        ) {
          props.onSortChange?.(props.columnSorting.name!, props.columnSorting.order!, props.columnSorting.type!, props.columnSorting.searchType);
        }
      }
    }
  };

  useEffect(() => {
    lastFilterTrigger();
    lastSortingTrigger();
    setLoading(true);
  }, [props.searchType, props.columnSorting, props.rows?.length, loading]);

  const saveColumns = (internalHead: ColumnConfig[], searchType?: OrderSearchType): PersistColumn => {
    const updatedList: ColumnConfig[] = [];

    // list2 for updated column
    const list2: ColumnConfig[] = internalHead;

    const columnList = (list: ColumnConfig[]) => {
      for (let i = 0; i < list2.length; i++) {
        list2[i].name === props.name && (list2[i].hidden = true);
      }
      const visibleHeader: ColumnConfig[] = list2.slice().filter(({ hidden }) => !hidden);

      for (let i = 0; i < list.length; i++) {
        let match = false;
        for (let j = 0; j < visibleHeader.length; j++) {
          if (list[i].name === visibleHeader[j].name) {
            match = true;
          }
        }
        // adding list[i] to updatedList if we don't find a match.
        if (!match) {
          updatedList.push(list[i]);
        }
      }
      return updatedList.unshift(...visibleHeader);
    };
    if (searchType === OrderSearchType.SIMPLE) {
      const list: ColumnConfig[] = defaultSimpleCol;
      columnList(list);
    } else if (searchType === OrderSearchType.PRESS) {
      const list: ColumnConfig[] = defaultPressCol;
      columnList(list);
    } else if (searchType === OrderSearchType.ADVANCED) {
      const list: ColumnConfig[] = defaultAdvanceCol;
      columnList(list);
    }
    return { currentType: searchType, columnConfig: updatedList };
  };

  const sortIconType = (() => {
    if (props.filter?.name === props.name && props.filter.value && props.filter.value.length) {
      return filterIcon;
    }
    if (props.sort?.name === props.name) {
      const asc = props.sortOrder === SortOrder.ASC;
      return asc ? sortAscIcon : sortDescIcon;
    }
    return moreIcon;
  })();

  let filterTimeout: NodeJS.Timeout | undefined;

  const debounce = (func: () => void, delay: number) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(func, delay);
  };

  const filterTyped = (e: ChangeEvent<HTMLInputElement>) => {
    setInputDefaultValue(e.currentTarget.value);
    const val = e.currentTarget.value;
    if (!val.length) {
      debounce(() => {
        props.setColumnFilter?.({ name: props.name, value: null, type: props.searchType });
        props.onFilterChange?.(props.name, null, props.searchType);
      }, 500);
    } else {
      debounce(() => {
        props.onFilterChange?.(props.name || '', val, props.searchType);
        if (props.columnFilter?.value !== val) {
          props.setColumnFilter?.({ name: props.name, value: val, type: props.searchType });
        }
        filterInput.current?.focus();
      }, 500);
    }

    setTimeout(() => {
      filterInput.current?.focus();
    }, 0);
  };

  if (customHeader) {
    return <TableHead key={uid(props)}>{customHeader?.({ ...props }, { language: props.language })}</TableHead>;
  }

  if (!interactive) {
    return (
      <TableHead key={uid(props)} className={props.innerClassName}>
        {props.title}
      </TableHead>
    );
  }

  const handleOnMove = (name: string, direction: 'left' | 'right') => props.onMoveChange?.(name, direction);

  // const type: TableCellType = 'type' in props.type || 'text' : 'text';
  return (
    <TableHead
      key={uid(props)}
      onClick={() => setMenuVisible(true)}
      className={props.innerClassName}
      ref={headerCellRef}
      interactive={true}
      data-testid={`table-head-cell-${props.name}`}
      innerClassName={styles.positionUnset}
    >
      <span className="label-text">{props.title}</span>
      <img className={classNames(styles.filterIcons)} src={sortIconType} alt={'Icon'} />
      <div className={`${styles.anchorContextmenu} dhlTableheaderCell-anchorContextmenu`}>
        <Menu isOpen={menuVisible} ref={focusRef}>
          {props.filterable && (
            <div className={classNames(styles.ContextMenuSearchWrapper)}>
              <Input
                wrapperProps={{ className: 'my-1' }}
                placeholder={props.t('contextmenu.textFilter')}
                name={'filter-' + props.name}
                onChange={filterTyped}
                ref={filterInput}
                value={inputDefaultValue || ''}
              />
            </div>
          )}
          {props.sortable && (
            <SortMenu
              t={props.t}
              propertiesName={props.name || ''}
              type={props.type}
              hidemenu={() => setMenuVisible(false)}
              name={props.name || ''}
              onSortByName={props.onSortChange}
              searchType={props.searchType}
              onResetSortAndFilters={props.onResetSortAndFilters}
              setColumnSorting={props.setColumnSorting}
            />
          )}
          {(props.hideable || props.movable) && (
            <>
              <Headline>{props.t('contextmenu.optionsHeading')}</Headline>
              {props.hideable && (
                <HideMenu
                  t={props.t}
                  propertiesName={props.name || ''}
                  hidemenu={() => {
                    if (props.name) {
                      const historyColumn = saveColumns(internalHeaders, props.searchType);
                      props.onSetTableConfiguration?.({ [props.name]: true }, historyColumn);
                    }
                    setMenuVisible(false);
                    // console.log('hide', menuVisible);
                  }}
                  inputClear={() => {
                    setInputDefaultValue('');
                    props.onFilterChange?.(props.name, null, props.searchType);
                  }}
                />
              )}
              {props.movable && (
                <MoveMenu
                  t={props.t}
                  propertiesName={props.name || ''}
                  onMove={handleOnMove}
                  name={props.name || ''}
                  hidemenu={() => setMenuVisible(false)}
                  saveColumnWithMove={props.saveColumnWithMove}
                  visibleColumn={internalHeaders.filter((a) => a.hidden === false)}
                />
              )}
            </>
          )}
        </Menu>
      </div>
    </TableHead>
  );
};
