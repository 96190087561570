import { DHLIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { Input } from 'common/components/Input';
import buttonClasses from 'common/styles/common.module.css';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import classes from './am-dhl-dropdown-button.module.css';

export type AMDHLDropdownButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  wrapperClassName?: string;
  error?: boolean;
};

export const AMDHLDropdownButton = forwardRef<HTMLInputElement, AMDHLDropdownButtonProps>(
  ({ wrapperClassName: className, type = 'button', ...props }, ref) => (
    <Input
      {...props}
      className={classNames(props.className, {
        [buttonClasses.textOverflow]: type === 'button'
      })}
      ref={ref}
      wrapperProps={{ className }}
      icon={
        <DHLIcon
          name={
            (props as any)?.['data-testid'] ? `${(props as any)?.['data-testid']}-dropdown-button` : 'orderSearchFilterAttrSelectableInput-function'
          }
          icon="arrow-down"
          className={classes.arrowDown}
        />
      }
      type={type}
      autoComplete="off"
    />
  )
);

AMDHLDropdownButton.displayName = 'AMDHLDropdownButton';
