// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"12\" viewBox=\"0 0 24 24\"><polygon fill=\"%23fff\" fill-rule=\"evenodd\" points=\"24 5.2 11.2 18 8 21.2 4.8 18 0 13.2 3.2 10 8 14.8 20.8 2 24 5.2\"></polygon></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_alignCenter__vuxIV{
    display: flex;
    align-items: center;
}
.Checkbox_cursorNotAllowed__HWlZq{
    cursor: not-allowed !important;
}
.Checkbox_checkboxWrapper__NUD0n label{
    margin-bottom: 0;
    cursor: pointer;
}
.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 1px solid rgba(50, 50, 50, 0.4);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.Checkbox_checkboxWrapper__NUD0n input.Checkbox_checked__DL9vH {
    background-color: #D40511;
    position: relative;
    border-color: #D40511;
}
.Checkbox_checkboxWrapper__NUD0n input.Checkbox_checked__DL9vH::before {
    /* font-family: 'icomoon' !important;
    content: "\\e90c"; */
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 4px;
    top: 4px;

}

.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"]:disabled {
    border-color: #3232321a;
    background-color: #3232321a;
}

.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"]:disabled{
    color: #3232321a;
}
.Checkbox_centerItem__fa3UR{
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/common/components/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,8BAA8B;AAClC;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI;uBACmB;IACnB,gDAA+P;IAC/P,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,QAAQ;;AAEZ;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".alignCenter{\n    display: flex;\n    align-items: center;\n}\n.cursorNotAllowed{\n    cursor: not-allowed !important;\n}\n.checkboxWrapper label{\n    margin-bottom: 0;\n    cursor: pointer;\n}\n.checkboxWrapper input[type=\"checkbox\"] {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 22px;\n    height: 22px;\n    border-radius: 0.15em;\n    margin-right: 0.5em;\n    border: 1px solid rgba(50, 50, 50, 0.4);\n    border-radius: 4px;\n    outline: none;\n    cursor: pointer;\n}\n.checkboxWrapper input.checked {\n    background-color: #D40511;\n    position: relative;\n    border-color: #D40511;\n}\n.checkboxWrapper input.checked::before {\n    /* font-family: 'icomoon' !important;\n    content: \"\\e90c\"; */\n    content: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"12\" viewBox=\"0 0 24 24\"><polygon fill=\"%23fff\" fill-rule=\"evenodd\" points=\"24 5.2 11.2 18 8 21.2 4.8 18 0 13.2 3.2 10 8 14.8 20.8 2 24 5.2\"></polygon></svg>');\n    font-size: 12px;\n    color: #fff;\n    position: absolute;\n    right: 4px;\n    top: 4px;\n\n}\n\n.checkboxWrapper input[type=\"checkbox\"]:disabled {\n    border-color: #3232321a;\n    background-color: #3232321a;\n}\n\n.checkboxWrapper input[type=\"checkbox\"]:disabled{\n    color: #3232321a;\n}\n.centerItem{\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignCenter": `Checkbox_alignCenter__vuxIV`,
	"cursorNotAllowed": `Checkbox_cursorNotAllowed__HWlZq`,
	"checkboxWrapper": `Checkbox_checkboxWrapper__NUD0n`,
	"checked": `Checkbox_checked__DL9vH`,
	"centerItem": `Checkbox_centerItem__fa3UR`
};
export default ___CSS_LOADER_EXPORT___;
