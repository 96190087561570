// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.dhlSelectManyShuttle-input-inner li{
    white-space: pre;
}
ul.dhlSelectManyShuttle-input-inner{
    height:200px;
}
.dhlDialogModal-content{
    max-width: 900px;
    margin:0 auto;
}`, "",{"version":3,"sources":["webpack://./src/common/styles/dhlComponentCustomization.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":["ul.dhlSelectManyShuttle-input-inner li{\n    white-space: pre;\n}\nul.dhlSelectManyShuttle-input-inner{\n    height:200px;\n}\n.dhlDialogModal-content{\n    max-width: 900px;\n    margin:0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
