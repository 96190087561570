const customWordBreakForPDF = (text?: string, charPerLine?: number) => {
  if (!text || !charPerLine) {
    return '-';
  }

  const splitedStringArray: string[] = [];

  let start = 0;
  while (start < text.length) {
    let end = start + charPerLine;

    // If end of substring is in middle of word and there's more text ahead
    if (end < text.length && text[end] !== ' ' && text[end - 1] !== ' ') {
      // Move end back to previous space
      while (end > start && text[end] !== ' ') {
        end--;
      }

      // If single word is longer than charPerLine, force a split
      if (end === start) {
        end = start + charPerLine;
      }
    }

    splitedStringArray.push(text.substring(start, end));
    start = end;
  }

  return splitedStringArray;
};

export default customWordBreakForPDF;
