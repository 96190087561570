import { Party } from '../dtos/GetOrder';
import { OrderCustomerRole } from '../dtos/OrderCustomerRole';

export const getPayerProcedureOrParticipationValue = (parties: Party[], procedure?: boolean): string | undefined => {
  const payer = parties.find((p) => p.role === OrderCustomerRole.Payer);

  if (payer) {
    const customerId = payer.customerId;
    if (procedure) {
      return `${customerId.charAt(10)}${customerId.charAt(11)}`;
    }
    return `${customerId.charAt(12)}${customerId.charAt(13)}`;
  }

  return undefined;
};
