import { AllocatedOrder } from '../../../../../common/context/order/dtos/AllocatedOrder';

export const extractOrdersToPrepare = (orders: AllocatedOrder[], meta?: Record<string, any>): AllocatedOrder[] => {
  const pickupDate = {
    day: new Date(meta?.pickUpDate).getDate(),
    month: new Date(meta?.pickUpDate).getMonth(),
    year: new Date(meta?.pickUpDate).getFullYear()
  };
  return orders.filter((order) => {
    const pickup = order.data.orderDetail?.pickup;
    const firstInductionDate = order.data.firstInductionDate || '';
    return pickup || isDateMismatch(pickupDate, firstInductionDate);
  });
};

const isDateMismatch = (pickupDate: { day: number; month: number; year: number }, firstInductionDate: string) => {
  const date = new Date(firstInductionDate).getDate();
  const month = new Date(firstInductionDate).getMonth();
  const year = new Date(firstInductionDate).getFullYear();
  return date !== pickupDate.day || month !== pickupDate.month || year !== pickupDate.year;
};
