// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__zr1wm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
}

.App_app__zr1wm * {
  box-sizing: border-box;
  font-family: Delivery, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App_app__zr1wm a,
.App_app__zr1wm a:hover {
  color: #D40511;
}

.App_app__zr1wm .wrapper {
  padding: 5px 20px;
}

.App_app__zr1wm .wrapper fieldset {
  margin: 20px 0;
}

.App_app__zr1wm input::-webkit-outer-spin-button,
.App_app__zr1wm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App_app__zr1wm input[type=number] {
  -moz-appearance: textfield;
}

.App_app__zr1wm button {
  cursor: pointer;
}

.App_rightAlign__pLvjj {
  display: flex;
  justify-content: right;
}`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,0CAA0C;EAC1C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".app {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  align-items: stretch;\n}\n\n.app * {\n  box-sizing: border-box;\n  font-family: Delivery, Verdana, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.app a,\n.app a:hover {\n  color: #D40511;\n}\n\n.app :global(.wrapper) {\n  padding: 5px 20px;\n}\n\n.app :global(.wrapper) fieldset {\n  margin: 20px 0;\n}\n\n.app input::-webkit-outer-spin-button,\n.app input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.app input[type=number] {\n  -moz-appearance: textfield;\n}\n\n.app button {\n  cursor: pointer;\n}\n\n.rightAlign {\n  display: flex;\n  justify-content: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__zr1wm`,
	"rightAlign": `App_rightAlign__pLvjj`
};
export default ___CSS_LOADER_EXPORT___;
