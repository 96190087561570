import { defaultReducerState, TableReducerState } from '../reducers/table';
import { OrderReplyTDO, OrderTreeItem } from '../../../services/OrderService';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { OrderSearchType } from '../../search/dtos/OrderSearchType';

export interface TableContextValues extends TableReducerState {
  resetSortAndFilters: () => void;
  setColumnConfig: (columnConfig: any) => void;
  setColumnMove: (name: string, direction: 'left' | 'right') => void;
  setFilter: (name: string | null, value: string | null, type?: OrderSearchType) => void;
  setLoadingState: (state: TableLoadingState) => void;
  setPage: (number: number) => void;
  setPageSize: (size: number) => void;
  setRows: (rows: OrderTreeItem<OrderReplyTDO>[]) => void;
  setSorting: (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => void;
}

export const defaultTableContextValues: TableContextValues = {
  ...defaultReducerState,
  resetSortAndFilters: () => undefined,
  setColumnConfig: () => undefined,
  setColumnMove: () => undefined,
  setFilter: () => undefined,
  setLoadingState: () => undefined,
  setPage: () => undefined,
  setPageSize: () => undefined,
  setRows: () => undefined,
  setSorting: () => undefined
};
