import { createApi, Endpoint, responseOrUndefined } from 'common/utils/apiUtils';
import { AllowedMethodsOfDenotingPrepaymentTO, MasterdataResourceApiFactory, ProductTO } from 'generated';

export type Product = ProductTO;

export type MethodOfDenotingPrepayment = AllowedMethodsOfDenotingPrepaymentTO;

const productApi = createApi(MasterdataResourceApiFactory);

export type requestBaseProductsForGroupPrm = {
  productGroup: string;
  serviceType: string;
  isPartialServiceCapable?: boolean;
  hierarchyDestination?: string;
  hierarchyProductGroup?: string;
};

export const requestBaseProductsForGroup: Endpoint<requestBaseProductsForGroupPrm, Product[]> = async ({
  productGroup,
  serviceType,
  isPartialServiceCapable,
  hierarchyDestination,
  hierarchyProductGroup
}) =>
  responseOrUndefined(
    productApi.getProducts(undefined, productGroup, serviceType, undefined, isPartialServiceCapable, hierarchyDestination, hierarchyProductGroup)
  );

export const getDependentProducts: Endpoint<string, Product[]> = async (productGroup: string) =>
  responseOrUndefined(productApi.getDependentProducts(productGroup));

export const requestMethodOfDenotingPrepayment: Endpoint<string, MethodOfDenotingPrepayment[]> = async (productNumber) =>
  responseOrUndefined(productApi.getDenotingPrepaymentsForProduct(productNumber));
