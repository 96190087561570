import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import styles from 'order/productGroups/additionalOrders/workFlowSteps/step3/components/table/table-container.module.css';
import { Occurrence } from '../../../../../common/dtos/Occurrence';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomAllOrdersPartialServiceRelevantCell = (props: Props): JSX.Element => {
  const { orderReferences } = useOrderContext();
  const [allAllocated, setAllAllocated] = useState(true);
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });

  useLayoutEffect(() => {
    const ref = orderReferences?.find((o) => o.orderId === props.row.data?.orderNumber);

    if (ref) {
      setAllAllocated(ref.includeTotalQty === Occurrence.ALL);
    }
  }, [orderReferences]);

  return (
    <div className={styles.flex}>
      <div className={cn(styles.icon, { [styles.green]: allAllocated, [styles.yellow]: !allAllocated })}>
        <span>i</span>
      </div>
      {allAllocated ? t('cell.completelyAssigned') : t('cell.partiallyAssigned')}
    </div>
  );
};

export const customAllOrdersPartialServiceRelevantCellRenderer = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomAllOrdersPartialServiceRelevantCell value={value} row={row} column={column} />;
};
