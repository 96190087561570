import { Text, View } from '@react-pdf/renderer';
import { styles } from '../pdf/palettenleitzettelStyles';
import React from 'react';
import moment from 'moment';
import { CustomerInfoWithAddressTO } from 'generated';

export const compCustomerAddress = (customerType: string, customerInfo?: CustomerInfoWithAddressTO) => {
  return (
    <>
      <Text style={styles.textLabel}>{`${customerType}:`}</Text>
      <Text style={styles.textAdress}>{customerInfo?.name}</Text>
      <Text style={styles.textAdress}>{`${customerInfo?.address?.street} ${customerInfo?.address?.streetNumber}`}</Text>
      <Text style={styles.textAdress}>{`${customerInfo?.address?.postCode} ${customerInfo?.address?.city}`}</Text>
    </>
  );
};

export const compCustomerNumber = (customerType: string, customerId?: string, centered: boolean = true) => {
  return (
    <>
      <Text style={styles.textLabel}>{`Kundennummer ${customerType}:`}</Text>
      <View style={styles.fieldContainerStretched}>
        <Text style={styles.textValue}>{customerId || ''}</Text>
      </View>
    </>
  );
};

export const compProduct = (productName: string, zipCode?: string, fontSize?: boolean) => {
  return (
    <>
      <Text style={fontSize ? styles.textProductMedium : styles.textProduct}>{productName}</Text>
      <Text style={styles.textTarget}>{['de'].includes((zipCode || '').toLowerCase()) ? '0-9' : zipCode || ''}</Text>
    </>
  );
};

export const compPickupDate = (pickupDate?: Date, aligRight?: boolean) => {
  return (
    <>
      <Text style={styles.textLabel}>Einlieferungsdatum:</Text>
      <View style={[styles.fieldContainerStretchedCenter, { alignItems: aligRight ? 'flex-end' : 'center', paddingRight: '1mm' }]}>
        <Text style={styles.textDate}>{pickupDate ? moment(pickupDate).format('DD.MM.YYYY') : ''}</Text>
      </View>
    </>
  );
};

export const compCustomerOrderId = (customerOrderId?: string) => {
  return (
    <>
      <Text style={styles.textLabel}>Kundenauftragsnummer:</Text>
      <View style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Text style={styles.textValue}>{customerOrderId || ''}</Text>
      </View>
    </>
  );
};

export const compOrderId = (customerOrderId?: string) => {
  return (
    <>
      <Text style={styles.textLabel}>Auftragsnummer:</Text>
      <View style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Text style={styles.textValue}>{customerOrderId || ''}</Text>
      </View>
    </>
  );
};

export const compWeight = (weightValue?: number, weightUnit?: string, centered: boolean = false) => {
  return (
    <>
      <Text style={styles.textLabel}>Gewicht der Palette brutto:</Text>
      <View style={styles.fieldContainerStretchedCenter}>
        <Text style={[styles.textValueBig]}>
          {weightValue && weightValue > 0.0 ? `${weightValue.toLocaleString(undefined, { useGrouping: true })} ${weightUnit || ''}` : ''}
        </Text>
      </View>
    </>
  );
};

export const renderDate = (pickupDate?: Date, aligRight?: boolean) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingRight: '1mm'
      }}
    >
      <Text style={styles.textLabel}>Einlieferungsdatum:</Text>
      <Text style={(styles.textDate, { fontSize: '26pt', fontWeight: 'bold' })}>{pickupDate ? moment(pickupDate).format('DD.MM.YYYY') : ''}</Text>
    </View>
  );
};

export const renderValueWithLabel = (value?: number | string, label?: string, weightUnit?: string, language?: string) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1mm',
        marginBottom: '1.25mm'
      }}
    >
      <Text style={styles.textLabel}>{`${label}:`}</Text>
      <Text style={[{ fontWeight: 'light', fontSize: '12pt' }]}>
        {value ? `${value.toLocaleString(language, { useGrouping: true })}${weightUnit ? ' ' + weightUnit : ''}` : ''}
      </Text>
    </View>
  );
};
