// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiList_multiList__0p6-- {
  border: 1px solid rgba(50, 50, 50, 0.3);
  max-height: 210px;
  background: white;
  margin-top: 12px;
  border-radius: 3px;
  max-width: 100%;
  padding: 0;
}

.MultiList_item__ECyZb {
  background: #FFFFFF;
  padding: 0 10px;
  font-weight: 400;
  color: #323232;
  text-align: start;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  width: 100%;
  min-height: 30px;
  line-height: 30px;
}

.MultiList_item__ECyZb:disabled {
  color: #a8a7a7;
}

.MultiList_item__ECyZb:hover,
.MultiList_item__ECyZb.MultiList_active__VQHNF {
  background: #F5F5F5;
}

.MultiList_item__ECyZb.MultiList_disabled__i0XOZ:hover,
.MultiList_item__ECyZb:disabled:hover {
  background: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/MultiList/MultiList.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,wBAAgB;UAAhB,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,mBAAmB;AACrB","sourcesContent":[".multiList {\n  border: 1px solid rgba(50, 50, 50, 0.3);\n  max-height: 210px;\n  background: white;\n  margin-top: 12px;\n  border-radius: 3px;\n  max-width: 100%;\n  padding: 0;\n}\n\n.item {\n  background: #FFFFFF;\n  padding: 0 10px;\n  font-weight: 400;\n  color: #323232;\n  text-align: start;\n  appearance: none;\n  border: 0;\n  width: 100%;\n  min-height: 30px;\n  line-height: 30px;\n}\n\n.item:disabled {\n  color: #a8a7a7;\n}\n\n.item:hover,\n.item.active {\n  background: #F5F5F5;\n}\n\n.item.disabled:hover,\n.item:disabled:hover {\n  background: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiList": `MultiList_multiList__0p6--`,
	"item": `MultiList_item__ECyZb`,
	"active": `MultiList_active__VQHNF`,
	"disabled": `MultiList_disabled__i0XOZ`
};
export default ___CSS_LOADER_EXPORT___;
