// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-step-section_wrapper__O8O9u {
  margin-top: 30px;
}

.order-step-section_wrapper__O8O9u.order-step-section_sub__5x95N {
  margin-top: 0;
}

.order-step-section_sub__5x95N > .dhlHeadline {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/OrderStepSection/order-step-section.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  margin-top: 30px;\n}\n\n.wrapper.sub {\n  margin-top: 0;\n}\n\n.sub > :global(.dhlHeadline) {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `order-step-section_wrapper__O8O9u`,
	"sub": `order-step-section_sub__5x95N`
};
export default ___CSS_LOADER_EXPORT___;
