// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group_inputGroup__MtKaq {
  display: flex;
}

.input-group_inputGroup__MtKaq > :first-child input,
.input-group_inputGroup__MtKaq > :first-child button {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-group_inputGroup__MtKaq > :last-child input,
.input-group_inputGroup__MtKaq > :last-child button {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.input-group_inputGroup__MtKaq > :not(:first-child):not(:last-child) input,
.input-group_inputGroup__MtKaq > :not(:first-child):not(:last-child) button{
  border-radius: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/InputGroup/input-group.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;;EAEE,wCAAwC;EACxC,qCAAqC;AACvC;;AAEA;;EAEE,uCAAuC;EACvC,oCAAoC;AACtC;;AAEA;;EAEE,2BAA2B;AAC7B","sourcesContent":[".inputGroup {\n  display: flex;\n}\n\n.inputGroup > :first-child input,\n.inputGroup > :first-child button {\n  border-bottom-right-radius: 0 !important;\n  border-top-right-radius: 0 !important;\n}\n\n.inputGroup > :last-child input,\n.inputGroup > :last-child button {\n  border-bottom-left-radius: 0 !important;\n  border-top-left-radius: 0 !important;\n}\n\n.inputGroup > :not(:first-child):not(:last-child) input,\n.inputGroup > :not(:first-child):not(:last-child) button{\n  border-radius: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroup": `input-group_inputGroup__MtKaq`
};
export default ___CSS_LOADER_EXPORT___;
