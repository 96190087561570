import { PressOrderCreate } from 'order/productGroups/press/schema/pressSchema';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { LetterOrderCreate } from '../../letter/schemea/LetterSchema';
import { DialogMarketingOrderCreate } from '../../dialogMarketing/schema/dialogMarketingSchema';
import { AdditionalOrdersOrderCreate } from '../../additionalOrders/schema/additionalOrdersSchema';
import { InternationalClusterOrderCreate } from '../../international/schema/internationalClusterSchema';

export type OrderCreate =
  | PressOrderCreate
  | DialogMarketingOrderCreate
  | AdditionalOrdersOrderCreate
  | LetterOrderCreate
  | InternationalClusterOrderCreate;

export type AvailableProductGroup = OrderCreate['productGroup'];

export const availableProductGroups: AvailableProductGroup[] = [
  ProductGroup.DIALOGPOST,
  ProductGroup.DIALOGPOSTWURFSPEZIAL,
  ProductGroup.PRESS_DISTRIBUTION,
  ProductGroup.PARTIAL_SERVICE_LETTER,
  ProductGroup.BRIEF,
  ProductGroup.COLLECTION,
  ProductGroup.BRIEF_INTERNATIONAL,
  ProductGroup.PRESS_INTERNATIONAL,
  ProductGroup.DIALOGPOST_INTERNATIONAL
];

export const isAvailableProductGroup = (x: string): x is AvailableProductGroup => (<string[]>availableProductGroups).includes(x);
