import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { Pagination } from 'common/components/Paginator/Pagination';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { Filter } from 'common/components/Table/dtos/Filter';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { Sort } from 'common/components/Table/dtos/Sort';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { Table } from 'common/components/Table/Table';
import { useAuthContext } from 'common/context/auth/AuthContext';
import formatDate from 'date-fns/format';
import { GetPartialOrderRep } from 'generated';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { getCurrentSlice, moveColumnLeft, moveColumnRight, rowContainsFilter, sortRows } from 'order/common/context/table/utils/table';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { downloadCsvBlob } from 'order/common/utils/downloadBlob';
import { partialOrderToCsv } from 'order/orderView/components/ExportPartialOrderToCsv/partialOrderToCsv';
import classes from 'order/orderSearch/components/ResultMessage/result-message.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './table-container.module.css';
import { OrderCategory } from '../../context/order/dtos/OrderCategory';
import { allocationConvertion } from '../QtyAllocation/helper/allocationConvertion';

const defaultPageSize = 5;

interface Props {
  columnConfig: ColumnConfig[];
  change?: boolean;
  showActionBar?: boolean;
  hideTableOptions?: boolean;
  selectedDate?: Date;
}

export const AllocationTable = (props: Props): JSX.Element | null => {
  const {
    allocationsOrders,
    allocationsCount,
    allocationsTotal,
    allocationsLoading,
    displayAllAllocations,
    setDisplayAllAllocations,
    order,
    orderCategory,
    upsertMetaData,
    orderReferences,
    allocatedDiscounts,
    setAllocatedDiscounts
  } = useOrderContext();
  const [rows, setRows] = useState<AllocatedOrder[]>([]);
  const [currentSlice, setCurrentSlice] = useState<AllocatedOrder[]>([]);
  const [sumTotalNetWeight, setSumTotalNetWeight] = useState<string | undefined>(undefined);
  const filter = useRef<Filter>({ name: null, value: null });
  const sort = useRef<Sort>({ name: null, order: null });
  const sorting = useRef<Sort>({ name: null, order: SortOrder.DESC, type: ColumnType.CUSTOM_CELL });
  const page = useRef<number>(1);
  const pageSize = useRef<number>(defaultPageSize);
  const filteredRows = useRef<AllocatedOrder[]>([]);
  const [columns, setColumnConfig] = useState<ColumnConfig[]>(props.columnConfig);
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const { t: resultMessage } = useTranslation('translation', { keyPrefix: 'gkp.msg.dataStatusIndicator' });
  const language = useAuthContext().user.language;

  const { getFacility } = useProductionFacilities(order?.orderDetail?.date ? new Date(Date.parse(order.orderDetail.date)) : undefined);

  const filterRows = (allocationsOrders: AllocatedOrder[]) => {
    let filtered = [...allocationsOrders];
    const { name, value } = filter.current;
    if (name && value) {
      filtered = filtered.filter((r) => rowContainsFilter(name, value, r));
    }
    return filtered;
  };

  useEffect(() => {
    page.current = 1;
    setRows(allocationsOrders || []);
    const filtered = filterRows(allocationsOrders);
    const sortedRows = sortRows(sorting.current.name, filtered || [], sorting.current.order);
    const curSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    filteredRows.current = sortedRows || [];
    setCurrentSlice(curSlice);
  }, [allocationsOrders]);

  useEffect(() => {
    if (!allocationsLoading && allocationsOrders.length && orderCategory === OrderCategory.ABHOLAUFTRAG) {
      const totalNetWeight = allocationsOrders.map((r) => r.data.totalNetWeight).reduce((sum, current) => (sum as number) + (current as number), 0);
      const totalGrossWeight = allocationsOrders
        .map((r) => r.data.totalGrossWeight)
        .reduce((sum, current) => (sum as number) + (current as number), 0);
      const totalPackagingQty = allocationsOrders.map((r) => r.data.packagingQty).reduce((sum, current) => (sum as number) + (current as number), 0);
      const anyUnstackable = allocationsOrders.find((a) => !a.data.stackable);
      const totalNetWeightInKg = (totalNetWeight ? totalNetWeight / 1000 : 0)?.toLocaleString(language);
      const totalGrossWeightInKg = totalGrossWeight?.toLocaleString(language);
      setSumTotalNetWeight(totalNetWeightInKg);
      upsertMetaData('sumTotalGrossWeight', totalGrossWeightInKg);
      upsertMetaData('sumTotalNetWeight', totalNetWeightInKg);
      upsertMetaData('sumTotalPallets', totalPackagingQty);
      upsertMetaData('stackable', !anyUnstackable);
    }
  }, [allocationsLoading, allocationsOrders, orderCategory]);

  const resetSortAndFilters = () => {
    filteredRows.current = rows;
    const curSlice = getCurrentSlice(filteredRows.current, page.current, pageSize.current);
    setCurrentSlice(curSlice);
  };

  const setFilter = (name: string | null, value: string | null) => {
    filter.current = { name, value };
    const filtered = filterRows(rows);
    filteredRows.current = filtered;
    page.current = 1;
    const currentSlice = getCurrentSlice(filtered, 1, pageSize.current);
    setCurrentSlice(currentSlice);
  };

  const setSorting = (name: string, order: SortOrder, type: ColumnType) => {
    const filtered = [...filteredRows.current];
    const sortedRows = sortRows(name, filtered, order);
    const currentSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    sort.current = { name, order, type };
    sorting.current = { name, order, type };
    filteredRows.current = sortedRows;
    setCurrentSlice(currentSlice);
  };

  const setColumnMove = (name: string, direction: 'left' | 'right') => {
    let columnConf = [...columns];
    if (direction === 'left') {
      columnConf = moveColumnLeft(columnConf, name);
    } else if (direction === 'right') {
      columnConf = moveColumnRight(columnConf, name);
    }

    setColumnConfig(columnConf);
  };

  const handlePageSizeChange = (size: number) => {
    let newSlice = [...filteredRows.current];

    page.current = 1;

    if (size > 0) {
      newSlice = getCurrentSlice(filteredRows.current, page.current, size);
    }

    pageSize.current = size;
    setDisplayAllAllocations(false);
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number) => {
    const newSlice = getCurrentSlice(filteredRows.current, p, pageSize.current);

    page.current = p;
    setDisplayAllAllocations(false);
    setCurrentSlice(newSlice);
  };

  const isFiltered = (name: string) => filter.current.name === name;

  useEffect(() => {
    if (orderReferences.length !== allocatedDiscounts.length) {
      const newOrderReferences = orderReferences.filter((a) => a.new);
      newOrderReferences.forEach((b) => {
        const exists = allocatedDiscounts.some((item) => item.orderId === b.orderId);
        if (!exists) {
          const newData = allocationConvertion(b);
          if (newData) setAllocatedDiscounts(newData, 'ADD');
        }
      });
    }
  }, [orderReferences.length]);

  return allocationsOrders.length || allocationsTotal > 0 ? (
    <div className={styles.searchTable}>
      {filteredRows.current.length !== rows.length ? (
        <div className={classes.message}>
          {resultMessage('allocFiltered', {
            filtered: filteredRows.current.length,
            count: rows.length
          })}
          {allocationsCount ? (
            <span className={styles.subMessage}>
              ({allocationsCount} {t('result.of')} {allocationsCount} {t('result.loaded')})
            </span>
          ) : null}
        </div>
      ) : (
        <div className={classes.message}>
          {resultMessage('allocCount', {
            count: rows.length
          })}
          {allocationsTotal && allocationsCount ? (
            <span className={styles.subMessage}>
              ({allocationsCount} {t('result.of')} {allocationsTotal} {t('result.loaded')})
            </span>
          ) : null}
        </div>
      )}
      {orderCategory === OrderCategory.ABHOLAUFTRAG && !allocationsLoading ? (
        <div className={styles.subMessage}>{t('sumTotalNetWeight', { weight: `${sumTotalNetWeight} ` })}</div>
      ) : null}
      <div className={styles.tableCentering}>
        <div className={styles.wrapper}>
          {/* <div className={styles.container}>
            <div className={styles.overflow}> */}
          <Table
            columnConfig={columns}
            currentSlice={currentSlice || []}
            loadingState={allocationsLoading ? TableLoadingState.LOADING : TableLoadingState.LOADED}
            loadingText={`${Math.ceil((allocationsCount / allocationsTotal) * 100)}% ${t('result.loaded')}`}
            t={t}
            filter={filter.current}
            sort={sort.current}
            onResetSortAndFilters={resetSortAndFilters}
            onFilterChange={setFilter}
            onSortChange={setSorting}
            onMoveChange={setColumnMove}
            highlightedRows={true}
            language={language}
            withTableOptions={!props.hideTableOptions}
            allChildrenExpanded={displayAllAllocations}
          />
          {/* </div>
          </div> */}
          <div className={styles.actionsBar}>
            <Pagination
              loadingState={TableLoadingState.LOADED}
              totalItems={filteredRows.current.length}
              defaultPageSize={defaultPageSize}
              currentPage={page.current}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
            />
          </div>
          {props.showActionBar ? (
            <div
              className="dhlTable-actionbar"
              style={{
                justifyContent: 'end'
              }}
            >
              <DHLButton
                size="xs"
                type="primary"
                label="Auftragsliste exportieren"
                icon="csv"
                iconPosition="icon-first"
                onClick={() => {
                  if (order?.productGroup !== ProductGroup.PARTIAL_SERVICE_LETTER) {
                    return;
                  }

                  const csvString = partialOrderToCsv({
                    getProductionFacility: getFacility,
                    order: order as unknown as GetPartialOrderRep,
                    allocatedOrders: allocationsOrders
                  });

                  const title = `AMGKP-Auftragsliste Zusatzauftrag Teilleistungen Auftragsnummer-${formatDate(new Date(), 'yyyyMMddHHmmss')}.csv`;
                  downloadCsvBlob(title, csvString);
                }}
                disabled={allocationsLoading}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};
