import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';

import { SFCProps } from 'common/utils/formHelpers';
import { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import React, { ReactElement } from 'react';
import { FieldValues, useFieldArray } from 'react-hook-form';

import style from './DisplayQuantityWeightSection.module.css';
import './translation';

export type Shipment = {
  description?: string;
  totalQuantity?: number;
  additionalQuantity?: number;
  individualWeight?: number;
};

export type DisplayQuantityWeightSectionProps<T extends FieldValues> = SFCProps<T, Shipment[]> & {
  name: string;
  disabled?: boolean;
  showVariant?: boolean;
};

export const DisplayQuantityWeightSection = <T extends FieldValues>({
  disabled,
  name,
  showVariant
}: DisplayQuantityWeightSectionProps<T>): ReactElement => {
  const { fields } = useFieldArray<Record<string, Shipment[]>, string>({
    name: name
  });
  const { t } = useTranslation('DisplayQuantityWeightSection');
  return (
    <OrderStepSection headline={showVariant ? t('headline.variant') : t('headline.collective')}>
      {fields.map((field, index) => {
        const prefix = `${name}.${index}` as const;
        return (
          <DHLFormGroup className={style.DynamicRow} key={field.id}>
            <TextInput
              name={`${prefix}.description`}
              label={t(`description`)}
              required
              rules={{
                required: 'required',
                maxLength: { value: 120, message: '' }
              }}
              disabled={disabled}
              error={({ fieldState }) => {
                if (!fieldState.error) {
                  return undefined;
                }
                if (fieldState.error?.type === 'required') {
                  return t('errors.required');
                }
                return t('errors.description');
              }}
            />
            <TextInput name={`${prefix}.totalQuantity`} label={t(`quantity`)} disabled />
            <TextInput name={`${prefix}.additionalQuantity`} label={t(`additionalQuantity`)} disabled />
            <TextInput
              name={`${prefix}.individualWeight`}
              type="number"
              label={t(`individualWeight`)}
              required
              rules={{ required: true, max: 1e4 - 1 }}
              disabled={disabled}
              error={({ fieldState }) => {
                if (!fieldState.error) {
                  return undefined;
                }
                if (fieldState.error?.type === 'required') {
                  return t('errors.required');
                }
                return t('errors.individualWeight');
              }}
            />
          </DHLFormGroup>
        );
      })}
    </OrderStepSection>
  );
};
