import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import cn from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { useCounter } from 'common/utils/hooks';
import { GetOrderRep } from 'generated';
import { Header } from 'order/common/components/Header';
import { OrderStepProvider } from 'order/common/services/OrderStepProvider';
import { OrderChangeSteps } from 'order/orderChange/common/components/OrderChangeSteps';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { getOrder } from 'order/common/context/order/utils/getOrder';
import { OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { useTranslation } from 'i18n';
import { CustomOrderAlert } from 'common/components/PendingOrder/CustomOrderAlert';
import { LoadingOrder } from 'common/components/LoadingOrder/LoadingOrder';
import { useSearchParams } from 'order/common/hooks/useSearchParams';
import { OrderMode } from 'order/common/dtos/OrderMode';

import orderClasses from 'common/styles/order.module.css';
import classes from './order-change.module.css';
import { ErrorMessageContainer } from '../../../../productGroups/common/components/atom/ErrorMessageContainer/ErrorMessageContainer';
import { SpaceErrorAlerts } from '../../../../common/components/Alerts/SpaceErrorAlerts';

export type OrderChangeProps = {
  text?: string;
};

export type OrderChangeRouteParams = {
  orderId: string;
};

export const OrderChange: React.VFC<OrderChangeProps> = () => {
  const isPartial = useSearchParams('orderType')?.includes('ZA');

  const methods = useForm<GetOrderRep>();
  const useCounterReturn = useCounter(1, 0, 3);
  const { counter: step } = useCounterReturn;
  const { orderId: orderIdParam } = useParams<OrderChangeRouteParams>();
  const { alerts, clear } = useAlerts();
  const orderNumberLoading = useRef<string | undefined>(undefined);
  const {
    productGroup,
    orderCategory,
    setOrder,
    setOrderId,
    setOrderMode,
    setProductGroup,
    setOrderCategory,
    setOrderCategoryProductKey,
    orderId,
    order,
    resetOrderReferences,
    reset,
    resetAllocations
  } = useOrderContext();
  const [orderLoading, setOrderLoading] = useState(true);
  const { t: oc } = useTranslation('orderCreate');
  const rawOrder = useRef<OrderResponse | undefined>(undefined);
  const orderFromContext = useRef<boolean>(true);

  const fetchOrder = async (id: string) => {
    const data = isPartial ? await getOrder<OrderResponse>(id) : await getOrder<OrderResponse>(id, { includeDst: true });
    if (data) {
      setOrder(data);
      setProductGroup(data.productGroup as ProductGroup);
      setOrderCategory(data.orderCategory);
      setOrderCategoryProductKey(data.orderCategoryProductKey);
      rawOrder.current = data;
    }
    setOrderId(id);
    setOrderLoading(false);
  };

  useEffect(() => {
    setOrderMode(OrderMode.CHANGE);
    if (orderLoading && orderId?.toString() !== orderIdParam.toString() && orderNumberLoading.current !== orderIdParam) {
      orderFromContext.current = false;
      reset();
      resetOrderReferences();
      resetAllocations();
      orderNumberLoading.current = orderIdParam;
      fetchOrder(orderIdParam);
    } else if (orderLoading && orderId?.toString() === orderIdParam.toString() && order) {
      rawOrder.current = order;
      setOrderLoading(false);
    }
  }, [orderIdParam, orderId, orderLoading, order]);

  useEffect(() => clear(), [step]);

  if (orderLoading) {
    return <LoadingOrder headline={oc('headline.orderChange')} />;
  }

  if (!orderLoading && !rawOrder.current) {
    return <ErrorMessageContainer message={oc('errors.notFound')} dataTestId={'error-order-not-found'} />;
  }

  if (!orderLoading && rawOrder.current && (rawOrder.current as any)?.timeoutError) {
    return <CustomOrderAlert headline={oc('headline.orderChange')} message={oc(`notifications.timeoutWarning`)} title={oc('notifications.notice')} />;
  }

  if (!orderLoading && rawOrder.current && rawOrder.current?.pendingIds) {
    return (
      <CustomOrderAlert headline={oc('headline.orderChange')} message={oc(`notifications.pendingIdsWarning`)} title={oc('notifications.warning')} />
    );
  }

  return (
    <div className={classes.container}>
      <Header type={OrderMode.CHANGE} activeStep={step} productGroup={productGroup} orderCategory={orderCategory} />
      <SpaceErrorAlerts alerts={alerts} />
      <div
        className={
          step === 2 && (productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || productGroup === ProductGroup.COLLECTION) ? '' : orderClasses.dialog
        }
      >
        <OrderStepProvider {...useCounterReturn}>
          <FormProvider {...methods}>
            <OrderChangeSteps orderData={rawOrder.current} orderFromContext={orderFromContext.current} />
          </FormProvider>
        </OrderStepProvider>
      </div>
    </div>
  );
};
