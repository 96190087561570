import * as React from 'react';
import { ReactNode, useReducer } from 'react';

import { TableContext } from './TableContext';
import { defaultReducerState, tableReducer, TableReducerActions } from './reducers/table';
import { OrderReplyTDO, OrderTreeItem } from '../../services/OrderService';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { OrderSearchType } from '../search/dtos/OrderSearchType';

interface Props {
  children: ReactNode;
}

export const TableProvider = (props: Props): JSX.Element => {
  const [tableData, dispatchTableData] = useReducer(tableReducer, defaultReducerState);

  const setColumnConfig = (columnConfig: any) => {
    dispatchTableData({ type: TableReducerActions.SET_COLUMNS, data: columnConfig });
  };

  const setLoadingState = (state: TableLoadingState) => {
    dispatchTableData({ type: TableReducerActions.SET_LOADING_STATE, data: state });
  };

  const setRows = (rows: OrderTreeItem<OrderReplyTDO>[]) => {
    dispatchTableData({ type: TableReducerActions.SET_ROWS, data: rows });
  };

  const setPage = (number: number) => {
    dispatchTableData({ type: TableReducerActions.SET_PAGINATOR_PAGE, data: number });
  };

  const setPageSize = (size: number) => {
    dispatchTableData({ type: TableReducerActions.SET_PAGINATOR_PAGE_SIZE, data: size });
  };

  const setColumnMove = (name: string, direction: 'left' | 'right') => {
    dispatchTableData({ type: TableReducerActions.MOVE_COLUMN, data: { name, direction } });
  };

  const setFilter = (name: string | null, value: string | null, type?: OrderSearchType) => {
    dispatchTableData({ type: TableReducerActions.SET_FILTER, data: { name, value, type } });
  };

  const setSorting = (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => {
    dispatchTableData({ type: TableReducerActions.SORT_ROWS, data: { name, order, type, searchType } });
  };

  const resetSortAndFilters = () => {
    dispatchTableData({ type: TableReducerActions.RESET_FILTER_AND_SORTING });
  };

  return (
    <TableContext.Provider
      value={{
        ...tableData,
        resetSortAndFilters,
        setColumnConfig,
        setColumnMove,
        setFilter,
        setLoadingState,
        setPage,
        setPageSize,
        setRows,
        setSorting
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
};
