import { UseCounterReturn } from 'common/utils/hooks';
import { createContext, createElement, Dispatch, PropsWithChildren, useContext, VFC } from 'react';

export type OrderStepReturn = {
  step: number;
  nextStep: () => void;
  previousStep: () => void;
  goToStep: Dispatch<number>;
};

const Context = createContext<OrderStepReturn>({
  step: 0,
  nextStep: () => {},
  previousStep: () => {},
  goToStep: () => {}
});

export const OrderStepProvider: VFC<PropsWithChildren<UseCounterReturn>> = ({ counter, increase, decrease, setCounter, children }) =>
  createElement(
    Context.Provider,
    {
      value: {
        step: counter,
        nextStep: increase,
        previousStep: decrease,
        goToStep: setCounter
      }
    },
    children
  );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOrderStep = () => useContext(Context);
