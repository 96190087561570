import React, { FC } from 'react';
import { ProductInfoSection } from 'order/common/components/Overview/ProductInfoSection';
import { ZoneRegion } from 'order/common/components/Overview/ZoneRegion';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { ProductGroup } from '../../../../../common/dtos/ProductGroup';
import { OrderCategory } from '../../../../../common/context/order/dtos/OrderCategory';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';
import { OrderContentTabProps } from '../OrderContentTab';
import { OrderTreeContainer } from '../../../OrderTree/OrderTreeContainer';
import { PAProductInfoSection } from 'order/common/components/Overview/PAProductInfoSection/PAProductInfoSection';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export const DialogPostTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;
  const { productGroup } = useOrderContext();
  const isPostakuell = order.orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL;

  return (
    <div data-testid={'order-content-tab-dialog-testid'}>
      {isPostakuell ? <PAProductInfoSection order={order} /> : <ProductInfoSection value={order} />}
      <ZoneRegion value={order} />
      <CustomerDetailSection
        parties={order?.parties}
        withAddress={order && productGroup === ProductGroup.BRIEF && order.orderCategory === OrderCategory.AFM_BRIEF}
        orderCategoryProductKey={order.orderCategoryProductKey}
        validityDate={order?.orderDetail?.date}
      />
      {order.hasOrderTree && <OrderTreeContainer order={order} />}
    </div>
  );
};
