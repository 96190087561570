import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './alerts.module.css';
import { useAlerts } from 'common/AlertContext';
import { AlertTypes } from './dtos/AlertTypes';
import { AlertDescription } from './AlertDescription/AlertDescription';
import { alertSvgSwitch } from './helpers/alertSvgSwitch';

export type AlertProps = {
  name?: string;
  id: string;
  type?: AlertTypes;
  closeable: boolean;
  title?: string;
  description?: string;
  className?: string;
};

export const Alert: FC<AlertProps> = (props) => {
  const { remove } = useAlerts();
  const closeBtnHandler = () => {
    remove(props.id);
  };

  return (
    <div
      className={classNames(
        styles.alertContainer,
        props.type === AlertTypes.Error
          ? styles.errorAlert
          : props.type === AlertTypes.Warning
          ? styles.warningAlert
          : props.type === AlertTypes.Success
          ? styles.successAlert
          : styles.neutralAlert,
        props.className
      )}
      data-testid={`${props.type}-alert-${props.id}`}
    >
      <div className={classNames(styles.alertIconContainer)}>
        <div id={`${props.type}-alert-${props.id}`} data-testid={`alert-icon-${props.type}-${props.id}`} className={classNames(styles.svgIcon)}>
          {alertSvgSwitch(props.type)}
        </div>
      </div>
      <div className={classNames(styles.alertTextContainer)}>
        <h6 data-testid={`alert-heading-${props.type}-${props.id}`} className={classNames(styles.alertHeading)}>
          {props.title}
        </h6>
        {props.description && (
          <div className={classNames(styles.alertDescriptionContainer)}>
            <AlertDescription limit={150}>{props?.description}</AlertDescription>
          </div>
        )}
      </div>
      {props.closeable && (
        <div className={classNames(styles.closeBtnContainer)}>
          <button
            id={`${props.type}-alert-${props.id}`}
            data-testid={`${props.type}-alert-close-${props.id}`}
            onClick={closeBtnHandler}
            className={classNames('alertCloser')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon
                fill="#323232"
                fillRule="evenodd"
                points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};
