import React, { FC, useCallback } from 'react';
import i18n from 'i18n';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { useTranslation } from 'react-i18next';
import { translationEn } from './Translations/englishTranslation';
import { translationGe } from './Translations/germanTranslation';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

export interface JobTitleInputProps {
  name?: string;
  required?: boolean;
  editing?: boolean;
  orderLabel?: string;
  defaultValue?: string;
}

i18n.addResourceBundle('de', 'jobTitleInput', translationGe);
i18n.addResourceBundle('en', 'jobTitleInput', translationEn);

export const JobTitleInput: FC<JobTitleInputProps> = (props) => {
  const { t } = useTranslation('jobTitleInput');
  const { setValue } = useFormContext();
  const [errorMessageKey, setErrorMessageKey] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length == 0 && props.required) {
        setError(true);
        setErrorMessageKey('required');
      } else if (e.target.value.length > 80) {
        setError(true);
        setErrorMessageKey('length');
      } else {
        setError(false);
      }
      setValue(props.name ? `${props.name}` : 'orderLabel', e.target.value);
    },
    [props.name, props.required, setValue]
  );

  return (
    <CustomTextInput
      required={props.required}
      type={'text'}
      name={props.name}
      label={t('label')}
      testid={'jobTitleInput'}
      onChange={onChangeHandler}
      error={error ? t(`error.${errorMessageKey}`) : undefined}
      value={props.defaultValue ?? ''}
    />
  );
};
