import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';

export type { AppProps } from './App';
export { default as App } from './App';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <div>Es ist ein Fehler im Auftragsmanagement aufgetreten.</div>;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
