// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrintDialog_subSection__wJ6Zb{
    padding-top: 15px;
    padding-left: 10px;
}
.PrintDialog_subSection__wJ6Zb .checkbox-container .checkbox-and-label-container.disabled .form-check-label{
    color:rgba(50, 50, 50, 0.4) !important;
}
.PrintDialog_printDialog__wjZpW {
    width:50% !important;
    margin: 10% auto !important;
}`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/PrintDialog/PrintDialog.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,oBAAoB;IACpB,2BAA2B;AAC/B","sourcesContent":[".subSection{\n    padding-top: 15px;\n    padding-left: 10px;\n}\n.subSection :global(.checkbox-container .checkbox-and-label-container.disabled .form-check-label){\n    color:rgba(50, 50, 50, 0.4) !important;\n}\n.printDialog {\n    width:50% !important;\n    margin: 10% auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subSection": `PrintDialog_subSection__wJ6Zb`,
	"printDialog": `PrintDialog_printDialog__wjZpW`
};
export default ___CSS_LOADER_EXPORT___;
