import i18n from 'i18n';
import React from 'react';

import { CustomerNameTO } from 'generated';
import { OrderTableConfiguration } from 'order/common/context/search/dtos/OrderTableConfiguration';
import { ColumnConfig, ContentAlignment } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import {
  customRendererForOrderNumber,
  customRendererForOrderType,
  customRendererForFrankierung,
  customRendererForProduct,
  statusRenderer,
  customRendererForOrderValue,
  customRendererForQuantity,
  customRendererForIcons,
  customRendererForProcedure,
  customRendererForPackagingType,
  customRendererForPackagingQty
} from './functions/customRendererFunctions';
import { defaultSimpleCol, defaultPressCol, defaultAdvanceCol } from './defaultColumns';
import styles from './AMTable.module.css';

const customerRenderer = (value: CustomerNameTO): string => (value?.name ? `${value.customerId} ${value.name}` : value?.customerId || '');

const translate = (key: string) => i18n.t('orderTable.column.config.' + key);

export const columns: Record<string, ColumnConfig> = {
  orderValue: {
    name: 'orderValue',
    property: 'data.orderValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL
  },
  deliveryDate: {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE
  },
  state: {
    hideable: true,
    name: 'state',
    property: 'data.state',
    sortable: true,
    filterable: true,
    title: translate('state'),
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM
  },
  sender: {
    hideable: true,
    hidden: true,
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM_CELL
  },
  deliverer: {
    hideable: true,
    hidden: true,
    customRenderer: customerRenderer,
    name: 'deliverer',
    property: 'data.submitter',
    sortable: true,
    filterable: true,
    title: translate('deliverer'),
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM_CELL
  },
  payer: {
    hideable: true,
    hidden: true,
    customRenderer: customerRenderer,
    name: 'payer',
    property: 'data.payer',
    sortable: true,
    filterable: true,
    title: translate('payer'),
    className: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM
  },
  producer: {
    hideable: true,
    hidden: true,
    customRenderer: customerRenderer,
    name: 'producer',
    property: 'data.producer',
    sortable: true,
    filterable: true,
    title: translate('manufacturer'),
    className: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM
  },
  beneficiary: {
    hideable: true,
    hidden: true,
    customRenderer: customerRenderer,
    name: 'beneficiary',
    property: 'data.beneficiary',
    sortable: true,
    filterable: true,
    title: translate('authorizedPerson'),
    className: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM
  },
  packagingType: {
    hideable: true,
    hidden: true,
    customRenderer: customRendererForPackagingType,
    name: 'packagingType',
    property: 'data.packagingType',
    sortable: true,
    filterable: true,
    title: translate('packagingType'),
    type: ColumnType.CUSTOM_CELL
  },
  packagingQty: {
    hideable: true,
    hidden: true,
    name: 'packagingQty',
    property: 'data.packagingQty',
    customRenderer: customRendererForPackagingQty,
    sortable: true,
    filterable: false,
    title: translate('packagingQty'),
    type: ColumnType.CUSTOM_CELL
  },
  orderNumber: {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    filterable: true,
    title: translate('orderNumber'),
    customRenderer: customRendererForOrderNumber,
    type: ColumnType.CUSTOM_CELL
  },
  orderType: {
    name: 'orderType',
    property: 'data.orderType',
    sortable: true,
    filterable: true,
    title: translate('orderType'),
    customRenderer: customRendererForOrderType,
    innerClassName: styles.columnWithWrappedTextAndSmaller,
    type: ColumnType.CUSTOM_CELL
  },
  orderLabel: {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    filterable: true,
    title: translate('actionName'),
    className: styles.tdColumnWithWrappedForOrderLabel,
    type: ColumnType.COMMON
  },
  product: {
    hideable: true,
    name: 'product',
    property: 'data.product',
    sortable: true,
    filterable: true,
    title: translate('product'),
    customRenderer: customRendererForProduct,
    type: ColumnType.CUSTOM_CELL
  },
  totalQty: {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForQuantity,
    type: ColumnType.CUSTOM_CELL
  },
  frankierung: {
    hideable: true,
    hidden: true,
    name: 'frankierung',
    property: 'data.frankierung',
    sortable: true,
    filterable: true,
    title: translate('postage'),
    customRenderer: customRendererForFrankierung,
    type: ColumnType.CUSTOM_CELL
  },
  procedure: {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'procedure',
    property: 'data.procedure',
    sortable: true,
    filterable: true,
    title: translate('procedure'),
    customRenderer: customRendererForProcedure,
    tdClassName: styles.tdColumnWithWrappedTextAndSmall,
    type: ColumnType.CUSTOM_CELL
  },
  participation: {
    contentAlignment: ContentAlignment.CENTER,
    hideable: true,
    hidden: true,
    name: 'participation',
    property: 'data.participation',
    sortable: true,
    filterable: true,
    title: translate('participation'),
    type: ColumnType.COMMON
  },
  paymentClearingNumber: {
    contentAlignment: ContentAlignment.RIGHT,
    hideable: true,
    hidden: true,
    name: 'paymentClearingNumber',
    property: 'data.paymentClearingNumber',
    sortable: true,
    filterable: true,
    title: translate('deliveryReceiptNumberBreaking'),
    innerClassName: styles.columnWithWrappedTextAndSmall,
    type: ColumnType.COMMON
  },
  zkz: {
    hideable: true,
    hidden: true,
    name: 'zkz',
    property: 'data.zkz',
    sortable: true,
    filterable: true,
    title: translate('newspaperID'),
    type: ColumnType.COMMON
  },
  newspaperNumber: {
    hideable: true,
    hidden: true,
    name: 'newspaperNumber',
    property: 'data.newspaperNumber',
    sortable: true,
    filterable: true,
    title: translate('newspaperNumber'),
    type: ColumnType.COMMON
  },
  customerOrderId: {
    hideable: true,
    hidden: true,
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    filterable: true,
    title: translate('customerOrderNumber'),
    type: ColumnType.COMMON
  },
  settlementNumber: {
    hideable: true,
    hidden: true,
    name: 'settlementNumber',
    property: 'data.settlementNumber',
    sortable: true,
    filterable: true,
    title: translate('billingNumber'),
    type: ColumnType.COMMON
  },
  iconsTableCell: {
    hideable: false,
    sortable: false,
    movable: false,
    name: null,
    property: 'iconsTableCell',
    title: '',
    customRenderer: customRendererForIcons,
    type: ColumnType.CUSTOM_CELL
  },
  treeExpand: {
    hideable: false,
    icon: 'arrow-down',
    sortable: false,
    movable: false,
    name: null,
    property: 'treeExpand',
    title: null,
    type: ColumnType.TREE_EXPAND
  }
};

const commonColumns: ColumnConfig[] = [
  columns.orderValue,
  columns.deliveryDate,
  columns.state,
  columns.sender,
  columns.deliverer,
  columns.payer,
  columns.producer,
  columns.beneficiary,
  columns.packagingType,
  columns.packagingQty
];

export const fullColumnList = (): ColumnConfig[] => {
  const commonCols = Object.assign([], commonColumns);
  return [
    columns.orderNumber,
    columns.orderType,
    columns.orderLabel,
    columns.product,
    columns.totalQty,
    ...commonCols,
    columns.frankierung,
    columns.procedure,
    columns.participation,
    columns.paymentClearingNumber,
    columns.zkz,
    columns.newspaperNumber,
    columns.customerOrderId,
    columns.settlementNumber,
    columns.iconsTableCell,
    columns.treeExpand
  ].map((config: ColumnConfig): ColumnConfig => {
    if (config.sortable !== false) {
      config.sortable = true;
    }
    if (config.movable !== false) {
      config.movable = true;
    }
    return config;
  });
};
export const pressFullColumnList = (): ColumnConfig[] => {
  const commonCols = Object.assign([], commonColumns);
  return [
    columns.orderNumber,
    {
      ...columns.zkz,
      hideable: false,
      hidden: false
    },
    {
      ...columns.newspaperNumber,
      hidden: false
    },
    columns.product,
    columns.totalQty,
    ...commonCols,
    columns.customerOrderId,
    columns.settlementNumber,
    columns.iconsTableCell,
    {
      ...columns.treeExpand,
      disableMinWidth: true
    }
  ].map((config: ColumnConfig): ColumnConfig => {
    if (config.sortable !== false) {
      config.sortable = true;
    }
    if (config.movable !== false) {
      config.movable = true;
    }
    return config;
  });
};

const saveMovedColumn = (
  preservedColumn: ColumnConfig[],
  currentColumn: ColumnConfig[],
  savedColumn: ColumnConfig[],
  tableConfig: OrderTableConfiguration
) => {
  preservedColumn.forEach((col) => {
    if (col.name && tableConfig.hidden && tableConfig.hidden[col.name] !== undefined) {
      return (col.hidden = tableConfig.hidden[col.name]);
    }
  });

  const compare = currentColumn.some((a, i) => {
    if (savedColumn[i] && savedColumn[i].name !== (undefined || null)) {
      return a.name !== savedColumn[i].name;
    }
  });

  if (compare) {
    const data = currentColumn.filter((a, i) => {
      if (savedColumn[i] && savedColumn[i].name) {
        return a.name !== savedColumn[i].name;
      }
    });

    const fromIndex = data.map((c) => currentColumn?.findIndex((a) => a.name === c.name));
    const swapElements = (array: ColumnConfig[], index1: number) => {
      const finding = preservedColumn.find((a) => {
        if (savedColumn[index1] && savedColumn[index1].name) {
          return savedColumn[index1].name === a.name;
        }
      });
      return finding && (array[index1] = finding);
    };
    data.map((a, index) => swapElements(currentColumn, fromIndex[index]));
  }
};

export const advancedColumnList = (tableConfig: OrderTableConfiguration | undefined, columnConfig?: ColumnConfig[]): ColumnConfig[] => {
  const list: ColumnConfig[] = Object.assign([], fullColumnList());
  if (tableConfig) {
    list.forEach((i) => {
      if (i.name && tableConfig.hidden && tableConfig.hidden[i.name] !== undefined) {
        if (columnConfig) {
          i.hidden = tableConfig.hidden[i.name];
          saveMovedColumn(defaultAdvanceCol, list, columnConfig, tableConfig);
        } else {
          i.hidden = tableConfig.hidden[i.name];
        }
      }
      return i;
    });
  }
  return list;
};

export const simpleColumnList = (tableConfig: OrderTableConfiguration | undefined, columnConfig?: ColumnConfig[]): ColumnConfig[] => {
  const list: ColumnConfig[] = Object.assign([], fullColumnList());
  if (tableConfig) {
    list.forEach((i) => {
      if (i.name && tableConfig.hidden && tableConfig.hidden[i.name] !== undefined) {
        if (columnConfig) {
          i.hidden = tableConfig.hidden[i.name];
          saveMovedColumn(defaultSimpleCol, list, columnConfig, tableConfig);
        } else {
          i.hidden = tableConfig.hidden[i.name];
        }
      }

      return i;
    });
  }

  return list;
};

export const pressColumnList = (tableConfig: OrderTableConfiguration | undefined, columnConfig?: ColumnConfig[]): ColumnConfig[] => {
  const list: ColumnConfig[] = Object.assign([], pressFullColumnList());

  if (tableConfig) {
    list.forEach((i) => {
      if (i.name && tableConfig.hidden && tableConfig.hidden[i.name] !== undefined) {
        if (columnConfig) {
          i.hidden = tableConfig.hidden[i.name];
          saveMovedColumn(defaultPressCol, list, columnConfig, tableConfig);
        } else {
          i.hidden = tableConfig.hidden[i.name];
        }
      }
      return i;
    });
  }

  return list;
};
