import React, { useCallback, useState } from 'react';
import { DHLButton, DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import { useAlerts } from 'common/AlertContext';
import { movePallet } from 'order/common/services/OrderService';
import { useTranslation } from 'react-i18next';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { MovePallet, MovePalletContent } from '../MovePalletContent/MovePalletContent';
import { useFormContext, useWatch } from 'react-hook-form';
import { Order } from '../../../../common/context/order/dtos/Order';
import { Button } from '../../../../../common/components/Button';
import { MovePalletProps, PalletAssignments } from '../../../../../generated';
import { ShareType } from '../../SplitOrder/SplitOrderContent/SplitOrderContent';
import { getOrder } from '../../../../common/context/order/utils/getOrder';
import { OrderResponse } from '../../../../common/context/order/dtos/GetOrder';
import { LoadingState } from '../../../../common/context/search/dtos/LoadingState';

interface MovePalletModalProps {
  show: boolean;
  onCancel: () => void;
  order: Order;
}

export const MovePalletModal = (props: MovePalletModalProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'splitOrder' });
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { setDirty, setOrder, setProductGroup, setOrderId, setLoadingState, setOrderCategoryProductKey, setOrderCategory, setOrderPrice } =
    useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const { control } = useFormContext<MovePallet>();
  const [selectedDestination, shareAfterType, targetOrders, palletMovementValid] = useWatch({
    control,
    name: ['selectedDestination', 'shareAfterType', 'targetOrders', 'palletMovementValid']
  });
  const backWrapperRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.querySelector('button')?.focus();
      }, 0);
    }
  }, []);
  /* istanbul ignore next */
  const loadNewOrder = async (id: string) => {
    const newOrder = await getOrder<OrderResponse>(id, { includeDst: true, includePrice: true });
    setLoadingState(LoadingState.LOADING);
    if (newOrder) {
      setOrderId(id);
      setOrder(newOrder);
      setProductGroup(newOrder.productGroup);
      setOrderCategory(newOrder.orderCategory);
      setOrderCategoryProductKey(newOrder.orderCategoryProductKey);
      setLoadingState(LoadingState.LOADED);
      setOrderPrice(newOrder?.orderPrice);
    }
  };

  const movePalletProps = (): MovePalletProps => {
    const assignments: PalletAssignments[] = targetOrders.map((val) => {
      return {
        targetOrder: {
          orderNumber: selectedDestination[val]?.targetOrder,
          lastChangedOn: selectedDestination[val]?.lastChangedOn
        },
        consignmentInformation: {
          ...(shareAfterType !== ShareType.VARIANT && {
            destinations: selectedDestination[val]?.destination.map(({ key, sourceOrder }) => {
              return {
                movedItem:
                  shareAfterType !== ShareType.REGION ? (key === '0-9' ? '*' : key) : key === '0-9' ? '**' : key.length < 2 ? key + '*' : key,
                sourceOrder: sourceOrder
              };
            })
          }),
          ...(shareAfterType === ShareType.VARIANT && {
            shipmentNumbers: selectedDestination[val]?.destination.map(({ key, sourceOrder }) => {
              const matchVar = props.order?.packagesPerVariant?.filter((item1) => item1.description === key)[0];
              return {
                movedItem: matchVar?.refShipmentId || '',
                sourceOrder: sourceOrder
              };
            })
          })
        }
      };
    });
    return {
      separationType: shareAfterType === ShareType.VARIANT ? 'VARIANT' : 'DESTINATION',
      ...(shareAfterType !== ShareType.VARIANT && {
        aggregationLevel: shareAfterType === ShareType.REGION ? 'ROUTING_REGION' : 'ROUTING_ZONE'
      }),
      assignments: assignments
    };
  };

  return (
    <DHLModal
      name="confirm"
      title={t`palletMove`}
      content={<MovePalletContent order={props.order} />}
      actionbar={
        <>
          <div ref={backWrapperRef}>
            <DHLButton
              name={'cancel-button'}
              label={t(`cancel`)}
              onClick={() => {
                props.onCancel();
              }}
              type={'default'}
            />
          </div>
          <Button
            variant={activeSpinner ? 'default' : 'primary'}
            dataTestId="move-pallet-button"
            onClick={async () => {
              const moveProps = movePalletProps();
              /* istanbul ignore next */
              try {
                setActiveSpinner(true);
                deleteAlerts();
                const { data } = await movePallet(moveProps);
                const dpResponse = data.response;
                setActiveSpinner(false);
                props.onCancel();
                if (dpResponse?.resultStatus !== 'ERROR') {
                  setDirty(false);
                  addAlert({
                    type: AlertTypes.Success,
                    title: t('alerts.success'),
                    description: dpResponse.message
                  });
                  props.order?.orderId && (await loadNewOrder(props.order?.orderId));
                } else {
                  dpResponse?.amErrors?.forEach((error) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory}!`,
                      description: error.errorDescription
                    });
                  });
                }
              } catch (e) {
                console.error(e);
                setActiveSpinner(false);
                props.onCancel();
                addAlert({
                  type: AlertTypes.Error,
                  title: `ERROR!`,
                  description: t('errors.palletMoveResponseErrors')
                });
              }
            }}
            type="button"
            label={t('palletMove')}
            activeSpinner={activeSpinner}
            style={{ minWidth: '275px' }}
            disabled={!palletMovementValid}
          />
        </>
      }
      show={props.show}
      onClickClose={() => {
        props.onCancel();
      }}
    />
  );
};
