export const computeBLChunks = (orderIds: string[]) => {
  // Do nothing if input array is empty
  if (orderIds?.length === 0) {
    return [];
  }

  // Max no. of parallel bulkloads is 10
  const maxChunkSize = 10;

  const chunks: string[][] = [];
  let chunkSize = 0;

  // Reusable function to split the array into chunks
  const iterator = (orderIds: string[], chunkSize: number) => {
    for (let i = 0; i < orderIds?.length; i += chunkSize) {
      chunks.push(orderIds?.slice(i, i + chunkSize));
    }
  };

  // Finding the highest divisble int for a given input array length, this length is used to split the input array into 2 parts
  const reminder = orderIds?.length % maxChunkSize;

  // Case 1 : if input array length is not divisble by 10 and input array length is greater than maxChunkSize
  if (reminder !== 0 && orderIds?.length > maxChunkSize) {
    // split the array into two parts p1 and p2, eg: 999 -> 990 and 9
    const p1 = orderIds.slice(0, orderIds.length - reminder); // p1 has orderIds from 0 to orderIds.length - reminder (990)
    const p2 = orderIds.slice(orderIds?.length - reminder, orderIds.length); // p2 has remaining orderIds (9)
    chunkSize = Math.ceil(p1?.length / maxChunkSize);

    // Split the p1 array into chunks
    iterator(p1, chunkSize);

    // Add the remaining orderIds to each of the chunks
    chunks?.forEach((chunk) => {
      if (p2?.length > 0) {
        chunk?.push(p2.pop() as string); // pop the last element from p2 and add it to the chunk
      }
    });
  }
  // Case 2 : if input array length is divisble by 10 or input array length is less than maxChunkSize
  else {
    // If input array length is <= maxChunkSize, chunkSize is always 1
    chunkSize = orderIds?.length <= maxChunkSize ? 1 : orderIds?.length / maxChunkSize;
    iterator(orderIds, chunkSize);
  }

  return chunks;
};
