import React, { FC } from 'react';
import classes from './error-message-container.module.css';

type Props = {
  message: string;
  dataTestId: string;
  classes?: string;
};
export const ErrorMessageContainer: FC<Props> = (props: any) => {
  return (
    <div className={props.classes ? props.classes : classes.errorContainer} data-testid={props.dataTestId}>
      {props.message}
    </div>
  );
};
