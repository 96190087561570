import { OrderSearchResultRepTDO } from 'order/common/services/OrderService';
import { OrderSearchType } from '../dtos/OrderSearchType';
import { HistoryItem } from '../dtos/HistoryItem';
import { OrderSearchAttribute } from '../../../../orderSearch/services/orderSearchAttribute';
import { OrderTableConfiguration } from '../dtos/OrderTableConfiguration';
import { SearchState } from '../dtos/SearchState';
import { PersistColumn } from '../hooks/useSearchPersistence';
import { Sort } from 'common/components/Table/dtos/Sort';
import { Filter } from 'common/components/Table/dtos/Filter';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';

export type SearchReducerState = {
  cancelOrderStatus: string | undefined;
  currentSearch: SearchState;
  currentSearches: SearchState[];
  currentType: OrderSearchType;
  loading: boolean;
  persistedSearches: HistoryItem[];
  persistedColumns: PersistColumn[];
  previousSearch: SearchState;
  updatedAt: number;
  action?: SearchActions;
  myOrders?: MyOrder[];
  lastSearch: [OrderSearchType, OrderSearchAttribute[], Filter | undefined, Sort | undefined];
  metadata?: Record<string, any>;
};

export enum SearchActions {
  SET_LOADING = 'setLoading',
  SET_CANCEL_ORDER_STATUS = 'setCancelOrderStatus',
  SET_CURRENT_SEARCH = 'setCurrentSearch',
  SET_CURRENT_SEARCH_ID = 'setCurrentSearchId',
  SET_CURRENT_SEARCH_DIRTY = 'setCurrentSearchDirty',
  SET_CURRENT_SEARCH_UPDATED = 'setCurrentSearchUpdated',
  SET_CURRENT_SEARCH_ORDERS = 'setCurrentSearchOrders',
  SET_CURRENT_SEARCH_RAW_ORDERS = 'setCurrentSearchRawOrders',
  SET_CURRENT_SEARCH_ATTRIBUTES = 'setCurrentSearchAttributes',
  SET_CURRENT_SEARCH_TABLE_CONFIG = 'setCurrentSearchTableConfig',
  SET_CURRENT_SEARCHES = 'setCurrentSearches',
  SET_CURRENT_TYPE = 'setCurrentType',
  SET_ORDERS = 'setOrders',
  SET_PERSISTED_SEARCHES = 'setPersistedSearches',
  SET_PERSISTED_COLUMNS = 'setPersistedColumns',
  SET_PREVIOUS_SEARCH = 'setPreviousSearch',
  SET_STATE = 'setState',
  SET_UPDATED_AT = 'setUpdatedAt',
  SET_MYORDERS = 'setMyOrders',
  UPSERT_META_DATA = 'upsertMetaData'
}

export type Action =
  | { type: SearchActions.SET_LOADING; data: boolean }
  | { type: SearchActions.SET_CANCEL_ORDER_STATUS; data: string | undefined }
  | { type: SearchActions.SET_CURRENT_SEARCH; data: SearchState }
  | { type: SearchActions.SET_CURRENT_SEARCH_ATTRIBUTES; data: OrderSearchAttribute[] }
  | { type: SearchActions.SET_CURRENT_SEARCH_ID; data: string }
  | { type: SearchActions.SET_CURRENT_SEARCH_DIRTY; data: boolean }
  | { type: SearchActions.SET_CURRENT_SEARCH_ORDERS; data: OrderSearchResultRepTDO | undefined }
  | { type: SearchActions.SET_CURRENT_SEARCH_RAW_ORDERS; data: OrderSearchResultRepTDO | undefined }
  | { type: SearchActions.SET_CURRENT_SEARCH_TABLE_CONFIG; data: OrderTableConfiguration }
  | { type: SearchActions.SET_CURRENT_SEARCH_UPDATED; data: boolean }
  | { type: SearchActions.SET_CURRENT_SEARCHES; data: SearchState[] }
  | { type: SearchActions.SET_CURRENT_TYPE; data: OrderSearchType }
  | { type: SearchActions.SET_ORDERS; data: OrderSearchResultRepTDO | undefined }
  | { type: SearchActions.SET_PERSISTED_SEARCHES; data: HistoryItem[] }
  | { type: SearchActions.SET_PERSISTED_COLUMNS; data: PersistColumn[] }
  | { type: SearchActions.SET_PREVIOUS_SEARCH; data: SearchState }
  | { type: SearchActions.SET_STATE; data: SearchReducerState }
  | { type: SearchActions.SET_UPDATED_AT; data: number }
  | { type: SearchActions.SET_MYORDERS; data: MyOrder[] }
  | { type: SearchActions.UPSERT_META_DATA; data: { key: string; value: unknown } };

export const defaultSearch: SearchState = {
  active: true,
  attributes: [],
  dirty: false,
  id: undefined,
  orders: undefined,
  rawOrders: undefined,
  tableConfig: {
    hidden: {}
  },
  type: undefined,
  updated: false,
  loading: false,
  filter: undefined,
  sort: undefined
};

export const defaultSearchState: SearchReducerState = {
  cancelOrderStatus: undefined,
  currentSearch: {
    attributes: [],
    tableConfig: {},
    type: OrderSearchType.SIMPLE,
    orders: {},
    loading: false
  },
  currentSearches: [],
  currentType: OrderSearchType.SIMPLE,
  loading: false,
  persistedSearches: [],
  persistedColumns: [],
  previousSearch: {
    ...defaultSearch,
    active: false
  },
  myOrders: [],
  updatedAt: new Date().getTime(),
  lastSearch: [OrderSearchType.SIMPLE, [], undefined, undefined]
};

export function searchReducer(state: SearchReducerState, action: Action): SearchReducerState {
  // console.debug('searchReducer', action.type, action.data);
  switch (action.type) {
    case SearchActions.SET_LOADING: {
      return {
        ...state,
        loading: action.data,
        currentSearch: {
          ...state.currentSearch,
          loading: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CANCEL_ORDER_STATUS: {
      return {
        ...state,
        cancelOrderStatus: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          ...action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_ID: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          id: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_DIRTY: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          dirty: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_ORDERS: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          orders: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_RAW_ORDERS: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          rawOrders: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_UPDATED: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          updated: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_STATE: {
      return {
        ...state,
        ...action.data
      };
    }
    case SearchActions.SET_CURRENT_SEARCHES: {
      return {
        ...state,
        currentSearches: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_TYPE: {
      return {
        ...state,
        currentType: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_ORDERS: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          orders: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_PREVIOUS_SEARCH: {
      return {
        ...state,
        previousSearch: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_PERSISTED_SEARCHES: {
      return {
        ...state,
        persistedSearches: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_PERSISTED_COLUMNS: {
      return {
        ...state,
        persistedColumns: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_UPDATED_AT: {
      return {
        ...state,
        updatedAt: action.data,
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          attributes: action.data
        },
        action: action.type
      };
    }
    case SearchActions.SET_CURRENT_SEARCH_TABLE_CONFIG: {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          tableConfig: {
            ...state.currentSearch.tableConfig,
            ...action.data
          }
        },
        action: action.type
      };
    }
    case SearchActions.SET_MYORDERS: {
      return {
        ...state,
        myOrders: action.data,
        action: action.type
      };
    }
    case SearchActions.UPSERT_META_DATA: {
      const metadata = state.metadata ?? {};
      metadata[action.data.key] = action.data.value;
      return {
        ...state,
        metadata
      };
    }
    default:
      return defaultSearchState;
  }
}
