// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PressStep3_marginTop26__SX4IS {
  margin-top: 26px;
}
.PressStep3_payerContainer__Se\\+KW {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/press/workFlowSteps/step3/PressStep3.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".marginTop26 {\n  margin-top: 26px;\n}\n.payerContainer {\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginTop26": `PressStep3_marginTop26__SX4IS`,
	"payerContainer": `PressStep3_payerContainer__Se+KW`
};
export default ___CSS_LOADER_EXPORT___;
