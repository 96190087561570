import { determineLetterTypeByFormat } from '../../../common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import classNames from 'classnames';
import { ServiceClusterRep } from 'generated';
import styles from '../PSLBillingTable/pslbilling-table.module.css';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';

export type PSLCorrectionBillingTableRowProps = {
  index: number;
  item: ServiceClusterRep;
};

export const PSLCorrectionBillingTableRow: FC<PSLCorrectionBillingTableRowProps> = ({ item, index }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const { user } = useAuthContext();
  const pricePos = item.pricePositions && item.pricePositions[0];
  return (
    <tr key={'row-data-' + index} className={classNames(styles.parentRow, 'dhlTable-row dhlTable-row-default leaf')}>
      <td>{index + 1}</td>
      <td>
        {determineLetterTypeByFormat(item.baseProductFormat ?? '')
          ? determineLetterTypeByFormat(item.baseProductFormat ?? '')
          : item.baseProductFormat === 'FSR'
          ? t('postageDiscount')
          : ''}
      </td>
      <td className={styles.rightAlign}>{pricePos?.qty?.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
      <td className={styles.rightAlign}>
        {pricePos?.unitAmount?.value?.toLocaleString(user.language, {
          style: 'currency',
          currency: pricePos?.unitAmount?.currency,
          maximumFractionDigits: 3
        })}
      </td>
      <td className={styles.rightAlign}>{pricePos?.taxRate && pricePos.taxRate + '%'}</td>
      <td className={styles.rightAlign}>
        {pricePos?.amount?.value?.toLocaleString(user.language, {
          style: 'currency',
          currency: pricePos?.amount?.currency,
          maximumFractionDigits: 3
        })}
      </td>
    </tr>
  );
};
