import React, { FC } from 'react';
import { LetterBaseProductSection } from 'order/common/components/Overview/LetterBaseProductSection/LetterBaseProductSection';
import { PostingInfoSection } from 'order/common/components/Overview/PostingInfoSection/PostingInfoSection';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';
import { OrderContentTabProps } from '../OrderContentTab';
import { OrderCategoryProductKey } from '../../../../../common/context/order/dtos/OrderCategoryProductKey';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { UseDVBriefProductsReturn, useDVBriefProducts } from 'order/productGroups/letter/utils/useDVBriefProducts';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { useTranslation } from 'i18n';

export const LetterTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { order: rawOrder } = useOrderContext();
  const isDVBrief = order.orderCategoryProductKey === OrderCategoryProductKey.DV_BRIEF;
  const isAfmBrief = order.orderCategoryProductKey === OrderCategoryProductKey.AFM_BRIEF;
  const date = order?.orderDetail?.date ? new Date(order?.orderDetail?.date) : new Date();
  const { premium } = useDependentProducts(order?.productGroup, date, order?.letterBaseProducts?.[0].baseProduct?.productNumber);
  const premiumNumbers = premium.map((p) => p.productNumber);
  const productNumber = order?.dependentProducts?.find((lBP) => premiumNumbers.includes(lBP.productNumber))?.productNumber;

  const baseProducts = useBaseProducts(order?.letterProductGroup || ProductGroup.BRIEF, date);
  const {
    dvBrief,
    dvInternational,
    dvPZA,
    dvEPostBrief,
    dvStrip,
    dvBook,
    dvBlind,
    dvDVD,
    dvEPostBriefWithPartialService,
    dvInternationalList,
    dvBriefList,
    dvEPostBriefList,
    dvBlindList,
    dvDVDList,
    dvPZAList,
    dvStripList,
    dvBookList
  } = useDVBriefProducts(baseProducts, order.letterBaseProducts as any, isDVBrief);

  // Delivery Option Handling - Should only be shown if the order is a DV Brief or if the order is a E-PostBrief with a partial service capable base product
  const isDeliveryOptionAvailable = dvEPostBriefWithPartialService || dvBrief || !isDVBrief;

  // Aditional Services Handling - Should not be shown if the order is not PZA or Book or not a DV Brief
  const isAdditionalServiceAvailable = (!dvPZA && !dvBook) || !isDVBrief;

  const dvBriefProducts: UseDVBriefProductsReturn = {
    dvBrief: dvBrief,
    dvInternational: dvInternational,
    dvPZA: dvPZA,
    dvEPostBrief: dvEPostBrief,
    dvStrip: dvStrip,
    dvBook: dvBook,
    dvBlind: dvBlind,
    dvDVD: dvDVD,
    dvEPostBriefWithPartialService: dvEPostBriefWithPartialService,
    dvBriefList: dvBriefList,
    dvInternationalList: dvInternationalList,
    dvPZAList: dvPZAList,
    dvEPostBriefList: dvEPostBriefList,
    dvStripList: dvStripList,
    dvBookList: dvBookList,
    dvBlindList: dvBlindList,
    dvDVDList: dvDVDList
  };

  return (
    <div data-testid={'order-content-tab-letter-testid'}>
      <LetterBaseProductSection dvBriefProducts={dvBriefProducts} value={order} />
      {(isDeliveryOptionAvailable || isAdditionalServiceAvailable) && (
        <OrderOverviewRow>
          {isDeliveryOptionAvailable && (
            <OrderOverviewWidget title={t('deliveryOption')}>
              {rawOrder?.otherAttributes?.ZOP ? (order?.otherAttributes?.ZOP === 'std' ? 'E+1' : 'E+1 bis 2') : ''}
            </OrderOverviewWidget>
          )}
          {isAdditionalServiceAvailable && (
            <OrderOverviewWidget title={t('additionalServices')}>
              {<p>{premium.find((p) => p.productNumber === productNumber)?.descriptionShort}</p>}
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
      )}
      <PostingInfoSection value={order} />
      <CustomerDetailSection parties={order?.parties} withAddress={isAfmBrief} validityDate={order?.orderDetail?.date} />
    </div>
  );
};
