import React, { ReactElement, useEffect, useState } from 'react';
import { FieldValues, useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { ObjectContaining, PathsEndingWith } from 'common/utils/formHelpers';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import styles from '../../SplitOrder/SplitOrderContent/SplitOrderContent.module.css';
import '../../../../../common/styles/dhlComponentCustomization.css';
import { DateInput } from '../../../../productGroups/common/components/atom/DateInput';
import { TimeCitySchema } from '../../../../productGroups/common/components/molecule/TimeCitySection';
import { CustomerSection } from '../../../../productGroups/common/components/molecule/CustomerSection';
import { OrderSearchKey } from '../../../../common/context/search/dtos/OrderSearchKey';
import { Order } from '../../../../common/context/order/dtos/Order';
import { OrderCustomerRole } from '../../../../common/context/order/dtos/OrderCustomerRole';
import { shuttleData } from './movePalletDataHelper';
import { Shuttle, useShuttleState } from 'common/customComponent/select-many-shuttle';
import 'common/customComponent/select-many-shuttle/styles/shuttle.css';
import { ShuttleState } from 'common/customComponent/select-many-shuttle/components/Shuttle/hooks/useShuttleState';
import { requestOrderSearchData } from '../../../../common/context/search/fetcher/requestOrderSearch';
import { OrderSearchType } from '../../../../common/context/search/dtos/OrderSearchType';
import { OrderSearchResultRepTDO } from '../../../../common/services/OrderService';
import { getOrder } from '../../../../common/context/order/utils/getOrder';
import { OrderResponse } from '../../../../common/context/order/dtos/GetOrder';
import { ShareType } from '../../SplitOrder/SplitOrderContent/SplitOrderContent';
import { useBoolean } from '../../../../../common/hooks/useBoolean';
import { LoadingOrder } from '../../../../../common/components/LoadingOrder/LoadingOrder';
import classNames from 'classnames';
import moment from 'moment';

export type MovePallet = {
  orderDate: Date;
  order2Date: Date;
  submitter: string;
  submitter2: string;
  selectedDestination: LooseObjectDest;
  targetOrders: Array<string>;
  shareAfterType: ShareType;
  order2: string;
  palletMovementValid: boolean;
};

interface MovePalletContentProps {
  order: Order;
}
type selectDestinationType = {
  targetOrder: string;
  lastChangedOn: string;
  destination: Array<{ key: string; value: string; sourceOrder: SourceOrder }>;
};
interface LooseObjectOrders {
  [key: string]: Order;
}
interface LooseObjectDest {
  [key: string]: selectDestinationType;
}
export type SourceOrder = {
  orderNumber: string;
  lastChangedOn: string;
};
export const updateData = (
  state: ShuttleState,
  action: {
    type: string;
    data: Array<{ key: string; value: string; sourceOrder: SourceOrder }>;
  }
): ShuttleState => {
  switch (action.type) {
    case 'source':
      state.source = action.data;
      return { ...state };
    case 'target':
      state.target = action.data;
      return { ...state };
    default:
      return { ...state };
  }
};
const order2Data: LooseObjectOrders = {};
export const MovePalletContent = <T extends FieldValues>({ order }: MovePalletContentProps): ReactElement => {
  const { t } = useTranslation('', { keyPrefix: 'splitOrder' });
  const { t: oc } = useTranslation('orderCreate');
  const [showOrderDiff, setShowOrderDiff] = useBoolean(true);
  const { setValue, control, formState } = useFormContext<MovePallet>();
  const { fieldState, field: dateField } = useController<ObjectContaining<TimeCitySchema>, PathsEndingWith<'date'>>({ name: `${name}.date` });
  const [orderTreeValues, setOrderTreeValues] = useState<Array<string>>([]);
  const [dataLoaded, setDataLoaded] = useBoolean(false);
  const destObjs: LooseObjectDest = {};
  const shareAfterOptions = Object.values(ShareType).filter(
    (item) => item !== ShareType.VARIANT || (item === ShareType.VARIANT && order.constraints?.splittableByVariant)
  );
  const [targetOrders, selectedDestination, shareAfterType, order2, palletMovementValid] = useWatch({
    control,
    name: ['targetOrders', 'selectedDestination', 'shareAfterType', 'order2', 'palletMovementValid']
  });
  const shuttle = useShuttleState({
    source: order?.orderId && selectedDestination ? selectedDestination[order?.orderId]?.destination : [],
    target: selectedDestination ? selectedDestination[order2]?.destination : []
  });
  const [emptyOrderWarning, setEmptyOrderWarning] = useBoolean(false);
  useEffect(() => {
    const orderTreeProps: Array<string> = [];
    if (order.hasOrderTree && orderTreeValues.length === 0) {
      requestOrderSearchData(
        OrderSearchType.PARTIAL,
        [
          {
            key: OrderSearchKey.OrderId,
            value: order.orderId ?? ''
          }
        ],
        {}
      ).then((data: OrderSearchResultRepTDO) => {
        data?.orders?.forEach((item, itemIndex) => {
          const elemFound = item?.children?.find((element) => element?.data?.orderNumber === order.orderId);
          if (elemFound) {
            item?.children?.forEach((childItem) => {
              childItem?.data?.state === 'PLA' &&
                childItem?.data?.orderNumber &&
                childItem?.data?.orderNumber !== order.orderId &&
                orderTreeProps?.push(childItem?.data?.orderNumber);
            });
          } else {
            item?.children?.forEach((childItem) => {
              const elemFound = childItem?.children?.find((element) => element?.data?.orderNumber === order.orderId);
              if (elemFound) {
                childItem?.children?.forEach((secondLevelChildItem) => {
                  secondLevelChildItem?.data?.state === 'PLA' &&
                    secondLevelChildItem?.data?.orderNumber &&
                    secondLevelChildItem?.data?.orderNumber !== order.orderId &&
                    orderTreeProps?.push(secondLevelChildItem?.data?.orderNumber);
                });
              }
            });
          }
        });
        orderTreeProps.forEach((orderId) => {
          const order2DataProm = getOrder2Data(orderId);
          order2DataProm.then((res) => {
            if (res) order2Data[orderId] = res as unknown as Order;
            if (orderTreeProps.every((v) => Object.keys(order2Data).includes(v))) {
              setOrderTreeValues(orderTreeProps ?? []);
            }
          });
        });
        if (orderTreeProps.length === 0) setDataLoaded.on();
      });
    } else {
      setDataLoaded.on();
    }
    return () => {
      setValue('targetOrders', []);
      setValue('selectedDestination', {});
      setValue('palletMovementValid', false);
      setEmptyOrderWarning.off();
      setValue('order2', '');
    };
  }, []);
  useEffect(() => {
    const targetOrders: Array<string> = [];
    if (orderTreeValues.length && order?.orderId) {
      destObjs[order?.orderId] = {
        targetOrder: order?.orderId,
        lastChangedOn: order?.lastChangedOn || '',
        destination: shuttleData(shareAfterType, order)
      };
      targetOrders.push(order?.orderId);
      orderTreeValues.forEach((v) => {
        destObjs[v] = {
          targetOrder: order2Data[v]?.orderId || '',
          lastChangedOn: order2Data[v]?.lastChangedOn || '',
          destination: shuttleData(shareAfterType, order2Data[v])
        };
        targetOrders.push(v);
      });
      setValue('targetOrders', targetOrders);
      setValue('selectedDestination', destObjs);
      setValue('order2', orderTreeValues[0]);
      setDataLoaded.on();
      order?.orderId && updateData(shuttle.shuttleState, { type: 'source', data: destObjs[order?.orderId]?.destination || [] });
      updateData(shuttle.shuttleState, { type: 'target', data: destObjs[order2]?.destination || [] });
    }
  }, [orderTreeValues, shareAfterType]);
  useEffect(() => {
    setValue('orderDate', order.orderDetail?.date || new Date());
    setValue('submitter', order.parties.find((p) => p.role === OrderCustomerRole.Submitter)?.customerId || '');
    setValue('shareAfterType', ShareType.ZONEANDREGION);
  }, [order]);
  useEffect(() => {
    if (orderTreeValues.every((v) => Object.keys(order2Data).includes(v)) && order2) {
      setValue('submitter2', order2Data[order2]?.parties.find((p: any) => p.role === OrderCustomerRole.Submitter)?.customerId || '');
      setValue('order2Date', order2Data[order2]?.orderDetail?.date || new Date());
      order?.orderId && updateData(shuttle.shuttleState, { type: 'source', data: selectedDestination[order?.orderId]?.destination || [] });
      updateData(shuttle.shuttleState, { type: 'target', data: selectedDestination[order2]?.destination || [] });
      setShowOrderDiff.on();
    }
  }, [order2]);

  useEffect(() => {
    if (order?.orderId && selectedDestination) {
      selectedDestination[order?.orderId] = {
        targetOrder: order?.orderId,
        lastChangedOn: order?.lastChangedOn || '',
        destination: shuttle.shuttleState.source
      };
      selectedDestination[order2] = {
        targetOrder: order2Data[order2]?.orderId || '',
        lastChangedOn: order2Data[order2]?.lastChangedOn || '',
        destination: shuttle.shuttleState.target
      };
      setValue('selectedDestination', selectedDestination);
    }
    setValue('palletMovementValid', false);
    setEmptyOrderWarning.off();
    targetOrders?.forEach((orderId) => {
      selectedDestination[orderId]?.destination.forEach((destObj) => {
        if (destObj.sourceOrder.orderNumber !== selectedDestination[orderId].targetOrder) {
          setValue('palletMovementValid', true);
        }
      });
    });
    if (orderTreeValues.length && (shuttle.shuttleState.source.length === 0 || shuttle.shuttleState.target.length === 0)) setEmptyOrderWarning.on();
  }, [shuttle.shuttleState]);

  async function getOrder2Data(id: string) {
    if (id) return await getOrder<OrderResponse>(id);
  }

  if (!dataLoaded) {
    return <LoadingOrder headline={''} />;
  }

  return (
    <>
      <FormRow mode="two-col" className={styles.dividerPosition}>
        <OrderStepSection headline={t('order1')}>
          <div className={styles.firstOrder}>
            <AMDHLSelectSingle
              name={'shareAfter'}
              data-testid={'share-after'}
              placeholder={t('shareAfter')}
              items={shareAfterOptions}
              valueToString={(item) => t(item)}
              value={shareAfterType}
              disabled={false}
              onChange={(item) => {
                /* istanbul ignore next */
                setValue('shareAfterType', (item as ShareType) ?? 'zoneAndRegion');
                setValue('palletMovementValid', false);
                order?.orderId && updateData(shuttle.shuttleState, { type: 'source', data: selectedDestination[order?.orderId]?.destination || [] });
              }}
            />
          </div>
          {palletMovementValid && (
            <div className={classNames(styles.changedOrder, styles.firstOrder)} style={{ marginTop: '10px' }}>
              {t('errors.shareAfterWarning')}
            </div>
          )}
        </OrderStepSection>
        <div className={styles.headerDivider}></div>
        <div className={styles.secondOrder}>
          <OrderStepSection headline={t('order2')} />
          <AMDHLSelectSingle
            name={'order2'}
            data-testid={'share-after'}
            placeholder={t('selectOrder')}
            items={orderTreeValues}
            valueToString={(item) =>
              item +
              (order2Data[item]?.submissionId && ', TE-' + order2Data[item]?.submissionId) +
              (order2Data[item]?.orderDetail?.date && ', ' + moment(order2Data[item]?.orderDetail?.date).format('DD.MM.yyyy'))
            }
            value={order2}
            onChange={(item) => {
              /* istanbul ignore next */
              setValue('order2', item || '');
              setShowOrderDiff.off();
            }}
          />
        </div>
      </FormRow>
      <Shuttle {...shuttle}>
        <Shuttle.Container name={'source'} headline1={shareAfterType === 'variant' ? t('designation') : t('line')} headline2={t('numberOfPallets')}>
          {({ source, selected }, getItemProps) =>
            source.map((item, index) => (
              <Shuttle.Item
                {...getItemProps(index)}
                key={item.key + item.sourceOrder.orderNumber}
                value={item.value}
                selected={selected.source.has(index)}
              >
                <FormRow mode="two-col" style={{ margin: '8px 0', gridTemplateColumns: '10fr 2fr' }}>
                  <span>
                    {item.key}
                    <span className={styles.changedOrder}>
                      {order.orderId !== item.sourceOrder.orderNumber &&
                        showOrderDiff &&
                        ' (' +
                          item.sourceOrder.orderNumber +
                          ', TE-' +
                          item.submissionId +
                          ', ' +
                          moment(item.postageDate).format('DD.MM.yyyy') +
                          ')'}
                    </span>
                  </span>
                  <span style={{ textAlign: 'right' }}>{item.value}</span>
                </FormRow>
              </Shuttle.Item>
            ))
          }
        </Shuttle.Container>
        <Shuttle.Controls />
        <Shuttle.Container name={'target'} headline1={shareAfterType === 'variant' ? t('designation') : t('line')} headline2={t('numberOfPallets')}>
          {({ target, selected }, getItemProps) =>
            target.map((item, index) => (
              <Shuttle.Item
                {...getItemProps(index)}
                key={item.key + item.sourceOrder.orderNumber}
                value={item.value}
                selected={selected.target.has(index)}
              >
                <FormRow mode="two-col" style={{ margin: '8px 0', gridTemplateColumns: '10fr 2fr' }}>
                  <span>
                    {item.key}
                    <span className={styles.changedOrder}>
                      {order2 !== item.sourceOrder.orderNumber &&
                        showOrderDiff &&
                        ' (' +
                          item.sourceOrder.orderNumber +
                          ', TE-' +
                          item.submissionId +
                          ', ' +
                          moment(item.postageDate).format('DD.MM.yyyy') +
                          ')'}
                    </span>
                  </span>
                  <span style={{ textAlign: 'right' }}>{item.value}</span>
                </FormRow>
              </Shuttle.Item>
            ))
          }
        </Shuttle.Container>
      </Shuttle>
      {emptyOrderWarning && (
        <div data-testid="shuttle-error" className="fieldNote" style={{ margin: '-10px 0 0 0' }}>
          {t('errors.emptyOrderWarning')}
        </div>
      )}
      <FormRow mode="two-col" className={styles.dividerPosition}>
        <OrderStepSection headline={t('deliveryDate')}>
          <div className={styles.firstOrder}>
            <DateInput name={`orderDate`} label={`${t('date')}`} disabled={true} error={fieldState.error ? 'invalid' : undefined} />
          </div>
          <div className={styles.firstOrder}>
            <OrderStepSection headline={t('consignor')} />
            <CustomerSection<MovePallet>
              inputName="submitter"
              customerRole={OrderSearchKey.Submitter}
              errorMessage={oc('error.customerIdUnknown')}
              infoPosition={'one-col'}
              disabled={true}
            />
          </div>
        </OrderStepSection>
        <div className={styles.headerDivider}></div>
        <div className={styles.secondOrder}>
          <OrderStepSection headline={t('deliveryDate')} />
          <DateInput
            name={`order2Date`}
            label={`${t('date')}`}
            error={fieldState.error ? 'invalid' : undefined}
            disabled={true}
            rules={{
              required: false
            }}
          />
          <div>
            <OrderStepSection headline={t('consignor')} />
            <CustomerSection<MovePallet>
              inputName="submitter2"
              customerRole={OrderSearchKey.Submitter}
              errorMessage={oc('error.customerIdUnknown')}
              infoPosition={'one-col'}
              disabled={true}
            />
          </div>
        </div>
      </FormRow>
    </>
  );
};
