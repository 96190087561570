import React from 'react';
import classNames from 'classnames';

import styles from './pagination.module.css';

interface Props {
  active: boolean;
  labelId: string | number;
  label: number | string;
  onSelect: (number: number | string) => void;
}

export const PageElement = (props: Props): JSX.Element => {
  return (
    <li
      className={classNames(styles.paginationItem, { [styles.currentPage]: props.active, [styles.linkToPage]: !props.active })}
      data-testid={`pagination-button-list-item-${props.labelId}`}
      key={`pagination-page-element-${props.labelId}`}
    >
      {props.active ? (
        <span
          className={classNames({ [styles.currentPage]: props.active }, styles.paginationItem)}
          data-testid={`pagerName-selectedpage-${props.labelId}`}
        >
          {props.label}
        </span>
      ) : (
        <button
          onClick={() => props.onSelect(props.label)}
          data-testid={`pagerName-page-element-button-${props.labelId}`}
          className={classNames(styles.paginationItem, styles.pageButton)}
        >
          {props.label}
        </button>
      )}
    </li>
  );
};
