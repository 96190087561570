import dropdownClasses from 'common/styles/common.module.css';
import { reference } from 'common/utils/helpers';
import React, { ButtonHTMLAttributes } from 'react';
import multiListClasses from './MultiList.module.css';

export type ItemProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
};

export const Item = reference<HTMLButtonElement, ItemProps>(
  'Item',
  'button',
  ({ active }) => [multiListClasses.item, dropdownClasses.textOverflow, { [multiListClasses.active]: active }],
  ({ active, type = 'button', ...props }) => ({ type, ...props })
);
