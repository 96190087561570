import { QtyAllocation } from 'generated';
import { LetterTypes } from 'order/common/components/QtyAllocation/dtos/LetterTypes';

export enum discountsPerLetterType {
  // Standard letter
  dv_s_false_false = 'dv-s-false-false',
  dv_s_true_false = 'dv-s-true-false',
  dv_s_true_true = 'dv-s-true-true',
  afm_s_false_false = 'afm-s-false-false',
  afm_s_true_false = 'afm-s-true-false',
  afm_s_true_true = 'afm-s-true-true',
  // Compact letter
  dv_k_false_false = 'dv-k-false-false',
  dv_k_true_false = 'dv-k-true-false',
  dv_k_true_true = 'dv-k-true-true',
  afm_k_false_false = 'afm-k-false-false',
  afm_k_true_false = 'afm-k-true-false',
  afm_k_true_true = 'afm-k-true-true',
  // Large letter
  dv_g_false_false = 'dv-g-false-false',
  dv_g_true_false = 'dv-g-true-false',
  dv_g_true_true = 'dv-g-true-true',
  afm_g_false_false = 'afm-g-false-false',
  afm_g_true_false = 'afm-g-true-false',
  afm_g_true_true = 'afm-g-true-true',
  // Maxi letter
  dv_m_false_false = 'dv-m-false-false',
  dv_m_true_false = 'dv-m-true-false',
  dv_m_true_true = 'dv-m-true-true',
  afm_m_false_false = 'afm-m-false-false',
  afm_m_true_false = 'afm-m-true-false',
  afm_m_true_true = 'afm-m-true-true',
  // Postcard
  dv_p_false_false = 'dv-p-false-false',
  dv_p_true_false = 'dv-p-true-false',
  dv_p_true_true = 'dv-p-true-true',
  afm_p_false_false = 'afm-p-false-false',
  afm_p_true_false = 'afm-p-true-false',
  afm_p_true_true = 'afm-p-true-true'
}

// Function to compute the quantities of each letter type, postage type and discount type
export const calLetterDiscountsWithType = (qtyAllocations: (QtyAllocation | undefined)[] | undefined) => {
  const discounts: any = {};
  qtyAllocations?.forEach((qa) => {
    if (qa?.format) {
      const key = `${qa?.postageType}-${qa?.format}-${qa?.containsIds}-${qa?.flexOption}`?.toLowerCase();
      if (qa?.quantity) {
        discounts[key] = (discounts[key] || 0) + qa?.quantity;
      }
    }
  });

  const standardTotal =
    (discounts[discountsPerLetterType.dv_s_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_s_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_s_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_s_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_s_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_s_true_true] || 0);

  const compactTotal =
    (discounts[discountsPerLetterType.dv_k_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_k_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_k_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_k_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_k_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_k_true_true] || 0);

  const largeTotal =
    (discounts[discountsPerLetterType.dv_g_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_g_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_g_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_g_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_g_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_g_true_true] || 0);

  const maxiTotal =
    (discounts[discountsPerLetterType.dv_m_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_m_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_m_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_m_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_m_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_m_true_true] || 0);

  const postcardTotal =
    (discounts[discountsPerLetterType.dv_p_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_p_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_p_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_p_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_p_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_p_true_true] || 0);

  const dvFFTotal =
    (discounts[discountsPerLetterType.dv_s_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_k_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_g_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_m_false_false] || 0) +
    (discounts[discountsPerLetterType.dv_p_false_false] || 0);
  const dvTFTotal =
    (discounts[discountsPerLetterType.dv_s_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_k_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_g_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_m_true_false] || 0) +
    (discounts[discountsPerLetterType.dv_p_true_false] || 0);
  const dvTTTotal =
    (discounts[discountsPerLetterType.dv_s_true_true] || 0) +
    (discounts[discountsPerLetterType.dv_k_true_true] || 0) +
    (discounts[discountsPerLetterType.dv_g_true_true] || 0) +
    (discounts[discountsPerLetterType.dv_m_true_true] || 0) +
    (discounts[discountsPerLetterType.dv_p_true_true] || 0);
  const afmFFTotal =
    (discounts[discountsPerLetterType.afm_s_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_k_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_g_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_m_false_false] || 0) +
    (discounts[discountsPerLetterType.afm_p_false_false] || 0);
  const afmTFTotal =
    (discounts[discountsPerLetterType.afm_s_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_k_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_g_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_m_true_false] || 0) +
    (discounts[discountsPerLetterType.afm_p_true_false] || 0);
  const afmTTTotal =
    (discounts[discountsPerLetterType.afm_s_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_k_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_g_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_m_true_true] || 0) +
    (discounts[discountsPerLetterType.afm_p_true_true] || 0);

  const letterDiscountsWithType = {
    [LetterTypes.Standard]: {
      dvFFQty: discounts[discountsPerLetterType.dv_s_false_false] || 0,
      afmFFQty: discounts[discountsPerLetterType.afm_s_false_false] || 0,
      dvTFQty: discounts[discountsPerLetterType.dv_s_true_false] || 0,
      afmTFQty: discounts[discountsPerLetterType.afm_s_true_false] || 0,
      dvTTQty: discounts[discountsPerLetterType.dv_s_true_true] || 0,
      afmTTQty: discounts[discountsPerLetterType.afm_s_true_true] || 0,
      total: standardTotal
    },
    [LetterTypes.Compact]: {
      dvFFQty: discounts[discountsPerLetterType.dv_k_false_false] || 0,
      afmFFQty: discounts[discountsPerLetterType.afm_k_false_false] || 0,
      dvTFQty: discounts[discountsPerLetterType.dv_k_true_false] || 0,
      afmTFQty: discounts[discountsPerLetterType.afm_k_true_false] || 0,
      dvTTQty: discounts[discountsPerLetterType.dv_k_true_true] || 0,
      afmTTQty: discounts[discountsPerLetterType.afm_k_true_true] || 0,
      total: compactTotal
    },
    [LetterTypes.Large]: {
      dvFFQty: discounts[discountsPerLetterType.dv_g_false_false] || 0,
      afmFFQty: discounts[discountsPerLetterType.afm_g_false_false] || 0,
      dvTFQty: discounts[discountsPerLetterType.dv_g_true_false] || 0,
      afmTFQty: discounts[discountsPerLetterType.afm_g_true_false] || 0,
      dvTTQty: discounts[discountsPerLetterType.dv_g_true_true] || 0,
      afmTTQty: discounts[discountsPerLetterType.afm_g_true_true] || 0,
      total: largeTotal
    },
    [LetterTypes.Maxi]: {
      dvFFQty: discounts[discountsPerLetterType.dv_m_false_false] || 0,
      afmFFQty: discounts[discountsPerLetterType.afm_m_false_false] || 0,
      dvTFQty: discounts[discountsPerLetterType.dv_m_true_false] || 0,
      afmTFQty: discounts[discountsPerLetterType.afm_m_true_false] || 0,
      dvTTQty: discounts[discountsPerLetterType.dv_m_true_true] || 0,
      afmTTQty: discounts[discountsPerLetterType.afm_m_true_true] || 0,
      total: maxiTotal
    },
    [LetterTypes.Postcard]: {
      dvFFQty: discounts[discountsPerLetterType.dv_p_false_false] || 0,
      afmFFQty: discounts[discountsPerLetterType.afm_p_false_false] || 0,
      dvTFQty: discounts[discountsPerLetterType.dv_p_true_false] || 0,
      afmTFQty: discounts[discountsPerLetterType.afm_p_true_false] || 0,
      dvTTQty: discounts[discountsPerLetterType.dv_p_true_true] || 0,
      afmTTQty: discounts[discountsPerLetterType.afm_p_true_true] || 0,
      total: postcardTotal
    },
    ['totals']: {
      dvFFQty: dvFFTotal,
      dvTFQty: dvTFTotal,
      dvTTQty: dvTTTotal,
      afmFFQty: afmFFTotal,
      afmTFQty: afmTFTotal,
      afmTTQty: afmTTTotal,
      total: standardTotal + compactTotal + largeTotal + maxiTotal + postcardTotal
    }
  };

  return letterDiscountsWithType;
};
