import React, { ReactElement } from 'react';
import { useSpecialLetterProducts } from '../../../hooks/useSpecialLetterProducts';
import { ALSBaseProductsIntPress, AdditionalLetterBaseProducts } from 'order/productGroups/international/components/AdditionalLetterService';
import { ProductGroup } from 'order/common/dtos/ProductGroup';

export type PressAdditionalServiceLetterProductNameProps = {
  baseProductNumber?: string;
  productNumber?: string;
  selectedDate?: Date;
  productGroup?: string;
};

export const PressAdditionalServiceLetterProductName = ({
  baseProductNumber,
  productNumber,
  selectedDate,
  productGroup
}: PressAdditionalServiceLetterProductNameProps): ReactElement => {
  const addLetServProds = useSpecialLetterProducts(baseProductNumber, selectedDate);

  return (
    <div data-testId={'additional-letter-baseProducts'}>
      {productGroup === ProductGroup.PRESS_DISTRIBUTION
        ? ALSBaseProductsIntPress[
            addLetServProds?.filter((p) => p.productNumber === productNumber)[0]?.descriptionShort as keyof typeof ALSBaseProductsIntPress
          ] + ':'
        : AdditionalLetterBaseProducts[
            addLetServProds?.filter((p) => p.productNumber === productNumber)[0]
              ?.hierarchyProductCharacteristic as keyof typeof AdditionalLetterBaseProducts
          ] + ':'}
    </div>
  );
};
