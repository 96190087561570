export const firstJuly24 = new Date('2024-07-01');
export const firstJuly24AsNumber = firstJuly24.valueOf();

// Reusable fn that returns boolean value for for first July 2024
export const isServiceAllowed = (inputDate?: Date): boolean => {
  if (!inputDate) {
    return false;
  } else {
    const date = new Date(inputDate);
    const dateToNumber = date?.valueOf();
    if (dateToNumber >= firstJuly24AsNumber) {
      return false;
    } else if (date?.toDateString() === firstJuly24?.toDateString()) {
      return false;
    } else {
      return true;
    }
  }
};
