import { getPaymentData, PaymentData, PaymentDataRequest } from 'common/services/PaymentBankInfoService';
import { validateSettlementNumber } from 'common/utils/helpers';
import { isValid as dateIsValid, format as dataFormat } from 'date-fns';
import { useEffect, useState } from 'react';
import useSWR from 'swr/immutable';

export type UsePaymentBankInfoReturn = {
  paymentBankInfo?: PaymentData;
  isLoading: boolean;
};

/**
 * Fetches the payment bank info using the settlement number / postcard number.
 * The settlement number must be exactly (10 numbers + 2 numbers + 2 alphanumeric) or (14 numbers +3 numbers) after trimming.
 *
 * Utilizes the SWR library.
 *
 * @param settlementNumber the settlement number or postcard number
 */
export const usePaymentBankInfo = (settlementNumber?: string, validityDate?: Date): UsePaymentBankInfoReturn => {
  const trimmed = settlementNumber?.replace(/\s+/g, '');
  const isValid = validateSettlementNumber(settlementNumber);
  const validityDateStr = validityDate && dateIsValid(validityDate) ? dataFormat(new Date(validityDate), 'yyyyMMdd') : '';

  const [query, setQuery] = useState<PaymentDataRequest | undefined>(() =>
    isValid ? { settlementNumber: trimmed || '', validityDateStr: validityDateStr } : undefined
  );
  useEffect(() => {
    if (isValid) {
      setQuery({ settlementNumber: trimmed || '', validityDateStr: validityDateStr });
    } else {
      setQuery(undefined);
    }
  }, [settlementNumber, isValid, trimmed, validityDateStr]);

  const { data, isValidating } = useSWR(query && ['paymentBankInfo', query], (_, paymentDataReq) => getPaymentData(paymentDataReq));

  return {
    paymentBankInfo: data,
    isLoading: isValidating
  };
};
