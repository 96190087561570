import React, { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormRow } from 'common/components/FormRow';
import i18n, { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { translationEnglish } from './Translations/englishTranslation';
import { translationGerman } from './Translations/germanTranslation';

i18n.addResourceBundle('de', 'partialServiceNumber', translationGerman);
i18n.addResourceBundle('en', 'partialServiceNumber', translationEnglish);

export interface Props {
  name: string;
  edit: boolean;
  partialServiceNumber?: string;
  disabled?: boolean;
}

export const PartialServiceNumber = ({ name, partialServiceNumber, edit, disabled }: Props): ReactElement => {
  const { t } = useTranslation('partialServiceNumber');
  const { setValue } = useFormContext();
  const [error, setError] = useState(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 5) {
      setError(true);
    } else if (parseInt(e.target.value) === 0) {
      setError(true);
    } else {
      setError(false);
      setValue('partialServiceNumber', e.target.value);
    }
  };

  return (
    <OrderStepSection headline={t('title')}>
      <FormRow mode="two-col">
        <CustomTextInput
          type={'text'}
          label={t('label')}
          autoComplete="off"
          error={error ? t('error') : undefined}
          onChange={onChangeHandler}
          testid={'partialServiceNumberInput'}
          // rules={{ regex: /^[0-9]+$/ }}
          rules={{ regex: /^[0-9]{0,4}$/ }}
          name={name}
          disabled={disabled}
          defaultValue={partialServiceNumber ? partialServiceNumber : ''}
        />
      </FormRow>
    </OrderStepSection>
  );
};
