import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Modal } from './Modal';
import { ModalData } from 'common/dtos/ModalData';
import { publishToTopic, subscribeToTopic, unsubscribeFromTopic, PubSubTopic } from 'common/dtos/PubSubEvents';

export const ModalWrapper = () => {
  const [dialogData, setModalDialog] = useState(null as ModalData | null);
  const modalOpen = useRef(false);

  const closeModal = () => {
    if (modalOpen.current) {
      modalOpen.current = false;
      setModalDialog(null);
      publishToTopic('am.modal.closed', null);
    }
  };

  const triggerCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    const tokenOpen = subscribeToTopic('am.modal.open' as PubSubTopic, (topic: string, dialogData: ModalData) => {
      modalOpen.current = true;
      setModalDialog(dialogData);
    });
    const tokenClose = subscribeToTopic('am.modal.close', triggerCloseModal);

    return () => {
      unsubscribeFromTopic(tokenOpen);
      unsubscribeFromTopic(tokenClose);
    };
  }, []);

  return dialogData ? <Modal {...dialogData} /> : null;
};
