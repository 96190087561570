// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-table_parentRow__55Mp2 {
  background-color: #ffffff !important;
  cursor: pointer;
}

.billing-table_removeBorder__GEA6h {
  border: 0 !important;
}

.billing-table_expandBtn__KgZrr svg * {
  fill: #d40511;
}

.billing-table_internalRowHeader__-TkIj {
  padding-left: 27px !important;
}

.billing-table_addBorderBottom__JBe8\\+ {
  border-bottom: 1px solid #dee2e6;
}

.billing-table_parentRow__55Mp2 td {
  vertical-align: middle;
}

.billing-table_rightAlign__8vQ9r {
  text-align: right !important;
}

.billing-table_billingTableP__XtNWd {
  margin-bottom: 15px !important;
}

.billing-table_whiteBg__GwfR9 {
  background-color: #ffffff !important;
}

.billing-table_productDescription__FMP-e,
.billing-table_parentRow__55Mp2 td {
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderChange/common/components/BillingTable/billing-table.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;;EAEE,qBAAqB;AACvB","sourcesContent":[".parentRow {\n  background-color: #ffffff !important;\n  cursor: pointer;\n}\n\n.removeBorder {\n  border: 0 !important;\n}\n\n.expandBtn svg * {\n  fill: #d40511;\n}\n\n.internalRowHeader {\n  padding-left: 27px !important;\n}\n\n.addBorderBottom {\n  border-bottom: 1px solid #dee2e6;\n}\n\n.parentRow td {\n  vertical-align: middle;\n}\n\n.rightAlign {\n  text-align: right !important;\n}\n\n.billingTableP {\n  margin-bottom: 15px !important;\n}\n\n.whiteBg {\n  background-color: #ffffff !important;\n}\n\n.productDescription,\n.parentRow td {\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentRow": `billing-table_parentRow__55Mp2`,
	"removeBorder": `billing-table_removeBorder__GEA6h`,
	"expandBtn": `billing-table_expandBtn__KgZrr`,
	"internalRowHeader": `billing-table_internalRowHeader__-TkIj`,
	"addBorderBottom": `billing-table_addBorderBottom__JBe8+`,
	"rightAlign": `billing-table_rightAlign__8vQ9r`,
	"billingTableP": `billing-table_billingTableP__XtNWd`,
	"whiteBg": `billing-table_whiteBg__GwfR9`,
	"productDescription": `billing-table_productDescription__FMP-e`
};
export default ___CSS_LOADER_EXPORT___;
