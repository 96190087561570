import { PressBasicProduct } from 'order/productGroups/press/schema/pressSchema';

export enum PRESS_CATEGORIES {
  CLASSIC = 'BASIC_TRANSPORT_PRD',
  SMART = 'BASIC_TRANSPORT_PRD_SMART'
}

export interface PressDistributionBaseProduct {
  region: string;
  productNumber: string;
  validFrom: string;
  validTo: string;
  descriptionShort: string;
  duration: string;
  prdCategory?: PRESS_CATEGORIES;
  prdBasicProduct?: PressBasicProduct;
}
