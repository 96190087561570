export const validPaymentClearingNumber = (value: string): boolean => {
  return value.length >= 1 && value.length <= 5;
};

export const validPostageAmountValue = (value: string): boolean => {
  const regEx = /^\d+,\d{0,2}$/;
  return regEx.test(value);
};

export const validPostageMachineCodeLength = (value: string): boolean => {
  return value.length === 10;
};
 
export const validPostageMachineCodeFormat = (value: string): boolean => {
  const regEx = /[1-6][D]([0-9A-Fa-f]{8})$/g;
  return regEx.test(value);
};
 
export const validPostageMachineCode = (value: string | undefined): string | true => {
  // Handle case where value is undefined or null
  if (typeof value !== 'string') {
    return 'error.invalidValue'; // Return a generic error message or handle it appropriately
  }
 
  if (value.trim() === '') {
    return true;
  }
 
  if (!validPostageMachineCodeLength(value)) {
    return 'error.postageMachineCodeLength';
  }
 
  if (!validPostageMachineCodeFormat(value)) {
    return 'error.postageMachineCode';
  }
 
  return true;
};
 
 