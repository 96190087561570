import { InternalHandler } from '../IdAssignment';

const overlappingStart = (r1: InternalHandler, r2: InternalHandler) => {
  return Number(r1.from) >= Number(r2.from) && Number(r1.from) <= Number(r2.to);
};
const overlappingEnd = (r1: InternalHandler, r2: InternalHandler) => {
  return Number(r1.to) >= Number(r2.from) && Number(r1.to) <= Number(r2.to);
};
const overlappingAll = (r1: InternalHandler, r2: InternalHandler) => {
  return Number(r1.from) <= Number(r2.from) && Number(r1.to) >= Number(r2.to);
};
const definedRange = (r: InternalHandler | undefined): r is InternalHandler => {
  return !!r && !!r.to && !!r.from;
};

export const isRangeOverlapping = (ranges: (InternalHandler | undefined)[]): { [i: string | number]: boolean } => {
  return ranges
    .map((r1, i, array) => {
      if (!definedRange(r1)) return { [i]: false };
      const overlapping = array
        .slice(0, i)
        .filter(definedRange)
        .some((rn) => overlappingStart(r1, rn) || overlappingEnd(r1, rn) || overlappingAll(r1, rn));

      return { [r1.id]: overlapping };
    })
    .reduce((acc, cur) => Object.assign(acc, cur), {});
};
