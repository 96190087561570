import { deDateFormatter } from 'common/utils/helpers';
import moment, { isMoment } from 'moment';
import { OrderReplyTDO, OrderTreeItem } from '../services/OrderService';

export const deliveryDateRangeEvaluation = (order?: OrderReplyTDO, children?: OrderTreeItem<OrderReplyTDO>[]): string => {
  let startDate;
  let endDate;
  if (order) {
    let momentStart = order.firstInductionDate ? moment(order.firstInductionDate) : undefined;
    let momentEnd = order.firstInductionDate ? moment(order.firstInductionDate) : undefined;
    if (children) {
      // calc date range over all sub elements
      for (const orderTreeItem of children) {
        if (orderTreeItem.data?.firstInductionDate) {
          momentStart = moment.min([momentStart, moment(orderTreeItem.data.firstInductionDate)].filter(isMoment));
          momentEnd = moment.max([momentEnd, moment(orderTreeItem.data.firstInductionDate)].filter(isMoment));
        }
      }
    }
    if (momentStart && momentStart.isValid()) {
      startDate = deDateFormatter.format(momentStart.toDate()).toString();
    }
    if (momentEnd && momentEnd.isValid()) {
      endDate = deDateFormatter.format(momentEnd.toDate()).toString();
    }
  }

  if (!startDate) {
    return '-';
  }
  if (!endDate || startDate === endDate) {
    return startDate;
  }
  return `${startDate} - ${endDate}`;
};
