import { DHLButton, DHLModal } from '@gkuis/gkp-base-widgets/dist/lib';
import { getOrder } from 'order/common/context/order/utils/getOrder';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import GitInfo from 'react-git-info/macro';
import { useTranslation } from 'react-i18next';

import {
  GetCollectionOrderRep,
  GetDialogPostOrderRep,
  GetDialogPostWurfSpecialOrderRep,
  GetInternationalOrderRep,
  GetLetterOrderRep,
  GetOrderRep,
  GetPartialOrderRep,
  GetPostaktuellOrderRep,
  GetPressDistributionOrderRep,
  JournalPaymentInfoRep,
  JournalRep,
  OrderCustomerRoleEnum,
  Product
} from 'generated';
import i18n from 'i18n';
import { useBaseProductsX } from '../../../common/hooks/useBaseProductsX';
import { useDependentProducts } from '../../../common/hooks/useDependentProducts';
import { useProductionFacilities } from '../../../common/hooks/useProductionFacilities';
import { getCustomerInfo, UseCustomerInfoReturn } from '../../../productGroups/common/services/getCustomerInfo';
import { OrderTypes } from '../../../orderSearch/components/OrderTypes';
import { downloadEinlieferungslistePdfDiP } from 'order/common/exports/PrintEinlieferungsliste/PrintEinlieferungslistePdfDiP';
import { downloadEinlieferungslistePdfTL } from 'order/common/exports/PrintEinlieferungsliste/PrintEinlieferungslistePdfTL';
import { downloadPalettenleitzettelPdf } from 'order/common/exports/PrintPalettenleitzettel/PrintPalettenleitzettelPdf';
import { downloadTransportAvisPdf } from 'order/common/exports/PrintTransportAvis/PrintTransportAvisPdf';
import { PrintDialogContent, PrintDialogOptions } from './PrintDialogContent';
import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { AllocatedOrder, AllocatedOrderData } from '../../../common/context/order/dtos/AllocatedOrder';

import translation from './printDialog.de.json';

import styles from './PrintDialog.module.css';
import { OrderCategory } from '../../../common/context/order/dtos/OrderCategory';
import { getJournalPaymentData, getJournals } from '../../../../common/services/JournalService';
import { downloadEinlieferungslistePdfAfmBrief } from 'order/common/exports/PrintEinlieferungsliste/PrintEinlieferungslistePdfAfmBrief';
import { downloadEinlieferungslistePdfPress } from 'order/common/exports/PrintEinlieferungsliste/PrintEinlieferungslistePdfPress';
import { usePressDistributionBaseProducts } from 'order/common/hooks/usePressDistributionBaseProducts';
import { PressDistributionBaseProduct } from 'order/common/dtos/PressDistributionBaseProduct';
import { downloadEinlieferungslistePdfInternational } from 'order/common/exports/PrintEinlieferungsliste/PrintEinlieferungslistePdfInternational';
import { internationalProducts } from 'order/common/services/OrderService';
import { InternationalBaseProduct } from 'order/productGroups/international/dtos/InternationalBaseProducts';
import { requestSpecialLetterProducts } from 'order/common/services/specialLetterProducts';
import { AdditionalLetterBaseProducts } from 'order/productGroups/international/components/AdditionalLetterService';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { loadAdditionalOrdersForPrintingInBulk } from 'order/common/utils/loadAdditionalOrdersForPrintingInBulk';
import { usePressDistributionSupplementProducts } from 'order/common/hooks/usePressDistributionSupplementProducts';
import moment from 'moment';

interface PrintDialogProps {
  show: boolean;
  orderId: string;
  rawOrder?: GetOrderRep;
  allocatedOrders?: AllocatedOrder[];
  context?: string;
  orderCategory?: string | undefined;
  onCancel: () => void;
}

i18n.addResourceBundle('de', 'printDialog', translation);
i18n.addResourceBundle('en', 'printDialog', translation);

enum PrintList {
  Einlieferungsliste = 1,
  Palettenleitzettel,
  Transportavis
}

export const PrintDialog = (props: PrintDialogProps): JSX.Element => {
  const { t } = useTranslation('printDialog');
  const { t: translation } = useTranslation('translation');
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState({} as PrintDialogOptions);
  const [originatorInfo, setOriginatorInfo] = useState<UseCustomerInfoReturn>({ isLoading: true, customerInfo: undefined });
  const [submitterInfo, setSubmitterInfo] = useState<UseCustomerInfoReturn>({ isLoading: true, customerInfo: undefined });
  const [journalPaymentInfoFetched, setJournalPaymentInfoFetched] = useState<{ isLoading: boolean; journalPaymentInfo?: JournalPaymentInfoRep }>({
    isLoading: true,
    journalPaymentInfo: undefined
  });
  const order = useRef<GetOrderRep | undefined>(props.rawOrder || undefined);
  const orders = useRef<AllocatedOrderData[] | undefined>(undefined);
  const [journalInfo, setJournalInfo] = useState<JournalRep>({});
  const [isJournalInfoLoading, setJournalInfoLoading] = useState(true);
  const [pressBaseProducts, setPressBaseProducts] = useState<PressDistributionBaseProduct[]>([]);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [printingLists, setPrintingLists] = useState<PrintList[] | undefined>(undefined);
  const [printingListCurr, setPrintingListCurr] = useState<PrintList | undefined>(undefined);
  const [nveOrderFetched, setNveOrderFetched] = useState<{ isLoading: boolean; nveOrder?: GetPressDistributionOrderRep }>({
    isLoading: true,
    nveOrder: undefined
  });
  const { language } = useAuthContext().user;

  const isPrinting = printingListCurr !== undefined || (printingLists != undefined && printingLists.length > 0);
  const isNotPrintable = !options.postingList && !options.transportNotification && !options.palletLabel;
  const zkz = (order.current as GetPressDistributionOrderRep)?.journal?.zkz;
  const baseProductNumbers = (order.current as GetPressDistributionOrderRep)?.nationalProducts?.map((bp) => bp.productNumber ?? '');
  const pBp = usePressDistributionBaseProducts(zkz ?? '');
  const fetchSupplementProductsByZkz = usePressDistributionSupplementProducts(
    zkz,
    baseProductNumbers,
    moment(order.current?.orderDetail?.date).format('yyyy-MM-DD')
  );
  // ASL handling for international cluster
  const [internationalBaseProducts, setInternationalBaseProducts] = useState<InternationalBaseProduct[]>([]);
  const [internationalBaseProductsLoaded, setInternationalBaseProductsLoaded] = useState(false);

  const [ASL, setASL] = useState<Product[]>([]);
  const [ASLLoaded, setASLLoaded] = useState(false);
  const AllASLLoaded = useRef<boolean[]>([]);

  const fetchASL = useCallback(async (productNumber?: string, bpWithASLLen?: number) => {
    const date1: string = new Date().getDate().toString().padStart(2, '0');
    const month: string = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const year = new Date().getFullYear();
    const SLP = await requestSpecialLetterProducts(productNumber, `${year}-${month}-${date1}`);
    if (SLP?.length) {
      AllASLLoaded.current.push(true);
    }
    const validEnumValues = [
      AdditionalLetterBaseProducts.Eigenhändig,
      AdditionalLetterBaseProducts.Rückschein,
      AdditionalLetterBaseProducts.Übergabeeinschreiben
    ];
    setASL((prev) => [...prev, ...SLP.filter((p) => validEnumValues.find((a) => p.descriptionShort?.includes(a)))]);
    if (bpWithASLLen === AllASLLoaded.current.length) {
      setASLLoaded(true);
    }
  }, []);

  const fetchOrder = useCallback(async (orderId: string) => {
    // load order
    setIsOrderLoading(true);
    const orderResponse = await getOrder<GetOrderRep>(orderId, {
      includeDst: props?.orderCategory === OrderCategory.PRESS_DISTRIBUTION,
      includePrice: true
    });

    if (orderResponse) {
      order.current = orderResponse;
      if (orderResponse?.productGroup === ProductGroup.COLLECTION && (orderResponse as GetCollectionOrderRep).orderReferences?.length) {
        const orderIds = (orderResponse as GetCollectionOrderRep).orderReferences?.map((orderReference) => orderReference.orderId || '');
        await loadOrdersForAdditionalOrder(orderIds);
      }
      setIsOrderLoading(false);
    }
  }, []);

  const fetchOriginatorInfo = useCallback(async (originatorId?: string) => {
    if (originatorId) {
      const data = await getCustomerInfo(originatorId);
      if (data) {
        setOriginatorInfo(data);
        return;
      }
    }
    setOriginatorInfo({ isLoading: false, customerInfo: undefined });
  }, []);

  const fetchJournalPaymentInfo = useCallback(async (zkz?: string) => {
    if (zkz) {
      const data = await getJournalPaymentData(zkz);
      if (data) {
        setJournalPaymentInfoFetched({ isLoading: false, journalPaymentInfo: data.data });
        return;
      }
    }
    setJournalPaymentInfoFetched({ isLoading: false, journalPaymentInfo: undefined });
  }, []);

  const fetchJournalInfo = useCallback(async (zkz?: string) => {
    if (zkz) {
      const data = await getJournals(zkz);
      if (data) {
        setJournalInfo(data.data?.[0]);
        setJournalInfoLoading(false);
        return;
      }
    }
    setJournalInfo({ registrationNumber: (order?.current as GetPressDistributionOrderRep).journal?.zkz, title: '' });
  }, []);

  const fetchSubmitterInfo = useCallback(async (submitterId?: string) => {
    if (submitterId) {
      const data = await getCustomerInfo(submitterId);
      if (data) {
        setSubmitterInfo(data);
        return;
      }
    }
    setOriginatorInfo({ isLoading: false, customerInfo: undefined });
  }, []);

  // Fetching International BaseProducts
  const fetchInternationalBaseProducts = useCallback(async () => {
    if (order?.current?.productGroup) {
      const products: InternationalBaseProduct[] = (await internationalProducts(order?.current?.productGroup)).data;
      if (products) {
        setInternationalBaseProducts(products);
        setInternationalBaseProductsLoaded(true);
      }
    }
  }, []);

  const loadOrdersForAdditionalOrder = useCallback(async (orderIds: string[] | undefined): Promise<AllocatedOrderData[]> => {
    if (!props.rawOrder && orderIds?.length) {
      const currentOrders = await loadAdditionalOrdersForPrintingInBulk(orderIds);
      currentOrders?.forEach((c) => ((c as any).orderNumber = c.orderId));
      orders.current = currentOrders as AllocatedOrderData[];
      return currentOrders as AllocatedOrderData[];
    }
    return orders.current || [];
  }, []);

  const fetchNveOrder = useCallback(async (orderId: string | undefined) => {
    if (orderId) {
      // load press order with NVE
      const data = (await getOrder(orderId, {
        includeDst: true,
        includeNve: true
      })) as GetPressDistributionOrderRep;
      if (data) {
        setNveOrderFetched({ isLoading: false, nveOrder: data });
        return;
      }
      setNveOrderFetched({ isLoading: false, nveOrder: undefined });
    }
  }, []);

  useEffect(() => {
    if (props.show && !props.rawOrder && !display) {
      setDisplay(true);
      fetchOrder(props.orderId);
    } else if (props.show && props.rawOrder && !display) {
      order.current = props.rawOrder;
      orders.current = props.allocatedOrders?.map((a) => a.data);
      setDisplay(true);
    } else if (!props.show) {
      order.current = undefined;
      setDisplay(false);
    }
  }, [display, props.show, props.rawOrder]);

  useEffect(() => {
    if (
      display &&
      !isOrderLoading &&
      (printingLists?.includes(PrintList.Palettenleitzettel) ||
        printingLists?.includes(PrintList.Transportavis) ||
        printingLists?.includes(PrintList.Einlieferungsliste))
    ) {
      const originatorId = order.current?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Originator)?.customerId;
      fetchOriginatorInfo(originatorId);
      const submitterId = order.current?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Submitter)?.customerId;
      fetchSubmitterInfo(submitterId);
      if (order.current && order.current.orderCategory === OrderCategory.PRESS_DISTRIBUTION) {
        const zkz = (order.current as GetPressDistributionOrderRep).journal?.zkz;
        fetchJournalInfo(zkz);
        fetchJournalPaymentInfo(zkz);
        setPressBaseProducts(pBp);
        // for press orders it is necessary to have the order with updated sscc's (NVE)
        fetchNveOrder(order.current?.orderId);
      }
      if (
        order?.current?.productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
        order?.current?.productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
        order?.current?.productGroup === ProductGroup.PRESS_INTERNATIONAL ||
        order?.current?.productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL
      ) {
        fetchInternationalBaseProducts();
        if (order?.current?.productGroup !== ProductGroup.DIALOGPOST_INTERNATIONAL) {
          const intOrder = order.current as GetInternationalOrderRep;
          const bpWithASL = intOrder?.letterBaseProducts?.filter((lp) => lp?.discounts?.length) || [];
          setASL([]);
          AllASLLoaded.current = [];
          if (bpWithASL.length === 0) {
            setASLLoaded(true);
          }
          bpWithASL?.map((lp) => fetchASL(lp?.baseProduct?.productNumber, bpWithASL?.length));
        }
      }
    }
  }, [display, isOrderLoading, printingLists]);

  // eslint-disable-next-line new-cap
  const gitInfo = GitInfo();

  // load additional data
  //  product master data
  const { baseProducts: validBaseProducts, isLoading: isBaseProductsLoading } = useBaseProductsX(
    display &&
      !isOrderLoading &&
      printingLists?.includes(PrintList.Einlieferungsliste) &&
      order.current?.orderType === OrderTypes.Einlieferungsauftrag &&
      [ProductGroup.DIALOGPOST.toString(), ProductGroup.DIALOGPOSTWURFSPEZIAL.toString()].includes(order.current?.productGroup)
      ? order.current?.productGroup
      : undefined,
    order.current?.orderDetail?.date
  );
  const {
    easy,
    nichtAutomationsfaehig,
    premium,
    trackAndMatch,
    startPointSelection,
    deliveryAreaSelection,
    isLoading: isDependentProductsLoading
  } = useDependentProducts(
    display &&
      !isOrderLoading &&
      printingLists?.includes(PrintList.Einlieferungsliste) &&
      order.current?.orderType === OrderTypes.Einlieferungsauftrag &&
      [ProductGroup.DIALOGPOST.toString(), ProductGroup.DIALOGPOSTWURFSPEZIAL.toString(), ProductGroup.POSTAKTUELL].includes(
        order.current?.productGroup
      )
      ? order.current?.productGroup
      : undefined,
    order.current?.orderDetail?.date,
    ProductGroup.DIALOGPOST.toString() === order.current?.productGroup
      ? (order.current as GetDialogPostOrderRep)?.baseProduct?.productNumber
      : ProductGroup.DIALOGPOSTWURFSPEZIAL.toString() === order.current?.productGroup
      ? (order.current as GetDialogPostWurfSpecialOrderRep)?.baseProduct?.productNumber
      : undefined
  );
  const { facilities: validProductionFacilities, isLoading: isProductionFacilitiesLoading } = useProductionFacilities(
    order.current?.orderDetail?.date
  );

  const handleOptionChange = (newOptions: PrintDialogOptions) => {
    setOptions(newOptions);
  };

  const reset = () => {
    setOptions({} as PrintDialogOptions);
    setPrintingLists(undefined);
    setPrintingListCurr(undefined);
  };

  const onCancel = () => {
    reset();
    props.onCancel();
  };

  const onPrintStart = () => {
    const printLists: PrintList[] = [];
    if (options.postingList) {
      printLists.push(PrintList.Einlieferungsliste);
    }
    if (options.palletLabel) {
      printLists.push(PrintList.Palettenleitzettel);
    }
    if (options.transportNotification) {
      printLists.push(PrintList.Transportavis);
    }
    setPrintingLists(printLists);
    setPrintingListCurr(undefined);
  };

  useEffect(() => {
    if (!printingListCurr) {
      if (printingLists && printingLists.length > 0) {
        // const version = gitInfo.tags && gitInfo.tags.length > 0 ? gitInfo.tags[gitInfo.tags.length - 1] : process.env.REACT_APP_VERSION;
        const version: string | undefined = gitInfo.tags[gitInfo.tags.length - 1] || process.env.REACT_APP_VERSION;
        const printingListCurrNew = printingLists[0];

        const executeIfPrintingStarted = () => {
          const printingListsNew = printingLists.filter((printList) => printList !== printingListCurrNew);
          setPrintingLists(printingListsNew);
          setPrintingListCurr(printingListCurrNew);
        };

        if (
          printingListCurrNew === PrintList.Einlieferungsliste &&
          order.current?.orderType === OrderTypes.Einlieferungsauftrag &&
          order.current &&
          !isBaseProductsLoading &&
          !isDependentProductsLoading &&
          !isProductionFacilitiesLoading
        ) {
          const dependentProductDescs = [] as string[];
          easy &&
            order.current?.dependentProducts?.some((orderProduct) => orderProduct.productNumber === easy.productNumber) &&
            dependentProductDescs.push('Easy');
          nichtAutomationsfaehig &&
            order.current?.dependentProducts?.some((orderProduct) => orderProduct.productNumber === nichtAutomationsfaehig.productNumber) &&
            dependentProductDescs.push('Nicht automationsfähig');
          premium &&
            dependentProductDescs.push(
              premium.find((premiumProduct) =>
                order.current?.dependentProducts?.find((orderProduct) => orderProduct.productNumber === premiumProduct.productNumber)
              )?.descriptionShort || ''
            );
          trackAndMatch &&
            order.current?.dependentProducts?.some((orderProduct) => orderProduct.productNumber === trackAndMatch.productNumber) &&
            dependentProductDescs.push('Track & Match');
          deliveryAreaSelection &&
            deliveryAreaSelection.find((deliveryAreaSelection) =>
              order.current?.dependentProducts?.find((orderProduct) => orderProduct.productNumber === deliveryAreaSelection.productNumber)
            ) &&
            dependentProductDescs.push(translation('deliveryAreaSelection'));
          startPointSelection &&
            startPointSelection.find((startPointSelection) =>
              order.current?.dependentProducts?.find((orderProduct) => orderProduct.productNumber === startPointSelection.productNumber)
            ) &&
            dependentProductDescs.push(translation('startPointSelection'));
          switch (order.current?.productGroup) {
            case ProductGroup.DIALOGPOST.toString():
              setTimeout(() => {
                downloadEinlieferungslistePdfDiP(
                  options.variantList,
                  order.current as { productGroup: 'DIALOGPOST' } & GetDialogPostOrderRep,
                  validBaseProducts,
                  dependentProductDescs,
                  validProductionFacilities,
                  version,
                  onPrintFinished
                );
              }, 200);
              executeIfPrintingStarted();
              break;

            case ProductGroup.DIALOGPOSTWURFSPEZIAL.toString():
              setTimeout(() => {
                downloadEinlieferungslistePdfDiP(
                  options.variantList,
                  order.current as { productGroup: 'DIALOGPOSTWURFSPEZIAL' } & GetDialogPostWurfSpecialOrderRep,
                  validBaseProducts,
                  dependentProductDescs,
                  validProductionFacilities,
                  version,
                  onPrintFinished
                );
              }, 200);
              executeIfPrintingStarted();
              break;

            case ProductGroup.POSTAKTUELL.toString():
              setTimeout(() => {
                downloadEinlieferungslistePdfDiP(
                  options.variantList,
                  order.current as { productGroup: 'POSTAKTUELL' } & GetPostaktuellOrderRep,
                  [],
                  dependentProductDescs,
                  validProductionFacilities,
                  version,
                  onPrintFinished
                );
              }, 200);
              executeIfPrintingStarted();
              break;

            case ProductGroup.BRIEF.toString():
              setTimeout(() => {
                downloadEinlieferungslistePdfAfmBrief(order.current as GetLetterOrderRep, validProductionFacilities, version, onPrintFinished);
              }, 200);
              executeIfPrintingStarted();
              break;
            case ProductGroup.PRESS_DISTRIBUTION.toString():
              if (!isJournalInfoLoading) {
                executeIfPrintingStarted();
                setTimeout(() => {
                  downloadEinlieferungslistePdfPress(
                    options.variantList,
                    order.current as { productGroup: ProductGroup.PRESS_DISTRIBUTION } & GetPressDistributionOrderRep,
                    pressBaseProducts,
                    dependentProductDescs,
                    validProductionFacilities,
                    version,
                    journalInfo,
                    fetchSupplementProductsByZkz,
                    onPrintFinished
                  );
                }, 200);
              }
              break;

            case ProductGroup.DIALOGPOST_INTERNATIONAL.toString():
              if (internationalBaseProductsLoaded) {
                setTimeout(() => {
                  downloadEinlieferungslistePdfInternational(
                    order.current as { productGroup: ProductGroup.DIALOGPOST_INTERNATIONAL } & GetInternationalOrderRep,
                    validProductionFacilities,
                    internationalBaseProducts,
                    [],
                    version,
                    onPrintFinished
                  );
                }, 200);
                executeIfPrintingStarted();
              }
              break;

            case ProductGroup.BRIEF_INTERNATIONAL.toString():
              if (internationalBaseProductsLoaded && ASLLoaded) {
                setTimeout(() => {
                  downloadEinlieferungslistePdfInternational(
                    order.current as { productGroup: ProductGroup.BRIEF_INTERNATIONAL } & GetInternationalOrderRep,
                    validProductionFacilities,
                    internationalBaseProducts,
                    ASL,
                    version,
                    onPrintFinished
                  );
                }, 200);
                executeIfPrintingStarted();
              }
              break;

            case ProductGroup.BRIEF_DMC_INTERNATIONAL.toString():
              if (internationalBaseProductsLoaded && ASLLoaded) {
                setTimeout(() => {
                  downloadEinlieferungslistePdfInternational(
                    order.current as { productGroup: ProductGroup.BRIEF_DMC_INTERNATIONAL } & GetInternationalOrderRep,
                    validProductionFacilities,
                    internationalBaseProducts,
                    ASL,
                    version,
                    onPrintFinished
                  );
                }, 200);
                executeIfPrintingStarted();
              }
              break;

            case ProductGroup.PRESS_INTERNATIONAL.toString():
              if (internationalBaseProductsLoaded && ASLLoaded) {
                setTimeout(() => {
                  downloadEinlieferungslistePdfInternational(
                    order.current as { productGroup: ProductGroup.PRESS_INTERNATIONAL } & GetInternationalOrderRep,
                    validProductionFacilities,
                    internationalBaseProducts,
                    ASL,
                    version,
                    onPrintFinished
                  );
                }, 200);
                executeIfPrintingStarted();
              }
              break;

            default:
              break;
          }
        }

        if (
          printingListCurrNew === PrintList.Einlieferungsliste &&
          order.current?.orderType === OrderTypes.Zusatzauftrag &&
          order.current &&
          !isOrderLoading &&
          !isProductionFacilitiesLoading
        ) {
          const printPSL = async () => {
            const orderIds = (order.current as GetPartialOrderRep).orderReferences?.map((orderReference) => orderReference.orderId || '');
            let loadedOrders: (AllocatedOrderData | undefined)[] | undefined = undefined;
            // if (options.variantList) {
            //   loadedOrders = await loadOrdersForAdditionalOrder(orderIds);
            // }
            loadedOrders = isAvailableFromJan25(order?.current?.orderDetail?.date)
              ? await loadOrdersForAdditionalOrder(orderIds)
              : options?.variantList
              ? await loadOrdersForAdditionalOrder(orderIds)
              : [];
            await downloadEinlieferungslistePdfTL(
              options.variantList,
              order.current as { productGroup: 'PARTIAL' } & GetPartialOrderRep,
              loadedOrders,
              validProductionFacilities,
              version,
              onPrintFinished
            );
          };
          printPSL();
          executeIfPrintingStarted();
        }

        // Palettenleitzettel
        if (
          printingListCurrNew === PrintList.Palettenleitzettel &&
          order.current &&
          !originatorInfo.isLoading &&
          !submitterInfo.isLoading &&
          (order.current?.orderCategory !== OrderCategory.PRESS_DISTRIBUTION || (!journalPaymentInfoFetched.isLoading && !nveOrderFetched.isLoading))
        ) {
          setTimeout(() => {
            const printOrder = order.current?.orderCategory !== OrderCategory.PRESS_DISTRIBUTION ? order.current : nveOrderFetched.nveOrder;
            if (printOrder) {
              downloadPalettenleitzettelPdf(
                printOrder,
                originatorInfo.customerInfo,
                submitterInfo.customerInfo,
                version,
                journalPaymentInfoFetched.journalPaymentInfo,
                language,
                onPrintFinished
              );
            }
          }, 200);
          executeIfPrintingStarted();
        }

        const isPressLoading = order.current?.productGroup === ProductGroup.PRESS_DISTRIBUTION && isJournalInfoLoading;
        // Transportavis
        if (
          printingListCurrNew === PrintList.Transportavis &&
          order.current &&
          !originatorInfo.isLoading &&
          !submitterInfo.isLoading &&
          !isProductionFacilitiesLoading &&
          !isPressLoading
        ) {
          const printTransportAvis = async () => {
            let loadedOrders: GetOrderRep[] | undefined = undefined;
            if (order.current?.orderCategory === OrderCategory.ABHOLAUFTRAG) {
              const orderIds = (order.current as GetCollectionOrderRep).orderReferences?.map((orderReference) => orderReference.orderId || '');
              loadedOrders = (await loadOrdersForAdditionalOrder(orderIds))?.filter((o) => o) as GetOrderRep[];
            }
            setTimeout(() => {
              if (order.current) {
                downloadTransportAvisPdf(
                  order.current,
                  loadedOrders,
                  originatorInfo.customerInfo,
                  submitterInfo.customerInfo,
                  validProductionFacilities,
                  version,
                  journalInfo,
                  language,
                  onPrintFinished
                );
              }
            }, 200);
          };
          printTransportAvis();
          executeIfPrintingStarted();
        }
      }
    }
  }, [
    isOrderLoading,
    printingLists,
    printingListCurr,
    isBaseProductsLoading,
    isDependentProductsLoading,
    originatorInfo,
    submitterInfo,
    isProductionFacilitiesLoading,
    journalPaymentInfoFetched,
    nveOrderFetched,
    journalInfo,
    isJournalInfoLoading,
    internationalBaseProductsLoaded,
    ASLLoaded
  ]);

  const onPrintFinished = () => {
    setPrintingListCurr(undefined);
  };

  useEffect(() => {
    if (display && !printingListCurr && printingLists && printingLists.length <= 0) {
      onCancel();
    }
  }, [display, printingListCurr, printingLists]);

  return (
    <PrintDialogFrame
      props={{ ...props, onCancel: onCancel }}
      content={
        <PrintDialogContent
          show={display}
          order={order.current}
          options={options}
          handleOptionChange={handleOptionChange}
          isSuspended={isOrderLoading || isPrinting}
          allocatedOrders={orders.current}
        />
      }
      actionBar={
        <>
          <DHLButton
            name={'cancel-button'}
            label={t`cancel`}
            onClick={() => {
              onCancel();
            }}
            type={'default'}
          />
          <DHLButton
            name={'save-button'}
            label={t`print`}
            onClick={onPrintStart}
            type={'primary'}
            disabled={(order.current as any)?.pendingIds || isOrderLoading || isNotPrintable || isPrinting}
          />
        </>
      }
    />
  );
};

interface PrintDialogFrameProps {
  props: PrintDialogProps;
  content: JSX.Element;
  actionBar?: JSX.Element;
}

export const PrintDialogFrame = ({ props, content, actionBar }: PrintDialogFrameProps): JSX.Element => {
  const { t } = useTranslation('printDialog');
  const scrollPos = useRef(0);

  useEffect(() => {
    if (props.show) scrollPos.current = window.scrollY;
  }, [props.show]);

  useEffect(() => {
    window.scrollTo(1, Number(scrollPos.current));
  }, [props.show]);

  return (
    <DHLModal
      name="confirm"
      title={t`modalTitle`}
      content={content}
      className={styles.printDialog}
      actionbar={actionBar}
      noActionBar={!actionBar}
      show={props.show}
      onClickClose={() => {
        props.onCancel();
      }}
    />
  );
};
