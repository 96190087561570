import { OrderResponse } from '../dtos/GetOrder';
import { OrderReference } from '../dtos/OrderReference';
import { determineFlexOption, determineFlexOptionStd } from './determineFlexOption';
import { QtyAllocation, sortFormatList } from '../dtos/Order';
import { qtySumCalculator } from './qtySumCalculator';
import { Occurrence } from 'common/dtos/Occurrence';
import { DeliveryOption } from 'common/dtos/DeliveryOption';
import { determineDeliveryOption } from './determineDeliveryOption';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';

export function createOrderReference(
  order: OrderResponse,
  qtyAllocation: QtyAllocation[],
  date: Date | string
): { orderReference: OrderReference; qtyAlloc: QtyAllocation[]; totalQty: number; initialTotalQty: number } {
  let qtyAlloc: QtyAllocation[] = [];
  let totalQty = 0;
  if (qtyAllocation) {
    qtyAlloc = qtyAllocation;
    qtyAlloc.forEach((q) => {
      q.initialQty = q.quantity;
      q.includeTotalQty = q.includeTotalQty ?? true;
      q.containsIds = q.containsIds ?? (!!q.ranges?.length || order.includesDestinations); // only true if ranges available
      q.flexOption = isAvailableFromJan25(new Date(date))
        ? determineFlexOption(order?.otherAttributes || {}) && q.containsIds
          ? true
          : determineFlexOptionStd(order?.otherAttributes || {}) && q.containsIds
          ? false
          : q.flexOption && q.containsIds
          ? true
          : q.flexOption ?? true
        : determineFlexOption(order?.otherAttributes || {}) && q.containsIds
        ? true
        : q.flexOption && q.containsIds
        ? true
        : q.flexOption ?? false; // only true if containsIds true

      q.idsQty = q.containsIds ? q.quantity : 0;
      q.reducible = true;
    });
    totalQty = qtySumCalculator(qtyAlloc, 'quantity') as number;
  }
  qtyAlloc = qtyAlloc.sort((a, b) => sortFormatList.indexOf(a.format) - sortFormatList.indexOf(b.format));

  const orderReference: OrderReference = {
    orderId: order?.orderId || '',
    frankingIdPrefix: order?.postage?.machineCode || order?.postage?.frankingIdPrefix || undefined,
    machineCode: order?.postage?.machineCode,
    postageFrankingIdPrefix: order?.postage?.frankingIdPrefix,
    includeTotalQty: Occurrence.ALL,
    includesDestinations: order?.includesDestinations,
    containsIds: order.includesDestinations ? Occurrence.ALL : Occurrence.NONE,
    flexOption: isAvailableFromJan25(new Date(date))
      ? determineFlexOptionStd(order?.otherAttributes || {})
        ? Occurrence.NONE
        : determineFlexOption(order?.otherAttributes || {}) || order.includesDestinations
        ? Occurrence.ALL
        : Occurrence.NONE
      : determineFlexOption(order?.otherAttributes || {})
      ? Occurrence.ALL
      : Occurrence.NONE,
    totalQty,
    allocatedQty: totalQty,
    idsQty: order.includesDestinations ? totalQty : 0,
    qtyAllocation: qtyAlloc,
    initialTotalQty: totalQty,
    selectedDeliveryOption: determineDeliveryOption(order?.otherAttributes || {}),
    new: true
  };

  return { orderReference, qtyAlloc, totalQty, initialTotalQty: totalQty };
}
