import React, { useEffect, useState } from 'react';
import { UserRights } from 'common/dtos/userRights';
import { OrderCreatePage } from './OrderCreatePage';
import { Spinner } from 'order/common/components/Spinner';
import { Authorized } from 'common/components/Authorized/Authorized';

export const AuthorizedOrderCreateWrapper = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  return <Authorized userRight={UserRights.OrderEdit}>{!loaded ? <Spinner testid="spinnerTestId" /> : <OrderCreatePage />}</Authorized>;
};
