import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import React, { ReactNode, VFC } from 'react';
import classes from './order-step-headline.module.css';

export type OrderStepHeadlineProps = {
  icon?: string;
  alt?: string;
  children?: ReactNode;
  notCapitalise?: boolean;
};

export const OrderStepHeadline: VFC<OrderStepHeadlineProps> = (props) => (
  <header className={classes.wrapper}>
    <img src={props.icon} alt={props.alt} />
    <DHLHeadline type="h4" color="gray" textAlignment="left" className={props.notCapitalise ? `${classes.notCapitalise}` : `${classes.title}`}>
      {props.children}
    </DHLHeadline>
  </header>
);
