import React, { AllHTMLAttributes, VFC, Fragment } from 'react';
import classNames from 'classnames';

import { concatNonUndefined } from 'common/utils/helpers';

import classes from './progress-bar.module.css';

export type ProgressBarProps = AllHTMLAttributes<HTMLDivElement> & {
  steps: string[];
  active?: number;
  'data-testid'?: string;
};

export const ProgressBar: VFC<ProgressBarProps> = ({ steps, active = -1, ...props }) => {
  const maxStep = steps.length + 1;
  return (
    <div
      role="meter"
      aria-valuemax={maxStep}
      aria-valuemin={-1}
      aria-valuenow={active}
      aria-labelledby={concatNonUndefined(props.id, '-label-', active < 0 ? undefined : active)}
      {...props}
    >
      <div className={classes.indicatorContainer}>
        {steps.map((_, index) => (
          <Fragment key={index + 'line'}>
            {index > 0 && (
              <hr
                key={index + 'line'}
                className={classNames(classes.line, {
                  [classes.done]: index <= active
                })}
              />
            )}
            <span
              key={index}
              id={concatNonUndefined(props.id, '-indicator-', index)}
              data-testid={concatNonUndefined(
                props['data-testid'],
                '-indicator-',
                index,
                index === active ? '-active' : index <= active ? '-done' : ''
              )}
              className={classNames(classes.indicator, {
                [classes.active]: index === active,
                [classes.done]: index < active
              })}
            >
              {index + 1}
            </span>
          </Fragment>
        ))}
      </div>
      <div className={classes.labelContainer}>
        {steps.map((step, index) => (
          <label
            key={`${step}-${index}`}
            id={concatNonUndefined(props.id, '-label-', index)}
            data-testid={concatNonUndefined(props['data-testid'], '-label-', index)}
            className={classNames(classes.label, {
              [classes.active]: index === active,
              [classes.done]: index < active
            })}
          >
            {step}
          </label>
        ))}
      </div>
    </div>
  );
};
