/* eslint-disable @typescript-eslint/no-explicit-any */
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { OrderPriceRep, PricePositionRep, ServiceClusterRep } from 'generated';
import { BillingTableHeadRow } from 'order/orderChange/common/components/BillingTable/BillingTableHeadRow';
import { BillingTableItemHeadRow, BillingTableItemHeadRowProps } from 'order/orderChange/common/components/BillingTable/BillingTableItemHeadRow';
import { BillingTableItemRow } from 'order/orderChange/common/components/BillingTable/BillingTableItemRow';
import { BillingTableRow } from 'order/orderChange/common/components/BillingTable/BillingTableRow';
import React, { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import tableStyles from '../../../../orderSearch/components/table/AMTable.module.css';
import styles from './billing-table.module.css';
import { ProductGroup } from '../../../../common/dtos/ProductGroup';

type BillingTableProps = {
  orderPrice?: OrderPriceRep;
  productGroup?: string;
};

export type Price = PricePositionRep & {
  totalWeight?: any;
  unitPaymentKilo?: any;
  reduction?: any;
  tariffZone?: any;
  zkz?: any;
  designation?: any;
};

export const BillingTable: VFC<BillingTableProps> = ({ orderPrice, productGroup }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>(
    orderPrice?.serviceClusters?.map((item: ServiceClusterRep, idx: number) => {
      if (!item.baseProductDescription) return idx;
      else return -1;
    }) || []
  );
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const isInternationalProduct =
    productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
    productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
    productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL ||
    productGroup === ProductGroup.PRESS_INTERNATIONAL;
  const handleRowClick = (rowId: number) =>
    setExpandedRows((rows) => {
      if (rows.includes(rowId)) {
        return rows.filter((id) => id !== rowId);
      }
      return [...rows, rowId];
    });
  const flags: Omit<BillingTableItemHeadRowProps, 'index'> = {};

  orderPrice?.serviceClusters?.forEach((item: ServiceClusterRep, idx: number) => {
    if (expandedRows.includes(idx)) {
      item.pricePositions?.forEach((item1: Price) => {
        if (item1.zkz) flags.showZKZ = true;
        if (item1.tariffZone) flags.showTariffZone = true;
        if (item1.totalWeight || isInternationalProduct) flags.showTotalWeight = true;
        if ((item1.unitPaymentKilo && item1.unitPaymentKilo?.value) || isInternationalProduct) flags.showBasicCharge = true;
        if (item1.reduction) flags.showDiscount = true;
        if (item1.designation) flags.showDesignation = true;
        if (!isInternationalProduct && item1.unitWeight?.value) flags.showSingleWeight = true;
      });
    }
  });
  return (
    <>
      <DHLHeadline className={styles.billingTableP} type="h6">
        {t('settlementOfOrderItems')}
      </DHLHeadline>
      <table className={'dhlTable table ' + tableStyles.amTable}>
        <thead className={classNames('dhlTable-sort-header ' + tableStyles.headcellalign)}>
          <BillingTableHeadRow {...flags} />
        </thead>
        <tbody>
          {orderPrice?.serviceClusters?.map((item: ServiceClusterRep, index: number) => {
            const isExpanded = expandedRows.includes(index);
            return (
              <>
                <BillingTableRow
                  key={'row-data-' + index}
                  index={index}
                  isExpanded={isExpanded}
                  item={item}
                  onClick={() => handleRowClick(index)}
                  {...flags}
                />
                {isExpanded && <BillingTableItemHeadRow key={'row-expanded-' + index} index={index} {...flags} />}
                {isExpanded &&
                  item.pricePositions?.map((item1: Price) => {
                    return <BillingTableItemRow key={'row-data-child' + index} index={index} item={item1} {...flags} />;
                  })}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
