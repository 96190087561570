import styles from './pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
type PSLBillingTableHeadRowProps = {
  unitAmountCol: boolean;
};
export const PSLBillingTableHeadRow: VFC<PSLBillingTableHeadRowProps> = ({ unitAmountCol }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  return (
    <tr>
      <th className={styles.removeWhiteSpace}>{t('position')}</th>
      <th className={styles.removeWhiteSpace}>{t('product')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('shipmentQuantityPartialService')}</th>
      {unitAmountCol && (
        <th data-testid="psl-billing-unitAmount" className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>
          {t('basicFeePerPiece')}
        </th>
      )}
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('teleServiceDiscountPercent')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('partialServiceDiscount')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('shipmentQuantityIdDiscount')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('idDiscountPercentage')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('idDiscount')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('shipmentQuantityTransitTimeDiscount')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('termDiscountPercent')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('termDiscount')}</th>
      <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('totalFeeReductionWithoutVAT')}</th>
    </tr>
  );
};
