export interface JournalItem {
  /**
   * The journal registration number.
   * @type {string}
   * @memberof JournalRep
   */
  registrationNumber?: string;
  /**
   * The journal title from concatenating the two title parts
   * @type {string}
   * @memberof JournalRep
   */
  title?: string;
  /**
   * The journal hasTariffCode
   * @type {boolean}
   * @memberof JournalRep
   */
  hasTariffCode?: boolean;
}

export function isJournalItemArray(x: JournalItem[]): x is JournalItem[] {
  const filtered = x.filter((i) => !!i.registrationNumber && !!i.title);

  return x.length === filtered.length;
}
