import { useHistory } from 'react-router-dom';
import React, { VFC } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { useBoolean } from 'common/hooks/useBoolean';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { PostageType } from 'order/common/dtos/PostageType';
import { PrintDialog } from 'order/orderView/components/PrintDialog/PrintDialog';
import { CancelOrder } from 'order/productGroups/common/components/molecule/CancelOrder/CancelOrder';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { GetOrderRep } from 'generated';
import { SplitOrderModal } from '../SplitOrder/SplitOrderModal/SplitOrderModal';

import styles from './FooterSection.module.css';
import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { useOrderContext } from '../../../common/context/order/OrderContext';
import { Button } from 'common/components/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderCreate } from '../../../productGroups/common/utils/orderCreateSchema';
import { OrderMergeModal } from '../OrderMerge/components/OrderMergeModal/OrderMergeModal';
import { MovePalletModal } from 'order/orderView/components/MovePallet/MovePalletModal/MovePalletModal';
import { buttonSize } from '../../../common/utils/buttonSizeHelper';

export type FooterSectionProps = {
  order: Order;
  productionState?: ProductionState;
  isPayerValidated?: boolean;
  invoiceCenterLink?: string;
};

export const FooterSection: VFC<FooterSectionProps> = ({ order, productionState, isPayerValidated, invoiceCenterLink }) => {
  const [isPrintDialogOpen, setPrintDialogOpen] = useBoolean(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useBoolean(false);
  const [isSplitOrderDialogOpen, setSplitOrderDialogOpen] = useBoolean(false);
  const [isMovePalletDialogOpen, setMovePalletDialogOpen] = useBoolean(false);
  const [isMergeOrderDialogOpen, setMergeOrderDialogOpen] = useBoolean(false);
  const history = useHistory();
  const { hasPermission, checkWhitelistingKey, checkWhitelistingWithProductionState } = useAuthContext();
  const { clear } = useAlerts();
  const { setCancelOrderStatus } = useSearchContext();
  const { allocationsLoading, reset, order: rawOrder, orderPrice, allocationsOrders, resetAllocations, resetOrderReferences } = useOrderContext();
  const { t } = useTranslation('orderSearch');
  const isPartial = order.orderType === OrderTypes.Zusatzauftrag;
  const { triggerLastSearch } = useSearchContext();

  // Checks for Pallets move, split & merge buttons
  const isPalletMoveAllowed =
    hasPermission(UserRights.PalletsMove) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.PALLETSMOVE);
  const isSplitAllowed =
    hasPermission(UserRights.OrderSplit) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.SPLIT);

  const isMergeAllowed =
    hasPermission(UserRights.OrderMerge) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.MERGE);

  const isInvoiceCenterAllowed =
    hasPermission(UserRights.InvoiceCenter) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.INVOICE);

  const methods = useForm<OrderCreate>({
    mode: 'onBlur'
  });
  const buttonWidth = buttonSize([
    isPalletMoveAllowed ? i18n.t('viewOrderFooter.menu.movePallets') : '',
    isInvoiceCenterAllowed ? i18n.t('viewOrderFooter.menu.invoice') : '',
    isMergeAllowed ? i18n.t('viewOrderFooter.menu.merge') : ''
  ]);
  const isPrintReady =
    rawOrder?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
    rawOrder?.productGroup === ProductGroup.TEILLEISTUNG ||
    rawOrder?.productGroup === ProductGroup.COLLECTION
      ? true
      : !!orderPrice?.lastChangedAt;

  const disableEdit =
    rawOrder?.pendingIds ||
    rawOrder?.disableEditing ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.CHANGE)) ||
    !hasPermission(UserRights.OrderEdit) ||
    productionState === ProductionState.CANCELED ||
    productionState === ProductionState.CANCELED_AND_MERGED ||
    productionState === ProductionState.BILLED ||
    productionState === ProductionState.BEING_PROCESSED;

  const disableDelete =
    rawOrder?.pendingIds ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.DELETE)) ||
    !hasPermission(UserRights.OrderEdit) ||
    productionState === ProductionState.CANCELED ||
    productionState === ProductionState.CANCELED_AND_MERGED ||
    productionState === ProductionState.BILLED ||
    productionState === ProductionState.BEING_PROCESSED;

  const disableCopy =
    rawOrder?.pendingIds ||
    rawOrder?.disableEditing ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.COPY)) ||
    !hasPermission(UserRights.OrderEdit) ||
    isPartial ||
    order.postage?.type === PostageType.DV ||
    order.orderCategory === OrderCategory.DIP_SAMMEL ||
    order.orderCategory === OrderCategory.DIP_VARIO ||
    order.orderCategory === OrderCategory.DV_BRIEF;

  const disablePrint =
    rawOrder?.pendingIds ||
    (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.PRINT)) ||
    !hasPermission(UserRights.OrderPrint) ||
    ((order.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || order.productGroup === ProductGroup.TEILLEISTUNG) && allocationsLoading) ||
    !isPrintReady;

  return (
    <div className={classNames(styles.grid, styles.mt_26)}>
      <div className={styles.footer_left}>
        <DHLButton
          name={'auftrag-suchen'}
          label={i18n.t('viewOrderFooter.jobSearchButton')}
          type={'secondary'}
          onClick={() => {
            reset();
            triggerLastSearch();
            history.push(AppPaths.orderSearchPath);
          }}
          disabled={!hasPermission(UserRights.OrderSearch)}
        />
      </div>
      <CancelOrder
        orderId={order.orderId || ''}
        orderLabel={order.orderLabel || ''}
        show={isCancelDialogOpen}
        onCancel={() => setCancelDialogOpen.off()}
        orderMode={OrderMode.VIEW}
      />
      <FormProvider {...methods}>
        <SplitOrderModal
          order={order}
          show={isSplitOrderDialogOpen}
          onCancel={() => {
            setSplitOrderDialogOpen.off();
          }}
        />
        <MovePalletModal order={order} show={isMovePalletDialogOpen} onCancel={() => setMovePalletDialogOpen.off()} />
      </FormProvider>
      <FormProvider {...methods}>
        <OrderMergeModal order={order} show={isMergeOrderDialogOpen} onCancel={() => setMergeOrderDialogOpen.off()} />
      </FormProvider>
      <ErrorBoundary context={'OderView-Footer-PrintDialog'}>
        <PrintDialog
          show={isPrintDialogOpen}
          orderId={order.orderId || ''}
          allocatedOrders={allocationsOrders || undefined}
          onCancel={() => setPrintDialogOpen.off()}
          rawOrder={{ ...order, orderPrice: orderPrice } as GetOrderRep}
          orderCategory={order?.orderCategory}
        />
      </ErrorBoundary>
      <div className={styles.footer_right}>
        <ul className={classNames(styles.footerList)}>
          <li className={styles.flexButton}>
            <Button
              name={'auftrag-duplizieren'}
              label={i18n.t('viewOrderFooter.menu.copy')}
              // iconClass="icon-copy"
              JSXIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                  <path
                    fill={disableCopy ? 'rgba(50, 50, 50, 0.1)' : '#D40511'}
                    fillRule="evenodd"
                    d="M16.3333333,0 C17.4373333,0 18.3333333,0.896 18.3333333,2 L18.3333333,4.66666667 L20.3333333,4.66666667 C21.4373333,4.66666667 22.3333333,5.56266667 22.3333333,6.66666667 L22.3333333,22 C22.3333333,23.104 21.4373333,24 20.3333333,24 L7.66666667,24 C6.56266667,24 5.66666667,23.104 5.66666667,22 L5.66666667,20 L3,20 C1.896,20 1,19.104 1,18 L1,2 C1,0.896 1.896,0 3,0 L16.3333333,0 Z M16.3333333,2 L3,2 L3,18 L5.66666667,18 L5.66666667,6.66666667 C5.66666667,5.56266667 6.56266667,4.66666667 7.66666667,4.66666667 L16.3333333,4.66666667 L16.3333333,2 Z"
                  ></path>
                </svg>
              }
              type={'button'}
              sizing="sm"
              style={{ width: buttonWidth + 'px' }}
              className="px-2"
              dataTestId="auftrag-duplizieren"
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderCopyPath}/${order.orderId}`);
              }}
              disabled={disableCopy}
            />
          </li>
          <li className={styles.flexButton}>
            <Button
              name={'auftrag-drucken'}
              label={i18n.t('viewOrderFooter.menu.print')}
              // iconClass="icon-print"

              JSXIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                  <path
                    fill={disablePrint ? 'rgba(50, 50, 50, 0.1)' : '#D40511'}
                    fillRule="evenodd"
                    d="M16.941181,3.82354287 L7.05881904,3.82354287 L7.05881904,5.23531431 L16.941181,5.23531431 L16.941181,3.82354287 Z M16.941181,6.6470476 L7.05881904,6.6470476 L7.05881904,8.05881904 L16.941181,8.05881904 L16.941181,6.6470476 Z M23.2941143,9.47059048 L19.7646857,9.47059048 L19.7646857,1 L4.23527617,1 L4.23527617,9.47059048 L0.705885719,9.47059048 C0.282354287,9.47059048 0,9.75294477 0,10.1764762 L0,21.4705714 C0,21.8941028 0.282354287,22.1764571 0.705885719,22.1764571 L23.2941143,22.1764571 C23.7176457,22.1764571 24,21.8941028 24,21.4705714 L24,10.1764762 C24,9.82351427 23.6470381,9.47059048 23.2941143,9.47059048 Z M18.3529143,17.941181 L5.6470476,17.941181 L5.6470476,16.5294095 L18.3529143,16.5294095 L18.3529143,17.941181 Z M18.3529143,9.47059048 L5.6470476,9.47059048 L5.6470476,2.41177144 L18.3529143,2.41177144 L18.3529143,9.47059048 Z M21.1764571,17.941181 L19.7646857,17.941181 L19.7646857,16.5294095 L21.1764571,16.5294095 L21.1764571,17.941181 Z M22.5882286,13.7058666 L21.1764571,13.7058666 L21.1764571,12.2941334 L22.5882286,12.2941334 L22.5882286,13.7058666 Z"
                  ></path>
                </svg>
              }
              type={'button'}
              sizing="sm"
              style={{ width: buttonWidth + 'px' }}
              className="px-2"
              dataTestId="auftrag-drucken"
              onClick={() => setPrintDialogOpen.on()}
              disabled={disablePrint}
            />
          </li>
          <li className={styles.flexButton}>
            <Button
              name={'auftrag-stornieren'}
              label={i18n.t('viewOrderFooter.menu.delete')}
              // iconClass="icon-delete"
              JSXIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                  <path
                    fill={disableDelete ? 'rgba(50, 50, 50, 0.1)' : '#D40511'}
                    fillRule="evenodd"
                    d="M19.5842511,4.55352 L19.5842511,22.03578 C19.5842511,23.12058 18.7189499,24 17.6525202,24 L17.6525202,24 L6.34871918,24 C5.28040092,24 4.41604399,23.12058 4.41604399,22.03578 L4.41604399,22.03578 L4.41604399,4.55352 L19.5842511,4.55352 Z M7.31653219,6.71454 C6.83572405,6.71454 6.44438506,7.10874 6.44438506,7.59726 L6.44438506,7.59726 L6.44438506,20.95596 C6.44438506,21.44508 6.83572405,21.83928 7.31653219,21.83928 C7.79574687,21.83928 8.18643668,21.44508 8.18643668,20.95596 L8.18643668,20.95596 L8.18643668,7.59726 C8.18643668,7.10874 7.79574687,6.71454 7.31653219,6.71454 Z M11.6640481,6.71454 C11.183535,6.71454 10.7934944,7.10874 10.7934944,7.59726 L10.7934944,7.59726 L10.7934944,20.95596 C10.7934944,21.44508 11.183535,21.83928 11.6640481,21.83928 C12.144148,21.83928 12.5327132,21.44508 12.5327132,20.95596 L12.5327132,20.95596 L12.5327132,7.59726 C12.5327132,7.10874 12.144148,6.71454 11.6640481,6.71454 Z M16.0110328,6.71454 C15.5315231,6.71454 15.1385316,7.10874 15.1385316,7.59726 L15.1385316,7.59726 L15.1385316,20.95596 C15.1385316,21.44508 15.5315231,21.83928 16.0110328,21.83928 C16.4905426,21.83928 16.8802291,21.44508 16.8802291,20.95596 L16.8802291,20.95596 L16.8802291,7.59726 C16.8802291,7.10874 16.4905426,6.71454 16.0110328,6.71454 Z M20.2414008,2.26488 C20.6597104,2.26488 21,2.60982 21,3.03582 C21,3.46116 20.6597104,3.8064 20.2414008,3.8064 L3.75960249,3.8064 C3.33993554,3.8064 3,3.46116 3,3.03582 C3,2.60982 3.33993554,2.26488 3.75960249,2.26488 L20.2414008,2.26488 Z M14.3681881,0 C14.787796,0 15.1287939,0.34494 15.1287939,0.77058 C15.1287939,1.19628 14.787796,1.54152 14.3681881,1.54152 L9.62986436,1.54152 C9.21019741,1.54152 8.87156023,1.19628 8.87156023,0.77058 C8.87156023,0.34494 9.21019741,0 9.62986436,0 L14.3681881,0 Z"
                  ></path>
                </svg>
              }
              type={'button'}
              sizing="sm"
              style={{ width: buttonWidth + 'px' }}
              className="px-2"
              dataTestId="auftrag-stornieren"
              onClick={async () => {
                setCancelDialogOpen.on();
                setCancelOrderStatus(undefined, '');
              }}
              disabled={disableDelete}
            />
          </li>
          <li className={styles.flexButton}>
            <Button
              name={'auftrag-bearbeiten'}
              label={i18n.t('viewOrderFooter.menu.edit')}
              // iconClass="icon-edit"
              JSXIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                  <path
                    fill={disableEdit ? 'rgba(50, 50, 50, 0.1)' : '#D40511'}
                    fillRule="evenodd"
                    d="M1.07148,18.9618 L5.03844,22.92876 L0,23.95098 L1.07148,18.9618 Z M19.96746,-1.90070182e-13 L24,4.03518 L5.70972,22.32714 L1.67448,18.2919 L19.96746,-1.90070182e-13 Z"
                  ></path>
                </svg>
              }
              type={'button'}
              sizing="sm"
              style={{ width: buttonWidth + 'px' }}
              className="px-2"
              dataTestId="auftrag-bearbeiten"
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderChangePath}/${order.orderId}${isPartial ? '?orderType=ZA' : ''}`);
              }}
              disabled={disableEdit}
            />
          </li>

          {isInvoiceCenterAllowed && isPayerValidated && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-rechnungscenter'}
                label={i18n.t('viewOrderFooter.menu.invoice')}
                // iconClass={'icon-integration-rechnungscenter'}
                JSXIcon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="mr-2">
                    <path
                      fill="#D40511"
                      fillRule="evenodd"
                      d="M20.1071,0 C21.1518,0 22,0.8482 22,1.8929 L22,1.8929 L22,22.1072 C22,23.1519 21.1518,24 20.1071,24 L20.1071,24 L3.8928,24 C2.8481,24 2,23.1519 2,22.1072 L2,22.1072 L2,1.8929 C2,0.8482 2.8481,0 3.8928,0 L3.8928,0 Z M20,2 L4,2 L4,22 L20,22 L20,2 Z M13.1847,5 C14.7479,5 16.0205,5.5111 17,6.5384 L17,6.5384 L15.5834,8.0486 C15.237,7.7082 14.8995,7.47 14.5742,7.3302 C14.186,7.1634 13.7693,7.0795 13.3236,7.0795 C12.1025,7.0795 11.2576,7.7209 10.8069,9.026 L10.8069,9.026 L10.6968,9.3453 L13.8112,9.3453 L13.8112,10.8641 L10.4962,10.8641 L10.4962,12.4491 L13.8112,12.4491 L13.8112,13.968 L10.7015,13.968 L10.8059,14.2842 C11.2422,15.6045 12.0875,16.2538 13.3236,16.2538 C13.7693,16.2538 14.186,16.1698 14.5742,16.0031 C14.8995,15.8632 15.237,15.6251 15.5834,15.2847 L15.5834,15.2847 L17,16.7949 C16.0205,17.8222 14.7479,18.3333 13.1847,18.3333 C11.9368,18.3333 10.8709,17.9732 9.9886,17.2485 C9.1016,16.5198 8.5167,15.4884 8.2252,14.159 L8.2252,14.159 L8.1834,13.968 L7,13.968 L7,12.4491 L8.0322,12.4491 L8.0322,10.8641 L7,10.8641 L7,9.3453 L8.1811,9.3453 L8.2246,9.1569 C8.529,7.8392 9.1178,6.8135 9.9992,6.0842 C10.8749,5.3598 11.9373,5 13.1847,5 Z"
                    />
                  </svg>
                }
                type={'button'}
                sizing="sm"
                style={{ width: buttonWidth + 'px' }}
                className="px-2"
                dataTestId="InvoiceOrderButton"
                onClick={() => {
                  window.open(invoiceCenterLink, '_blank');
                }}
              />
            </li>
          )}
          {isPalletMoveAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-paletten-verschieben'}
                label={i18n.t('viewOrderFooter.menu.movePallets')}
                // iconClass={'icon-move'}
                JSXIcon={
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                    <path
                      fill="#D40511"
                      fillRule="evenodd"
                      d="M469.335 241.478l-77.993 77.993-60.339-60.339 181.020-181.020 181.018 181.020-60.339 60.339-78.033-78.033v227.895h239.934l-77.99-77.99 60.339-60.339 181.020 181.020-181.020 181.018-60.339-60.339 78.035-78.035h-239.979v229.086l78.033-78.033 60.339 60.339-181.018 181.020-181.020-181.020 60.339-60.339 77.993 77.99v-229.043h-229.090l78.035 78.035-60.339 60.339-181.020-181.018 181.020-181.020 60.339 60.339-77.99 77.99h229.045v-227.855z"
                    ></path>
                  </svg>
                }
                type={'button'}
                sizing="sm"
                style={{ width: buttonWidth + 'px' }}
                className="px-2"
                dataTestId="movePalletsButton"
                onClick={() => {
                  setMovePalletDialogOpen.on();
                  clear();
                }}
              />
            </li>
          )}
          {isSplitAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-teilen'}
                label={i18n.t('viewOrderFooter.menu.share')}
                // iconClass={'icon-split'}
                JSXIcon={
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                    <path
                      fill="#D40511"
                      fillRule="evenodd"
                      d="M554.667 487.006v366.327h-85.333v-347.381l-256.010-256.011v141.065h-85.333v-284.339h284.339v85.333h-136.267l226.466 226.466 226.466-226.466h-131.661v-85.333h284.339v284.339h-85.333v-145.67l-241.673 241.67z"
                    ></path>
                  </svg>
                }
                type={'button'}
                sizing="sm"
                style={{ width: buttonWidth + 'px' }}
                className="px-2"
                dataTestId="splitOrderButton"
                onClick={() => {
                  setSplitOrderDialogOpen.on();
                  clear();
                }}
              />
            </li>
          )}
          {isMergeAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-zusammenfuhren'}
                label={i18n.t('viewOrderFooter.menu.merge')}
                // iconClass={'icon-merge'}
                JSXIcon={
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024" className="mr-2">
                    <path
                      fill="#D40511"
                      fillRule="evenodd"
                      d="M371.505 371.503l97.83-97.83v284.657l-264.611 264.61 60.34 60.341 247.164-247.166 247.166 247.166 60.339-60.341-265.065-265.065v-284.644l98.274 98.272 60.339-60.339-201.058-201.058-201.058 201.058 60.339 60.339z"
                    ></path>
                  </svg>
                }
                type={'button'}
                sizing="sm"
                style={{ width: buttonWidth + 'px' }}
                className="px-2"
                dataTestId="mergeOrderButton"
                onClick={() => {
                  resetAllocations();
                  resetOrderReferences();
                  clear();
                  setMergeOrderDialogOpen.on();
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
