// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AsideInfoBox_infoBox__dSZ3i {
  margin-bottom: 1rem;
}

.AsideInfoBox_list__pslsZ {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: .9rem;
  color: #717171;
  line-height: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/atom/AsideInfoBox/AsideInfoBox.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".infoBox {\n  margin-bottom: 1rem;\n}\n\n.list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  font-size: .9rem;\n  color: #717171;\n  line-height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `AsideInfoBox_infoBox__dSZ3i`,
	"list": `AsideInfoBox_list__pslsZ`
};
export default ___CSS_LOADER_EXPORT___;
