import { QtyAllocation } from '../dtos/Order';

export const qtySumCalculator = (qtyAlloc: QtyAllocation[], property: keyof QtyAllocation) => {
  try {
    return qtyAlloc.map((r) => r[property]).reduce((sum, current) => (sum as number) + (current as number), 0);
  } catch (e) {
    console.error('qtySumCalculator', e);
    return 0;
  }
};
