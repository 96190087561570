export enum ColumnType {
  COMMON = 'common',
  CUSTOM = 'custom',
  CUSTOM_CELL = 'customCell',
  READONLY = 'readOnly',
  DATE_TIME = 'dateTime',
  NUMBER = 'number',
  ICON_BUTTON = 'iconButton',
  CUSTOM_ICON_BUTTON = 'customIconButton',
  CHILD_EXPAND = 'childExpand',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  CURRENCY = 'currency',
  DATE = 'date',
  TIME = 'time',
  IMAGE_LINK = 'imageLink',
  BUTTON_LINK = 'buttonLink',
  LINK = 'link',
  RESOURCE_KEY = 'resourceKey',
  TREE_EXPAND = 'treeExpand',
  SUB_COMPONENT_EXPAND = 'subComponentExpand'
}
