/**
 * This package provides a list of default `DateRangePreset`s to be used with date range pickers.
 * There are also several utility methods available to work with the default presets.
 *
 * For example, if you have a preset key in the form of the enum {@link DefaultDayRangePreset}, you can ask for the linked
 * {@link DayRangePreset} to get the corresponding localization by calling {@link getDefaultDayRange}:
 * ```
 * getDefaultDayRange(this.preset)?.getLocalization()
 * ```
 *
 * @module
 * @see AMDHLDateRangeInput
 */
import { DateRangePreset } from '@gkuis/gkp-base-widgets/dist/lib/stores/DateRangePickerDataStore';
import i18n from 'i18n';
import moment from 'moment';

/**
 * DayRangePreset is a date range with a day-based granularity.
 * It implements the `DateRangePreset` to be used in a `DateRangePickerDataStore`.
 * By adding the enum {@link DefaultDayRangePreset}, we list the default presets and give them a unique key.
 */
export class DayRangePreset implements DateRangePreset {
  startOffsetInDays: number;
  endOffsetInDays: number;
  preset: DefaultDayRangePreset;

  constructor(preset: DefaultDayRangePreset, startOffsetInDays: number, endOffsetInDays: number) {
    this.preset = preset;
    this.startOffsetInDays = startOffsetInDays;
    this.endOffsetInDays = endOffsetInDays;
  }

  getLocalization(): string {
    return i18n.t('orderSearch.dateRange.preset.' + DefaultDayRangePreset[this.preset]);
  }

  getStartDate(): moment.Moment {
    return moment().add(this.startOffsetInDays, 'days');
  }

  getEndDate(): moment.Moment {
    return moment().add(this.endOffsetInDays, 'days');
  }
}

/**
 * The DefaultDayRangePreset enum represents the list of keys to map the default {@link DayRangePreset}s to.
 */
export enum DefaultDayRangePreset {
  /* eslint-disable */
  LAST_30_DAYS,
  LAST_AND_NEXT_14_DAYS,
  LAST_AND_NEXT_1_DAY,
  LAST_7_DAYS,
  LAST_3_DAYS,
  YESTERDAY,
  TODAY,
  TOMORROW,
  NEXT_7_DAYS,
  NEXT_30_DAYS,
  USER_DEFINED
  /* eslint-enable */
}

const LAST_30_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.LAST_30_DAYS, -29, 0);
const LAST_AND_NEXT_14_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS, -14, 14);
const LAST_AND_NEXT_1_DAY_PRESET = new DayRangePreset(DefaultDayRangePreset.LAST_AND_NEXT_1_DAY, -1, 1);
const LAST_7_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.LAST_7_DAYS, -6, 0);
const LAST_3_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.LAST_3_DAYS, -3, 0);
const YESTERDAY_PRESET = new DayRangePreset(DefaultDayRangePreset.YESTERDAY, -1, -1);
const TODAY_PRESET = new DayRangePreset(DefaultDayRangePreset.TODAY, 0, 0);
const TOMORROW_PRESET = new DayRangePreset(DefaultDayRangePreset.TOMORROW, 1, 1);
const NEXT_7_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.NEXT_7_DAYS, 0, 6);
const NEXT_30_DAYS_PRESET = new DayRangePreset(DefaultDayRangePreset.NEXT_30_DAYS, 0, 29);
const USER_DEFINED_PRESET = new DayRangePreset(DefaultDayRangePreset.USER_DEFINED, 0, 0);

const defaultDayRanges: Map<DefaultDayRangePreset, DayRangePreset> = new Map<DefaultDayRangePreset, DayRangePreset>([
  [DefaultDayRangePreset.LAST_30_DAYS, LAST_30_DAYS_PRESET],
  [DefaultDayRangePreset.LAST_AND_NEXT_14_DAYS, LAST_AND_NEXT_14_DAYS_PRESET],
  [DefaultDayRangePreset.LAST_AND_NEXT_1_DAY, LAST_AND_NEXT_1_DAY_PRESET],
  [DefaultDayRangePreset.LAST_7_DAYS, LAST_7_DAYS_PRESET],
  [DefaultDayRangePreset.YESTERDAY, YESTERDAY_PRESET],
  [DefaultDayRangePreset.TODAY, TODAY_PRESET],
  [DefaultDayRangePreset.TOMORROW, TOMORROW_PRESET],
  [DefaultDayRangePreset.NEXT_7_DAYS, NEXT_7_DAYS_PRESET],
  [DefaultDayRangePreset.NEXT_30_DAYS, NEXT_30_DAYS_PRESET],
  [DefaultDayRangePreset.USER_DEFINED, USER_DEFINED_PRESET]
]);

const partialSearchDayRanges: Map<DefaultDayRangePreset, DayRangePreset> = new Map<DefaultDayRangePreset, DayRangePreset>([
  [DefaultDayRangePreset.YESTERDAY, YESTERDAY_PRESET],
  [DefaultDayRangePreset.TODAY, TODAY_PRESET],
  [DefaultDayRangePreset.TOMORROW, TOMORROW_PRESET],
  [DefaultDayRangePreset.LAST_3_DAYS, LAST_3_DAYS_PRESET],
  [DefaultDayRangePreset.USER_DEFINED, USER_DEFINED_PRESET]
]);

const partialCollectionSearchDayRanges: Map<DefaultDayRangePreset, DayRangePreset> = new Map<DefaultDayRangePreset, DayRangePreset>([
  [DefaultDayRangePreset.USER_DEFINED, USER_DEFINED_PRESET]
]);

/**
 * This function returns a list of all default {@link DayRangePreset}s.
 *
 * @return {DayRangePreset[]} list of default {@link DayRangePreset}s
 */
export function getDefaultDayRanges(): DayRangePreset[] {
  return Array.from(defaultDayRanges.values());
}

export function getPartialSearchDayRanges(isCollection: boolean): DayRangePreset[] {
  return isCollection ? Array.from(partialCollectionSearchDayRanges.values()) : Array.from(partialSearchDayRanges.values());
}

/**
 * This function returns a list of key-value entries with {@link DefaultDayRangePreset} keys and according {@link DayRangePreset} values.
 *
 * @return {[DefaultDayRangePreset, DayRangePreset][]} list of key value entries
 */
export function getDefaultDayRangeEntries(): [DefaultDayRangePreset, DayRangePreset][] {
  return Array.from(defaultDayRanges.entries());
}

export function getPartialSearchRangeEntries(isCollection: boolean): [DefaultDayRangePreset, DayRangePreset][] {
  return isCollection ? Array.from(partialCollectionSearchDayRanges.entries()) : Array.from(partialSearchDayRanges.entries());
}

/**
 * This function finds a {@link DayRangePreset} value identifiable by a {@link DefaultDayRangePreset} key, if one exists.
 *
 * @param {DefaultDayRangePreset} preset the {@link DefaultDayRangePreset} key
 *
 * @return {DayRangePreset} value if it exists, {@code undefined} otherwise
 */
export function getDefaultDayRange(preset: DefaultDayRangePreset): DayRangePreset | undefined {
  return defaultDayRanges.get(preset) || partialSearchDayRanges.get(preset);
}
