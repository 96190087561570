import classNames from 'classnames';
import React, { ReactNode, VFC } from 'react';
import classes from './order-overview.module.css';
import toolTipStyles from '../../../../../../common/components/AMDHLRadioButtonCard/AMDHLRadioButtonCard.module.css';
import { Tooltip, TooltipProps } from '../../../../../../common/components/Tooltip/Tooltip';

export type OrderOverviewRowProps = {
  children?: ReactNode;
  noMargin?: boolean;
  classNames?: string;
};

export const OrderOverviewRow: VFC<OrderOverviewRowProps> = ({ children, noMargin }) => {
  return <section className={classNames(classes.row, [{ [classes.noMargin]: noMargin }])}>{children}</section>;
};

export type OrderOverviewWidgetProps = {
  width?: 1 | 2 | 3;
  title?: ReactNode;
  children?: ReactNode;
  tooltip?: TooltipProps;
};

export const OrderOverviewWidget: VFC<OrderOverviewWidgetProps> = ({ width = 1, title, children, tooltip }) => {
  const containerClass = classNames(classes.widget, [classes.widgetOne, classes.widgetTwo, classes.widgetThree][width - 1]);

  return (
    <div className={containerClass}>
      {title && (
        <div className={classNames(toolTipStyles.tooltipTileFlexer)}>
          <h6 className={classes.title}>{title}</h6>
          {tooltip && (
            <Tooltip placement={tooltip.placement} content={tooltip.content}>
              <i className={classNames(toolTipStyles.radioButtonCardInfoForTooltip, 'icon-alert-info')}></i>
            </Tooltip>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
