import React, { useEffect, useState } from 'react';

import { usePayerInfo } from 'common/hooks/usePayerInfo';
import i18n, { useTranslation } from 'i18n';
import { OrderOverviewWidget, OrderOverviewWidgetProps } from 'order/productGroups/common/components/molecule/OrderOverview';
import { CustomerInfoWithAddressTO, OrderCustomerRoleEnum, PaymentRep } from 'generated';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';

import transDE from './translate.de.json';
import transEN from './translate.en.json';
import { TooltipProps } from '../../../../common/components/Tooltip/Tooltip';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { parseIsoDate, validateSettlementNumber } from 'common/utils/helpers';

i18n.addResourceBundle('de', 'payerInfo', transDE);
i18n.addResourceBundle('en', 'payerInfo', transEN);

export type PayerInfoProps = {
  payerId?: string;
  validityDate?: Date | string;
  role: OrderCustomerRoleEnum;
  withAddress?: boolean;
  step?: 'Overview';
  toolTip?: TooltipProps;
} & Pick<OrderOverviewWidgetProps, 'width'>;

export const PayerInfo: React.VFC<PayerInfoProps> = ({ payerId, validityDate, role, width, withAddress, step, toolTip }) => {
  const { orderCategory, order } = useOrderContext();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const payerData = usePayerInfo(payerId, typeof validityDate === 'string' ? parseIsoDate(validityDate) : validityDate);
  const valid = validateSettlementNumber(payerId);
  const { t } = useTranslation('payerInfo');
  const showNetPayroll: boolean = payerId?.substr(10, 2) === '38';
  const isCollection = orderCategory === OrderCategory.ABHOLAUFTRAG || order?.orderCategory === OrderCategory.ABHOLAUFTRAG;
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const filteredParty = order?.parties?.filter((p) => p.customerId === payerId);
  const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
    customerId: payerId,
    name: filteredParty?.[0]?.name,
    address: filteredParty?.[0]?.orderAddress
  };

  const fetchCustomerInfo = async (payerId?: string) => {
    if (payerId) {
      const { customerInfo: data } = await getCustomerInfo(payerId);
      if (data) {
        setCustomerInfo(data);
      } else if (transformedCustomerData) {
        setCustomerInfo(transformedCustomerData);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(payerId);
  }, [payerId]);

  return (
    <OrderOverviewWidget title={isCollection ? oc('customer') : t(role)} width={width} tooltip={toolTip}>
      {payerId?.length === 10 || withAddress ? (
        <>
          <p data-testid="customerNumber">
            {payerId?.length === 10 ? t('customerNumber') : t('billingNumber')}: {customerInfo?.customerId}
          </p>
          <p>{customerInfo?.name}</p>
          <p>
            {customerInfo?.address?.street} {customerInfo?.address?.streetNumber}
          </p>
          <p>
            {customerInfo?.address?.postCode} {customerInfo?.address?.city}
          </p>
        </>
      ) : (
        <>
          <p data-testid="billingNumber">
            {payerId?.length === 17 ? t('postcardNumber') : t('billingNumber')}: {payerData?.companyName ? payerId : ''}
          </p>
          <p>{payerData?.companyName}</p>
          {role === OrderCustomerRoleEnum.Contractor && (
            <>
              <p>
                {payerData?.address?.street} {payerData?.address?.streetNumber}
              </p>
              <p>
                {payerData?.address?.postCode} {payerData?.address?.city}
              </p>
            </>
          )}
          <p>
            {t('iban')}: {payerData?.iban}
          </p>
          <p>
            {t('bank')}: {payerData?.bank}
          </p>
          {showNetPayroll ? <p>{t('netPayroll')}</p> : ''}
        </>
      )}
    </OrderOverviewWidget>
  );
};
