/**
 * Sanitize a string value to be placed into a csv file.
 * This method replaces a single quotation mark with two marks and deletes carriage returns and line feeds.
 *
 * @param value string value to be sanitized
 * @return {string} sanitized value
 */
export const sanitizeValue = (value: string): string =>
  value.replaceAll('"', '""').replaceAll('\r\n', ' ').replaceAll('\r', ' ').replaceAll('\n', ' '); //

export type CSVMatrixElement = string | number | undefined;

export type CSVMatrix = CSVMatrixElement[][];

export const matrixToCsv = (matrix: CSVMatrix): string => matrix.map(matrixRowToCsv).join('\r\n');

const matrixRowToCsv = (row: CSVMatrixElement[]): string =>
  row
    .map((value) => (typeof value === 'string' ? sanitizeValue(value) : value))
    .map((value) => (value ? `"${value}"` : ''))
    .join(';');
