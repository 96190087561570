import { getJournalPaymentData } from 'common/services/JournalService';
import { JournalPaymentInfo } from '../dtos/JournalPaymentInfo';

export const fetchJournalPaymentInfo = async (zkz: string): Promise<JournalPaymentInfo | undefined> => {
  try {
    const { data } = await getJournalPaymentData(zkz);
    return data;
  } catch (e) {
    console.error(`Fetching order data for ${zkz} failed`, e);
    return undefined;
  }
};
