import React, { FC } from 'react';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { Order as Order } from 'order/common/context/order/dtos/Order';
import { useTranslation } from 'i18n';
import { toFormattedDate } from 'common/formatting';
import { OrderCustomerRoleEnum } from 'generated';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';

export type PostingInfoSectionProps = {
  value: Order;
};

export const PostingInfoSection: FC<PostingInfoSectionProps> = ({ value }) => {
  const date = new Date(value.orderDetail?.date ? value.orderDetail?.date : '');
  const { getFacility } = useProductionFacilities(date);
  const { t } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const facility = getFacility(value.orderDetail?.productionPlantId || '');
  const findByRole = (role: OrderCustomerRoleEnum) => value?.parties.find((p) => p.role === role);
  const contactForQuestions = findByRole(OrderCustomerRoleEnum.Submitter)?.contact;

  return (
    <OrderOverviewRow>
      <OrderOverviewWidget width={1} title={t('postingInformation')}>
        <table data-testid="postingInfoSection">
          <tr>
            <td>{t('consignmentDate')}</td>
            <td>
              <p>{toFormattedDate(date)}</p>
            </td>
          </tr>
          <tr>
            <td>{t('consignmentCity')}</td>
            <td>
              <p>
                {facility?.address?.postCode} {facility?.comment}
              </p>
            </td>
          </tr>
          {contactForQuestions && (
            <tr>
              <td>{t('contactPersonForQueries')}</td>
              <td>
                <p>
                  {contactForQuestions?.firstName} {contactForQuestions?.lastName}
                </p>
                <p>{contactForQuestions?.telephone}</p>
                <p>{contactForQuestions?.email}</p>
              </td>
            </tr>
          )}
        </table>
      </OrderOverviewWidget>
    </OrderOverviewRow>
  );
};
