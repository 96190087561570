import { useRecommendations } from 'common/hooks/useRecommendations';
import { requestUserRecommendations, sendUserRecommendations } from '../services/recommendations';

export interface GenericRecommendation {
  key: string;
  meta?: string;
  value: string;
}

export const useGenericRecommendations = (key: string): any => {
  const keyValue = `${key}-Recommendations`;

  return useRecommendations<GenericRecommendation>({
    key: keyValue,
    get: async () => {
      return await requestUserRecommendations<GenericRecommendation>(keyValue);
    },
    set: (recommendations) => {
      sendUserRecommendations(keyValue, JSON.stringify(recommendations));
    },
    compare: (a, b) => a.value === b.value
  });
};
