// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-post-overview_infoBox__-X20c {
  margin-bottom: 1rem;
}

.dialog-post-overview_list__Oyx8N {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  color: #717171;
  line-height: 1.5rem;
}

.dialog-post-overview_list__Oyx8N li {
  margin-bottom: 5px;
}

.dialog-post-overview_list__Oyx8N table {
  margin: 1rem 0;
}

.dialog-post-overview_header__kCkjr {
  margin-bottom: 10px !important;
}

.dialog-post-overview_section__SvRZk {
  margin-bottom: 30px !important;
}

.dialog-post-overview_icon__aJiml {
  padding-right: 10px !important;
  height: 24px !important;
  width: 23px !important;
  margin-right: 12px !important;
}

.dialog-post-overview_OrderOverviewWidget__1AdJP p,
.dialog-post-overview_OrderOverviewWidget__1AdJP h6 {
  display: inline;
}

.dialog-post-overview_OrderOverviewWidget__1AdJP p {
  margin-left: 5px;
}

.dialog-post-overview_OrderOverviewWidgetTwo__NSeQa {
  margin-top: 10px;
}

.dialog-post-overview_OrderOverviewWidget__1AdJP h6,
.dialog-post-overview_onlyTitle__ASUyx {
  color: #323232;
  font-weight: 600;
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  line-height: 1.125;
}

/* For Postaktuell*/

.dialog-post-overview_tableContainer__b8HZ\\+ {
  max-width: 75%;
}
.dialog-post-overview_patableContainer__ntwEh thead th p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.dialog-post-overview_onlyTitle__ASUyx {
  margin-bottom: 0;
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/dialogMarketing/components/Overview/dialog-post-overview.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA,mBAAmB;;AAEnB;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".infoBox {\n  margin-bottom: 1rem;\n}\n\n.list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  font-size: 0.9rem;\n  color: #717171;\n  line-height: 1.5rem;\n}\n\n.list li {\n  margin-bottom: 5px;\n}\n\n.list table {\n  margin: 1rem 0;\n}\n\n.header {\n  margin-bottom: 10px !important;\n}\n\n.section {\n  margin-bottom: 30px !important;\n}\n\n.icon {\n  padding-right: 10px !important;\n  height: 24px !important;\n  width: 23px !important;\n  margin-right: 12px !important;\n}\n\n.OrderOverviewWidget p,\n.OrderOverviewWidget h6 {\n  display: inline;\n}\n\n.OrderOverviewWidget p {\n  margin-left: 5px;\n}\n\n.OrderOverviewWidgetTwo {\n  margin-top: 10px;\n}\n\n.OrderOverviewWidget h6,\n.onlyTitle {\n  color: #323232;\n  font-weight: 600;\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  line-height: 1.125;\n}\n\n/* For Postaktuell*/\n\n.tableContainer {\n  max-width: 75%;\n}\n.patableContainer thead th p {\n  font-size: 14px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n.onlyTitle {\n  margin-bottom: 0;\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `dialog-post-overview_infoBox__-X20c`,
	"list": `dialog-post-overview_list__Oyx8N`,
	"header": `dialog-post-overview_header__kCkjr`,
	"section": `dialog-post-overview_section__SvRZk`,
	"icon": `dialog-post-overview_icon__aJiml`,
	"OrderOverviewWidget": `dialog-post-overview_OrderOverviewWidget__1AdJP`,
	"OrderOverviewWidgetTwo": `dialog-post-overview_OrderOverviewWidgetTwo__NSeQa`,
	"onlyTitle": `dialog-post-overview_onlyTitle__ASUyx`,
	"tableContainer": `dialog-post-overview_tableContainer__b8HZ+`,
	"patableContainer": `dialog-post-overview_patableContainer__ntwEh`
};
export default ___CSS_LOADER_EXPORT___;
