import React, { useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { PressProductInfoSection } from 'order/common/components/Overview/PressProductInfoSection';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import i18n, { useTranslation } from 'i18n';
import { changePressOrder, createPressOrder } from 'order/common/services/OrderService';
import { AppPaths } from 'Main';
import { useAlerts } from 'common/AlertContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import orderClasses from 'common/styles/order.module.css';
import { createMultipleOrders, PressOrderCreate, translatePressDistribution } from 'order/productGroups/press/schema/pressSchema';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { Order } from 'order/common/context/order/dtos/Order';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { usePressDistributionDependentProducts } from '../../../../common/hooks/usePressDistributionDependentProducts';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import iconDataEdit from 'assets/icon-data-edit.svg';
import { PRODUCT_TYPES } from '../../components/PressBaseProduct/PressBaseProducts';
import Axios from 'axios';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';
import { fetchErrorCode } from '../../../../common/utils/errorCodeHelper';

export const PressStep4: VFC = observer(() => {
  const { t } = useTranslation('orderCreate');
  const { watch, handleSubmit } = useFormContext<PressOrderCreate>();
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { hasPermission } = useAuthContext();
  const values = watch();
  const { reset: resetSearchContext, triggerMultipleAlerts, updateMyOrders, myOrders } = useSearchContext();
  const history = useHistory();
  const { reset: resetOrderContext, isDirty, setDirty, orderMode, order, orderId, orderCategoryProductKey, meta } = useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const { pressDistributionDependentProducts: dependentProductsReturn } = usePressDistributionDependentProducts(
    values.journal?.zkz,
    values.details?.date
  );

  // Finding the national base product to connect international base product with.
  const pressNBPTypes: PRODUCT_TYPES[] = [PRODUCT_TYPES.NEXT_DAY, PRODUCT_TYPES.SECOND_DAY, PRODUCT_TYPES.BASIC, PRODUCT_TYPES.SAME_DAY];
  const nationalProdWithIBP: { duration?: string } = { duration: undefined };
  const allSelectedNBPTypes = values?.nationalProducts?.flatMap((pd) => pd?.baseProductType);
  nationalProdWithIBP['duration'] = pressNBPTypes.find((value) => allSelectedNBPTypes?.includes(value));

  return (
    <>
      <main className={orderClasses.formContent}>
        <OrderStepHeadline icon={iconDataEdit} alt={'data Edit Icon'}>
          {t('steps.overview')}
        </OrderStepHeadline>
        <div style={{ marginTop: '-10px' }}>
          <PressProductInfoSection
            value={translatePressDistribution(values, orderId as string | undefined, order?.amexOrder, dependentProductsReturn) as Order}
            orderMode={orderMode}
            supplements={values.supplements}
            pressDependentProducts={dependentProductsReturn}
            nationalProdWithIBP={nationalProdWithIBP.duration}
          />
        </div>
        <CustomerDetailSection
          parties={translatePressDistribution(values, orderId as string | undefined, order?.amexOrder, dependentProductsReturn)?.parties || []}
          validityDate={order?.orderDetail?.date}
        />
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
            dataTestId="btnBack"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant={activeSpinner ? 'default' : 'primary'}
          disabled={!hasPermission(UserRights.OrderEdit) || activeSpinner}
          dataTestId="btnSubmit"
          onClick={async () => {
            await handleSubmit(async (values: PressOrderCreate) => {
              try {
                deleteAlerts();
                const allOrders = createMultipleOrders(
                  values,
                  orderId as string | undefined,
                  order,
                  dependentProductsReturn,
                  true,
                  orderMode,
                  nationalProdWithIBP.duration,
                  orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable ? meta?.enablePackaging : undefined
                );
                setActiveSpinner(true);
                let firstOrderResponse: any;
                if (allOrders.length && allOrders[0].orderId && allOrders[0].amexOrder) {
                  setActiveSpinner(true);
                  const { data } = await changePressOrder(allOrders[0]);
                  firstOrderResponse = data;
                } else if (allOrders.length) {
                  setActiveSpinner(true);
                  const { data } = await createPressOrder(allOrders[0]);
                  firstOrderResponse = data;
                }
                if (firstOrderResponse?.resultStatus !== 'ERROR') {
                  Axios.all(
                    allOrders?.slice(1)?.map((val) =>
                      val.orderId && val.amexOrder
                        ? changePressOrder(val).catch((error) => {
                            return {
                              data: {
                                resultStatus: 'ERROR',
                                amErrors: [
                                  {
                                    code: error?.response?.data?.status,
                                    errorCategory: error?.response?.data?.error ?? 'ERROR',
                                    errorDescription: error?.response?.data?.message ?? 'Unexpected error occurred while changing an order'
                                  }
                                ]
                              }
                            };
                          })
                        : createPressOrder(val).catch((error) => {
                            return {
                              data: {
                                resultStatus: 'ERROR',
                                amErrors: [
                                  {
                                    code: error?.response?.data?.status,
                                    errorCategory: error?.response?.data?.error ?? 'ERROR',
                                    errorDescription: error?.response?.data?.message ?? 'Unexpected error occurred while creating an order'
                                  }
                                ]
                              }
                            };
                          })
                    )
                  )
                    .then((dpRes) => {
                      const dpResponse = [{ data: firstOrderResponse }, ...dpRes];
                      setActiveSpinner(false);
                      resetSearchContext();

                      // For persisting my orders

                      let id: string = '';
                      dpResponse?.map((dp) => (dp?.data?.orderId ? (id += dp?.data?.orderId?.toString()) : ''));

                      const myOrderData: MyOrder = {
                        id: id || '',
                        myOrderNumbers: dpResponse?.map((dp) => dp?.data?.orderId || ''),
                        orderCategory: values?.orderCategory,
                        orderCategoryProductKey: order?.orderCategoryProductKey || orderCategoryProductKey,
                        newspaperNumber: values?.journal?.journalNumber,
                        newspaperTitle: meta?.zkzTitle,
                        zkz: values?.journal?.zkz,
                        creationDate: new Date()?.getTime(),
                        sortingDate: new Date()?.getTime()
                      };

                      if (allOrders?.length === 1) {
                        const found = myOrders
                          ?.map((myOrder) => myOrder?.myOrderNumbers)
                          ?.filter((myOrderArr) => myOrderArr?.length === 1)
                          ?.find((myOrderNums) => (allOrders[0]?.orderId ? myOrderNums?.includes(allOrders[0].orderId) : undefined));
                        if (!found || !myOrders?.length) {
                          updateMyOrders(myOrderData, 'ADD');
                        } else {
                          updateMyOrders(myOrderData, 'UPDATE');
                        }
                      } else {
                        updateMyOrders(myOrderData, 'ADD');
                      }

                      resetOrderContext();
                      setDirty(false);
                      triggerMultipleAlerts(dpResponse);
                      history?.push(AppPaths.orderSearchPath);
                    })
                    .catch(() => {
                      setActiveSpinner(false);
                      addAlert({
                        type: AlertTypes.Error,
                        title: i18n.t('responseErrors.create.title'),
                        description: i18n.t('responseErrors.create.description')
                      });
                    });
                } else {
                  setActiveSpinner(false);
                  firstOrderResponse?.amErrors?.forEach((error: { code?: string; errorCategory?: string; errorDescription?: string }) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory} ${error.code}!`,
                      description: error?.errorDescription?.toString()
                    });
                  });
                }
              } catch (e: any) {
                console.error(e);
                setActiveSpinner(false);
                addAlert({
                  type: AlertTypes.Error,
                  title: i18n.t('responseErrors.create.title'),
                  description:
                    (orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.description') : i18n.t('responseErrors.create.description')) +
                    fetchErrorCode(e?.response)
                });
              } finally {
                nextStep();
              }
            }, console.error)();
          }}
          type="button"
          label={orderMode === OrderMode.CHANGE ? t('buttons.createOrderConfirm') : t('buttons.submitOrder')}
          activeSpinner={activeSpinner}
          style={{ minWidth: '275px' }}
        />
      </footer>
    </>
  );
});
