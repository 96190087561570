import classNames from 'classnames';

import headlines from 'common/styles/Headline.module.css';

import { ProgressBar } from 'order/common/components/ProgressBar/ProgressBar';
import React, { AllHTMLAttributes } from 'react';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useTranslation } from 'react-i18next';
import classes from './header.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OnlineHelp } from '../OnlineHelp/OnlineHelp';
import { OnlineHelpLinks } from '../../dtos/OnlineHelpLink';
import { useOrderContext } from '../../context/order/OrderContext';

export type HeaderProps = AllHTMLAttributes<HTMLDivElement> & {
  activeStep?: number;
  type?: OrderMode;
  productGroup?: string;
  orderCategory?: string;
};

export const Header: React.VFC<HeaderProps> = ({ activeStep, type, productGroup, orderCategory, ...props }) => {
  const { t } = useTranslation('orderCreate');
  const steps = [
    t('steps.productGroup'),
    t('steps.orderContent'),
    productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ? t('steps.orderPosting') : t('steps.payerInfo'),
    t('steps.overview')
  ];
  const stepsForCollection = [t('steps.productGroup'), t('steps.orderContent'), t('steps.orderAssignment'), t('steps.overview')];
  const { orderCategoryProductKey } = useOrderContext();
  const helpLinkOnProduct = (): OnlineHelpLinks => {
    if (activeStep === 0) return OnlineHelpLinks.ORDER_STEP1;
    else return OnlineHelpLinks[(orderCategoryProductKey + '_' + ((activeStep ?? 1) + 1)) as keyof typeof OnlineHelpLinks];
  };
  return (
    <header {...props}>
      <div className={classes.titleHelpLinkContainer}>
        <span />
        <h2 className={classNames(headlines.h4, headlines.center)}>
          {type === OrderMode.CHANGE
            ? t('pageHeading.orderChange')
            : type === OrderMode.CREATE
            ? t('pageHeading.orderCreate')
            : t('pageHeading.orderCopy')}
        </h2>
        <OnlineHelp link={helpLinkOnProduct()} />
      </div>
      <div className={classes.progressSpacer}>
        <ProgressBar
          className={classNames('col-8', 'offset-2')}
          steps={productGroup === ProductGroup.COLLECTION ? stepsForCollection : steps}
          active={activeStep}
          data-testid="status-progress-bar"
        />
      </div>
    </header>
  );
};
