import React from 'react';

import { CellMetaData } from 'common/components/Table/dtos/ColumnConfig';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import { QtyAllocation } from '../../QtyAllocation/QtyAllocation';

interface Props {
  row: AllocatedOrder;
  meta?: CellMetaData;
  readOnly?: boolean;
}

export const CustomRowChildCell = (props: Props): JSX.Element => {
  return <QtyAllocation orderNumber={props.row.data?.orderNumber} />;
};

export const qtyAllocationRenderer = (row: AllocatedOrder, meta?: CellMetaData) => {
  return <CustomRowChildCell row={row} meta={meta} />;
};
