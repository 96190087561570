import { ActiveMultiList } from 'common/components/ActiveMultiList';
import { ProductionFacilityTO } from 'generated';
import { useTranslation } from 'i18n';
import { sortProductionFacilities } from 'order/common/components/ProductionFacilitySelect/sortProductionFacilities';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import React, { useEffect, useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';

export type ProductionFacilitySelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  selectedDate?: Date;
  disabled?: boolean;
  useSLN?: boolean;
  useDepot?: boolean;
};

export const ProductionFacilitySelect: React.VFC<ProductionFacilitySelectProps> = ({ selectedDate, useSLN, useDepot, disabled, ...props }) => {
  const { field, fieldState } = useController(props);
  const { facilities } = useProductionFacilities(selectedDate);
  const { t: tCity } = useTranslation('timeCitySection');

  const productionFacilitiesData = useMemo<ProductionFacilityTO[]>(
    () =>
      facilities
        .filter((pf) => {
          if (pf.catalogId?.startsWith('GrASt')) {
            return true;
          }
          if (useSLN && pf.catalogId?.startsWith('SLN')) {
            return true;
          }
          if (useDepot && pf.catalogId?.startsWith('DEPOT')) {
            return true;
          }
        })
        .sort(sortProductionFacilities),
    [facilities, useDepot, useSLN]
  );

  useEffect(() => {
    if (productionFacilitiesData && productionFacilitiesData.length) {
      const comparedValue = productionFacilitiesData.find(({ catalogId }) => catalogId === field.value);
      field.value && comparedValue === undefined ? field.onChange('') : field.value;
    }
  }, [facilities]);

  return (
    <ActiveMultiList
      name={field.name}
      onChange={(item) => {
        field.onChange(item?.catalogId);
        field.onBlur();
      }}
      onBlur={field.onBlur}
      valueToString={(pf) => `${pf.catalogId?.startsWith('GrASt') ? `${pf.address?.postCode} ${pf.comment}` : `${pf.catalogId} ${pf.address?.city}`}`}
      value={productionFacilitiesData.find(({ catalogId }) => catalogId === field.value)}
      items={productionFacilitiesData}
      placeholder={tCity(`city`)}
      data-testid={'productionFacilityId'}
      disabled={disabled}
      error={fieldState.error ? tCity(`error.invalid`) : undefined}
    />
  );
};
