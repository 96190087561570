export const translationEnglish = {
  orderSearchSuccessTitle: 'Auftrag erfolgreich erstellt!',
  orderSearchSuccessMessage: 'Ihre Auftragsnummer lautet: ',
  cancelOrderTitle: 'Auftrag storniert',
  cancelOrderSuccess: 'Auftrag wurde erfolgreich storniert!',
  cancelOrderError: 'Auftrag konnte nicht storniert werden!',
  error: 'Error',
  warning: 'Warning',
  modalTitle: 'Auftrag stornieren',
  modalContent1: 'Sie sind im Begriff den Auftrag ',
  modalContent2: ' mit der  Auftragsnummer ',
  modalContent3: ' zu stornieren. Diese Aktion kann nicht rückgängig gemacht werden.',
  cancel: 'Abbrechen',
  cancelOrder: 'Auftrag stornieren'
};
