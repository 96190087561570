import React, { useEffect, useState, VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { ObjectContaining } from 'common/utils/formHelpers';
import { CustomerInfo } from 'order/productGroups/common/components/atom/CustomerInfo/CustomerInfo';
import { CustomerInput, CustomerRole } from 'order/productGroups/common/components/atom/CustomerInput';
import { getCustomerInfo } from 'order/productGroups/common/services/getCustomerInfo';
import { OtherPartnerSchema } from './otherPartnerSchema';
import { roleToString } from './PartnerRoles';
import { CustomerInfoWithAddressTO, OrderCustomerRoleEnum } from 'generated';
import classes from './other-partners.module.css';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useCustomerRecommendations } from 'order/productGroups/common/services/useCustomerRecommendations';

export type OtherPartnerProps = {
  prefix: `${string}.${number}`;
  availableRoles: string[];
  data: OtherPartnerSchema;
  disabled?: boolean;
};

export const OtherPartnerInput: VFC<OtherPartnerProps> = ({ prefix, availableRoles, data, disabled }) => {
  const { order } = useOrderContext();
  const { setValue, resetField, control, trigger, clearErrors } = useFormContext<ObjectContaining<OtherPartnerSchema[]>>();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoWithAddressTO | undefined>(undefined);
  const { t } = useTranslation('orderCreate');
  const { add } = useCustomerRecommendations(data.role as unknown as CustomerRole);
  const filteredParty = order?.parties?.filter((p) => p.customerId === data?.customerId && p.role === data.role);
  const transformedCustomerData: CustomerInfoWithAddressTO | undefined = {
    customerId: data?.customerId,
    name: data?.info?.name,
    address: filteredParty?.[0]?.orderAddress
  };
  const isNotBeneficiary = data.role !== OrderCustomerRoleEnum.Beneficiary;
  const customerIdDisabled = disabled && isNotBeneficiary;

  const fetchCustomerInfo = async (customerId?: string) => {
    if (customerId) {
      const { customerInfo: data } = await getCustomerInfo(customerId);
      if (data) {
        setCustomerInfo(data);
      } else if (transformedCustomerData) {
        setCustomerInfo(transformedCustomerData);
      }
    }
  };

  useEffect(() => {
    fetchCustomerInfo(data.customerId);
  }, [data.customerId]);

  useEffect(() => {
    if (customerInfo?.name && customerInfo?.customerId) {
      add({
        customerId: customerInfo.customerId,
        name: customerInfo.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  return (
    <FormRow mode="two-col" width="66%">
      <div>
        <Controller
          control={control}
          name={`${prefix}.role`}
          render={({ field: { ref, ...field }, fieldState }) => (
            <AMDHLSelectSingle<string>
              {...field}
              placeholder="PARTNERROLLE"
              items={['', ...availableRoles]}
              valueToString={(v) => roleToString(v as unknown as OrderCustomerRoleEnum)}
              data-testid={field.name}
              disabled={disabled}
              error={fieldState.error ? t('errors.partnerRole') : undefined}
              onChange={(item) => {
                field.onChange(item);
                trigger(`${prefix}.role`);
                if (!item) {
                  clearErrors(`${prefix}.customerId`);
                }
              }}
            />
          )}
        />
        {data.customerId && <CustomerInfo customerId={data.customerId} className={classes.info} />}
      </div>
      <CustomerInput
        name={`${prefix}.customerId`}
        onDelete={() => {
          resetField(`${prefix}.customerId`);
          resetField(`${prefix}.info`);
        }}
        placeholder={`${t('pressDistribution.zahlungspflichtiger.placeholder')}${data.role ? '*' : ''}`}
        onSelect={(customerId) => setValue(`${prefix}.customerId`, customerId)}
        customerRole={data.role as unknown as CustomerRole}
        disabled={customerIdDisabled}
        rules={{
          required: !!data.role
        }}
        errorMessage={t('error.customerIdUnknown')}
      />
    </FormRow>
  );
};
