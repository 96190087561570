export enum OrderMode {
  CREATE = 'OrderCreate',
  CHANGE = 'OrderChange',
  SEARCH = 'OrderSearch',
  VIEW = 'OrderView',
  COPY = 'OrderCopy'
}

export enum WhitelistMethod {
  CHANGE = 'change',
  COPY = 'copy',
  CREATE = 'create',
  DELETE = 'delete',
  GET = 'get',
  PRINT = 'print',
  PALLETSMOVE = 'palletMove',
  SPLIT = 'split',
  MERGE = 'merge',
  INVOICE = 'getInvoice'
}
