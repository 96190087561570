import { Age, AllocatedOrderData } from '../dtos/AllocatedOrder';
import { OrderResponse } from '../dtos/GetOrder';
import { determineFlexOption, determineFlexOptionStd } from './determineFlexOption';
import { getPayerProcedureOrParticipationValue } from './getPayerProcedureOrParticipationValue';
import { Occurrence } from 'common/dtos/Occurrence';
import { OrderCustomerRole } from '../dtos/OrderCustomerRole';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';

export function createAllocationOrderData(order: OrderResponse, date: Date | string): Partial<AllocatedOrderData> {
  return {
    ...order,
    orderNumber: order?.orderId,
    containsIds: order?.includesDestinations ? Occurrence.ALL : Occurrence.NONE,
    flexOption: isAvailableFromJan25(new Date(date))
      ? determineFlexOptionStd(order?.otherAttributes || {})
        ? Occurrence.NONE
        : determineFlexOption(order?.otherAttributes || {}) || order.includesDestinations
        ? Occurrence.ALL
        : Occurrence.NONE
      : determineFlexOption(order?.otherAttributes || {})
      ? Occurrence.ALL
      : Occurrence.NONE,
    firstInductionDate: order?.orderDetail?.date || '',
    payerParticipation: getPayerProcedureOrParticipationValue(order?.parties || []),
    payerProcedure: getPayerProcedureOrParticipationValue(order?.parties || [], true),
    frankierung: order?.postage?.type || '',
    frankingIdPrefix: order?.postage?.machineCode || order?.postage?.frankingIdPrefix || undefined,
    machineCode: order?.postage?.machineCode,
    includeTotalQty: Occurrence.ALL,
    originator: order?.parties?.find((p) => p.role === OrderCustomerRole.Originator),
    paymentClearingNumber: order?.postage?.paymentClearingNumber || undefined,
    age: Age.NEW
  };
}
