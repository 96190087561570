// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PAProductInfoSection_tableContainer__QQpsy {
  max-width: 75%;
}
.PAProductInfoSection_tableContainer__QQpsy thead th p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Overview/PAProductInfoSection/PAProductInfoSection.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".tableContainer {\n  max-width: 75%;\n}\n.tableContainer thead th p {\n  font-size: 14px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `PAProductInfoSection_tableContainer__QQpsy`
};
export default ___CSS_LOADER_EXPORT___;
