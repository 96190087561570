// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-row_formGroup__Cog5l {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 15px;
}

.form-row_formGroup__Cog5l.form-row_checkboxes__bnn5l {
  display: flex;
}

.form-row_formGroup__Cog5l.form-row_checkboxes__bnn5l > * {
  flex-grow: 0;
}

.form-row_formGroup__Cog5l.form-row_radiobuttons__6kayc {
  display: flex;
}

.form-row_formGroup__Cog5l.form-row_radiobuttons__6kayc > * {
  flex-grow: 0;
}

.form-row_formGroup__Cog5l.form-row_twoCol__v2KGt {
  grid-template-columns: minmax(0, 1fr) 1fr;
}
.form-row_formGroup__Cog5l.form-row_twoColMinWidth__Colig {
  grid-template-columns: minmax(0, 1fr) 1fr;
}
.form-row_formGroup__Cog5l.form-row_twoColLastMinWidth__WT8DH {
  grid-template-columns: 1fr minmax(0, 1fr);
}

.form-row_formGroup__Cog5l.form-row_threeCol__1dnDP {
  grid-template-columns: 1fr 1fr 1fr;
}

.form-row_formGroup__Cog5l.form-row_oneCol__O168L {
  grid-template-columns: 1fr;
}

.form-row_formGroup__Cog5l.form-row_nCol__WJgPB {
  display: flex;
}

.form-row_formGroup__Cog5l.form-row_nCol__WJgPB > * {
  flex-grow: 1;
  flex-basis: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/FormRow/form-row.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAS;EAAT,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".formGroup {\n  display: grid;\n  gap: 20px;\n  margin-bottom: 15px;\n}\n\n.formGroup.checkboxes {\n  display: flex;\n}\n\n.formGroup.checkboxes > * {\n  flex-grow: 0;\n}\n\n.formGroup.radiobuttons {\n  display: flex;\n}\n\n.formGroup.radiobuttons > * {\n  flex-grow: 0;\n}\n\n.formGroup.twoCol {\n  grid-template-columns: minmax(0, 1fr) 1fr;\n}\n.formGroup.twoColMinWidth {\n  grid-template-columns: minmax(0, 1fr) 1fr;\n}\n.formGroup.twoColLastMinWidth {\n  grid-template-columns: 1fr minmax(0, 1fr);\n}\n\n.formGroup.threeCol {\n  grid-template-columns: 1fr 1fr 1fr;\n}\n\n.formGroup.oneCol {\n  grid-template-columns: 1fr;\n}\n\n.formGroup.nCol {\n  display: flex;\n}\n\n.formGroup.nCol > * {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formGroup": `form-row_formGroup__Cog5l`,
	"checkboxes": `form-row_checkboxes__bnn5l`,
	"radiobuttons": `form-row_radiobuttons__6kayc`,
	"twoCol": `form-row_twoCol__v2KGt`,
	"twoColMinWidth": `form-row_twoColMinWidth__Colig`,
	"twoColLastMinWidth": `form-row_twoColLastMinWidth__WT8DH`,
	"threeCol": `form-row_threeCol__1dnDP`,
	"oneCol": `form-row_oneCol__O168L`,
	"nCol": `form-row_nCol__WJgPB`
};
export default ___CSS_LOADER_EXPORT___;
