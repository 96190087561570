// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-group_selectGroup__dQOVM {
  display: flex;
  gap: 20px;
}

.select-group_selectGroup__dQOVM > * {
  flex-grow: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/SelectGroup/select-group.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".selectGroup {\n  display: flex;\n  gap: 20px;\n}\n\n.selectGroup > * {\n  flex-grow: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectGroup": `select-group_selectGroup__dQOVM`
};
export default ___CSS_LOADER_EXPORT___;
