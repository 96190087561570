import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Headline, Label } from '../../../ContextMenu';
import { SortOrder } from '../../dtos/SortOrder';
import { ColumnType } from '../../dtos/ColumnType';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { Sort } from '../../dtos/Sort';
import classes from './table-column-menu.module.css';

declare type HeadMenuProps = {
  propertiesName: string;
  t: (value: string) => string;
  hidemenu?: () => void;
  inputClear?: () => void;
  onResetSortAndFilters?: () => void;
};

declare type SortMenuProps = HeadMenuProps & {
  type: ColumnType;
  hidemenu?: () => void;
  name: string;
  sortOrder?: SortOrder;
  sortedName?: string;
  searchType?: OrderSearchType;
  onSortByName?: (name: string, order: SortOrder, type: ColumnType, searchType?: OrderSearchType) => void;
  setColumnSorting?: (sort: Sort) => void;
};

export const SortMenu = (props: SortMenuProps) => {
  const [sortedDesc, setSortedDesc] = useState(false);
  const [sortedAsc, setSortedAsc] = useState(false);

  useEffect(() => {
    setSortedAsc(props.name === props.sortedName && props.sortOrder === SortOrder.ASC);
    setSortedDesc(props.name === props.sortedName && props.sortOrder === SortOrder.DESC);
  }, [props.sortOrder, props.name]);

  const sort = (order: SortOrder) => {
    const asc = order === SortOrder.ASC;
    if ((asc && sortedAsc) || (!asc && sortedDesc)) {
      props.onResetSortAndFilters?.();
    } else {
      props.setColumnSorting?.({
        name: props.name,
        order: order,
        type: props.type === ColumnType.DATE ? ColumnType.TEXT : props.type,
        searchType: props.searchType
      });
      props.onSortByName?.(props.name, order, props.type === ColumnType.DATE ? ColumnType.TEXT : props.type, props.searchType);
    }
  };

  return (
    <>
      <Headline>{props.t('contextmenu.sortHeading')}</Headline>
      <Label
        checked={sortedAsc}
        onClick={(e) => {
          e.stopPropagation();
          sort(SortOrder.ASC);
          props.hidemenu?.();
        }}
        data-testid={'table-menu-sort-asc'}
      >
        {props.t('contextmenu.sortAscending')}
      </Label>
      <Label
        checked={sortedDesc}
        onClick={(e) => {
          e.stopPropagation();
          sort(SortOrder.DESC);
          props.hidemenu?.();
        }}
        data-testid={'table-menu-sort-desc'}
      >
        {props.t('contextmenu.sortDescending')}
      </Label>
    </>
  );
};

declare type MoveMenuProps = HeadMenuProps & {
  name: string;
  hidemenu?: () => void;
  onMove?: (name: string, direction: 'left' | 'right') => void;
  saveColumnWithMove?: () => void;
  visibleColumn?: any[];
};

export const MoveMenu = ({ name, onMove, t, hidemenu, saveColumnWithMove, visibleColumn }: MoveMenuProps) => {
  const diableLeftMove = name ? visibleColumn?.[0]?.name === name : false;
  const diableRightMove = name ? visibleColumn?.slice(-1)[0]?.name === name : false;
  return (
    <>
      <Label
        checked={false}
        onClick={(e) => {
          e.stopPropagation();
          hidemenu?.();
          onMove?.(name, 'left');
          saveColumnWithMove?.();
        }}
        data-testid={'table-menu-move-left'}
        className={classNames(diableLeftMove ? classes.hoverOnDisable : '')}
        disabled={diableLeftMove}
      >
        {t('contextmenu.moveLeft')}
      </Label>
      <Label
        checked={false}
        onClick={(e) => {
          e.stopPropagation();
          hidemenu?.();
          onMove?.(name, 'right');
          saveColumnWithMove?.();
        }}
        data-testid={'table-menu-move-right'}
        className={classNames('dhlContextmenuMoveRight', diableRightMove ? classes.hoverOnDisable : '')}
        disabled={diableRightMove}
      >
        {t('contextmenu.moveRight')}
      </Label>
    </>
  );
};

export const HideMenu = ({ hidemenu, inputClear, onResetSortAndFilters, t }: HeadMenuProps) => (
  <Label
    checked={false}
    onClick={(e) => {
      e.stopPropagation();
      onResetSortAndFilters?.();
      hidemenu?.();
      inputClear?.();
      /*
      const index = dataStore.findHeader(propertiesName);
      if (index < 0) {
        return;
      }
      const props = dataStore.headers[index];
      props.hidden = !props.hidden;
       */
    }}
    data-testid={'table-menu-hide'}
  >
    {t('contextmenu.hide')}
  </Label>
);
