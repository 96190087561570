export enum ProductGroup {
  DIALOGPOST = 'DIALOGPOST',
  PRESS_DISTRIBUTION = 'PRESS_DISTRIBUTION',
  POSTWURF_SPEZIAL = 'POSTWURF SPEZIAL',
  PARTIAL_SERVICE_LETTER = 'PARTIAL',
  TEILLEISTUNG = 'TEILLEISTUNG',
  COLLECTION = 'COLLECTION',
  LETTER = 'LETTER',
  BRIEF = 'BRIEF',
  ABHOLAUFTRAG = 'ABHOLAUFTRAG',
  DIALOGPOSTWURFSPEZIAL = 'DIALOGPOSTWURFSPEZIAL',
  INFOPOST_DHL = 'INFOPOST_DHL',
  POSTAKTUELL = 'POSTAKTUELL',
  HYBRID_BRIEF = 'HYBRID_BRIEF',
  BRIEF_INTERNATIONAL = 'BRIEF_INTERNATIONAL',
  DIALOGPOST_INTERNATIONAL = 'IP_INTERNATIONAL',
  BRIEF_DMC_INTERNATIONAL = 'BRIEF_DMC_INTERNATIONAL',
  PRESS_INTERNATIONAL = 'PUB_INTERNATIONAL'
}
