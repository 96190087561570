import { translate } from './translateCsv';

export const csvCols = {
  orderNumber: translate('orderNumber'), // Auftragsnummer
  customerOrderId: translate('customerOrderId'), // Kundenreferenz
  orderLabel: translate('orderLabel'), // Auftragsbezeichnung
  'originator.customerId': translate('originatorCustomerId'), // Absender EKP-Nr.
  'originator.name': translate('originatorName'), // Absender Name
  'submitter.customerId': translate('submitterCustomerId'), // Einlieferer EKP-Nr.
  'submitter.name': translate('submitterName'), // Einlieferer Name
  'payer.customerId': translate('payerCustomerId'), // Zahlungspflichtiger EKP-Nr.
  'payer.name': translate('payerName'), // Zahlungspflichtiger Name
  'producer.customerId': translate('producerCustomerId'), // Hersteller EKP-Nr.
  'producer.name': translate('producerName'), // Hersteller Name
  'beneficiary.customerId': translate('beneficiaryCustomerId'), // Berechtigter EKP-Nr.
  'beneficiary.name': translate('beneficiaryName'), // Berechtigter Name
  settlementNumber: translate('settlementNumber'), // Abrechnungsnummer
  procedure: translate('procedure'), // Verfahren
  participation: translate('participation'), // Teilnahme
  paymentClearingNumber: translate('paymentClearingNumber'), // Einlieferungsbelegsnummer
  zkz: translate('zkz'), // ZKZ
  newspaperNumber: translate('newspaperNumber'), // Zeitungstitel
  //  issueFrom: translate('issueFrom'), // HeftNr. von
  //  issueYearFrom: translate('issueYearFrom'), // Heftfolge Jahr von
  //  issueTo: translate('issueTo'), // HeftNr. bis
  //  issueYearTo: translate('issueYearTo'), // Heftfolge Jahr bis
  product: translate('product'), // Produkt
  totalQty: translate('totalQty'), // Sendungsmenge
  //  palletQty: translate('palletQty'), // Anzahl Paletten
  frankierung: translate('frankierung'), // Frankierung
  totalAmountValue: translate('totalAmountValue'), // Auftragswert netto (EUR)
  firstInductionDate: translate('creationDate'), // Einlieferungstag(e)
  state: translate('state'), // Status
  jobGroup: translate('jobGroup'), // Jobgruppe
  partialGroup: translate('partialGroup') // Teileinlieferungsgruppe
};
