import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './myOrders.module.css';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { getFormattedDateTime } from 'common/utils/dates';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderSearchAttribute } from 'order/common/context/search/dtos/OrderSearchAttribute';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { ActivityIndicator } from 'order/common/components/ActivityIndicator/ActivityIndicator';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';

export interface MyOrder {
  id: string;
  orderCategory?: string;
  orderCategoryProductKey?: OrderCategoryProductKey | string;
  orderLabel?: string;
  zkz?: string;
  newspaperTitle?: string;
  newspaperNumber?: string;
  myOrderNumbers: string[];
  creationDate?: number;
  sortingDate: number;
}

export const MyOrders = (): JSX.Element => {
  const maxLengthOfMyOrders = 40;
  const { t } = useTranslation('', { keyPrefix: 'orderSearch.myOrders' });
  const { t: tocpk } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });

  const { myOrders, updateMyOrders, triggerSearchWithAttributes, myOrdersLoaded } = useSearchContext();

  const searchHandler = (item: MyOrder) => {
    const searchAttributes: OrderSearchAttribute[] = item?.myOrderNumbers?.map((orderId) => {
      return { key: OrderSearchKey.OrderId, value: orderId };
    });
    triggerSearchWithAttributes(
      item?.orderCategoryProductKey == OrderCategoryProductKey.PRESSDISTRIBUTION ? OrderSearchType.PRESS : OrderSearchType.SIMPLE,
      searchAttributes
    );
  };

  if (!myOrdersLoaded) {
    return (
      <div style={{ background: '#f5f5f5' }}>
        <ActivityIndicator testId={'myOrders'} message={''} />
      </div>
    );
  }

  return (
    <div className={styles.myOrders}>
      <div className={styles.savedHeaderContainer}>
        <h5>{`${t('createdOrders')}`}</h5>
        <span className={styles.subMessage}>{` (${myOrders?.length} ${t('result.of')} ${maxLengthOfMyOrders})`}</span>
      </div>
      <div>
        {myOrders?.length ? (
          myOrders
            ?.sort((a, b) => (a?.sortingDate && b?.sortingDate ? b.sortingDate - a.sortingDate : 0))
            .map((item) => {
              return (
                <div className={styles.myOrderCard} key={item?.id} data-testid={`myOrderCard-${item?.id}`}>
                  <div style={{ maxWidth: '75%' }}>
                    <h6>
                      <span style={{ textTransform: 'capitalize' }}>
                        {item?.orderCategoryProductKey ? `${tocpk(item?.orderCategoryProductKey)}` : ''}
                      </span>
                      {item?.orderCategoryProductKey === OrderCategoryProductKey.PRESSDISTRIBUTION
                        ? `, ZKZ ${item?.zkz ?? ''}, ${item?.newspaperTitle ?? ''}, ${t('newspaperNumber')} ${item?.newspaperNumber ?? ''}`
                        : item?.orderLabel && `, ${item?.orderLabel}`}
                    </h6>
                    <div className={styles.orderNumbers}>
                      {item?.myOrderNumbers?.map((orderId) => {
                        return (
                          <p className={styles.chip} key={orderId}>
                            {`${t('orderNumber')} ${orderId}`}
                          </p>
                        );
                      })}
                    </div>
                    <p className={styles.date}>{item?.creationDate && `${t('createdOn')} ${getFormattedDateTime(new Date(item?.creationDate))}`}</p>
                  </div>
                  <div>
                    <DHLButton
                      type={'primary'}
                      name={`searchIconButton-${item?.id}`}
                      size={'normal'}
                      label={t('search')}
                      onClick={() => searchHandler(item)}
                    />
                    <DHLButton
                      name={'deleteIconButton-' + item?.id}
                      label={'deleteIconButton'}
                      iconPosition={'icon'}
                      icon={'delete'}
                      size={'normal'}
                      tooltip={t('deleteToolTip')}
                      onClick={() => updateMyOrders(item, 'DELETE')}
                    />
                  </div>
                </div>
              );
            })
        ) : (
          <div className={styles.emptyList}>{t('emptyList')}</div>
        )}
      </div>
    </div>
  );
};
