// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-overview_row__5N6Y\\+ {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
  grid-template-columns: 1fr 1fr 1fr;
}

.order-overview_noMargin__k0vDI {
  margin-top: 0 !important;
}

.order-overview_row__5N6Y\\+:last-child {
  border-bottom: none;
}

.order-overview_widget__YaV9b p {
  margin-bottom: 5px;
}

.order-overview_widget__YaV9b table {
  width: 100%;
  table-layout: fixed;
}

.order-overview_widget__YaV9b td {
  vertical-align: top;
}

.order-overview_widgetOne__a6dz8 {
  grid-column: span 1;
}

.order-overview_widgetTwo__w8Jeh {
  grid-column: span 2;
}

.order-overview_widgetThree__eRycW {
  grid-column: span 3;
}

.order-overview_title__9apaJ {
  margin-bottom: 15px;
  color: #323232;
  font-weight: 600;
  font-style: normal;
  font-family: Delivery, Verdana, sans-serif;
  line-height: 1.125;
}
`, "",{"version":3,"sources":["webpack://./src/order/productGroups/common/components/molecule/OrderOverview/order-overview.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAS;EAAT,SAAS;EACT,eAAe;EACf,gCAAgC;EAChC,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":[".row {\n  display: grid;\n  gap: 20px;\n  padding: 20px 0;\n  border-bottom: 1px solid #e6e6e6;\n  grid-template-columns: 1fr 1fr 1fr;\n}\n\n.noMargin {\n  margin-top: 0 !important;\n}\n\n.row:last-child {\n  border-bottom: none;\n}\n\n.widget p {\n  margin-bottom: 5px;\n}\n\n.widget table {\n  width: 100%;\n  table-layout: fixed;\n}\n\n.widget td {\n  vertical-align: top;\n}\n\n.widgetOne {\n  grid-column: span 1;\n}\n\n.widgetTwo {\n  grid-column: span 2;\n}\n\n.widgetThree {\n  grid-column: span 3;\n}\n\n.title {\n  margin-bottom: 15px;\n  color: #323232;\n  font-weight: 600;\n  font-style: normal;\n  font-family: Delivery, Verdana, sans-serif;\n  line-height: 1.125;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `order-overview_row__5N6Y+`,
	"noMargin": `order-overview_noMargin__k0vDI`,
	"widget": `order-overview_widget__YaV9b`,
	"widgetOne": `order-overview_widgetOne__a6dz8`,
	"widgetTwo": `order-overview_widgetTwo__w8Jeh`,
	"widgetThree": `order-overview_widgetThree__eRycW`,
	"title": `order-overview_title__9apaJ`
};
export default ___CSS_LOADER_EXPORT___;
