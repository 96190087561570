import { OrderResponse } from '../dtos/GetOrder';
import { OrderReference } from '../dtos/OrderReference';
import { determineFlexOption } from './determineFlexOption';
import { QtyAllocation, sortFormatList } from '../dtos/Order';
import { qtySumCalculator } from './qtySumCalculator';
import { preProcessExistingQtyAllocations } from './preProcessExistingQtyAllocations';
import { Occurrence } from 'common/dtos/Occurrence';
import { DeliveryOption } from 'common/dtos/DeliveryOption';

export function createOrderReferenceFromExisting(
  origOrder: OrderResponse, // original order qty allocation (ZA) (initially loaded)
  referencedOrder: OrderResponse // referenced order included in the orig order (EA) (dependent-loaded)
): { orderReference: OrderReference; qtyAlloc: QtyAllocation[]; totalQty: number; initialTotalQty: number } {
  const origOrderReference = origOrder?.orderReferences.find((r) => r.orderId === referencedOrder.orderId);
  let qtyAlloc: QtyAllocation[] = preProcessExistingQtyAllocations(
    origOrderReference?.qtyAllocation || [],
    referencedOrder.qtyAllocation || [],
    origOrder?.productGroup
  );
  const totalQty = qtySumCalculator(qtyAlloc, 'quantity') as number;
  const totalIdsQty = qtySumCalculator(qtyAlloc, 'initialIdsQty') as number;
  const initialTotalQty = qtySumCalculator(qtyAlloc || [], 'initialQty') as number;
  qtyAlloc = qtyAlloc.sort((a, b) => sortFormatList.indexOf(a.format) - sortFormatList.indexOf(b.format));

  const orderReference: OrderReference = {
    ...origOrderReference,
    orderId: referencedOrder?.orderId || '',
    frankingIdPrefix: referencedOrder?.postage?.machineCode || referencedOrder?.postage?.frankingIdPrefix || undefined,
    machineCode: referencedOrder?.postage?.machineCode,
    postageFrankingIdPrefix: referencedOrder?.postage?.frankingIdPrefix,
    includeTotalQty: initialTotalQty === totalQty ? Occurrence.ALL : initialTotalQty > totalQty ? Occurrence.SOME : Occurrence.NONE,
    includesDestinations: referencedOrder?.includesDestinations,
    // totalQty === totalIdsQty ? Occurrence.ALL : totalIdsQty === 0 ? Occurrence.NONE : totalQty > totalIdsQty ? Occurrence.SOME : Occurrence.NONE,
    containsIds: origOrderReference?.containsIds || Occurrence.NONE,
    flexOption: origOrderReference?.flexOption || Occurrence.NONE,
    totalQty,
    allocatedQty: totalQty,
    idsQty: totalIdsQty,
    qtyAllocation: qtyAlloc,
    initialTotalQty,
    pendingIds: origOrder.pendingIds,
    selectedDeliveryOption: determineFlexOption(referencedOrder?.otherAttributes || {}) ? DeliveryOption.FLEX : DeliveryOption.STANDARD,
    new: false
  };

  return { orderReference, qtyAlloc, totalQty, initialTotalQty };
}
