export enum UserRights {
  ProductDiPPWSp = 'R_ProductDiPPWSp',
  ProductPrD = 'R_ProductPrD',
  OrderEdit = 'R_OrderEdit',
  PalletLabelPrint = 'R_PalletLabelPrint',
  OrderExport = 'R_OrderExport',
  OrderPrint = 'R_OrderPrint',
  OrderSearch = 'R_OrderSearch',
  OrderShow = 'R_OrderShow',
  OrderCopy = 'R_OrderCopy',
  NetPayroll = 'R_NettoEntgeltAbrechnung',
  ProductTL = 'R_ProductTL',
  OrderAccessLetter = 'R_AuftragsZugriffBrief',
  OrderSplit = 'R_OrderSplit',
  OrderMerge = 'R_OrderMerge',
  PalletsMove = 'R_OrderPalletMove',
  InvoiceCenter = 'R_InvoiceCenter',
  Personal = 'A_Personal',
  Impersonated = 'A_Impersonated',
  PersonalAdmin = 'A_PersonalAdmin'
}

export const defaultUserRights = [];
