import { getUser } from 'common/context/auth/AuthProvider';
import { sendRequest } from 'common/api-adapter';
import { BASE_URL } from 'common/utils/apiUtils';
import { ProductTO } from '../../../generated';

export const requestSpecialLetterProducts = async (productNumber?: string, validityPoint?: string): Promise<ProductTO[]> => {
  const user = getUser();
  try {
    const { data } = await sendRequest({
      method: 'get',
      url: `${BASE_URL}masterdata/special-letter-products`,
      params: {
        productNumber,
        validityPoint
      },
      token: user._accessToken,
      extUserId: user.extUserId
    });

    if (data) {
      return data;
    }

    return [];
  } catch (e: any) {
    console.error('Fetching special letter products failed', e);
    return [];
  }
};
