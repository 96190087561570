import { useState } from 'react';
import { crc32 } from 'crc';
import dompurify from 'dompurify';

import { AuthUser } from 'common/dtos/auth';
import { BASE_URL } from 'common/utils/apiUtils';
import { loadFromSessionStorage, saveInSessionStorage } from 'common/storage/storageService';
import { sendRequest } from 'common/api-adapter';
import { OrderSearchResultRepTDO } from '../../../services/OrderService';
import { OrderSearchType } from '../dtos/OrderSearchType';
import { HistoryItem } from '../dtos/HistoryItem';
import { SearchState } from '../dtos/SearchState';
import { getUser } from '../../../../../common/context/auth/AuthProvider';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';

const SETTINGS_NONCE = 'pw6e3zvfOYPCbDBn';

interface PersistSearchParams {
  currentType: OrderSearchType;
  currentSearchId: string | undefined;
  searchHistory: HistoryItem[];
  orders?: OrderSearchResultRepTDO;
}

export interface PersistedSearch {
  currentType: OrderSearchType;
  currentSearchId: string | undefined;
  searchHistory: HistoryItem[];
}
export interface PersistedCurrentSearches {
  currentType: OrderSearchType;
  currentSearches: SearchState[];
}
export interface PersistColumn {
  currentType?: OrderSearchType;
  columnConfig: ColumnConfig[];
}

export const useSearchPersistence = () => {
  const [persistedSearchHistory, setPersistedSearchHistory] = useState<PersistedSearch | undefined>(undefined);
  const [persistedMyOrders, setPersistedMyOrders] = useState<MyOrder[]>([]);
  const [persistedColumnConfig, setPersistedColumnConfig] = useState<PersistColumn[] | []>([]);

  const persistSearchHistory = async (data: PersistSearchParams): Promise<void> => {
    const persistedSearch: PersistedSearch = {
      currentSearchId: data.currentSearchId,
      currentType: data.currentType,
      searchHistory: data.searchHistory
    };
    const securedData = {
      payload: persistedSearch,
      crc: crc32(SETTINGS_NONCE + JSON.stringify(persistedSearch)).toString(16)
    };
    const encodedSearch = btoa(JSON.stringify(securedData));
    const user = getUser();

    try {
      saveInSessionStorage(`AM-search-${user.userName}`, encodedSearch);

      await sendRequest({
        url: `${BASE_URL}settings/search`,
        method: 'put',
        token: user._accessToken,
        extUserId: user.extUserId,
        data: {
          payload: encodedSearch
        }
      });
      setPersistedSearchHistory(persistedSearch);
    } catch (e) {
      console.error('Persisting search failed: ', e);
    }
  };

  const persistMyOrders = async (data: MyOrder[]): Promise<void> => {
    const securedData = {
      payload: data,
      crc: crc32(SETTINGS_NONCE + JSON.stringify(data)).toString(16)
    };
    const encodedSearch = btoa(JSON.stringify(securedData));
    const user = getUser();

    try {
      // saveInSessionStorage(`AM-search-${user.userName}`, encodedSearch);
      await sendRequest({
        url: `${BASE_URL}settings/myOrders`,
        method: 'put',
        token: user._accessToken,
        extUserId: user.extUserId,
        data: {
          payload: encodedSearch
        }
      });
      setPersistedMyOrders(data);
    } catch (e) {
      console.error('Persisting my orders failed: ', e);
    }
  };

  const getPersistedMyOrdersFromServer = async (): Promise<MyOrder[] | undefined> => {
    try {
      const user = getUser();
      const { data } = await sendRequest({
        url: `${BASE_URL}settings/myOrders`,
        method: 'get',
        token: user._accessToken,
        extUserId: user.extUserId
      });
      if (data && data.payload) {
        const securedData = JSON.parse(dompurify.sanitize(atob(data.payload)));
        if (securedData && securedData.crc && securedData.payload) {
          const crcStr = crc32(SETTINGS_NONCE + JSON.stringify(securedData.payload)).toString(16);
          if (crcStr === securedData.crc) {
            setPersistedMyOrders(securedData.payload);
            return securedData.payload;
          } else {
            console.error('Corrupted: my orders list');
          }
        }
      }
    } catch (e) {
      console.error('Requesting my orders settings failed: ', e);

      return undefined;
    }
  };

  const getPersistedSearchFromServer = async (): Promise<PersistedSearch | undefined> => {
    try {
      const user = getUser();
      const { data } = await sendRequest({
        url: `${BASE_URL}settings/search`,
        method: 'get',
        token: user._accessToken,
        extUserId: user.extUserId
      });
      if (data && data.payload) {
        const securedData = JSON.parse(dompurify.sanitize(atob(data.payload)));
        if (securedData && securedData.crc && securedData.payload) {
          const crcStr = crc32(SETTINGS_NONCE + JSON.stringify(securedData.payload)).toString(16);
          if (crcStr === securedData.crc) {
            setPersistedSearchHistory(securedData.payload);
            return securedData.payload;
          } else {
            console.error('Corrupt search settings.');
          }
        }
      }
    } catch (e) {
      console.error('Requesting persisted search history settings failed: ', e);

      return undefined;
    }
  };

  const persistCurrentSearches = async (user: AuthUser, data: PersistedCurrentSearches): Promise<void> => {
    const encodedSearch = btoa(JSON.stringify(data));

    try {
      saveInSessionStorage(`AM-search-${user.userName}`, encodedSearch);
    } catch (e) {
      console.error('Persisting current searches failed: ', e);
    }
  };

  const getPersistedCurrentSearches = async (user: AuthUser): Promise<PersistedCurrentSearches | undefined> => {
    return new Promise((r) => {
      r(loadFromSessionStorage(`AM-search-${user.userName}`));
    });
  };

  const persistColumnConfig = async (data: PersistColumn[]): Promise<void> => {
    const securedData = {
      payload: data,
      crc: crc32(SETTINGS_NONCE + JSON.stringify(data)).toString(16)
    };
    const encodedSearch = btoa(JSON.stringify(securedData));
    const user = getUser();

    try {
      saveInSessionStorage(`AM-search-${user.userName}`, encodedSearch);

      await sendRequest({
        url: `${BASE_URL}settings/columnConfig`,
        method: 'put',
        token: user._accessToken,
        extUserId: user.extUserId,
        data: {
          payload: encodedSearch
        }
      });
      // setPersistedColumnConfig((prevState) => [...prevState, ...data]);
      setPersistedColumnConfig(data);
    } catch (e) {
      console.error('Persisting search failed: ', e);
    }
  };

  const getPersistedColumnConfigFromServer = async (): Promise<PersistColumn[] | undefined> => {
    try {
      const user = getUser();
      const { data } = await sendRequest({
        url: `${BASE_URL}settings/columnConfig`,
        method: 'get',
        token: user._accessToken,
        extUserId: user.extUserId
      });
      if (data && data.payload) {
        const securedData = JSON.parse(dompurify.sanitize(atob(data.payload)));
        if (securedData && securedData.crc && securedData.payload) {
          const crcStr = crc32(SETTINGS_NONCE + JSON.stringify(securedData.payload)).toString(16);
          if (crcStr === securedData.crc) {
            setPersistedColumnConfig(securedData.payload);
            return securedData.payload;
          } else {
            console.error('Corrupt columnConfig settings.');
          }
        }
      }
    } catch (e) {
      console.error('Requesting persisted columnConfig history settings failed: ', e);

      return undefined;
    }
  };

  return {
    getPersistedSearchFromServer,
    getPersistedCurrentSearches,
    persistSearchHistory,
    persistMyOrders,
    persistCurrentSearches,
    persistedSearchHistory,
    persistedMyOrders,
    persistColumnConfig,
    persistedColumnConfig,
    getPersistedColumnConfigFromServer,
    getPersistedMyOrdersFromServer
  };
};
