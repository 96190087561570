import { GetOrderRep, ProductionFacilityTO } from '../../../../generated';

export const getDeliveryPoint = (
  productionPlantId?: string,
  validProductionFacilities?: ProductionFacilityTO[]
): ProductionFacilityTO | undefined => {
  if (productionPlantId) {
    return validProductionFacilities?.find((facility) => facility.catalogId && facility.catalogId === productionPlantId);
  }

  return undefined;
};

export const getPickupDepot = (order?: GetOrderRep, validProductionFacilities?: ProductionFacilityTO[]): ProductionFacilityTO | undefined => {
  if (order?.orderDetail?.date) {
    const orderDate = new Date(order.orderDetail.date);
    if (order?.pickupAddress?.orderAddress?.country === 'DE') {
      const pickupRegion = order?.pickupAddress?.orderAddress?.postCode?.slice(0, 1)
        ? +order?.pickupAddress?.orderAddress?.postCode?.slice(0, 2)
        : undefined;
      const pickupDepotRegex = /^([0-9]{1,2})-([0-9]{1,2})$/;
      if (order?.orderDetail?.pickup && pickupRegion && orderDate && validProductionFacilities) {
        return validProductionFacilities.find((facility) => {
          if (facility.catalogId && facility.catalogId?.startsWith('DEPOT') && facility.regions) {
            if (orderDate >= new Date(facility?.validFrom || '') && orderDate <= new Date(facility?.validTo || '')) {
              return facility.regions.some((region) => {
                const result = region.match(pickupDepotRegex);
                if (result && result.length === 3) {
                  if (+result[1] <= pickupRegion && pickupRegion <= +result[2]) {
                    return true;
                  }
                }
                return false;
              });
            }
            return false;
          }
          return false;
        });
      }
    } else if (
      order?.pickupAddress?.orderAddress?.country &&
      ['AT', 'BE', 'CH', 'CZ', 'DK', 'FR', 'LU', 'NL', 'PL'].includes(order?.pickupAddress?.orderAddress?.country)
    ) {
      const pickUpCountry = order?.pickupAddress?.orderAddress?.country;
      if (order?.orderDetail?.pickup && validProductionFacilities && orderDate) {
        return validProductionFacilities.find((facility) => {
          if (facility.catalogId && facility.catalogId?.startsWith('DEPOT') && facility.internationalPickUp) {
            if (orderDate >= new Date(facility?.validFrom || '') && orderDate <= new Date(facility?.validTo || '')) {
              return facility.internationalPickUp.includes(pickUpCountry);
            }
          }
        });
      }
    }
    return undefined;
  }
};
